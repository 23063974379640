import { AnimatePresence, motion, useCycle } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AppButton from '../../../components/UI/Buttons';
import { purchase } from '../../../helpers/choreography/purchase';
import { getShippingLogo } from '../../../helpers/shipping-logos';
import { saveShipping } from '../../../store/actions/purchase';
import ShippingDescription from './ShippingDescription';

function ShippingMobile({ names, info }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useCycle(false, true);
  const [isOnSelection, setIsOnSelection] = useCycle(true, false);
  const [selectedButton, setSelBtn] = useState('');
  const [btnIsSelected, setBtnIsSelected] = useState(false);
  const [btnIsAvailable, setBtnIsAvailable] = useState(false);
  const [selectedShipping, setSelShipping] = useState([]);
  const [selectedPrice, setSelPrice] = useState({
    activeObject: null,
    objects: selectedShipping,
  });

  useEffect(() => {
    setIsOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSelection = (service) => {
    setIsOpen();
    setSelBtn(service);
    const shippOptions = info[service];
    setSelShipping(shippOptions);
    setBtnIsSelected(!btnIsSelected);
    setTimeout(() => {
      setIsOnSelection();
    }, 400);
  };

  const setSelectionPrice = (i) => {
    dispatch(saveShipping(selectedShipping[i]));
    setSelPrice({ ...selectedPrice, activeObject: selectedShipping[i] });
    setBtnIsAvailable(!btnIsAvailable);
  };

  const setClassess = (i) => {
    if (selectedShipping[i] === selectedPrice.activeObject) {
      return 'shipping-brand-item shipping-brand-item--selected';
    } else {
      return 'shipping-brand-item';
    }
  };

  const cancelSelection = () => {
    setIsOpen();
    setTimeout(() => {
      setIsOnSelection();
    }, 800);
  };

  const continueWShipment = () => {
    history.push('/carrito/metodo-de-pago');
  };

  return (
    <div className={`${isOnSelection ? 'sf-selection' : 'sf-selection sf-selection--active'}`}>
      {/* Build list */}
      <motion.h4
        variants={purchase.downMovement}
        transition={{
          ease: 'easeOut',
          delay: 0.4,
          duration: 0.2,
        }}
        initial='hidden'
        animate='visible'
        exit='hidden'
        className='sf-title'>
        Selecciona tu paquetería
      </motion.h4>

      <AnimatePresence exitBeforeEnter>
        {isOnSelection && (
          <motion.div animate={isOpen ? 'open' : 'closed'} initial={false} className='sf-election'>
            <motion.article variants={purchase.buttonsParent} className='sf-box'>
              {names.map((service, i) => {
                const logo = getShippingLogo(service);
                const modifier = service.toLocaleLowerCase();

                return (
                  <motion.div variants={purchase.buttonsItem} key={i} className='sf-wrap'>
                    <AppButton
                      type='purchase'
                      url={logo}
                      modifier={modifier}
                      customClick={() => {
                        showSelection(service);
                      }}
                    />
                  </motion.div>
                );
              })}
            </motion.article>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Selected list */}
      <AnimatePresence exitBeforeEnter>
        {!isOpen && (
          <motion.div
            variants={purchase.pageMovement}
            transition={{
              ease: 'easeInOut',
              delay: 0.5,
              duration: 0.4,
            }}
            initial='hidden'
            animate='visible'
            exit='hidden'
            className='sf-selected-service'>
            <header className='sf-header'>
              <AppButton type='purchase' url={getShippingLogo(selectedButton)} modifier={selectedButton.toLocaleLowerCase()} handler={btnIsSelected} customClick={cancelSelection} />
            </header>
            <div className='sf-description'>
              {selectedShipping.map((brand, i) => {
                return <ShippingDescription classess={setClassess(i)} brand={brand} handleSelection={setSelectionPrice} id={i} key={i} />;
              })}
            </div>
            <nav className='sf-nav'>
              <AppButton type='main' txt='Continuar' available={btnIsAvailable} customClick={continueWShipment} />
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ShippingMobile;
