import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultImg } from '../../helpers';
import { getReviews } from '../../store/actions/products';
import RatingProduct from './../RatingProduct';
import { Link } from 'react-router-dom';

const CommentsDesktop = () => {
  const { reviews, product } = useSelector((state) => state.product);
  const carouselContainer = useRef(false);
  const isLoaded = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!reviews.length && !isLoaded.current) {
      isLoaded.current = true;
      dispatch(getReviews());
    }
  }, [dispatch, reviews]);

  useEffect(() => {
    const carouselElement = carouselContainer.current;
    if (reviews.length > 1 && carouselContainer.current) {
      $(carouselElement).slick({
        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        mobileFirst: true,
        infinite: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToScroll: 4,
              slidesToShow: 4,
            },
          },
        ],
      });
    }
  }, [reviews]);

  if (!reviews.length) return '';

  return (
    <div id='comments'>
      <h1>Opiniones del producto <Link to={`/comentarios/${product.id}/${product.code}`}><i className="icon-plus-circle"></i> Ver más</Link></h1>
      <div className='carousel' ref={carouselContainer}>
        {reviews.map((reviewItem, reviewItemIndex) => {
          return (
            <div className='carousel-items' key={reviewItemIndex}>
              <div className='comment'>
                <div className='author'>
                  <div className='photo'>
                    <img src={reviewItem.user_avatar} alt={reviewItem.name} onError={(event) => defaultImg(event)} />
                  </div>
                  <div className='name'>
                    <h1>{reviewItem.user_name}</h1>
                    <RatingProduct rating={reviewItem.rating} />
                  </div>
                </div>
                <p>{reviewItem.comment}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommentsDesktop;
