import React from 'react';
import { connect } from 'react-redux';
import {
  onlyAcceptName,
  onlyAcceptNumbers,
  onlyAcceptNumbersLetters,
  onlyAcceptPhone,
  onlyAlphaNumeric,
  validateAddress,
  validateName,
  validateNumberStreet,
  validatePhone,
  validateZip,
} from '../../../helpers';
import { isMobile } from '../../../helpers';
import inputScroll from '../../../helpers/input-auto-scroll-viewport';
import { getLocationByZip } from '../../../store/actions/cart';
import { enableAddAddress, enableNext, getShippingCost, setAddress } from '../../../store/actions/purchase';
import { addNewAddress, getAddresses } from '../../../store/actions/user';
import Select from '../../FormElements/Select';
import Input from '../../Input';
import InputCheckbox from '../../FormElements/InputCheckbox';
import Modal from '../../Modal';
import PackagesRates from '../PackagesRates';
import ChangeDeliveryType from '../ChangeDeliveryType';

class MyAddresses extends React.Component {
  state = {
    newPersonalInformation: {
      alias: '',
      name: '',
      phone: '',
      address: '',
      interiorNumber: '',
      exteriorNumber: '',
      zip: '',
      suburbs: false,
      state: false,
      city: false,
      references: '',
      principal: false,
    },
    addNewAddressForm: false,
    addressID: false,
    isMobile: false,
  };

  componentDidMount() {
    this.props.getAddresses();
    this.setState({ isMobile: isMobile() });
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });

    const inputsModal = document.querySelectorAll('#purchase-user-registered .form-add-address.desktop .ps input');
    inputsModal.forEach((input) => {
      input.addEventListener('click', (event) => {
        inputScroll(input, '#purchase-user-registered .form-add-address.desktop .ps', 0, 500);
      });
    });

    const inputsModalMobile = document.querySelectorAll('#purchase-user-registered .form-add-address.mobile .ps input');
    inputsModalMobile.forEach((input) => {
      input.addEventListener('click', (event) => {
        inputScroll(input, '#purchase-user-registered .form-add-address.mobile .ps', 0, 500);
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addresses.length !== this.props.addresses.length) {
      const newPersonalInformation = {
        alias: '',
        name: '',
        phone: '',
        address: '',
        interiorNumber: '',
        exteriorNumber: '',
        zip: '',
        suburbs: false,
        state: false,
        city: false,
        references: '',
        principal: false,
      };
      this.setState({ newPersonalInformation, addNewAddressForm: false });
    }
  }

  addNewAddressForm = () => {
    const open = !this.state.addNewAddressForm;
    const body = document.querySelector('body');
    if (open) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
    this.setState({ addNewAddressForm: open });
  };

  update = async (name, value) => {
    const newPersonalInformation = { ...this.state.newPersonalInformation };
    newPersonalInformation[name] = value;
    if (name === 'zip') {
      newPersonalInformation.suburbs = false;
      newPersonalInformation.city = false;
      newPersonalInformation.state = false;
      if (value.length === 5) {
        this.setState({ newPersonalInformation });
        const locations = await this.props.getLocationByZip(value);
        if (locations.state) {
          newPersonalInformation.city = locations.city;
          newPersonalInformation.state = locations.state;
          return this.setState({ newPersonalInformation, suburbs: locations.suburbs });
        }
      }
    }

    this.setState({ newPersonalInformation });
  };

  enableAddNewAddress = () => {
    return (
      !!this.state.newPersonalInformation.alias.length &&
      !!this.state.newPersonalInformation.name.length &&
      !!this.state.newPersonalInformation.phone.length &&
      !!this.state.newPersonalInformation.address.length &&
      !!this.state.newPersonalInformation.references.length &&
      !!this.state.newPersonalInformation.exteriorNumber.length &&
      !!this.state.newPersonalInformation.zip.length &&
      !!this.state.newPersonalInformation.suburbs &&
      !!this.state.newPersonalInformation.state.name.length &&
      !!this.state.newPersonalInformation.city.name.length
    );
  };

  addNewAddress = () => {
    if (!this.enableAddNewAddress()) return;
    const address = {
      alias: this.state.newPersonalInformation.alias,
      name: this.state.newPersonalInformation.name,
      phone: this.state.newPersonalInformation.phone,
      state_id: this.state.newPersonalInformation.state.id,
      state_name: this.state.newPersonalInformation.state.name,
      city_id: this.state.newPersonalInformation.city.id,
      city_name: this.state.newPersonalInformation.city.name,
      suburb_id: this.state.newPersonalInformation.suburbs.id,
      suburb_name: this.state.newPersonalInformation.suburbs.name,
      zip: this.state.newPersonalInformation.zip,
      interior_number: this.state.newPersonalInformation.interiorNumber,
      exterior_number: this.state.newPersonalInformation.exteriorNumber,
      address: this.state.newPersonalInformation.address,
      is_default: this.state.newPersonalInformation.principal,
      references: this.state.newPersonalInformation.references,
    };

    this.props.addNewAddress(address);
  };

  isAddressSelected = (item) => this.props.addressID === item.address_id;

  selectAddress = (addressID) => {
    this.props.setAddressID(addressID);
  };

  nextStep = () => {
    if (this.props.deliveryType === 'office' && this.props.officeID) {
      return this.props.nextStep();
    }
    if (this.props.addressID) {
      this.props.getShippingCost();
    }
  };

  render() {
    return (
      <>
        <section id='personal-information-registered'>
          <ChangeDeliveryType />
          <h1>Mis direcciones</h1>
          <span className='btn btn-white-plus btn-add-address' onClick={() => this.addNewAddressForm()}>
            AGREGAR NUEVA <i className='icon-plus'></i>
          </span>
          <ul className={`addresses${!this.props.addresses || !this.props.addresses?.length ? ' empty' : ''}`}>
            <li className='item-address btn-add-address' onClick={() => this.addNewAddressForm()}>
              <div>
                <i className='icon-plus'></i>
                AGREGAR NUEVA
              </div>
            </li>
            {this.props.addresses &&
              this.props.addresses.map((item, index) => (
                <li className={`item-address${this.isAddressSelected(item) ? ' active' : ''}`} key={index} onClick={() => this.selectAddress(item.address_id)}>
                  <h2>
                    <i className='icon-pin'></i> {item.alias}
                  </h2>
                  <p>
                    <span className='name'>{item.address.name}</span>
                    <span className='phone'>{item.address.phone}</span>
                  </p>
                  <p className='address'>
                    {item.address.content} {item.address.exterior_number} {item.address.interior_number ? `int. ${item.address.interior_number}` : ''}
                    <br />
                    {item.location.suburb_name}
                    <br />
                    {item.location.zip}
                    <br />
                    {item.location.city_name}, {item.location.state_name}
                  </p>
                </li>
              ))}
            <li className='item-address btn-next'>
              <span className={`btn btn-primary rounded${this.props.enableNext() ? '' : ' disabled'}${this.props.loaderRates ? ' btn-loader' : ''}`} onClick={() => this.nextStep()}>
                {this.props.deliveryType === 'office' ? 'SIGUIENTE' : 'COTIZAR ENVÍO'}
              </span>
            </li>
          </ul>
          <Modal className='form-add-address mobile' open={this.state.addNewAddressForm && this.state.isMobile} close={() => this.addNewAddressForm()}>
            <h1>Nueva dirección</h1>
            <Input placeholder='Alias' icon='icon-tag' value={this.state.newPersonalInformation.alias} update={(value) => this.update('alias', value)} bottomDistance={0} name='alias' />

            <Input
              placeholder='Nombre completo'
              icon='icon-login'
              update={(value) => this.update('name', value)}
              value={this.state.newPersonalInformation.name}
              min={validateName.minlength}
              max={validateName.maxlength}
              pattern={validateName.regexp}
              bottomDistance={0}
              accept={onlyAcceptName}
              minWords={validateName.minWords}
              name='name'
            />

            <Input
              placeholder='Teléfono'
              icon='icon-phone'
              type='phone'
              update={(value) => this.update('phone', value)}
              value={this.state.newPersonalInformation.phone}
              min={validatePhone.minlength}
              max={validatePhone.maxlength}
              pattern={validatePhone.regexp}
              bottomDistance={0}
              accept={onlyAcceptPhone}
              name='phone'
            />

            <Input
              placeholder='Dirección'
              icon='icon-pin'
              update={(value) => this.update('address', value)}
              value={this.state.newPersonalInformation.address}
              bottomDistance={0}
              min={validateAddress.minlength}
              name='address'
            />

            <div className='address-number'>
              <Input
                type='text'
                placeholder='No. Exterior'
                update={(value) => this.update('exteriorNumber', value)}
                value={this.state.newPersonalInformation.exteriorNumber}
                bottomDistance={0}
                min={validateNumberStreet.minlength}
                max={validateNumberStreet.maxlength}
                pattern={validateNumberStreet.regexp}
                accept={onlyAcceptNumbers}
                name='exterior_number'
              />

              <Input
                type='text'
                placeholder='No. Interior'
                suffix='Opcional'
                update={(value) => this.update('interiorNumber', value)}
                value={this.state.newPersonalInformation.interiorNumber}
                bottomDistance={0}
                min={validateNumberStreet.minlength}
                max={validateNumberStreet.maxlength}
                pattern={validateNumberStreet.regexp}
                accept={onlyAcceptNumbersLetters}
                name='interior_number'
              />
            </div>
            <Input
              type='number'
              placeholder='Código Postal'
              icon='icon-pin'
              update={(value) => this.update('zip', value)}
              value={this.state.newPersonalInformation.zip}
              pattern={validateZip.regexp}
              max={validateZip.maxlength}
              bottomDistance={0}
              accept={onlyAcceptNumbers}
              name='zip'
            />
            <Select
              placeholder='Colonia'
              options={this.state.suburbs}
              keyLabel='name'
              value={this.state.newPersonalInformation.suburbs}
              icon='icon-pin'
              onChange={(value) => this.update('suburbs', value)}
              name='suburbs'
            />

            <Input placeholder='Estado' value={this.state.newPersonalInformation.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} name='state' />
            <Input placeholder='Municipio' value={this.state.newPersonalInformation.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} />
            <Input
              placeholder='Entre las calles'
              update={(value) => this.update('references', value)}
              value={this.state.newPersonalInformation.references}
              bottomDistance={0}
              accept={onlyAlphaNumeric}
              name='references'
            />
            <div className='actions'>
              <InputCheckbox
                item={{ label: 'Usar como principal', value: 'Si' }}
                className='center'
                onChange={(value) => this.update('principal', value)}
                selected={this.state.newPersonalInformation.principal}
              />
              <span className={`btn btn-primary rounded${this.enableAddNewAddress() ? '' : ' disabled'}${this.props.loader ? ' btn-loader' : ''}`} onClick={() => this.addNewAddress()}>
                AGREGAR
              </span>
            </div>
          </Modal>

          <Modal className='form-add-address desktop' open={this.state.addNewAddressForm && !this.state.isMobile} close={() => this.addNewAddressForm()} height={660} width={740}>
            <h1>Nueva dirección</h1>
            <div className='form'>
              <div>
                <Input placeholder='Alias' icon='icon-tag' value={this.state.newPersonalInformation.alias} update={(value) => this.update('alias', value)} tabIndex='15' name='alias' />
                <Input
                  placeholder='Teléfono'
                  icon='icon-phone'
                  update={(value) => this.update('phone', value)}
                  value={this.state.newPersonalInformation.phone}
                  min={validatePhone.minlength}
                  max={validatePhone.maxlength}
                  pattern={validatePhone.regexp}
                  type='phone'
                  tabIndex='17'
                  accept={onlyAcceptPhone}
                  name='phone'
                />
                <div className='address-number'>
                  <Input
                    type='text'
                    placeholder='No. Exterior'
                    update={(value) => this.update('exteriorNumber', value)}
                    value={this.state.newPersonalInformation.exteriorNumber}
                    tabIndex='19'
                    min={validateNumberStreet.minlength}
                    max={validateNumberStreet.maxlength}
                    pattern={validateNumberStreet.regexp}
                    accept={onlyAcceptNumbers}
                    name='exterior_number'
                  />

                  <Input
                    type='text'
                    placeholder='No. Interior'
                    suffix='Opcional'
                    update={(value) => this.update('interiorNumber', value)}
                    value={this.state.newPersonalInformation.interiorNumber}
                    min={validateNumberStreet.minlength}
                    max={validateNumberStreet.maxlength}
                    pattern={validateNumberStreet.regexp}
                    tabIndex='20'
                    accept={onlyAcceptNumbersLetters}
                    name='interior_number'
                  />
                </div>
                <Select
                  placeholder='Colonia'
                  options={this.state.suburbs}
                  keyLabel='name'
                  value={this.state.newPersonalInformation.suburbs}
                  icon='icon-pin'
                  onChange={(value) => this.update('suburbs', value)}
                  name='suburbs'
                />
                <Input placeholder='Municipio' value={this.state.newPersonalInformation.city.name || ''} readonly={true} icon='icon-pin' name='city' />
              </div>

              <div>
                <Input
                  placeholder='Nombre completo'
                  icon='icon-login'
                  update={(value) => this.update('name', value)}
                  value={this.state.newPersonalInformation.name}
                  min={validateName.minlength}
                  max={validateName.maxlength}
                  pattern={validateName.regexp}
                  tabIndex='16'
                  accept={onlyAcceptName}
                  minWords={validateName.minWords}
                  name='name'
                />

                <Input
                  placeholder='Dirección'
                  icon='icon-pin'
                  update={(value) => this.update('address', value)}
                  value={this.state.newPersonalInformation.address}
                  tabIndex='18'
                  min={validateAddress.minlength}
                  name='address'
                />

                <Input
                  type='number'
                  placeholder='Código Postal'
                  icon='icon-pin'
                  update={(value) => this.update('zip', value)}
                  value={this.state.newPersonalInformation.zip}
                  pattern={validateZip.regexp}
                  max={validateZip.maxlength}
                  tabIndex='21'
                  accept={onlyAcceptNumbers}
                  name='zip'
                />
                <Input placeholder='Estado' value={this.state.newPersonalInformation.state.name || ''} readonly={true} icon='icon-pin' name='state' />
                <Input
                  placeholder='Entre las calles'
                  update={(value) => this.update('references', value)}
                  value={this.state.newPersonalInformation.references}
                  tabIndex='22'
                  accept={onlyAlphaNumeric}
                  name='references'
                />
              </div>
            </div>
            <div className='actions'>
              <div>
                <InputCheckbox
                  item={{ label: 'Usar como principal', value: 'Si' }}
                  className='center'
                  onChange={(value) => this.update('principal', value)}
                  selected={this.state.newPersonalInformation.principal}
                />
              </div>
              <div>
                <span className={`btn btn-primary rounded${this.enableAddNewAddress() ? '' : ' disabled'}${this.props.loader ? ' btn-loader' : ''}`} onClick={() => this.addNewAddress()}>
                  AGREGAR
                </span>
              </div>
            </div>
          </Modal>
        </section>
        <PackagesRates scrollAfterClose={false} />
      </>
    );
  }
}

const mapStateProps = (state) => {
  return {
    loaderAddresses: !state.user.addresses,
    loader: state.user.loader,
    loaderRates: state.purchase.loaderRates,
    addresses: state.user.addresses,
    addressID: state.purchase.addressID,
    officeID: state.purchase.officeID,
    deliveryType: state.purchase.deliveryType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAddresses: () => dispatch(getAddresses()),
    getLocationByZip: (zip) => dispatch(getLocationByZip(zip)),
    addNewAddress: (address) => dispatch(addNewAddress(address)),
    setAddressID: (addressID) => dispatch(setAddress(addressID)),
    getShippingCost: () => dispatch(getShippingCost()),
    enableNext: () => dispatch(enableNext()),
    enableAddAddress: () => dispatch(enableAddAddress()),
    nextStep: () => dispatch({ type: 'PURCHASE_STEP' }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(MyAddresses);
