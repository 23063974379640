import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BtnAddFavorite from '../../components/BtnAddFavorite';
import Image from '../../components/Image';
import Loader from '../../components/Loader';
import MoneyFormat from '../../components/MoneyFormat';
import AppButton from '../../components/UI/Buttons';
import { productAnimations } from '../../helpers/choreography/products';
import useIsMobile from '../../hooks/useIsMobile';
import useProductCart from '../../hooks/useProductCart';
import { updateItemCart } from '../../store/actions/cart';
import { getColors, showColors } from '../../store/actions/global';
import FakeSelect from './../FormElements/FakeSelect';

function ContainerProductItem({ ...rest }) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.cart);
  const { logged } = useSelector((state) => state.user);
  const { products, info, index, setProductTo, setShowModal, toDelete, setToDelete, last } = rest;
  const { code, media, name, brand, price, product_id, quantity, sku, colors, is_favorite } = info;

  // Erase Flow
  const removeItem = (product) => {
    setProductTo(product);
    setToDelete(product.product_id);
    setShowModal(true);
  };

  useEffect(() => {
    setToDelete(false);
  }, [products, setToDelete]);

  // Prices
  const actualPrice = price * quantity;
  const [total, setTotal] = useState(actualPrice.toFixed(2));
  const [acceptDifferentColors, setAcceptDifferentColors] = useState(false);

  const checkboxChange = (e) => {
    setAcceptDifferentColors(e.target.checked);
  };

  const handleColors = () => {
    if (colors.length < 2) return;
    const colorInfo = colors?.map((color, i) => {
      const completeColor = { id: i, sel_quantity: 0, ...color };
      return completeColor;
    });
    const color = {
      id: product_id,
      useDifferentColors: acceptDifferentColors,
      selection: colorInfo,
    };
    dispatch(getColors(color));
    dispatch(showColors(true));
    setProductTo(color);
  };

  // Product Quantity handlers
  const init = useRef(false);
  const productCart = useProductCart(product_id);
  const [showUnitPrice, setShowUnitPrice] = useState(false);

  const [messageLimitItems, setMessageLimitItems] = useState('');

  useEffect(() => {
    if (productCart.quantity && !init.current) {
      const totality = price * productCart.quantity;
      setTotal(totality.toFixed(2));
    }
  }, [productCart, price]);

  useEffect(() => {
    if (quantity > 1) {
      setShowUnitPrice(true);
    }
  }, [quantity]);

  const updateInput = (value) => {
    let newInputValue = value;
    const stock = productCart.stock;
    const message = `El producto no cuenta con el inventario necesario que requiere, actualmente contamos con: ${stock} piezas`;
    if (newInputValue > stock) {
      newInputValue = stock;
      setMessageLimitItems(message);
    } else {
      setMessageLimitItems('');
    }

    if (newInputValue === 0) {
      newInputValue = 1;
      setTotal(price * newInputValue);
      dispatch(updateItemCart(product_id, newInputValue));
    }

    if (newInputValue <= stock && productCart.quantity !== newInputValue) {
      setTotal(price * newInputValue);
      dispatch(updateItemCart(product_id, newInputValue));
    }

    if (!value && productCart.quantity > 0) {
      removeItem(info);
    }
  };

  return (
    <div
      className={classNames({
        product: true,
        'product--erasing': toDelete === product_id,
        'product--disclaimer': colors.length > 1,
      })}>
      <motion.div
        variants={productAnimations.loaderAnimation}
        transition={{
          ease: 'easeInOut',
          duration: 0.5,
        }}
        initial='hidden'
        animate={toDelete === product_id || loader === product_id ? 'visible' : 'hidden'}
        exit='hidden'
        className='product-loader'>
        <Loader ellipsis />
      </motion.div>
      <div className='product-info-wrap'>
        <nav className='product-navigation'>
          {isMobile ? (
            <AppButton
              type='icon'
              icon='trash'
              modifier='erase'
              customClick={() => {
                removeItem(info);
              }}
              id={info}
            />
          ) : (
            <>
              {logged && <BtnAddFavorite id={product_id} is_favorite={is_favorite} />}
              <AppButton
                type='icon'
                icon='trash'
                modifier='erase'
                customClick={() => {
                  removeItem(info);
                }}
                id={info}
              />
            </>
          )}
        </nav>
        <div className='product-info'>
          <figure className='product-img'>
            <Image src={media[0]?.sizes.small} alt={name} />
            {isMobile && logged && (
              <nav className='product-navigation'>
                <BtnAddFavorite id={product_id} is_favorite={is_favorite} />
              </nav>
            )}
          </figure>
          <div className='product-data'>
            <h4 className='title'>
              <Link to={`/producto/${product_id}/${code || name.toLowerCase().replace(' ', '-')}`}>{name}</Link>
            </h4>
            <ul className='product-particularities'>
              <li className='particularity'>
                <span className='txt txt-clear'>SKU:</span>
                <span className='txt txt-primary'>{sku}</span>
              </li>
              <li className='particularity'>
                <span className='txt txt-clear'>Color:</span>

                {!!colors.length && (
                  <>
                    {colors.length < 1 ? (
                      <span className='txt txt-clear'>{colors[0].name}</span>
                    ) : (
                      <span className='txt txt-primary txt-primary--colors'>
                        {colors.map((color, i) => {
                          const lastColor = colors[colors.length - 1];
                          const lastValidation = lastColor.name === color.name;
                          const clearName = color.name.replace(/^[,\s]+|[,\s]+$/g, '');

                          if (lastValidation) {
                            return clearName;
                          } else {
                            return clearName + ', ';
                          }
                        })}
                      </span>
                    )}
                  </>
                )}
              </li>
              <li className='particularity'>
                <span className='txt txt-clear'>Marca:</span>
                <span className='txt txt-primary'>{brand}</span>
              </li>
            </ul>
            <p className='product-pricing-mobile'>
              <MoneyFormat money={total} />
            </p>
            <div className={classNames('product-colors-mask', { disable: colors.length > 1 })} onClick={handleColors}>
              {last && <FakeSelect options={[1, 2, 3, 4, 5, 6, 7, 8, 9]} value={productCart.quantity} up onChange={updateInput} customValueNumber />}
              {!last && <FakeSelect options={[1, 2, 3, 4, 5, 6, 7, 8, 9]} value={productCart.quantity} onChange={updateInput} customValueNumber />}
            </div>
            {messageLimitItems && (
              <div className='form-input error'>
                <span className='error'>{messageLimitItems}</span>
              </div>
            )}
          </div>
        </div>

        {colors.length > 1 && (
          <div className='product-availability'>
            <label className='checkbox' htmlFor={'checkbox_' + index}>
              <span className='checkbox__input'>
                <input type='checkbox' name='checkbox' id={'checkbox_' + index} onChange={checkboxChange} />
                <span className='checkbox__control'>
                  <span className='checkbox__control__indicator'></span>
                </span>
              </span>
              <span className='checkbox__label'>Modelos sujetos a disponibilidad, de no encontrarse en stock se reemplazará en un color distinto.</span>
            </label>
          </div>
        )}
      </div>
      <div className='product-pricing'>
        <div className='product-pricing-wrap'>
          {showUnitPrice && (
            <p className='mxp-text mxp-text-clear'>
              <MoneyFormat type='mixed' money={price} />
            </p>
          )}
          <p className='mxp-text mxp-text-total'>
            <MoneyFormat type='mixed' money={total} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContainerProductItem;
