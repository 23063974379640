import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Container from '../../components/Cart/Container';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import Loader from '../../components/Loader';
import MoneyFormat from '../../components/MoneyFormat';
import { purchaseMakePaymentOXXO, purchaseMakePaymentTC } from '../../store/actions/purchase';
import { purchaseMakePaymentLoyalty, purchaseMakePaymentPaypal } from './../../store/actions/purchase';
import { checkoutAnalytics } from './../../store/actions/analytics-events';

const MakePaymentPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { personalInfo, cardData, paymentMethod, loader, paymentTC, paymentOXXO, paymentLoyalty, deliveryType, ...purchase } = useSelector((state) => state.purchase);
  const { profile } = useSelector((state) => state.user);
  const { total, products, containers } = useSelector((state) => state.cart);
  const { offices } = useSelector((state) => state.home);

  const [totalPay, setTotalPay] = useState(0)

  const loaded = useRef();

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;

      const option = paymentMethod + (purchase.loyalty_points ? ' + Monedero electrónico' : '');

      const metodoEntrega = purchase.officeID ? 'Recoger en sucursal' : 'Entrega a domicilio';

      const office = offices.find(item => item.id === purchase.officeID) || false;

      const entrega = office.name || 'nombre paqueteria';

      checkoutAnalytics({ step: 4, products, containers, option, metodoEntrega, entrega });
    }
  }, [products, containers, purchase.officeID, offices, purchase.loyalty_points, paymentMethod]);

  useEffect(() => {
    const sum = total + (purchase.shipping.total || 0);
    const sumTotal = paymentMethod !== 'loyalty' ? sum - purchase.loyalty_points : sum
    setTotalPay(sumTotal);
  }, [total, purchase.shipping.total, purchase.loyalty_points, paymentMethod])

  useEffect(() => {
    if (!personalInfo.zip) {
      history.replace('/carrito/datos-generales');
    }
  }, [personalInfo, history]);

  useEffect(() => {
    if (paymentTC.error) {
      return history.push('/carrito/error')
    }
    if ((!paymentTC.error && paymentTC.reference) || paymentOXXO.reference || paymentLoyalty.reference) {
      history.push('/carrito/confirmacion');
    }
  }, [history, paymentTC, paymentOXXO, paymentLoyalty.reference]);

  const makePayment = {
    tc: () => {
      dispatch(purchaseMakePaymentTC());
    },
    oxxo: () => {
      dispatch(purchaseMakePaymentOXXO());
    },
    paypal: () => {
      dispatch(purchaseMakePaymentPaypal());
    },
    loyalty: () => {
      dispatch(purchaseMakePaymentLoyalty())
    }
  };

  const handlePayment = () => {
    window.scroll(0, 0);
    makePayment[paymentMethod]();
  };

  const findOffice = () => {
    return offices.find((office) => office.id === purchase.officeID);
  };

  return (
    <ContainerBoxPage className='purchase-process make-payment-page'>
      <Container>
        {loader && <Loader spinner={{ title: 'Procesando' }} blue />}
        {!loader && (
          <>
            <section className='payment-info'>
              <h2>Datos del usuario</h2>
              <div className='payment-data'>
                <div className='item'>
                  <div className='label'>Nombre:</div>
                  <div className='value'>{personalInfo.name}</div>
                </div>
                <div className='item'>
                  <div className='label'>Correo electrónico:</div>
                  <div className='value'>{personalInfo.email || profile.email}</div>
                </div>
                <div className='item'>
                  <div className='label'>Teléfono:</div>
                  <div className='value'>{personalInfo.phone}</div>
                </div>
              </div>
            </section>

            {deliveryType === 'office' && (
              <section className='payment-info'>
                <h2>Datos de entrega</h2>
                <div className='payment-data'>
                  <div className='item'>
                    <div className='label'>Sucursal:</div>
                    <div className='value'>{findOffice().name}</div>
                  </div>
                  <div className='item'>
                    <div className='label'>Dirección:</div>
                    <div className='value'>{findOffice().address}</div>
                  </div>
                  <div className='item'>
                    <div className='label'>Código Postal:</div>
                    <div className='value'>{findOffice().zip}</div>
                  </div>
                </div>
                <Link className='link change-data' to='/carrito/metodo-de-entrega'>
                  Cambiar
                </Link>
              </section>
            )}

            {deliveryType === 'home' && (
              <section className='payment-info'>
                <h2>Datos de entrega</h2>
                <div className='payment-data'>
                  <div className='item'>
                    <div className='label'>Dirección:</div>
                    <div className='value'>
                      {personalInfo.address} No. {personalInfo.exteriorNumber}
                      {personalInfo.interiorNumber && (
                        <>
                          <br />
                          Int. {personalInfo.interiorNumber}
                        </>
                      )}
                      <br />
                      {personalInfo.suburb.name || personalInfo.suburb}, {personalInfo.city.name}
                    </div>
                  </div>
                  <div className='item'>
                    <div className='label'>Código Postal:</div>
                    <div className='value'>{personalInfo.zip}</div>
                  </div>
                  <div className='item'>
                    <div className='label'>Entre las calles:</div>
                    <div className='value'>{personalInfo.references}</div>
                  </div>
                </div>
                <Link className='link change-data' to='/carrito/metodo-de-entrega'>
                  Cambiar
                </Link>
              </section>
            )}

            {paymentMethod === 'tc' && (
              <section className='payment-info'>
                <h2>Método de pago</h2>
                <div className='payment-data'>
                  <div className='item'>
                    <div className='label'>Nombre del tarjetahabiente:</div>
                    <div className='value'>{cardData.name}</div>
                  </div>
                  <div className='item'>
                    <div className='label'>Número de la tarjeta:</div>
                    <div className='value'>**** **** **** {cardData.last4}</div>
                  </div>
                  <div className='item'>
                    <div className='label'>Fecha de vencimiento:</div>
                    <div className='value'>
                      {cardData.exp_month} / {cardData.exp_year}
                    </div>
                  </div>
                  {purchase.msi && <div className='item'>
                    <div className='label'>Mensualidades:</div>
                    <div className='value'>
                      {purchase.msi} meses
                    </div>
                  </div>}
                </div>
                <Link className='link change-data' to='/carrito/metodo-de-pago'>
                  Cambiar
                </Link>
              </section>
            )}
            {paymentMethod !== 'tc' && (
              <section className='payment-info'>
                <h2>Método de pago</h2>
                <div className='payment-data'>
                  <div className='item'>
                    <div className='label'>Tipo:</div>
                    {paymentMethod === 'oxxo' && <div className='value'>OXXO Pay</div>}
                    {paymentMethod === 'paypal' && <div className='value'>Paypal</div>}
                    {paymentMethod === 'loyalty' && <div className='value'>Monedero electrónico</div>}
                  </div>
                </div>
                <Link className='link change-data' to='/carrito/metodo-de-pago'>
                  Cambiar
                </Link>
              </section>
            )}

            <div className='payment-action'>
              <button className='btn btn-primary' onClick={handlePayment}>
                PAGAR: <MoneyFormat money={totalPay} />
              </button>
            </div>
          </>
        )}
      </Container>
    </ContainerBoxPage>
  );
};

export default MakePaymentPage;
