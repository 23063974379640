import { animate } from "framer-motion";
import React, { useEffect, useRef } from 'react';
import classNames from "classnames";
import IconBox from '../../components/BasketBox/IconBox';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import MoneyFormat from '../MoneyFormat';
import useIsMobile from "../../hooks/useIsMobile";

function Counter({ from, to }) {
    const ref = useRef();

    useEffect(() => {
        const controls = animate(from, to, {
            duration: 2,
            onUpdate(value) {
                ref.current.textContent = value.toFixed(0);
            }
        });
        return () => controls.stop();
    }, [from, to]);

    return <span ref={ref} />;
}

function ContainerInformer({ props }) {

    const {
        estimatedCostBox,
        loaderEstimatedCostBox
    } = useSelector((state) => state.cart);
    const isMobile = useIsMobile()

    // const nextPackage = (code) => {
    //     if (code === 'basico') {
    //         return ' Medio'
    //     } else if (code === 'medio') {
    //         return ' Avanzado'
    //     } else if (code === 'avanzado') {
    //         return ' Master'
    //     }
    // }

    if (props) {

        const containersLength = props.length

        const {
            code,
            height,
            length,
            name,
            percentage,
            weight,
            width
        } = props[0]

        const sizes = `Ancho - ${width} cm,
            Largo - ${length} cm,
            Alto - ${height} cm
        `
        // const white = 'svg--whitebox';
        // const next = nextPackage(code)

        return (
            <aside className={classNames({
                'container-info': true,
                // 'container-info--basico': ['basico', 'basico-1', 'basico-2'].includes(code),
                // 'container-info--medio': ['medio', 'medio-1', 'medio-2'].includes(code),
                // 'container-info--avanzado': ['avanzado', 'avanzado-1', 'avanzado-2'].includes(code),
                // 'container-info--master': ['master', 'master-1', 'master-2'].includes(code)
            })}>
                <div className="container-flex">
                    <div className="container-usage">
                        <IconBox type='informer-new' id='container-informer-box' name='new-box' percent={percentage} />
                    </div>
                    <div className="container-progress">
                        <p className="mxp-text">Llevas el</p>
                        <p className="container-progress-number">
                            <Counter from={0} to={percentage} /> %</p>
                        <p className="mxp-text">de llenado.</p>
                    </div>
                </div>
                <small className="container-progress--disclaimer">Aprovecha tu envío y agrega más productos.</small>
                <div className="container-estimated-cost">
                    {loaderEstimatedCostBox && <Loader spinner={{ title: 'Calculando' }} initial blue scale="0.2" />}
                    {!loaderEstimatedCostBox && estimatedCostBox > 0 && <>Costo estimado de envío: <span className="container-estimated-cost-price"><MoneyFormat type="mixed" money={estimatedCostBox} /></span></>}
                    {!loaderEstimatedCostBox && !estimatedCostBox && <>Costo no disponible</>}
                </div>
                {!isMobile && (
                    <div className="container-data">
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Tamaño
                            </p>
                            <p className="mxp-text">
                                {name}
                            </p>
                        </div>
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Medidas:
                            </p>
                            <p className="mxp-text">
                                {sizes}
                            </p>
                        </div>
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Peso máximo:
                            </p>
                            <p className="mxp-text">
                                {weight} kg
                            </p>
                        </div>
                    </div>
                )}
                {!!code &&
                    <div className="container-disclaimer">
                        <img src="/img/icon-caja.svg" alt="Caja" />
                        <p className="mxp-text">
                            Actualmente llevas <strong className="mxp-text-bold"> {containersLength} {containersLength > 1 ? 'cajas:' : 'caja:'} {name}</strong>
                        </p>
                    </div>
                }
                {/* {code !== 'master' &&
                    <div className="container-disclaimer">
                        <p className="mxp-text">
                            Continua agregando productos y tu caja cambiará al siguiente nivel:
                            <strong className="mxp-text-bold">
                                {next}
                            </strong>
                        </p>
                    </div>} */}
            </aside>
        )
    } else {
        return (
            <aside className={classNames({
                'container-info': true,
                'container-info--empty': true
            })}>
                <div className="container-flex">
                    <div className="container-usage">
                        <IconBox type='informer' id='container-informer-box' name='new-box' percent={0} />
                    </div>
                    <div className="container-progress">
                        <p className="mxp-text">Aprovechando el:</p>
                        <p className="container-progress-number">
                            <Counter from={0} to={0} /> %</p>
                        <p className="mxp-text">de tu envío.</p>
                    </div>
                </div>
                <div className="container-estimated-cost">
                    Costo estimado de envío: <span className="container-estimated-cost-price"><MoneyFormat type="mixed" money={estimatedCostBox} /></span>
                </div>
                {!isMobile && (
                    <div className="container-data">
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Tamaño
                            </p>
                            <p className="mxp-text">
                                Chico
                            </p>
                        </div>
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Medidas:
                            </p>
                            <p className="mxp-text">
                                Ancho - 21 cm
                                Largo - 31 cm
                                Alto - 30 cm
                            </p>
                        </div>
                        <div className="container-box">
                            <p className="mxp-text-clear">
                                Peso máximo:
                            </p>
                            <p className="mxp-text">
                                10 g
                            </p>
                        </div>
                    </div>
                )}
            </aside>

        )
    }


}

export default ContainerInformer
