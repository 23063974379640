import React from 'react';
import { connect } from 'react-redux';
import { imageUrl, isMobile } from '../../helpers/index';
import MoneyFormat from '../MoneyFormat';
import PerfectScroll from '../PerfectScroll';
import { enableNext, nextStep, getShippingCost } from '../../store/actions/purchase';

class PurchaseDetails extends React.Component {
  state = {
    open: false,
    isMobile: isMobile()
  };

  changeIsMobile = () => this.setState({ isMobile:isMobile() });

  componentDidMount(){
    window.addEventListener('resize', this.changeIsMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeIsMobile);
  }

  total = () => {
    const shipping = this.props.purchase.shipping.total || 0;
    return this.props.total + shipping;
  };

  openDetail = () => {
    const body = document.querySelector('body');
    const open = !this.state.open;
    if (open) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
    this.setState({ open });
  };

  nextStep = () => {
    if (this.props.enableNext()) {
      if (this.props.purchase.step === 1 && !this.props.purchase.officeID) {
        return this.props.getShippingCost(this.props.orderID, this.props.purchase.personalInfo.zip);
      }
      this.props.nextStep();
    }
  };

  render() {
    return (
      <section id='purchase-details' className={this.state.open ? 'active' : ''}>
        <div className='bg'>
          <h2>Detalle de la compra</h2>
          <div className='total-detail'>
            <table>
              <tbody>
                <tr>
                  <th>SUBTOTAL:</th>
                  <td>
                    <MoneyFormat money={this.props.subTotal} />
                  </td>
                </tr>
                <tr>
                  <th>IVA:</th>
                  <td>
                    <MoneyFormat money={this.props.iva} />
                  </td>
                </tr>
                <tr>
                  <th>ENVÍO:</th>
                  <td>
                    <MoneyFormat money={this.props.purchase.shipping.total || 0} />
                  </td>
                </tr>
                <tr className='total'>
                  <th>TOTAL:</th>
                  <td>
                    <MoneyFormat money={this.total()} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='detail-mobile details'>
            <h2>
              Detalle de la compra <i className='icon-down-open' onClick={() => this.openDetail()}></i>
            </h2>
            <div>
              <PerfectScroll height={this.state.isMobile || this.props.logged ? false : 350}>
                <div className='products'>
                  {this.props.products.map((product, index) => (
                    <article key={index}>
                      <div className='image'>
                        {!product.media.length && <img src={imageUrl(false)} alt={product.name} />}
                        {!!product.media.length && <img src={imageUrl(product.media[0].sizes.small)} alt={product.name} />}
                      </div>
                      <div className='details'>
                        <h1>{product.name}</h1>
                        <p className='label price'>
                          <strong>Precio:</strong> <MoneyFormat money={product.price} />
                        </p>
                        <p className='label quantity'>
                          <strong>Piezas:</strong> {product.quantity}
                        </p>
                      </div>
                    </article>
                  ))}
                </div>
                <div className='total-detail'>
                  <table>
                    <tbody>
                      <tr>
                        <th>SUBTOTAL:</th>
                        <td>
                          <MoneyFormat money={this.props.subTotal} />
                        </td>
                      </tr>
                      <tr>
                        <th>IVA:</th>
                        <td>
                          <MoneyFormat money={this.props.iva} />
                        </td>
                      </tr>
                      <tr>
                        <th>ENVÍO:</th>
                        <td>
                          <MoneyFormat money={this.props.purchase.shipping.total || 0} />
                        </td>
                      </tr>
                      <tr className='total'>
                        <th>TOTAL:</th>
                        <td>
                          <MoneyFormat money={this.total()} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </PerfectScroll>
            </div>
          </div>
        </div>
        <div className='short-detail'>
          <div className='detail'>
            <span className='btn-open' onClick={() => this.openDetail()}>
              Detalle <i className='icon-up-open'></i>
            </span>
            <span className='total'>
              Total: <MoneyFormat money={this.total()} />
            </span>
          </div>
          <div className='btn-go'>
            <span
              className={`btn btn-white btn-small rounded next${this.props.enableNext() ? '' : ' disabled'}${this.props.purchase.loader || this.props.purchase.loaderRates ? ' btn-loader' : ''}`}
              onClick={() => this.nextStep()}>
              {this.props.purchase.step > 1 || this.props.purchase.officeID ? 'SIGUIENTE' : 'COTIZAR ENVÍO'}
            </span>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateProps = (state) => {
  return {
    subTotal: state.cart.sub_total,
    iva: state.cart.iva,
    total: state.cart.total,
    shipping: state.purchase.shipping.total,
    count: state.cart.count,
    products: state.cart.products,
    purchase: state.purchase,
    orderID: state.cart.orderID,
    logged: state.user.logged
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enableNext: () => dispatch(enableNext()),
    nextStep: () => dispatch(nextStep()),
    getShippingCost: (orderID, zip) => dispatch(getShippingCost(orderID, zip)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default React.memo(createConnect(PurchaseDetails));
