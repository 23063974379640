export const types = {
  HISTORY_GET: 'HISTORY_GET',
  HISTORY_GET_DETAIL: 'HISTORY_GET_DETAIL',
  HISTORY_CHANGE_ORDER: 'HISTORY_CHANGE_ORDER',
  HISTORY_LOADER: 'HISTORY_LOADER',
  HISTORY_LOADER_RECREATE: 'HISTORY_LOADER_RECREATE',
  HISTORY_CHANGE_PAGE: 'HISTORY_CHANGE_PAGE',
  HISTORY_ERROR_RECREATE: 'HISTORY_ERROR_RECREATE',
  HISTORY_NOT_FOUND: 'HISTORY_NOT_FOUND',
  HISTORY_TRACKING: 'HISTORY_TRACKING',
  HISTORY_OPEN_TRACKING: 'HISTORY_OPEN_TRACKING',
  HISTORY_RESET_DETAIL: 'HISTORY_RESET_DETAIL',
  HISTORY_RESET: 'HISTORY_RESET',
  RESET: 'RESET',
  SET_REFERENCE_HISTORY: 'SET_REFERENCE_HISTORY',
  SET_PRODUCT_REVIEW: 'SET_PRODUCT_REVIEW',
  SET_PRODUCT_REVIEW_RESPONSE: 'SET_PRODUCT_REVIEW_RESPONSE',
  HISTORY_FILTER: 'HISTORY_FILTER',
  HISTORY_IS_RECREATE: 'HISTORY_IS_RECREATE',
};

const initialStore = {
  detail: false,
  errorRecreate: false,
  items: [],
  loader: false,
  loaderRecreate: false,
  next: '',
  notFound: false,
  order: 'newer',
  orderBy: false,
  deliveryStatus: false,
  page: 0,
  tracking: false,
  openTracking: false,
  is_recreate: false,
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.HISTORY_GET:
      const loadItems = action.reset ? [] : [...store.items];
      loadItems.push(action.items);

      return { ...store, items: loadItems, loader: false, order: action.order, next: action.next, page: loadItems.length - 1, deliveryStatus: action.deliveryStatus};
    case types.HISTORY_GET_DETAIL:
      return { ...store, detail: action.detail || false, loader: false };
    case types.HISTORY_LOADER:
      return { ...store, loader: action.loader || false };
    case types.HISTORY_LOADER_RECREATE:
      return { ...store, loaderRecreate: action.orderID || false };
    case types.HISTORY_CHANGE_PAGE:
      return { ...store, page: action.page };
    case types.HISTORY_RESET:
    case types.RESET:
      return { ...initialStore };
    case types.HISTORY_RESET_DETAIL:
      return { ...store, detail: false, notFound: false, tracking: false, openTracking: false }
    case types.HISTORY_ERROR_RECREATE:
      return { ...store, errorRecreate: action.error, loaderRecreate: false };
    case types.HISTORY_NOT_FOUND:
      return { ...store, notFound: action.notFound, loader: false };
    case types.HISTORY_TRACKING:
      return { ...store, tracking: action.tracking };
    case types.HISTORY_OPEN_TRACKING:
      return { ...store, openTracking: action.openTracking }
    case types.SET_REFERENCE_HISTORY:
      return { ...store, reference: action.reference }
    case types.SET_PRODUCT_REVIEW:
      return { ...store, product_review: action.product }
    case types.SET_PRODUCT_REVIEW_RESPONSE:
      return { ...store, product_review_response: action.status, loader: false }
    case types.HISTORY_IS_RECREATE:
      return { ...store, is_recreate: action.payload }
    case types.HISTORY_FILTER:
      return { ...store, order: action.order, orderBy: action.orderBy }
    default:
      return { ...store };
  }
};

export default reducer;
