import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsMobile from '../../../hooks/useIsMobile';
import { changeView, setShowFilters } from '../../../store/actions/categories';
import { applyFilters } from '../../../store/actions/filters';
import Breadcrumbs from '../../Breadcrumbs';
import FakeSelect from '../../FormElements/FakeSelect';
import AppButton from '../../UI/Buttons';


const SearchHeader = () => {
    const body = document.querySelector('body');
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    const { search:{sorts}, active } = useSelector(state => state.filters)
    const { view } = useSelector(state => state.categories)

    return (
        <div className="category-header">
            {
                !isMobile && (
                    <Breadcrumbs className="cats-breadcrumbs" />
                )
            }
            <div className="category-header-flx">
                <div className="category-header-mixed">
                    <h2 className="mxp-title">Resultados</h2>
                </div>
                <nav className="category-header-navigation">
                    {isMobile && (
                        <>
                            <p className="mxp-text mxp-text-clear">
                                Filtros:
                            </p>
                            <AppButton
                                type="icon"
                                icon="filters-light"
                                modifier="filter"
                                customClick={() => {
                                    dispatch(setShowFilters(true))
                                    body.classList.add('no-scroll');
                                }}
                            />
                        </>
                    )}
                    {!isMobile && (
                        <>
                            <div className="category-header-filter">
                                <p className="mxp-text mxp-text-clear">Ordenar por:</p>
                                <FakeSelect className='category-header-select'
                                    placeholder="Más relevantes"
                                    options={sorts}
                                    value={active.sort || false}
                                    all
                                    onChange={(option) => {
                                        dispatch(applyFilters('sort', option))
                                    }}
                                />
                            </div>
                            <div className="category-header-views-splitter"></div>
                            <div className="category-header-views">
                                <AppButton
                                    type="icon"
                                    icon="ico-cuadricula"
                                    modifier={`grid ${view ? 'active' : ''}`}
                                    customClick={() => {
                                        dispatch(changeView(true))
                                    }}
                                />
                                <AppButton
                                    type="icon"
                                    icon="ico-lista"
                                    modifier={`grid ${!view ? 'active' : ''}`}
                                    customClick={() => {
                                        dispatch(changeView(false))
                                    }}
                                />
                            </div>
                        </>
                    )}
                </nav>
            </div>
        </div>
    )
}

export default SearchHeader
