import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";


const Accordion = ({ children, ...rest }) => {
    const { i, title, expanded, setExpanded } = rest;
    const isOpen = expanded ? i === expanded : false;
    const { name } = title;

    const [open, setOpen] = useState(false)

    const onExpanded = () => {
        if(setExpanded) {
            return setExpanded(!isOpen);
        }
        setOpen(!open)
    }

    return (
        <>
            <motion.div
                initial={false}
                onClick={onExpanded}
                className={classNames('accordion-tab', {
                    'accordion-tab--open': isOpen || open,
                    'accordion-tab--closed': (!isOpen && !open)
                })}
            >
                <p className="accordion-title">
                    {name}
                </p>
                <span className="accordion-title-icon">
                    {(isOpen || open) ?
                        <span className="icon-down-open"></span>
                        :
                        <span className="icon-right-open"></span>
                    }
                </span>
            </motion.div>
            <AnimatePresence initial={false}>
                <motion.section
                    key="content"
                    initial="collapsed"
                    animate={(isOpen || open) ? 'open' : 'collapsed'}
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto", y: 20 },
                        collapsed: { opacity: 0, height: 0, y: 0 }
                    }}
                    className="accordion-section"
                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {children}
                </motion.section>
            </AnimatePresence>
        </>
    );
};

export default Accordion;
