import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateAddress, validateEmail, validateName, validateNumberStreet, validatePhone, validateRFC, validateZip } from '../helpers/validations';
import { purchaseGetLocationsByZip, purchaseResetLocationsByZip } from '../store/actions/purchase';
import FakeSelect from './FormElements/FakeSelect';
import FormInput from './FormElements/FormInput';
import InputCheckbox from './FormElements/InputCheckbox';
import { validateText } from './../helpers/validations';

const NewAddress = ({ onSubmitForm, labelSubmit = 'CONTINUAR', close, title, logged, initialData }) => {
  const dispatch = useDispatch();

  const { locationsByZip, loaderLocations } = useSelector((state) => state.purchase);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialData || {},
  });

  const { alias, name, phone, address, exteriorNumber, zip, suburb, state, city, references, isMain } = watch();

  const enableSubmit = () => {
    const errorsArr = { ...errors };
    delete errorsArr.rfc;
    return isDirty && !Object.keys(errorsArr).length && alias && name && phone && address && exteriorNumber && zip && suburb && state && city && references;
  };

  const handleClose = () => {
    dispatch(purchaseResetLocationsByZip())
    if (close) {
      close();
    }
  }

  const formSubmit = (data) => {
    const address = {
      address_id: initialData.address_id || false,
      alias: data.alias.trim(),
      name: data.name.trim(),
      phone: data.phone.trim(),
      state_id: data.state.id,
      state_name: data.state.name,
      city_id: data.city.id,
      city_name: data.city.name,
      suburb_id: data.suburb.id || data.suburb_id,
      suburb_name: data.suburb.name || data.suburb_name,
      zip: data.zip.trim(),
      interior_number: data.interiorNumber.trim(),
      exterior_number: data.exteriorNumber.trim(),
      address: data.address.trim(),
      is_default: data.isMain ? 1 : 0,
      references: data.references.trim(),
    };
    onSubmitForm(address);
    handleClose()
  };

  useEffect(() => {
    if (zip && /^[0-9]{5}$/.test(zip)) {
      dispatch(purchaseGetLocationsByZip(zip));
    }
  }, [dispatch, zip]);

  useEffect(() => {
    if (locationsByZip.message) {
      setError('zip', { type: 'zip', message: locationsByZip.message }, true);
    } else if (locationsByZip.city) {
      setValue('city', locationsByZip.city);
      setValue('city_label', locationsByZip.city.name);
      setValue('state', locationsByZip.state);
      setValue('state_label', locationsByZip.state.name);
      if (!initialData && locationsByZip.city.name !== initialData.city.name) {
        setValue('suburb', '');
      }
    }
  }, [setValue, setError, locationsByZip, initialData]);

  return (
    <form className='form-add-update-address' onSubmit={handleSubmit(formSubmit)}>
      {(title || close) && (
        <div className='form-title'>
          {title && <h1 className='title-section'>{title}</h1>}
          {close && (
            <span onClick={handleClose}>
              <i className='icon-cancel-circle'></i>
            </span>
          )}
        </div>
      )}

      {logged && <FormInput className='input-alias' name='alias' options={validateText} register={register} error={errors.alias} label='Alias*' defaultValue={initialData.alias || ''} setValue={setValue} validate="text" />}
      <FormInput
        label='Nombre completo*'
        placeholder='Jorge Eduardo Hernández Pérez'
        name='name'
        register={register}
        options={validateName}
        error={errors.name}
        defaultValue={initialData.name || ''}
        setValue={setValue}
        validate="name"
      />

      {!logged && (
        <FormInput
          label='Correo electrónico*'
          placeholder='jorgeEHP@correo.com'
          name='email'
          register={register}
          options={validateEmail}
          error={errors.email}
          type='email'
          defaultValue={initialData.email || ''}
          setValue={setValue}
          validate="email"
        />
      )}

      <FormInput
        label='Teléfono*'
        placeholder='10 dígitos'
        name='phone'
        register={register}
        options={validatePhone}
        error={errors.phone}
        type='tel'
        maxLength='10'
        defaultValue={initialData.phone || ''}
        setValue={setValue}
        validate="number"
      />

      <FormInput
        label='Código Postal*'
        placeholder='00000'
        name='zip'
        type='numeric'
        maxLength='5'
        register={register}
        options={validateZip}
        error={errors.zip}
        loader={loaderLocations}
        readOnly={loaderLocations}
        defaultValue={initialData.zip || ''}
        setValue={setValue}
        validate="number"
      />

      <FormInput label='Dirección*' placeholder='Nombre de la calle' name='address' register={register} options={validateAddress} error={errors.address} defaultValue={initialData.address || ''} setValue={setValue} validate="address" />

      <div className='address-number'>
        <FormInput
          label='Número exterior*'
          placeholder='000'
          name='exteriorNumber'
          register={register}
          options={validateNumberStreet}
          error={errors.exteriorNumber}
          defaultValue={initialData.exteriorNumber || initialData.exterior_number || ''}
          setValue={setValue}
          pattern={/[^a-zA-Z0-9]/g}
        />

        <FormInput label='Número interior' placeholder='000' name='interiorNumber' register={register} defaultValue={initialData.interiorNumber || initialData.interior_number || ''} setValue={setValue} pattern={/[^a-zA-Z0-9]/g} />
      </div>

      <FakeSelect
        options={locationsByZip?.suburbs}
        label='Colonia*'
        placeholder='Selecciona una colonia'
        value={typeof suburb !== 'undefined' ? suburb : initialData.suburb}
        name='suburb'
        registerOptions={{ shouldValidate: true, required: true }}
        id='new-address-select-suburb'
        onChange={setValue}
      />

      <FormInput label='Estado*' name='state_label' register={register} readOnly />

      <FormInput label='Municipio*' name='city_label' register={register} error={errors.city} readOnly />

      <FormInput label='Entre las calles*' name='references' register={register} options={validateText} error={errors.references} defaultValue={initialData.references || ''} setValue={setValue} validate="text" />

      {!logged && (
        <FormInput
          label='RFC'
          name='rfc'
          register={register}
          defaultValue={initialData.rfc || ''}
          placeholder='13 caracteres'
          options={validateRFC}
          error={errors.rfc}
          maxLength='13'
          className='input-uppercase'
          setValue={setValue}
        />
      )}

      {logged && (
        <InputCheckbox
          register={register}
          name='isMain'
          className='isMain'
          item={{ value: 1, label: 'Usar como principal' }}
          selected={typeof isMain === 'undefined' ? initialData.is_default : isMain}
        />
      )}
      <button className={`btn btn-primary rounded-small${enableSubmit() ? '' : ' disabled'}`}>{labelSubmit}</button>
    </form>
  );
};

export default React.memo(NewAddress);
