import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyFilters } from './../../../store/actions/filters';
import Tag from './../../UI/Tag';

const CurrentFilters = () => {
  const dispatch = useDispatch();
  const {active} = useSelector(state => state.filters)

  const handleRemoveFilter = (item, type) => {
    dispatch(applyFilters(type, item));
  };

  if(!Object.keys(active).length) return ''

  return (<div
    className="cats-filters-array category-tags--small">
    {Object.entries(active).map(([type, filter], i) => {
      if (type === 'sort') {
        return (
          <div key={i} className='category-tags category-tags-type-sorts'>
            <p className='mxp-text-clear'>
              Ordenar por:
              <span className='category-tags-type'>{filter.name || filter.display}</span>
            </p>
          </div>
        );
      } else {
        return (
          <Tag
            key={i}
            name={filter.name}
            type='filtered'
            customClick={() => {
              handleRemoveFilter(filter, type);
            }}
          />
        );
      }
    })}
  </div>);
};

export default CurrentFilters;
