import React from 'react';

class ProgressIndicator extends React.Component {
  render() {
    if (!this.props.options) return '';
    return (
      <div className={`progress-indicator steps-${this.props.options.length} current-step-${this.props.current}`}>
        {this.props.options.map((item, index) => (
          <span key={index} className={`${item.id === this.props.current ? 'active' : ''}${item.id < this.props.current ? 'enabled' : ''}`}>
            {item.label}
          </span>
        ))}
      </div>
    );
  }
}

export default ProgressIndicator;
