import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { capitalize } from '../../../helpers';
import useCurrentCategory from './../../../hooks/useCurrentCategory';
import useIsMobile from './../../../hooks/useIsMobile';
import { setShowFilters } from './../../../store/actions/categories';
import { addMultipleFilters, filtersRemove } from './../../../store/actions/filters';
import FakeSelect from './../../FormElements/FakeSelect';
import Accordion from './../../UI/Accordion';
import { AppButton } from './../../UI/Buttons';
import RangeSlider from './../../UI/RangeSliderRedux';
import Tag from './../../UI/Tag';

const body = document.querySelector('body');

const CategoryFiltersMobile = ({ type = 'category' }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();
  const currentCategory = useCurrentCategory();

  const { categories } = useSelector((state) => state.categories);
  const currentFilters = useSelector((state) => state.filters);

  const { sorts, range_prices, brands, materials } = currentFilters[type];

  const { active } = currentFilters;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      brand: active.brand || '',
      material: active.material || '',
      sort: active.sort || '',
    },
  });

  const { category, sort, brand, material } = watch();

  const submitForm = (data) => {
    body.classList.remove('no-scroll');
    window.scrollTo(0, 0);

    delete data.category;

    const filters = {};

    Object.entries(data).forEach(([key, item]) => {
      if (item) {
        filters[key] = item;
      }
    });

    if (!!Object.keys(filters).length) {
      dispatch(addMultipleFilters(filters));
    }

    if (category) {
      history.push(`/box/categoria/${category.id}/${category.code}`);
      dispatch(filtersRemove());
    }
    dispatch(setShowFilters(false));
  };

  const handleRange = (range) => {
    const min = Math.round(range[0]);
    const max = Math.floor(range[1]);
    setValue('prices', { min, max, name: `$${min} a $${max}` }, { shouldDirty: true });
  };

  const handleClose = () => {
    dispatch(setShowFilters(false));
    body.classList.remove('no-scroll');
  };

  return (
    <form
      className={classNames({
        'category-filters': true,
        'category-filters-mobile': isMobile,
      })}
      onSubmit={handleSubmit(submitForm)}>
      <div className='category-filters-head'>
        <h3 className='mxp-subtitle'> Filtros </h3>
        <span className='btn btn-icon btn-icon--close' onClick={handleClose}>
          <i className='icon-cancel-circle'></i>
        </span>
      </div>

      <div className='category-filters-scroll'>
        <div className='category-filters-drops'>
          <div className='category-filters-drop'>
            <p className='mxp-text mxp-text-clear'>Categorías</p>
            <FakeSelect className='category-select' options={categories} register={register} name='category' value={category || currentCategory} onChange={setValue} />
          </div>
          <div className='category-filters-drop category-filters-drop--order'>
            <p className='mxp-text mxp-text-clear'>Ordenar por</p>
            {sorts && <FakeSelect className='category-select' placeholder='Más relevantes' options={sorts} register={register} name='sort' onChange={setValue} value={sort} />}
          </div>
        </div>
        {range_prices?.max - range_prices?.min >= 1 && (
          <div className='cats-filters-range'>
            <p className='mxp-text mxp-text-clear'>Precio</p>
            <RangeSlider
              action={handleRange}
              MIN={Math.round(range_prices.min)}
              MAX={Math.floor(range_prices.max)}
              min={active.prices?.min || Math.round(range_prices.min)}
              max={active.prices?.max || Math.floor(range_prices.max)}
            />
          </div>
        )}
        {brands && (
          <div className='cats-filters-accordion category-filters-accordion'>
            <Accordion title={{ name: 'Marcas' }}>
              {brands.map((item, i) => {
                const prodName = item.name.toLowerCase();
                return (
                  <Tag
                    key={i}
                    active={brand?.id === item.id}
                    name={capitalize(prodName)}
                    customClick={() => {
                      setValue('brand', item, { shouldDirty: true });
                    }}
                    type='single'
                  />
                );
              })}
            </Accordion>
          </div>
        )}
        {materials && (
          <div className='cats-filters-accordion category-filters-accordion'>
            <Accordion i={2} title={{ name: 'Material' }}>
              {materials.map((item, i) => {
                const prodName = item.name.toLowerCase();
                return (
                  <Tag
                    key={i}
                    name={capitalize(prodName)}
                    active={material?.id === item.id}
                    customClick={() => {
                      setValue('material', item, { shouldDirty: true });
                    }}
                    type='single'
                  />
                );
              })}
            </Accordion>
          </div>
        )}
        <nav className='cats-filters-nav'>
          <AppButton type='main' txt='Aplicar' available={isDirty} />
        </nav>
      </div>
    </form>
  );
};

export default CategoryFiltersMobile;
