import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../components/FormElements/FormInput';
import Loader from '../../../components/Loader';
import MoneyFormat from '../../../components/MoneyFormat';
import AppButton from '../../../components/UI/Buttons';
import WalletRequest from '../../../components/User/WalletRequest';
import { userGetLoyaltyInfo, userHideLoyaltyInfo } from '../../../store/actions/user';
import ProfileContainer from '../ProfileContainer';

const Form = ({ close, message }) => {
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onBlur',
  });

  const { password } = watch();

  const enableSubmit = () => !Object.keys(errors).length && password;

  const formSubmit = (data) => {
    dispatch(userGetLoyaltyInfo(data.password));
  };

  return (
    <form className='form-confirmation' onSubmit={handleSubmit(formSubmit)}>
      <p>
        Para poder mostrar el número de la tarjeta, por favor, ingresa tu contraseña.{' '}
        <span className='close' onClick={close}>
          <i className='icon-cancel-circle'></i>
        </span>
      </p>
      <FormInput
        register={register}
        name='password'
        type='password'
        label='Contraseña*'
        placeholder='8 a 30 caracteres'
        setValue={setValue}
        options={{ required: true, shouldValidate: true }}
        error={errors.password}
      />
      {message && <div className="danger">{message}</div>}
      <AppButton txt='INGRESAR' type='main' available={enableSubmit()} small />
    </form>
  );
};

const MyWalletPage = () => {
  const dispatch = useDispatch();

  const { profile, loyalty: loyaltyFull, loaderLoyalty } = useSelector((state) => state.user);

  const [confirmShowNumber, setConfirmShowNumber] = useState(false);

  if (!profile.loyalty?.last4) return <WalletRequest />;

  const handleHideCard = () => {
    dispatch(userHideLoyaltyInfo())
    setConfirmShowNumber(false)
  }

  return (
    <ProfileContainer className='profile-my-wallet'>
      <h1 className='title-section'>Monedero electrónico</h1>
      {loaderLoyalty && <Loader spinner={{ title: 'Cargando' }} blue />}
      {!loaderLoyalty && (
        <>
          <div className='card-image'>
            <img src='/img/logo-white.png' alt='MXP' />
            <div className='number'>
              {(!loyaltyFull || loyaltyFull.message) && (
                <>
                  <span>****</span>
                  <span>****</span>
                  <span>****</span>
                </>
              )}
              <span>{loyaltyFull?.card || profile.loyalty.last4}</span>
            </div>
          </div>
          <div className={`data${loyaltyFull ? ' full' : ''}`}>
            {confirmShowNumber && !loyaltyFull && <Form />}
            <div className='name'>
              <strong>Nombre del titular:</strong>
              <span>{profile.user_name}</span>
            </div>
            <div className='total'>
              <strong>Total acumulado:</strong>
              <span>
                <MoneyFormat money={loyaltyFull?.points || profile.loyalty.points} />
              </span>
            </div>
            {!loyaltyFull && profile.loyalty.last4 && (
              <>
                <div className={`actions${confirmShowNumber ? ' active' : ''}`}>
                  {!loyaltyFull && (
                    <span className='link' onClick={() => setConfirmShowNumber(true)}>
                      Ver número de la tarjeta
                    </span>
                  )}
                  <span className='close' onClick={() => setConfirmShowNumber(false)}>
                    <i className='icon-cancel-circle'></i>
                  </span>
                </div>
              </>
            )}
            {loyaltyFull && profile.loyalty.last4 && (
              <>
                <div className="actions">
                    <span className='link' onClick={handleHideCard}>
                      Ocultar número de la tarjeta
                    </span>
                </div>
              </>
            )}
          </div>
          {confirmShowNumber && (!loyaltyFull || loyaltyFull.message) && <Form close={() => setConfirmShowNumber(false)} message={loyaltyFull.message} />}
        </>
      )}
    </ProfileContainer>
  );
};

export default React.memo(MyWalletPage);
