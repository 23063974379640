import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Breadcrumbs from '../../components/Breadcrumbs'
import ContainerBoxPage from '../../components/containers/ContainerBoxPage'
import RegistrationDesktop from '../../components/User/RegistrationDesktop'
import RegistrationMobile from '../../components/User/RegistrationMobile'
import useIsMobile from '../../hooks/useIsMobile'

const Registration = () => {
    const isMobile = useIsMobile()
    const history = useHistory()
    const { logged } = useSelector((state) => state.user)

    useEffect(() => {
        if (logged) {
            history.push('/')
        }
    }, [history, logged])

    return (
        <ContainerBoxPage className='registration-box-page'>
            <div className="registration-panel">
                <Breadcrumbs className="registration-crumbs" />
                {isMobile && <RegistrationMobile />}
                {!isMobile && <RegistrationDesktop />}
            </div>
        </ContainerBoxPage>
    )
}

export default Registration
