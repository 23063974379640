import React from 'react'
import { useHistory } from 'react-router';
import classNames from 'classnames';
import ContainerProductItem from './ContainerProductItem';
import PerfectScroll from '../PerfectScroll';
import AppButton from '../../components/UI/Buttons';

function ContainerProductList({ loader, toDelete, products, setToDelete, setShowModal, setProductTo }) {
    const canContinue = products.length > 0;
    const canScroll = products.length > 3;
    const history = useHistory();

    const handleGoToHome = () => {
        history.push('/')
    }

    const rowLen = products.length;

    return (
        <>
            {
                canContinue ?
                    <div className={classNames({
                        'container-list-wrapper': true,
                        'container-list-wrapper--min-scroll': canScroll
                    })}>
                        {
                            canScroll ?
                                <PerfectScroll scrollYMarginOffset={0} wheelPropagation={true}>
                                    <ul className="container-list">
                                        {
                                            products.map(((product, i) => {
                                                const uniqueID = Number(i)

                                                if (rowLen === i + 1) {
                                                    // Last
                                                    return (
                                                        <li className="container-item" key={i}>
                                                            <ContainerProductItem
                                                                toDelete={toDelete}
                                                                setToDelete={setToDelete}
                                                                setProductTo={setProductTo}
                                                                setShowModal={setShowModal}
                                                                products={products}
                                                                info={product}
                                                                loader={loader}
                                                                index={uniqueID}
                                                                last={true}
                                                            />
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li className="container-item" key={i}>
                                                            <ContainerProductItem
                                                                toDelete={toDelete}
                                                                setToDelete={setToDelete}
                                                                setProductTo={setProductTo}
                                                                setShowModal={setShowModal}
                                                                products={products}
                                                                info={product}
                                                                loader={loader}
                                                                index={uniqueID}
                                                            />
                                                        </li>
                                                    )
                                                }

                                            }))
                                        }
                                    </ul>
                                </PerfectScroll>
                                :
                                <ul className="container-list">
                                    {
                                        products.map(((product, i) => {
                                            const uniqueID = Number(i)
                                            return (
                                                <li className="container-item" key={i}>
                                                    <ContainerProductItem
                                                        toDelete={toDelete}
                                                        setToDelete={setToDelete}
                                                        setProductTo={setProductTo}
                                                        setShowModal={setShowModal}
                                                        products={products}
                                                        info={product}
                                                        loader={loader}
                                                        index={uniqueID}
                                                    />
                                                </li>
                                            )
                                        }))
                                    }
                                </ul>
                        }
                    </div>
                    :
                    <ul className="container-list">
                        <li className="container-fallback">
                            <div className="container-fallback-box">
                                <h4 className="mxp-title-h4">No hay productos agregados a tu contenedor.</h4>
                                <AppButton type="main" txt="IR AL Catálogo" modifier="main" available={!canContinue} onClick={handleGoToHome} />
                            </div>
                        </li>
                    </ul>
            }
        </>
    )
}

export default ContainerProductList
