import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { onlyAcceptNumbers, onlyAcceptNumbersLetters, onlyAlphaNumeric, validateAddress, validateNumberStreet, validateZip } from '../../../helpers';
import { isMobile } from '../../../helpers';
import moveInput from '../../../helpers/input-auto-scroll-viewport';
import { getLocationByZip } from '../../../store/actions/cart';
import Select from '../../FormElements/Select';
import Input from '../../Input';

class FormChangeAddress extends React.Component {
  state = {
    isMobile: false,
    errors: {},
    suburbs: [],
  };

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
  }

  componentDidUpdate() {
    const inputs = document.querySelectorAll('.change-address input');
    if (inputs.length > 0) {
      inputs.forEach((element) => {
        if (!element.classList.contains('input-move')) {
          element.classList.add('input-move');
          element.addEventListener(
            'focus',
            (event) => {
              moveInput(event.currentTarget, '.change-address .ps', 0, 500);
            },
            true,
          );
        }
      });
    }
  }

  update = async (name, value, error) => {
    const errors = { ...this.state.errors };
    if (error) {
      errors[name] = true;
    } else {
      delete errors[name];
    }
    this.setState({ errors });
    if (name === 'submit') {
      return this.props.changeAddress('submit', false);
    }
    if (name === 'zip' && value.length === 5) {
      const response = await this.props.getLocation(value);
      this.props.changeAddress('suburbs', '');
      this.props.changeAddress('city', response.city);
      this.props.changeAddress('state', response.state);
      this.setState({ suburbs: response.suburbs });
    }
    this.props.changeAddress(name, value);
  };

  render() {
    return (
      <Fragment>
        {this.state.isMobile && (
          <Fragment>
            <Input
              className='field-address'
              placeholder='Dirección'
              icon='icon-pin'
              update={(value) => this.update('address', value)}
              value={this.props.personalInfo.address}
              tabIndex='4'
              bottomDistance={100}
              hasModal={false}
              min={validateAddress.minlength}
              name='address'
            />
            <div className='address-number'>
              <Input
                placeholder='No. Exterior'
                update={(value) => this.update('exteriorNumber', value)}
                value={this.props.personalInfo.exteriorNumber}
                tabIndex='5'
                type='text'
                min={validateNumberStreet.minlength}
                pattern={validateNumberStreet.regexp}
                bottomDistance={100}
                hasModal={false}
                accept={onlyAcceptNumbers}
                name='exterior_number'
              />
              <Input
                placeholder='No. Interior'
                suffix='Opcional'
                update={(value) => this.update('interiorNumber', value)}
                value={this.props.personalInfo.interiorNumber}
                tabIndex='5'
                type='text'
                bottomDistance={100}
                hasModal={false}
                accept={onlyAcceptNumbersLetters}
                name='interior_number'
              />
            </div>
            <Input
              className='field-zip'
              type='number'
              placeholder='Código postal'
              icon='icon-pin'
              update={(value) => this.update('zip', value)}
              pattern={validateZip.regexp}
              max={validateZip.maxlength}
              value={this.props.personalInfo.zip}
              tabIndex='7'
              bottomDistance={100}
              hasModal={false}
              accept={onlyAcceptNumbers}
              name='zip'
            />

            <Select
              className='field-suburbs'
              placeholder='Colonia'
              options={this.state.suburbs}
              keyLabel='name'
              value={this.props.personalInfo.suburbs}
              icon='icon-pin'
              onChange={(value) => this.update('suburbs', value)}
            />

            <Input className='field-state' placeholder='Estado' value={this.props.personalInfo.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
            <Input className='field-city' placeholder='Municipio' value={this.props.personalInfo.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
            <Input
              className='field-references'
              placeholder='Entre las calles'
              update={(value) => this.update('references', value)}
              value={this.props.personalInfo.references}
              tabIndex='10'
              bottomDistance={100}
              hasModal={false}
              accept={onlyAlphaNumeric}
            />
          </Fragment>
        )}

        {!this.state.isMobile && (
          <Fragment>
            <div className={`row${this.state.errors.zip || this.state.errors.name ? ' error' : ''}`}>
              <Input
                className='field-zip'
                type='number'
                placeholder='Código postal'
                icon='icon-pin'
                update={(value, error) => this.update('zip', value, error)}
                pattern={validateZip.regexp}
                max={validateZip.maxlength}
                value={this.props.personalInfo.zip}
                tabIndex='7'
                bottomDistance={100}
                hasModal={false}
                accept={onlyAcceptNumbers}
                name='zip'
              />
              <Input
                className='field-address'
                placeholder='Dirección'
                icon='icon-pin'
                update={(value, error) => this.update('address', value, error)}
                value={this.props.personalInfo.address}
                tabIndex='4'
                bottomDistance={100}
                hasModal={false}
                min={validateAddress.minlength}
                name='address'
              />
            </div>
            <div className={`row${this.state.errors.exteriorNumber || this.state.errors.interiorNumber ? ' error' : ''}`}>
              <div className='address-number'>
                <Input
                  placeholder='No. Exterior'
                  update={(value, error) => this.update('exteriorNumber', value, error)}
                  value={this.props.personalInfo.exteriorNumber}
                  tabIndex='5'
                  type='text'
                  min={validateNumberStreet.minlength}
                  pattern={validateNumberStreet.regexp}
                  bottomDistance={100}
                  hasModal={false}
                  accept={onlyAcceptNumbers}
                  name='exterior_number'
                />
                <Input
                  placeholder='No. Interior'
                  suffix='Opcional'
                  update={(value, error) => this.update('interiorNumber', value, error)}
                  value={this.props.personalInfo.interiorNumber}
                  tabIndex='5'
                  type='text'
                  bottomDistance={100}
                  hasModal={false}
                  accept={onlyAcceptNumbersLetters}
                  name='interior_number'
                />
              </div>
              <Select
                className='field-suburbs'
                placeholder='Colonia'
                options={this.state.suburbs}
                keyLabel='name'
                value={this.props.personalInfo.suburbs}
                icon='icon-pin'
                onChange={(value) => this.update('suburbs', value)}
              />
            </div>

            <div className='row'>
              <Input className='field-state' placeholder='Estado' value={this.props.personalInfo.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
              <Input className='field-city' placeholder='Municipio' value={this.props.personalInfo.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
            </div>

            <div className='row'>
              <Input
                className='field-references'
                placeholder='Entre las calles'
                update={(value) => this.update('references', value)}
                value={this.props.personalInfo.references}
                tabIndex='10'
                bottomDistance={100}
                hasModal={false}
                accept={onlyAlphaNumeric}
                name='references'
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    personalInfo: state.purchase.changeAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAddress: (name, value) => dispatch({ type: 'PURCHASE_CHANGE_ADDRESS_GUEST', name, value }),
    getLocation: (zip) => dispatch(getLocationByZip(zip)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(FormChangeAddress);
