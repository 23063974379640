import React from 'react';
import { Link } from 'react-router-dom';

const BannerMerchant = () => {
  return (
    <section className='banner-merchant'>
      <div>
        <img src='/img/demo/banner-eres-comerciantes.jpg' alt='¿Eres comerciante?' />
        <div className='banner-merchant-info'>
          <h1>¿Eres comerciante?</h1>
          Obtén descuentos especiales en pedidos al mayoreo.<br />
          <Link className='btn btn-white btn-outline'>CONTÁCTANOS</Link>
        </div>
      </div>
    </section>
  );
};

export default BannerMerchant;
