import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getSector } from '../../helpers';
import useIsMobile from '../../hooks/useIsMobile';
import { setSector } from '../../store/actions/categories';
import MoneyFormat from '../MoneyFormat';
import { closeSession } from './../../store/actions/user';

const menuLinks = [
  {
    name: 'Mi perfil',
    icon: 'icon-login',
    to: '/perfil/datos-personales'
  },
  {
    name: 'Mis compras',
    icon: 'icon-box-open-1',
    to: '/perfil/mis-compras'
  },
  {
    name: 'Tutoriales',
    icon: 'icon-play',
    to: '/perfil/tutoriales'
  }, {
    name: 'Monedero',
    icon: 'icon-card-2',
    to: '/perfil/monedero'
  }
]

const Main = () => {
  const { logged, profile, open } = useSelector((state) => state.user)
  const { catalogs } = useSelector(state => state.home)
  const [categoriesArr, setCategoriesArr] = useState([])
  const isMobile = useIsMobile()
  const history = useHistory()
  const dispatch = useDispatch()

  const userOpenForm = (open) => {
    dispatch({ type: 'USER_OPEN', open })
  };
  // const goBack = () => dispatch({ type: 'USER_BACK' });

  const goLink = (event) => {
    if (!logged) {
      event.preventDefault();
      userOpenForm('login');
      return;
    }

    userOpenForm(false);
  };

  const handleCloseSession = () => dispatch(closeSession())

  useEffect(() => {
    if (catalogs.length) {
      const items = catalogs.map(category => ({ value: category.code, label: category.alt_name || category.name }))
      setCategoriesArr(items);
    }
  }, [catalogs])

  const sector = getSector();

  const handleSectors = (option) => {
    dispatch(setSector(option.value));
    userOpenForm(false);
  }

  const handleLogin = () => {
    userOpenForm('login')
  }

  return (
    open === 'user-menu' && (
      <div className={`user-navigation ${logged ? 'user-navigation--logged' : ''}`}>
        {!logged && isMobile && (
          <>
            <ul className="user-navigation-options">
              {categoriesArr && (
                categoriesArr.map((option, i) => {
                  return (
                    <li className={`user-options-item ${option.value === sector ? 'user-options-item--active' : ''}`}
                      onClick={() => handleSectors(option)}
                      key={i}
                    > {option.label} </li>
                  )
                })
              )}
              <li className="user-options-item">
                <Link onClick={(event) => goLink(event)} to='/usuario/historial-de-compras'> Historial de compra </Link>
              </li>
              <li className="user-options-item">
                <Link to="/tutoriales" onClick={() => userOpenForm(false)}>Tutoriales</Link>
              </li>
              {!logged && (
                <li className="user-options-item" onClick={handleLogin}> Iniciar sesión </li>
              )}
            </ul>
            <div className='register-option'>
              <p className="mxp-text">¿Aún no tienes una cuenta?</p>
              <span onClick={() => {
                userOpenForm(false)
                history.push('/box/registro')
              }}>REGÍSTRATE AQUÍ</span>
            </div>
          </>
        )}

        {logged && (
          <>
            <div className={`avatar${profile.avatar ? ' active' : ''}`}>
              {isMobile && <figure className="avatar-img">
                {logged && profile.avatar && <img src={profile.avatar} alt={profile.user_name} />}
              </figure>}
              <div className="avatar-data">
                <div className="avatar-data-desk">
                  <p className="mxp-txt avatar-data-hi">¡Hola!</p>
                  <p className="mxp-txt avatar-data-name">{profile.user_name}</p>
                </div>
                <p className="mxp-txt avatar-data-close" onClick={handleCloseSession}>Cerrar sesión</p>
              </div>
            </div>
            <ul className="user-options-logged">
              {menuLinks.map((item, i) => {
                if (item.name !== 'Monedero') {
                  return (
                    <li key={i} className="user-options-item">
                      <Link onClick={(event) => goLink(event)} to={item.to}>
                        <span className={`user-options-elm ${item.icon}`}>{item.name}</span>
                      </Link>
                    </li>
                  )
                } else {
                  return (
                    <li key={i} className="user-options-item user-options-item--money">
                      <Link onClick={(event) => goLink(event)} to={item.to}>
                        <span className="user-options-elm">
                          <span className={item.icon}></span>
                          <p>
                            <span>{item.name}</span>
                            <span className="user-options-elm-price">
                              <MoneyFormat type="mixed" money={124} />
                            </span>
                          </p>
                        </span>
                      </Link>
                    </li>
                  )
                }
              })}
              {categoriesArr && isMobile && (
                categoriesArr.map((option, i) => {
                  return (
                    <li className={`user-options-item--small user-options-item ${option.value === sector ? 'user-options-item--active' : ''}`}
                      onClick={() => handleSectors(option)}
                      key={i}
                    > {option.label} </li>
                  )
                })
              )}
            </ul>
          </>
        )}
      </div>
    )
  );
};

export default Main;
