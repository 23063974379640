import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useIsMobile from '../hooks/useIsMobile';
import usePathInfo from './../hooks/usePathInfo';

const Breadcrumbs = ({ title, className = '', onlyGoBack = false, productCategory, titleBtnBack, marginTop, basic }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const pathInfo = usePathInfo({ breadcrumbs: true, productCategory });

  const goBack = () => {
    if (history.length > 2) {
      return history.goBack();
    }
    history.go('/');
  };

  const backElement = () => (
    <ul id='breadcrumbs' className={classNames({ basic: basic, 'margin-top': marginTop }, className)}>
      <li className='btn-back' onClick={goBack}>
        <span className='icon-left-open'></span> {titleBtnBack || 'Regresar'}
      </li>
    </ul>
  );

  if (onlyGoBack) return backElement();

  if (!pathInfo?.breadcrumbs) return '';

  if ((pathInfo?.breadcrumb?.desktop === 'back' && !isMobile) || (pathInfo.breadcrumbs.mobile === 'back' && isMobile)) return backElement();

  if (isMobile && pathInfo.breadcrumbs.mobile) {
    return (
      <ul id='breadcrumbs' className={classNames({ basic: basic, 'margin-top': marginTop }, className)}>
        {pathInfo.breadcrumbs?.mobile?.map((breadcrumb, breadcrumbIndex) => {
          return (
            <li key={breadcrumbIndex}>
              <Link to={breadcrumb.path || breadcrumb}>
                {breadcrumbIndex === 0 ? <i className='icon-left-open'></i> : ''}
                {breadcrumb.title}
              </Link>
            </li>
          );
        })}
        {!pathInfo.info.breadcrumb_remove_title_mobile && (
          <li>
            <span>{title || pathInfo.info.title}</span>
          </li>
        )}
      </ul>
    );
  }

  return (
    <ul id='breadcrumbs' className={classNames({ basic: basic, 'margin-top': marginTop }, className)}>
      {pathInfo.breadcrumbs?.desktop?.map((breadcrumb, breadcrumbIndex) => {
        if (!breadcrumb) return '';
        return (
          <li key={breadcrumbIndex}>
            <Link to={breadcrumb.path || breadcrumb}>
              {breadcrumbIndex === 0 ? <i className='icon-left-open'></i> : ''}
              {breadcrumb.title}
            </Link>
          </li>
        );
      })}
      {!pathInfo.info.breadcrumb_remove_title && (
        <li>
          <span>{title || pathInfo.info.title}</span>
        </li>
      )}
    </ul>
  );
};

export default React.memo(Breadcrumbs);
