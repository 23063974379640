import React from 'react';
import PerfectScroll from './PerfectScroll';

class AdvancedModal extends React.Component {
  static Header = 'Header';
  static Body = 'Body';
  static Footer = 'Footer';

  componentDidMount() {
    this.position = window.pageYOffset;
    this.bodyElement = document.querySelector('body');
    this.bodyElement.classList.add('no-scroll');
    window.addEventListener('click', (event) => {
      const { target } = event;
      if (target === this.el) {
        this.close();
      }
    });
  }

  componentWillUnmount() {
    this.bodyElement.classList.remove('no-scroll');
    if(this.props.scrollAfterClose){
      window.scroll(0, this.position);
    }
  }

  close = () => (this.props.close ? this.props.close() : false);

  render() {
    if (!this.props.children.length)
      return (
        <div className={`advanced-modal ${this.props.className || ''}`} ref={(el) => (this.el = el)}>
          <div className='advanced-modal-container'>
            <span onClick={this.close} className='close'>
              <i className='icon-close'></i>
            </span>
            <PerfectScroll scrollYMarginOffset={this.props.scrollYMarginOffset || 0}>{this.props.children.props.children}</PerfectScroll>
          </div>
        </div>
      );

    const header = this.props.children.find((child) => child.type === 'Header');
    const body = this.props.children.find((child) => child.type === 'Body');
    const footer = this.props.children.find((child) => child.type === 'Footer');

    if (!body) return '';

    return (
      <section className={`advanced-modal ${this.props.className || ''}${!!footer ? ' with-footer' : ''}`} ref={(el) => (this.el = el)}>
        <div className='advanced-modal-container'>
          <span className='close' onClick={this.close}>
            <i className='icon-close'></i>
          </span>
          {!!header && <div className='header'>{header.props.children}</div>}
          <PerfectScroll scrollYMarginOffset={this.props.scrollYMarginOffset || 30}>{body.props.children}</PerfectScroll>
          {!!footer && <div className='footer'>{footer.props.children}</div>}
        </div>
      </section>
    );
  }
}

AdvancedModal.defaultProps = {
  scrollAfterClose: true,
}

export default AdvancedModal;
