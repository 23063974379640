import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  onlyAcceptEmail, onlyAcceptName,


  onlyAcceptNumbers,
  onlyAcceptNumbersLetters, onlyAcceptPhone,




  onlyAlphaNumeric, validateAddress, validateEmail,
  validateName,
  validateNumberStreet,
  validatePhone,
  validateZip
} from '../../../helpers';
import inputScroll from '../../../helpers/input-auto-scroll-viewport';
import { getLocationByZip } from '../../../store/actions/cart';
import { enableNext, getShippingCost } from '../../../store/actions/purchase';
import Select from '../../FormElements/Select';
import Input from '../../Input';
import PackagesRates from '../PackagesRates';
import ChangeDeliveryType from '../ChangeDeliveryType';

class PersonalInformation extends React.Component {
  state = {
    suburbs: [],
    enableNext: false,
  };

  getLocation = (value, error) => {
    if (value && value.length === 5 && !error) {
      this.props.getLocation(value);
    }
  };

  update = async (name, value) => {
    if (name === 'zip' && value !== this.props.personalInfo.zip) {
      this.props.updateField('suburbs', false);
      this.props.updateField('city', false);
      this.props.updateField('state', false);
      this.setState({ suburbs: [] });
      this.props.updateField(name, value);
      if (value.length === 5) {
        const location = await this.props.getLocation(value);
        if (location.state) {
          this.props.updateField('city', location.city);
          this.props.updateField('state', location.state);
          return this.setState({ suburbs: location.suburbs });
        }
      }
    }

    this.props.updateField(name, value);
  };

  nextStep = () => {
    if(this.props.officeID){
      return this.props.nextStep();
    }
    if (this.props.enableNext()) {
      this.props.getShippingCost(this.props.orderID, this.props.personalInfo.zip);
    }
  };

  componentDidMount() {
    const inputsModal = document.querySelectorAll('#purchase-user-guest input');
    inputsModal.forEach((input) => {
      input.addEventListener('focus', (event) => {
        inputScroll(event.currentTarget, false, 120, 500);
      });
    });
  }

  componentDidUpdate() {
    const inputsModal = document.querySelectorAll('#purchase-user-guest input');
    inputsModal.forEach((input) => {
      input.removeEventListener('focus', (event) => {
        inputScroll(event.currentTarget, false, 120, 500);
      });
      input.addEventListener('focus', (event) => {
        inputScroll(event.currentTarget, false, 120, 500);
      });
    });
  }

  render() {
    return (
      <Fragment>
        <ChangeDeliveryType />
        <div className='personal-info'>
          <Input
            className='field-name'
            placeholder='Nombre completo'
            icon='icon-login'
            update={(value) => this.update('name', value)}
            value={this.props.personalInfo.name}
            tabIndex='1'
            min={validateName.minlength}
            max={validateName.maxlength}
            pattern={validateName.regexp}
            bottomDistance={100}
            hasModal={false}
            accept={onlyAcceptName}
            name='name'
            minWords={validateName.minWords}
          />
          <Input
            className='field-email'
            placeholder='Correo electrónico'
            icon='icon-mail'
            update={(value) => this.update('email', value)}
            pattern={validateEmail}
            value={this.props.personalInfo.email}
            tabIndex='2'
            bottomDistance={100}
            hasModal={false}
            accept={onlyAcceptEmail}
            name='email'
            inputMode="email"
          />
          <Input
            className='field-phone'
            placeholder='Teléfono'
            icon='icon-phone'
            update={(value) => this.update('phone', value)}
            type='phone'
            value={this.props.personalInfo.phone}
            min={validatePhone.minlength}
            max={validatePhone.maxlength}
            pattern={validatePhone.regexp}
            tabIndex='3'
            bottomDistance={100}
            hasModal={false}
            accept={onlyAcceptPhone}
            name='phone'
            inputMode="numeric"
          />
<Input
            className='field-address'
            placeholder='Dirección'
            icon='icon-pin'
            update={(value) => this.update('address', value)}
            value={this.props.personalInfo.address}
            tabIndex='4'
            bottomDistance={100}
            hasModal={false}
            min={validateAddress.minlength}
            name='address'
          />
          <div className='address-number'>
            <Input
              placeholder='No. Exterior'
              update={(value) => this.update('exteriorNumber', value)}
              value={this.props.personalInfo.exteriorNumber}
              tabIndex='5'
              type='text'
              min={validateNumberStreet.minlength}
              pattern={validateNumberStreet.regexp}
              bottomDistance={100}
              hasModal={false}
              accept={onlyAcceptNumbers}
              name='exterior_number'
            />
            <Input
              placeholder='No. Interior'
              suffix='Opcional'
              update={(value) => this.update('interiorNumber', value)}
              value={this.props.personalInfo.interiorNumber}
              tabIndex='5'
              type='text'
              bottomDistance={100}
              hasModal={false}
              accept={onlyAcceptNumbersLetters}
              name='interior_number'
            />
          </div>
          <Input
            className='field-zip'
            type='number'
            placeholder='Código postal'
            icon='icon-pin'
            update={(value) => this.update('zip', value)}
            pattern={validateZip.regexp}
            max={validateZip.maxlength}
            value={this.props.personalInfo.zip}
            tabIndex='7'
            bottomDistance={100}
            hasModal={false}
            accept={onlyAcceptNumbers}
            name='zip'
            inputMode="numeric"
          />

          <Select
            className='field-suburbs'
            placeholder='Colonia'
            options={this.state.suburbs}
            keyLabel='name'
            value={this.props.personalInfo.suburbs}
            icon='icon-pin'
            onChange={(value) => this.update('suburbs', value)}
          />

          <Input className='field-state' placeholder='Estado' value={this.props.personalInfo.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
          <Input className='field-city' placeholder='Municipio' value={this.props.personalInfo.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={100} hasModal={false} />
          <Input
            className='field-references'
            placeholder='Entre las calles'
            update={(value) => this.update('references', value)}
            value={this.props.personalInfo.references}
            tabIndex='10'
            bottomDistance={100}
            hasModal={false}
            accept={onlyAlphaNumeric}
            name='references'
          />
          <div className='btn-next'>
            <span className={`btn btn-primary rounded inline${this.props.enableNext() ? '' : ' disabled'}${this.props.loader ? ' btn-loader' : ''}`} onClick={() => this.nextStep()}>
            {this.props.officeID ? 'SIGUIENTE' : 'COTIZAR ENVÍO'}
            </span>
          </div>
        </div>
        <PackagesRates close={() => {}} scrollAfterClose={false} />
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    orderID: state.cart.orderID,
    personalInfo: state.purchase.personalInfo,
    officeID: state.purchase.officeID,
    loader: state.purchase.loader || state.purchase.loaderRates,
    deliveryType: state.purchase.deliveryType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: (zip) => dispatch(getLocationByZip(zip)),
    updateField: (field, value) => dispatch({ type: 'PURCHASE_UPDATE_PERSONAL_INFO', field, value }),
    enableNext: () => dispatch(enableNext()),
    nextStep: () => dispatch({ type: 'PURCHASE_STEP' }),
    getShippingCost: (orderID, zip) => dispatch(getShippingCost(orderID, zip)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(PersonalInformation);
