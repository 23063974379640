import $ from 'jquery';
import 'slick-carousel'
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { imageUrl } from '../../helpers/index';
import classNames from 'classnames';
import AnimationLastAdded from './../Product/AnimationLastAdded';
import { useSelector } from 'react-redux';

const SliderHeader = ({ images }) => {
  const {hide_last} = useSelector(state => state.cart)

  const imagesFiltered = images.filter((item) => item.code === 'slider');

  const imagesContainer = useRef(null);
  const thumbsContainer = useRef(null);

  useEffect(() => {
    let mainSlider = false;
    let sliderThumbnails = false;
    if (imagesContainer.current) {
      mainSlider = $(imagesContainer.current);
      mainSlider.slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        nextArrow: false,
        prevArrow: false,
        asNavFor: thumbsContainer.current,
        infinite: false,
        lazyLoad: 'ondemand',
      });
    }
    if (thumbsContainer.current) {
      sliderThumbnails = $(thumbsContainer.current);

      sliderThumbnails.slick({
        slidesToScroll: 1,
        slidesToShow: 4,
        prevArrow: '<i class="prev icon-left-open"></i>',
        nextArrow: '<i class="next icon-right-open"></i>',
        asNavFor: imagesContainer.current,
        infinite: false,
        centerPadding: 0,
        lazyLoad: 'ondemand',
        variableWidth: true,
        draggable: false
      });

      sliderThumbnails.on('afterChange', (event, slick, currentSlide) => {
        if (currentSlide > 0) {
          sliderThumbnails.removeClass('disable-prev');
        } else {
          sliderThumbnails.addClass('disable-prev');
        }
        if (currentSlide < imagesFiltered.length - 1) {
          sliderThumbnails.removeClass('disable-next');
        } else {
          sliderThumbnails.addClass('disable-next');
        }
      });
    }

    return () => {
      if(mainSlider){
        mainSlider.slick('unslick');
      }
      if(sliderThumbnails){
        sliderThumbnails.slick('unslick');
      }
    };
  }, [imagesFiltered]);

  const changeSlide = (index) => {
    $(imagesContainer.current).slick('slickGoTo', index);
  };

  if (!imagesFiltered.length)
    return (
      <div className='slider-img-zoom'>
        <img src={imageUrl(false)} alt='N/A' />
      </div>
    );

  return (
    <div id='slider-header' className={classNames({
      animation: !hide_last
    })}>
      <div ref={imagesContainer} className='slider-images'>
        {imagesFiltered.map((image, imageIndex) => (
          <div key={imageIndex}>
            <img className='image-zoom' src={imageUrl(false)} data-lazy={image.sizes.medium} alt='' />
          </div>
        ))}
      </div>
      <div ref={thumbsContainer} className='slider-thumbs disable-prev'>
        {imagesFiltered.map((image, imageIndex) => (
          <div key={imageIndex} className='thumb' onClick={() => changeSlide(imageIndex)}>
            <img src={imageUrl(false)} data-lazy={image.sizes.medium} alt='' />
          </div>
        ))}
      </div>
      <AnimationLastAdded />
    </div>
  );
};

SliderHeader.propTypes = {
  images: PropTypes.array.isRequired,
};

export default React.memo(SliderHeader);
