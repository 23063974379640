import React from 'react'
import dragSlider from '../helpers/drag-slider';

class Carousel extends React.Component{
  componentDidMount(){
    this.$el = new dragSlider(this.el, 1.5, this.props.gap || 10, this.props.onDragStart);
  }

  componentDidUpdate(){
    this.$el = new dragSlider(this.el, 1.5, this.props.gap || 10, this.props.onDragStart);
  }

  shouldComponentUpdate(nextProps){
    return nextProps.children !== this.props.children;
  }

  disableArrows = () => {

  }

  nextItems = () => {
    this.$el.next();
  }

  prevItems = () => {
    this.$el.prev();
  }

  render() {
    return (<div className={`own-carousel ${this.props.className ? ' ' + this.props.className : ''}${this.props.hasArrows ? ' has-arrows' : '' }` }>
      {this.props.hasArrows && <div className="prev" onClick={this.prevItems}><i className="icon-left-open"></i></div>}
      <div ref={(el) => this.el = el}>
        {this.props.children}
      </div>
      {this.props.hasArrows && <div className="next" onClick={this.nextItems}><i className="icon-right-open"></i></div>}
    </div>)
  }
}

export default React.memo(Carousel)
