import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { clearPackageError } from '../../store/actions/purchase';

const ShippingPackagesNotFound = () => {
  const dispatch = useDispatch();

  const handleClearError = () => dispatch(clearPackageError())

  return (
    <div id="shipping-packages-not-found">
      <i className='icon-alert'></i>
      <p>Lo sentimos, no hay paqueterías disponibles para la dirección indicada.</p>
      <p>Por favor, elige otra dirección o puedes recoger tu pedido en una de nuestras sucursales.</p>
      <Link className="btn btn-primary rounded" to="/carrito/datos-generales" onClick={handleClearError}>REGRESAR</Link>
    </div>
  );
};

export default ShippingPackagesNotFound;
