const inputScroll = (input, container = false, distance = 30) => {
  const inputContainer = container ? document.querySelector(container) : window;
  const scroll = inputContainer.scrollTop || inputContainer.scrollY || 0;

  const visibleArea = (inputContainer.innerHeight || inputContainer.offsetHeight) + (inputContainer.offsetTop || 0);

  const inputData = input.getBoundingClientRect();
  const inputPosition = inputData.top + inputData.height + distance;

  if (inputPosition > visibleArea) {
    const scrollTo = inputPosition - visibleArea + scroll;

    if (inputContainer.scrollTop) {
      inputContainer.scrollTop = scrollTo;
    } else {
      inputContainer.scroll({ top: scrollTo, behavior: 'smooth' });
    }
  }
};

const moveInput = (input, container = false, distance = 30, animation = 500) => {
  if(!input) return false;

  if(animation){
    setTimeout(() => {
      inputScroll(input, container, distance);
    }, animation);
  } else {
    inputScroll(input, container, distance);
  }
};

export default moveInput;
