import axiosApi from '../axiosAPI';
import { types } from '../reducers/compare';

export const getProductCompare = (productID) => async (dispatch) => {
  dispatch({ type: types.COMPARE_LOADER, loader: true });
  const product = await axiosApi.get('v1/products/' + productID + '/compare');
  if (!!product.data.compare) {
    dispatch({ type: types.COMPARE_ADD_DATA, data: product.data });
  } else {
    dispatch({ type: types.COMPARE_LOADER, loader: false });
  }
};

export const selectProduct = (product_id) => (dispatch) => dispatch({ type: types.COMPARE_SELECT_ITEM, product_id });
