export const categoriesAnim = {
    layout: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: -50
        },
    }
}