import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerUser } from '../../store/actions/user';
import InputImage from '../FormElements/InputImage';
import PerfectScroll from '../PerfectScroll';
import { validateEmail, validatePassword, validateName } from '../../helpers';
import { isMobile } from '../../helpers';
import Input from '../Input';
import Modal from '../Modal';
import moveInput from '../../helpers/input-auto-scroll-viewport';
import UserConfirm from './UserConfirm';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: false,
      email: false,
      password: false,
      password_confirm: false,
      photo: '',
      error: false,
      isMobile: isMobile(),
      openConfirmationClose: false,
      confirmationClose: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
    const inputsModal = document.querySelectorAll('#user-options-menu .ps input');
    inputsModal.forEach((input) => {
      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, '#user-options-menu .ps', 30, 500);
      });
    });
  }

  update = (field, value, error = false) => {
    const state = { ...this.state };
    state[field] = value;
    this.setState({ ...state, error });
  };

  setPhoto = (photo) => {
    this.setState({ photo });
  };

  registerUser = (event) => {
    event.preventDefault();
    if (this.state.error) return;

    const photo = this.state.photo ? this.state.photo.replace('data:image/jpeg;base64,', '') : '';

    this.props.registration({
      name: this.state.name.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      image: photo,
    });
    this.setState({ register: true });
  };

  resetForm = () => {
    this.setState({
      name: '',
      email: '',
      password: '',
      password_confirm: '',
      photo: false,
      error: {},
      openConfirmationClose: false,
      confirmClose: false,
    });
  };

  clear = () => {
    this.props.registerClear();
    this.resetForm();
  };

  close = (cancel = false) => {
    if (this.props.open === 'user-confirm') {
      return (window.location.href = '/categorias');
    }
    const body = document.querySelector('body');
    if (this.props.registerResponse || cancel) {
      body.classList.remove('no-scroll');
      this.clear();
      this.props.userOpenForm('');
      return;
    }
    this.setState({ openConfirmationClose: true });
  };

  confirmClose = (confirm = false) => {
    if (confirm) {
      return this.close(true);
    }
    this.setState({ openConfirmationClose: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.registerResponse !== this.props.registerResponse) {
      const scrollContainer = document.querySelector('#register-form .perfect-scroll .ps');
      if (scrollContainer) {
        scrollContainer.scroll(0, 0);
      }
    }
    if (prevProps.open !== this.props.open) {
      this.resetForm();
    }
    const inputsModal = document.querySelectorAll('#register-form .ps input');
    inputsModal.forEach((input) => {
      input.removeEventListener('focus', (event) => {
        moveInput(event.currentTarget, '#register-form .ps', 30, 500);
      });
      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, '#register-form .ps', 30, 500);
      });
    });
  }

  render() {
    return (
      <Fragment>
        <div id='register-form' className={`${this.props.open} ${['register', 'user-confirm'].includes(this.props.open) ? 'active' : ''}${this.props.cropper ? ' cropper' : ''}`}>
          <div>
            <span className='go-back' onClick={() => this.props.openBack()}>
              <i className='icon-left-open'></i> Regresar
            </span>
            <span className='icon-close' onClick={() => this.close()}></span>
            <h1>Registro</h1>
            <PerfectScroll className={`${this.props.open === 'register' ? 'active' : ''}`}>
              {this.props.open === 'register' && !this.props.registerResponse.status_code && (
                <form action='#' onSubmit={(event) => this.registerUser(event)} autoComplete='off'>
                  <InputImage save={(photo) => this.setPhoto(photo)} isMobile={this.state.isMobile} close={() => this.close()} />
                  {(!this.props.cropper || this.state.isMobile) && (
                    <Fragment>
                      <Input
                        placeholder='Nombre completo'
                        min={validateName.minlength}
                        max={validateName.maxlength}
                        pattern={validateName.regexp}
                        icon='icon-login'
                        update={(value, error) => this.update('name', value, error)}
                        value={this.state.name}
                        name='name'
                      />

                      <Input
                        placeholder='Correo electrónico'
                        pattern={validateEmail}
                        icon='icon-mail'
                        update={(value, error) => this.update('email', value, error)}
                        value={this.state.email}
                        inputMode='email'
                        autoCapitalize='off'
                        name='email'
                      />

                      <Input
                        type='password'
                        placeholder='Contraseña'
                        min={validatePassword.minlength}
                        max={validatePassword.maxlength}
                        pattern={validatePassword.regexp}
                        icon='icon-key'
                        update={(value, error) => this.update('password', value, error)}
                        value={this.state.password}
                        name='password'
                      />
                      <Input
                        type='password'
                        placeholder='Confirmar contraseña'
                        compare={{ value: this.state.password, label: '*La contraseña no coincide.' }}
                        icon='icon-key'
                        update={(value, error) => this.update('password_confirm', value, error)}
                        value={this.state.password_confirm}
                        name='password_confirm'
                      />

                      <button
                        className={`btn btn-primary rounded${this.props.loader ? ' btn-loader' : ''}`}
                        disabled={this.state.error || !this.state.name || !this.state.email || !this.state.password || !this.state.password_confirm}>
                        Crear cuenta
                      </button>
                    </Fragment>
                  )}
                </form>
              )}

              {this.props.registerResponse.status_code === 202 && (
                <div className='confirm-register'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='100' height='135' viewBox='0 0 100 135'>
                    <g fill='none' fillRule='evenodd'>
                      <g fillRule='nonzero'>
                        <g>
                          <path
                            fill='#BB9292'
                            fillOpacity='.348'
                            d='M88.663 0H11.337C5.078.006.007 5.078 0 11.337v112.21c.006 6.258 5.078 11.33 11.337 11.337h77.326c6.259-.007 11.33-5.079 11.337-11.337V11.337C99.994 5.078 94.922.007 88.663 0z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                          <path
                            fill='#BB9292'
                            fillOpacity='.348'
                            d='M73.256 92.744H43.302c-1.926 0-3.488 1.562-3.488 3.489 0 1.926 1.562 3.488 3.488 3.488h29.954c1.926 0 3.488-1.562 3.488-3.488 0-1.927-1.562-3.489-3.488-3.489zM73.256 107.512H43.302c-1.246 0-2.398.665-3.02 1.744-.624 1.08-.624 2.409 0 3.488.622 1.08 1.774 1.744 3.02 1.744h29.954c1.926 0 3.488-1.561 3.488-3.488s-1.562-3.488-3.488-3.488zM73.256 77.907H43.302c-1.926 0-3.488 1.562-3.488 3.488 0 1.927 1.562 3.489 3.488 3.489h29.954c1.926 0 3.488-1.562 3.488-3.489 0-1.926-1.562-3.488-3.488-3.488zM30.233 77.907c-1.927 0-3.489 1.562-3.489 3.488 0 1.927 1.562 3.489 3.489 3.489 1.926 0 3.488-1.562 3.488-3.489 0-1.926-1.562-3.488-3.488-3.488zM30.233 92.744c-1.927 0-3.489 1.562-3.489 3.489 0 1.926 1.562 3.488 3.489 3.488 1.926 0 3.488-1.562 3.488-3.488 0-1.927-1.562-3.489-3.488-3.489zM30.233 107.512c-1.927 0-3.489 1.561-3.489 3.488s1.562 3.488 3.489 3.488c1.926 0 3.488-1.561 3.488-3.488s-1.562-3.488-3.488-3.488z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                          <path
                            fill='#FFF'
                            d='M43.15 57c.957 0 1.87-.398 2.515-1.096L70.1 29.647c.825-.886 1.109-2.14.743-3.288-.365-1.148-1.324-2.016-2.515-2.278-1.19-.262-2.432.123-3.257 1.009L43.277 48.51l-8.159-9.7c-.76-.98-2.004-1.469-3.239-1.277-1.235.193-2.265 1.037-2.683 2.2-.418 1.162-.157 2.457.679 3.374L40.518 55.78c.632.748 1.558 1.193 2.544 1.221h.088z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <h2>{this.props.registerResponse.title}</h2>
                  <p>{this.props.registerResponse.message}</p>
                  <Link onClick={() => this.close()} to='/categorias' className='btn btn-primary rounded'>
                    IR A CATÁLOGO
                  </Link>
                </div>
              )}

              {[200, 3, 203].includes(this.props.registerResponse.status_code) && (
                <div className='error-register'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='100' height='135' viewBox='0 0 100 135'>
                    <g fill='none' fillRule='evenodd'>
                      <g fillRule='nonzero'>
                        <g>
                          <path
                            fill='#BB9292'
                            fillOpacity='.353'
                            d='M88.663 0H11.337C5.078.006.007 5.078 0 11.337v112.21c.006 6.258 5.078 11.33 11.337 11.337h77.326c6.259-.007 11.33-5.079 11.337-11.337V11.337C99.994 5.078 94.922.007 88.663 0z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                          <path
                            fill='#BB9292'
                            fillOpacity='.353'
                            d='M73.256 92.744H43.302c-1.926 0-3.488 1.562-3.488 3.489 0 1.926 1.562 3.488 3.488 3.488h29.954c1.926 0 3.488-1.562 3.488-3.488 0-1.927-1.562-3.489-3.488-3.489zM73.256 107.512H43.302c-1.246 0-2.398.665-3.02 1.744-.624 1.08-.624 2.409 0 3.488.622 1.08 1.774 1.744 3.02 1.744h29.954c1.926 0 3.488-1.561 3.488-3.488s-1.562-3.488-3.488-3.488zM73.256 77.907H43.302c-1.926 0-3.488 1.562-3.488 3.488 0 1.927 1.562 3.489 3.488 3.489h29.954c1.926 0 3.488-1.562 3.488-3.489 0-1.926-1.562-3.488-3.488-3.488zM30.233 77.907c-1.927 0-3.489 1.562-3.489 3.488 0 1.927 1.562 3.489 3.489 3.489 1.926 0 3.488-1.562 3.488-3.489 0-1.926-1.562-3.488-3.488-3.488zM30.233 92.744c-1.927 0-3.489 1.562-3.489 3.489 0 1.926 1.562 3.488 3.489 3.488 1.926 0 3.488-1.562 3.488-3.488 0-1.927-1.562-3.489-3.488-3.489zM30.233 107.512c-1.927 0-3.489 1.561-3.489 3.488s1.562 3.488 3.489 3.488c1.926 0 3.488-1.561 3.488-3.488s-1.562-3.488-3.488-3.488z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                          <path
                            fill='#FFF'
                            d='M60.178 55.99c1.322 1.337 3.477 1.348 4.813.024 1.336-1.324 1.347-3.482.024-4.82l-10.178-10.19 10.14-10.199c1.322-1.337 1.311-3.495-.025-4.819-1.335-1.324-3.49-1.313-4.813.024L50 36.21l-10.178-10.2c-1.322-1.337-3.477-1.348-4.813-.024-1.336 1.324-1.347 3.482-.024 4.819l10.226 10.2-10.188 10.19c-.855.865-1.184 2.121-.864 3.296.32 1.174 1.243 2.088 2.419 2.397 1.176.31 2.427-.033 3.283-.898L50 45.8l10.178 10.19z'
                            transform='translate(-157 -316) translate(157 316)'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <h2>Lo sentimos</h2>
                  <p>{this.props.registerResponse.message}</p>
                  <span className='btn btn-primary rounded' onClick={() => this.clear()}>
                    REGRESAR
                  </span>
                </div>
              )}

              {this.props.open === 'user-confirm' && <UserConfirm />}
            </PerfectScroll>
          </div>
        </div>

        {this.state.openConfirmationClose && (
          <Modal className='alert-close-confirmation' open={this.state.openConfirmationClose} close={() => this.confirmClose()} width={320} height={460}>
            <h1>¡Espera!</h1>
            <p>
              Si regresas ahora todo tu progreso
              <br />
              se perderá.
            </p>
            <p>¿Deseas abandonar tu registro?</p>
            <span className='btn btn-primary rounded' onClick={() => this.confirmClose(true)}>
              ABANDONAR
            </span>
            <span className='btn btn-primary rounded' onClick={() => this.confirmClose()}>
              CONTINUAR
            </span>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    registerResponse: state.user.registerResponse,
    open: state.user.open,
    cropper: state.user.cropper,
    loader: state.user.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registration: (user) => dispatch(registerUser(user)),
    registerClear: () => dispatch({ type: 'REGISTER_CLEAR' }),
    userOpenForm: (open) => dispatch({ type: 'USER_OPEN', open }),
    openBack: () => dispatch({ type: 'USER_BACK' }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(RegisterForm);
