import React from 'react';
import { connect } from 'react-redux';
import { updateFavorites } from '../store/actions/favorites';
import PropTypes from 'prop-types'

class BtnAddFavorite extends React.Component {
  state = {
    fakeActive: false,
  };

  updateFavorite = () => {
    this.setState({ fakeActive: true });
    this.props.updateFavorite(this.props.id);
  };

  isFavorite = () => {
    const isFav = this.props.products ? this.props.products : false
    const favID = isFav ? isFav.filter(item => item.id === this.props.id)[0] : false

    if (favID !== undefined) {
      return this.props.id === favID.id
    }
  };

  render() {
    const is_favorite = this.state.fakeActive || this.isFavorite()
    return <span
      className={`favorite add ${is_favorite ? 'icon-heart-1' : 'icon-heart-1-empty'} ${this.props.loader ? 'favorite--loading' : ''}`}
      onClick={this.updateFavorite}></span>;
  }
}

BtnAddFavorite.propTypes = {
  id: PropTypes.number.isRequired
}

const mapStateProps = (state) => {
  return {
    favorites: state.favorites.favorites,
    products: state.favorites.products,
    loader: state.favorites.favorites_loader
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFavorite: (id) => dispatch(updateFavorites(id)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(BtnAddFavorite);
