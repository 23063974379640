import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateCard, validateName } from '../../helpers/validations';
import useCard from '../../hooks/useCard';
import { generateToken } from '../../store/actions/purchase';
import FormInput from '../FormElements/FormInput';
import useIsMobile from './../../hooks/useIsMobile';
import AdvancedModal from './../AdvancedModal';
import FakeSelect from './../FormElements/FakeSelect';
import InputCheckbox from './../FormElements/InputCheckbox';

const AddNewCard = ({ close }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const { tokenCard, loader, tokenizer_error } = useSelector((state) => state.purchase);

  useEffect(() => {
    if (tokenCard) {
      history.push('/carrito/realizar-pago');
    }
  }, [history, tokenCard]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const handleSave = (check) => {
    setValue('save', check);
  };

  const { name, number, cvc, exp_month, exp_year, save } = watch();

  const cardType = useCard(number);

  const submitForm = (data) => {
    const card = { ...data };
    delete card.save;
    dispatch(generateToken(card, data.save));
  };

  const enableSubmit = () => !Object.keys(errors).length && name && number && cvc && exp_month && exp_year;

  return (
    <form className='form-payment-card' onSubmit={handleSubmit(submitForm)}>
      <AdvancedModal className='format-2' close={close}>
        <AdvancedModal.Header>Nueva tarjeta</AdvancedModal.Header>
        <AdvancedModal.Body>
          <FormInput name='name' label='Nombre del tarjetahabiente*' placeholder='Jorge Eduardo Hernández Pérez' register={register} options={validateName} error={errors.name} setValue={setValue}
          validate="name" />
          <FormInput name='number' label='Número de la tarjeta*' placeholder='16 dígitos' register={register} options={validateCard} error={errors.number} type='numeric' setValue={setValue}
          validate="number" />
          <FakeSelect name='exp_month' options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} label='Mes*' placeholder='Selecciona' value={exp_month} onChange={setValue} id="add-new-card-exp-month" />
          <FakeSelect
            name='exp_year'
            options={[2021, 2022, 2023, 2024, 2025, 2026, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035]}
            label='Año*'
            placeholder='Selecciona'
            value={exp_year}
            onChange={setValue}
            id="add-new-card-exp-year"
          />
          <FormInput name='cvc' label='CVV*' placeholder={cardType.cvv.placeholder} register={register} options={cardType.cvv.options} error={errors.cvc} type='numeric' setValue={setValue}
          validate="number" />
          {tokenizer_error && !isMobile && <div className='error-card'>{tokenizer_error}</div>}
          {!isMobile ? (
            <div className='save'>
              <InputCheckbox className='isMain' item={{ value: 1, label: '¿Deseas guardar esta tarjeta?' }} onChange={handleSave} selected={save || 0} />
              <button className={`btn btn-primary${enableSubmit() && !loader ? '' : ' disabled'}`}>CONTINUAR</button>
            </div>
          ) : (
            <InputCheckbox className='isMain' item={{ value: 1, label: '¿Deseas guardar esta tarjeta?' }} onChange={handleSave} selected={save || 0} />
          )}
        </AdvancedModal.Body>
        {isMobile && (
          <AdvancedModal.Footer>
            <button className={`btn btn-primary${enableSubmit() && !loader ? '' : ' disabled'}`}>CONTINUAR</button>
            {tokenizer_error && isMobile && <div className='error-card'>{tokenizer_error}</div>}
          </AdvancedModal.Footer>
        )}
      </AdvancedModal>
    </form>
  );
};

export default AddNewCard;
