import classNames from 'classnames';
import React from 'react';
import AppButton from './Buttons';
import Pantone from './Pantone';

const Color = ({ color, quantity, onChange, stock }) => {
  const handleUpdate = (value, replace = false) => {
    if (!onChange) return;
    const newQuantity = replace ? value : quantity + value;

    if (newQuantity < 1) {
      onChange({ ...color, quantity: 0 });
    } else if (newQuantity > stock) {
      onChange({ ...color, quantity: stock });
    } else {
      onChange({ ...color, quantity: newQuantity });
    }
  };

  const handleChange = (event) => {
    if (!onChange) return;
    const value = event.currentTarget.value.replace(/[^0-9]/g, '');
    handleUpdate(parseInt(value), true);
  };

  return (
    <div className='color-item'>
      <div className='color-item-info'>
        <Pantone hex={color.colors} />
        <p className='mxp-txt'>{color.name}</p>
      </div>
      <div className='color-item-quantity'>
        <div className='color-quantity'>
          <AppButton type='icon' icon='minus-circle' modifier='circle' available={quantity > 0} customClick={() => handleUpdate(-1)} />
          <input
            type='text'
            className={classNames({
              'color-quantity-input': true,
              'color-quantity-input--empty': quantity < 1,
              'color-quantity-input--used': quantity > 0,
            })}
            value={quantity}
            onChange={handleChange}
          />
          <AppButton type='icon' icon='plus-circle' modifier='circle' customClick={() => handleUpdate(1)} available={quantity < stock} />
        </div>
      </div>
    </div>
  );
};

export default Color;
