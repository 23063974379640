import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import { getProducts } from '../../../store/actions/products';
import ModalBox from '../../UI/ModalBox';
import { setApply, setRange } from './../../../store/actions/categories';
import { applyFilters } from './../../../store/actions/filters';
import CategoryFilters from './CategoryFilters';
import CategoryFiltersMobile from './CategoryFiltersMobile';
import CategoryProducts from './CategoryProducts';
import CategoryTags from './CategoryTags';
import CurrentFilters from './CurrentFilters';

const CategoriesFlow = () => {
  const isMobile = useIsMobile();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { params } = match;
  const { categoryID } = params;
  const { showFilters } = useSelector((state) => state.categories);
  const filters = useSelector((state) => state.filters);
  const { active } = filters;
  const { range_prices } = filters.category; // Filtros disponibles

  useEffect(() => {
    dispatch(setRange(false));
    dispatch(getProducts(categoryID, { reloadFilters: true, removeFilters: true }));
  }, [dispatch, categoryID]);

  const updateProducts = useCallback(() => {
    dispatch(getProducts(categoryID, {}));
  }, [dispatch, categoryID]);

  useEffect(() => {
    updateProducts();
  }, [dispatch, active, updateProducts]);

  const handleFinalMove = useCallback(
    (values) => {
      const min = Math.round(values[0]);
      const max = Math.floor(values[1]);
      let range = {
        min: min >= range_prices.min ? min : Math.round(range_prices.min),
        max: max <= range_prices.max ? max : Math.floor(range_prices.max),
        name: `$${min} a $${max}`,
      };
      if (range.min > range.max || range.min < range_prices.min || range.min > range_prices.max) {
        range.min = Math.round(range_prices.min);
      }
      if (range.max < range.min || range.max < range_prices.min || range.max > range_prices.max) {
        range.max = Math.floor(range_prices.max);
      }
      dispatch(setRange(range));
      dispatch(setApply(true));
      dispatch(applyFilters('price', range));
    },
    [dispatch, range_prices],
  );

  useEffect(() => {
    if (!active.prices || typeof range_prices === 'undefined') return;

    if (range_prices.max - range_prices.min < 1) {
      dispatch(applyFilters('price', active.prices));
      return;
    }

    const values = {};
    if (active.prices.max > range_prices.max || active.prices.max < range_prices.min) {
      values.max = range_prices.max;
    }
    if (active.prices.min < range_prices.min || active.prices.min > range_prices.max) {
      values.min = range_prices.min;
    }

    if (values.min || values.max) {
      handleFinalMove([values.min || active.prices.min, values.max || active.prices.max]);
    }
  }, [dispatch, range_prices, active.prices, handleFinalMove, categoryID]);

  return (
    <div className='category-flow'>
      <div className='category-flow-flx'>
        {!isMobile && <CategoryFilters />}
        <div className='category-flow-products'>
          <CategoryTags />
          {isMobile && <CurrentFilters />}
          <CategoryProducts />
        </div>
      </div>
      {isMobile && (
        <ModalBox animType='enterSide' show={showFilters} modifier='mb-filters-wrap'>
          <CategoryFiltersMobile />
        </ModalBox>
      )}
    </div>
  );
};

export default CategoriesFlow;
