import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './sass/styles.sass';
import * as serviceWorker from './serviceWorker';
import store from './store';
import 'slick-carousel'

const tmpPurchase = JSON.parse(window.localStorage.getItem('tmp_purchase'));

if(tmpPurchase) {
  const currentTime = new Date().getTime();
  const expireTime = tmpPurchase.time + (5 * 60 * 1000);
  if(expireTime < currentTime) {
    window.localStorage.removeItem('tmp_purchase');
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
