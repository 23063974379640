import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressIndicator from '../components/FormElements/ProgressIndicator';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import PurchaseUserGuest from '../components/Purchase/Guest/index';
import PurchaseUserRegistered from '../components/Purchase/Logged/index';
import PurchaseDetails from '../components/Purchase/PurchaseDetails';
import { getCart } from '../store/actions/cart';
import { analyticsPurchase } from '../store/actions/analytics-events';

class PurchasePage extends React.Component {
  state = {
    closeAndGo: false,
    enableNext: false,
  };

  addAlert = (event) => {
    if (window.location.pathname !== '/carrito' || this.props.step > 3 || event.currentTarget.target === '_blank' || event.currentTarget.classList.contains('no-alert')) return;

    event.preventDefault();
    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    this.setState({ closeAndGo: { type: 'link', link: event.currentTarget.pathname + event.currentTarget.search + event.currentTarget.hash } });
  };

  componentDidMount = async() => {
    const localOrder = window.localStorage.getItem('orderID');

    if (!this.props.orderID && !localOrder) {
      return this.props.history.push('/categorias');
    }
    if (!this.props.orderID && localOrder) {
      await this.props.getCart(localOrder);
    }

    this.props.analyticsPurchase()

    window.scrollTo(0, 0);
    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      link.addEventListener('click', (event) => this.addAlert(event));
    });
  }

  componentWillUnmount() {
    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      link.removeEventListener('click', (event) => this.addAlert(event));
    });
  }

  updateState = (value) => {};

  requestConfirmClose() {}

  confirmClose = () => {
    this.props.clear();
    if (this.state.closeAndGo.type === 'link') {
      const body = document.querySelector('body');
      body.classList.remove('no-scroll');
      return this.props.history.push(this.state.closeAndGo.link);
    }
    if (this.state.closeAndGo.type === 'history') {
      return this.props.history.goBack();
    }
    this.props.history.push('/');
  };

  cancelClose = () => {
    this.setState({ closeAndGo: false });
    const body = document.querySelector('body');
    body.classList.remove('no-scroll');
  };

  enableNext = (enableNext) => {
    this.setState({ enableNext });
  };

  goBack = () => {
    if (this.props.step > 1) {
      return this.props.goBack();
    }
    this.setState({ closeAndGo: { type: 'history' } });
  };

  componentDidUpdate(prevProps){
    if(this.props.step !== prevProps.step){
      this.props.analyticsPurchase()
    }
  }

  render() {
    if (!this.props.hasProducts && this.props.step < 4) return <Loader />;
    return (
      <main>
        {(this.props.step > 1 || !this.props.isLogged) && this.props.step < 4 && (
          <span className='go-back' onClick={() => this.goBack()}>
            <i className='icon-left-open'></i> Regresar
          </span>
        )}
        <h1>Compra</h1>
        {this.props.step < 3 && <PurchaseDetails />}
        <section id='container-step-purchase' className={`container-step-${this.props.step}`}>
          {this.props.step < 4 && (
            <ProgressIndicator
              options={[
                {
                  id: 1,
                  label: this.props.isLogged ? 'Mis direcciones' : 'Datos personales',
                },
                {
                  id: 2,
                  label: 'Método de pago',
                },
                {
                  id: 3,
                  label: 'Realizar pago',
                },
              ]}
              current={this.props.step}
            />
          )}
          {!this.props.isLogged && <PurchaseUserGuest step={this.props.step} />}
          {this.props.isLogged && <PurchaseUserRegistered step={this.props.step} />}
        </section>
        {this.state.closeAndGo && (
          <Modal className='alert-close-purchase' open={this.state.closeAndGo} close={() => this.cancelClose()}>
            <h1>¡Espera!</h1>
            <p>
              Si regresas ahora todo tu progreso se perderá.
            </p>
            <p>¿Deseas abandonar tu compra?</p>
            <span className='btn btn-primary-outline rounded' onClick={() => this.confirmClose()}>
              ABANDONAR
            </span>
            <span className='btn btn-primary rounded' onClick={() => this.cancelClose()}>
              CONTINUAR
            </span>
          </Modal>
        )}
      </main>
    );
  }
}

const mapStateProps = (state) => {
  return {
    isLogged: state.user.logged,
    step: state.purchase.step,
    orderID: state.cart.orderID,
    hasProducts: !!state.cart.products.length,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goBack: () => dispatch({ type: 'PURCHASE_GO_BACK' }),
    clear: () => dispatch({ type: 'PURCHASE_CLEAR_DATA' }),
    getCart: (id = false) => dispatch(getCart(id, true)),
    analyticsPurchase: () => dispatch(analyticsPurchase())
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default React.memo(withRouter(createConnect(PurchasePage)));
