import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumbs from '../../components/Breadcrumbs'
import ContainerBoxPage from '../../components/containers/ContainerBoxPage'
import FormInput from '../../components/FormElements/FormInput';
import AppButton from '../../components/UI/Buttons';
import { recoveryChangePassword, userMenuControl } from '../../store/actions/user';
import { types } from '../../store/reducers/user';

const RecoveryPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const { error, loader, recovery_success, recovery_fail, logged } = useSelector((state) => state.user)
    const [showValue, setShowValue] = useState(false);
    const params = useParams()
    const { recovery_token } = params
    const paramsSearch = useLocation().search
    let verify_id = '';

    if (paramsSearch != null && paramsSearch !== '' && paramsSearch !== undefined) {
        verify_id = paramsSearch.split('=')[1]
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
    } = useForm({
        mode: 'onBlur',
    });

    const submit = (data) => {
        dispatch(recoveryChangePassword(recovery_token, data.password, verify_id))
    }

    const handleSuccessAfter = () => {
        dispatch(userMenuControl(true, 'user-login', 'top'))
    }

    const enableSubmit = () => isDirty && !Object.keys(errors).length;

    useEffect(() => {
        if (logged) {
            history.push('/')
        }
    }, [history, logged])

    return (
        <ContainerBoxPage className='recovery-box-page'>
            <div className="recovery-panel">
                <Breadcrumbs className="recovery-crumbs" />

                <div className="rec-page-header">
                    <h2 className="mxp-title">Recuperar contraseña</h2>
                </div>
                <div className={classNames({
                    'rec-flow': true,
                    'rec-flow--action': recovery_fail || recovery_success
                })}>
                    {!recovery_fail && !recovery_success && <div className="rec-field register-field--passwords">
                        <div className="register-inputs">
                            <p className="rec-label">Ingresa tu nueva contraseña.</p>
                            <form onSubmit={handleSubmit(submit)}>
                                <FormInput
                                    label='Contraseña*'
                                    placeholder='Ingresa de 8 a 30 caracteres'
                                    name='password'
                                    register={register}
                                    error={error}
                                    options={{ shouldValidate: true, required: true }}
                                    type='password'
                                    defaultValue={''}
                                    setValue={setValue}
                                    setShowValue={setShowValue}
                                    showValue={showValue}
                                    className="pass"
                                />
                                <p className="mxp-text"> Recuerda que la contraseña debe contener mínimo 8 caracteres, incluir mayúsculas, minúsculas, números y caracteres especiales ($#&,.-). </p>
                                <AppButton type="main" txt="Cambiar contraseña" available={enableSubmit} loader={loader} />
                            </form>
                        </div>
                    </div>}
                    {recovery_success && (
                        <div className="purchases-product-response purchases-product-response--ok">
                            <figure className="purchases-product-icon">
                                <span className="icon-ok-2"></span>
                            </figure>
                            <h3 className="mxp-sub-subtitle">¡Listo!</h3>
                            <p className="mxp-text">Tu contraseña ha sido restablecida.</p>
                            <AppButton type="main" txt="Iniciar sesión" onClick={handleSuccessAfter} available={true} />
                        </div>
                    )}
                    {recovery_fail && (
                        <div className="purchases-product-response purchases-product-response--error">
                            <figure className="purchases-product-icon">
                                <span className="icon-alert"></span>
                            </figure>

                            <h3 className="mxp-sub-subtitle">¡Lo sentimos!</h3>
                            {recovery_fail.status_code === 210 &&
                                <p className="mxp-text">{recovery_fail.message}</p>
                            }
                            {recovery_fail.status_code !== 210 &&
                                <p className="mxp-text">Ocurrió un error inesperado. Por favor, intenta nuevamente.</p>
                            }
                            <AppButton type="main" txt="Reintentar" onClick={() => {
                                dispatch({ type: types.USER_RECOVERY, recovery: { recovery_fail: false, recovery_success: false } })
                            }} available={true} />
                        </div>
                    )}
                </div>
            </div>
        </ContainerBoxPage>
    )
}

export default RecoveryPassword
