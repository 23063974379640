import axiosApi from '../axiosAPI';
import { types } from '../reducers/home';

export const getHomeData = () => async (dispatch, getStore) => {
  if (getStore().home.code) return;
  const response = await axiosApi.get('v1/site/home');
  dispatch({ type: types.HOME_GET_DATA, data: response.data });
};

export const setCurrentVideo = (title) => dispatch => {
  dispatch({
    type: types.SET_CURRENT_VIDEO,
    payload: title
  })
}
