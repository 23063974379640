import { combineReducers } from 'redux';
import cartReducer from './cart';
import categoriesReducer from './categories';
import compareReducer from './compare';
import favoritesReducer from './favorites';
import filtersReducer from './filters';
import globalReducer from './global';
import historyPurchaseReducer from './history-purchase';
import homeReducer from './home';
import productReducer from './products';
import purchaseReducer from './purchase';
import searchReducer from './search';
import userReducer from './user';
import surveyReducer from './survey';
import boxReducer from './box'

export default combineReducers({
  purchase: purchaseReducer,
  box: boxReducer,
  cart: cartReducer,
  categories: categoriesReducer,
  compare: compareReducer,
  favorites: favoritesReducer,
  filters: filtersReducer,
  global: globalReducer,
  history: historyPurchaseReducer,
  home: homeReducer,
  product: productReducer,
  search: searchReducer,
  survey: surveyReducer,
  user: userReducer,
});
