import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getShippingLogo } from '../../../helpers/shipping-logos';
import { saveShipping } from '../../../store/actions/purchase';
import AppButton from '../../UI/Buttons';
import ShippingDescription from './ShippingDescription';

function ShippingDesktop({ names, info }) {
    const history = useHistory();
    const dispatch = useDispatch();

    const firstItem = Object.keys(info).map((item) => { return item })
    const [btnIsSelected, setBtnIsSelected] = useState(false);
    const [btnIsAvailable, setBtnIsAvailable] = useState(false);
    const [selectedShipping, setSelShipping] = useState(info[firstItem[0]]);
    const [selectedPrice, setSelPrice] = useState({
        activeObject: null,
        objects: selectedShipping
    })
    const [selectedService, setSelService] = useState({
        activeObject: firstItem[0],
        objects: names
    })

    const showSelection = (service, i) => {
        const shippingOptions = info[service];
        setSelShipping(shippingOptions)
        setBtnIsSelected(!btnIsSelected)
        setSelService({ ...selectedService, activeObject: names[i] });
        setBtnIsAvailable(false)
    }


    const setSelectionPrice = (i) => {
        dispatch(saveShipping(selectedShipping[i]))
        setSelPrice({ ...selectedPrice, activeObject: selectedShipping[i] });
        setBtnIsAvailable(true)
    }

    const setClasses = (i) => {
        if (selectedShipping[i] === selectedPrice.activeObject) {
            return 'shipping-brand-item shipping-brand-item--selected'
        } else {
            return 'shipping-brand-item'
        }
    }

    const setStates = (i) => {
        if (names[i] === selectedService.activeObject) {
            return true
        } else {
            return false
        }
    }

    const continueWShipment = () => {
        history.push('/carrito/metodo-de-pago')
    }

    return (
        <div className="shipping-selection">
            <div className="sfd-left">
                {selectedService.objects.map((service, i) => {
                    const logo = getShippingLogo(service);
                    const modifier = service.toLocaleLowerCase();
                    const handlerses = setStates(i);

                    return (
                        <AppButton
                            key={i}
                            type="purchase"
                            url={logo}
                            modifier={modifier}
                            handler={handlerses}
                            customClick={() => {
                                showSelection(service, i)
                            }} />
                    )
                })}
            </div>
            <div className="sfd-right sfd-selected-service">
                <div className="sfd-description">
                    {
                        selectedShipping.map((brand, i) => <ShippingDescription
                            classess={setClasses(i)}
                            brand={brand}
                            handleSelection={setSelectionPrice}
                            id={i}
                            key={i}
                        />)
                    }
                </div>
                <nav className="sf-nav">
                    <AppButton type="main" txt="Continuar" available={btnIsAvailable} customClick={continueWShipment} />
                </nav>
            </div>
        </div>
    )
}

export default ShippingDesktop
