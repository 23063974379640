import React from 'react'
import { capitalize } from '../../helpers';

const FilterLink = ({ filter, action, type }) => {
    const { display, product_quantity, name } = filter;

    const handleClick = () => {
        action(type, filter)
    }

    return (
        <div className={`filter-link`} onClick={handleClick}>
            <p className="mxp-text">
                {name && (capitalize(name.toLowerCase()))}
                {display && (capitalize(display.toLowerCase()))}
                <span className="mxp-text-clear"> ({product_quantity}) </span>
            </p>
        </div>
    )
}

export default FilterLink
