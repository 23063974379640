import React, { useEffect, useRef } from 'react';
import { isChildOfClass } from '../../helpers/index';
import PerfectScroll from '../PerfectScroll';

const SelectAdvanced = ({ options, placeholder, icon, value, label, prefix, suffix, onChange }) => {
  const inputSelect = useRef(null);

  const open = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const selectAdvancedOpened = document.querySelector('.select-advanced.open');
    if (selectAdvancedOpened && selectAdvancedOpened !== inputSelect.current) {
      selectAdvancedOpened.classList.remove('open');
    }
    event.currentTarget.parentElement.classList.add('open');
  };

  const close = (event) => {
    const selectAdvancedOpened = document.querySelector('.select-advanced.open');

    if (selectAdvancedOpened && !event) {
      selectAdvancedOpened.classList.remove('open');
      return;
    }

    if (event && selectAdvancedOpened && !isChildOfClass(event.target, 'select-advanced')) {
      selectAdvancedOpened.classList.remove('open');
    }
  };

  const change = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    if (!onChange) return;
    onChange(option);
    close();
  };

  useEffect(() => {
    window.addEventListener('click', close);

    return () => {
      window.removeEventListener('click', close);
    };
  }, []);

  return (
    <div className='select-advanced' ref={inputSelect}>
      <div className='selected' onClick={open}>
        {icon && <span className={`icon ${icon}`}></span>}
        {!value && <span className='placeholder'>{placeholder}</span>}
        {value && (
          <div className='value'>
            {value[label]}
            {suffix && <span>{value[suffix]}</span>}
          </div>
        )}
        <span className='icon-down-open'></span>
      </div>
      {options && (
        <PerfectScroll height={300}>
          <ul className='options'>
            {options.map((option, optionIndex) => (
              <li key={optionIndex} onClick={(event) => change(event, option)}>
                {option[label]}
                {suffix && <span>{option[suffix]}</span>}
              </li>
            ))}
          </ul>
        </PerfectScroll>
      )}
    </div>
  );
};

export default SelectAdvanced;
