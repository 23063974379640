import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import SearchFlow from '../../components/Search/Box/SearchFlow';
import SearchHeader from '../../components/Search/Box/SearchHeader';
import { categoriesAnim } from '../../helpers/choreography/category';
import { searchGetResults } from '../../store/actions/search';

const SearchPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { active } = useSelector((state) => state.filters);

  useEffect(() => {
    dispatch(searchGetResults({ keyword: params.search, reload: true }));
  }, [dispatch, params.search]);

  useEffect(() => {
    dispatch(searchGetResults({}));
  }, [dispatch, active]);

  return (
    <ContainerBoxPage className='category-box-page'>
      <motion.section variants={categoriesAnim.layout} transition={{ ease: 'easeInOut', delay: 0.2, duration: 0.4 }} initial='hidden' animate='visible' exit='hidden' className='categos'>
        <div className='panel'>
          <SearchHeader />
          <SearchFlow />
        </div>
      </motion.section>
    </ContainerBoxPage>
  );
};

export default SearchPage;
