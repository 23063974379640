import { types } from '../reducers/survey';
import { axiosApi } from './../axiosAPI';

export const surveyGet = (id) => async (dispatch) => {
  const response = await axiosApi.get(`v1/users/survey/${id}`);

  dispatch({ type: types.SURVEY_GET, survey: response.data });
};

export const surveySubmit = (token, answers) => async (dispatch) => {
  dispatch({ type: types.SURVEY_LOADER, loader: true})
  const response = await axiosApi.post(`v1/users/survey/`, { token, answers });
  if(response.data.status_code === 2){
    return dispatch({type: types.SURVEY_ERROR, error: response.data})
  }
  dispatch({ type: types.SURVEY_GET, survey: response.data})
};

export const surveyError = (error) => (dispatch) => {
  dispatch({type: types.SURVEY_ERROR, error})
}
