import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OxxoPay from '../../components/Cart/PaymentConfirmations/OxxoPay';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import { purchaseConfirmAnalytics } from '../../store/actions/analytics-events';
import { purchaseReset, purchaseResetAnalyticsData } from '../../store/actions/purchase';
import CardPayment from './../../components/Cart/PaymentConfirmations/CardPayment';
import { unloadCart } from './../../store/actions/cart';

let init = false;

const ConfirmationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { paymentMethod, purchaseCompleteAnalytics } = useSelector((state) => state.purchase);
  const { offices } = useSelector((state) => state.home);

  if (purchaseCompleteAnalytics && !init) {
    init = true;
    purchaseConfirmAnalytics({ purchase: purchaseCompleteAnalytics, offices });
  }

  useEffect(() => {
    if (!paymentMethod) {
      history.replace('/box');
      return;
    }
    return () => {
      dispatch(purchaseReset());
      dispatch(unloadCart());
      dispatch(purchaseResetAnalyticsData());
      init = false;
    };
  }, [dispatch, paymentMethod, history]);

  return (
    <ContainerBoxPage className='purchase-process purchase-confirmation'>
      {paymentMethod !== 'oxxo' && <CardPayment />}
      {paymentMethod === 'oxxo' && <OxxoPay />}
    </ContainerBoxPage>
  );
};

export default React.memo(ConfirmationPage);
