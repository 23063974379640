import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsMobile from '../../hooks/useIsMobile';
import { purchaseGetHistory, purchaseNextPage } from '../../store/actions/history-purchase';
import IconBox from '../BasketBox/IconBox';
import FakeSelect from '../FormElements/FakeSelect';
import InputRadio from '../FormElements/InputRadio';
import Loader from '../Loader';
import PurchasesDetail from './PurchasesDetail';


let init = false

const PurchasesHistory = () => {
    const { notFound, items, page, loader, next, deliveryStatus } = useSelector((state) => state.history)

    const dispatch = useDispatch();
    const isMobile = useIsMobile()

    const [orderInPage, setOrderInPage] = useState(false)
    const [sortInPage, setSortInPage] = useState(false)
    const [sortValue, setSortValue] = useState("newer")
    const [loadMore, setLoadMore] = useState(false)

    useEffect(() => {
        /** google analytics event */
        window.dataLayer.push({
            accionHistorial: 'Registrarme',
            event: 'Historial',
        });
        /** end - google analytcs event */
        if (!items.length && init === false && !loadMore) {
            init = true
            dispatch(purchaseGetHistory('', 'newer', true));
        }
        return () => {
            init = false;
            setLoadMore(false)
        }
    }, [dispatch, items.length, loadMore]);

    const handleSelect = (option) => {
        setSortInPage(option.label)
        setSortValue(option.value)

        dispatch(purchaseGetHistory('', option.value, true, orderInPage));
    }

    const setDeliveryStatus = (deliveryStatus) => {
        if (orderInPage === deliveryStatus) {
            setOrderInPage(false);
            dispatch(purchaseGetHistory('', 'older', true));
        } else {
            setOrderInPage(deliveryStatus);
            dispatch(purchaseGetHistory('', sortValue, true, deliveryStatus));
        }
    };

    const nextPage = (next) => {
        window.scroll(0, 0);
        setLoadMore(true)
        dispatch(purchaseNextPage(page + next));
    };

    return (
        <section className="profile-purchases">
            {isMobile && <h2 className="mxp-subtitle">Mis compras</h2>}
            <div className="purchases-header">
                <div className={`purchases-filter ${loader ? 'purchases-order-disabled' : ''}`}>
                    <p className="mxp-text-clear">Ordenar por:</p>
                    <FakeSelect className='purchases-select'
                        placeholder={sortInPage || 'Más recientes'}
                        options={[
                            { label: 'Más recientes', value: 'newer' },
                            { label: 'Antiguas', value: 'older' },
                        ]}
                        onChange={(option) => { handleSelect(option) }}
                    />
                </div>
                <div className={`purchases-order ${loader ? 'purchases-order-disabled' : ''}`}>
                    <p className="mxp-text-clear">Mostrar solo:</p>
                    <div className="purchases-order-flx">
                        <InputRadio
                            value={orderInPage || deliveryStatus}
                            options={[
                                { label: 'En tránsito', value: 'IN_PROGRESS' },
                                { label: 'Entregadas', value: 'DELIVERED' },
                            ]}
                            onChange={(value) => setDeliveryStatus(value)}
                        />
                    </div>
                </div>
            </div>
            {!notFound && (
                <div className={`purchases-items ${loader ? 'purchases-items--is-loading' : ''}`}>
                    {loader && !items.length && <Loader spinner={{ title: 'Cargando' }} blue />}
                    {loader && !!items.length && <Loader spinner={{ title: 'Cargando' }} blue />}
                    {!loader && !!items.length && (
                        items[page].map((item, itemIndex) => {
                            return <PurchasesDetail key={itemIndex} info={item} />
                        })

                    )}
                    {(next || !!items.length) && (
                        <>
                            <div className='navigation'>
                                <span className={`prev${!page ? ' disable' : ''}`} onClick={() => nextPage(-1)}>
                                    <i className='icon-left-open'></i>Regresar
                                </span>
                                <span className={`next${!next && page === items.length - 1 ? ' disable' : ''}`} onClick={() => nextPage(1)}>
                                    Siguiente<i className='icon-right-open'></i>
                                </span>
                            </div>
                        </>
                    )}
                </div>
            )}
            {!loader && notFound && (
                <div className='purchases-empty'>
                    <IconBox type='informer' id='container-informer-box' name='empty' percent={0} />
                    <strong>Aún no tienes compras realizadas.</strong>
                </div>
            )}

        </section>
    );
};

export default PurchasesHistory
