export const types = {
  SEARCH: 'SEARCH',
  SEARCH_ERROR: 'SEARCH_ERROR',
  SEARCH_OPEN: 'SEARCH_OPEN',
  SEARCH_SET_PAGE: 'SEARCH_SET_PAGE',
  SEARCH_SET_FILTERS: 'SEARCH_SET_FILTERS',
  SEARCH_LOADER: 'SEARCH_LOADER',
  SEARCH_RESET: 'SEARCH_RESET',
  SEARCH_LOAD_PRODUCTS: 'SEARCH_LOAD_PRODUCTS',
  SEARCH_SET_PRODUCTS: 'SEARCH_SET_PRODUCTS',
  RESET: 'RESET',
};

const initialStore = {
  categories: [],
  error: '',
  filters: {},
  filters_active: {},
  keyword: '',
  loader: false,
  open: false,
  products: [],
  results: [],
  product_quantity: 0,
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.SEARCH:
      const products = action.products || [...store.products];
      return { ...store, keyword: action.keyword, results: action.results, error: '', filters: action.filters, products, categories: action.categories, loader: false, product_quantity: action.product_quantity, filters_active: action.filters_active };
    case types.SEARCH_SET_PRODUCTS:
      return  { ...store, products: action.products, loader: false, product_quantity: action.product_quantity || store.product_quantity, keyword: action.keyword || store.keyword };
    case types.SEARCH_LOAD_PRODUCTS:
      return {...store, products: [...store.products, ...action.data.products], filtes: action.data.filters, loader: false}
    case types.SEARCH_ERROR:
      const productsError = action.page ? [] : [...store.products];
      return { ...store, keyword: action.keyword, results: [], products: productsError, error: action.error, filters_active: action.filters_active, loader: false, filters: action.filters || store.filters };
    case types.SEARCH_OPEN:
      return { ...store, open: action.open, results: [], keyword: '', error: '' };
    case types.SEARCH_SET_PAGE:
      return { ...store, products: { ...store.results } };
    case types.SEARCH_SET_FILTERS:
      return { ...store, filters_active: action.filters };
    case types.SEARCH_LOADER:
      return { ...store, loader: action.loader };
    case types.RESET:
    case types.SEARCH_RESET:
      return { ...initialStore };
    default:
      return { ...store };
  }
};

export default reducer;
