import React from 'react';
import AdvancedModal from './AdvancedModal';
import { useDispatch } from 'react-redux';

const ErrorLoad = () => {
  const dispatch = useDispatch();

  const reload = () => {
    dispatch({ type: 'ERROR_SERVER', error: false });
    window.location.reload();
  };

  return (
    <AdvancedModal className='error-load animate__animated animate__fadeIn' close={reload}>
      <AdvancedModal.Body>
        <img src='/img/error-load.png' alt='Error' />
        <div className='message'>
          <strong>¡Algo salió mal!</strong>
          Por favor, intenta más tarde.
        </div>
      </AdvancedModal.Body>
      <AdvancedModal.Footer>
        <span onClick={reload} className='btn btn-primary rounded'>
          CONTINUAR
        </span>
      </AdvancedModal.Footer>
    </AdvancedModal>
  );
};

export default React.memo(ErrorLoad);
