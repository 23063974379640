import React from 'react';
import { useSelector } from 'react-redux';
import CardForm from './CardForm';
import CardList from './CardList';


const PaymentCard = () => {
  const {logged} = useSelector(state => state.user)

  if(!logged) return <CardForm />

  return <CardList />;
};

export default PaymentCard;
