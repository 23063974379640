import React from 'react';
import { connect } from 'react-redux';
import { months, years } from '../helpers';
import { onlyAcceptName, validateName } from '../helpers';
import { enableNext, expireDateError, generateToken } from '../store/actions/purchase';
import InputCard from './FormElements/InputCard';
import Select from './FormElements/Select';
import Input from './Input';
import InputCheckbox from './FormElements/InputCheckbox';

class FormAddCard extends React.Component {
  updateField = (name, value) => {
    this.props.updateCardFields(name, value);
  };

  enableSubmit = () => !!this.props.enableSubmit;

  submit = (event) => {
    event.preventDefault();
    this.props.generateToken();
  };

  cardMaxLength = () => {
    if (this.props.cardData.type === 'visa') return 19;
    if (this.props.cardData.type === 'amex') return 15;
    if (this.props.cardData.type === 'mastercard') return 16;
    return 19;
  };

  cvvMaxLength = () => {
    if (this.props.cardData.type === 'visa') return 3;
    if (this.props.cardData.type === 'amex') return 4;
    if (this.props.cardData.type === 'mastercard') return 3;

    return 3;
  };

  render() {
    return (
      <form action='/' className='form-add-card' onSubmit={(event) => this.submit(event)} ref={(el) => this.el = el}>
        <Input
          placeholder='Nombre del tarjetahabiente'
          icon='icon-login'
          update={(value) => this.updateField('name', value)}
          value={this.props.cardData.name}
          min={validateName.minlength}
          max={validateName.maxlength}
          accept={onlyAcceptName}
          name='name'
          minWords={validateName.minWords}
        />
        <InputCard
          type='card'
          placeholder='Número de la tarjeta'
          icon='icon-card'
          onChange={(value) => this.props.updateCardFields('number', value)}
          value={this.props.cardData.number}
          maxLength={this.cardMaxLength()}
          name='card_number'
        />
        <div className={`fields-expire${!!this.props.cardData.month || !!this.props.cardData.year ? ' active' : ''}`}>
          <strong>Fecha de vencimiento</strong>
          <Select
            placeholder='Mes'
            icon='icon-calendar'
            options={months}
            onChange={(value) => this.props.updateCardFields('month', value)}
            value={this.props.cardData.month}
            keyLabel='label'
            object={true}
          />
          <Select placeholder='Año' icon='icon-calendar' options={years} onChange={(value) => this.props.updateCardFields('year', value)} value={this.props.cardData.year} />
          {this.props.expireDateError() && <div className='invalid-date'>{this.props.expireDateError()}</div>}
        </div>
        <InputCard
          type='cvv'
          placeholder='CVV'
          icon='icon-card'
          onChange={(value) => this.props.updateCardFields('cvv', value)}
          value={this.props.cardData.cvv}
          maxLength={this.cvvMaxLength()}
          minLength={this.cvvMaxLength()}
          name='cvv'
        />
        <div className='actions'>
          {this.props.enableSave && (
            <div className='save'>
              <InputCheckbox item={{ label: '¿Deseas guardar esta tarjeta?', value: 1 }} onChange={(value) => this.props.updateCardFields('save', value)} selected={this.props.cardData.save} />
            </div>
          )}
          <button className={`btn btn-primary rounded${this.props.enableSubmit() ? '' : ' disabled'}${this.props.loader ? ' btn-loader' : ''}`}>{this.props.labelSubmit || 'SIGUIENTE'}</button>
        </div>
        {this.props.error && <div className='error-token'>{this.props.error}</div>}
      </form>
    );
  }
}

const mapStateProps = (state) => {
  return {
    cardData: state.purchase.cardData,
    error: state.purchase.tokenizer_error,
    loader: state.purchase.loader,
    step: state.purchase.step
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCardFields: (field, value) => dispatch({ type: 'PURCHASE_UPDATE_TC_DATA', field, value }),
    enableSubmit: () => dispatch(enableNext()),
    generateToken: () => dispatch(generateToken()),
    expireDateError: () => dispatch(expireDateError()),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(FormAddCard);
