import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import MoneyFormat from '../../MoneyFormat';

const OxxoPay = () => {
  const {paymentOXXO} = useSelector(state => state.purchase)

  const printData = () => {
    const contentWindow = window.open('', 'Pago en OXXO', 'height=600,width=400');

    contentWindow.document.write('<html><head><title>' + document.title  + '</title>');
    contentWindow.document.write('</head><body >');
    contentWindow.document.write('<h1>' + document.title  + '</h1>');
    contentWindow.document.write(document.getElementById('oxxo-pay').innerHTML);
    contentWindow.document.write('</body></html>');

    contentWindow.print();
    contentWindow.close();

    return true;
  }

  return (
    <div id='oxxo-pay'>
      <img className="oxxo-logo" src='/img/cart/oxxo-pay.jpg' alt='OXXO Pay' />
      <div className='total-to-pay'>
        Cantidad a pagar:
        <span className='total'>
          <MoneyFormat money={paymentOXXO.amount} />
        </span>
        En tu tienda OXXO más cercana.
      </div>
      <p>Menciona al empleado que pagarás un servicio, imprime esta pantalla y muéstrale el código de barras para que realice el cargo correspondiente. </p>
      <div className='code-bar'>
        <strong>{paymentOXXO.reference.replace(/([0-9]{4})/g, "$&-").replace(/-$/, '')}</strong>
        <img src={paymentOXXO.barcode_url} alt={paymentOXXO.reference} />
        <span>OXXO cobrará una comisión adicional al momento de realizar tu pago.</span>
      </div>
      <Link className='btn btn-primary rounded-small' to='/box'>
        IR AL CATÁLOGO
      </Link>
      <span onClick={printData} className='btn btn-primary btn-primary-outline rounded-small'>IMPRIMIR</span>
    </div>
  );
};

export default OxxoPay;
