import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesGrid, getBanner } from '../store/actions/categories';
import { imageUrl } from '../helpers/index';
import { Link } from 'react-router-dom';

const BannerCategories = () => {
  const dispatch = useDispatch();
  const {banner, grid} = useSelector((state) => state.categories);
  const [bannerSize, setBannerSize] = useState('');

  useEffect(() => {
    const changeBannerURL = () => {
      if (window.innerWidth < 760) {
        setBannerSize('small');
      } else if (window.innerWidth < 900) {
        setBannerSize('medium');
      } else {
        setBannerSize('large');
      }
    };

    if (!banner) {
      dispatch(getBanner());
    }

    changeBannerURL();

    window.removeEventListener('resize', changeBannerURL);
    window.addEventListener('resize', changeBannerURL);
    return () => {
      window.removeEventListener('resize', changeBannerURL);
    };
  }, [dispatch, banner]);

  const displayCategoriesGrid = () => dispatch(categoriesGrid(!grid));

  return (
    <>
      <section id='banner-categories'>
        {bannerSize === 'small' && <img alt={banner.name} loading="lazy" src={imageUrl(banner.sectors?.small, true)} />}
        {bannerSize === 'medium' && <img alt={banner.name} loading="lazy" src={imageUrl(banner.sectors?.medium, true)} />}
        {bannerSize === 'large' && <img alt={banner.name} loading="lazy" src={imageUrl(banner.sectors?.large, true)} />}

        <div className='info'>
          <h1>
            <span className='title'>{banner.alt_name || banner.name}</span>
            <span className='change-grid' onClick={displayCategoriesGrid}>
              <i className={`icon-${grid ? 'list-bullet' : 'grid'}`}></i>
            </span>
          </h1>
          <div className='contact'>
            <p>{banner.contact_us?.title}</p>
            <strong>{banner.contact_us?.copy}</strong>
            <Link to="/contacto" className='btn btn-blue rounded'>
              Contáctanos
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(BannerCategories);
