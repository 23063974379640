const swipeCard = (element, idRightLeft, removeClass = false) => {
  let distanceX = 0;
  let startX;
  let startY;
  const speed = 1.5;

  if (!element) return;

  const idRightLeftElement = document.getElementById(idRightLeft);
  if (!idRightLeftElement) return;

  element.addEventListener('touchstart', (event) => {
    if (!idRightLeftElement) return;

    let pageX = event.changedTouches[0].pageX;
    let pageY = event.changedTouches[0].pageY;
    startX = pageX - element.offsetLeft;
    startY = pageY - element.offsetTop;
  }, {passive: true});

  element.addEventListener('touchmove', (event) => {
    if (!idRightLeftElement) return;

    let pageX = event.changedTouches[0].pageX;
    const x = pageX - element.offsetLeft;
    const walkX = (x - startX) * speed;

    let pageY = event.changedTouches[0].pageY;
    const Y = pageY - element.offsetTop;
    const walkY = (Y - startY) * speed;

    if (Math.abs(walkX) > Math.abs(walkY)) {
      event.stopPropagation();
      event.preventDefault();
      distanceX = startX - event.changedTouches[0].pageX;
    }
  }, {passive: true});

  element.addEventListener('touchend', (event) => {
    if (!idRightLeftElement) return;
    if (!idRightLeftElement.classList.contains('active') && distanceX > 100) {
      if (removeClass) {
        const currentSwipe = document.querySelector(removeClass);
        if (currentSwipe) {
          currentSwipe.classList.remove('active');
        }
      }
      idRightLeftElement.classList.add('active');
      distanceX = 0;
    } else if (idRightLeftElement.classList.contains('active') && distanceX < -100) {
      idRightLeftElement.classList.remove('active');
      distanceX = 0;
    }
  });
};

export default swipeCard;
