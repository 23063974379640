import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import { filterAnalytics } from './../../../store/actions/analytics-events';

// install Swiper modules
SwiperCore.use([Navigation]);

const CategoryTags = () => {
  const {
    params: { categoryID, slug },
  } = useRouteMatch();

  const { categories } = useSelector((state) => state.categories);

  const currentCategory = categories.find((item) => {
    if (item.id === parseInt(categoryID)) return true;
    return item.subcategories.find((subcategory) => subcategory.id === parseInt(categoryID));
  });

  const sendAnalytics = (category) => filterAnalytics('sub-categoria', category)

  if (!currentCategory?.subcategories.length) return '';

  return (
    <div className='category-tags'>
      <aside className='cats-sub-cats'>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={16}
          freeMode={true}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          className='mySwiper'>
          <SwiperSlide className='subcategory-slide'>
            <Link onClick={() => sendAnalytics('todos')} className={`subcategory-tag ${currentCategory.id === parseInt(categoryID) ? 'subcategory-tag--active' : ''}`} to={`/box/categoria/${currentCategory.id}/${currentCategory.code}`}>
              Todos
            </Link>
          </SwiperSlide>
          {currentCategory.subcategories.map((info, i) => {
            const { name, id, code } = info;
            return (
              <SwiperSlide className={`subcategory-slide ${name.length > 12 ? 'swiper-slide--long' : ''} `} key={i}>
                <Link onClick={() => sendAnalytics(name)} className={`subcategory-tag ${code === slug ? 'subcategory-tag--active' : ''}`} to={`/box/categoria/${id}/${code}`}>
                  {name}
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className='swiper-button-next'></div>
        <div className='swiper-button-prev'></div>
      </aside>
    </div>
  );
};

export default CategoryTags;
