import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useBrand from '../../hooks/useBrand';
import useLastProduct from '../../hooks/useLastProduct';
import MoneyFormat from '../MoneyFormat';
import IconBox from './IconBox';
import { cartHideLast } from './../../store/actions/cart';

let timerConfirmation = null;

const ConfirmLastAdded = () => {
  const location = useLocation();

  const [container, setContainer] = useState({ code: '', name: '', percentage: 0 });

  const { containers, hide_last } = useSelector((state) => state.cart);
  const { is_recreate } = useSelector((state) => state.history);

  const product = useLastProduct();

  const brand = useBrand(product.brand_id);
  const dispatch = useDispatch();

  useEffect(() => {
    const lastContainer = containers ? [...containers].pop() : false;
    if (!lastContainer) return;

    setContainer(lastContainer);
  }, [dispatch, containers]);

  useEffect(() => {
    if (hide_last) return;

    if (timerConfirmation) {
      clearTimeout(timerConfirmation);
      timerConfirmation = null;
    }
    timerConfirmation = setTimeout(() => dispatch(cartHideLast()), 4000);
  }, [dispatch, hide_last]);

  // if(location.pathname.startsWith('/product') && !forceDisplay) return '';

  return (
    <div id='confirm-last-added' className={`${container.code}${!hide_last ? ' active' : ''}${location.pathname.startsWith('/producto/') ? ' is-detail' : ''}`} style={{ opacity: 0 }}>
      {!is_recreate && (<div className='quantity'>{product.quantity} producto(s) agregado(s) a tu caja</div>)}
      {is_recreate && (<div className='quantity'>Se ha agregado a tu caja</div>)}
      <div className={`detail ${is_recreate ? 'detail--clear' : ''}`}>
        <div className='box'>
          <div className='name'>{container.name}</div>
          <IconBox id='confirm-last-added' name={container.code} percent={container.percentage} />
        </div>
        {!is_recreate && (
          <div className='item'>
            <strong className='title'>{product.name}</strong>
            <span className='brand'>{brand.name}</span>
            <span className='total'>
              <MoneyFormat money={product.price * product.quantity} />
            </span>
          </div>
        )}
      </div>
      {location.pathname !== '/box/detail' && (
        <Link className='btn' to='/box/detail' onClick={() => dispatch(cartHideLast())}>
          IR AL PEDIDO
        </Link>
      )}
    </div>
  );
};

export default React.memo(ConfirmLastAdded);
