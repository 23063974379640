import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { highlightText } from '../../helpers';
import { headerAnimations } from '../../helpers/choreography/header';
import { analyticsSearch, analyticsSearchProductDetails, searchAnalytics } from '../../store/actions/analytics-events';
import { setSector } from '../../store/actions/categories';
import { openSearch, search } from '../../store/actions/search';
// Comps
import PerfectScroll from '../PerfectScroll';
import AppButton from '../UI/Buttons';
import IconBox from './IconBox';


const SearchMobile = ({ show, setShow }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [showCats, setShowCats] = useState(false)
    const [categoriesArr, setCategoriesArr] = useState([])
    const { catalogs } = useSelector(state => state.home)
    const { sector } = useSelector(state => state.categories)
    const [text, setText] = useState('')
    const timer = useRef(false)

    useEffect(() => {
        if (catalogs.length) {
            const items = catalogs.map(category => ({ value: category.code, label: category.alt_name || category.name }))
            setCategoriesArr(items);
        }
    }, [dispatch, catalogs])

    const { results, error, loader, keyword } = useSelector((state) => state.search);

    const clearTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = false;
        }
    };

    const currentSector = catalogs.find(item => item.id === sector) || {code: 'hogar'}

    useEffect(() => {
        if (!text) return;
        clearTimer();

        timer.current = setTimeout(() => {
            const searchText = text.trim();
            if(searchText === keyword) return;
            searchAnalytics(searchText, currentSector.code);
            dispatch(search(searchText, false));
        }, 1000);

        return () => {
            clearTimer();
        };
    }, [dispatch, text, keyword, currentSector]);

    const updateText = ({ currentTarget: { value } }) => {
        setText(value.replace(/[^a-z0-9ñáéíóúü\s]/gi, ''));
    };

    const sendForm = (event) => {
        event.preventDefault();
        event.currentTarget.blur();
        const searchText = text.trim();
        if (!searchText) return;
        analyticsSearch(searchText);
        history.push(`/buscar/${searchText}`);
        setText('');
        setShow(false)
    };

    const closeSearch = (product) => {
        window.scroll(0, 400)
        dispatch(openSearch(false));
        analyticsSearchProductDetails(product);
    };

    const handleSectors = (option) => {
        dispatch(setSector(option.value));
        analyticsSearch(option.label);

        if (!keyword) {
            history.push(`/categorias`);
            setShow(false)
        } else {
            dispatch(search(keyword, true));
            history.push(`/buscar/${keyword}`);
            setShow(false)
        }
        setShowCats()
    }

    const showResults = () => {
        return text && text.trim() === keyword && (results.length || error) && !showCats && show;
    };

    return (
        <motion.div
            variants={headerAnimations.showSearchMobile}
            transition={{
                ease: 'easeInOut',
                duration: .2
            }}
            initial="hidden"
            animate={show ? "visible" : "hidden"}
            exit="hidden"
            className="search-mobile-flow"
        >
            <div className="search-mobile-form">
                <div className="search-mobile-input">
                    <span className={`icon-search-light ${loader ? 'search-mobile--is-searching' : ''}`}> </span>
                    <form onSubmit={sendForm}>
                        <input type="text" className="search-mobile-input-txt"
                            onChange={(event) => updateText(event)} value={text}
                        />
                    </form>
                    {/* <AppButton
                        type="icon"
                        icon="plus"
                        modifier={showCats ? 'show-cats active' : 'show-cats'}
                        customClick={() => {
                            setShowCats(!showCats)
                        }}
                    /> */}
                    <AnimatePresence>
                        {
                            showCats && (
                                <motion.div
                                    variants={headerAnimations.showCatsList}
                                    transition={{
                                        ease: 'easeInOut',
                                        duration: .2
                                    }}
                                    initial="hidden"
                                    animate={"visible"}
                                    exit="hidden"
                                    className="search-mobile-cats-filter"
                                >
                                    <div className="search-cats-filter">
                                        <p className="mxp-text mxp-text-clear">Buscar en:</p>
                                        <ul className="search-cats-list">
                                            {
                                                categoriesArr.map((option, i) => {
                                                    const { label } = option
                                                    return (
                                                        <li className={`search-cats-item ${option.value === sector ? 'search-cats-item--active' : ''}`}
                                                            onClick={() => handleSectors(option)}
                                                            key={i}
                                                        > {label} </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </motion.div>
                            )
                        }
                    </AnimatePresence>
                </div>
                <nav className="search-nav">
                    <AppButton
                        type="icon"
                        icon="close"
                        modifier="close"
                        customClick={() => {
                            setShow(false)
                            setShowCats(false)
                        }}
                    />
                </nav>
                <AnimatePresence>
                    {showResults() && (
                        <motion.div
                            variants={headerAnimations.showCatsList}
                            transition={{ ease: 'easeInOut', duration: .2 }}
                            initial="hidden"
                            animate={"visible"}
                            exit="hidden"
                            className="search-results">
                            <div className="search-results-list">
                                {!error && (
                                    <PerfectScroll height={results.length * 23} className={`search-result-scroll`}>
                                        <ul className='results-list'>
                                            {results.map((result, index) => (
                                                <li key={index} className="results-item">
                                                    <Link onClick={() => closeSearch(result)} to={`/producto/${result.id}/${result.code}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: highlightText(result.name, keyword) }}></div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </PerfectScroll>
                                )}

                                {error && (
                                    <motion.div
                                        variants={headerAnimations.showCatsList}
                                        transition={{
                                            ease: 'easeInOut',
                                            duration: .2
                                        }}
                                        initial="hidden"
                                        animate={"visible"}
                                        exit="hidden"
                                    >
                                        <div className="search-results-error">
                                            <h3 className="mxp-title">{error.message || error}</h3>
                                            <IconBox type='informer' id='container-informer-box' name='empty' percent={0} />
                                        </div>
                                    </motion.div>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

export default SearchMobile
