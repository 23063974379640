import React from 'react';
import { defaultImg } from '../helpers';
import dragSlider from '../helpers/drag-slider';
import Loader from './Loader';

class ProductZoomMobile extends React.Component {
  state = {
    image: false,
  };
  componentDidMount() {
    new dragSlider(this.carousel);
    this.loader = document.getElementById('main-loader') || false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.images !== this.props.images || nextState.image !== this.state.image;
  }

  detail = () => {
    return this.state.image || this.props.open;
  };

  setDetail(image) {
    if ((this.state.image || this.props.open !== image) && this.state.image !== image) {
      this.loader.classList.add('active');
      this.setState({ image });
    }
  }

  imageLoaded = () => {
    this.loader.classList.remove('active');
  };

  render() {
    return (
      <div className='product-zoom-mobile'>
        <div className='detail'>
          <span className='close-lightbox icon-close' onClick={() => this.props.close(false)}></span>
          <strong>{this.detail().alt}</strong>
          <img src={this.detail().sizes.medium} alt={this.detail().alt} onLoad={() => this.imageLoaded()} />
        </div>
        <div className='carousel' ref={(el) => (this.carousel = el)}>
          {this.props.images.filter((item) => item.code === 'slider').map((image, index) => (
            <div className={`image${this.detail() === image ? ' active' : ''}`} key={index} onClick={() => this.setDetail(image)}>
              <img src={image.sizes.small} alt={image.alt} onError={(event) => defaultImg(event)} loading='lazy' />
            </div>
          ))}
        </div>

        <Loader />
      </div>
    );
  }
}

export default ProductZoomMobile;
