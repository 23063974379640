import React from 'react';
import Camera from '../helpers/take-photo';

class TakePhotoCamera extends React.Component {
  componentDidMount() {
    this.$camera = new Camera(this.camera, 960, 960);
    this.$camera.open();
  }

  open = () => {};

  takePhoto = () => {
    const photo = this.$camera.takePhoto();
    this.$camera.close();
    this.props.capture(photo);
  };

  render() {
    return (
      <div className='camera-take-photo'>
        <video ref={(el) => (this.camera = el)} autoPlay={true}></video>
        <span className='btn btn-primary' onClick={() => this.takePhoto()}>
          Tomar foto
        </span>
      </div>
    );
  }
}

export default TakePhotoCamera;
