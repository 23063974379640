import React from 'react';
import Header from '../BasketBox/Header';
import Footer from '../Footer';
import Contacts from '../UI/Contacts';
const ContainerBoxPage = ({ children, ...rest }) => {


  return (
    <div id='container-box-page' {...rest} theme="blue-root">
      <Header />
      <div className="container-box-wrapp">
        {children}
      </div>
      <Contacts />
      <Footer />
    </div>
  );
};

export default ContainerBoxPage;
