import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PrivateRoute from '../routes/PrivateRoute';
import routes from '../routes/cart';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const CartRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const key = location.pathname;

  useEffect(() => {
    return () => {
      dispatch({ type: 'PURCHASE_RESET' });
    }
  }, [dispatch])

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={key}>
        {routes.map((item, itemIndex) =>
          item.private ? (
            <PrivateRoute key={itemIndex} path={item.path} exact={item.exact} component={item.component} />
          ) : (
            <Route key={itemIndex} path={item.path} exact={item.exact} component={item.component} />
          ),
        )}
      </Switch>
    </AnimatePresence>
  );
};

export default CartRoutes;
