import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useIsMobile from '../../hooks/useIsMobile';
import AppButton from '../UI/Buttons';
import Main from './Main';
import BoxLoginForm from './BoxLoginForm';
import { loadProfile } from '../../store/actions/user';

const UserOptions = () => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch();
  const { error, open, logged, profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (logged && !profile) {
      dispatch(loadProfile())
    }
  }, [dispatch, logged, profile])

  return (
    <div
      id='user-options-menu'
      className={`
          user-options-menu
          ${open}
          ${open && !['register', 'user-confirm'].includes(open) ? ' active' : ''}
          ${error ? ' error' : ''
        }`}>
      {isMobile && (
        <nav className="user-header">
          <div className='user-logo'>
            <img src='/img/lg-mxp-color@2x.png' alt='Más x Pieza' />
          </div>
          <AppButton
            type="icon"
            icon="close"
            modifier="close"
            customClick={() => {
              dispatch({ type: 'USER_OPEN', open: false });
            }}
          />
        </nav>
      )}
      {open !== 'edit-profile' && (
        <>
          {open === 'user-menu' && <Main />}
          {!logged && open === 'login' && isMobile && <BoxLoginForm />}
          {!logged && open === 'user-menu' && !isMobile && <BoxLoginForm />}
          {/* {!this.props.user.logged && this.props.user.open === 'recovery' && <RecoveryTokenForm />}
              {!this.props.user.logged && this.props.user.open === 'recovery_change_password' && <RecoveryPasswordForm />} */}
        </>
      )}
    </div>
  )
}

export default UserOptions
