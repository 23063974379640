import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { applyFilters } from '../../../store/actions/filters';
import FakeSelect from '../../FormElements/FakeSelect';
import FilterLink from '../../UI/FilterLink';
import RangeSlider from '../../UI/RangeSliderRedux';
import Tag from '../../UI/Tag';
import useCurrentCategory from './../../../hooks/useCurrentCategory';
import { filterAnalytics } from './../../../store/actions/analytics-events';

const CategoryFilters = ({ type = 'category' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCategory = useCurrentCategory();
  const currentFilters = useSelector((state) => state.filters);

  const { categories } = useSelector((state) => state.categories);

  const filters = currentFilters[type];

  const { range_prices, brands, materials } = filters; // Filtros disponibles

  const { active } = currentFilters; // Filtros activados

  const handleFinalMove = useCallback(
    (values) => {
      const min = Math.round(values[0]);
      const max = Math.floor(values[1]);
      let range = {
        min: min >= range_prices.min ? min : range_prices.min,
        max: max <= range_prices.max ? max : range_prices.max,
        name: `$${min} a $${max}`,
      };
      if (range.min > range.max) {
        range.min = range_prices.min;
      }
      if (range.max < range.min) {
        range.max = range_prices.max;
      }
      dispatch(applyFilters('price', range));
    },
    [dispatch, range_prices],
  );

  const handleTags = (type, filter) => {
    window.scrollTo(0, 0);
    dispatch(applyFilters(type, filter));
  };

  const handleChangeCategory = (category) => {
    filterAnalytics('categoria', category.name)
    history.push(`/box/categoria/${category.id}/${category.code}`);
  };

  const handleRemoveFilter = (type, filter) => {
    dispatch(applyFilters(type, filter));
  };

  return (
    <div
      className={classNames({
        'category-filters': true,
      })}>
      <div className='category-filters-head'>
        <h3 className='mxp-subtitle'> Filtros </h3>
      </div>
      {!!Object.keys(active).length && (
        <div
          className={classNames({
            'cats-filters-array': true,
          })}>
          {Object.entries(active).map(([type, filter], i) => {
            return <Tag key={i} name={filter.name || filter.display} type='filtered' customClick={() => handleRemoveFilter(type, filter)} />;
          })}
        </div>
      )}
      <div className='category-filters-scroll'>
        <div className='category-filters-drops'>
          <div className='category-filters-drop cats-filters-drop'>
            <FakeSelect className='category-select' placeholder='Categoría' options={categories} onChange={handleChangeCategory} value={currentCategory} all />
          </div>
        </div>
        {range_prices?.max - range_prices?.min >= 1 && (
          <div className='cats-filters-range'>
            <p className='mxp-text mxp-text-clear'>Precio</p>
            {range_prices?.min && (
              <RangeSlider
                action={handleFinalMove}
                MIN={Math.round(range_prices.min)}
                MAX={Math.floor(range_prices.max)}
                min={active.prices?.min || Math.round(range_prices.min)}
                max={active.prices?.max || Math.floor(range_prices.max)}
              />
            )}
          </div>
        )}
        <div className={`cats-filter-option`}>
          <div className='cats-filter-option-head'>
            <p className='cats-filter-title'>Marca:</p>
          </div>
          <div className='cats-filter-options'>
            <div className='cats-filter-ranges'>
              {brands &&
                brands.slice(0, 10).map((filter, i) => {
                  return (
                    <FilterLink
                      filter={filter}
                      key={i}
                      action={() => {
                        handleTags('brand', filter);
                      }}
                      type='Marcas'
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className='cats-filter-option'>
          <div className='cats-filter-option-head'>
            <p className='cats-filter-title'>Material:</p>
          </div>
          <div className='cats-filter-options'>
            <div className='cats-filter-ranges'>
              {materials &&
                materials.slice(0, 10).map((filter, i) => {
                  return (
                    <FilterLink
                      filter={filter}
                      key={i}
                      action={() => {
                        handleTags('material', filter);
                      }}
                      type='Materiales'
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilters;
