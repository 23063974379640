import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import ModalBox from '../../UI/ModalBox';
import CategoryFilters from './../../Categories/Box/CategoryFilters';
import CategoryFiltersMobile from './../../Categories/Box/CategoryFiltersMobile';
import SearchProducts from './SearchProducts';
import CurrentFilters from './../../Categories/Box/CurrentFilters';

const SearchFlow = () => {
  const isMobile = useIsMobile();
  const match = useRouteMatch();
  const { params } = match;
  const { categoryID } = params;
  const { loader } = useSelector((state) => state.search);
  const { showFilters, categories } = useSelector((state) => state.categories);

  const isCats = () => {
    const subCategories = categories.filter((item) => item.id === parseInt(categoryID))[0];
    if (subCategories?.subcategories.length > 1 && subCategories?.subcategories !== undefined && !loader) {
      return true;
    } else return;
  };

  const subcategoriesExist = isCats();

  return (
    <div className='category-flow category-flow-search-view'>
      <div className='category-flow-flx'>
        {!isMobile && <CategoryFilters type='search' />}
        {isMobile && <CurrentFilters />}
        <div className='category-flow-products'>
          {isMobile && (
            <div
              className={classNames({
                'cats-filters-array': true,
                'category-tags--small': subcategoriesExist,
              })}></div>
          )}
          <SearchProducts />
        </div>
      </div>
      {isMobile && (
        <ModalBox animType='enterSide' show={showFilters} modifier='mb-filters-wrap'>
          <CategoryFiltersMobile type='search' />
        </ModalBox>
      )}
    </div>
  );
};

export default SearchFlow;
