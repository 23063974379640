import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FeaturedProducts from '../../components/BasketBox/FeaturedProducts';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import PerfectScroll from '../../components/PerfectScroll';
import DetailRating from '../../components/Product/DetailRating';
import RatingProduct from '../../components/RatingProduct';
import useIsMobile from '../../hooks/useIsMobile';
import { getReviews, productGetRelated } from '../../store/actions/products';
import BannerMerchant from './../../components/BannerMerchant';
import { productGet } from './../../store/actions/products';
import Image from './../../components/Image';

const ReviewsPage = () => {
  const [reviewType, setReviewType] = useState(false);

  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const params = useParams();
  const { reviews, product, related } = useSelector((state) => state.product);

  useEffect(() => {
    if (!reviews.length) {
      dispatch(getReviews(params.id));
    }
    if (!Object.keys(product).length) {
      dispatch(productGet(params.id));
      dispatch(productGetRelated(params.id))
    }
  }, [dispatch, params.id, product, reviews.length]);

  const reviewsFiltered = () => {
    if (reviewType === 'positive') {
      return reviews.filter((item) => item.rating > 2.5);
    }
    if (reviewType === 'negative') {
      return reviews.filter((item) => item.rating <= 2.5);
    }
    return reviews;
  };

  return (
    <ContainerBoxPage className='reviews-page'>
      <Breadcrumbs />
      <h1>Opiniones del producto</h1>
      <div className='review-data'>
        <DetailRating rating={product.rating} reviews={reviewsFiltered()} />
        <div className='review-list'>
          <div className='review-type'>
            <span onClick={() => setReviewType(false)} className={!reviewType ? 'active' : ''}>
              Todas
            </span>
            <span onClick={() => setReviewType('positive')} className={reviewType === 'positive' ? 'active' : ''}>
              Positivas
            </span>
            <span onClick={() => setReviewType('negative')} className={reviewType === 'negative' ? 'active' : ''}>
              Negativas
            </span>
          </div>
          {!isMobile && (
            <PerfectScroll className='reviews-items'>
              {reviewsFiltered().map((review, reviewIndex) => (
                <div key={reviewIndex} className='review-item'>
                  <div className='review-rating'>
                    <Image src={review.user_avatar} alt={review.user_name} />
                    <div className='info'>
                      <strong className='review-user'>{review.user_name}</strong>
                      <RatingProduct rating={review.rating} />
                    </div>
                  </div>
                  <div className='comment'>{review.comment}</div>
                </div>
              ))}
            </PerfectScroll>
          )}
          {isMobile && (
            <div className='reviews-items'>
              {reviewsFiltered().map((review, reviewIndex) => (
                <div key={reviewIndex} className='review-item'>
                  <div className='review-rating'>
                    <Image src={review.user_avatar} alt={review.user_name} />
                    <div className='info'>
                      <strong className='review-user'>{review.user_name}</strong>
                      <RatingProduct rating={review.rating} />
                    </div>
                  </div>
                  <div className='comment'>{review.comment}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {isMobile && <BannerMerchant />}
      </div>
      {!!related.length && <FeaturedProducts title='Te recomendamos' products={related} link='/' />}
    </ContainerBoxPage>
  );
};

export default ReviewsPage;
