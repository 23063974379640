import Axios from 'axios';

let baseURL = process.env.ENVIRONMENT && process.env.ENVIRONMENT !== 'dev' ? 'https://qa-api.masxpieza.com' : 'https://dev-api.masxpieza.com';

if (process.env.ENVIRONMENT === 'prod') {
  baseURL = 'https://api.masxpieza.com';
}

export const axiosApi = Axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const sleep = () => new Promise((resolve) => {
    const refreshing = window.sessionStorage.getItem('refreshing') || false;
    if (refreshing) {
      setTimeout(() => {
        resolve(sleep())
      }, 1000);
    }else{
      resolve();
    }
});

axiosApi.interceptors.request.use(async (config) => {
  const refreshing = window.sessionStorage.getItem('refreshing') || false;

  if (!refreshing && config.getToken) {
    window.sessionStorage.setItem('refreshing', 1);
    const refresh = await Axios.post(
      baseURL + '/v1/users/refresh-token',
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    ).catch((error) => error.response);

    if (refresh.data.status_code === 0) {
      window.localStorage.setItem('logged', 1);
    } else {
      window.localStorage.removeItem('logged');
    }
    window.sessionStorage.removeItem('refreshing');
  }

  if (refreshing || config.getToken) {
    await sleep();
  }

  if (config.data?.captcha || config.captcha) {
    if(process.env.ENVIRONMENT){
      const recaptcha_token = await window.grecaptcha.execute(process.env.GOOGLE_RECAPTCHA_ID, { action: 'submit' });
      config.headers.recaptcha_token = recaptcha_token;
    }
    delete config.data?.captcha;
  }

  return config;
}, (error) => Promise.reject(error));

export const interceptorError = (store) => {
  axiosApi.interceptors.response.use(
    (response) => Promise.resolve({ ok: true, ...response }),
    (error) => {
      const logged = window.localStorage.getItem('logged') || false;
      if (error.response?.status === 401 && logged) {
        return axiosApi.request({ ...error.response.config, getToken: true });
      }

      const response = {
        ok: false,
        ...error,
        data: { ...error.response?.data, ok: false } || {
          ok: false,
          msg: 'Error',
        },
      };
      if (!error.response?.data) {
        store.dispatch({ type: 'ERROR_SERVER', error: response.data });
      }

      return response;
    },
  );
};

export default axiosApi;
