import React from 'react'

const Pantone = ({ hex }) => {
    if (hex.length > 1) {
        return (
            <div className={`pantone`}>
                <span className="pantone-color" style={{ backgroundColor: hex[0] }}></span>
                <span className="pantone-color" style={{ backgroundColor: hex[1] }}></span>
            </div>
        )
    } else {
        return (
            <div className={`pantone`} style={{ backgroundColor: hex[0] }}> </div>
        )
    }
}

export default Pantone
