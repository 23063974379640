import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { interceptorError } from './axiosAPI';
import combinedReducer from './reducers';

const middleware = [
  thunk.withExtraArgument({
    api: process.env.ENVIRONMENT && process.env.ENVIRONMENT !== 'dev' ? 'https://qa-api.masxpieza.com/v1' : 'https://dev-api.masxpieza.com/v1',
  }),
];

const store = createStore(combinedReducer, composeWithDevTools(applyMiddleware(...middleware)));

interceptorError(store);

export default store;
