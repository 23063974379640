export const types = {
  SURVEY_LOADER: 'SURVEY_LOADER',
  SURVEY_GET: 'SURVEY_GET',
  SURVEY_ERROR: 'SURVEY_ERROR',
  RESET: 'RESET',
};

const initialStore = {
  name: '',
  questions: [],
  ok: false,
  loader: true,
  error: false,
  message: '',
  status_code: false,
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.SURVEY_LOADER:
      return { ...store, loader: action.loader || false };
    case types.SURVEY_GET:
      return { ...store, ...action.survey, loader: false };
    case types.SURVEY_ERROR:
      return { ...store, error: action.error, loader: false };
    case types.RESET:
      return {...initialStore}
    default:
      return { ...store };
  }
};

export default reducer;
