import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeaturedProducts from '../../components/BasketBox/FeaturedProducts';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import ColorsAlert from '../../components/Detail/ColorsAlert';
import ContainerAlert from '../../components/Detail/ContainerAlert';
import ContainerInfo from '../../components/Detail/ContainerInfo';
import ContainerInformer from '../../components/Detail/ContainerInformer';
import ModalBox from '../../components/UI/ModalBox';
import BoxLoginForm from '../../components/UserMenu/BoxLoginForm';
import useIsMobile from '../../hooks/useIsMobile';
import { analyticsRemoveCart } from '../../store/actions/analytics-events';
import { deleteItemCart, getRelated } from '../../store/actions/cart';
import { userIsReminder, userIsReminderOv } from '../../store/actions/user';

function DetailPage() {
  const history = useHistory()
  const { products, related, containers, loader, orderID } = useSelector((state) => state.cart);
  const { is_reminder_overlay, logged } = useSelector((state) => state.user);
  const { show_colors } = useSelector((state) => state.global);
  const [show_alert_modal, setShowModal] = useState(false);
  const isMobile = useIsMobile()

  // Delete Item
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = useState(false);
  const [productTo, setProductTo] = useState([]);
  const [productID, setProductID] = useState(false);

  const deleteItem = () => {
    const { product_id, quantity } = productTo;
    analyticsRemoveCart(productTo, quantity);
    dispatch(deleteItemCart(product_id));
    setShowModal(false);
  };

  const handleEditQuantity = (product) => {
    setProductID(product.id);
    setProductTo(product);
  };

  useEffect(() => {
    if (logged && is_reminder_overlay) {
      dispatch(userIsReminderOv(false))
      dispatch(userIsReminder(false))
      history.push('/carrito/datos-generales')
    }
  }, [dispatch, history, is_reminder_overlay, logged])

  useEffect(() => {
    dispatch(getRelated(orderID))
  }, [dispatch, orderID])


  return (
    <ContainerBoxPage className='detail-box-page'>
      <section className='detail-container'>
        {!isMobile && (
          <div className="detail-header">
            <Breadcrumbs />
            <h2 className="mxp-subtitle">Mi caja</h2>
          </div>
        )}
        <div className='detail-panel'>
          {/* Container information */}
          <ContainerInformer props={containers} />

          {/* Container Products and check */}
          <ContainerInfo loader={loader} toDelete={toDelete} products={products} setToDelete={setToDelete} setShowModal={setShowModal} setProductTo={handleEditQuantity} />
        </div>
      </section>
      {!!related?.length && (
        <div className="detail-related">
          <FeaturedProducts subtitle={'Te recomendamos'} products={related} />
        </div>
      )}
      <ModalBox show={show_alert_modal} modifier='mb-alerts-wrap'>
        <ContainerAlert customAction={deleteItem} setShowModal={setShowModal} cancelAction={setToDelete} title='¿Estás seguro que deseas eliminar este producto? ' />
      </ModalBox>
      <ModalBox show={show_colors} modifier='mb-colors-wrap'>
        <ColorsAlert productID={productID} />
      </ModalBox>
      <ModalBox show={is_reminder_overlay} modifier='mb-login-reminder'>
        <BoxLoginForm />
      </ModalBox>
    </ContainerBoxPage>
  );
}

export default DetailPage;
