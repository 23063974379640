import React from 'react';

const RatingProduct = ({ rating }) => {
  const starts = () => {
    const ratingRound = Math.round(rating * 2) / 2;
    const starts = [1, 2, 3, 4, 5].map((start, index) => {
      if (ratingRound >= start) {
        return <span className='icon-star active' key={index}></span>;
      } else if (ratingRound > start - 1) {
        return <span className='icon-star-half active' key={index}></span>;
      } else {
        return <span className='icon-star' key={index}></span>;
      }
    });
    return starts;
  };

  return <div className='rating-product'>{starts()}</div>;
};

export default React.memo(RatingProduct);
