import PropTypes from 'prop-types';
import React from 'react';
import AdvancedModal from './AdvancedModal';

const ErrorModal = ({ msg, close, children, className }) => {
  return (
    <AdvancedModal className={`error-modal${className ? ' ' + className : ''}`} close={close} scrollYMarginOffset={0}>
      <AdvancedModal.Body>
        <img src='/img/danger.png' alt='Error' />
        {msg && <p>{msg}</p>}
        {children}
      </AdvancedModal.Body>
      <AdvancedModal.Footer>
        <span className='btn btn-primary rounded' onClick={close}>
          ACEPTAR
        </span>
      </AdvancedModal.Footer>
    </AdvancedModal>
  );
};

ErrorModal.propTypes = {
  msg: PropTypes.string,
  close: PropTypes.func.isRequired,
};

export default ErrorModal;
