import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { highlightText } from '../helpers';
import { analyticsSearch, analyticsSearchProductDetails } from '../store/actions/analytics-events';
import { openSearch, search } from '../store/actions/search';

class SearchMobile extends React.Component {
  state = {
    text: '',
    scroll: false,
  };

  constructor(props){
    super(props);
    this.input = React.createRef();
  }


  search = ({ currentTarget }) => {
    clearTimeout(this.$search);

    const text = currentTarget.value.replace(/[^a-z0-9ñáéíóúü\s]/gi, '');

    if (text.length > 2) {
      this.$search = setTimeout(() => {
        analyticsSearch(text);
        this.props.search(text);
      }, 1000);
    }
    this.setState({ text });
  };

  closeSearch = () => {
    const body = document.querySelector('body');
    body.classList.remove('no-scroll');
    this.props.closeSearch();
    this.setState({ text: '' });
  };

  goSearch = (event) => {
    event.preventDefault();
    if (this.$search) {
      clearTimeout(this.$search);
    }
    const searchText = this.state.text.trim();
    if(!searchText) return;
    analyticsSearch(searchText);
    window.location.href = `/buscar/${searchText}`;
  };

  componentDidUpdate(prevProps, prevState) {
    const body = document.querySelector('body');
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        this.setState({ scroll: window.scrollY });
        body.classList.add('no-scroll');
      } else {
        body.classList.remove('no-scroll');
        this.setState({ scroll: false });
      }
    }
  }

  sendAnalytics = (product) => {
    this.props.closeSearch();
    analyticsSearchProductDetails(product);
  };

  removeFocus = (event) => {
    if(document.activeElement === this.input.current){
      this.input.current.blur();
    }
  }

  render() {
    return (
      <form action='#' id='search-mobile' className={this.props.open ? 'active' : ''} onSubmit={this.goSearch}>
        <div className='input-search'>
          <label className={this.props.loader ? ' search-loader' : ''}>
            <input type='text' className={!!this.state.text ? 'active' : ''} value={this.state.text} onChange={(event) => this.search(event)} ref={this.input} />
            <button>
              <i className='icon-search'></i>
            </button>
          </label>
          <span className='icon-close' onClick={() => this.closeSearch()}></span>
        </div>
        <div className='search-result-mobile' onScroll={this.removeFocus}>

{this.props.loader && <div className="lds-ellipsis center"><div></div><div></div><div></div><div></div></div>}

          {!this.props.loader && !!this.props.results.length && !this.props.error && <strong className='title'>Sugerencias</strong>}
          {!this.props.loader && <ul className='results'>
            {this.props.error && (
              <li className='not-found'>
                <img src='/img/search-alert-mobile.png' alt='Search alert icon' />
                <strong>¡Lo sentimos!</strong>
                <p>No hay resultados para tu búsqueda.</p>
              </li>
            )}
            {!!this.state.text.length &&
              this.props.results.map((result, index) => (
                <li key={index}>
                  <Link onClick={() => this.sendAnalytics(result)} to={`/producto/${result.id}/${result.code}`}>
                    <div dangerouslySetInnerHTML={{ __html: highlightText(result.name, this.props.keyword) }}></div>
                  </Link>
                </li>
              ))}
          </ul>}
        </div>
      </form>
    );
  }
}

const mapStateProps = (state) => {
  return {
    open: state.search.open,
    keyword: state.search.keyword,
    results: state.search.results,
    error: state.search.error,
    loader: state.search.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (keyword) => dispatch(search(keyword)),
    closeSearch: () => dispatch(openSearch(false)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(SearchMobile);
