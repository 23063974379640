import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import ErrorModal from './../components/ErrorModal';
import Rating from './../components/FormElements/Rating';
import Textarea from './../components/FormElements/Textarea';
import { surveyError, surveyGet, surveySubmit } from './../store/actions/survey';
import PageContainer from './PageContainer';

const SurveyPage = () => {
  const [answers, setAnswers] = useState([]);

  const { questions, loader, message, status_code, error } = useSelector((state) => state.survey);

  const { token } = useParams();

  const dispatch = useDispatch();

  const send = (event) => {
    event.preventDefault();
    dispatch(surveySubmit(token, Object.values(answers)));
  };

  useEffect(() => {
    dispatch(surveyGet(token));
  }, [dispatch, token]);

  const updateAnswer = (id, value) => {
    const clearValue = value.toString().replace(/[^\wÀ-ÿ,.\n_-\s!¡]/gi, '');
    answers[id] = { id, value: clearValue };
    setAnswers({ ...answers });
  };

  const disableSubmit = () => {
    let enable = true;
    questions.forEach(question => {
      if(question.required && enable && !answers[question.id]){
        enable = false;
      }
    });
    return !enable;
  };

  const closeError = () => dispatch(surveyError(false));

  return (
    <PageContainer template="empty" className="survey-page">
      <form action='/' onSubmit={send} className={status_code === 1301 ? 'thanks' : ''}>
        <Link to='/' className="logo">
          <img src='/img/logo.svg' alt='Más x Pieza' />
        </Link>
        {status_code === 1301 && (
          <div id='survey-thanks'>
            <h1>Gracias, apreciamos tu opinión y por eso te obsequiamos</h1>
            <p>10% de descuento en tu próxima compra.</p>
            <Link to='/' className='btn btn-primary btn-small rounded'>
              Ir al sitio de Más x Pieza
            </Link>
          </div>
        )}
        {status_code !== 1301 && (
          <>
            <h1>Encuesta de satisfacción</h1>
            {(status_code === 2 || status_code === 1302) && <p>{message}</p>}

            {loader && <Loader />}
            {!status_code && (
              <>
                <p>Compártenos tu experiencia contestando las siguientes preguntas. Tu opinión nos ayuda a mejorar el servicio que te ofrecemos.</p>
                <ul className='questions'>
                  {questions.map((question) => (
                    <li key={question.id}>
                      <strong>{question.content}</strong>
                      {question.answer_type === 'number' && <Rating value={answers[question.id]?.value} onChange={(value) => updateAnswer(question.id, value)} />}
                      {question.answer_type === 'text' && <Textarea value={answers[question.id]?.value || ''} maxLength={question.max} onChange={(value) => updateAnswer(question.id, value)} />}
                    </li>
                  ))}
                </ul>
                <button className='btn btn-primary btn-small btn-inline rounded' disabled={disableSubmit()}>
                  Enviar
                </button>
              </>
            )}
          </>
        )}
      </form>
      <footer>&copy;2021 Más x Pieza. <span>Derechos reservados.</span></footer>
      {error && <ErrorModal close={closeError}>{error.message}</ErrorModal>}
    </PageContainer>
  );
};

export default SurveyPage;
