import React from 'react';
import { useSelector } from 'react-redux';
import { imageUrl } from '../../helpers/index';
import MoneyFormat from '../MoneyFormat';

const RelatedProductAdded = () => {
  const product = useSelector((state) => state.cart.recommendedAdded);

  if (!product) return '';

  return (
    <div id='related-product-added'>
      <strong>Añadiste 1 producto(s).</strong>
      <article>
        <div className='image'>
          <img src={imageUrl(product.media[0] ? product.media[0].sizes.small : false)} alt={product.name} />
        </div>
        <div className='detail'>
          <h1>{product.name}</h1>
          <span className='price'>
            <MoneyFormat value={product.price} />
          </span>
        </div>
        <div className='icon'>
          <i className='icon-ok'></i>
        </div>
      </article>
    </div>
  );
};

export default RelatedProductAdded;
