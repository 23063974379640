export const contacts = {
    showList: {
        visible: {
            width: 270,
            x: 0
        },
        hidden: {
            width: 270,
            x: 270
        },
    },
    openBtn: {
        visible: {
            x: -270,
        },
        hidden: {
            x: 0,
        },
    },
    showOverlay: {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
    },
    upButton: {
        visible: {
            y: 0,
            opacity: 1,
        },
        hidden: {
            y: 20,
            opacity: 0
        },
    }
}