import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setAddress } from '../../store/actions/purchase';
import { getAddresses } from '../../store/actions/user';
import Loader from '../Loader';
import PerfectScroll from '../PerfectScroll';
import useIsMobile from './../../hooks/useIsMobile';
import AddNewAddress from './AddNewAddress';

const GeneralDataLogged = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { addresses, loaderAddresses } = useSelector((state) => state.user);
  const { addressID } = useSelector((state) => state.purchase);

  const [addAddress, setAddAddress] = useState(false);

  useEffect(() => {
    dispatch(getAddresses());
  }, [dispatch]);

  const handleSetAddress = (id) => dispatch(setAddress(id));

  const handleCloseFormAddress = () => {
    setAddAddress(false);
    const addressListContainer = document.querySelector('.addresses-list .ps');
    if (addressListContainer) {
      addressListContainer.scroll(0, 0);
    }
  };

  if (loaderAddresses) return <Loader spinner={{ title: 'Cargando' }} blue />;

  if (!loaderAddresses && !addresses.length) {
    return (
      <>
        <div className='not-found-addresses'>
          <i className='icon-pin'></i>
          <p>Aún no tienes direcciones agregadas.</p>
          <span className='btn btn-primary rounded-small' onClick={() => setAddAddress(true)}>
            Agregar dirección
          </span>
        </div>
        {addAddress && <AddNewAddress close={handleCloseFormAddress} />}
      </>
    );
  }

  return (
    <>
      <div id='general-data-logged'>
        {!isMobile && (
          <PerfectScroll className='addresses-list' height={500} propagation={true}>
            {addresses.map((item, itemIndex) => (
              <div key={itemIndex} className={`item${addressID === item.address_id ? ' selected' : ''}`} onClick={() => handleSetAddress(item.address_id)}>
                <i className='icon-ok'></i>
                <div>
                  <h1>{item.alias}</h1>
                  <div className='info'>
                    {item.address.name}
                    <br />
                    {item.address.phone}
                  </div>
                </div>
                <p>
                  {item.address.content} {item.address.exterior_number}
                  {item.address.interior_number ? ' int. ' + item.address.interior_number : ''}
                  <br />
                  {item.location.suburb_name}
                  <br />
                  {item.location.zip}
                  <br />
                  {item.location.city_name}, {item.location.state_name}
                </p>
              </div>
            ))}
          </PerfectScroll>
        )}
        {isMobile && (
          <div className='addresses-list'>
            {addresses.map((item, itemIndex) => (
              <div key={itemIndex} className={`item${addressID === item.address_id ? ' selected' : ''}`} onClick={() => handleSetAddress(item.address_id)}>
                <i className='icon-ok'></i>
                <div>
                  <h1>{item.alias}</h1>
                  <div className='info'>
                    {item.address.name}
                    <br />
                    {item.address.phone}
                  </div>
                </div>
                <p>
                  {item.address.content} {item.address.exterior_number}
                  {item.address.interior_number ? ' int. ' + item.address.interior_number : ''}
                  <br />
                  {item.location.suburb_name}
                  <br />
                  {item.location.zip}
                  <br />
                  {item.location.city_name}, {item.location.state_name}
                </p>
              </div>
            ))}
          </div>
        )}
        <span className='link' onClick={() => setAddAddress(true)}>
          <i className='icon-plus-circle'></i> Agregar nueva
        </span>
        <Link to='/carrito/metodo-de-entrega' className={`btn btn-primary${!addressID ? ' disabled' : ''}`}>
          CONTINUAR
        </Link>
      </div>
      {addAddress && <AddNewAddress close={handleCloseFormAddress} />}
    </>
  );
};

export default GeneralDataLogged;
