import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import ProductThumb from './ProductThumb';
import { useDispatch } from 'react-redux';
import { analyticsCategory } from '../store/actions/analytics-events';


const CategorySlider = ({name, id, products, code}) => {
  const dispatch = useDispatch();

  const sendAnalytics = () => {
    dispatch(analyticsCategory(name))
  }

  if (!id) return '';
    return (
      <section className='category-slider'>
        <div className='slider-title'>
          <h1>{name}</h1>
          <Link onClick={sendAnalytics} to={`/categoria/${id}/${code}`}>Ver todos &gt;</Link>
        </div>
        <Carousel className='carousel'>{products?.map((product) => <ProductThumb key={product.id} {...product} section="Categories" categoryName={name} />)}</Carousel>
      </section>
    );
};

export default CategorySlider;
