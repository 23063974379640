import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useIsMobile from '../../hooks/useIsMobile'
import MoneyFormat from '../MoneyFormat'
import { useDispatch } from 'react-redux';
import { setReference } from '../../store/actions/history-purchase';

const PurchasesDetail = ({ info }) => {
    const isMobile = useIsMobile()
    const history = useHistory()
    const dispatch = useDispatch();

    const progressStatus = () => {
        if (info.delivery_status === 'Pendiente de recolección' || info.delivery_status === 'En ruta a recolección' || info.delivery_status === 'Created') {
            return 'En progreso'
        }
        return info.delivery_status
    }

    const setReferenceAction = (reference) => {
        dispatch(setReference(reference));
    }

    const progress = progressStatus()

    return (
        <article className="purchase-ticket" onClick={() => {
            history.push(`/perfil/mis-compras/${info.reference}`)
            setReferenceAction(info.reference);
        }}>
            {isMobile &&
                <>
                    <div className={classNames({
                        "purchase-ticket-icon": true,
                        "purchase-ticket-icon--process": progress === 'En progreso',
                        "purchase-ticket-icon--packaging": info.delivery_status === 'Listo para recolección',
                        "purchase-ticket-icon--delivered": info.delivery_status === 'Entregado',
                        "purchase-ticket-icon--cancelled": info.delivery_status === 'Cancelado'
                    })}>
                        {progress === 'En progreso' && <span className="icon-tracking-light"></span>}
                        {info.delivery_status === 'Listo para recolección' && <span className="icon-packaging"></span>}
                        {info.delivery_status === 'Entregado' && <span className="icon-box-buyer"></span>}
                        {info.delivery_status === 'Cancelado' && <span className="icon-cancelled"></span>}
                    </div>
                    <div className="purchase-ticket-data">
                        <div className="purchase-ticket-info">
                            <h4 className="mxp-sub-subtitle">{progress}: {info.date}</h4>
                            <p className="mxp-text-clear purchase-nowrap">ID: {info.reference}</p>
                        </div>
                        <span className="icon-right-open-light"></span>
                    </div>
                </>
            }
            {!isMobile &&
                <>
                    <div className="purchase-ticket-status">
                        <div className={classNames({
                            "purchase-ticket-icon": true,
                            "purchase-ticket-icon--process": progress === 'En progreso',
                            "purchase-ticket-icon--packaging": info.delivery_status === 'Listo para recolección',
                            "purchase-ticket-icon--delivered": info.delivery_status === 'Entregado',
                            "purchase-ticket-icon--cancelled": info.delivery_status === 'Cancelado'
                        })}>
                            {progress === 'En progreso' && <span className="icon-tracking-light"></span>}
                            {info.delivery_status === 'Listo para recolección' && <span className="icon-packaging"></span>}
                            {info.delivery_status === 'Entregado' && <span className="icon-box-buyer"></span>}
                            {info.delivery_status === 'Cancelado' && <span className="icon-cancelled"></span>}
                        </div>
                        <div className="purchase-ticket-status-info">
                            <h4 className="mxp-sub-subtitle">{progress}</h4>
                            <p className="mxp-text-clear purchase-nowrap">ID: {info.reference}</p>
                        </div>
                    </div>
                    <div className="purchase-ticket-data">
                        <div className="purchase-ticket-info">
                            <div className="purchase-text">
                                <span className="mxp-text-clear">Fecha de compra:</span>
                                <span className="mxp-text">{info.date}</span>
                            </div>
                            {/* {info.delivery_status === 'Entregado' &&
                                <div className="purchase-text">
                                    <span className="mxp-text-clear">Fecha de entrega:</span>
                                    <span className="mxp-text">06/06/2021</span>
                                </div>
                            } */}
                            {
                                info.quantity !== 0 &&
                                <div className="purchase-text-inline">
                                    <span className="mxp-text-clear">Cantidad:</span>
                                    <span className="mxp-text">{info.quantity}</span>
                                </div>
                            }
                        </div>
                        <div className="purchase-ticket-price">
                            <div className="purchase-text">
                                <span className="mxp-text-clear">Total de la compra:</span>
                                <span className="mxp-text"><MoneyFormat type="mixed" money={info.total} /></span>
                            </div>
                            <span className="purchase-ticket-link">
                                Ver detalle
                                <span className="icon-right-open-light"></span>
                            </span>
                        </div>
                    </div>
                </>
            }
        </article>
    )
}

export default PurchasesDetail
