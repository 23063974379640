import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import BannerCategories from '../components/BannerCategories';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductsNotFound from '../components/Categories/ProductsNotFound';
import CategorySlider from '../components/CategorySlider';
import FooterSticky from '../components/FooterSticky';
import Loader from '../components/Loader';
import ProductFilters from '../components/ProductFilters';
import { getCategories, getCategoriesWithProducts } from '../store/actions/categories';
import { getProducts } from '../store/actions/products';
import { userActivate } from '../store/actions/user';
import CategoriesFilter from './../components/Categories/CategoriesFilters';
import CategoriesGrid from './../components/Categories/CategoriesGrid';

class CategoriesPage extends React.Component {
  state = {
    scroll: 0,
    update: false,
    categoriesSlider: false,
    filters: {},
    scrollFilters: 0,
  };
  componentDidMount() {
    if (this.props.match.path === '/usuario/activar-cuenta/:token') {
      this.props.confirmUser(this.props.match.params.token);
    }

    if (!this.props.categories.length || !this.props.products.length) {
      this.props.getCategories(true);
      window.scrollTo(0, 0);
    }
    if (this.props.match.params.recovery_token) {
      const body = document.querySelector('body');
      body.classList.add('no-scroll');
      this.props.recoveryChangeForm(this.props.match.params.recovery_token);
    }
  }

  componentDidUpdate() {
    if (this.state.update) {
      window.scroll(0, this.state.scroll);
      this.setState({ update: false });
    }

    setTimeout(() => {
      if (this.state.scrollFilters) {
        this.setState({ scrollFilters: false });
        $('html, body').animate({ scrollTop: this.state.scroll });
      }
    }, 500);
  }

  applyFilters = () => {
    if ($('.cats-with-products').length) {
      this.setState({ update: true, scroll: $('.cats-with-products').offset().top - 60, scrollFilters: true });
    }

    this.props.getCategories(true);
  };

  loadCategories = () => {
    this.setState({ update: true, scroll: window.scrollY });
    this.props.loadCategories();
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.products !== this.props.products || nextProps.loader !== this.props.loader || nextProps.filters !== this.props.filters;
  }

  goSearch = (search) => {
    window.scroll(0, 0);
    this.props.history.push(`/buscar/${search}`);
  };

  render() {
    if (!this.props.categories.length)
      return (
        <div>
          <BannerCategories />
          <Loader />
        </div>
      );
    return (
      <>
        <main>
          <Breadcrumbs className='categories' />
          <BannerCategories />
          {!this.props.grid && (
            <>
              <CategoriesFilter />
              <div className='container'>
                <ProductFilters apply={() => this.applyFilters()} items={{ filters: this.props.filters.categories, active: this.props.filters.active }} />
                <FooterSticky />

                <div className='categories'>
                  {this.props.products.length < 1 && this.props.notFound && <ProductsNotFound />}

                  {!!this.props.products.length && (
                    <>
                      <div className='cats-with-products'>{this.props.products && this.props.products.map((item) => {
                        return <CategorySlider key={item.id} {...item} />
                      })}</div>
                      {!this.state.update && (
                        <div className='load-categories'>
                          {this.props.showMore > 0 && (
                            <button className='btn orange simple' onClick={() => this.loadCategories()}>
                              Ver más <span className='icon-down-open'></span>
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {this.props.grid && <CategoriesGrid />}
        </main>
        {this.props.loader && <Loader />}
      </>
    );
  }
}

const mapStateProps = (state) => {
  return {
    banner: state.categories.banner,
    products: state.categories.categories_w_products,
    categories: state.categories.categories,
    notFound: state.categories.notFound,
    grid: state.categories.grid,
    showMore: state.categories.cat_ids_pending.length && !state.categories.hideBtnLoad,
    loader: state.categories.loader,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
    getCategories: (getProducts = false) => dispatch(getCategories(getProducts)),
    loadCategories: (id = false) => dispatch(getCategoriesWithProducts(id)),
    recoveryChangeForm: (token) => dispatch({ type: 'USER_RECOVERY_CHANGE_PASSWORD_FORM', token }),
    confirmUser: (token) => dispatch(userActivate(token)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(CategoriesPage);
