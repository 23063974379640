import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useBrand = (id) => {
  const [brand, setBrand] = useState({
    id: '',
    name: '',
    code: '',
  });

  const {brands} = useSelector(state => state.categories)

  useEffect(() => {
    const find = brands.find(item => item.id === id);
    if(find){
      setBrand(find);
    }else{
      setBrand({
        id: '',
        name: '',
        code: '',
      })
    }
  }, [brands, id]);

  return brand;
};

export default useBrand;
