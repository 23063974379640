import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PerfectScroll from '../../components/PerfectScroll';
import { isChildOfID } from '../../helpers';
import { userMenuControl } from '../../store/actions/user';
import useIsMobile from './../../hooks/useIsMobile';

const FakeSelect = ({ hook, options, placeholder, value, defaultValue, up, className, onChange, name, registerOptions = {}, label, itemHeight = 33, customValueNumber, id, completed, handle, register, all }) => {
  const [open, setOpen] = useState(false);
  const [input, setInputValue] = useState('');
  const fakeSelectRef = useRef();
  const isMobile = useIsMobile();
  const dispatch = useDispatch()

  useEffect(() => {
    if (register && name) {
      register(name, { ...registerOptions })
    }
  }, [register, name, registerOptions])

  useEffect(() => {
    if (defaultValue && onChange) {
      onChange(name, defaultValue)
    }
  }, [defaultValue, name, onChange])

  const updateValue = (option) => {
    if (handle) {
      return handle(option)
    }
    if (onChange && name) {
      onChange(name, option, { ...registerOptions, shouldDirty: true, shouldTouch: true });
    }
    else if(onChange && all){
      onChange(option)
    }
    else if (onChange) {
      if (option.display) {
        onChange(option.display);
      } else if (option.name) {
        onChange(option.name);
      } else {
        onChange(option)
      }
    }
  };

  const handleChange = (option) => {
    updateValue(option);
    setOpen(false);
    setInputValue('');
  };

  const handleChangeInput = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');
    updateValue(event.currentTarget.value);
  };

  const handleEnterInput = (event) => {
    if(event.key === 'Enter'){
      setOpen(false)
    }
  }

  useEffect(() => {
    if (!id) return;
    const checkClick = (event) => {
      const element = event.target;
      const check = isChildOfID(element, id);
      if (!check) {
        setOpen(false);
      }
    };

    if (!open) {
      window.removeEventListener('click', checkClick);
      return;
    }

    window.addEventListener('click', checkClick);

    return () => {
      window.removeEventListener('click', checkClick);
    };
  }, [id, input, open]);


  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (fakeSelectRef.current && !fakeSelectRef.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [open])

  return (
    <div
      id={id}
      className={`fake-select${open ? ' fake-select-open' : ''}${className ? ' ' + className : ''}${up ? ' up' : ' down'}${options?.length ? '' : ' disabled'}${customValueNumber ? ' input-field' : ''
        }${completed ? ' completed' : ''}`}
      ref={fakeSelectRef}>
      {label && <div className='label'>{label}</div>}
      <div className='placeholder' onClick={() => {
        if (hook) { dispatch(userMenuControl(false, false)) }
        setOpen(!open)
      }}>
        <span>{value?.label || value?.name || value?.display || value || defaultValue || placeholder || '- Seleccionar -'}</span>
        <i className='icon-down-open'></i>
      </div>

      {open && (
        <div className='items'>
          <PerfectScroll height={itemHeight * options?.length} propagation={isMobile}>
            {options?.map((option, optionIndex) => (
              <div key={optionIndex} onClick={() => handleChange(option)} className={`item${value && ((value.value && value?.value === option?.value) || value === option) ? ' selected' : ''}`}>
                {option.label || option.name || option.display || option}
              </div>
            ))}
          </PerfectScroll>
          {customValueNumber && (
            <div className='input'>
              <input type='text' inputMode='numeric' onChange={handleChangeInput} onKeyUp={handleEnterInput} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FakeSelect;
