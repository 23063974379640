
import { useSelector } from 'react-redux';
const useTotalPurchase = () => {
  const { sub_total, total, iva } = useSelector((state) => state.cart);
  const { shipping, loyalty_points, paymentMethod } = useSelector(state => state.purchase)

  const totalPayment = () => {
    const sum = total + (shipping.total || 0);
    return paymentMethod !== 'loyalty' ? sum - loyalty_points : sum;
  }

  return {
    sub_total,
    iva,
    shipping: shipping.total,
    loyalty_points,
    total_with_iva: total,
    total: totalPayment()
  }
}

export default useTotalPurchase;
