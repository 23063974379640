export const types = {
  HOME_GET_DATA: 'HOME_GET_DATA',
  SET_CURRENT_VIDEO: 'SET_CURRENT_VIDEO',
};

const initialStore = {
  catalogs: [],
  code: '',
  contact: [],
  footers: [],
  offices: [],
  phone: {},
  section: '',
  current_video: ''
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.HOME_GET_DATA:
      window.sessionStorage.setItem('home', JSON.stringify(action.data));
      return { ...store, ...action.data };
    case types.SET_CURRENT_VIDEO:
      return { ...store, current_video: action.payload };
    default:
      const homeData = JSON.parse(window.sessionStorage.getItem('home')) || {};
      return { ...store, ...homeData };
  }
};

export default reducer;
