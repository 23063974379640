import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDeleteAddress, userSetAddress } from '../../store/actions/user';
import ContainerAlert from '../Detail/ContainerAlert';
import Loader from '../Loader';
import ModalBox from './../UI/ModalBox';
import { userSetAddressAsDefault } from './../../store/actions/user';

const body = document.querySelector('body');

const AddressCard = ({ address: item }) => {
  const dispatch = useDispatch();
  const { loaderDelete } = useSelector((state) => state.user);
  const positionY = useRef(0);

  const [confirmation, setConfirmation] = useState(false);

  const handleEditBtn = () => {
    dispatch(userSetAddress(item));
  };

  const handleDelete = () => {
    handleOpenConfirmation(false);
    setTimeout(() => dispatch(userDeleteAddress(item.address_id)), 1000)
  };

  const handleSetDefault = (isDefault) => dispatch(userSetAddressAsDefault(item.address_id, item.is_default ? 0 : 1));

  const handleOpenConfirmation = (isOpen = false) => {
    setConfirmation(isOpen);
    if (isOpen) {
      positionY.current = window.scrollY;
      body.classList.add('no-scroll')
      window.scroll(0, 0);
    } else {
      body.classList.remove('no-scroll')
      window.scroll(0, positionY.current);
    }
  };

  return (
    <>
      <ModalBox show={confirmation} modifier='mb-alerts-wrap'>
        <ContainerAlert
          cancelAction={handleOpenConfirmation}
          customAction={handleDelete}
          title='¿Estás seguro que deseas eliminar
esta dirección? '></ContainerAlert>
      </ModalBox>
      <div className={`address-card${loaderDelete ? ' disabled' : ''}`}>
        {loaderDelete === item.address_id && <Loader spinner blue />}
        <div className='user-data'>
          <div className='address-title'>
            <i className={`icon-heart${item.is_default === 1 ? '' : '-empty'} check-primary`} onClick={handleSetDefault}></i>
            <h1>{item.alias}</h1>
            <i className='icon-trash trash' onClick={() => handleOpenConfirmation(true)}></i>
          </div>
          {item.address.name}
          <br />
          {item.address.phone}
        </div>
        <div className='address-data'>
          {item.address.content} {item.address.exterior_number}
          {item.address.interior_number ? ' Int. ' + item.address.interior_number : ''}
          <br />
          {item.location.suburb_name}
          <br />
          {item.location.zip}
          <br />
          {item.location.city_name}, {item.location.state_name}
          <i className='icon-edit edit' onClick={handleEditBtn}></i>
        </div>
        <div className='actions'>
          <i className={`icon-heart${item.is_default === 1 ? '' : '-empty'} check-primary`} onClick={handleSetDefault}></i>
          <i className='icon-edit edit' onClick={handleEditBtn}></i>
          <i className='icon-trash trash' onClick={() => handleOpenConfirmation(true)}></i>
        </div>
      </div>
    </>
  );
};

export default React.memo(AddressCard);
