import axiosApi from '../axiosAPI';
import { types } from '../reducers/user';
import { getCart } from './cart';
import { setSector } from './categories';
import { purchaseTokenizeCard, setAddress } from './purchase';
import { identityUserAnalytics } from './analytics-events';

export const registerUser = (userData) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  userData.catalog_id = userData.sector.id;
  delete userData.sector;
  delete userData.password_confirm;
  await axiosApi.post(`v1/users`, { ...userData, captcha: true })
    .then(async (response) => {
      /** google analytics event */
      identityUserAnalytics('Registro', 'Formulario')
      /** end - google analytcs event */
      dispatch({
        type: types.REGISTER,
        registerResponse: response.data,
      });
      if (response.data.status_code === 202) {
        dispatch(userCreateLoyaltyCard(userData.phone))
      }
    })
    .catch((error) => dispatch({ type: types.USER_ERROR, error: { message: error } }));
};

export const userActivate = (token) => async (dispatch, getStore, params) => {
  const activate = await axiosApi.post(`v1/users/activate`, { token });
  if (activate.data.status_code === 203) {
    return dispatch({ type: types.USER_CONFIRM_ERROR, error: activate.data });
  }
  return dispatch({ type: types.USER_CONFIRM });
};

export const userLogin = (userData) => async (dispatch, getStore, params) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  await axiosApi
    .post(`v1/users/signin`, { ...userData, captcha: true })
    .then(async (login) => {
      if (login.data.status_code === 206 || login.data.status_code === 207) {
        return dispatch({ type: types.USER_ERROR, error: login.data });
      }
      // analytics
      identityUserAnalytics('Login', 'Formulario')
      // end analytics
      await axiosApi.get(`v1/users/profile`).then(async (userData) => {
        dispatch({ type: types.USER_LOGIN, token: false, profile: userData.data });
        if (userData.data.catalog?.id) {
          dispatch(setSector(userData.data.catalog?.id));
        }

        if (getStore().cart.orderID) {
          const order_id = getStore().cart.orderID;

          const associate = {
            order_id,
          };

          window.localStorage.setItem('orderID', order_id);

          await axiosApi.post('v1/users/orders/associate', associate);
          dispatch(getCart(order_id));
        } else if (userData.data.order_id.length > 0) {
          window.localStorage.setItem('orderID', userData.data.order_id);
          dispatch(getCart(userData.data.order_id));
        }

        const containerOptionsMenu = document.querySelector('.user-options-menu .perfect-scroll>div');
        if (containerOptionsMenu) {
          containerOptionsMenu.scrollTop = 0;
        }
      });
    })
    .catch((error) => dispatch({ type: types.USER_ERROR, error: { message: error } }));
};

export const loadProfile = () => async (dispatch, getStore) => {
  const { logged, profile: currentProfile } = getStore().user;

  if (logged && !currentProfile) {
    const response = await axiosApi.get('v1/users/profile');
    dispatch({ type: types.USER_GET_PROFILE, profile: response.data });
    if (response.data.order_id) {
      dispatch(getCart(response.data.order_id));
    } else {
      const orderID = getStore().cart.orderID;
      if (orderID && !response.data.order_id) {
        dispatch(userAssociateOrderID(orderID));
      }
    }
  } else {
    const orderID = window.localStorage.getItem('orderID');
    if (orderID) {
      dispatch(getCart(orderID));
    }
  }
};

export const recovery_password = (email) => async (dispatch, getStore, params) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const recovery = await axiosApi.post(`v1/users/send-recovery-password-token`, { email, captcha: true });
  if (recovery.data.status_code === 209) {
    dispatch({ type: types.USER_LOADER, loader: false });
    return dispatch({ type: types.USER_RECOVERY, recovery: { recovery_success: recovery.data, recovery_fail: false } });
  } else {
    dispatch({ type: types.USER_LOADER, loader: false });
    return dispatch({ type: types.USER_RECOVERY, recovery: { recovery_fail: recovery.data, recovery_success: false } });
  }
};

export const recoveryChangePassword = (token, password, verify_id) => async (dispatch, getStore) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  //const token = getStore().user.recovery_token;
  const changePassword = await axiosApi.post(`v1/users/recovery-password`, { token, password, verify_id, captcha: true }).catch((error) => error.response);

  if (changePassword.data.status_code === 212) {
    /** google analytics event */
    window.dataLayer.push({
      accionInicioSesion: 'Cambiar o recuperar contraseña',
      metodoInicioSesion: 'Correo electrónico',
      event: 'iniciarSesion',
    });
    /** end - google analytcs event */
    return dispatch({ type: types.USER_RECOVERY_CHANGE_PASSWORD, success: changePassword.data, fail: false });
  }
  return dispatch({ type: types.USER_RECOVERY_CHANGE_PASSWORD, fail: changePassword.data, success: false });
};

/**
 * Función para actualizar/cambiar la contraseña del usuario.
 * @param {Contraseña actual del usuario} currentPassword
 * @param {Nueva contraseña establecida por el usuario} newPassword
 */

export const updatePassword = (current_password, new_password) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });

  const changePassword = await axiosApi.patch(`v1/users/profile/password`, { current_password, new_password }).catch((error) => error.response);

  if (changePassword.data.status_code === 212) {
    //Analytics
    setTimeout(() => {
      dispatch(closeSession())
    }, 3000)
    return dispatch({ type: types.CHANGE_PASSWORD, success: changePassword.data, fail: false });
  }
  dispatch({ type: types.CHANGE_PASSWORD, fail: changePassword.data, success: false });
};

export const removeErrorPassword = () => (dispatch) => dispatch({ type: types.CHANGE_PASSWORD, fail: false, success: false });

export const userGetAddresses = () => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const addresses = await axiosApi.get(`v1/users/addresses`);

  if (addresses.data.status_code === 502 || addresses.data.status_code === 401) {
    return dispatch({ type: types.USER_GET_ADDRESSES, addresses: [] });
  }

  dispatch({ type: types.USER_GET_ADDRESSES, addresses: addresses.data });
  return addresses.data;
};

export const unloadAddresses = () => (dispatch) => {
  dispatch({ type: types.USER_GET_ADDRESSES, addresses: []})
  dispatch({type: types.USER_LOADER_ADDRESSES})
}

export const userSetAddress = (addressRaw) => (dispatch) => {
  const address =
    typeof addressRaw === 'object'
      ? {
        address_id: addressRaw.address_id,
        alias: addressRaw.alias,
        address: addressRaw.address.content,
        city: {
          id: addressRaw.location.city_id,
          name: addressRaw.location.city_name,
        },
        email: false,
        exteriorNumber: addressRaw.address.exterior_number,
        interiorNumber: addressRaw.address.interior_number,
        name: addressRaw.address.name,
        phone: addressRaw.address.phone,
        references: addressRaw.address.references,
        state: {
          id: addressRaw.location.state_id,
          name: addressRaw.location.state_name,
        },
        suburb: addressRaw.location.suburb_name,
        suburb_name: addressRaw.location.suburb_name,
        suburb_id: addressRaw.location.suburb_id,
        zip: addressRaw.location.zip,
        is_default: addressRaw.is_default,
      }
      : addressRaw;
  dispatch({ type: types.USER_SET_ADDRESS_EDIT, address });
};

export const getAddresses = () => async (dispatch, getStore) => {
  const addresses = await axiosApi.get(`v1/users/addresses`);
  if (addresses.data.status_code === 502 || addresses.data.status_code === 401) {
    return dispatch({ type: types.USER_GET_ADDRESSES, addresses: [] });
  }
  let addressID = getStore().purchase.addressID;
  let address = false;

  if (!addressID && !!addresses.data.length) {
    const addressDefault = addresses.data.find((item) => item.is_default === 1);
    if (addressDefault) {
      addressID = addressDefault.address_id;
      address = {
        alias: addressDefault.alias,
        address: addressDefault.address.content,
        city: {
          id: addressDefault.location.city_id,
          name: addressDefault.location.city_name,
        },
        email: false,
        exteriorNumber: addressDefault.address.exterior_number,
        interiorNumber: addressDefault.address.interior_number,
        name: addressDefault.address.name,
        phone: addressDefault.address.phone,
        references: addressDefault.address.references,
        state: {
          id: addressDefault.location.state_id,
          name: addressDefault.location.state_name,
        },
        suburb: addressDefault.location.suburb_name,
        zip: addressDefault.location.zip,
      };
    } else {
      addressID = addresses.data[0].address_id;
    }
  }

  if (addressID && address) {
    dispatch(setAddress(addressID, address));
  }
  dispatch({ type: types.USER_GET_ADDRESSES, addresses: addresses.data });
};

export const addNewAddress = (address) => async (dispatch, getStore) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  if (!address.email) {
    address.email = getStore().user.profile.email;
  }
  address.is_default = address.is_default === 'Si' ? 1 : 0;
  address.interior_number = address.interior_number || '';
  address.references = address.references || '';

  await axiosApi
    .post(`v1/users/addresses`, address)
    .then(async (response) => {
      const addresses = await axiosApi.get(`v1/users/addresses`);
      dispatch({ type: types.USER_GET_ADDRESSES, addresses: addresses.data });
      const newAddress = addresses.data.find((item) => item.address_id === response.data.address_id);

      const formatNewAddress = {
        alias: newAddress.alias,
        address: newAddress.address.content,
        city: { id: newAddress.location.city_id, name: newAddress.location.city_name },
        email: address.email,
        exteriorNumber: newAddress.address.exterior_number,
        interiorNumber: newAddress.address.interior_number || '',
        name: newAddress.address.name,
        phone: newAddress.address.phone,
        references: newAddress.address.references || '',
        state: { id: newAddress.location.state_id, name: newAddress.location.state_name },
        suburbs: { id: newAddress.location.suburb_id, name: newAddress.location.suburb_name },
        suburb: { id: newAddress.location.suburb_id, name: newAddress.location.suburb_name },
        zip: newAddress.location.zip,
      };

      dispatch({ type: 'PURCHASE_SET_ADDRESS_ID', addressID: response.data.address_id, address: formatNewAddress });
    })
    .catch((error) => {
      if (error.response) {
        return dispatch({ type: types.USER_ERROR_ADD_ADDRESS, error: error.response.data });
      }
    });
};

export const closeSession = () => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  await axiosApi.post('v1/users/signout');
  dispatch(setSector(false));
  dispatch({ type: types.USER_CLOSE_SESSION });
  dispatch({ type: 'RESET' });
  if (window.location.pathname === '/carrito') {
    window.location.href = '/';
  }
};

export const userAssociateOrderID = (order_id) => async (dispatch, getStore) => {
  const { logged } = getStore().user;
  if (!logged) return;
  await axiosApi.post('v1/users/orders/associate', { order_id });
  dispatch({ type: types.USER_ASSOCIATE_ORDER_ID, order_id });
};

export const userOpenMenu = (open) => (dispatch) => {
  dispatch({ type: types.USER_OPEN, open });
};
export const userOpenActions = (action) => (dispatch) => {
  dispatch({ type: types.USER_OVERLAY, payload: action });
};
export const userOpenLayer = (layer) => (dispatch) => {
  dispatch({ type: types.USER_LAYER, payload: layer });
};

export const userMenuControl = (action, layer, position = false) => (dispatch) => {
  dispatch({ type: types.USER_OVERLAY, payload: action });
  if (position) {
    dispatch({ type: types.USER_LAYER, payload: { layer: layer, position: position } });
  } else {
    dispatch({ type: types.USER_LAYER, payload: { layer: layer, position: 'top' } });
  }
};

export const userChangeStep = (step) => (dispatch) => {
  dispatch({ type: types.USER_STEP, payload: step });
};

// update profile
export const userUpdateProfile = (input, value) => async (dispatch) => {
  let field = input === 'image' ? 'avatar' : input;
  const data = input === 'image' ? `data:image/jpeg;base64,${value}` : value;

  if (input === 'catalog_id') {
    field = 'catalog';
  }

  dispatch({ type: types.USER_UPDATE_PROFILE_INPUT, field, data });

  const valueToSend = input === 'catalog_id' ? value.id : value;

  await axiosApi.patch('v1/users/profile', { [input]: valueToSend });
};

export const userClearConfirmUpdateProfile = () => (dispatch) => {
  dispatch({ type: types.USER_CLEAR_CONFIRM })
}

export const userRemoveOrderID = () => (dispatch) => {
  dispatch({ type: types.USER_REMOVE_ORDER_ID });
};

export const setUserOpen = (action) => (dispatch) => {
  dispatch({ type: types.USER_LOGIN_DESKTOP, payload: action })
  dispatch(clearError())
}

export const userDeleteAddress = (address_id) => async (dispatch, getStore) => {
  if (!address_id) return;

  dispatch({ type: types.USER_LOADER_DELETE, address_id });
  const response = await axiosApi.delete('/v1/users/addresses', { data: { address_id } });
  if (response.data.status_code === 3) {
    // handle error message="Los datos enviados no cumplen con el formato correcto."
  }

  const addresses = getStore().user.addresses.filter((item) => item.address_id !== address_id);

  dispatch({ type: types.USER_GET_ADDRESSES, addresses });
};

const formatAddress = (addressRaw) => {
  return {
    address_id: addressRaw.address_id,
    alias: addressRaw.alias,
    is_default: addressRaw.is_default,
    location: {
      state_id: addressRaw.state_id,
      state_name: addressRaw.state_name,
      city_id: addressRaw.city_id,
      city_name: addressRaw.city_name,
      suburb_id: addressRaw.suburb_id,
      suburb_name: addressRaw.suburb_name,
      zip: addressRaw.zip,
    },
    address: {
      name: addressRaw.name,
      phone: addressRaw.phone,
      content: addressRaw.address,
      interior_number: addressRaw.interior_number,
      exterior_number: addressRaw.exterior_number,
      references: addressRaw.references,
      rfc: addressRaw.rfc,
    },
  };
};

export const userUpdateAddress = (data) => async (dispatch, getStore) => {
  const addressRaw = data || getStore().user.address;

  await axiosApi.put('/v1/users/addresses', addressRaw);

  const address = formatAddress(addressRaw);

  const addresses = getStore().user.addresses;

  if (addressRaw.is_default === 1) {
    const findIndexDefault = addresses.findIndex((item) => item.is_default === 1);
    if (findIndexDefault > -1) {
      addresses[findIndexDefault].is_default = 0;
    }
  }

  const index = addresses.findIndex((item) => item.address_id === addressRaw.address_id);
  addresses[index] = address;

  dispatch({ type: types.USER_GET_ADDRESSES, addresses });
};

export const userCreateAddress = (addressRaw) => async (dispatch, getStore) => {
  const response = await axiosApi.post(`v1/users/addresses`, addressRaw);

  if (response.data.address_id) {
    const address = formatAddress(addressRaw);
    address.address_id = response.data.address_id;

    if (addressRaw.is_default) {
    }

    const addresses = addressRaw.is_default
      ? getStore().user.addresses.map((item) => {
        item.is_default = 0;
        return item;
      })
      : getStore().user.addresses;

    addresses.unshift(address);

    dispatch({ type: types.USER_GET_ADDRESSES, addresses });
  }
};

export const userSetAddressAsDefault = (address_id, is_default) => async (dispatch, getStore) => {
  dispatch({ type: types.USER_LOADER_DELETE, address_id });
  await axiosApi.put('/v1/users/addresses', { address_id, is_default }); // 503 = complete

  const addresses = getStore().user.addresses.map((item) => {
    item.is_default = item.address_id === address_id ? is_default : 0;
    return item;
  });

  dispatch({ type: types.USER_GET_ADDRESSES, addresses });
};

export const userUpdateFullProfile =
  ({ name, image, phone, catalog_id, rfc }) =>
    async (dispatch) => {
      const data = {
        name,
        image,
        phone,
        catalog_id,
        rfc,
      };
      await axiosApi.patch('v1/users/profile', data);
    };

export const getLinkedSocialProfile = () => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const userSocial = await axiosApi.get('v1/users/linked-social-networks');
  if (userSocial.data.status_code !== 200) {
    dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: false });
  }

  dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: userSocial.data });
};

export const patchLinkedSocialProfile = (type) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const userSocial = await axiosApi.patch('v1/users/linked-social-networks', { social_type: type, status: false });
  if (userSocial.data.status_code !== 200) {
    dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: false });
  }
  dispatch(getLinkedSocialProfile());
  dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: userSocial.data });
  dispatch({ type: types.USER_LOADER, loader: false });
};

export const postLinkedSocialProfile = (type, access_token) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const userSocial = await axiosApi.post('v1/users/linked-social-networks', { social_type: type, access_token, captcha: true });
  if (userSocial.data.status_code !== 200 || userSocial.data.message) {
    dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: false });
    dispatch({ type: types.USER_SOCIAL_NETWORK_ERROR, error: userSocial.data })
  }
  dispatch(getLinkedSocialProfile());
  dispatch({ type: types.USER_SOCIAL_LINKED, socialNetwork: userSocial.data });
  dispatch({ type: types.USER_LOADER, loader: false });
};

export const userSocialNetworkErrorClear = () => (dispatch) => {
  dispatch({ type: types.USER_SOCIAL_NETWORK_ERROR })
}

export const userLoginSocialNetwork = (type, access_token) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  const login = await axiosApi.post('v1/users/signin-social-network', { social_type: type, access_token, captcha: true });
  if (login.data.status_code === 0) {
    identityUserAnalytics('Login', type)
    return dispatch(getProfileData(login));

  }
  dispatch({ type: types.USER_LOADER, loader: false });
  return dispatch({ type: types.USER_ERROR, error: login.data });
};

const getProfileData = (login) => async (dispatch, getStore) => {
  await axiosApi.get(`v1/users/profile`).then(async (userData) => {
    dispatch({ type: types.USER_LOGIN, token: login.data, profile: userData.data });

    if (getStore().cart.orderID) {
      const order_id = getStore().cart.orderID;

      const associate = {
        order_id,
      };

      window.localStorage.setItem('orderID', order_id);

      await axiosApi.post('v1/users/orders/associate', associate);
      dispatch(getCart(order_id));
    } else if (userData.data.order_id.length > 0) {
      window.localStorage.setItem('orderID', userData.data.order_id);
      dispatch(getCart(userData.data.order_id));
    }
  });
};

export const getUserData = (type, access_token) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true })
  const userData = await axiosApi.post('v1/users/user-data-social-network', { social_type: type, access_token, captcha: true }).catch((error) => error.response);
  if (userData.status === 200) {

    const data = {
      ...userData.data,
      social_type: type,
      access_token,
    };
    return dispatch({ type: types.USER_DATA_NETWORK, data: data });
  }
};

export const registerUserNetwork = (userData) => async (dispatch, getStore, params) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  userData.catalog_id = userData.sector.id;
  delete userData.sector;
  delete userData.password_confirm;
  await axiosApi
    .post(`v1/users/register-social-network`, { ...userData, captcha: true })
    .then(async (response) => {
      dispatch({
        type: types.REGISTER,
        registerResponse: response.data,
      });
      if (response.data.status_code === 202) {
        identityUserAnalytics('Registro', userData.social_network.social_type)
        dispatch(userCreateLoyaltyCard(userData.phone))
      }
      dispatch({ type: types.USER_LOADER, loader: false });
    })
    .catch((error) => {
      if (error.response) {
        return dispatch({ type: types.USER_ERROR, error: error.response.data });
      }
    });
};

export const userGetLoyaltyInfo = (password) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER_LOYALTY, loader: true });
  const response = await axiosApi.post('/v1/users/profile/loyalty/info', { password });
  dispatch({ type: types.USER_GET_LOYALTY, loyalty: response.data });
};

export const userHideLoyaltyInfo = () => (dispatch) => dispatch({ type: types.USER_GET_LOYALTY, loyalty: false })

export const userUpdateProfileAll = (profile, catalog) => async (dispatch) => {
  dispatch({ type: types.USER_LOADER, loader: true });
  profile.rfc = !profile.rfc ? '' : profile.rfc;
  if (profile.image) {
    profile.image = profile.image.replace('data:image/jpeg;base64,', '');
  }
  const response = await axiosApi.patch('/v1/users/profile', profile);

  if (profile.image) {
    profile.avatar = 'data:image/jpeg;base64,' + profile.image;
  }
  dispatch({ type: types.USER_UPDATE_PROFILE, confirmation: response.data, profile: { ...profile, user_name: profile.name }, catalog });
};

const formatCardData = (card, address) => {
  const { name, number, cvv: cvc, exp_month, exp_year } = card;
  return {
    number,
    name,
    exp_year,
    exp_month: ("0" + exp_month).slice(-2),
    cvc,
    phone: address.address.phone,
    address: {
      street1: `${address.address.content} ${address.address.exterior_number}${address.address.interior_number ? ' int ' + address.address.interior_number : ''}`,
      street2: address.location.suburb_name,
      city: address.location.city_name,
      state: address.location.state_name,
      zip: address.location.zip,
      country: 'Mexico',
    },
  }
}

export const userAddCard = (data) => async (dispatch, getStore) => {
  const addresses = getStore().user.addresses;

  const address = addresses.find((item) => item.is_default === 1) || addresses[0];

  const card = formatCardData(data, address);

  dispatch(purchaseTokenizeCard({ card, save: true, setCard: true }));
};

export const userCreateLoyaltyCard = (phone) => async () => {
  axiosApi.post('/v1/loyalty', { phone });
}

export const userRequestWallet = () => async (dispatch, getStore) => {
  const { profile } = getStore().user;
  dispatch({ type: types.USER_LOADER_LOYALTY, loader: true });
  const response = await axiosApi.post('/v1/loyalty', { phone: profile.phone });
  dispatch({ type: types.USER_REQUEST_WALLET, data: response.data });
}

export const userRegistered = (itIs) => (dispatch) => {
  return dispatch({
    type: types.USER_REGISTER_CORRECTLY,
    payload: itIs
  });
};

export const clearError = () => (dispatch) => {
  return dispatch({
    type: types.USER_CLEAR_ERROR
  });
};

export const userIsReminder = (itIs) => (dispatch) => {
  return dispatch({
    type: types.USER_REMINDER,
    payload: itIs
  });
};

export const userIsReminderOv = (itIs) => (dispatch) => {
  return dispatch({
    type: types.USER_REMINDER_OVERLAY,
    payload: itIs
  });
};
