import { types } from '../reducers/global'

export const globalSetPage = (currentPage) => (dispatch) => {
  return dispatch({ type: types.GLOBAL_SET_PAGE, currentPage })
}

export const showColors = (show) => (dispatch) => {
  return dispatch({ type: types.USER_SHOW_COLORS, payload: show })
}

export const getColors = (color) => async (dispatch, getStore) => {
  const { colors_info } = getStore().global
  const selectedColor = colors_info.filter(item => item.id === color.id)
  const colorExist = selectedColor.length !== 0

  if (!colorExist) {
    dispatch({
      type: types.USER_SAVE_COLORS,
      payload: color
    })
  }
}

export const updateColors = (values, color, id) => async (dispatch, getStore) => {
  const { colors_info } = getStore().global
  const colors_info_updated = colors_info
    .map(item => {
      if (item.id === id) item.selection = item.selection.map(colorSelection => {
        if (colorSelection.id === color.id) colorSelection.sel_quantity = values
        return colorSelection
      })
      return item
    })

  dispatch({
    type: types.USER_UPDATE_COLORS,
    payload: colors_info_updated
  })
}


