export function lerp(ratio, start, end) {
    return start + (end - start) * ratio;
}

export function norm(val, min, max) {
    return (val - min) / (max - min);
}

export function map(val, min1, max1, min2, max2) {
    return lerp(norm(val, min1, max1), min2, max2);
}