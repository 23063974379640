import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FakeSelect from '../../../components/FormElements/FakeSelect';
import FormInput from '../../../components/FormElements/FormInput';
import Loader from '../../../components/Loader';
import { validateName } from '../../../helpers/validations';
import { purchaseSetTokenCard, updateCardsSaved } from '../../../store/actions/purchase';
import { userAddCard, userGetAddresses } from '../../../store/actions/user';
import ProfileContainer from '../ProfileContainer';
import { AppButton } from './../../../components/UI/Buttons';
import { months, years } from './../../../helpers/index';
import useCard from './../../../hooks/useCard';
import useIsMobile from './../../../hooks/useIsMobile';
import { getCardsSaved, purchaseClearPaymentMethod } from './../../../store/actions/purchase';

const MyCardEditPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const params = useParams();

  const [card, setCard] = useState({
    name: '',
    number: '',
    exp_month: '',
    exp_year: '',
  });
  const [last4, setLast4] = useState('');

  const { cardsList, loaderCards, tokenizer_error, loaderTokenize, cardData, cardUpdate } = useSelector((state) => state.purchase);
  const { addresses, loaderAddresses } = useSelector((state) => state.user);

  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      dispatch(purchaseClearPaymentMethod(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id && !cardsList.length && loaderCards) {
      dispatch(getCardsSaved());
    } else {
      dispatch(userGetAddresses());
    }
  }, [dispatch, params.id, cardsList, loaderCards]);

  useEffect(() => {
    dispatch(userGetAddresses());
  }, [dispatch]);

  useEffect(() => {
    const currentCard = cardsList.find((x) => x.id === params.id) || false;
    if (currentCard) {
      setCard(currentCard);
      setLast4('**** **** **** ' + currentCard.last4);
    }
  }, [cardsList, params]);

  const handleCancel = () => {
    if (history.length > 2) {
      return history.goBack();
    }
    history.push('/perfil/mis-tarjetas');
  };

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  const watchValues = watch();
  const { number, exp_month, exp_year, name, cvv } = watchValues;

  const cardType = useCard(card.brand || number);

  useEffect(() => {
    if (!exp_month || !exp_year) return;
    const currentYear = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth().toString();
    if (parseInt(exp_year) === parseInt(currentYear) && parseInt(exp_month) <= parseInt(currentMonth)) {
      setError('exp_month', { type: 'invalid', message: '*Fecha de expiración invalida' });
      return;
    }
    clearErrors('exp_month');
  }, [clearErrors, exp_month, exp_year, setError]);

  const enableSubmit = () => {
    return Object.keys(watchValues).length === 5 && !Object.keys(errors).length && isDirty && cvv;
  };

  const submitForm = (data) => {
    if (params.id) {
      return dispatch(updateCardsSaved({ ...card, exp_month, exp_year, name }));
    }
    dispatch(userAddCard(data));
  };

  useEffect(() => {
    if (cardData.id && !params.id) {
      dispatch(purchaseSetTokenCard(false));
      history.push('/perfil/mis-tarjetas');
    }
  }, [dispatch, history, cardData, params.id]);

  useEffect(() => {
    if (cardUpdate.status_code === 607) {
      dispatch({ type: 'PURCHASE_CARDS_UPDATE' });
      setTimeout(() => {
        history.push('/perfil/mis-tarjetas');
      }, 1000);
    }
  }, [dispatch, history, cardUpdate]);

  return (
    <ProfileContainer className='my-card-edit-page'>
      {((params.id && loaderCards) || (!params.id && loaderAddresses)) && <Loader spinner blue />}
      {((params.id && !loaderCards) || (!params.id && !loaderAddresses && !!addresses.length)) && (
        <>
          <div className='my-card-edit-header'>
            <h1 className='title-section'>{params.id ? 'Editar tarjeta' : 'Nueva tarjeta'}</h1>
            <span className='cancel' onClick={handleCancel}>
              <i className='icon-cancel-circle'></i>
            </span>
          </div>
          <form onSubmit={handleSubmit(submitForm)}>
            <FormInput
              register={register}
              name='name'
              label='Nombre del tarjetahabiente*'
              placeholder='Jorge Eduardo Hernández Pérez'
              options={validateName}
              error={errors.name}
              setValue={setValue}
              defaultValue={card.name}
              validate='name'
            />
            {!params.id && (
              <FormInput
                register={register}
                name='number'
                label='Número de la tarjeta*'
                placeholder='16 dígitos'
                error={errors.number}
                setValue={setValue}
                defaultValue={card.number}
                validate='number'
                type='numeric'
              />
            )}
            {params.id && <FormInput readOnly register={register} name='number' label='Número de la tarjeta*' defaultValue={last4} type='numeric' disabled={!!params.id} />}
            <div className='expires'>
              <FakeSelect
                options={months}
                label='Mes*'
                placeholder={isMobile ? '00' : 'Selecciona un mes'}
                name='exp_month'
                value={exp_month}
                register={register}
                registerOptions={{ shouldValidate: true, required: true }}
                id='card-expire-month'
                onChange={setValue}
                handle={(option) => setValue('exp_month', String(option.value), { shouldDirty: true, shouldValidate: true, shouldTouch: true })}
                defaultValue={card.exp_month}
                completed={exp_month}
              />
              <FakeSelect
                options={years}
                label='Año*'
                placeholder={isMobile ? '0000' : 'Selecciona un año'}
                name='exp_year'
                register={register}
                value={exp_year}
                registerOptions={{ shouldValidate: true, required: true }}
                id='card-expire-year'
                onChange={setValue}
                handle={(option) => setValue('exp_year', String(option), { shouldDirty: true, shouldValidate: true })}
                defaultValue={card.exp_year.length === 2 ? `20${card.exp_year}` : card.exp_year}
                completed={exp_year}
              />
              {errors.exp_month && <div className='error-expires'>{errors.exp_month.message}</div>}
            </div>
            <FormInput register={register} name='cvv' label='CVV*' placeholder={cardType.cvv.placeholder} options={cardType.cvv.options} error={errors.cvv} validate='number' maxLength="4" minLength="3" type='numeric' setValue={setValue} />
            {tokenizer_error && <div className='error-x'>{tokenizer_error}</div>}
            {cardUpdate && <div className='error-x'>{cardUpdate.message}</div>}
            <AppButton type='main' txt='GUARDAR TARJETA' available={enableSubmit()} loader={loaderTokenize} small lato />
          </form>
        </>
      )}
    </ProfileContainer>
  );
};

export default MyCardEditPage;
