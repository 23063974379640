import React from 'react';
import { AppButton } from './UI/Buttons';

const AlertMessage = ({ type, title, message, onClick, children }) => {
  if (!message) return '';
  return (
    <div className='purchases-product-response purchases-product-response--error'>
      <figure className='purchases-product-icon'>
        <span className='icon-alert'></span>
      </figure>

      <h3 className='mxp-sub-subtitle'>{title || 'Lo sentimos ha ocurrido un error inesperado.'}</h3>
      {message && <p className='mxp-text'>{message}</p>}
      {onClick && <AppButton type='main' txt='Reintentar' onClick={onClick} available />}
      {children}
    </div>
  );
};

export default React.memo(AlertMessage);
