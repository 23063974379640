import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getCart, unloadCart } from '../../store/actions/cart';
import { loadProfile, setUserOpen, userIsReminder, userMenuControl, userRegistered } from '../../store/actions/user';
import { AnimatePresence, motion } from 'framer-motion';
import { headerAnimations } from '../../helpers/choreography/header';
import routes from '../../routes/cart';
import useIsMobile from './../../hooks/useIsMobile';
import BoxesList from './BoxesList';
import BoxHeaderIcon from './BoxHeaderIcon';
import ConfirmLastAdded from './ConfirmLastAdded';
import SearchForm from './SearchForm';
import UserNavigational from '../UserMenu/UserNavigations';
import BoxLoginForm from '../UserMenu/BoxLoginForm';
import UserMenu from '../UserMenu/UserList';
import UserRecovery from '../UserMenu/UserRecovery';
import { setShowFilters } from '../../store/actions/categories';
import { getAppFavorites } from '../../store/actions/favorites';
import { containerModalAnalytics } from '../../store/actions/analytics-events';


const Header = () => {
  const body = document.querySelector('body');
  const [openBox, setOpenBox] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { favorites, favorites_loader } = useSelector(state => state.favorites)
  const { showFilters } = useSelector(state => state.categories)
  const { orderID } = useSelector(state => state.cart)
  const { logged, profile, overlay, overlay_position, layer, login_desktop } = useSelector((state) => state.user);

  const userOpen = () => {
    dispatch(setUserOpen(!login_desktop))
    dispatch(userIsReminder(false))
  }

  useEffect(() => {
    if (login_desktop) {
      body.classList.add('no-scroll');
      dispatch(userMenuControl(false, 'user-login', 'top'))
    } else {
      body.classList.remove('no-scroll');
      dispatch(userMenuControl(false, false, false))
    }
  }, [login_desktop, dispatch, body])

  const { containers } = useSelector((state) => state.cart);

  const preventDefault = (event) => {
    if (location.pathname === '/') {
      event.preventDefault();
      body.classList.remove('no-scroll');
      dispatch(setUserOpen(false))

    }
  };

  useEffect(() => {
    const closeOpenBox = () => {
      if (isMobile && openBox) {
        setOpenBox(false);
      }
    };
    window.addEventListener('resize', closeOpenBox);

    return () => {
      window.removeEventListener('resize', closeOpenBox);
    };
  }, [isMobile, openBox]);

  const checkCart = window.sessionStorage.getItem('cart');

  useEffect(() => {
    if (!checkCart && orderID) {
      dispatch(getCart());
    }
    if(checkCart && !orderID) {
      window.sessionStorage.removeItem('cart');
      dispatch(unloadCart());
    }
  }, [dispatch, checkCart, orderID]);

  const container = containers.length ? [...containers].pop() : { percentage: 0 };

  const iconBox = () => {
    return container.percentage;
  };

  const openBoxAnalytics = () => {
    const containerTxt = `${containers.length} x ${container.code}`
    containerModalAnalytics(containerTxt, container.percentage)
  }

  const handleOpenBox = () => {
    if (isMobile) {
      if (!openBox) {
        openBoxAnalytics()
      }
      setOpenBox(!openBox);
    }
  };

  const handleOpenBoxHover = () => {
    openBoxAnalytics()
  }

  const purchaseRoutes = () => {
    const current_route = location.pathname;

    const matcher = routes.map((route) => {
      const itMatch = route.path === current_route
      if (itMatch) {
        return itMatch
      } else {
        return undefined
      }
    }).filter(item => item !== undefined)

    return matcher
  }

  const isPurchase = purchaseRoutes()[0];

  // Search Flow
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  // Header overlays
  const userOpenForm = (position, layer) => {
    body.classList.add('no-scroll');
    dispatch(userMenuControl(true, layer, position))

    if (isMobile) {
      dispatch(setShowFilters(false))
    }
  };

  const closeOutside = () => {
    body.classList.remove('no-scroll');
    dispatch(setUserOpen(false))
  }

  useEffect(() => {
    if (logged && !profile) {
      dispatch(loadProfile())
    }
  }, [dispatch, logged, profile])

  useEffect(() => {
    if (logged && overlay && layer === 'user-login') {
      if (!isMobile) { dispatch(setUserOpen(false)) } else { dispatch(userMenuControl(false, false, false)) }
      body.classList.remove('no-scroll');
    }
  }, [body.classList, dispatch, isMobile, layer, logged, overlay, profile])

  const { products } = useSelector((state) => state.favorites);
  const [favoritesExist, setFavoritesExist] = useState(false);
  useEffect(() => {
    if (favoritesExist) return
    if (logged && !products.length && !favorites_loader) {
      setFavoritesExist(true)
      dispatch(getAppFavorites())
      dispatch(userRegistered(false))
    }
    if (logged && products === undefined) {
      dispatch(getAppFavorites())
    }
  }, [dispatch, favorites, favoritesExist, favorites_loader, logged, products])

  return (
    <>
      <header className={classNames({
        'header-page': true,
        'header-page--is-searching': showMobileSearch,
        'header-page--space': showFilters
      })}>
        <div>
          {isMobile && <i className='icon-menu-1' onClick={() => userOpenForm('left', 'user-menu')} />}
          <Link to='/' onClick={preventDefault} className='logo'>
            <img src='/img/lg-mxp-color.svg' alt='Más x Pieza' width="2em" />
          </Link>
          {
            !isPurchase && (
              <>
                <SearchForm
                  showMobile={showMobileSearch}
                  setShowMobile={setShowMobileSearch}
                />
                {!isMobile && (
                  <div className={`login-flow`}>
                    <div className={`login-off ${logged ? 'login--avatar' : ''} ${!profile.avatar ? 'no-image' : ''} ${login_desktop ? 'login--disabled' : ''}`} onClick={userOpen}>
                      {logged ?
                        <span className="login-img">
                          <img src={profile?.avatar || '/img/ico-avatar-generico.svg'} alt={profile?.user_name} />
                        </span>
                        :
                        <i className='icon-login-2'></i>
                      }
                    </div>
                    <AnimatePresence exitBeforeEnter>
                      {/* Desktop user form */}
                      {layer === 'user-login' && !logged && <BoxLoginForm />}

                      {/* Desktop user menu */}
                      {layer === 'user-login' && logged && <UserMenu />}

                      {/* Desktop user recovery */}
                      {layer === 'user-recovery' && !logged && <UserRecovery />}
                    </AnimatePresence>
                  </div>

                )}
                <div className={`header-box-action${openBox ? ' active' : ''}`}>
                  <BoxHeaderIcon percent={iconBox()} onClick={handleOpenBox} onMouseEnter={handleOpenBoxHover} />
                  {!containers.length && (!isMobile || openBox) && <BoxesList close={handleOpenBox} />}
                  {containers.length && <ConfirmLastAdded close={handleOpenBox} />}
                </div>
              </>
            )
          }
        </div>
      </header>
      {isMobile &&
        <>
          <UserNavigational />
          <AnimatePresence exitBeforeEnter initial={false}>
            {overlay &&
              <motion.div
                variants={headerAnimations.showOverlay}
                transition={{ ease: 'easeInOut', duration: 0.4, delay: .2 }}
                initial='hidden'
                animate='visible'
                exit='hidden'
                onClick={closeOutside}
                className={`header-overlay header-overlay--${overlay_position}`}></motion.div>
            }
          </AnimatePresence>
        </>
      }
    </>
  );
};

export default React.memo(Header);
