import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { purchasePaymentLoyalty, purchaseSetPaymentMethod } from '../../store/actions/purchase';
import MoneyFormat from '../MoneyFormat';

const PaymentLoyalty = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loyalty, shipping, loyalty_points } = useSelector((state) => state.purchase);
  const { total } = useSelector((state) => state.cart);
  const { profile } = useSelector((state) => state.user);

  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(false);
  const [editAmount, setEditAmount] = useState(false);

  const validateAmount = (amount) => {
    const amountParsed = parseFloat(amount);
    if (amountParsed >= maxAmount) {
      return maxAmount;
    }
    return (amountParsed > maxAmount - 10 ? maxAmount - 10 : amountParsed).toFixed(2);
  }

  const purchaseSetAmount = () => {
    dispatch(purchasePaymentLoyalty(parseFloat(amount).toFixed(2)));
    history.push('/carrito/realizar-pago');
  };

  const handleAddAmount = () => {
    const check = validateAmount(amount);
    setAmount(check);
    dispatch(purchasePaymentLoyalty(check));
  };

  const handleChange = (event) => {
    const value = event.currentTarget.value.replace(/[^0-9.]/g, '');
    setAmount(value);
  };

  const updateAmount = (event) => {
    const value = event.currentTarget.value;
    const check = validateAmount(value);
    setAmount(check);
    setEditAmount(false);
  };

  const handleEnter = (event) => {
    const key = event.key;
    if (key === 'Enter') {
      updateAmount(event);
    }
  };

  useEffect(() => {
    if (!amount) {
      const totalCart = total + (shipping.total || 0);
      let limitAmount = 0;
      if (loyalty.points > totalCart) {
        limitAmount = totalCart;
      } else {
        limitAmount = loyalty.points > totalCart - 10 ? totalCart - 10 : loyalty.points;
      }

      setAmount(limitAmount);
      setMaxAmount(limitAmount);
    }
  }, [amount, loyalty, shipping.total, total]);

  const getTypePayment = () => {
    return parseFloat(amount) >= total + (shipping.total || 0) ? 'full' : 'partial';
  };

  const handleClick = (add) => {
    const newAmount = parseFloat(amount) + add;
    const check = newAmount <= maxAmount ? newAmount : maxAmount;
    setAmount(check);
  };

  const changePaymentMethod = () => {
    dispatch(purchaseSetPaymentMethod('tc'))
  }

  return (
    <div id='payment-loyalty'>
      <div className='info'>
        <strong>Monto acumulado:</strong>
        <i className={`icon-minus-circle${amount < 1 ? ' disabled' : ''}`} onClick={() => handleClick(-1)}></i>
        {!editAmount ? (
          <div className='total' onClick={() => setEditAmount(true)}>
            <MoneyFormat money={amount} />
          </div>
        ) : (
          <div className='total'>
            <input type='text' inputMode='numeric' value={amount} onBlur={updateAmount} onKeyDown={handleEnter} onChange={handleChange} autoFocus />
          </div>
        )}
        <i className={`icon-plus-circle${amount >= maxAmount ? ' disabled' : ''}`} onClick={() => handleClick(1)}></i>
      </div>
      {loyalty.points < total + (shipping.total || 0) && <div className='warning'>El monto acumulado no cubre el total de la compra. Te sugerimos:</div>}
      {getTypePayment() === 'partial' && <>
      <button className='btn btn-primary add-amount' onClick={handleAddAmount} disabled={loyalty_points === parseFloat(amount).toFixed(2)}>
          {loyalty_points ? 'ACTUALIZAR' : 'ABONAR'} MONTO
        </button>
        <button className='btn btn-primary-outline change-payment-method' onClick={changePaymentMethod}>OTRO MÉTODO DE PAGO</button>
      </>}
      <div className='user-info'>
        <div className='name'>
          <strong>Nombre del propietario:</strong>
          {profile.user_name}
        </div>
        <div className='card'>
          <strong>Número de la tarjeta:</strong>
          **** **** **** {loyalty.last4}
        </div>
      </div>
      {getTypePayment() === 'full' && (
        <button className='btn btn-primary' disabled={amount < 1} onClick={purchaseSetAmount}>
          CONTINUAR
        </button>
      )}
    </div>
  );
};

export default PaymentLoyalty;
