import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import AppButton from '../../components/UI/Buttons'
import { modalAnimations } from '../../helpers/choreography/modal-box'

function ContainerAlert({ setShowModal, customAction, title, cancelAction, label = "eliminar", cancel = true, type, copy }) {

    const handleClose = () => {
        if (setShowModal) {
            setShowModal(false)
        }
        if (cancelAction) {
            cancelAction(false)
        }
    }

    return (
        <div className={classNames({
            "mb-alert": true,
            "mb-alert--global": type === 'global'
        })}>
            <div className="mb-alert-info">
                <figure className="mb-alert-icon">
                    <motion.svg
                        variants={modalAnimations.triangleAnim}
                        transition={{
                            ease: "easeInOut",
                            delay: .4,
                            duration: .4
                        }}
                        initial="hidden"
                        animate="visible"
                        version="1.1" id="alert-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 481.6 419.5">
                        <path
                            className="st0"
                            id="rectangle"
                            d="M463.8,350.8L281.9,33.3C265.1,4,217.2,4,200.4,33.2L17.9,350.7c-15.2,26.4,6.8,57.4,40.7,57.4 H423C456.9,408.2,478.9,377.2,463.8,350.8z" />
                        <g id="exclamation">
                            <path
                                className="st1"
                                d="M240.2,356.5L240.2,356.5c-9.4,0-17-7.6-17-17v-105c0-9.4,7.6-17,17-17h0c9.4,0,17,7.6,17,17v105 C257.2,348.9,249.6,356.5,240.2,356.5z" />
                            <path
                                className="st1"
                                d="M240.2,187.5L240.2,187.5c-11.6,0-21-9.4-21-21v0c0-11.6,9.4-21,21-21h0c11.6,0,21,9.4,21,21v0 C261.2,178.1,251.8,187.5,240.2,187.5z" />
                        </g>
                    </motion.svg>
                </figure>
                {type === 'global' && (
                    <>
                        <p className="mxp-text mb-alert-title"> {title} </p>
                        <p className="mxp-text"> {copy} </p>
                    </>
                )}
                {!type && (
                    <p className="mxp-text">
                        {title}
                    </p>
                )}
            </div>
            <nav className="mb-alert-nav">
                {cancel && <AppButton
                    type="outlined"
                    txt="Cancelar"
                    customClick={handleClose}
                    available={true}
                />}
                <AppButton
                    type="main"
                    modifier="red"
                    txt={label}
                    customClick={() => {
                        customAction()
                    }}
                    available={true}
                />
            </nav>
        </div>
    )
}

export default ContainerAlert
