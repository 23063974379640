import React from 'react';
import { connect } from 'react-redux';
import BannerCategories from '../components/BannerCategories';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductsNotFound from '../components/Categories/ProductsNotFound';
import FooterSticky from '../components/FooterSticky';
import Loader from '../components/Loader';
import ProductFilters from '../components/ProductFilters';
import ProductThumb from '../components/ProductThumb';
import { getProducts, unloadCategory } from '../store/actions/products';
import CategoriesFilter from './../components/Categories/CategoriesFilters';
import { resetFilters } from './../store/actions/filters';

class CategoryPage extends React.Component {
  state = {
    scroll: 0,
    update: false,
  };

  loadProducts(filter = false) {
    this.setState({ update: true, scroll: window.scrollY });
    const offset = filter ? 0 : this.props.category.products.length;
    this.props.getProducts(this.props.match.params.categoryID, { offset, limit: 15, append: !filter });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadProducts(true);
      return;
    }
    if (this.state.update) {
      window.scrollTo(0, this.state.scroll);
      setTimeout(() => {
        this.setState({ update: false, scroll: 0 });
      }, 2000);
    }
  }

  componentDidMount() {
    if (!this.props.category.products || (this.props.category.id && this.props.category.id !== parseInt(this.props.match.params.categoryID))) {
      this.props.getProducts(this.props.match.params.categoryID);
    }
    window.scrollTo(0, 0);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.loader !== this.props.loader || nextProps.filters !== this.props.filters || nextProps.location.pathname !== this.props.location.pathname;
  }

  componentWillUnmount() {
    this.props.resetFilters();
  }

  goBack = () => {
    this.props.unload();
    this.props.history.goBack();
  };

  goSearch = (search) => {
    window.scroll(0, 0);
    this.props.history.push(`/buscar/${search}`);
  };

  render() {
    if (!this.props.category.products && !this.props.error) return <Loader />;
    if (this.props.error) {
      return (
        <>
          <main>
            <Breadcrumbs title={this.props.category.name} className='categories' />
            <BannerCategories />
            <CategoriesFilter />
            <div className='container'>
              <ProductFilters apply={() => this.loadProducts(true)} items={{ filters: this.props.filters.category, active: this.props.filters.active }} />
              <div className='products-list'>
                <h1 className='category-name'>{this.props.category.name}</h1>
                <div className='products'>
                  <ProductsNotFound />
                </div>
              </div>
            </div>
          </main>
          <FooterSticky />
        </>
      );
    }

    return (
      <>
        <main>
          <Breadcrumbs title={this.props.category.name} className='categories' />
          <BannerCategories />
          <CategoriesFilter />
          <div className='container'>
            <ProductFilters apply={() => this.loadProducts(true)} items={{ filters: this.props.filters.category, active: this.props.filters.active }} />
            <div className='products-list'>
              <h1 className='category-name'>{this.props.category.name}</h1>
              <div className='products'>
                {this.props.category.products &&
                  this.props.category.products.map((product, index) => <ProductThumb key={index} {...product} section='Category' showRating={true} categoryName={this.props.category.name} />)}
              </div>
              {!this.props.hideBtnLoader && (
                <div className='get-more-products'>
                  <button className='btn orange simple' onClick={() => this.loadProducts()}>
                    Ver más <span className='icon-down-open'></span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </main>
        <FooterSticky />
        {this.props.loader && <Loader />}
      </>
    );
  }
}

const mapStateProps = (state) => {
  return {
    category: state.product.category,
    hideBtnLoader: state.product.hideBtnLoader,
    error: state.product.error,
    filters: state.filters,
    loader: state.product.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (id, params, filters) => dispatch(getProducts(id, params)),
    unload: () => dispatch(unloadCategory()),
    resetFilters: () => dispatch(resetFilters()),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(CategoryPage);
