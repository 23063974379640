import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { onlyAlphaNumeric } from './../../helpers/validations';

const Textarea = ({ value, name, placeholder, maxLength, onChange, required, register, options={} }) => {
  const container = useRef();
  const customScroll = useRef();

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (container.current) {
      customScroll.current = new PerfectScrollbar(container.current, {
        suppressScrollX: true,
        wheelPropagation: false,
      });
    }

    return () => {
      if (customScroll.current) {
        customScroll.current.destroy();
        customScroll.current = null;
      }
    };
  }, [container, customScroll]);

  const handleChange = ({ currentTarget }) => {
    currentTarget.style.height = 'auto'
    currentTarget.style.height = currentTarget.scrollHeight + 'px';
    customScroll.current.update();
    onChange(name, currentTarget.value.replace(onlyAlphaNumeric, ''), {...options, shouldDirty: true});
  };

  const update = ({ currentTarget }) => {
    currentTarget.style.height = 'auto'
    currentTarget.style.height = currentTarget.scrollHeight + 'px';
    customScroll.current.update();
    onChange(currentTarget.value);
  };

  return (
    <label className={`textarea${value || active ? ' active' : ''}`}>
      {placeholder && <p className="label">
        {placeholder}
        {!required && <span>&nbsp;(Opcional)</span>}
      </p>}
      <div>
        <div ref={container}>
          {register && <textarea {...register(name, options)} maxLength={maxLength || 100}  onFocus={() => setActive(true)} onBlur={() => setActive(false)} onChange={handleChange}></textarea>}
          {!register && <textarea name={name} maxLength={maxLength || 100} onChange={update} onFocus={() => setActive(true)} onBlur={() => setActive(false)} value={value}></textarea>}
        </div>
      </div>
      {maxLength && (
        <span className='counter'>
          {value.length}/{maxLength} caracteres
        </span>
      )}
    </label>
  );
};

export default Textarea;
