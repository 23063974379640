import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { openSearch } from '../store/actions/search';
import { withRouter } from 'react-router-dom';

class FooterSticky extends React.Component {
  showFilters() {
    const productFilters = document.getElementById('product-filters');
    if(!productFilters) return;
    productFilters.classList.add('active');
    const body = document.querySelector('body');
    body.classList.add('no-scroll');
  }

  showSearchForm = () => {
    this.props.openSearch();
  };

  render() {
    return (
      <Fragment>
        <div className='footer-sticky'>
          <div>
            {!this.props.notFilter && this.props.location.pathname !== '/' && <span className='icon-filters' onClick={() => this.showFilters()}></span>}
            {(this.props.location.pathname === '/' || this.props.showPhone) && (
              <a href={`tel:${this.props.phone.office?.number.replace(/[^\d]+/g, '')}`} rel='noopener noreferrer'>
                <i className='icon-phone'></i>
              </a>
            )}
            <a target='_blank'
                  rel='noopener noreferrer'
                  href={`https://wa.me/52${this.props.phone.mobile?.number.replace(/[^\d]+/g, '')}?text=${encodeURI('Hola, que tal. Me interesa conocer más sobre sus productos.')}`}><span className='icon-whatsapp'></span></a>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    phone: state.home.phone
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearch: () => dispatch(openSearch(true)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default withRouter(createConnect(FooterSticky));
