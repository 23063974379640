import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  onlyAcceptName,
  onlyAcceptNumbers,
  onlyAcceptNumbersLetters,
  onlyAcceptPhone,
  onlyAlphaNumeric,
  validateAddress,
  validateName,
  validateNumberStreet,
  validatePhone,
  validateZip,
} from '../../../helpers';
import { isMobile } from '../../../helpers/index';
import Select from '../../FormElements/Select';
import Input from '../../Input';
import InputCheckbox from '../../FormElements/InputCheckbox';
import { getLocationByZip } from '../../../store/actions/cart';
import { addNewAddress } from '../../../store/actions/user';
import moveInput from '../../../helpers/input-auto-scroll-viewport';

class FormAddNewAddress extends React.Component {
  state = {
    suburbs: [],
    errors: {},
    isMobile: false,
    newPersonalInformation: {
      alias: '',
      name: '',
      phone: '',
      address: '',
      interiorNumber: '',
      exteriorNumber: '',
      zip: '',
      suburbs: false,
      state: false,
      city: false,
      references: '',
      principal: false,
    },
  };

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
  }

  componentDidUpdate(){
    const inputs = document.querySelectorAll('.change-address input');
    if(inputs.length>0){
      inputs.forEach(element => {
        if(!element.classList.contains('input-move')){
          element.classList.add('input-move')
          element.addEventListener('focus', (event) => {
            moveInput(event.currentTarget, '.change-address .ps', 0, 500)
          }, true);
        }
      });
    }
  }

  enableAddNewAddress = () => {
    return (
      !!this.props.data.alias &&
      !!this.props.data.name &&
      !!this.props.data.phone &&
      !!this.props.data.address &&
      !!this.props.data.references &&
      !!this.props.data.exteriorNumber &&
      !!this.props.data.zip &&
      !!this.props.data.suburbs &&
      !!this.props.data.state &&
      !!this.props.data.city
    );
  };

  update = async (name, value, error = false) => {
    const errors = { ...this.state.errors };
    if (error) {
      errors[name] = true;
    } else {
      delete errors[name];
    }
    if (name === 'submit') {
      this.props.addNewAddress({
        alias: this.props.data.alias,
        name: this.props.data.name,
        phone: this.props.data.phone,
        state_id: this.props.data.state.id,
        state_name: this.props.data.state.name,
        city_id: this.props.data.city.id,
        city_name: this.props.data.city.name,
        suburb_id: this.props.data.suburbs.id,
        suburb_name: this.props.data.suburbs.name,
        zip: this.props.data.zip,
        interior_number: this.props.data.interiorNumber,
        exterior_number: this.props.data.exteriorNumber,
        address: this.props.data.address,
        is_default: this.props.data.principal,
        references: this.props.data.references,
      });
    }

    this.props.update(name, value);

    if (name === 'zip') {
      this.props.update('suburbs', false);
      this.props.update('city', false);
      this.props.update('state', false);
      if (value.length === 5) {
        const locations = await this.props.getLocationByZip(value);
        if (locations.state) {
          this.props.update('city', locations.city);
          this.props.update('state', locations.state);
          return this.setState({ suburbs: locations.suburbs });
        }
      }
    }

    this.setState({ errors });
  };

  render() {
    return (
      <form action='/' onSubmit={() => this.update('submit', false)} id="form-add-new-address">
        {this.state.isMobile && (
          <Fragment>
            <Input placeholder='Alias' icon='icon-tag' value={this.props.data.alias} update={(value, error) => this.update('alias', value, error)} bottomDistance={0} name='alias' />

            <Input
              placeholder='Nombre completo'
              icon='icon-login'
              update={(value, error) => this.update('name', value, error)}
              value={this.props.data.name}
              min={validateName.minlength}
              max={validateName.maxlength}
              pattern={validateName.regexp}
              bottomDistance={0}
              accept={onlyAcceptName}
              minWords={validateName.minWords}
              name='name'
            />

            <Input
              placeholder='Teléfono'
              icon='icon-phone'
              type='phone'
              update={(value, error) => this.update('phone', value, error)}
              value={this.props.data.phone}
              min={validatePhone.minlength}
              max={validatePhone.maxlength}
              pattern={validatePhone.regexp}
              bottomDistance={0}
              accept={onlyAcceptPhone}
              name='phone'
            />

            <Input
              placeholder='Dirección'
              icon='icon-pin'
              update={(value, error) => this.update('address', value, error)}
              value={this.props.data.address}
              bottomDistance={0}
              min={validateAddress.minlength}
              name='address'
            />

            <div className='address-number'>
              <Input
                type='text'
                placeholder='No. Exterior'
                update={(value, error) => this.update('exteriorNumber', value, error)}
                value={this.props.data.exteriorNumber}
                bottomDistance={0}
                min={validateNumberStreet.minlength}
                max={validateNumberStreet.maxlength}
                pattern={validateNumberStreet.regexp}
                accept={onlyAcceptNumbers}
                name='exterior_number'
              />

              <Input
                type='text'
                placeholder='No. Interior'
                suffix='Opcional'
                update={(value, error) => this.update('interiorNumber', value, error)}
                value={this.props.data.interiorNumber}
                bottomDistance={0}
                min={validateNumberStreet.minlength}
                max={validateNumberStreet.maxlength}
                pattern={validateNumberStreet.regexp}
                accept={onlyAcceptNumbersLetters}
                name='interior_number'
              />
            </div>
            <Input
              type='number'
              placeholder='Código Postal'
              icon='icon-pin'
              update={(value, error) => this.update('zip', value, error)}
              value={this.props.data.zip}
              pattern={validateZip.regexp}
              max={validateZip.maxlength}
              bottomDistance={0}
              accept={onlyAcceptNumbers}
              name='zip'
            />
            <Select placeholder='Colonia' options={this.state.suburbs} keyLabel='name' value={this.props.data.suburbs} icon='icon-pin' onChange={(value) => this.update('suburbs', value)} />

            <Input placeholder='Estado' value={this.props.data.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} />
            <Input placeholder='Municipio' value={this.props.data.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} />
            <Input
              placeholder='Entre las calles'
              update={(value, error) => this.update('references', value, error)}
              value={this.props.data.references}
              bottomDistance={0}
              accept={onlyAlphaNumeric}
              name='references'
            />
            <div className='actions'>
              <InputCheckbox item={{ label: 'Usar como principal', value: 'Si' }} className='center' onChange={(value) => this.update('principal', value)} selected={this.props.data.principal} />

            </div>
          </Fragment>
        )}

        {!this.state.isMobile && (
          <Fragment>
            <div className={`row${this.state.error ? ' error' : ''}`}>
              <Input placeholder='Alias' icon='icon-tag' value={this.props.data.alias} update={(value, error) => this.update('alias', value, error)} bottomDistance={0} name='alias' />

              <Input
                placeholder='Nombre completo'
                icon='icon-login'
                update={(value, error) => this.update('name', value, error)}
                value={this.props.data.name}
                min={validateName.minlength}
                max={validateName.maxlength}
                pattern={validateName.regexp}
                bottomDistance={0}
                accept={onlyAcceptName}
                minWords={validateName.minWords}
                name='name'
              />
            </div>
            <div className={`row${this.state.error ? ' error' : ''}`}>
              <Input
                placeholder='Teléfono'
                icon='icon-phone'
                type='phone'
                update={(value, error) => this.update('phone', value, error)}
                value={this.props.data.phone}
                min={validatePhone.minlength}
                max={validatePhone.maxlength}
                pattern={validatePhone.regexp}
                bottomDistance={0}
                accept={onlyAcceptPhone}
                name='phone'
              />
              <Input
                type='number'
                placeholder='Código Postal'
                icon='icon-pin'
                update={(value, error) => this.update('zip', value, error)}
                value={this.props.data.zip}
                pattern={validateZip.regexp}
                max={validateZip.maxlength}
                bottomDistance={0}
                accept={onlyAcceptNumbers}
                name='zip'
              />
            </div>
            <div className={`row${this.state.error ? ' error' : ''}`}>
              <Input
                placeholder='Dirección'
                icon='icon-pin'
                update={(value, error) => this.update('address', value, error)}
                value={this.props.data.address}
                bottomDistance={0}
                min={validateAddress.minlength}
                name='address'
              />
              <div className='address-number'>
                <Input
                  type='text'
                  placeholder='No. Exterior'
                  update={(value, error) => this.update('exteriorNumber', value, error)}
                  value={this.props.data.exteriorNumber}
                  bottomDistance={0}
                  min={validateNumberStreet.minlength}
                  max={validateNumberStreet.maxlength}
                  pattern={validateNumberStreet.regexp}
                  accept={onlyAcceptNumbers}
                  name='exterior_number'
                />

                <Input
                  type='text'
                  placeholder='No. Interior'
                  suffix='Opcional'
                  update={(value, error) => this.update('interiorNumber', value, error)}
                  value={this.props.data.interiorNumber}
                  bottomDistance={0}
                  min={validateNumberStreet.minlength}
                  max={validateNumberStreet.maxlength}
                  pattern={validateNumberStreet.regexp}
                  accept={onlyAcceptNumbersLetters}
                  name='interior_number'
                />
              </div>
            </div>
            <div className={`row${this.state.error ? ' error' : ''}`}>
              <Select placeholder='Colonia' options={this.state.suburbs} keyLabel='name' value={this.props.data.suburbs} icon='icon-pin' onChange={(value) => this.update('suburbs', value)} />

              <Input placeholder='Estado' value={this.props.data.state.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} />
            </div>
            <div className={`row${this.state.error ? ' error' : ''}`}>
              <Input placeholder='Municipio' value={this.props.data.city.name || ''} readonly={true} icon='icon-pin' bottomDistance={0} />
              <Input
                placeholder='Entre las calles'
                update={(value, error) => this.update('references', value, error)}
                value={this.props.data.references}
                bottomDistance={0}
                accept={onlyAlphaNumeric}
                name='references'
              />
            </div>
            <div className='row actions'>
              <InputCheckbox item={{ label: 'Usar como principal', value: 'Si' }} className='center' onChange={(value) => this.update('principal', value)} selected={this.props.data.principal} />
              <span className={`btn btn-primary rounded${this.enableAddNewAddress() ? '' : ' disabled'}${this.props.loader ? ' btn-loader' : ''}`} onClick={() => this.update('submit', false)}>
                AGREGAR
              </span>
            </div>
          </Fragment>
        )}
      </form>
    );
  }
}

const mapStateProps = (state) => {
  return {
    loader: state.purchase.loader,
    purchase: state.purchase
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocationByZip: (zip) => dispatch(getLocationByZip(zip)),
    addNewAddress: (address) => dispatch(addNewAddress(address)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(FormAddNewAddress);
