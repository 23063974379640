import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

let productCartDefault = {
  product_id: false,
  name: '',
  price: 0,
  media: [],
  stock: 0,
  colors: [],
  quantity: 0
};

const useProductCart = (id) => {
  const [product, setProduct] = useState(productCartDefault)
  const { products } = useSelector((state) => state.cart);

  useEffect(() => {

    const find = products.find(item => item.product_id === parseInt(id)) || productCartDefault;

    setProduct(find);
  }, [products, id])

  return product;
}

export default useProductCart;
