class ResizeImage {
  constructor(input, limit = 10) {
    this.file = typeof input.files !== 'object' ? false : input.files[0];
    this.limit = limit;
    this.source = false;
    this.base64 = '';
    this.size = this.file ? (this.file.size / 1024 / 1024) : 0;
    this.name = this.file ? this.file.name.replace(/\.[a-z]{3,4}$/, '') : '';
    this.icon = 'picture'
  }

  onlyCode() {
    this.base64 = this.base64.replace(/.+;base64,/, '');
    return this;
  }

  setLimit(size) {
    this.limit = size;
    return this;
  }

  checkSize() {
    if (!this.file) return false;
    return this.size <= this.limit;
  }

  loadFile() {
    if (!this.file || !this.checkSize()) return false;

    return new Promise((resolve, reject) => {
      const loadFile = new FileReader();

      loadFile.onload = () => resolve({
        ok: true,
        base64: loadFile.result,
        size: this.size,
        name: this.name,
      });

      loadFile.onerror = () => reject(new Error({ ok: false, msg: 'Error al cargar archivo' }));

      loadFile.readAsDataURL(this.file);
    });
  }

  getFile = async () => {
    const data = await this.loadFile();
    if (data.ok) {
      this.base64 = data.base64;
    }
    return this;
  }

  resizeImage = ({ base64, width, ...data }) => new Promise((resolve, reject) => {
    const image = new Image();
    const canvas = document.createElement('canvas');

    image.onload = () => {
      const height = (width * image.naturalHeight) / image.naturalWidth;
      canvas.setAttribute('width', width);
      canvas.setAttribute('height', height);

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);
      const resized = ctx.canvas.toDataURL('image/jpeg', 0.8);
      resolve({
        original: base64,
        base64: resized,
        width,
        height,
        ...data
      });
    };
    image.onerror = () => reject(image);
    image.src = base64;
  });

  getResize = async (width) => {
    const data = await this.loadFile().then((response) => this.resizeImage({ ...response, width }));

    this.original = data.original;
    this.base64 = data.base64;
    this.width = data.width;
    this.height = data.height;

    return this;
  }
}

export default ResizeImage;
