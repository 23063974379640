import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isMobile } from '../helpers/index';
import { aperturaSucursalAnalytics, mapaSucursalAnalytics, telefonoSucursalAnalytics } from './../store/actions/analytics-events';

const Footer = () => {
  const menu = useSelector((state) => state.home.footers);
  const contact = useSelector((state) => state.home.contact);
  const offices = useSelector((state) => state.home.offices);
  const [openOffices, setOpenOffices] = useState(true);
  const [openOffice, setOpenOffice] = useState(false);

  const handleOpenOffices = () => {
    const changeOpenOffices = !openOffices;
    setOpenOffices(changeOpenOffices);
    if (changeOpenOffices && !isMobile()) {
      setTimeout(() => {
        const footerElement = document.getElementsByTagName('footer');
        if (footerElement.length) {
          const footerPosition = footerElement[0].getBoundingClientRect();
          window.scroll(0, window.scrollY + footerPosition.y - 100)
        }
      }, 500);
    }
  };

  const handleOpenOffice = (id) => {
    if (id === openOffice) return setOpenOffice(false);
    const office = offices.find(item => item.id === id);
    aperturaSucursalAnalytics(office.name)
    setOpenOffice(id);
  };

  const googleMapLink = (office) => `https://www.google.com/maps/search/?api=1&query=${encodeURI((office.address + ', CP.' + office.zip + ', ' + office.state + ', ' + office.city).replace('#', ''))}`;

  const handleAnalyticsOpenMap = (name) => mapaSucursalAnalytics(name)

  const handleAnalyticsPhone = (name) => telefonoSucursalAnalytics(name)

  return (
    <footer>
      <span className={`offices-link-desktop ${openOffices ? 'active' : ''}`} onClick={handleOpenOffices}>
        Sucursales <i className={openOffices ? 'icon-minus-circle' : 'icon-plus-circle'}></i>
      </span>
      {openOffices && (
        <ul id='footer-offices-desktop' className='offices-desktop animate__animated animate__fadeIn'>
          {offices.map((office, indexOffice) => (
            <li key={indexOffice}>
              <strong className='name'>{office.name}</strong>
              <div className='address'>
                <a target='_blank' onClick={() => handleAnalyticsOpenMap(office.name)} rel='noopener noreferrer' href={googleMapLink(office)}>
                  {office.street} #{office.number}
                  <br />
                  {office.suburb}
                  <br />
                  {office.zip}
                </a>
              </div>
              <div className='phone'>
                  {office.phones.map((phone, indexPhone) => (
                    <a key={indexPhone} onClick={() => handleAnalyticsPhone(office.name)} className='no-alert' href={`tel:${phone}`}>
                      {phone}
                    </a>
                  ))}
              </div>
              <div className='schedules'>
                  {office.schedules.map((schedule, scheduleIndex) => (
                    <div key={scheduleIndex}>{schedule}</div>
                  ))}
              </div>
            </li>
          ))}
        </ul>
      )}

      <ul className='menu'>
        {menu.map((item, indexItem) => (
          <li key={indexItem} className={item.code}>
            {item.code === 'sucursales' ? (
              <>
                <span onClick={handleOpenOffices}>
                  {item.name} <i className={openOffices ? 'icon-minus-circle' : 'icon-plus-circle'}></i>
                </span>
                {openOffices && (
                  <ul id='footer-offices-mobile' className='offices-mobile animate__animated animate__fadeIn'>
                    {offices.map((office, indexOffice) => (
                      <li key={indexOffice} className={openOffice === office.id ? 'open' : ''}>
                        <strong className='name' onClick={() => handleOpenOffice(office.id)}>
                          {office.name} <i className={openOffice === office.id ? 'icon-cancel-circle' : 'icon-plus-circle'}></i>
                        </strong>
                        <div className='detail'>
                          <div className='address'>
                            <a target='_blank' onClick={() => handleAnalyticsOpenMap(office.name)} rel='noopener noreferrer' href={googleMapLink(office)} className='details'>
                              {office.street} #{office.number}
                              <br />
                              {office.suburb}
                              <br />
                              {office.zip}
                            </a>
                          </div>
                          <div className='phone'>
                            {office.phones.map((phone, indexPhone) => (
                              <a key={indexPhone} onClick={() => handleAnalyticsPhone(office.name)} className='no-alert' href={`tel:${phone}`}>
                                {phone}
                              </a>
                            ))}
                          </div>
                          <div className='schedules'>
                            {office.schedules.map((schedule, scheduleIndex) => (
                              <div key={scheduleIndex}>{schedule}</div>
                            ))}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <a href={item.link} target={item.target}>
                {item.name}
              </a>
            )}
          </li>
        ))}
      </ul>
      <div className='contact'>
        <ul>
          {contact.map((item, itemIndex) => {
            if (item.name === 'Mailing') {
              return (
                <li key={itemIndex}>
                  <Link to='/contacto'>
                    <i className={item.icon}></i>
                  </Link>
                </li>
              );
            }
            if (item.name === 'Instagram') {
              return (
                <li key={itemIndex}>
                  <a target={item.target} href={item.link}>
                    <img src='/img/icon-instagram.svg' alt='Instagram' />
                  </a>
                </li>
              );
            }
            return (
              <li key={itemIndex}>
                <a target={item.target} href={item.link}>
                  <i className={item.icon}></i>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
