import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { imageUrl, moneyFormat } from '../../helpers/index';
import { addCart, cartAddRecommendedToCart } from '../../store/actions/cart';
import PerfectScroll from '../PerfectScroll';
import RelatedProductAdded from './RelatedProductAdded';

const RelatedProducts = ({ products }) => {
  const dispatch = useDispatch();

  const addRecommendedToCart = (product) => {
    dispatch(cartAddRecommendedToCart(product));
    dispatch(addCart(product.product_id));
  };

  if (products.length < 1) return '';

  return (
    <PerfectScroll className='products-related'>
      <RelatedProductAdded />
      <p>Te sugerimos estos productos que están relacionados con tu compra.</p>
      {products.map((item, index) => (
        <article key={index}>
          <div className='img'>
            <img src={imageUrl(item.media[0] ? item.media[0].sizes.small : false)} alt='related' />
          </div>
          <div className='content'>
            <h1>{item.name}</h1>
            <span className='price'>{moneyFormat(item.price)}</span>
            <span className='btn btn-primary rounded' onClick={() => addRecommendedToCart(item)}>
              Añadir
            </span>
          </div>
        </article>
      ))}
    </PerfectScroll>
  );
};

RelatedProducts.propTypes = {
  products: PropTypes.array.isRequired,
};

export default RelatedProducts;
