import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';

const STEP = 0.1;

const RangeSlider = ({ rtl, ...rest }) => {
    let { action, MIN, MAX, min, max } = rest;

    min = min >= MIN ? min : MIN;
    max = max <= MAX ? max : MAX;

    const [values, setValues] = useState([min, max])

    useEffect(() => {
        setValues([min, max]);
    }, [min, max])

    return (
        <div className={classNames({
            'range-slider': true
        })}>
            <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                rtl={rtl}
                onChange={(items) => {
                    setValues(items);
                }}
                onFinalChange={action}
                renderTrack={({ props, children }) => (
                    <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} className='range-slider-wrap'>
                        <div
                            ref={props.ref}
                            className='range-slider-bg'
                            style={{
                                background: getTrackBackground({
                                    values,
                                    colors: ['#ccc', '#0089e4', '#ccc'],
                                    min: MIN,
                                    max: MAX,
                                    rtl,
                                }),
                                alignSelf: 'center'
                            }}>
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ index, props, isDragged }) => (
                    <div {...props} style={{ ...props.style }} className='range-slider-ball'>
                        <div className='range-slider-thumb'>${values[index]}</div>
                        <div
                            style={{
                                backgroundColor: isDragged ? '#0089e4' : '#0089e4',
                            }}
                            className='range-slider-indicator'
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default React.memo(RangeSlider);
