export const headerAnimations = {
    showSearchMobile: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: 50
        },
    },
    showCatsList: {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 0,
            y: -50
        },
    },
    showOverlay: {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0
        },
    },
    showMenu: {
        visible: {
            x: 0,
        },
        hidden: {
            x: -500,
        }
    },
    showMenuDesktop: {
        visible: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0,
            y: -50,
        }
    },
    showLogin: {
        visible: {
            y: 0,
        },
        hidden: {
            y: -500,
        }
    }
}