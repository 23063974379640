import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from '../Carousel';
import { getCategories, categoriesCarouselSetActive } from '../../store/actions/categories';
import CarouselCatsItem from './CarouselCatsItem';
import { Link, useParams } from 'react-router-dom';
import { isChildOfClass } from '../../helpers';

const CategoriesFiltersCarousel = () => {
  const { categories } = useSelector((state) => state.categories);
  const { categoryID } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    const hideDropDown = ({ target }) => {
      const isChild = isChildOfClass(target, 'carousel-cats-item')
      if (!isChild) {
        dispatch(categoriesCarouselSetActive(false));
      }
    }
    window.addEventListener('click', hideDropDown)

    return () => {
      window.removeEventListener('click', hideDropDown)
    }
  }, [dispatch])

  const resetCurrentActive = () => {
    dispatch(categoriesCarouselSetActive(false));
  }

  return (
    <section id='carousel-cats'>
      <h1>categoríassss</h1>
      {!categories.length && <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}
      {!!categories.length && <Carousel className='items' onDragStart={resetCurrentActive} hasArrows>
        <div className={`carousel-cats-item${!categoryID ? ' selected' : ''}`}>
          <Link to="/categorias">Todos los productosss</Link>
        </div>
        {categories.map((category) => (
          <CarouselCatsItem key={category.id} {...category} onChange={() => console.warn} />
        ))}
      </Carousel>
      }
    </section>
  );
};

export default React.memo(CategoriesFiltersCarousel);
