// import Loader from '../components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import AddToBox from '../components/BasketBox/AddToBox';
import AddToBox2 from '../components/BasketBox/AddToBox2';
import FeaturedProducts from '../components/BasketBox/FeaturedProducts';
import Breadcrumbs from '../components/Breadcrumbs';
import BtnAddFavorite from '../components/BtnAddFavorite';
import Comments from '../components/Comments';
import Image from '../components/Image';
import Loader from '../components/Loader';
import MoneyFormat from '../components/MoneyFormat';
import CommentsDesktop from '../components/Products/CommentsDesktop';
import ProductFeatures from '../components/Products/ProductFeatures';
import SliderHeader from '../components/Products/SliderHeader';
import RatingProduct from '../components/RatingProduct';
import SliderImg from '../components/SliderImg';
import useIsMobile from '../hooks/useIsMobile';
import { productoDetailAnalytics } from '../store/actions/analytics-events';
import { updateLastVisited } from '../store/actions/categories';
import { getProduct, unloadProduct } from '../store/actions/products';
import ContainerBoxPage from './../components/containers/ContainerBoxPage';
import ColorsAlert from './../components/Detail/ColorsAlert';
import ModalBox from './../components/UI/ModalBox';

const body = document.querySelector('body');

const ProductPage = () => {
  const [colorSelected, setColorSelected] = useState(false);
  const { logged } = useSelector((state) => state.user);
  const { product, related, reviews, loaderProduct, listName } = useSelector((state) => state.product);
  const hasCompare = useSelector((state) => !!state.compare.compare.length);
  const [selectColors, setSelectColors] = useState(false)

  const { id } = useParams();
  const isMobile = useIsMobile();

  const analyticsDetail = useRef(true);

  const dispatch = useDispatch();

  useEffect(() => {
    body.classList.remove('no-scroll');
    dispatch(getProduct(id));
    updateLastVisited(id)
    return () => {
      dispatch(unloadProduct());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!colorSelected && !!product.colors?.length) {
      setColorSelected(product.colors[0]);
    }
  }, [colorSelected, product]);

  useEffect(() => {
    if (analyticsDetail.current && product.name) {
      productoDetailAnalytics({product, listName});
    }
  }, [product, listName]);

  return (
    <ContainerBoxPage className='product-page'>
        <Breadcrumbs title='Detalle' productCategory={product.categories ? product.categories[0] : false} className='categories' />
        <>
        {loaderProduct && <Loader spinner={{ title: 'Cargando'}} blue />}
        {!loaderProduct && !!product.name && <div className='product-slider-content'>
          {!!product?.media.length && (isMobile ? <SliderImg images={product.media} productID={product.id} logged={logged} /> : <SliderHeader images={product.media} />)}
          {!product.media.length && (
            <div className='default-image'>
              <Image />
            </div>
          )}

          <div className='product-detail'>
            {!isMobile && (
              <>
                <div id='zoom-detail'></div>
                <div className='product-title'>
                  <h1>{product.name}</h1>
                  {logged && <BtnAddFavorite id={product.id} />}
                </div>
                <span className='price'>
                  <MoneyFormat money={product.price} />
                </span>
                {product.colors.length > 1 && <AddToBox2 onClick={() => setSelectColors(true)} />}
                {product.colors.length === 1 && <AddToBox2 />}
              </>
            )}
            {isMobile && (
              <div className='colors-mobile'>
                <span>{colorSelected?.name}</span>
                {product.colors?.length > 1 && (
                  <ul>
                    {product.colors.map((color, colorIndex) => (
                      <li key={colorIndex} className="color-item">
                        {color.colors.map((item, itemIndex) => {
                          return <div style={{ backgroundColor: item }} key={itemIndex}></div>;
                        })}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            {product.description && <div className='description'>{product.description}</div>}
            {!isMobile && (
              <>
                <div className='rating-desktop'>
                  <RatingProduct rating={product.rating} />
                  {!!reviews.length && <span>{reviews.length} {reviews.length > 1 ? 'opiniones' : 'opinión'}</span>}
                </div>
                <div className='colors-desktop'>
                  Color: <span>{colorSelected?.name}</span>
                  {product.colors?.length > 1 && (
                    <ul>
                      {product.colors.map((color, colorIndex) => (
                        <li key={colorIndex} className="color-item">
                          {color.colors.map((item, itemIndex) => {
                            return <div key={itemIndex} style={{ backgroundColor: item }}></div>;
                          })}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>
          {!isMobile && (
            <div className='product-features'>
              <ProductFeatures product={product} />
              {hasCompare && (
                <Link to={`/comparar/${product.id}/${product.code}`} className='btn-compare'>
                  Comparar producto
                </Link>
              )}
            </div>
          )}
        </div>}

        {/* ------- */}

        {isMobile && !loaderProduct && (
          <>
            <ProductFeatures product={product} />
            {hasCompare && (
              <Link to={`/comparar/${product.id}/${product.code}`} className='btn-compare'>
                Comparar producto
              </Link>
            )}
            <div className='rating-by-users'>
              <strong>Valoración de los usuarios</strong>
              <RatingProduct rating={product.rating} />
              {!!reviews.length && <span>{reviews.length} opiniones</span>}
            </div>
            <button className='btn btn-primary rounded-small buy-now'>COMPRAR AHORA</button>
          </>
        )}
        {!loaderProduct && !!reviews.length && (isMobile ? <Comments /> : <CommentsDesktop />)}

        <div className='tutorial-banner'>
          <div>
            <img src={isMobile ? '/img/banner-mobile.jpg' : '/img/banner-desktop-pedido.jpg'} alt='¿Cómo hacer mi primer pedido?' />
            <div className='tutorial-banner-content'>
              {!isMobile && <img src='/img/icon-box.png' alt='box' />}
              <div>
                <h1>¿Cómo hacer mi primer pedido?</h1>
                {!isMobile && (
                  <>
                    Descúbre las ventajas y capacidad de tu caja.
                    <strong>¡Ahorra a lo grande!</strong>
                  </>
                )}
              </div>
              <Link to='/tutoriales' className='btn btn-primary btn-primary-outline rounded-small'>
                VER TUTORIAL
              </Link>
            </div>
          </div>
        </div>
        {!!related.length && <FeaturedProducts subtitle='Te recomendamos' products={related} />}
        {isMobile && product.colors?.length > 1 && <AddToBox  onClick={() => setSelectColors(true)} />}
        {isMobile && product.colors?.length === 1 && <AddToBox />}
      </>
      {product.colors?.length > 1 && <ModalBox show={selectColors} modifier='mb-colors-wrap' close={() => setSelectColors(false)}>
        <ColorsAlert productID={id} product={product} close={() => setSelectColors(false)}/>
      </ModalBox>}
    </ContainerBoxPage>
  );
};

export default React.memo(ProductPage);
