export const types = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT: 'GET_PRODUCT',
  PRODUCT_GET: 'PRODUCT_GET',
  PRODUCT_RELATED: 'PRODUCT_RELATED',
  LOAD_PRODUCT_CATEGORY: 'LOAD_PRODUCT_CATEGORY',
  UNLOAD: 'UNLOAD',
  UNLOAD_CATEGORY: 'UNLOAD_CATEGORY',
  ERROR: 'ERROR',
  LOADER: 'LOADER',
  PRODUCT_DETAIL_LOADERS: 'PRODUCT_DETAIL_LOADERS',
  REMOVE_LOADER: 'REMOVE_LOADER',
  PRODUCT_GET_REVIEWS: 'PRODUCT_GET_REVIEWS',
  PRODUCTS_DETAIL_RESET: 'PRODUCTS_DETAIL_RESET',
  PRODUCTS_SET_MAX_STOCK: 'PRODUCTS_SET_MAX_STOCK',
  PRODUCT_SET_ANALYTICS_LIST: 'PRODUCT_SET_ANALYTICS_LIST',
  PRODUCT_RELOAD_CONTENT: 'PRODUCT_RELOAD_CONTENT',
};

const initialStore = {
  category: {},
  disableBtnReviews: false,
  error: false,
  hideBtnLoader: false,
  loadMore: true,
  loader: true,
  loaderProduct: true,
  loaderRelated: true,
  loaderCompare: true,
  product: {},
  related: [],
  reviews: [],
  listName: 'NA',
  reloadContent: false,
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.PRODUCT_SET_ANALYTICS_LIST:
      return {...store, listName: action.list || 'NA'}
    case types.LOADER:
      return { ...store, loader: true };
    case types.REMOVE_LOADER:
      return { ...store, loader: false };
    case types.GET_PRODUCTS:
      return { ...store, category: action.category, error: false, loader: false, hideBtnLoader: action.hideBtnLoader || false, loaderProduct: false };
    case types.LOAD_PRODUCT_CATEGORY:
      const updateCategory = store.category;
      updateCategory.products = [...updateCategory.products, ...action.category.products];
      return { ...store, category: { ...updateCategory }, loader: false, hideBtnLoader: action.hideBtnLoader };
    case types.UNLOAD_CATEGORY:
      return { ...store, category: {}, error: false, loader: false };
    case types.GET_PRODUCT:
      return { ...store, product: action.product, loader: false, related: action.related, loaderProduct: false };
    case types.PRODUCT_GET_REVIEWS:
      return { ...store, reviews: [...store.reviews, ...action.reviews], disableBtnReviews: action.disableBtnReviews };
    case types.PRODUCT_GET:
      return { ...store, product: action.product, loader: false }
    case types.PRODUCT_RELATED:
      return { ...store, related: action.related, loader: false, loaderRelated: false }
    case types.UNLOAD:
      return { ...store, product: {}, error: false, loader: false };
    case types.ERROR:
      return { ...store, error: action.error, loader: false, hideBtnLoader: true };
    case types.PRODUCT_DETAIL_LOADERS:
      return {...store, loaderProduct: action.loaderProduct || true, loaderRelated: action.loaderRelated || true, loaderCompare: action.loaderCompare || true };
    case types.RESET:
    case types.PRODUCTS_DETAIL_RESET:
      return { ...initialStore };
    case types.PRODUCTS_SET_MAX_STOCK:
      return { ...store, product: { ...store.product, quantity_available: action.stock } }
    case types.PRODUCT_RELOAD_CONTENT:
      return { ...store, reloadContent: action.reload }
    default:
      return { ...store };
  }
};

export default reducer;
