import urlParams from 'params-url';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { purchaseConfirmationPaypal } from '../../store/actions/purchase';
import ContainerBoxPage from './../../components/containers/ContainerBoxPage';

const CompletePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { paymentPaypal: data } = useSelector((state) => state.purchase);

  useEffect(() => {
    const { token } = urlParams.parse(location.search);
    window.grecaptcha.ready(() => {
      dispatch(purchaseConfirmationPaypal(token));
    });
  }, [dispatch, location]);

  useEffect(() => {
    if (data.reference) {
      history.push('/carrito/confirmacion');
    }
  }, [history, data]);

  return (
    <ContainerBoxPage className='complete-page' style={{ background: '#fff' }}>
      {!data.message && <Loader spinner={{ title: 'Procesando' }} blue />}
      {data.message && !data.reference && <div id="error-paypal">
      <i className='icon-alert'></i>
      <p>Ha ocurrido un error al procesar
tu pago a través de PayPal.</p>
      <p>Por favor, inténtalo nuevamente o selecciona
otro método de pago.</p>
      <Link to="/carrito/datos-generales" className="btn btn-primary rounded-small">VOLVER A INTENTAR</Link>
      <Link to="/carrito/datos-generales" className="btn btn-primary rounded-small btn-primary-outline">CAMBIAR MÉTODO DE PAGO</Link>
    </div>}
    </ContainerBoxPage>
  );
};

export default CompletePage;
