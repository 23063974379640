import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react'
import { clearError, setUserOpen, userIsReminder, userIsReminderOv, userLoginSocialNetwork } from '../../store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { headerAnimations } from '../../helpers/choreography/header';
import { userMenuControl } from '../../store/actions/user';
import useIsMobile from '../../hooks/useIsMobile'
import AppButton from '../UI/Buttons';
import LoginForm from './LoginForm'
import classNames from 'classnames';

const BoxLoginForm = () => {
    const ref = useRef()
    const body = document.querySelector('body');
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const history = useHistory()

    const btnGoogle = useRef(false) // referencia a un elemento html


    // const userOpenForm = (open) => dispatch({ type: 'USER_OPEN', open });
    const { layer, login_desktop, is_reminder, is_reminder_overlay } = useSelector((state) => state.user)


    const handleGoogleConnect = (googleUser) => { }

    useEffect(() => {
        const attachSigning = (element) => {
            window.auth2.attachClickHandler(element, {},
                function (googleUser) {
                    dispatch(userLoginSocialNetwork('google', googleUser.getAuthResponse().id_token));
                }, function (error) {

                });
        }

        if (window.gapi && btnGoogle.current) {
            window.gapi.load('auth2', function () {
                window.auth2 = window.gapi.auth2.init({
                    client_id: process.env.GOOGLE_SIGNING_CLIENT_ID,
                    cookiepolicy: 'single_host_origin',
                });
                attachSigning(btnGoogle.current);
            });
        }
    }, [dispatch])

    const FB = window.FB;

    const statusChangeCallback = useCallback((response) => {
        const facebookLogin = () => {
            FB.login(function (response) {
                if (response.authResponse) {
                    dispatch(userLoginSocialNetwork('facebook', response.authResponse.accessToken));
                } else {
                    // not auth / cancelled the login!
                }
            });
        }
        if (response.status === 'connected') {
            dispatch(userLoginSocialNetwork('facebook', response.authResponse.accessToken));
        } else {
            facebookLogin();
        }
    }, [dispatch, FB])

    useEffect(() => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: process.env.FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v2.7'
            });

            FB.getLoginStatus(function (response) {
                statusChangeCallback(response);
            });
        };
    }, [FB, statusChangeCallback])

    const checkLoginState = () => {
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (login_desktop && ref.current && !ref.current.contains(e.target)) {
                dispatch(setUserOpen(false))
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [dispatch, login_desktop])

    return (
        <motion.div
            variants={is_reminder ? false : isMobile ? headerAnimations.showMenu : headerAnimations.showMenuDesktop}
            transition={is_reminder ? false : isMobile ? { ease: 'easeInOut', duration: 0.4 } : { ease: 'easeInOut', duration: 0.2 }}
            initial='hidden'
            animate='visible'
            exit='hidden'
            className={classNames({
                "login": true,
                "login-overlayed": layer === 'user-login' && isMobile,
                "login-header": !is_reminder_overlay
            })}
            ref={ref}
        >

            <div className="login-flx">
                <div className="login-box">
                    {isMobile && !is_reminder && (
                        <>
                            <AppButton
                                type="icon"
                                icon="close"
                                modifier="close"
                                customClick={() => {
                                    body.classList.remove('no-scroll');
                                    dispatch(userMenuControl(false, false, false))
                                    dispatch(clearError())
                                }}
                            />
                            <h3 className="mxp-subtitle">Inicia sesión</h3>
                        </>
                    )}

                    {is_reminder && (
                        <div className="login-reminder-head">
                            <h3 className="mxp-subtitle">¡Pst!</h3>
                            <p className="mxp-text">Para realizar tu compra de manera más fácil
                                y en menor tiempo, inicia sesión.</p>

                            <AppButton
                                type="icon"
                                icon="close"
                                modifier="close"
                                customClick={() => {
                                    body.classList.remove('no-scroll');
                                    dispatch(userIsReminderOv(false))
                                    dispatch(userIsReminder(false))
                                    dispatch(clearError())
                                }}
                            />
                        </div>
                    )}
                    <LoginForm />
                    <div className={`login-recovery ${is_reminder ? 'login-recovery-reminder' : ''}`} onClick={() => {
                        dispatch(userMenuControl(true, 'user-recovery', 'top'))
                        dispatch(userIsReminderOv(false))
                        dispatch(userIsReminder(false))
                    }}>
                        <p className="mxp-text">¿Olvidaste tu contraseña?</p>
                    </div>
                    {is_reminder && isMobile && (
                        <div className="login-register login-register-reminder">
                            <p className="mxp-text">¿No tienes una cuenta?</p>
                            <p className="mxp-text mxp-text-action" onClick={() => {
                                history.push('/box/registro')
                                dispatch({ type: 'REGISTER_CLEAR' })
                                dispatch(setUserOpen(false))
                                dispatch(userIsReminderOv(false))
                                dispatch(userIsReminder(false))
                            }}>Regisrate aquí</p>
                        </div>
                    )}
                    <div className="login-connect">
                        <AppButton
                            type="connect"
                            txt="Inicia sesión con Facebook"
                            icon="facebook"
                            modifier="login-facebok"
                            available={true}
                            customClick={checkLoginState} />
                        {<AppButton
                            type="connect"
                            txt="Inicia sesión con Google+"
                            icon="google"
                            modifier="google"
                            available={true}
                            customClick={handleGoogleConnect}
                            refValue={btnGoogle} />}

                    </div>
                    {is_reminder && !isMobile && (
                        <div className="login-register login-register-reminder">
                            <p className="mxp-text">¿No tienes una cuenta?</p>
                            <p className="mxp-text mxp-text-action" onClick={() => {
                                history.push('/box/registro')
                                dispatch({ type: 'REGISTER_CLEAR' })
                                dispatch(setUserOpen(false))
                                dispatch(userIsReminderOv(false))
                                dispatch(userIsReminder(false))
                            }}>Regisrate aquí</p>
                        </div>
                    )}
                    <div className="login-register">
                        {is_reminder && (
                            <p className="mxp-text mxp-text-reminder" onClick={() => {
                                history.push('/carrito/datos-generales')
                                dispatch(userIsReminderOv(false))
                                dispatch(userIsReminder(false))
                            }}>Continua como invitado</p>
                        )}
                        {!is_reminder && (
                            <>
                                <p className="mxp-text">¿No tienes una cuenta?</p>
                                <p className="mxp-text mxp-text-action" onClick={() => {
                                    history.push('/box/registro')
                                    dispatch({ type: 'REGISTER_CLEAR' })
                                    dispatch(setUserOpen(false))
                                }}>Regisrate aquí</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default BoxLoginForm
