import axiosApi from '../axiosAPI';
import { types } from '../reducers/categories';
import { vistaAnalytics } from './analytics-events';
import { filterParams, setFilters } from './filters';

export const updateMostWanted = (search) => async () => {
  axiosApi.post('v1/products/most-wanted', { search, captcha: true }).catch((error) => error.response);
}

export const getLastVisited = () => async (dispatch) => {
  const userID = window.localStorage.getItem('user_id');
  dispatch({ type: types.LOAD_LAST_VISITED });

  if (userID) {
    const last_visited_call = await axiosApi(`v1/products/recently-viewed?limit=10&offset=0&userId=${userID}`);

    if (last_visited_call.ok) {
      dispatch({
        type: types.GET_LAST_VISITED,
        payload: [last_visited_call.data.category]
      })
      dispatch({ type: types.LOAD_REMOVE_LAST_VISITED });
    }

  } else {
    dispatch({ type: types.LOAD_REMOVE_LAST_VISITED });
  }
}

export const updateLastVisited = (id) => async (dispatch) => {
  const userID = window.localStorage.getItem('user_id')

  if (!userID) {
    const last_visited = {
      "user_id": "",
      "product_id": id,
      "captcha:": true
    }

    const updateLastVisited = await axiosApi.post('v1/products/recently-viewed', last_visited).catch((error) => error.response);
    const { user_id } = updateLastVisited.data
    window.localStorage.setItem('user_id', user_id);
  } else {
    const last_visited = {
      "user_id": userID,
      "product_id": id,
      "captcha:": true
    }
    await axiosApi.post('v1/products/recently-viewed', last_visited).catch((error) => error.response);
  }
}

export const getCustomCategories = () => async (dispatch) => {
  dispatch({ type: types.LOAD_CUSTOM_CATEGORIES });

  let custom_categories = [];
  const sellers_call = await axiosApi(`v1/products/best-sellers`);
  const wanted_call = await axiosApi(`v1/products/most-wanted`);

  custom_categories = [{
    best_sellers: { ...sellers_call.data.category, id: 100 },
    most_wanted: wanted_call.data
  }]


  if (sellers_call.ok && wanted_call.ok) {
    dispatch({ type: types.LOAD_REMOVE_CUSTOM_CATEGORIES });
    dispatch({
      type: types.GET_CUSTOM_CATEGORIES,
      payload: custom_categories
    })
  }

}

const getProducts = async (params, IDs, filters, catalog_id = 3) => {
  const responseProducts = await axiosApi(`v1/categories/${IDs}/products${filterParams(filters)}${filters ? '&' : '?'}catalog_id=${catalog_id}`);
  return responseProducts.data;
};

export const getCategories = (getProducts = false, home = false) => async (dispatch, getStore) => {
  dispatch({ type: types.CATEGORIES_LOADER });

  let { categories, sector } = getStore().categories;
  let { profile } = getStore().user;


  const catalog_id = parseInt(sector) || profile?.catalog?.id || 3;

  if (!categories.length) {
    const response = await axiosApi.get(`v1/products/categories?include_subcategories=true&catalog_id=${catalog_id}`);
    if (!response.ok) {
      return dispatch({ type: types.CATEGORIES_REMOVE_LOADER });
    }
    categories = response.data;
  }

  let products = false;
  let currentFilters = getStore().filters.filters;
  let filters = {};
  let IDs = [];

  if (getProducts && !home) {
    IDs = categories.map((category) => category.id);
    const activeFilters = { ...getStore().filters.active };
    const responseProducts = await axiosApi(`v1/categories/${IDs.join(',')}/products${filterParams({ activeFilters })}${!!Object.keys(activeFilters).length ? '&' : '?'}catalog_id=${catalog_id}`);
    if (responseProducts.data.status_code === 100) {
      dispatch({ type: types.CATEGORIES_HIDE_BTN_LOAD, hidden: true, notFound: true });
      return dispatch({ type: types.CATEGORIES_REMOVE_LOADER });
    }

    products = responseProducts.data.categories;

    const theFilters = responseProducts.data.filter

    if (currentFilters) {
      filters.brands = mergeFilters([...currentFilters.brands, ...theFilters.brands], 'id');
      filters.prices = mergeFilters([...currentFilters.prices, ...theFilters.prices], 'id');
      filters.materials = mergeFilters([...currentFilters.materials, ...theFilters.materials], 'id');
      filters.sorts = mergeFilters([...currentFilters.sorts, ...responseProducts.data.sort_by], 'id');
    } else {
      filters = responseProducts.data.filter;
      filters.sorts = responseProducts.data.sort_by;
    }

    const currentIds = responseProducts.data.categories.map((item) => item.id);
    IDs = [...IDs].filter((item) => !currentIds.includes(item));
    dispatch(setFilters(filters));
  }
  if (getProducts && home) {
    IDs = categories.map((category) => category.id);
    const responseProducts = await axiosApi(`v1/categories/${IDs.join(',')}/products?addFilters=false&catalog_id=${catalog_id}`);
    if (responseProducts.data.status_code === 100) {
      dispatch({ type: types.CATEGORIES_HIDE_BTN_LOAD, hidden: true, notFound: true });
      return dispatch({ type: types.CATEGORIES_REMOVE_LOADER });
    }

    products = responseProducts.data.categories;

    const currentIds = responseProducts.data.categories?.map((item) => item.id);

    IDs = [...IDs].filter((item) => !currentIds.includes(item));
    dispatch({type: 'FILTERS_RESET'})
  }


  dispatch({
    type: types.GET_CATEGORIES,
    categories,
    products,
    IDs,
    hideBtnLoad: products.length < 4,
  })
};

const mergeFilters = (filters, key) => {
  const uniqueFilters = filters.reduce((items, item) => {
    return items.findIndex((filter) => filter[key] === item[key]) > -1 ? items : [...items, item];
  }, [])

  return uniqueFilters.sort((a, b) => {
    if (a.display) {
      if (a.display > b.display) {
        return 1;
      }
      if (a.display < b.display) {
        return -1
      }
      return 0;
    }
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1
    }
    return 0;
  })
}

export const getCategoriesWithProducts = (id = false) => async (dispatch, getStore, params) => {
  dispatch({ type: types.CATEGORIES_LOADER });
  const IDs = getStore().categories.cat_ids_pending;
  let ids = false;
  if (id) {
    ids = id;
    const index = IDs.indexOf(id);
    IDs.splice(index, 1);
  } else {
    ids = IDs;
  }

  const { user: { profile: { catalog } }, categories: { sector } } = getStore();

  const catalog_id = sector || catalog || 3;

  const products = await getProducts(params, ids, { ...getStore().filters.active }, catalog_id);
  if (products.status_code === 100) {
    dispatch({ type: types.CATEGORIES_HIDE_BTN_LOAD, hidden: true, notFound: false });
    return dispatch({ type: types.CATEGORIES_REMOVE_LOADER });
  }

  const currentIDs = products.categories.map((item) => item.id);
  const remainIDs = [...IDs].filter((id) => !currentIDs.includes(id));

  const { categories: currentFilters } = getStore().filters;

  let filters = {};
  if (currentFilters) {
    filters.brands = mergeFilters([...currentFilters.brands, ...products.filter.brands], 'id');
    filters.prices = mergeFilters([...currentFilters.prices, ...products.filter.prices], 'id');
    filters.materials = mergeFilters([...currentFilters.materials, ...products.filter.materials], 'id');
    filters.sorts = mergeFilters([...currentFilters.sorts, ...products.sort_by], 'id');
  } else {
    filters = products.filter;
    filters.sorts = products.sort_by;
  }

  dispatch(setFilters(filters));
  return dispatch({
    type: types.GET_CATEGORY_PRODUCTS,
    products: products.categories,
    IDs: remainIDs,
    hideBtnLoad: products.categories.length < 4,
  });
};

export const removeLoader = () => (dispatch) => dispatch({ type: types.CATEGORIES_REMOVE_LOADER });

export const setSector = (sector) => async (dispatch) => {
  dispatch({ type: types.CATEGORIES_SET_SECTOR, sector });
  dispatch(getCategories(true, true));// obtener categorias
};

export const getBanner = () => async (dispatch, getStore) => {
  const sector = getStore().categories.sector;

  const getSector = sector || 'horeca';
  const response = await axiosApi.get(`v1/site/catalogs/${getSector}`);
  dispatch({ type: types.CATEGORIES_GET_BANNER, sector: getSector, banner: response.data });
};

export const categoriesReset = () => (dispatch) => dispatch({ type: types.CATEGORIES_RESET });

export const categoryProductsRecommended = () => async (dispatch) => {
  const products = await axiosApi.get('v1/products/21589,23115/recommendation?offset=0&limit=10');

  const recommended = products.data.message ? [] : products.data;

  dispatch({ type: types.CATEGORIES_PRODUCTS_RECOMMENDED, products: recommended });
};

export const categoriesGrid = (grid) => (dispatch) => dispatch({ type: types.CATEGORIES_GRID, grid })

export const categoriesCarouselSetActive = (active) => (dispatch) => dispatch({ type: types.CATEGORIES_CAROUSEL_SET_ACTIVE, active })

export const getBestSellers = () => async (dispatch) => {
  dispatch({ type: types.LOAD_BEST_SELLERS, payload: true });
  const sellers_call = await axiosApi(`v1/products/best-sellers`)

  if (sellers_call.ok) {
    dispatch({
      type: types.GET_BEST_SELLERS,
      payload: [sellers_call.data.category]
    })
    dispatch({ type: types.LOAD_BEST_SELLERS, payload: false });
  }
}

export const setSort = (option) => async (dispatch) => {
  dispatch({
    type: types.SET_SORT,
    payload: option
  })
}

export const changeView = (view) => async (dispatch) => {
  const type = view ? 'grid' : 'list';
  vistaAnalytics(type)
  dispatch({
    type: types.CHANGE_VIEW,
    payload: view
  })
}

export const setShowFilters = (show) => async (dispatch) => {
  dispatch({
    type: types.SHOW_FILTERS,
    payload: show
  })
}

export const setCategory = (option) => async (dispatch) => {
  dispatch({
    type: types.SET_CATEGORY,
    payload: option
  })
}

export const setRange = (range) => async (dispatch) => {
  dispatch({
    type: types.SET_RANGE,
    payload: range
  })
}

export const setActiveTags = (arr) => async (dispatch) => {
  dispatch({
    type: types.SET_ACTIVE_TAGS,
    payload: [arr]
  })
}
export const setMaterials = (material) => async (dispatch) => {
  dispatch({
    type: types.SET_MATERIALS,
    payload: material
  })
}
export const setBrands = (brand) => async (dispatch) => {
  dispatch({
    type: types.SET_BRANDS,
    payload: brand
  })
}

export const setApply = (apply) => async (dispatch) => {
  dispatch({
    type: types.SET_APPLY_FILTERS,
    payload: apply
  })
}
