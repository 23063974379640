import { filterAnalytics } from './../actions/analytics-events';

export const types = {
  SET_FILTERS: 'SET_FILTERS',
  FILTERS_SET_SEARCH: 'FILTERS_SET_SEARCH',
  UNSET_FILTERS: 'UNSET_FILTERS',
  SET_FILTERS_CATEGORY: 'SET_FILTERS_CATEGORY',
  UNSET_FILTERS_CATEGORY: 'UNSET_FILTERS_CATEGORY',
  FILTER: 'FILTER',
  ADD_MULTIPLE_FILTERS: 'ADD_MULTIPLE_FILTERS',
  FILTERS_REMOVE: 'FILTERS_REMOVE',
  RESET_FILTER: 'RESET_FILTER',
  RESET_FILTER_SEARCH: 'RESET_FILTER_SEARCH',
  RESET: 'RESET',
  FILTERS_RESET: 'FILTERS_RESET',
  SET_FILTERS_SELECTION: 'SET_FILTERS_SELECTION',
};

const initialStore = {
  active: {},
  categories: false,
  category: false,
  search: false,
  selectedFilters: false
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.FILTERS_SET_SEARCH:
      return  { ...store, search: action.search, active: {} };
    case types.SET_FILTERS:
      const brandsSession = window.sessionStorage.getItem('brands') || '[]';
      const actionFilters = action.filters?.brands || [];
      const newBrandsSession = JSON.stringify([...JSON.parse(brandsSession), ...actionFilters]);
      window.sessionStorage.setItem('brands', newBrandsSession);

      if (action.filterType === 'categories') {
        return { ...store, categories: action.filters };
      }
      return { ...store, category: action.filters };
    case types.UNSET_FILTERS:
      return { ...store, filters: false };
    case types.FILTER:
      const active = { ...store.active };
      switch (action.name) {
        case 'price':
        case 'prices':
          if (active.prices && active.prices.min === action.value.min &&  active.prices.max === action.value.max) {
            delete active.prices;
          } else {
            active.prices = action.value;
            filterAnalytics('price', action.value.name)
          }
          break;
        case 'sort':
          if (active.sort === action.value) {
            delete active.sort;
          } else {
            active.sort = action.value;
            filterAnalytics('sort', action.value.name || action.value.display)
          }
          break;
        default:
          if (active[action.name]?.id === action.value.id) {
            delete active[action.name];
          } else {
            active[action.name] = action.value;
            filterAnalytics(action.name, action.value.name)
          }
          break;
      }
      return { ...store, active };
    case types.ADD_MULTIPLE_FILTERS:
      const multipleFilters = { ...store.active, ...action.filters };
      return { ...store, active: multipleFilters };
    case types.RESET:
    case types.RESET_FILTER:
      const newFilters = { ...store.active, ...action.active };
      return { ...store, active: newFilters};
    case types.RESET_FILTER_SEARCH:
      return { ...store, filters_active: action.active};
    case types.FILTERS_REMOVE:
      return { ...store, active: {} };
    case types.FILTERS_RESET:
      return {...initialStore};
    default:
      return { ...store };
  }
};

export default reducer;
