export const modalAnimations = {
    bgAnimation: {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 0,
            y: -500
        },
    },
    bgAnimationAside: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: 500
        },
    },
    bodyAnim: {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 0,
            y: -100
        },
    },
    bodyAnimSide: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: 100
        },
    },
    triangleAnim: {
        visible: {
            originX: 'center center',
            scale: 1,
        },
        hidden: {
            scale: 1.8,
        },
    },
    modalEnter: {
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                ease: 'easeInOut',
                duration: .1
            }
        },
        hidden: {
            opacity: 0,
            x: 760,
            transition: {
                ease: 'easeInOut',
                duration: .3,
                delay: .3,
            }
        },
    },
    modalEnterDesktop: {

    }
}