import { types } from '../reducers/filters';

export const filterParams = (filtersActive) => {
  const filtersNames = Object.keys(filtersActive);
  let applyFilters = [];
  if (Object.keys(filtersActive).length) {
    filtersNames.forEach((element) => {
      switch (element) {
        case 'prices':
          if (filtersActive[element] && filtersActive[element].min) {
            applyFilters.push(`minPrice=${filtersActive[element].min || 0}`);
          }
          if (filtersActive[element] && filtersActive[element].max) {
            applyFilters.push(`maxPrice=${filtersActive[element].max || 0}`);
          }
          break;
        default:
          if (filtersActive[element]) {
            applyFilters.push(`${element}=${filtersActive[element].id}`);
          }
          break;
      }
    });
  }
  return applyFilters.length ? '?' + applyFilters.join('&') : '';
};

export const setFilters = (filters, filterType = 'categories') => (dispatch) => {
  return dispatch({
    type: types.SET_FILTERS,
    filters,
    filterType,
  });
};

export const applyFilters = (name, value, filterType = 'categories') => (dispatch) => {
  return dispatch({
    type: types.FILTER,
    name,
    value,
    filterType,
  });
};

export const addMultipleFilters = (filters) => (dispatch) => dispatch({ type: types.ADD_MULTIPLE_FILTERS, filters });

export const resetFilters = (active = {}, type) => (dispatch) => {
  if (active && type) {
    switch (type) {
      case 'price':
      case 'prices':
        delete active.prices;
        break;
      default:
        delete active[type];
        break;
    }
  }
  return dispatch({ type: types.RESET_FILTER, active });
}

export const resetFiltersSearch = (active, type) => (dispatch) => {
  if (active && type) {
    switch (type) {
      case 'price':
      case 'prices':
        delete active.price;
        break;
      default:
        delete active[type];
        break;
    }
  } else {
    active = [];
  }
  return dispatch({ type: types.RESET_FILTER_SEARCH, active });
}

export const filtersRemove = () => (dispatch) => dispatch({ type: types.FILTERS_REMOVE })
