import React from 'react';
import { useState } from 'react';
import RatingProduct from '../RatingProduct';
import useIsMobile from './../../hooks/useIsMobile';
import BannerMerchant from './../BannerMerchant';

const PercentItem = ({ number, items, total }) => {
  return (
    <li>
      <span>
        {number} {number > 1 ? 'estrellas' : 'estrella'}
      </span>
      <div className='count'>
        <i style={{ width: `${(items * 100) / total}%` }}></i>
      </div>
    </li>
  );
};

const countStart = (items, number) => {
  return items.filter(item => item.rating >= number && item.rating < number +1)
}

const DetailRating = ({ rating, reviews = [] }) => {
  const isMobile = useIsMobile();
  const [reviewDetail, setReviewDetail] = useState(false)

  return (
    <div className='detail-rating'>
      <div className='rating-resume'>
        <div className='rating-count'>
          <strong className='rating'>{rating}</strong>
          <RatingProduct rating={rating} />
          {!!reviews.length && <span>Promedio de {reviews.length} opiniones</span>}
          {isMobile && (
            <span className='link more' onClick={() => setReviewDetail(!reviewDetail)}>
              <i className='icon-plus-circle'></i> Ver más
            </span>
          )}
        </div>
        {(reviewDetail || !isMobile) && <ul className='rating-detail'>
          <PercentItem number='5' items={countStart(reviews, 5)} total={reviews.length} />
          <PercentItem number='4' items={countStart(reviews, 4)} total={reviews.length} />
          <PercentItem number='3' items={countStart(reviews, 3)} total={reviews.length} />
          <PercentItem number='2' items={countStart(reviews, 2)} total={reviews.length} />
          <PercentItem number='1' items={countStart(reviews, 1)} total={reviews.length} />
        </ul>}
      </div>
      {!isMobile && <BannerMerchant />}
    </div>
  );
};

export default DetailRating;
