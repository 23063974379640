import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeaturedProducts from '../components/BasketBox/FeaturedProducts';
import SliderLargeBoxes from '../components/BasketBox/SliderLargeBoxes';
import Breadcrumbs from '../components/Breadcrumbs';
import ContainerBoxPage from '../components/containers/ContainerBoxPage';
import Image from '../components/Image';
import Loader from '../components/Loader';
import useIsMobile from '../hooks/useIsMobile';
import { cartGetContainers } from '../store/actions/cart';
import { getCategories } from '../store/actions/categories';
import { getHomeData } from '../store/actions/home';
import { tutorialAnalytics } from './../store/actions/analytics-events';

const body = document.querySelector('body');

const TutorialsPage = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [video, setVideo] = useState(false);
  const [play, setPlay] = useState(false);

  const { tutorials, current_video } = useSelector((state) => state.home);
  const { categories_w_products } = useSelector((state) => state.categories);

  useEffect(() => {
    if (!categories_w_products.length) {
      dispatch(getCategories(true));
    }
    dispatch(cartGetContainers());
  }, [dispatch, categories_w_products]);

  useEffect(() => {
    if (!tutorials) {
      dispatch(getHomeData());
    }
  }, [dispatch, tutorials]);

  const handleClickVideo = (video = false) => {
    setVideo(video);
    if (video) {
      tutorialAnalytics(video.title)
    }
    if (isMobile && video) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
      setPlay(!!video);
    }
  };

  const closeVideo = useCallback(() => {
    if (!isMobile && video) {
      body.classList.remove('no-scroll');
    }
    if (isMobile && video) {
      body.classList.add('no-scroll');
    }
  }, [isMobile, video]);

  useEffect(() => {
    window.addEventListener('resize', closeVideo);

    return () => {
      window.removeEventListener('resize', closeVideo);
    };
  }, [closeVideo]);

  const handlePlay = () => {
    tutorialAnalytics(video?.title || tutorials[0]?.title)
    setPlay(true);
  }

  useEffect(() => {
    if (current_video) {
      setVideo(current_video);
    }
  }, [current_video])

  if (!tutorials)
    return (
      <ContainerBoxPage className='tutorials-page'>
        <Loader ellipsis />
      </ContainerBoxPage>
    );

  return (
    <ContainerBoxPage className='tutorials-page'>
      <Breadcrumbs marginTop />
      <div className='tutorials'>
        {!isMobile && (
          <div className='video'>
            <strong>{video?.title || tutorials[0]?.title}</strong>
            <div className='video-container'>
              {!play && (
                <>
                  <div className='icon-play' onClick={handlePlay}></div>
                  <Image
                    src={video?.media?.large || tutorials[0]?.media.large}
                    alt={video?.title || tutorials[0]?.title}
                    srcSet={`${video?.media?.small || tutorials[0]?.media.small} 480w,
                              ${video?.media?.large || tutorials[0]?.media.large} 960w`}
                  />
                </>
              )}
              {play && (
                <iframe
                  width='560'
                  height='315'
                  src={video?.link || tutorials[0]?.link}
                  title={video?.title || tutorials[0]?.title}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen></iframe>
              )}
            </div>
            <SliderLargeBoxes />
          </div>
        )}
        {video && isMobile && (
          <div className='video'>
            <div className='icon-cancel-circle' onClick={() => handleClickVideo(false)}></div>
            <iframe
              // width='560'
              // height='315'
              src={video.link}
              title={video.title}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen></iframe>
          </div>
        )}
        <div className='videos'>
          <div className='tutorial-main' onClick={() => handleClickVideo(tutorials[0])}>
            <div className='icon-play'></div>
            <Image
              src={tutorials[0]?.media.large}
              alt={tutorials[0]?.title}
              srcSet={`${tutorials[0]?.media.small} 480w,
            ${tutorials[0]?.media.large} 960w`}
            />
          </div>
          <h2>Más tutoriales</h2>
          <div className='list'>
            {tutorials?.map((tutorial, tutorialIndex) => {
              if (tutorialIndex === 0 && isMobile) return;
              return (
                <div key={tutorialIndex} className='tutorial'>
                  <div className='icon-play' onClick={() => handleClickVideo(tutorial)}></div>
                  <Image
                    src={tutorial.media.large}
                    alt={tutorial.title}
                    srcSet={`${tutorial.media.small} 480w,
                             ${tutorial.media.large} 960w`}
                  />
                  <strong>{tutorial.title}</strong>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <FeaturedProducts title='Productos destacados' products={categories_w_products[0]?.products} />
    </ContainerBoxPage>
  );
};

export default TutorialsPage;
