import React from 'react'
import swipeCard from '../helpers/swipe-basket-item'

class BasketItem extends React.Component{
  componentDidMount(){
    swipeCard(this.el, this.props.idRightLeft, '.confirm-delete.active')
  }
  render() {
    return (<div className="basket-item">
      <div ref={(el) => this.el = el}>{this.props.children}</div>
    </div>)
  }
}

export default React.memo(BasketItem)
