import React from 'react';
import $ from 'jquery';
import 'slick-carousel'
import { defaultImg } from '../helpers';
import ProductZoomMobile from './ProductZoomMobile';
import BtnAddFavorite from './BtnAddFavorite';

class SliderImg extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    this.initCarousel();
  }

  initCarousel = () => {
    $('.slider-img').slick({
      prevArrow: false,
      nextArrow: false,
      dots: true,
      infinite: false,
      mobileFirst: true,
      adaptiveHeight: true,
    });
    $('.slider-img-large').slick({
      prevArrow: false,
      nextArrow: false,
      dots: false,
      infinite: false,
      lazyLoad: true,
      asNavFor: '.slider-img-thumb',
    });
    $('.slider-img-thumb').slick({
      prevArrow: false,
      nextArrow: false,
      dots: false,
      infinite: false,
      lazyLoad: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      asNavFor: '.slider-img-large',
      mobileFirst: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ],
    });
  };

  openLightbox = (image) => {
    const open = image;
    this.setState({ open });
    const body = document.querySelector('body');
    window.scroll(0, 0);
    if (open) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  };

  refreshSlider = () => {
    const slider = document.querySelector('.slider-img');
    if (slider) {
      $('.slider-img').slick('refresh');
    } else {
      $('.slider-img').slick('unslick');
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.images !== this.props.images || nextState.open !== this.state.open || nextProps.logged !== this.props.logged;
  }

  render() {
    return (
      <div className='product-slider'>
        {this.props.logged && <BtnAddFavorite id={this.props.productID} />}
        <div className='slider-img'>
          {this.props.images.map((image, index) => (
            <img
              src={image.sizes.small}
              alt={image.alt}
              key={index}
              onError={(event) => defaultImg(event)}
              loading='lazy'
              onClick={() => this.openLightbox(image)}
              onLoad={() => this.refreshSlider()}
            />
          ))}
        </div>

        {this.state.open && <ProductZoomMobile images={this.props.images} open={this.state.open} close={() => this.openLightbox()} />}
      </div>
    );
  }
}

export default React.memo(SliderImg);
