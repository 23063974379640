import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileContainer from '../../pages/User/ProfileContainer';
import { userRequestWallet } from './../../store/actions/user';
import { AppButton } from './../UI/Buttons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from '../Loader';

const WalletRequest = () => {
  const dispatch = useDispatch();

  const handleRequestWallet = () => dispatch(userRequestWallet());

  const { requestWallet, loaderLoyalty: loader } = useSelector((state) => state.user);

  return (
    <ProfileContainer className='profile-my-wallet'>
      {loader && <Loader spinner blue />}
      {!loader && (
        <>
          <h1 className='title-section'>Monedero electrónico</h1>
          <div
            className={classNames('my-wallet-request', {
              confirmation: !!requestWallet && requestWallet.ok,
              alert: !!requestWallet && !requestWallet.ok,
            })}>
            <div className='card-image'>
              <img src='/img/logo-white.png' alt='MXP' />
              <div className='number'>
                <span>0</span>
                <span>0</span>
                <span>0</span>
                <span>0</span>
                &nbsp;
                <span>0</span>
                <span>0</span>
                <span>0</span>
                <span>0</span>
                &nbsp;
                <span>0</span>
                <span>0</span>
                <span>0</span>
                <span>0</span>
                &nbsp;
                <span>0</span>
                <span>0</span>
                <span>0</span>
                <span>0</span>
              </div>
            </div>
            {!requestWallet && <div className='message request'>
              <strong>¿Aún no cuentas con este beneficio?</strong>
              <br />
              <p>Obtén la mejor recompensa por tus compras.</p>
              <AppButton type='main' txt='Obtener' onClick={handleRequestWallet} available />
            </div>}
            {!!requestWallet && requestWallet.last4 && <div className='message'>
              <i className='icon-ok-2 success'></i>
              <strong>Tu solicitud ha sido recibida con éxito.</strong>
              <br />
              <p>Alguien de nuestro equipo se pondrá en contacto lo más pronto posible.</p>
              <Link to='/' className='btn btn-primary rounded-small btn-small'>
                VER CATÁLOGO
              </Link>
            </div>}
            {!!requestWallet && !requestWallet?.last4 && <div className='message request'>
              <i className='icon-alert alert'></i>
              <strong>Lo sentimos ha ocurrido un error inesperado.</strong>
              <br />
              <p>Por favor, intenta nuevamente.</p>
              <AppButton type='main' txt='VOLVER A INTENTAR' onClick={handleRequestWallet} available />
            </div>}
          </div>
        </>
      )}
    </ProfileContainer>
  );
};

export default WalletRequest;
