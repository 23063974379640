import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateAddress, validateName, validatePhone } from '../../helpers/validations';
import { purchaseGetLocationsByZip, purchaseResetLocationsByZip } from '../../store/actions/purchase';
import { addNewAddress } from '../../store/actions/user';
import AdvancedModal from '../AdvancedModal';
import FormInput from '../FormElements/FormInput';
import InputCheckbox from '../FormElements/InputCheckbox';
import { validateNumberStreet, validateZip, validateText } from './../../helpers/validations';
import useIsMobile from './../../hooks/useIsMobile';
import FakeSelect from './../FormElements/FakeSelect';

const AddNewAddress = ({ close }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [sendForm, setSendForm] = useState(false)
  const {loader: loaderAddAddress} = useSelector(state => state.user)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError
  } = useForm({
    mode: 'onBlur',
  });

  const {alias, name, address, exteriorNumber, zip, state, city, references, suburb, isMain } = watch();

  const { locationsByZip, loaderLocations } = useSelector((state) => state.purchase);

  useEffect(() => {
    if (zip && /^[0-9]{5}$/.test(zip)) {
      dispatch(purchaseGetLocationsByZip(zip));
    }
  }, [dispatch, setValue, zip]);

  useEffect(() => {
    if (locationsByZip && locationsByZip.message){
      setError('zip', {type: 'zip', message: locationsByZip.message}, true)
    }
    else if(locationsByZip && locationsByZip.city){
      setValue('city', locationsByZip.city);
      setValue('city_label', locationsByZip.city.name);
      setValue('state', locationsByZip.state);
      setValue('state_label', locationsByZip.state.name);
      setValue('suburb', '');
    }
  }, [setValue, setError, locationsByZip]);

  const submit = (data) => {
    const address = {
      alias: data.alias.trim(),
      name: data.name.trim(),
      phone: data.phone.trim(),
      state_id: data.state.id,
      state_name: data.state.name,
      city_id: data.city.id,
      city_name: data.city.name,
      suburb_id: data.suburb.id,
      suburb_name: data.suburb.name,
      zip: data.zip.trim(),
      interior_number: data.interiorNumber.trim(),
      exterior_number: data.exteriorNumber.trim(),
      address: data.address.trim(),
      is_default: data.isMain ? 'Si' : 'No',
      references: data.references.trim(),
    }
    dispatch(addNewAddress(address))
    setSendForm(true)
  };

  const handleClose = () => {
    dispatch(purchaseResetLocationsByZip())
    window.scroll(0,0);
    close();
  }

  useEffect(() => {
    if(sendForm && !loaderAddAddress){
      window.scroll(0,0);
      close();
    }
  }, [sendForm, loaderAddAddress, close]);


  const enableSubmit = () => !Object.keys(errors).length && alias && name && address && exteriorNumber && zip && suburb && state && city && references;

  return (
    <form id='add-new-address' onSubmit={handleSubmit(submit)}>
      <AdvancedModal className='format-2' close={handleClose} scrollAfterClose={false}>
        <AdvancedModal.Header>Nueva dirección</AdvancedModal.Header>
        <AdvancedModal.Body>
          <FormInput name='alias' options={validateText} register={register} error={errors.alias} label='Alias*' setValue={setValue}
        validate="text" />

          <FormInput name='name' register={register} options={validateName} error={errors.name} label='Nombre completo*' placeholder='Jorge Eduardo Hernández Pérez' setValue={setValue}
        validate="name" />

          <FormInput name='phone' register={register} options={validatePhone} error={errors.phone} label='Teléfono' placeholder='10 dígitos' type="numeric" maxLength="10" setValue={setValue}
        validate="number" />
          <FormInput name='address' register={register} options={validateAddress} error={errors.address} label='Dirección*' placeholder='Nombre de la calle' validate="address" setValue={setValue} />

          <div className='address-number'>
            <FormInput name='exteriorNumber' register={register} options={validateNumberStreet} error={errors.exteriorNumber} label='Número exterior*' placeholder='000' setValue={setValue}
          pattern={/[^a-zA-Z0-9]/g} />

            <FormInput name='interiorNumber' register={register} error={errors.interiorNumber} label='Número interior' placeholder='000' setValue={setValue}
          pattern={/[^a-zA-Z0-9]/g} />

          </div>
          <FormInput name='zip' register={register} error={errors.zip} label='Código Postal*' placeholder='00000' options={validateZip} loader={loaderLocations} type="numeric" maxLength="5" setValue={setValue}
        validate="number" />

          <FakeSelect
            options={locationsByZip?.suburbs}
            label='Colonia*'
            placeholder='Selecciona una colonia'
            value={suburb}
            name='suburb'
            registerOptions={{ shouldValidate: true, required: true }}
            onChange={setValue}
            height={140}
          />

          <FormInput name='city_label' register={register} error={errors.city} label='Municipio*' readOnly />
          <FormInput name='state_label' register={register} error={errors.state} label='Estado*' readOnly />
          <FormInput name='references' register={register} error={errors.references} label='Entre las calles*' options={validateText} setValue={setValue}
        validate="text" />

          <InputCheckbox className='isMain' item={{ value: 1, label: 'Usar como principal' }} selected={isMain} onChange={(value) => setValue('isMain', value)} />
          {!isMobile && (
            <div className='submit'>
              <button type='submit' className={`btn btn-primary rounded${enableSubmit() ? '' : ' disabled'}${loaderAddAddress ? ' single-loader' : ''}`}>GUARDAR</button>
            </div>
          )}
        </AdvancedModal.Body>
        {isMobile && (
          <AdvancedModal.Footer>
            <button type='submit' className={`btn btn-primary rounded${enableSubmit() ? '' : ' disabled'}${loaderAddAddress ? ' single-loader' : ''}`}>GUARDAR</button>
          </AdvancedModal.Footer>
        )}
      </AdvancedModal>
    </form>
  );
};

export default AddNewAddress;
