import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from '../../../helpers/index';
import moveInput from '../../../helpers/input-auto-scroll-viewport';
import Card from '../../../sass/components/purchase/Card';
import { enableNext, getCardsSaved } from '../../../store/actions/purchase';
import Carousel from '../../Carousel';
import FormAddCard from '../../FormAddCard';

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      method: false,
      formAddCard: false,
      isMobile: isMobile(),
    };
  }

  setMethod = (method) => this.setState({ method });

  showFormAddCard = (event) => {
    this.props.clearCardData();
    const formAddCard = !this.state.formAddCard;
    this.setState({ formAddCard });
    const formElement = event.currentTarget;
    if(this.props.step === 3){
      setTimeout(() => {
        formElement.scrollIntoView();
      }, 300);
    }
  };

  componentDidMount = async () => {
    await this.props.getCards();
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
  };

  componentDidUpdate() {
    const inputsModal = document.querySelectorAll('.form-add-card input');
    const container = this.props.step === 3 ? '.form-change-payment-method .ps' : false;
    const distance = this.props.step === 3 ? 0 : 120;
    inputsModal.forEach((input) => {
      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, container, distance, 500);
      });
    });
  }

  submit = () => {
    if (this.props.submit) {
      return this.props.submit();
    }
    this.props.nextStep();
  };

  render() {
    return (
      <div id='payment-method' className={this.props.className}>
        <ul className='methods'>
          <li className={`tc${this.props.paymentMethod === 'tc' ? ' active' : ''}`} onClick={() => this.props.setMethod('tc')}>
            <i className='icon-card'></i>
            <strong>Tarjeta de crédito o débito</strong>
          </li>
        </ul>
        {this.props.paymentMethod === 'tc' && (
          <div className='card-list'>
            {this.props.step === 2 && (
              <div className='title'>
                <h2>MIS TARJETAS</h2>
                <span className='link' onClick={(event) => this.showFormAddCard(event)}>
                  NUEVA TARJETA <i className={this.state.formAddCard ? 'icon-close' : 'icon-plus'}></i>
                </span>
              </div>
            )}
            {(this.props.step === 2 || !this.state.isMobile) && (
              <Carousel className={`carousel-cards${this.state.formAddCard ? ' disabled' : ''}`}>
                <ul>
                  {this.props.cards &&
                    this.props.cards.map((card, index) => (
                      <li key={index}>
                        <Card {...card} />
                      </li>
                    ))}
                </ul>
              </Carousel>
            )}

            {this.props.step === 3 && this.state.isMobile && (
              <ul className='change-card-list'>
                {this.props.cards &&
                  this.props.cards.map((card, index) => (
                    <li key={index}>
                      <Card {...card} />
                    </li>
                  ))}
              </ul>
            )}
            <div className='add-cart-mobile'>
              <span className='link' onClick={(event) => this.showFormAddCard(event)}>
                NUEVA TARJETA <i className={this.state.formAddCard ? 'icon-close' : 'icon-plus'}></i>
              </span>
            </div>
            {this.state.formAddCard && <FormAddCard labelSubmit={this.props.labelSubmit || 'SIGUIENTE'} enableSave={true} />}
          </div>
        )}
        {(this.props.paymentMethod !== 'tc' || !this.state.formAddCard) && (
          <div className='next'>
            <span className={`btn btn-primary rounded${!this.props.enableNextPaymentMethod() ? ' disabled' : ''}`} onClick={this.submit}>
              {this.props.labelSubmit || 'SIGUIENTE'}
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    paymentMethod: state.purchase.paymentMethod,
    tokenCard: state.purchase.tokenCard,
    tmpCardToken: state.purchase.tmpCardToken,
    cards: state.purchase.cardsSaved,
    step: state.purchase.step,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMethod: (method) => dispatch({ type: 'PURCHASE_SET_PAYMENT_METHOD', method }),
    enableNextPaymentMethod: () => dispatch(enableNext()),
    nextStep: () => dispatch({ type: 'PURCHASE_STEP' }),
    getCards: () => dispatch(getCardsSaved()),
    clearCardData: () => dispatch({ type: 'PURCHASE_CLEAR_CARD_DATA' }),
  };
};
const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(PaymentMethod);
