import React from 'react';
import { Route } from 'react-router-dom';
import routes from '../routes/private/profile-user';
import PrivateRoute from '../routes/PrivateRoute';

const ProfileRoutes = () => {
  return (
    <>
      {routes.map((item, itemIndex) =>
        item.private ? (
          <PrivateRoute key={itemIndex} path={item.path} exact={item.exact} component={item.component} />
        ) : (

          <Route key={itemIndex} path={item.path} exact={item.exact} component={item.component} />
        ),
      )}
    </>
  );
};

export default ProfileRoutes;
