import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { purchasePaymentLoyalty } from '../../store/actions/purchase';
import MoneyFormat from '../MoneyFormat';
import useIsMobile from './../../hooks/useIsMobile';

const AddFromLoyaltyCard = () => {
  const dispatch = useDispatch();

  const { loyalty, loyalty_points, shipping } = useSelector((state) => state.purchase);
  const { total } = useSelector((state) => state.cart);
  const [hidden, setHidden] = useState(false);

  const isMobile = useIsMobile(1220);
  const { profile } = useSelector((state) => state.user);

  const handleAddPoints = () => {
    dispatch(purchasePaymentLoyalty(loyalty.points));
  };

  if (hidden || (total + (shipping.total || 0) < loyalty.points)) return '';

  if (isMobile) {
    return (
      <div className='add-from-loyalty-card'>
        {!loyalty_points && (
          <>
            <span className='title'>Tienes en tu Tarjeta de lealtad</span>
            <span className='btn btn-white'>
              <MoneyFormat money={loyalty.points} />
            </span>
            <span className='btn btn-white btn-outline' onClick={handleAddPoints}>
              Abonar
            </span>
          </>
        )}
        {!!loyalty_points && (
          <>
            <span className='title'>Se han abonado de tu Tarjeta de lealtad:</span>
            <div className='total'>
              <MoneyFormat money={loyalty_points} />
            </div>
            <div className='close'>
              <div className='icon-cancel-circle' onClick={() => setHidden(true)}></div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className='add-from-loyalty-card'>
      {loyalty_points ? (
        <div className='loyalty-confirmation'>
          Se han abonado{' '}
          <span className='total'>
            <MoneyFormat money={loyalty_points} />
          </span>{' '}
          de tu monedero electrónico.
          <i className='icon-cancel-circle' onClick={() => setHidden(true)}></i>
        </div>
      ) : (
        <div className='loyalty-notification'>
          {profile.user_name}, cuentas con{' '}
          <span className='total'>
            <MoneyFormat money={loyalty.points} />
          </span>{' '}
          en tu monedero electrónico, ¿deseas usarlos?
          <button className='btn btn-white btn-outline' onClick={handleAddPoints}>
            ABONAR
          </button>
        </div>
      )}
    </div>
  );
};

export default AddFromLoyaltyCard;
