class Camera {
  constructor(videoNode = false, width = false, height = false) {
    this.videoNode = videoNode;
    this.width = width || 300;
    this.height = height || 300;
  }

  cameraOldDevices(constraints, successCallback, errorCallback) {
    const getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    if (!getUserMedia) {
      return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
    }
    return new Promise(function (successCallback, errorCallback) {
      getUserMedia.call(navigator, constraints, successCallback, errorCallback);
    });
  }

  getUserMedia(options = false) {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = this.cameraOldDevices;
    }
    if(options) return navigator.mediaDevices.getUserMedia(options);
    return navigator.mediaDevices.getUserMedia;
  }

  hasCamera() {
    return !!this.getUserMedia();
  }

  open() {
    // TODO: no se puede abrir la camara externamente solo dentro del navegador
    this.getUserMedia({
        audio: false,
        video: {
          width: this.width,
          height: this.height,
          facingMode: 'user',
        },
      })
      .then((stream) => {
        this.videoNode.srcObject = stream;
        this.stream = stream;
      })
      .catch((error) => console.warn(error));
  }

  close() {
    this.videoNode.pause();
    this.stream.getTracks()[0].stop();
  }

  takePhoto() {
    let canvas = document.createElement('canvas');
    canvas.setAttribute('width', this.width);
    canvas.setAttribute('height', this.height);
    let context = canvas.getContext('2d');
    context.translate(this.width, 0);
    context.scale(-1, 1);
    context.drawImage(this.videoNode, 0, 0, canvas.width, canvas.height);
    this.photo = context.canvas.toDataURL('image/jpeg', 1);
    canvas = null;
    context = null;
    return this.photo;
  }
}

export default Camera;
