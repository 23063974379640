import React, { useEffect, useRef, useState } from 'react';

const InputEditProfile = ({ name, label, value, onChange, error, options, register, numeric, placeholder, type = 'text', readOnly, icon = 'edit', ...rest }) => {
  const [edit, setEdit] = useState(false);
  const [init, setInit] = useState(false);
  const htmlInput = useRef(false);

  const [showValue, setShowValue] = useState(false);

  useEffect(() => {
    if (placeholder && !value && !init) {
      onChange(name, placeholder);
      setInit(true);
    }
  }, [init, name, onChange, placeholder, value]);

  const handleChange = (event) => {
    if (onChange) {
      onChange(name, event.currentTarget.value, { shouldValidate: true, shouldDirty: true });
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      setEdit(false);
      onChange(name, event.currentTarget.value);
    }
  };

  const handleEnableEdit = () => {
    if(readOnly) return;
    setEdit(true);
    setTimeout(() => {
      htmlInput.current.focus();
    }, 500);
  };

  return (
    <div className={`input-edit-profile${error ? ' error' : ''}`}>
      <label htmlFor={`field-${name}`}>{label}</label>
      {!edit && (
        <div className='text-value' onClick={handleEnableEdit}>
          <span>{value || placeholder}</span> {!readOnly && icon && <i className={`icon-${icon}`}></i>}
        </div>
      )}
      <div className='input-field'>
        <input
          type={type !== 'password' || showValue ? 'text' : 'password'}
          {...register(name, options)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          id={`field-${name}`}
          inputMode={numeric ? 'numeric' : 'text'}
          autoComplete='off'
          ref={htmlInput}
          {...rest}
        />
        {type === 'password' && <i className={`icon-eye-${showValue ? 'on' : 'off'}`} onClick={() => setShowValue(!showValue)}></i>}
      </div>
      {error && <div className='msg'>{error.message}</div>}
    </div>
  );
};

export default InputEditProfile;
