import PersonalInformationPage from './../../pages/User/Profile/PersonalInformationPage';
import ChangePasswordPage from './../../pages/User/Profile/ChangePasswordPage';
import MyAddressesPage from './../../pages/User/Profile/MyAddressesPage';
import MyCardsPage from './../../pages/User/Profile/MyCardsPage';
import MyPurchasesPage from '../../pages/User/Profile/MyPurchasesPage';
import MyWalletPage from './../../pages/User/Profile/MyWalletPage';
import TutorialsPage from './../../pages/TutorialsPage';
import MyProfileMenu from './../../pages/User/Profile/MyProfileMenu';
import MyPurchasesDescription from '../../pages/User/Profile/MyPurchasesDescription';
import MyPurchasesReview from '../../pages/User/Profile/MyPurchasesReview';
import MyCardEditPage from './../../pages/User/Profile/MyCardEditPage';
import MyPurchaseTracking from '../../pages/User/Profile/MyPurchaseTracking';
import MyFavorites from '../../pages/User/Profile/MyFavorites';

const routes = [
  {
    id: 'my-profile',
    name: 'MyProfile',
    title: 'Mi perfil',
    path: '/perfil/mi-perfil',
    exact: true,
    component: MyProfileMenu,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'personal-information',
    name: 'PersonalInformation',
    title: 'Datos personales',
    path: '/perfil/datos-personales',
    exact: true,
    component: PersonalInformationPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'change-password',
    name: 'ChangePassword',
    title: 'Cambiar contraseña',
    path: '/perfil/cambiar-contrasena',
    exact: true,
    component: ChangePasswordPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'my-addresses-page',
    name: 'MyAddresses',
    title: 'Mis direcciones',
    path: '/perfil/mis-direcciones',
    exact: true,
    component: MyAddressesPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'my-cards',
    name: 'MyCards',
    title: 'Mis tarjetas',
    path: '/perfil/mis-tarjetas',
    exact: true,
    component: MyCardsPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'my-cards-new',
    name: 'MyCardsNew',
    title: 'Nueva tarjeta',
    path: '/perfil/tarjeta/agregar',
    exact: true,
    component: MyCardEditPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: [],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'my-cards-edit',
    name: 'MyCardsEdit',
    title: 'Editar tarjeta',
    path: '/perfil/tarjeta/:id/editar',
    exact: true,
    component: MyCardEditPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/perfil/mis-tarjetas'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'purchase-history',
    name: 'PurchaseHistory',
    title: 'Mis compras',
    path: '/perfil/mis-compras',
    exact: true,
    component: MyPurchasesPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'favorites',
    name: 'Favorites',
    title: 'Mis favoritos',
    path: '/perfil/mis-favoritos',
    exact: true,
    component: MyFavorites,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'purchase-history',
    name: 'PurchaseHistory',
    title: 'Mis compras',
    path: '/perfil/mis-compras/:id',
    exact: true,
    component: MyPurchasesDescription,
    private: true,
  },
  {
    id: 'purchase-history-tracking',
    name: 'PurchaseHistoryTracking',
    title: 'Mis compras',
    path: '/perfil/compra/:id/rastrear',
    exact: true,
    component: MyPurchaseTracking,
    private: true,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'purchase-history',
    name: 'PurchaseReview',
    title: 'Mis compras',
    path: '/perfil/mis-compras/:id/:slug',
    exact: true,
    component: MyPurchasesReview,
    breadcrumbs: ['/perfil/mis-compras'],
    breadcrumb_mobile: ['/perfil/mis-compras'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'wallet',
    name: 'MyWallet',
    title: 'Mi monedero electrónico',
    path: '/perfil/monedero',
    exact: true,
    component: MyWalletPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    private: true,
  },
  {
    id: 'tutorials',
    name: 'Tutorials',
    title: 'Tutoriales',
    path: '/perfil/tutoriales',
    exact: true,
    component: TutorialsPage,
    breadcrumbs: ['/'],
    private: false,
  },
];

export default routes;
