import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useCurrentCategory = () => {
  const params = useParams();
  const {categories} = useSelector(state => state.categories)

  let category = false;

  if (params.categoryID) {
    category = categories.find((item) => {
      if (item.id === parseInt(params.categoryID)) return true;
      return item.subcategories.find((subcategory) => subcategory.id === parseInt(params.categoryID));
    });
  }

  return category;
}

export default useCurrentCategory;
