import { axiosApi } from '../axiosAPI';

export const sendContactForm = (data) => async (dispatch, getStore) => {
  const user = getStore().user;
  if (user.logged) {
    data.email = user.profile.email;
  }
  const contact = await axiosApi.post('v1/contact', { ...data, captcha: true });
  return contact.data;
};
