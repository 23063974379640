import { types } from '../reducers/favorites';
import axiosApi from '../axiosAPI';
import { favoriteAnalytics } from './analytics-events';

export const updateFavorites = (id) => async (dispatch, getStore) => {
  if (!id) return;
  const item = getStore().favorites
  const isEmpty = !item.products.length
  if (isEmpty) {
    const favoritesApi = await axiosApi.post('v1/products/favorites', { product_id: id, captcha: true }).catch((error) => error.response)
    if (favoritesApi.ok) {
      dispatch({ type: types.GET_FAVORITES_LOADER, payload: true });
      const favoritesApi = await axiosApi(`v1/products/favorites`, { captcha: true });

      if (favoritesApi.ok) {
        const response = favoritesApi.data.categories
        dispatch({
          type: types.GET_FAVORITES,
          payload: response[0].products
        })
        dispatch({ type: types.GET_FAVORITES_LOADER, payload: false });
      }
    }
  }

  if (!isEmpty) {
    const findFavorite = item.products.filter(product => product.id === id)[0]
    const isFavorite = findFavorite !== undefined && findFavorite.id === id

    if (isFavorite) {
      dispatch({ type: types.GET_FAVORITES_LOADER, payload: true });
      const favoritesApi = await axiosApi.delete('v1/products/favorites', { data: { product_id: id } }).catch((error) => error.response)

      let updateFavorites = item;
      if (favoritesApi.ok) {
        let filtered = updateFavorites.products.filter(item => item.id !== id)
        dispatch({
          type: types.GET_FAVORITES,
          payload: filtered
        })
        dispatch({ type: types.GET_FAVORITES_LOADER, payload: false });
      }
    }
    else {
      const favoritesApi = await axiosApi.post('v1/products/favorites', { product_id: id, captcha: true }).catch((error) => error.response)
      if (favoritesApi.ok) {
        dispatch({ type: types.GET_FAVORITES_LOADER, payload: true });
        const favoritesApi = await axiosApi(`v1/products/favorites`);
        if (favoritesApi.ok) {
          const response = favoritesApi.data.categories
          const product = response[0].products.find(item => item.id === id);
          favoriteAnalytics(product.name);
          dispatch({
            type: types.GET_FAVORITES,
            payload: response[0].products
          })
          dispatch({ type: types.GET_FAVORITES_LOADER, payload: false });
        }
      }
    }
  }
};

export const isFavorite = (id) => (dispatch, getStore) => getStore().favorites.favorites.includes(id);

export const getAppFavorites = () => async (dispatch, getStore) => {
  const { products } = getStore().favorites
  if (products.length) return
  dispatch({ type: types.GET_FAVORITES_LOADER, payload: true });
  if (!products.length) {
    const favoritesApi = await axiosApi(`v1/products/favorites`, { captcha: true });
    if (favoritesApi.ok && favoritesApi.status === 200) {
      const response = favoritesApi.data.categories
      dispatch({
        type: types.GET_FAVORITES,
        payload: response[0].products
      })
      dispatch({ type: types.GET_FAVORITES_LOADER, payload: false });
    }

    if (favoritesApi.ok && favoritesApi.status === 204) {
      const response = []
      dispatch({
        type: types.GET_FAVORITES,
        payload: response
      })
      dispatch({ type: types.GET_FAVORITES_LOADER, payload: false });
    }
  }
}

export const resetFavorites = () => (dispatch) => {
  dispatch({
    type: types.RESET,
  })
}
