import React from 'react';
import { isChildOfClass } from '../../helpers/index';
import PerfectScroll from '../PerfectScroll';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      keyValue: this.props.keyValue || 'value',
      keyLabel: this.props.keyLabel || 'label',
    };
  }

  componentDidMount() {
    if (this.inputElement) {
      this.inputElement.addEventListener('click', (event) => {
        const selectActive = document.querySelector('.input-select.active');
        if (!!selectActive && selectActive !== this.inputElement.parentElement) {
          selectActive.classList.remove('active');
        }
        if (event.currentTarget.parentElement.classList.contains('active')) {
          event.currentTarget.parentElement.classList.remove('active');
        } else {
          event.currentTarget.parentElement.classList.add('active');
          event.currentTarget.parentElement.children[2].children[0].scrollTop = 0;
        }
      });
    }

    if (!window.hasSelectFormClose) {
      window.hasSelectFormClose = true;
      window.addEventListener('click', (event) => {
        const { target } = event;
        const selectActive = document.querySelector('.input-select.active');
        if (!isChildOfClass(target, 'input-select') && selectActive) {
          selectActive.classList.remove('active');
        }
      });
    }
  }

  selectValue = (item) => {
    if (this.props.onChange) {
      if (this.props.object) {
        return this.props.onChange(item);
      }
      const value = item[this.state.keyValue] || item;
      this.props.onChange(value);
    }
  };

  value = () => {
    if (this.props.value) return this.props.value[this.props.keyLabel] || this.props.value;

    return '';
  };

  isActive = (item) => {
    if (!this.props.value) return false;
    const value = item[this.state.keyValue] || item;
    const check = this.props.value[this.state.keyValue] || this.props.value;
    return value === check;
  };

  render() {
    if (!this.props.options || !this.props.options.length)
      return (
        <div className={`input-select ${this.props.className}${this.state.active ? ' active' : ''}${this.props.value ? ' completed' : ' empty '} disabled`}>
          <input type='text' value={this.value()} className='placeholder' placeholder={this.state.placeholder} readOnly={true} ref={(el) => (this.inputElement = el)} />
          <div className='label'>
            {this.props.icon && (
              <span className='icon'>
                <i className={this.props.icon}></i>
              </span>
            )}
            <span className='placeholder'>
              {this.props.placeholder || 'Seleccionar'}
              {this.props.suffix && <i>{this.props.suffix}</i>}
            </span>
          </div>
        </div>
      );
    return (
      <div
        className={`input-select ${this.props.className ? this.props.className : ''}${this.state.active ? ' active' : ''}${this.props.value ? ' completed' : ' empty'}${
          !this.props.options || !this.props.options.length ? ' disabled' : ''
        }`}>
        <input type='text' value={this.value()} className='placeholder' placeholder={this.state.placeholder} readOnly={true} ref={(el) => (this.inputElement = el)} />
        <div className='label'>
          {this.props.icon && (
            <span className='icon'>
              <i className={this.props.icon}></i>
            </span>
          )}
          <span className='placeholder'>
            {this.props.placeholder || 'Seleccionar'}
            {this.props.suffix && <i>{this.props.suffix}</i>}
          </span>
        </div>
        <PerfectScroll height={this.props.options.length * 40}>
          <ul className='options'>
            {this.props.options.map((item, index) => (
              <li key={index} onClick={() => this.selectValue(item)} className={`${this.props.keySubtitle ? 'with-subtitle ' : ''}${this.isActive(item) ? 'selected' : ''}`}>
                {item[this.state.keyLabel] || item}
                {this.props.keySubtitle && <span className="subtitle">
                  {item[this.props.keySubtitle]}
                  </span>}
              </li>
            ))}
          </ul>
        </PerfectScroll>
      </div>
    );
  }
}

export default Select;
