import React from 'react';
import PurchasesHistory from '../../../components/User/PurchasesHistory';
import ProfileContainer from '../ProfileContainer';

const MyPurchasesPage = () => {
  return (
    <ProfileContainer className='profile-purchases-wrapper'>
      <PurchasesHistory />
    </ProfileContainer>
  );
};

export default MyPurchasesPage;
