import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorLoad from '../components/ErrorLoad';
import Footer from '../components/Footer';
import Header from '../components/Header';
import RecommendedProductsMobile from '../components/MiniCart/RecommendedProductsMobile';
import SearchMobile from '../components/SearchMobile';
import { getHomeData } from '../store/actions/home';
import ErrorModal from './../components/ErrorModal';
import usePathInfo from './../hooks/usePathInfo';
import { globalSetPage } from './../store/actions/global';

const PageContainer = ({ children }) => {
  const history = useHistory();

  const {
    currentPage: { id, className, template },
    errorServer,
    alert,
  } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const page = usePathInfo({})

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  useEffect(() => {
    if (!page.info) {
      return history.push('/');
    }
    if (id !== page.info.id) {
      dispatch(globalSetPage(page.info));
    }
  }, [dispatch, history, id, page]);

  return (
    <div className='page'>
      <div id={id} className={className}>
        {(!template || template !== 'empty') && <Header />}
        {children}
        {(!template || template !== 'empty') && <Footer />}
        {errorServer && <ErrorLoad />}
        {alert && <ErrorModal msg={alert.message} type={alert.type || 'db'} />}
      </div>
      <SearchMobile />
      <RecommendedProductsMobile />
    </div>
  );
};

export default React.memo(PageContainer);
