import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductThumb from '../../../components/BasketBox/ProductThumb';
import Loader from '../../Loader';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { headerAnimations } from '../../../helpers/choreography/header';
import IconBox from '../../BasketBox/IconBox';
import { getProducts } from './../../../store/actions/products';
import { useParams } from 'react-router-dom';

const body = document.querySelector('body');

const CategoryProducts = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { view } = useSelector((state) => state.categories);
  const { category, loader, error, hideBtnLoader, reloadContent } = useSelector((state) => state.product);
  const { products } = category;

  const handleGetProducts = () => dispatch(getProducts(params.categoryID, { append: true, reloadFilters: false }));

  useEffect(() => {
    if (loader) {
      body.classList.add('reload-content');
    } else {
      body.classList.remove('reload-content');
    }
  });

  return (
    <div
      className={classNames({
        'category-products': true,
        'category-products--loading': loader,
      })}>
      {loader && !products?.length && <Loader spinner={{ title: 'Cargando' }} blue />}
      {loader && !!products?.length && <Loader spinner={{ title: 'Cargando' }} className='fixed-center-page' initial blue />}
      {/* <Loader spinner={{ title: 'Cargando' }} className='fixed-center-page' initial blue /> */}

      {!reloadContent && (
        <>
          {view && (!loader || !!products?.length) && !error && (
            <div className='cats-grids-flx cats-grids--grid'>
              {products &&
                products.map((prod, index) => {
                  return <ProductThumb list='categoria' key={index} product={prod} type={view ? 'prod' : 'list'} />;
                })}
              {!hideBtnLoader && (
                <div className='cats-filter-option-head'>
                  <p className='mxp-text-link icon-plus-circle' onClick={handleGetProducts}>
                    Ver más
                  </p>
                </div>
              )}
            </div>
          )}
          {!view && (!loader || !!products?.length) && !error && (
            <div className='cats-grids-flx cats-grids--list'>
              {products &&
                products.map((prod, index) => {
                  return <ProductThumb key={index} product={prod} type='list' />;
                })}
              {!hideBtnLoader && (
                <div className='cats-filter-option-head'>
                  <p className='mxp-text-link icon-plus-circle' onClick={handleGetProducts}>
                    Ver más
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {!loader && error && (
        <motion.div
          variants={headerAnimations.showCatsList}
          transition={{
            ease: 'easeInOut',
            duration: 0.2,
          }}
          initial='hidden'
          animate={'visible'}
          exit='hidden'
          className='search-results-error-wrap'>
          <div className='search-results-error'>
            <h3 className='mxp-title'>{error.message}</h3>
            <IconBox type='informer' id='container-informer-box' name='empty' percent={0} />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default CategoryProducts;
