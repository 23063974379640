import React from 'react';
import { moneyFormat } from '../helpers/index';

const MoneyFormat = ({ money, type }) => {
  if (type === 'mixed') {
    return (
      <>
        <span className='mxp-text-price'>{moneyFormat(money)}</span>
        {' MXN'}
      </>
    );
  }
  return (
    <>
      {moneyFormat(money)}
      <i style={{ fontStyle: 'normal' }}>MXN</i>
    </>
  );
};

export default React.memo(MoneyFormat);
