import React from 'react';

const ProductFeatures = ({ product }) => {
  const { brand, materials, attributes, sku } = product;

  if (!product) return '';

  const attribute = (code) => {
    return attributes?.find((item) => item.code === code)?.value;
  };

  return (
    <div id='product-features'>
      <h2 className='open-features'>Características</h2>
      <ul className='animate__animated animate__fadeIn'>
        <li>
          <i className='icon-size'></i>
          <div>
            {attribute('ancho')} cm x {attribute('largo')} cm x {attribute('alto')} cm
            <span className='label'>Ancho / Largo / Alto</span>
          </div>
        </li>
        <li className="sku">
          <i className='icon-tag'></i>
          <div>
            {brand?.name}
            <span className='label'>SKU: {sku}</span>
          </div>
        </li>
        <li>
          <i className='icon-weight'></i>
          <div>{attribute('peso')} kg</div>
        </li>
        <li>
          <i className='icon-material'></i>
          <div>
            {materials?.map((material, materialIndex) => (
              <span key={materialIndex}>{material.name}</span>
            ))}
          </div>
        </li>
        <li>
          <i className='icon-color'></i>
          <div>{attribute('color')}</div>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ProductFeatures);
