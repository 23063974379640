import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { imageUrl } from '../../helpers/index';
import useIsMobile from '../../hooks/useIsMobile';
import MoneyFormat from '../MoneyFormat';
import { cartSetLastAdded, addCart } from '../../store/actions/cart';

const RecommendedProductsMobile = () => {
  const isMobile = useIsMobile();

  const { count, total, last, related } = useSelector((state) => state.cart);

  const relatedItemsContainer = useRef(false);
  const windowPosition = useRef(false);

  const dispatch = useDispatch()

  const close = () => {
    dispatch(cartSetLastAdded())
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if(last){
      windowPosition.current = window.scrollY;
      body.classList.add('no-scroll')
    }
    return () => {
      const body = document.querySelector('body')
      windowPosition.current = false;
      body.classList.remove('no-scroll')
      setTimeout(() => window.scroll(0, windowPosition.current), 500)
    }
  }, [last])

  const addToCart = (product) => {
    relatedItemsContainer.current.scrollTop = 0;
    dispatch(addCart(product.product_id, product));
  }

  if (!isMobile || !last) return '';

  return (
    <section id='recommended-products-mobile'>
      <div className='recommended-header'>
        <div className='basket-mobile'>
          <span className='icon-basket pointer'>{count > 0 && <i>{count < 100 ? count : '99+'}</i>}</span>
        </div>
        <strong>Canasta</strong>
        <span className='icon-close' onClick={close}></span>
      </div>
      <div className='recommended-content' ref={relatedItemsContainer}>
        <div className='last-added'>
          <span>Añadiste 1 producto.</span>
          <img src={imageUrl(last.media[0]?.sizes.small)} alt={last.name} />
          <div className='detail'>
            <strong>{last.name}</strong>
            <span className='price'>
              <MoneyFormat money={last.price} />
            </span>
          </div>
          <div className='icon'>
            <i className='icon-ok'></i>
          </div>
        </div>

        {!!related.length && <div className='related-products'>
          <strong className='title'>¡Te sugerimos estos productos que están relacionados con tu compra!</strong>
          {related.map(item => <article key={item.product_id} onClick={() => addToCart(item)}>
            <img src={imageUrl(item.media[0]?.sizes.small)} alt={item.name} />
            <div className="detail">
              <h1>{item.name}</h1>
              <span className="price"><MoneyFormat money={item.price} /></span>
              <span className="btn btn-primary rounded btn-small">AÑADIR</span>
            </div>
          </article>)}
        </div>}
      </div>
      <div className='recommended-footer'>
        <div className='total'>
          Total:
          <span>
            <MoneyFormat money={total} />
          </span>
        </div>
        <div>
          <span className='btn btn-primary rounded' onClick={close}>CONTINUAR</span>
        </div>
      </div>
    </section>
  );
};

export default RecommendedProductsMobile;
