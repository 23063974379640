import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { buttons } from '../../helpers/choreography/buttons'

const Tag = ({ customClick, name, type, active }) => {
    const [isActive, setActive] = useState(false)

    const handleClick = () => {
        setActive(!isActive)
        customClick()
    }

    const handleClickTag = () => {
        customClick()
    }

    if(type === 'single'){
        return (
            <div className={classNames({
                'tag': true,
                'tag--selected': active,
                'tag--active': active
            })} onClick={handleClick}>
                <motion.div
                    variants={buttons.tagMove}
                    // animate={isActive ? "hidden" : "animate"}
                    className="tag-name"
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}>
                    {name}
                </motion.div>
                {/* <motion.div
                    variants={buttons.tagSel}
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}
                    animate={isActive ? "hidden" : "animate"}
                    className="tag-action icon-cancel-circle">
                </motion.div> */}
            </div>
        )
    }
    else if (type) {
        return (
            <div className={classNames({
                'tag': true,
                'tag--custom': type,
                'tag--selected': true
            })} onClick={handleClickTag}>
                <motion.div className="tag-name">
                    {name}
                </motion.div>
                <motion.div
                    variants={buttons.tagSel}
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}
                    animate={!isActive ? "hidden" : "animate"}
                    className="tag-action icon-cancel-circle">
                </motion.div>
            </div>
        )
    } else {
        return (
            <div className={classNames({
                'tag': true,
                'tag--selected': isActive,
                'tag--active': active
            })} onClick={handleClick}>
                <motion.div
                    variants={buttons.tagMove}
                    animate={isActive ? "hidden" : "animate"}
                    className="tag-name"
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}>
                    {name}
                </motion.div>
                <motion.div
                    variants={buttons.tagSel}
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}
                    animate={isActive ? "hidden" : "animate"}
                    className="tag-action icon-cancel-circle">
                </motion.div>
            </div>
        )
    }
}

export default Tag
