import axiosApi from '../axiosAPI';
import { types } from '../reducers/history-purchase';
import { cartSetWarnings, getCart } from './cart';

export const purchaseGetHistory = (next = '', order = 'newer', reset = false, delivery_status) => async (dispatch) => {
  dispatch({ type: types.HISTORY_LOADER, loader: true });
  let uri = `v2/payment/histories?asc=${order === 'older'}`;
  if (delivery_status) {
    uri += "&delivery_status=" + delivery_status;
  }

  if (next) {
    uri += "&next=" + next;
  }
  const response = await axiosApi.get(uri);
  if (response.data.status_code === 900) {
    return dispatch({ type: types.HISTORY_NOT_FOUND, notFound: response.data });
  }
  dispatch({ type: types.HISTORY_NOT_FOUND, notFound: false });
  dispatch({ type: types.HISTORY_GET, items: response.data.items, order, reset, next: response.data.next, deliveryStatus: delivery_status || false });
};

export const purchaseNextPage = (page) => (dispatch, getStore) => {
  const { items, next, order } = getStore().history;

  const check = !!items[page];
  if (!check) {
    return dispatch(purchaseGetHistory(next, order))
  }
  dispatch({ type: types.HISTORY_CHANGE_PAGE, page });
}

export const purchaseGetDetail = (reference) => async (dispatch) => {
  if (!reference) return;
  dispatch({ type: types.HISTORY_LOADER, loader: true });
  const response = await axiosApi.get(`v2/payment/histories/${reference}`);
  if (response.data.status_code === 900) {
    return dispatch({ type: types.HISTORY_GET_DETAIL, detail: 'back', });
  }
  return dispatch({ type: types.HISTORY_GET_DETAIL, detail: response.data });
};

export const isRecreate = (is) => async (dispatch) => {
  dispatch({ type: types.HISTORY_IS_RECREATE, payload: is });
}

export const purchaseRecreate = (orderID) => async (dispatch) => {
  dispatch({ type: types.HISTORY_LOADER_RECREATE, orderID });
  const response = await axiosApi.post('v1/cart/recreate', { order_id: orderID, captcha: true });
  if (response.data.order_id) {
    window.localStorage.setItem('orderID', response.data.order_id);
    await axiosApi.post('v1/users/orders/associate', { order_id: response.data.order_id, merge_items: true });
    await dispatch(getCart(response.data.order_id, true));
    if (response.data.warnings.length > 0) {
      dispatch(cartSetWarnings(response.data.warnings));
    }
    return dispatch({ type: types.HISTORY_LOADER_RECREATE });
  }
  dispatch({ type: types.HISTORY_ERROR_RECREATE, error: response.data })
};

export const sendReview = (review, reference) => async (dispatch) => {
  dispatch({ type: types.HISTORY_LOADER, loader: true });
  const response = await axiosApi.post('v1/products/reviews', { ...review, reference, captcha: true });
  if (response.status_code !== 105) {
    return dispatch(setProductReviewResponse(response.data));
  }
  dispatch(setProductReviewResponse(true));
  dispatch(purchaseGetDetail(reference));
};

export const historyReset = () => (dispatch) => dispatch({ type: types.HISTORY_RESET });

export const clearErrorRecreate = () => (dispatch) => {
  dispatch({ type: types.HISTORY_ERROR_RECREATE, error: false })
}

export const historyResetDetail = () => (dispatch) => dispatch({ type: types.HISTORY_RESET_DETAIL })

/**
 * Funci�n para obtener el seguimiento de env�o del producto.
 * @param { Referencia del producto. } reference
 *
 */

export const purchaseGetTracking = (reference) => async (dispatch) => {
  dispatch({ type: types.HISTORY_LOADER, loader: true });
  const tracking = await axiosApi.get(`v1/tracking/${reference}`);

  if (tracking.data.status_code === 0) {
    //Analytics
  }
  dispatch({ type: types.HISTORY_LOADER, loader: false });
  return dispatch({ type: types.HISTORY_TRACKING, tracking: tracking.data });
};

export const setReference = (reference) => (dispatch) => {
  dispatch({ type: types.SET_REFERENCE_HISTORY, reference })
}

export const setProductReview = (product) => (dispatch) => {
  dispatch({ type: types.SET_PRODUCT_REVIEW, product })
}

export const setProductReviewResponse = (status) => (dispatch) => {
  dispatch({ type: types.SET_PRODUCT_REVIEW_RESPONSE, status })
}

export const purchaseOpenTracking = (openTracking) => (dispatch) => dispatch({ type: types.HISTORY_OPEN_TRACKING, openTracking })
