import React from 'react'
import MoneyFormat from '../../MoneyFormat';

function ShippingDescription({ brand, handleSelection, id, classess }) {
    const {
        delivery_days,
        product,
        total
    } = brand;

    return (
        <article className={classess} onClick={() => { handleSelection(id) }}>
            <div className="shipping-cost">
                <p className="mxp-text shipping-price">
                    <MoneyFormat type="mixed" money={total} />
                </p>
                <p className="mxp-text mxp-text-clear">IVA INCLUIDO</p>
            </div>
            <div className="shipping-time">
                <p className="mxp-text shipping-days">{product}</p>
                {
                    delivery_days > 1
                        ?
                        <p className="mxp-text shipping-days-count">Entrega aproximada en {delivery_days} días.</p>
                        :
                        <p className="mxp-text shipping-days-count">Entrega aproximada en {delivery_days} día.</p>
                }
            </div>
        </article>
    )
}

export default ShippingDescription
