import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartAddItem } from '../../store/actions/cart';
import ProductThumb from '../BasketBox/ProductThumb';
import Breadcrumbs from '../Breadcrumbs';
import Image from '../Image';
import MoneyFormat from '../MoneyFormat';
import RatingProduct from '../RatingProduct';
import AppButton from '../UI/Buttons';

const Property = ({ title, icon, values }) => {
  return (
    <div className='property-mobile'>
      <div className='property-title'>
        <i className={`icon-${icon}`}></i>
        <strong>{title}</strong>
        <i className={`icon-${icon}`}></i>
      </div>
      <div className='property-values'>
        <span>{values[0]}</span>
        <span>{values[1]}</span>
      </div>
    </div>
  );
};

const attribute = (product, key) => {
  const find = product.attributes?.find((item) => item.code === key);
  return find?.value || '';
};

const ChangeProductToCompare = ({ close, set }) => {
  const { compare: products } = useSelector((state) => state.compare);
  return (
    <div className='select-product-to-compare'>
      <div className='compare-title'>
        <h1 className='section-title'>Elige un producto a comparar</h1>
        <i className='icon-cancel-circle' onClick={close}></i>
      </div>
      <div className='products-to-compare'>
        {products.map((product, productIndex) => {
          product.price = attribute(product, 'precio')
          return <ProductThumb action={() => close(product)} product={product} key={productIndex} />;
        })}
      </div>
    </div>
  );
};

const CompareProductMobile = () => {
  const [changeProduct, setChangeProduct] = useState(false);
  const [productCompare, setProductCompare] = useState(false);

  const dispatch = useDispatch()

  const { compare, main } = useSelector((state) => state.compare);
  const { loader } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!!compare.length) {
      setProductCompare(compare[0]);
    }
  }, [compare]);

  const handleChangeProduct = (status, product) => {
    if(product){
      setProductCompare(product)
    }
    window.scroll(0, 0);
    setChangeProduct(status);
  };

  const handleAddToCart = (product) => {
    product.price = attribute('precio')
    dispatch(cartAddItem(product, 1));
  };

  if (changeProduct) return <ChangeProductToCompare close={(product) => handleChangeProduct(false, product)} />;

  return (
    <>
      <Breadcrumbs />
      <h1 className='section-title'>Comparador de producto</h1>
      <div className='products'>
        <article className='main'>
          <Image src={main.media ? main.media[0]?.sizes.small : false} alt={main.name} />
          <RatingProduct rating={main.rating} />
          <h1>{main.name}</h1>
          <span className='price'>
            <MoneyFormat money={attribute(main, 'precio')} />
          </span>
          <AppButton txt="Agregar" type="main" loader={loader.product_id === main.product_id} available small onClick={() => handleAddToCart(main)}/>
        </article>
        <article className='main'>
          <Image src={productCompare.media ? productCompare.media[0]?.sizes.small : false} alt={productCompare.name} />
          <RatingProduct rating={productCompare.rating} />
          <h1>{productCompare.name}</h1>
          <span className='price'>
            <MoneyFormat money={attribute(productCompare, 'precio')} />
          </span>
          <AppButton txt="Agregar" type="main" loader={loader.product_id === productCompare.product_id} available small onClick={() => handleAddToCart(productCompare)}/>

        </article>
      </div>
      <div className='details'>
        <Property title='Marca' icon='tag' values={[attribute(main, 'marca'), attribute(productCompare, 'marca')]} />
        <Property
          title='Medidas'
          icon='size'
          values={[
            `${attribute(main, 'largo')} cm x ${attribute(main, 'ancho')} cm x ${attribute(main, 'alto')} cm`,
            `${attribute(productCompare, 'largo')} cm x ${attribute(productCompare, 'ancho')} cm x ${attribute(productCompare, 'alto')} cm`,
          ]}
        />
        <Property title='Material' icon='material' values={[attribute(main, 'material'), attribute(productCompare, 'material')]} />
        <Property title='Peso' icon='weight' values={[`${attribute(main, 'peso')} kg`, `${attribute(productCompare, 'peso')} kg`]} />
        <Property title='Color' icon='color' values={[attribute(main, 'color'), attribute(productCompare, 'color')]} />
      </div>
      <div className='change-product'>
        <div>
        <AppButton txt="Agregar" type="main" loader={loader.product_id === main.product_id} available small onClick={() => handleAddToCart(main)}/>
        </div>
        <div>
        <AppButton txt="Agregar" type="main" loader={loader.product_id === productCompare.product_id} available small onClick={() => handleAddToCart(productCompare)}/>
          <span className='link' onClick={() => handleChangeProduct(true)}>
            Cambiar producto
          </span>
        </div>
      </div>
    </>
  );
};

export default CompareProductMobile;
