import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PerfectScroll from '../PerfectScroll';
import { categoriesCarouselSetActive } from './../../store/actions/categories';

const CarouselCatsItem = ({ onChange, ...category }) => {
  const { active } = useSelector((state) => state.categories.carouselCats);

  const itemContainer = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [selected, setSelected] = useState(false);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState({});

  useEffect(() => {
    if (parseInt(params.categoryID) === category.id) {
      return setSelected(true);
    }
    const find = category.subcategories.find((item) => item.id === parseInt(params.categoryID));
    setSelected(!!find);
  }, [params, category]);

  const handleClick = (event) => {
    if (!category.subcategories.length) {
      setFilter();
      return;
    }

    const containerWidth = itemContainer.current.parentElement.offsetWidth;

    let left = event.currentTarget.getBoundingClientRect().x - 75;

    if (event.currentTarget.getBoundingClientRect().x - 75 > containerWidth - 233 + 75) {
      left = containerWidth - 233 + 75;
    }
    event.currentTarget.scrollIntoView({ block: 'nearest', inline: 'nearest' });

    setSubCategoriesOptions({
      left,
      height: category.subcategories.length * 22 + 22 + 24,
    });

    if (active.id !== category.id) {
      dispatch(categoriesCarouselSetActive(category));
    } else {
      dispatch(categoriesCarouselSetActive(false));
    }
  };

  const setFilter = (item = false) => {
    dispatch(categoriesCarouselSetActive(false));
    history.push(`/categoria/${item.id || category.id}/${item.code || category.code}`);
  };

  return (
    <div className={`carousel-cats-item${active.id === category.id ? ' open' : ''}${selected ? ' selected' : ''}`} ref={itemContainer}>
      <button onClick={handleClick}>
        {category.name}
        {!!category.subcategories.length && <i className='icon-down-open'></i>}
      </button>
      {!!category.subcategories.length && active.id === category.id && (
        <div className='carousel-subcategories' style={subCategoriesOptions}>
          <PerfectScroll scrollYMarginOffset={0}>
            <ul>
              <li onClick={setFilter} className={parseInt(params.categoryID) === category.id ? 'category-selected' : ''}>
                Ver todos <span>({category.product_quantity})</span>
              </li>
              {category.subcategories.map((subcategory, subcategoryIndex) => (
                <li key={subcategoryIndex} className={parseInt(params.categoryID) === subcategory.id ? 'category-selected' : ''} onClick={() => setFilter(subcategory)}>
                  {subcategory.name} <span>({subcategory.product_quantity})</span>
                </li>
              ))}
            </ul>
          </PerfectScroll>
        </div>
      )}
    </div>
  );
};

export default CarouselCatsItem;
