import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { categoriesGrid } from '../../store/actions/categories';
import { isMobile } from './../../helpers/index';

const CategoriesGrid = () => {
  const { categories, grid } = useSelector(state => state.categories)

  const dispatch = useDispatch()

  useEffect(() => {
    const removeGrid = () => {
      if(!isMobile() && grid){
        dispatch(categoriesGrid(false))
      }
    }

    window.addEventListener('resize', removeGrid);

    return () => {
      window.removeEventListener('resize', removeGrid);
    }
  }, [dispatch, grid])

  if(!categories.length || !grid) return '';

  return (<div id='categories-grid' className='animate__animated  animate__rubberBand'>
    {categories.map((category, categoryIndex) => <Link key={categoryIndex} to={`/categoria/${category.id}/${category.code}`}>
      <span>{category.name}</span>
    </Link>)}
  </div>);
};

export default CategoriesGrid;
