import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { purchaseAllowMakePayment, purchaseAllowPaymentMethods, purchaseAllowQuotations } from './../../store/actions/purchase';

const steps = [
  {
    path: '/carrito/datos-generales',
    title: 'Datos generales',
  },
  {
    path: '/carrito/metodo-de-entrega',
    title: 'Datos de entrega',
  },
  {
    path: '/carrito/metodo-de-pago',
    title: 'Método de pago',
  },
  {
    path: '/carrito/realizar-pago',
    title: 'Realizar pago',
  },
];

const StepsProgress = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { step } = useSelector((state) => state.purchase);

  useEffect(() => {
    const currentIndex = steps.findIndex((item) => item.path === location.pathname);

    dispatch({ type: 'PURCHASE_STEP', step: currentIndex });
  }, [dispatch, location, step]);

  const handleClick = (event, stepIndex) => {
    if (stepIndex === 1) {
      const allowQuotations = dispatch(purchaseAllowQuotations());
      if (!allowQuotations) {
        event.preventDefault();
        return;
      }
    }
    if (stepIndex === 2) {
      const allowPaymentMethods = dispatch(purchaseAllowPaymentMethods());
      if (!allowPaymentMethods) {
        event.preventDefault();
        return;
      }
    }
    if (stepIndex === 3) {
      const allowMakePayment = dispatch(purchaseAllowMakePayment());
      if (!allowMakePayment) {
        event.preventDefault();
        return;
      }
    }
  };

  return (
    <div id='steps-progress'>
      {steps.map((item, stepIndex) => (
        <NavLink to={item.path} key={stepIndex} className={step >= stepIndex ? 'enable' : ''} onClick={(event) => handleClick(event, stepIndex)}>
          <strong>
            <i className='step'>{stepIndex + 1}</i>
            <span className='title'>.- {item.title}</span>
          </strong>
        </NavLink>
      ))}
    </div>
  );
};

export default React.memo(StepsProgress);
