import React from 'react';
import ClosingSales from '../ClosingSales';
import ConfirmPayment from '../ConfirmPayment';
import MyAddresses from './MyAddresses';
import PaymentMethod from './PaymentMethod';

class PurchaseUserRegistered extends React.Component {
  render() {
    return (
      <div id='purchase-user-registered'>
        {this.props.step === 1 && <MyAddresses />}
        {this.props.step === 2 && <PaymentMethod />}
        {this.props.step === 3 && <ClosingSales />}
        {this.props.step === 4 && <ConfirmPayment />}
      </div>
    );
  }
}

export default PurchaseUserRegistered;
