import React from 'react';

class InputCard extends React.Component {
  state = {
    error: false,
  };

  focus = () => {
    this.inputContainer.classList.add('active');
  };

  blur = (event) => {
    const value = this.props.value || event.currentTarget.value.replace(/[^0-9]/g, '');

    this.props.onChange(value);

    if (this.props.type === 'card' && value) {
      let cardType = false;

      if (/^4[0-9]+/.test(value)) {
        cardType = 'visa';
      }

      if (/^(34|37).+/.test(value)) {
        cardType = 'amex';
      }

      if (/^(51|52|53|54|55|222100|271099).+/.test(value)) {
        cardType = 'mastercard';
      }

      if (!cardType) {
        this.setState({ error: '*El tipo de tarjeta no es válido, intenta con otra.' });
        return this.inputContainer.classList.add('error');
      }

      if (!/^4([0-9]{12}|[0-9]{15}|[0-9]{17,18})$/.test(value) && !/^(((51|52|53|54|55)[0-9]{14})|((222100|271099)[0-9]{10}))$/.test(value) && !/^3[47][0-9]{13}$/.test(value)) {
        this.setState({ error: '*El número de tarjeta no coincide con el formato solicitado, intenta nuevamente.' });
        return this.inputContainer.classList.add('error');
      }

      return;
    }

    if (this.props.type === 'cvv' && (!/^[0-9]{3,4}$/.test(value) || value.length < this.props.minLength)) {
      this.setState({ error: '*El código es incorrecto.' });
      return this.inputContainer.classList.add('error');
    }

    if (!value) {
      this.inputContainer.classList.remove('active');
    }
  };

  onchange = (event) => {
    const { currentTarget } = event;
    this.props.onChange(currentTarget.value.replace(/[^0-9]/g, ''));
    this.inputContainer.classList.remove('error');
    this.setState({ error: false });
  };

  componentDidMount() {
    if (!!this.props.value.length) {
      this.inputContainer.classList.add('active');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error && prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
      this.inputContainer.classList.add('error');
    }
  }

  render() {
    return (
      <div className={`input-card`} ref={(el) => (this.inputContainer = el)}>
        <div className='field'>
          <div className='label'>
            {this.props.icon && (
              <span className='icon'>
                <i className={this.props.icon}></i>
              </span>
            )}
            {this.props.placeholder}
          </div>
          <input
            type={this.props.type === 'cvv' ? 'password' : 'text'}
            value={this.props.value || ''}
            onChange={(event) => this.onchange(event)}
            onFocus={this.focus}
            onBlur={this.blur}
            autoComplete='off'
            inputMode='decimal'
            maxLength={this.props.maxLength}
            name={this.props.name}
          />
        </div>
        <div className='label-error'>{this.state.error}</div>
      </div>
    );
  }
}

export default InputCard;
