import { useSelector } from 'react-redux';
import { matchPath, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import cartRoutes from '../routes/cart';
import frontBoxRoutes from '../routes/frontBox';
import mainRoutes from '../routes/main';
import userRoutes from '../routes/private/profile-user';

const usePathInfo = ({ breadcrumbs, productCategory }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const { categories } = useSelector((state) => state.categories);


  const routes = [...mainRoutes, ...cartRoutes, ...frontBoxRoutes, ...userRoutes];

  const getInfo = (path) => routes.find((route) => matchPath(path, route));

  const parentCategory = (id) => {
    const categoryID = parseInt(id);
    let category = false;
    const findCategory = categories.some((item) => {
      const enableCategory = item.subcategories.find((c) => c.id === categoryID);
      if (enableCategory) {
        category = item;
      }
      return enableCategory;
    });

    return findCategory
      ? {
        title: category.name,
        path: `/box/categoria/${category.id}/${category.code}`,
      }
      : false;
  };

  const breadcrumbItem = (path) => {
    if (path.includes('/')) {
      return getInfo(path);
    }
    if (path === ':category') {
      return parentCategory(params.categoryID);
    }
    if (path === ':product-category' && productCategory) {
      return {
        title: productCategory.name,
        path: `/box/categoria/${productCategory.id}/${productCategory.code}`,
      };
    }
    if (path === ':product') {
      return {
        title: 'Detalle del producto',
        path: `/producto/${params.id}/${params.slug}`,
      };
    }
    return false;
  };

  const info = getInfo(pathname);
  const breadcrumbsItems = {}

  if (breadcrumbs) {
    const mobile = typeof info.breadcrumb_mobile === 'string' ? info.breadcrumb_mobile : info.breadcrumb_mobile?.map((item) => breadcrumbItem(item));
    const desktop = typeof info.breadcrumbs === 'string' ? info.breadcrumbs : info.breadcrumbs?.map((item) => breadcrumbItem(item));

    breadcrumbsItems.mobile = mobile;
    breadcrumbsItems.desktop = desktop;
  }

  return { info: info || false, breadcrumbs: breadcrumbsItems };
};

export default usePathInfo;
