const getBrand = (id) => {
  const brandsJSON = window.sessionStorage.getItem('brands');
  const brands = brandsJSON ? JSON.parse(brandsJSON) : [];
  return brands ? brands.find((item) => item.id === id) : {};
};

const getCategory = (id) => {
  if (!id) return {};

  const categoriesJSON = window.sessionStorage.getItem('categories');
  const categories = categoriesJSON ? JSON.parse(categoriesJSON) : [];
  return categories ? categories.find((item) => item.id === id) : {};
};

const getSector = () => window.localStorage.getItem('sector') || '';

const productAnalytics = (product, quantity = false) => {
  const data = {
    name: product.name,
    id: product.product_id || product.id,
    price: product.price,
    brand: product.brand || '',
    variant: '',
    dimension1: '',
    dimension2: '',
    dimension3: '',
  };
  if(product.attributes){
    let attributes = {};
    Object.values(product.attributes).forEach((attribute) => {
      attributes[attribute.code] = attribute.value;
    })

    if(attributes.alto){
      data.dimension1 = `${attributes.alto} cm x ${attributes.ancho} cm x ${attributes.largo} cm`
    }
    if(attributes.peso){
      data.dimension2 = attributes.peso
    }
  }
  if(product.materials){
    data.dimension3 = product.materials.map((material) => material.name)
  }

  if(quantity) data.quantity = quantity;

  let products = [];

  if(!!product.newVariants){
    const variantsRaw = product.newVariants.split(', ');
    variantsRaw.forEach((variant) => {
      const itemSplit = variant.split(': ');
      products.push({...data, variant: itemSplit[0], quantity: itemSplit[1]})
    })
  }else{
    products[0] = data;
  }

  return products;
};

const productsAnalytics = (products) => products.map((product) => productAnalytics(product));

export const analyticsRegister = () => {
  /** google analytics event */
  window.dataLayer.push({
    accionRegistro: 'Registrarme',
    metodoInicioSesion: 'Correo electrónico',
    event: 'Registro',
  });
  /** end - google analytcs event */
};

export const analyticsCategory = (category) => (dispatch, getStore) => {
  const sector = getStore().categories.sector;
  /** google analytics event */
  window.dataLayer.push({
    categoria: sector,
    subcategoria: category,
    event: 'Clic en categoría',
  });
  /** end - google analytcs event */
};

export const analyticsSearch = (text) => {
  /** google analytics event */
  window.dataLayer.push({
    busqueda: text,
    event: 'Busqueda',
  });
  /** end - google analytcs event */
};

export const analyticsFilter = (type, value) => {
  let tipoFiltro = false;
  let filtroUtilizado = false;

  switch (type) {
    case 'price':
      tipoFiltro = 'Por precio';
      filtroUtilizado = value.display;
      break;
    case 'brand':
      tipoFiltro = 'Por marca';
      filtroUtilizado = value.name;
      break;
    case 'material':
      tipoFiltro = 'Por material';
      filtroUtilizado = value.name;
      break;
    default:
      tipoFiltro = 'Ordenar';
      break;
  }

  if (type === 'sort') {
    switch (value) {
      case 1:
        filtroUtilizado = 'De la A a la Z';
        break;
      case 2:
        filtroUtilizado = 'De la Z a la A';
        break;
      case 3:
        filtroUtilizado = 'De menor a mayor $';
        break;
      default:
        filtroUtilizado = 'De mayor a menor $';
        break;
    }
  }

  if (!tipoFiltro) return;

  /** google analytics event */
  window.dataLayer.push({
    tipoFiltro,
    filtroUtilizado,
    event: 'Filtro',
  });
  /** end - google analytcs event */
};

export const analyticsSearchProductDetails = (product) => {
  const sector = getSector();
  const brand = getBrand(product.brand_id);
  /** google analytics event */
  const analyticsData = {
    ecommerce: {
      click: {
        actionField: { list: 'Search Results' },
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price,
            brand: brand?.name,
            category: sector,
          },
        ],
      },
    },
    event: 'productClick',
  };

  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

export const analyticsProductDetail = (product) => {
  let isDisabled = true;

  if (isDisabled) {
    productoDetailAnalytics(product);
    return;
  }

  const brand = product.brand || getBrand(product.brand_id);
  const sector = getSector();
  /** google analytics event */
  const analyticsData = {
    ecommerce: {
      detail: {
        actionField: { list: 'Apparel Gallery' },
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price,
            brand: brand?.name,
            category: sector + '/' + (product.categoryName || ''),
            variant: '',
            dimension3: product.rating + ' estrellas',
          },
        ],
      },
    },
    event: 'detail',
  };
  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

export const analyticsAddFavorite = (product) => {
  const brand = product.brand || getBrand(product.brand_id);
  const categoryName = product.categoryName || (product.product_category_ids?.length ? getCategory(product.product_category_ids[0]) : '');
  const sector = getSector();
  /** google analytics event */
  window.dataLayer.push({
    name: product.name,
    'categoria/subcategoria': sector + '/' + (categoryName?.name || categoryName || ''),
    marca: brand?.name,
    event: 'Favoritos',
  });
  /** end - google analytcs event */
};

export const analyticsAddCart = (product, quantity) => {
  if (!product) return;

  console.warn('analyticsAddCart', product);
  const brand = product.brand || getBrand(product.brand_id);
  const catIDs = product.product_category_ids || [];
  const categoryName = product.categoryName || getCategory(catIDs[0]);

  const cartItems = JSON.parse(window.sessionStorage.getItem('cartItems') || '[]');

  const cartItemIndex = cartItems.findIndex((item) => item.id === product.id || product.product_id);

  let cartItem = cartItems[cartItemIndex];

  if (cartItem && !cartItem.brand && brand?.name) {
    cartItem.brand = brand;
  }
  if (cartItem && !cartItem.categoryName) {
    cartItem.brand = categoryName;
  }

  if (cartItemIndex === -1) {
    cartItem = {
      id: product.id || product.product_id,
      brandName: brand?.name || brand,
      categoryName: categoryName?.name || categoryName,
    };
    cartItems.push(cartItem);
  } else {
    cartItems[cartItemIndex] = cartItem;
  }

  window.sessionStorage.setItem('cartItems', JSON.stringify(cartItems));

  const sector = getSector();

  addToCartProductAnalytics(product, quantity);

  const analyticsData = {
    ecommerce: {
      currencyCode: 'MXN',
      add: {
        products: [
          {
            brand: brand?.name,
            category: sector + '/' + (categoryName?.name || categoryName || ''),
            id: product.id,
            name: product.name,
            price: product.price,
            quantity,
            variant: '',
          },
        ],
      },
    },
    event: 'addToCart',
  };

  /** google analytics event */
  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

export const analyticsRemoveCart = (product, quantity) => {
  const sector = getSector();
  const brand = getBrand(product.brand_id);
  const analyticsData = {
    ecommerce: {
      currencyCode: 'MXN',
      remove: {
        products: [
          {
            name: product.name,
            id: product.id || product.product_id,
            price: product.price,
            brand: brand?.name || '',
            category: sector + '/' + (product.categoryName || ''),
            variant: '',
            quantity: quantity,
          },
        ],
      },
    },
    event: 'removeCart',
  };
  /** google analytics event */
  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

export const analyticsPurchase = () => (dispatch, getStore) => {
  const sector = getSector();
  const step = getStore().purchase.step;

  const sessionItems = JSON.parse(window.sessionStorage.getItem('cartItems') || '[]');

  const products = getStore().cart.products.map((item) => {
    const sessionItem = sessionItems.find((product) => product.id === item.product_id);

    return {
      brand: sessionItem?.brandName || '',
      category: sector + '/' + (sessionItem?.categoryName || ''),
      id: item.product_id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      variant: '',
    };
  });

  const analyticsData = {
    ecommerce: {
      checkout: {
        actionField: { step, option: step > 2 ? 'TC' : '' },
        products,
      },
    },
    event: 'checkout',
  };
  /** google analytics event */
  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

export const analyticsConfirmPayment = () => (dispatch, getStore) => {
  const sector = getSector();
  const cart = getStore().cart;
  const purchase = getStore().purchase;

  const sessionItems = JSON.parse(window.sessionStorage.getItem('cartItems') || '[]');

  const products = getStore().cart.products.map((item) => {
    const sessionItem = sessionItems.find((product) => product.id === item.product_id);

    return {
      brand: sessionItem?.brandName || '',
      category: sector + '/' + (sessionItem?.categoryName || ''),
      id: item.product_id,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      variant: '',
    };
  });

  let reference = '';

  if (purchase.deliveryType === 'tc') {
    reference = purchase.paymentTC.reference;
  }

  const analyticsData = {
    ecommerce: {
      purchase: {
        actionField: {
          id: reference,
          affiliation: sector,
          revenue: cart.total,
          dimension1: 'TC',
          dimension2: purchase.deliveryType === 'home' ? 'Domicilio' : 'En tienda',
        },
        products,
      },
    },
    event: 'purchase',
  };

  /** google analytics event */
  window.dataLayer.push(analyticsData);
  /** end - google analytcs event */
};

/************************* Analytics V2 *************************/

/**
 * Fase de envío de eventos hacia Google Analytics,
 * Doc de secciones a implementar: https://iainteractivec-my.sharepoint.com/:x:/g/personal/dhernandez_ia_com_mx/EUapB7NKgUVDj3OVE9ja8dABSI-PwEfuXIQcgss4zf3KRA?e=S7zcVQ&CID=48b80120-5b40-7c23-dad0-920c3c4033f8
 */

/**
 *
 * @param {Producto desde el servicio} product
 * @param {Cantidad de producto seleccionado por el usuario} quantity
 * @returns Regresa dimensiones del productos, producto de servicio y producto para DataLayer
 */
// const productProcess = (product, quantity) => {
//   let ancho = false;
//   let largo = false;
//   let alto = false;
//   let peso = false;
//   let color = false;
//   const sectorLocalStorage = window.localStorage.getItem('sector');
//   const sector = sectorLocalStorage && sectorLocalStorage !== 'false' ? sectorLocalStorage : 'home'
//   if (product.attributes) {
//     ancho = product.attributes.length > 0 && product.attributes.find((x) => x.name === 'Ancho');
//     largo = product.attributes.length > 0 && product.attributes.find((x) => x.name === 'Largo');
//     alto = product.attributes.length > 0 && product.attributes.find((x) => x.name === 'Alto');
//     peso = product.attributes.length > 0 && product.attributes.find((x) => x.name === 'Peso');
//     color = product.attributes.length > 0 && product.attributes.find((x) => x.name === 'Color');
//   }
//   const category = sector + (product.categoryName ? '/' + product.categoryName : '');
//   const materials = [];
//   if (product.materials?.length > 0) {
//     product.materials.forEach((item) => {
//       materials.push(item.name);
//     });
//   }

//   return {
//     sector,
//     ancho,
//     largo,
//     alto,
//     peso,
//     color,
//     materials,
//     category,
//     productInfo: product,
//     productDataLayer: [
//       {
//         name: product.name,
//         id: product.id,
//         price: product.price,
//         brand: product.brand?.name,
//         category: category,
//         variant: color?.value,
//         quantity,
//         dimension1: `${ancho?.value} cm x ${largo?.value} cm x ${alto?.value} cm`,
//         dimension2: peso?.value,
//         dimension3: materials,
//       },
//     ],
//   };
// };

/**
 * Vista de detalle del producto}
 * @param { producto con detalle }productDataLayer
 */
export const productoDetailAnalytics = ({ product, quantity = 1, listName = 'NA' }) => {
  try {
    const getProductProcess = productAnalytics(product);
    const dataLayerTemp = {
      ecommerce: {
        detail: {
          actionField: { list: listName },
          product: getProductProcess[0],
        },
      },
      event: 'detail',
    };
    window.dataLayer.push(dataLayerTemp);
  } catch (error) {
    console.warn(error);
  }
};

/**
 * Agregar producto al carrito o aumentar unidades.
 */
export const addToCartProductAnalytics = (product, quantity, containers) => {
  try {
    const getProductProcess = productAnalytics(product, quantity);

    const lastContainer = [...containers].pop();

    const dataLayer = {
      ecommerce: {
        currencyCode: 'MXN',
        contenedor: `${containers.length} x ${lastContainer.name}`,
        porcentajeContenedor: lastContainer.percentage,
        add: {
          products: getProductProcess,
        },
      },
      event: 'addToCart',
    };
    window.dataLayer.push(dataLayer);
  } catch (error) {
    console.warn(error);
  }
};

/**
 * Eliminar producto del carrito o disminuir unidades
 * @param {*} product
 * @param {*} quantity
 */
export const removeFromCartAnalytics = (product, quantity, containers) => {
  try {
    const getProductProcess = productAnalytics(product, quantity);

    const lastContainer = [...containers].pop();

    let dataLayer = {
      ecommerce: {
        currencyCode: 'MXN',
        contenedor: lastContainer ? `${containers.length} x ${lastContainer.name}` : '',
        porcentajeContenedor: lastContainer ? lastContainer.percentage : 0,
        remove: {
          products: getProductProcess,
        },
      },
      event: 'removeFromCart',
    };
    window.dataLayer.push(dataLayer);
  } catch (error) { }
};

/**
 * Pasos del proceso de compra 1 al 4.
 */
export const checkoutAnalytics = ({ products, containers, option, step = 1, ...rest }) => {

  const container = [...containers].pop();

  const productList = productsAnalytics(products);

  let dataLayerTemp = {
    ecommerce: {
      checkout: {
        actionField: { step, option: `${option.quantity} x ${option.name}` },
        products: productList,
        porcentajeContenedor: container.percentage,
        ...rest
      },
    },
    event: 'checkout',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Confirmar compra
 * @param {*} product
 * @param {*} quantity
 * @param {*} actionField
 */
export const purchaseConfirmAnalytics = ({ purchase, offices }) => {
  const container = [...purchase.containers].pop();
  const office = purchase.officeID ? offices.find(item => item.id === purchase.officeID) : false;

  const dataLayer = {
    ecommerce: {
      purchase: {
        actionField: {
          id: purchase.reference,
          revenue: purchase.total,
          contenedor: `${purchase.containers.length} x ${container.name}`,
          porcentajeContenedor: container.percentage,
          metodoEntrega: purchase.deliveryType === 'home' ? 'Entrega a domicilio' : 'Recoger en sucursal',
          entrega: purchase.deliveryType === 'home' ? purchase.shipping.carrier : office.name,
          pago: purchase.paymentMethod + (purchase.loyalty_points ? ' + Monedero electrónico' : '') + (purchase.msi ? ` - ${purchase.msi} MSI` : ''),
        },
        products: productsAnalytics(purchase.products),
      },
    },
    event: 'purchase',
  };
  window.dataLayer.push(dataLayer);
};

/**
 * Identificación, registro o login.
 * @param {Sección, Login o Registro} type
 * @param {Invitado, Google, etc} method
 * @param {segundos desde 01/1970 + random de 3 digitos} userId
 */
export const identityUserAnalytics = (type, method) => {
  const dataLayerTemp = {
    tipo: type,
    metodo: method,
    userID: Math.floor(Math.random() * 99999999999999999999).toString().padStart(20, 0),
    event: 'identificacion',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Búsqueda de productos
 * @param {*} consulta
 * @param {*} categoria
 */
export const searchAnalytics = (consulta, categoria) => {
  const dataLayerTemp = {
    consulta,
    categoria,
    event: 'busqueda',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Agregar a favoritos
 * @param {*} productoName
 */
export const favoriteAnalytics = (productoName) => {
  const dataLayerTemp = {
    producto: productoName,
    event: 'favorito',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Clic en slider
 * @param {*} promotions
 */

export const promoClickAnalytics = (promotions) => {
  const dataLayerTemp = {
    ecommerce: {
      promoClick: {
        promotions: [
          {
            name: promotions.name,
            creative: promotions.imgCreative,
            position: promotions.position,
          },
        ],
      },
    },
    event: 'promotionClick',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Filtrar productos
 * @param {*} tipoFiltro
 * @param {*} filtro
 */
export const filterAnalytics = (tipoFiltro, filtro) => {
  const dataLayer = {
    tipoFiltro,
    filtro,
    event: 'filtro',
  };
  window.dataLayer.push(dataLayer);
};

/**
 * Visualización de modal de contenedor
 * @param {*} contenedor
 * @param {*} porcentajeContenedor
 */
export const containerModalAnalytics = (contenedor, porcentajeContenedor) => {
  const dataLayerTemp = {
    contenedor: contenedor,
    porcentajeContenedor,
    event: 'modalContenedor',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Apertura de sección de sucursales en móvil
 * @param {*} sucursalName
 */
export const aperturaSucursalAnalytics = (sucursalName) => {
  const dataLayerTemp = {
    sucursal: sucursalName,
    event: 'aperturaSucursal',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Apertura de mapa de sucursal
 * @param {*} sucursalName
 */
export const mapaSucursalAnalytics = (sucursalName) => {
  const dataLayerTemp = {
    sucursal: sucursalName,
    event: 'mapaSucursal',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Clic en telefono de sucursal
 * @param {*} sucursalName
 */
export const telefonoSucursalAnalytics = (sucursalName) => {
  const dataLayerTemp = {
    sucursal: sucursalName,
    event: 'telefonoSucursal',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Cambio de vista de lista a grid o viceversa
 * @param {*} vista
 */
export const vistaAnalytics = (vista) => {
  const dataLayerTemp = {
    vista,
    event: 'vista',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Formulario de contacto
 * @param {*} asuento
 */
export const contactoAnalytics = (asuento) => {
  const dataLayerTemp = {
    asuento,
    event: 'contacto',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Perfilamiento
 * @param {*} medio
 */
export const segmentoAnalytics = (medio) => {
  const dataLayerTemp = {
    medio,
    event: 'perfilamiento',
  };
  window.dataLayer.push(dataLayerTemp);
};

/**
 * Reproducción de tutoriales
 * @param {*} tutorial
 */
export const tutorialAnalytics = (tutorial) => {
  const dataLayerTemp = {
    tutorial,
    event: 'reproduccionTutorial',
  };
  window.dataLayer.push(dataLayerTemp);
};
