import React from 'react';
import { connect } from 'react-redux';
import moveInput from '../../../helpers/input-auto-scroll-viewport';
import { enableNext } from '../../../store/actions/purchase';
import FormAddCard from '../../FormAddCard';

class PaymentMethod extends React.Component {
  componentDidUpdate(){
    const inputsModal = document.querySelectorAll('.form-add-card input');
    const container = this.props.step === 3 ? '.form-change-payment-method .ps' : false;
    const distance = this.props.step === 3 ? 0 : 120;
    inputsModal.forEach((input) => {
      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, container, distance, 500);
      });
    });
  }

  render() {
    return (
      <div id='payment-method' className={`user-guest ${this.props.paymentMethod}`}>
        <ul className='methods'>
          <li className={`tc${this.props.paymentMethod === 'tc' ? ' active' : ''}`} onClick={() => this.props.setMethod('tc')}>
            <i className='icon-card'></i>
            <strong>Tarjeta de crédito o débito</strong>
          </li>
        </ul>
        {this.props.paymentMethod === 'tc' && <FormAddCard labelSubmit={this.props.labelSubmit || false} />}
        {this.props.paymentMethod !== 'tc' && (
          <div className='next-paypal'>
            <button className={`btn btn-primary rounded paypal${this.props.paymentMethod !== 'paypal' ? ' disabled' : ''}`}>{this.props.labelSubmit || 'SIGUIENTE'}</button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    paymentMethod: state.purchase.paymentMethod,
    cardID: state.purchase.cardID,
    step: state.purchase.step
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMethod: (method) => dispatch({ type: 'PURCHASE_SET_PAYMENT_METHOD', method }),
    enableNextPaymentMethod: () => dispatch(enableNext()),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(PaymentMethod);
