export const types = {
  CHANGE_VIEW: 'CHANGE_VIEW',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORY_PRODUCTS: 'GET_CATEGORY_PRODUCTS',
  CATEGORIES_LOADER: 'CATEGORIES_LOADER',
  CATEGORIES_REMOVE_LOADER: 'CATEGORIES_REMOVE_LOADER',
  CATEGORIES_SET_SECTOR: 'CATEGORIES_SET_SECTOR',
  CATEGORIES_GET_BANNER: 'CATEGORIES_GET_BANNER',
  CATEGORIES_SET_SECTOR_LOADER: 'CATEGORIES_SET_SECTOR_LOADER',
  CATEGORIES_RESET: 'CATEGORIES_RESET',
  CATEGORIES_PRODUCTS_RECOMMENDED: 'CATEGORIES_PRODUCTS_RECOMMENDED',
  CATEGORIES_HIDE_BTN_LOAD: 'CATEGORIES_HIDE_BTN_LOAD',
  CATEGORIES_GRID: 'CATEGORIES_GRID',
  CATEGORIES_CAROUSEL_SET_ACTIVE: 'CATEGORIES_CAROUSEL_SET_ACTIVE',
  RESET: 'RESET',
  GET_CUSTOM_CATEGORIES: 'GET_CUSTOM_CATEGORIES',
  LOAD_CUSTOM_CATEGORIES: 'LOAD_CUSTOM_CATEGORIES',
  LOAD_REMOVE_CUSTOM_CATEGORIES: 'LOAD_REMOVE_CUSTOM_CATEGORIES',
  GET_LAST_VISITED: 'GET_LAST_VISITED',
  LOAD_LAST_VISITED: 'LOAD_LAST_VISITED',
  LOAD_REMOVE_LAST_VISITED: 'LOAD_REMOVE_LAST_VISITED',
  GET_MOST_WANTED: 'GET_MOST_WANTED',
  LOAD_MOST_WANTED: 'LOAD_MOST_WANTED',
  GET_BEST_SELLERS: 'GET_BEST_SELLERS',
  LOAD_BEST_SELLERS: 'LOAD_BEST_SELLERS',
  SET_SORT: 'SET_SORT',
  SHOW_FILTERS: 'SHOW_FILTERS',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_RANGE: 'SET_RANGE',
  SET_MATERIALS: 'SET_MATERIALS',
  SET_BRANDS: 'SET_BRANDS',
  SET_ACTIVE_TAGS: 'SET_ACTIVE_TAGS',
  SET_APPLY_FILTERS: 'SET_APPLY_FILTERS'
};

const initialStore = {
  banner: false,
  banner_loader: false,
  brands: [],
  cat_ids_pending: [],
  categories: [],
  categories_w_products: [],
  custom_categories: [],
  custom_categories_loader: false,
  hideBtnLoad: false,
  loader: false,
  notFound: false,
  products: [],
  productsRecommended: false,
  sector: '',
  grid: false,
  carouselCats: {
    active: false,
  },
  last_visited: [],
  last_visited_loader: false,
  best_sellers: [],
  best_sellers_loader: false,
  most_wanted: [],
  most_wanted_loader: false,
  view: true,
  sort: 'Más relevantes',
  showFilters: false,
  applyFiltersBtn: false,
  selected_category: '',
  priceRange: {},
  material: {},
  brand: {},
  active_filter_tags: [],
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.GET_CATEGORIES:
      window.sessionStorage.setItem('categories', JSON.stringify(action.categories));
      return {
        ...store,
        categories: action.categories,
        categories_w_products: action.products,
        cat_ids_pending: action.IDs,
        loader: false,
        hideBtnLoad: action.hideBtnLoad,
        notFound: !action.products.length,
      };
    case types.GET_CATEGORY_PRODUCTS:
      return { ...store, categories_w_products: [...store.categories_w_products, ...action.products], cat_ids_pending: action.IDs, loader: false, hideBtnLoad: action.hideBtnLoad };
    case types.CATEGORIES_LOADER:
      return { ...store, loader: true };
    case types.CATEGORIES_REMOVE_LOADER:
      return { ...store, loader: false };
    case types.CATEGORIES_SET_SECTOR:
      window.localStorage.setItem('sector', action.sector.sector || action.sector);
      const sector = action.sector.sector || action.sector;
      return { ...store, sector, sector_id: action.sector.id };
    case types.CATEGORIES_GET_BANNER:
      return { ...store, sector: action.sector, banner: action.banner, banner_loader: false };
    case types.CATEGORIES_SET_SECTOR_LOADER:
      return { ...store, banner_loader: true };
    case types.CATEGORIES_HIDE_BTN_LOAD:
      const updateCategoriesProducts = action.notFound ? [] : [...store.categories_w_products];
      return { ...store, hideBtnLoad: action.hidden, notFound: action.notFound, categories_w_products: updateCategoriesProducts };
    case types.CATEGORIES_RESET:
      return { ...initialStore };
    case types.CATEGORIES_PRODUCTS_RECOMMENDED:
      return { ...store, productsRecommended: action.products };
    case types.CATEGORIES_GRID:
      return { ...store, grid: action.grid }
    case types.CATEGORIES_CAROUSEL_SET_ACTIVE:
      return { ...store, carouselCats: { ...store.carouselCats, active: action.active } }
    case types.GET_CUSTOM_CATEGORIES:
      return { ...store, custom_categories: [...store.custom_categories, ...action.payload] }
    case types.LOAD_CUSTOM_CATEGORIES:
      return { ...store, custom_categories_loader: true };
    case types.LOAD_REMOVE_CUSTOM_CATEGORIES:
      return { ...store, custom_categories_loader: false };
    case types.GET_LAST_VISITED:
      return { ...store, last_visited: [...store.last_visited, ...action.payload] }
    case types.LOAD_LAST_VISITED:
      return { ...store, last_visited_loader: true };
    case types.LOAD_REMOVE_LAST_VISITED:
      return { ...store, last_visited_loader: false };
    case types.GET_MOST_WANTED:
      return { ...store, most_wanted: [...store.most_wanted, ...action.payload] }
    case types.LOAD_MOST_WANTED:
      return { ...store, most_wanted_loader: action.payload };
    case types.GET_BEST_SELLERS:
      return { ...store, best_sellers: [...store.best_sellers, ...action.payload] }
    case types.LOAD_BEST_SELLERS:
      return { ...store, best_sellers_loader: action.payload };
    case types.CHANGE_VIEW:
      return { ...store, view: action.payload };
    case types.SET_SORT:
      return { ...store, sort: action.payload };
    case types.SET_BRANDS:
      return { ...store, brand: action.payload };
    case types.SET_MATERIALS:
      return { ...store, material: action.payload };
    case types.SHOW_FILTERS:
      return { ...store, showFilters: action.payload };
    case types.SET_CATEGORY:
      return { ...store, selected_category: action.payload };
    case types.SET_RANGE:
      return { ...store, priceRange: action.payload || {} };
    case types.SET_ACTIVE_TAGS:
      return { ...store, active_filter_tags: [...store.active_filter_tags, ...action.payload] };
    case types.SET_APPLY_FILTERS:
      return { ...store, applyFiltersBtn: action.payload };
    default:
      const defaultSector = window.localStorage.getItem('sector') || '';
      const defaultBrands = window.sessionStorage.getItem('brands') || '[]';
      const defaultCategories = window.sessionStorage.getItem('categories') || '[]';

      return { ...store, sector: defaultSector, categories: JSON.parse(defaultCategories), brands: JSON.parse(defaultBrands) };
  }
};

export default reducer;
