import CategoriesPage from '../pages/CategoriesPage';
// import CategoryPage from '../pages/CategoryPage';
import ContactPage from '../pages/ContactPage';
import HomePage from '../pages/Box/HomePage';
import ProductPage from '../pages/ProductPage';
import PurchasePage from '../pages/Purchase';
import SearchPage from '../pages/Box/Search';
import SurveyPage from '../pages/SurveyPage';
import CompareProductPage from './../pages/Product/CompareProductPage';
import ReviewsPage from './../pages/Product/ReviewsPage';
import ActiveAccountPage from './../pages/ActiveAccountPage';
import RecoveryPassword from '../pages/User/RecoveryPassword';
import Categories from './../pages/Box/Categories';
// import TutorialsPage from '../pages/TutorialsPage';
import TutorialsPage from './../pages/TutorialsPage';

const routes = [
  {
    id: 'home-page',
    name: 'home',
    title: 'Home',
    path: '/',
    exact: true,
    component: HomePage,
    breadcrumbs: false,
  },
  {
    id: 'categories-page',
    name: 'categories',
    title: 'Categorías',
    path: '/categorias',
    exact: true,
    component: CategoriesPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true
  },
  {
    id: 'category-page',
    name: 'category',
    title: 'Categoría',
    path: '/categoria/:categoryID/:slug',
    exact: true,
    component: Categories,
    breadcrumbs: ['/', '/categorias', ':category'],
    breadcrumb_mobile: 'back',
  },
  {
    id: 'product-page',
    name: 'product',
    title: 'Producto',
    path: '/producto/:id/:slug',
    exact: true,
    component: ProductPage,
    breadcrumbs: ['/', ':product-category'],
    breadcrumb_mobile: [':product-category'],
    breadcrumb_remove_title_mobile: true
  },
  {
    id: 'reviews-page',
    name: 'reviews',
    title: 'Comentarios',
    path: '/comentarios/:id/:slug',
    exact: true,
    component: ReviewsPage,
    breadcrumbs: [':product'],
    breadcrumb_mobile: [':product'],
    breadcrumb_remove_title_mobile: true
  },
  {
    id: 'compare-product-page',
    name: 'compare-product',
    title: 'Comparar producto',
    path: '/comparar',
    exact: true,
    component: CompareProductPage,
    breadcrumbs: [':product'],
    breadcrumb_mobile: [':product'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'compare-page',
    name: 'compare',
    title: 'Comparar',
    path: '/comparar/:id/:slug',
    exact: true,
    breadcrumbs: [':product'],
    breadcrumb_mobile: [':product'],
    breadcrumb_remove_title_mobile: true,
    component: CompareProductPage,
  },
  {
    id: 'cart-page',
    name: 'cart',
    title: 'Carrito',
    path: '/carrito',
    exact: true,
    component: PurchasePage,
  },
  {
    id: 'search-page',
    name: 'search',
    title: 'Buscar',
    path: '/buscar/:search',
    exact: true,
    component: SearchPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
  },
  {
    id: 'categories-page',
    name: 'active-account',
    title: 'Activar cuenta de usuario',
    path: '/usuario/activar-cuenta/:token',
    exact: true,
    component: ActiveAccountPage,
    breadcrumbs: ['/']
  },
  {
    id: 'categories-page',
    name: 'recovery-account',
    title: 'Recuperar contraseña',
    path: '/usuario/recuperar-contrasena/:recovery_token',
    exact: true,
    component: RecoveryPassword,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'contact-page',
    name: 'contact-page',
    title: 'Contacto',
    path: '/contacto',
    exact: true,
    component: ContactPage,
  },
  {
    id: 'survey-page',
    name: 'survey-page',
    title: 'Encuesta',
    path: '/encuesta/:token',
    exact: true,
    template: 'empty',
    component: SurveyPage,
  },
  {
    id: 'tutorials-page',
    name: 'tutorials-page',
    title: 'Tutorials',
    path: '/tutoriales',
    exact: true,
    component: TutorialsPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
  },
];

export default routes;
