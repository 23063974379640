import React from 'react'
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import BoxLoginForm from './BoxLoginForm';
import UserMenu from './UserList';
import UserRecovery from './UserRecovery';

const UserNavigational = () => {
    const { layer, logged } = useSelector((state) => state.user)

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {/* Mobile user menu */}
                {layer === 'user-menu' && <UserMenu />}
                {layer === 'user-login' && !logged && <BoxLoginForm />}
                {layer === 'user-recovery' && !logged && <UserRecovery />}
            </AnimatePresence>
        </>
    )
}

export default UserNavigational
