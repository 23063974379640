import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/UI/ScrollTop';
import BoxRoutes from './containers/BoxRoutes';
import CartRoutes from './containers/CartRoutes';
import MainRoutes from './containers/MainRoutes';
import ProfileRoutes from './containers/ProfileRoutes';
import NotFound from './pages/NotFound';
import { getHomeData } from './store/actions/home';

const setVH = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setVH);
window.addEventListener('load', setVH);

function App() {
  const dispatch = useDispatch();
  const { offices } = useSelector((state) => state.home);

  useEffect(() => {
    if (!offices.length) {
      dispatch(getHomeData());
    }
  });
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/box' component={BoxRoutes} />
        <Route path='/carrito' component={CartRoutes} />
        <Route path='/perfil' component={ProfileRoutes} />
        <Route path='/' component={MainRoutes} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default React.memo(App);
