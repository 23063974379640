export const onlyAcceptName = /[^a-zÀ-ÿ`´¨^\s]/gi;

export const onlyAlphaNumeric = /[^a-z0-9À-ÿ()´`~¨\s-]/gi;

export const onlyAcceptEmail = /[^a-zA-Z0-9@+.-_]/g;

export const onlyAcceptNumbers = /[^0-9]/g;

export const onlyAcceptNumbersLetters = /[^a-zA-Z0-9-]/g;

export const onlyAcceptPhone = /[^0-9]/g;

export const clearAddress = /[^a-zÀ-ÿ0-9#.,\s]/gi;

export const clearText = /[^\wÀ-ÿ,.\n_\s-]/gi;

export const validateEmail = {
  required: true,
  pattern: { value: /^\w+([.+-]?\w+)*@[a-z0-9]+([-_]{1,2})?([a-z]*)(\.[a-z]{2,15})+$/i, message: '*Correo electrónico inválido.' }
};

export const validateName = {
  required: true,
  maxLength: {
    value: 100,
    message: 'Longitud máxima de 100 caracteres.'
  },
  minLength: {
    value: 4,
    message: 'Longitud mínima de 4 caracteres.'
  },
  validate: {
    onlyLetters: (value) => /^[a-zÀ-ÿ\s]+$/i.test(value) || '*Nombre solo puede contener letras, acentos y espacios.',
    fullName: (value) => /^([a-zÀ-ÿ]{3,})(?:\s[a-zÀ-ÿ]{3,})+$/i.test(value) || '*El nombre y apellido deben contener al menos 3 caracteres cada uno.'
  }
};

export const validateRFC = {
  validate: {
    length: (value) => !value || /^.{12,13}$/i.test(value) || 'Debes ingresar de 12 a 13 caracteres.',
    alphanumeric: (value) => !value || /^[a-z0-9]+$/i.test(value) || 'No se permiten acentos y/o caracteres especiales.',
    format: (value) => !value || /^[a-z]{3,4}[0-9]{6}[a-z0-9]{3}$/i.test(value) || 'Formato invalido.'
  }
}

export const typeCard = (value) => {
  if (/^4[0-9]+/.test(value)) {
    return {ok: true, type: 'visa'};
  }

  if (/^(34|37).+/.test(value)) {
    return {ok: true, type: 'american_express'};
  }

  if (/^(51|52|53|54|55|222100|271099).+/.test(value)) {
    return {ok: true, type: 'mastercard'};
  }

  return {ok: false, message: '*El tipo de tarjeta no es válido, intenta con otra.'};
}

export const validateCard = {
  required: true,
  validate: {
    typeCard: (value) => typeCard(value).ok || '*El tipo de tarjeta no es válido, intenta con otra.',
    format: (value) => /^4([0-9]{12}|[0-9]{15}|[0-9]{17,18})$/.test(value) || /^(((51|52|53|54|55)[0-9]{14})|((222100|271099)[0-9]{10}))$/.test(value) || /^3[47][0-9]{13}$/.test(value) || '*El número de tarjeta no coincide con el formato solicitado, intenta nuevamente.'
  }
}

export const validateCVV = {
  required: true,
  validate: {
    onlyNumbers: (value) => /^[0-9]+$/.test(value) || '*Campo numérico',
    format: (value) => /^[0-9]{3,4}$/.test(value) || '*El código es incorrecto.'
  }
}

export const validatePassword = ({equal, not_equal, equal_message = '', not_equal_message = ''}) => ({
  minLength: {
    value: 8,
    message: '*La contraseña debe contener al menos 8 caracteres.',
  },
  maxLength: {
    value: 30,
    message: '*La contraseña debe contener máximo 30 caracteres.',
  },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?¡"/+,.:;='^|~_()¿{}[\]\\-])[A-Za-z\d#$@!%&*?¡"/+,.:;='^|~_()¿{}[\]\\-]{8,32}$/,
    message: '*La contraseña debe contener al menos 1 letra minúscula, 1 letra mayúscula, 1 número y un carácter especial.',
  },
  validate: {
    equal: (value) => {
      return equal ? (value === equal || equal_message) : true
    },
    notEqual: (value) => {
      return not_equal ? (value !== not_equal || not_equal_message) : true
    },
  }
});

export const validatePhone = {
  required: true,
  pattern: {
    value: /^[\d]{10}$/,
    message: '*El teléfono debe contener 10 dígitos.',
  },
  maxLength: {
    value: 10,
    message: '*El teléfono debe contener 10 dígitos.',
  }
};

export const validateNumberStreet = {
  required: true,
  minLength: {
    value: 1,
    message: '*Debe contener al menos 1 dígito',
  },
  maxLength: {
    value: 10,
    message: '*Máximo 10 caracteres.',
  },
  pattern: {
    value: /^[a-zA-Z\d-]+$/,
    message: '*Solo admite números, letras y guion medio (-).',
  },
};

export const validateAddress = {
  required: true,
  minLength: {
    value: 4,
    message: '*La dirección debe contener al menos 4 caracteres.',
  },
  maxLength: {
    value: 200,
    message: '*La dirección debe ser menor o igual a 200 caracteres.',
  },
  pattern: {
    value: /^[a-zÀ-ÿ0-9#.,\s]+$/i,
    message: '*Solo admite números, letras, comas y puntos.',
  }
};

export const validateText = {
  required: true,
  minLength: {
    value: 4,
    message: '*El alias debe contener al menos 4 caracteres.',
  },
  maxLength: {
    value: 200,
    message: '*El alias debe ser menor o igual a 200 caracteres.',
  },
  pattern: {
    value: /^[\wÀ-ÿ,.\n_-\s]+$/i,
    message: '*Solo admite letras, coma, punto y guion medio (-).',
  }
};

export const validateZip = {
  required: true,
  maxLength: {
    value: 5,
    message: 'Máximo 5 dígitos.',
  },
  pattern: {
    value: /^[0-9]{5}$/,
    message: '*El Código Postal debe contener 5 dígitos.',
  },
};
