import React from 'react'
import ContainerBoxPage from '../../components/containers/ContainerBoxPage'

const Category = () => {
    return (
        <ContainerBoxPage className='category-box-page'>
            Category
        </ContainerBoxPage>
    )
}

export default Category
