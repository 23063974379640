import { useEffect, useState } from 'react';
import { typeCard } from '../helpers/validations';

const validations = {
  visa: {
    type: 'visa',
    cvv: {
      placeholder: '3 dígitos',
      options: {
        required: true,
        validate: {
          onlyNumbers: (value) => /^[0-9]+$/.test(value) || '*Campo numérico',
          format: (value) => /^[0-9]{3}$/.test(value) || '*El código es incorrecto.'
        },
      },
    },
  },
  mastercard: {
    type: 'mastercard',
    cvv: {
      placeholder: '3 dígitos',
      options: {
        required: true,
        validate: {
          onlyNumbers: (value) => /^[0-9]+$/.test(value) || '*Campo numérico',
          format: (value) => /^[0-9]{3}$/.test(value) || '*El código es incorrecto.'
        },
      },
    },
  },
  american_express: {
    type: 'american_express',
    cvv: {
      placeholder: '4 dígitos',
      options: {
        required: true,
        validate: {
          onlyNumbers: (value) => /^[0-9]+$/.test(value) || '*Campo numérico',
          format: (value) => /^[0-9]{4}$/.test(value) || '*El código es incorrecto.'
        },
      },
    },
  },
};

const useCard = (number) => {
  const [data, setData] = useState(validations.visa);

  useEffect(() => {
    let cardType = validations[number];
    if(!cardType) {
      const checkType = typeCard(number).type || 'visa';
      cardType = validations[checkType];
    }
    setData(cardType);
  }, [number]);

  return data;
};

export default useCard;
