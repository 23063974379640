import React from 'react';

class ErrorUploadImage extends React.Component {
  render() {
    return (
      <section className='error-upload-image'>
        <div>
          <div className='btns-go-back'>
            <span className='btn-close' onClick={() => this.props.close()}>
              <i className='icon-close'></i>
            </span>
            <span className='go-back' onClick={() => this.props.close()}>
              <i className='icon-left-open'></i> Regresar
            </span>
          </div>
          <div className='content'>
            <img src='/img/alert-image.png' alt='Lo sentimos' />
            <h1>Lo sentimos</h1>
            <p>La imagen ha excedido el límite de peso (12 MB) o no cumple con el formato requerido (JPG, JPGE, PNG).</p>
            <span className='btn btn-primary rounded' onClick={() => this.props.close()}>
              NUEVA IMAGEN
            </span>
          </div>
        </div>
      </section>
    );
  }
}

export default ErrorUploadImage;
