import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from '../../components/Cart/Container';
import GeneralDataGuest from '../../components/Cart/GeneralDataGuest';
import GeneralDataLogged from '../../components/Cart/GeneralDataLogged';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import { checkoutAnalytics } from './../../store/actions/analytics-events';

const GeneralDataPage = () => {
  const { logged } = useSelector((state) => state.user);
  const { products, containers } = useSelector((state) => state.cart);

  const loaded = useRef();

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      const option = { name: [...containers].pop().name, quantity: containers.length };
      checkoutAnalytics({ step: 1, products, containers, option });
    }
  }, [products, containers]);

  return (
    <ContainerBoxPage className='purchase-process'>
      <Container modifier='general-data-page'>
        {!logged && <GeneralDataGuest />}
        {logged && <GeneralDataLogged />}
        <div className='quote-here'>
          <p>¿Eres comerciante o mayorista?</p>
          <p>Contamos con precios especiales para ti.</p>
          <Link to='/contacto' className='link'>
            COTIZA AQUÍ
          </Link>
        </div>
      </Container>
    </ContainerBoxPage>
  );
};

export default GeneralDataPage;
