import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormAddNewAddress from './FormAddNewAddress';

class FormChangeAddressLogged extends React.Component {
  state = {
    addAddress: false,
  };
  isAddressSelected = (item) => this.props.changeAddressID === item.address_id || (!this.props.changeAddressID && this.props.addressID === item.address_id);

  addNewAddressForm = () => this.setState({ addAddress: true });

  selectAddress = (id) => {};

  render() {
    return (
      <Fragment>
        {!this.props.formAddNewAddressLogged && (
          <ul className={`addresses`}>
            {this.props.addresses.length < 200 && (
              <li className='item-address btn-add-address' onClick={() => this.props.addAddress(true)}>
                <div>
                  <i className='icon-plus'></i>
                  AGREGAR NUEVA
                </div>
              </li>
            )}
            {this.props.addresses &&
              this.props.addresses.map((item, index) => (
                <li className={`item-address${this.isAddressSelected(item) ? ' active' : ''}`} key={index} onClick={() => this.props.selectNewAddress(item.address_id)}>
                  <i className='icon-pin'></i>
                  <h1>{item.alias}</h1>
                  <p className='address'>
                    {item.address.content} {item.address.exterior_number} {item.address.interior_number ? `int. ${item.address.interior_number}` : ''}
                    <br />
                    {item.location.suburb_name}
                  </p>
                </li>
              ))}
          </ul>
        )}
        {this.props.formAddNewAddressLogged && <FormAddNewAddress data={this.props.changeAddress} update={(name, value) => this.props.updateFields(name, value)} />}
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    addressID: state.purchase.addressID,
    changeAddressID: state.purchase.changeAddressID,
    addresses: state.user.addresses,
    changeAddress: state.purchase.changeAddress,
    formAddNewAddressLogged: state.purchase.formAddNewAddressLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectNewAddress: (addressID) => dispatch({ type: 'PURCHASE_SELECT_NEW_ADDRESS_LOGGED', addressID }),
    updateFields: (name, value) => dispatch({ type: 'PURCHASE_CHANGE_ADDRESS_GUEST', name, value }),
    addAddress: (open) => dispatch({ type: 'PURCHASE_CHANGE_ADDRESS_FORM_LOGGED', open }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(FormChangeAddressLogged);
