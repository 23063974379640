import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumbs from '../components/Breadcrumbs';
import ContainerBoxPage from './../components/containers/ContainerBoxPage';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { userActivate } from '../store/actions/user';
import { userMenuControl } from './../store/actions/user';

const ActiveAccountPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.user);

  useEffect(() => {
    if (params.token) {
      dispatch(userActivate(params.token))
    }
  }, [dispatch, params.token])

  const openLoginForm = () => dispatch(userMenuControl(true, 'user-login', 'top'))

  return (
    <ContainerBoxPage className='active-account-page'>
      <div>
        <Breadcrumbs />
        <h1>Verificación de la cuenta</h1>
        <div className='confirmation'>
          <i className='icon-ok-2'></i>
          <strong>Tu cuenta se activó correctamente,</strong>
          {!logged && <span onClick={openLoginForm} className='btn btn-primary rounded-small'>INICIAR SESIÓN</span>}
          {logged && (
            <Link to='/perfil/mi-perfil' className='btn btn-primary-outline rounded-small'>
              PERSONALIZAR MI PERFIL
            </Link>
          )}
        </div>
      </div>
    </ContainerBoxPage>
  );
};

export default ActiveAccountPage;
