import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from '../../helpers/index';
import { enableNext, generateToken } from '../../store/actions/purchase';
import AdvancedModal from '../AdvancedModal';
import PaymentMethod from './Guest/PaymentMethod';
import PaymentMethodLogged from './Logged/PaymentMethod';

class FormChangePaymentMethod extends React.Component {
  state = {
    isMobile: false,
    method: false
  }

  componentDidMount(){
    this.setState({ isMobile: isMobile() })
    window.addEventListener('resize', () => {
      if(this.state.isMobile !== isMobile()){
        this.setState({ isMobile: isMobile() })
      }
    });
  }

  changeCard = () => {
    if(!this.props.isLogged || this.props.purchase.cardData.number){
      return this.props.generateToken();
    }
    return this.props.setCardToken();
  }

  render() {
    return <AdvancedModal className='form-change-payment-method' close={this.props.openChangePaymentMethod}>
      <AdvancedModal.Header>
        <h1>Cambiar método de pago</h1>
      </AdvancedModal.Header>
      <AdvancedModal.Body>
        {!this.props.isLogged && <PaymentMethod labelSubmit="CAMBIAR" />}
        {this.props.isLogged && <PaymentMethodLogged submit={this.changeCard} className='change-payment' labelSubmit="CAMBIAR" />}
      </AdvancedModal.Body>
      {this.state.isMobile && <AdvancedModal.Footer>
        <div className="btn-update">
          <span className={`btn btn-primary rounded${this.props.enableSubmit() ? '' : ' disabled'}`} onClick={this.changeCard}>CAMBIAR</span>
        </div>
      </AdvancedModal.Footer>}
    </AdvancedModal>;
  }
}

const mapStateProps = (state) => {
  return {
    isLogged: state.user.logged,
    purchase: state.purchase
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openChangePaymentMethod: () => dispatch({type: 'PURCHASE_CHANGE_PAYMENT_METHOD_OPEN'}),
    enableSubmit: () => dispatch(enableNext()),
    generateToken: () => dispatch(generateToken()),
    setCardToken: () => dispatch({type: 'PURCHASE_CHANGE_CARD_TOKEN'})
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(FormChangePaymentMethod);
