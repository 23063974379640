import React, { useState, useEffect } from 'react'
import AppButton from '../../../components/UI/Buttons'
import UserProduct from '../../../components/UI/UserProduct'
import ProfileContainer from '../ProfileContainer'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { sendReview } from '../../../store/actions/history-purchase';
import { browserName } from "react-device-detect";
import classNames from 'classnames';

const MyPurchasesReview = () => {

    const match = useRouteMatch()
    const history = useHistory()
    const dispatch = useDispatch()

    const { product_review, reference, loader, product_review_response, errorRecreate } = useSelector((state) => state.history)

    // const productRating = product_review ? product_review.review.rating : 0;
    const [commenting, setCommenting] = useState(true);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(product_review?.review?.rating);
    const [response, setResponse] = useState(false);
    const [restart, setRestart] = useState(false)


    const handleText = (event) => {
        setCommenting(true)
        event.preventDefault();
        const review = { product_id: product_review.id, rating, comment };

        if (rating || comment) {
            setRestart(true)
            dispatch(sendReview(review, reference));
        }
    }

    useEffect(() => {
        if (!product_review) {
            history.push(`/perfil/mis-compras`)
        } else {
            if (product_review.review) {
                setComment(product_review.review.comment)
            }
        }
    }, [dispatch, history, product_review]);

    const addComment = (commentary) => {
        setComment(commentary.replace(/[^\wÀ-ÿ,.\n_\-\s!¡]/, ''))
    }

    useEffect(() => {
        if (product_review_response && restart) {
            setCommenting(false);
            setResponse(true)
        }
    }, [product_review_response, restart]);

    useEffect(() => {
        if (errorRecreate) {
            setCommenting(false);
        }
    }, [errorRecreate]);

    const cleanAndGo = () => {
        setRestart(false)
        setCommenting(true);
        setResponse(false)
        history.push(`/perfil/mis-compras/${match.params.id}`)
    }

    return (
        <ProfileContainer>
            <section className="profile-purchases-review">
                <h2 className="mxp-subtitle">Escribe una reseña</h2>
                <div className="purchases-product">
                    {
                        product_review &&
                        <UserProduct
                            product={product_review}
                            cant={product_review.quantity}
                            type={response ? '' : "review"}
                            setRating={setRating}
                            ratingValue={rating}
                        />
                    }
                    <div className="purchases-product-form">
                        {commenting && !errorRecreate && <form onSubmit={handleText} className={classNames({
                            "purchases-product-comments": true,
                            "purchases-product-comments--safari": browserName === 'Safari',
                            "purchases-product-comments--firefox": browserName === 'Firefox'
                        })}>
                            <label className={`purchases-product-comments-label ${comment && comment.length > 1 ? 'purchases-product-comments-label--comment' : ''}`}>Comentario del producto</label>
                            <textarea placeholder="0 / 500 caracteres" cols="30" rows="10" className="purchases-product-textarea" value={comment} onChange={(event) => addComment(event.target.value)}></textarea>
                            <nav className="purchases-product-comments-nav">
                                <AppButton type="main" txt={rating ? "Guardar" : rating || comment ? "Guardar comentario" : "Guardar"} loader={loader} onClick={() => {
                                }} available={comment || rating ? true : false} />
                                <AppButton type="outlined" txt="Cancelar" onClick={(event) => {
                                    event.preventDefault()
                                    history.push(`/perfil/mis-compras/${match.params.id}`)
                                }} available={true} />
                            </nav>
                        </form>}

                        {!commenting && product_review_response.status_code === 105 && <div className="purchases-product-response purchases-product-response--ok">
                            <figure className="purchases-product-icon">
                                <span className="icon-ok-2"></span>
                            </figure>
                            <h3 className="mxp-sub-subtitle">Tu comentario ha sido cargado con éxito.</h3>
                            <p className="mxp-text">¡Gracias por tu tiempo y valoración del producto!</p>
                            <AppButton type="main" txt="Regresar" onClick={cleanAndGo} available={true} />
                        </div>}
                        {!commenting && [113, 106].includes(product_review_response.status_code) && <div className="purchases-product-response purchases-product-response--error">
                            <figure className="purchases-product-icon">
                                <span className="icon-alert"></span>
                            </figure>

                            <h3 className="mxp-sub-subtitle">Lo sentimos.</h3>
                            <p className="mxp-text">{product_review_response.message}</p>
                            <AppButton type="main" txt="Reintentar" onClick={() => {
                                setRestart(false)
                                setCommenting(true);
                                setResponse(false)
                            }} available={true} />
                        </div>}

                    </div>
                </div>
            </section>
        </ProfileContainer>
    )
}

export default MyPurchasesReview
