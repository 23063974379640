import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../components/FormElements/FormInput';
import { removeErrorPassword, updatePassword } from '../../../store/actions/user';
import { AppButton } from './../../../components/UI/Buttons';
import { validatePassword } from './../../../helpers/validations';
import ProfileContainer from './../ProfileContainer';

const ChangePasswordPage = () => {
  const dispatch = useDispatch();

  const { update_password_fail, update_password_success, loader } = useSelector((state) => state.user);

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    reset
    // clearErrors,
    // reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onFocus',
  });

  const { old_password, password, password_confirm } = getValues();

  const handleSubmitForm = (data) => {
    dispatch(updatePassword(data.old_password, data.password));
    reset(data)
  };

  useEffect(() => {
    if (old_password && password) {
      trigger('password')
    }

    if (!!password && !!password_confirm) {
      trigger('password_confirm')
    }

    dispatch(removeErrorPassword())
  }, [dispatch, trigger, old_password, password, password_confirm]);

  const enableSubmit = () => {
    return !Object.keys(errors).length && !!Object.keys(dirtyFields).length && old_password && password_confirm === password;
  };

  return (
    <ProfileContainer>
      <form className='form-change-password' onSubmit={handleSubmit(handleSubmitForm)}>
        <h1 className='title-section'>Cambiar contraseña</h1>
        <FormInput type='password' register={register} name='old_password' error={errors.old_password} label='Contraseña actual*' placeholder="Ingresa de 8 a 30 caracteres" setValue={setValue} options={validatePassword({not_equal: password, not_equal_message: '*La contraseña ingresada es igual a la que tienes actualmente.'})} />

        <FormInput type='password' register={register} name='password' error={errors.password} label='Nueva contraseña*' placeholder="Ingresa de 8 a 30 caracteres" setValue={setValue} options={validatePassword({not_equal: old_password, not_equal_message: '*La contraseña ingresada es igual a la que tienes actualmente.'})} />

        <FormInput type='password' register={register} name='password_confirm' error={errors.password_confirm} label='Confirmar contraseña*' placeholder="Ingresa de 8 a 30 caracteres" setValue={setValue} options={validatePassword({equal: password, equal_message: '* La contraseña no coincide.'})} />

        {update_password_success && !update_password_fail && (
          <div className='confirmation'>
            <i className='icon-ok-2'></i>
            <span>{update_password_success.message}</span>
          </div>
        )}
        {update_password_fail && (
          <div className='confirmation-fail'>
            <i className='icon-close'></i>
            {update_password_fail.message}
          </div>
        )}
        {!update_password_fail && !update_password_success && (
          <p>Recuerda que la contraseña debe contener mínimo 8 caracteres, incluir mayúsculas, minúsculas, números y caracteres especiales ($#&,.-).</p>
        )}
        <AppButton txt='CAMBIAR CONTRASEÑA' loader={loader} type='main' available={enableSubmit()} small />
      </form>
    </ProfileContainer>
  );
};

export default React.memo(ChangePasswordPage);
