import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDeliveryType, selectOffice } from '../../store/actions/purchase';
import InputRadio from '../FormElements/InputRadio';
import SelectAdvanced from '../FormElements/SelectAdvanced';

const ChangeDeliveryType = () => {
  const deliveryType = useSelector((state) => state.purchase.deliveryType);
  const officeID = useSelector((state) => state.purchase.officeID);
  const offices = useSelector((state) => state.home.offices);
  const dispatch = useDispatch();

  const changeType = (type) => dispatch(changeDeliveryType(type));

  const setOffice = (office) => dispatch(selectOffice(office.id));

  const getOffice = () => offices.find((office) => office.id === officeID);

  const optionsDelivery = [
    {
      label: 'Envío a domicilio',
      value: 'home',
      icon: 'icon-home',
    },
    {
      label: 'Recoger en tienda',
      value: 'office',
      icon: 'icon-store',
    },
  ];

  return (
    <div id='change-delivery-type'>
      <div className='inputs-switch'>
        <InputRadio options={optionsDelivery} onChange={(value) => changeType(value)} value={deliveryType} color='blue' />
      </div>
      {deliveryType === 'office' && (
        <div className='select-advanced-field'>
          <SelectAdvanced options={offices} label='name' icon='icon-pin' placeholder='Sucursal' onChange={(value) => setOffice(value)} value={getOffice()} suffix='address' />
        </div>
      )}
    </div>
  );
};

export default ChangeDeliveryType;
