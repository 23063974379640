import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ContainerBoxPage from './../../components/containers/ContainerBoxPage';

const PaymentErrorPage = () => {
  const { paymentMethod, paymentTC } = useSelector((state) => state.purchase);
  window.localStorage.removeItem('tmp_purchase');
  return (
    <ContainerBoxPage className='purchase-payment-error'>
      <div id='payment-error-page'>
        <div>
          <i className='icon icon-alert'></i>
          <h1>
            {paymentMethod === 'paypal' && 'Ocurrió un error al procesar tu pago a través de PayPal.'}
            {paymentMethod !== 'paypal' && 'Ocurrió un error al procesar tu pago.'}

          </h1>
          {paymentTC.error && <>
            <p className='message'>{paymentTC.message}</p>
            {paymentTC.detail && <strong>{paymentTC.detail}</strong>}
            {paymentTC.warning && <p>{paymentTC.warning}</p>}
          </>}
          {!paymentTC.message && <button className='btn btn-primary rounded-small' onClick={() => {}}>
            VOLVER A INTENTAR
          </button>}
          <Link className='btn btn-primary-outline rounded-small' to='/carrito/metodo-de-pago'>
            CAMBIAR MÉTODO DE PAGO
          </Link>
        </div>
      </div>
    </ContainerBoxPage>
  );
};

export default PaymentErrorPage;
