import DeliveryMethodPage from '../pages/Cart/DeliveryMethodPage';
import GeneralDataPage from '../pages/Cart/GeneralDataPage';
import MakePaymentPage from '../pages/Cart/MakePaymentPage';
import PaymentErrorPage from '../pages/Cart/PaymentErrorPage';
import PaymentMethodPage from '../pages/Cart/PaymentMethodPage';
import CompletePage from './../pages/Cart/CompletePage';
import ConfirmationPage from './../pages/Cart/ConfirmationPage';

const routes = [
  {
    path: '/carrito/datos-generales',
    component: GeneralDataPage,
  },
  {
    path: '/carrito/metodo-de-entrega',
    component: DeliveryMethodPage,
  },
  {
    path: '/carrito/metodo-de-pago',
    component: PaymentMethodPage,
  },
  {
    path: '/carrito/realizar-pago',
    component: MakePaymentPage,
  },
  {
    path: '/carrito/confirmacion',
    component: ConfirmationPage,
  },
  {
    path: '/carrito/completado',
    component: CompletePage
  },
  {
    path: '/carrito/cancelado',
    component: PaymentErrorPage
  },
  {
    path: '/carrito/error',
    component: PaymentErrorPage,
  },
];

export default routes;
