import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ProductThumb from '../../BasketBox/ProductThumb'
import Loader from '../../Loader';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { headerAnimations } from '../../../helpers/choreography/header';
import IconBox from '../../BasketBox/IconBox';
import { searchGetResults } from '../../../store/actions/search';
import { useParams } from 'react-router-dom';
import { updateMostWanted } from '../../../store/actions/categories';
import { useState } from 'react';

const SearchProducts = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { view } = useSelector(state => state.categories)
    const { products, loader, error, product_quantity } = useSelector(state => state.search)

    const handleLoadMoreProducts = () => dispatch(searchGetResults({ keyword: params.search, append: true }));

    const [isTrue, setIsTrue] = useState(false)

    useEffect(() => {
        if (isTrue) return
        if (products.length) {
            dispatch(updateMostWanted(params.search))
        }
        return () => {
            setIsTrue(true)
        }
    }, [dispatch, isTrue, params.search, products.length])

    return (
        <div className={classNames({
            'category-products': true,
            'category-products--loading': loader
        })}>
            {loader && !products?.length && <Loader spinner={{ title: 'Cargando' }} blue />}
            {loader && !!products?.length && <Loader spinner={{ title: 'Cargando' }} className="fixed-center-page" initial blue />}

            {view && (!loader || !!products?.length) && !error && (
                <div className="cats-grids-flx cats-grids--grid">
                    {products && (
                        products.map((prod, index) => {
                            return (
                                <ProductThumb list="buscador" key={index} product={prod} type={view ? 'prod' : 'list'} />
                            )
                        })
                    )}
                    {products?.length < product_quantity && (
                        <div className="cats-filter-option-head">
                            <p className="mxp-text-link icon-plus-circle" onClick={handleLoadMoreProducts}>Ver más</p>
                        </div>
                    )}
                </div>
            )}
            {!view && !loader && !error && (
                <div className="cats-grids-flx cats-grids--list">
                    {products && (
                        products.map((prod, index) => {
                            return (
                                <ProductThumb key={index} product={prod} type="list" />
                            )
                        })
                    )}
                    {products?.length < product_quantity && (
                        <div className="cats-filter-option-head">
                            <p className="mxp-text-link icon-plus-circle" onClick={handleLoadMoreProducts}>Ver más</p>
                        </div>
                    )}
                </div>
            )}
            {!loader && error && (
                <motion.div
                    variants={headerAnimations.showCatsList}
                    transition={{
                        ease: 'easeInOut',
                        duration: .2
                    }}
                    initial="hidden"
                    animate={"visible"}
                    exit="hidden"
                    className="search-results-error-wrap"
                >
                    <div className="search-results-error">
                        <h3 className="mxp-title">{error.message}</h3>
                        <IconBox type='informer' id='container-informer-box' name='empty' percent={0} />
                    </div>
                </motion.div>
            )}
        </div>
    )
}

export default SearchProducts
