import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsMobile from '../../../hooks/useIsMobile';
import { changeView, setShowFilters } from '../../../store/actions/categories';
import { applyFilters } from '../../../store/actions/filters';
import Breadcrumbs from '../../Breadcrumbs';
import FakeSelect from '../../FormElements/FakeSelect';
import AppButton from '../../UI/Buttons';

const CategoryHeader = () => {
  const body = document.querySelector('body');
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const {category: {sorts}, active} = useSelector((state) => state.filters);
  const { best_sellers, best_sellers_loader, view, last_visited, last_visited_loader } = useSelector((state) => state.categories);
  const { category, loader } = useSelector((state) => state.product);

  const categoryTitle = () => {
    if (best_sellers.length > 1) {
      return best_sellers_loader ? 'Cargando...' : best_sellers[0]?.name;
    }
    if (last_visited.length > 1) {
      return last_visited_loader ? 'Cargando...' : last_visited[0]?.name;
    }
    if (category) {
      return loader ? 'Cargando...' : category.name;
    }
  };

  const title = categoryTitle();

  return (
    <div className='category-header'>
      {!isMobile && <Breadcrumbs className='cats-breadcrumbs' />}
      <div className='category-header-flx'>
        <h2 className='mxp-title'>{title}</h2>
        <nav className='category-header-navigation'>
          {isMobile && (
            <>
              <p className='mxp-text mxp-text-clear'>Filtros:</p>
              <AppButton
                type='icon'
                icon='filters-light'
                modifier='filter'
                customClick={() => {
                  dispatch(setShowFilters(true));
                  body.classList.add('no-scroll');
                }}
              />
            </>
          )}
          {!isMobile && (
            <>
              <div className='category-header-filter'>
                <p className='mxp-text mxp-text-clear'>Ordenar por:</p>
                <FakeSelect
                  className='category-header-select'
                  placeholder="Más relevantes"
                  options={sorts}
                  value={active.sort || ''}
                  all
                  onChange={(option) => {
                    dispatch(applyFilters('sort', option));
                  }}
                />
              </div>
              <div className='category-header-views-splitter'></div>
              <div className='category-header-views'>
                <AppButton
                  type='icon'
                  icon='ico-cuadricula'
                  modifier={`grid ${view ? 'active' : ''}`}
                  customClick={() => {
                    dispatch(changeView(true));
                  }}
                />
                <AppButton
                  type='icon'
                  icon='ico-lista'
                  modifier={`grid ${!view ? 'active' : ''}`}
                  customClick={() => {
                    dispatch(changeView(false));
                  }}
                />
              </div>
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

export default CategoryHeader;
