import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MiniCart from './miniCart';
import SearchText from './SearchText';
import RegisterForm from './UserMenu/RegisterForm';
import UserOptions from './UserMenu/UserOptions';

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.user);

  const preventDefault = (event) => {
    if (location.pathname === '/') {
      event.preventDefault();
    }
  };

  const userOpenForm = () => {
    dispatch({ type: 'USER_OPEN', open: 'user-menu' });
  };

  return (
    <>
      <header className={`header-page ${location.pathname === '/' ? 'home' : ''}`}>
        <div>
          <Link to='/' onClick={preventDefault} className='logo'>
            <img src='/img/logo.svg' alt='Más x Pieza' />
          </Link>
          <SearchText />
          <div className='home-options'>
            <nav>
              {isLogged && (
                <>
                  <Link to='/usuario/historial-de-compras' className='history'>
                    HISTORIAL
                  </Link>
                </>
              )}
              <span className='login' onClick={userOpenForm}>
                <i className='icon-login-1'></i>
              </span>
            </nav>
          </div>
          {location.pathname !== '/carrito' && <MiniCart />}
        </div>
      </header>
      <UserOptions />
      {!isLogged && <RegisterForm />}
    </>
  );
};

export default React.memo(Header);
