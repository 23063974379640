
import axiosApi from '../axiosAPI';
import { types } from '../reducers/products';
import { getProductCompare } from './compare';
import { filterParams, setFilters, resetFilters, filtersRemove } from './filters';
import { setRange } from './categories';

let inProcess = false;

export const getProducts = (categoryId, { offset=0, limit=16, append=false, filter=false, reloadFilters=true, removeFilters = false }) => async (dispatch, getStore) => {
  if(inProcess) return;

  const isCategory = window.location.pathname.startsWith('/box/categoria/');

  if(reloadFilters) {
    dispatch({type: types.PRODUCT_RELOAD_CONTENT, reload: true})
  }

  const data = {
    offset,
    limit,
    append,
    filter,
  }

  if(append){
    const products = await getStore().product.category.products;
    data.offset = products.length;
  }

  inProcess = true;
  setTimeout(() => {
    inProcess = false;
  }, 1000);
  const store = getStore();
  let currentFilters = store.filters.active;
  if (store.product.error && store.product.category.id !== parseInt(categoryId)) {
    currentFilters = {};
    dispatch(resetFilters())
  }

  if(removeFilters){
    currentFilters = {};
    dispatch(filtersRemove())
  }
  dispatch({ type: types.LOADER });

  const catalog_id = parseInt(store.categories.sector) || store.user.profile?.catalog?.id || 3;

  const addCatalogID = isCategory ? '' : `&catalog_id=${catalog_id}`;

  let urlGet = `v1/categories/${categoryId}/products?offset=${data.offset}${addCatalogID}&limit=${data.limit}${filterParams({ ...currentFilters }).replace('?', '&')}`;

  const response = await axiosApi.get(urlGet);
  if (data.append) {
    return dispatch({
      type: types.LOAD_PRODUCT_CATEGORY,
      category: response.data.status_code !== 100 ? response.data.categories[0] : { products: [] },
      error: response.data.status_code === 100 ? response.data.status_code : false,
      hideBtnLoader: response.data.status_code === 100 || response.data.categories[0].products.length < data.limit,
    });

  } else if (response.data.status_code === 100) {
    return dispatch({
      type: types.ERROR,
      error: response.data,
    });
  } else {
    let filters = response.data.filter;
    filters.sorts = response.data.sort_by;
    if(reloadFilters){
      dispatch(setFilters(filters, 'category'));
    }
    dispatch({type: types.PRODUCT_RELOAD_CONTENT, reload: false})
    dispatch(setRange(response.data.filter.range_prices || {min: 0, max: 9999}))
    return dispatch({
      type: types.GET_PRODUCTS,
      category: response.data.categories.length ? response.data.categories[0] : false,
      hideBtnLoader: response.data.categories[0].products.length < data.limit,
    });
  }
};

const productDetail = async(id, dispatch) => {
  const product = await axiosApi.get(`v1/products/${id}`).catch((error) => error.response);

  if (product.status === 204) {
    window.location.href = '/box'
    return false;
  }

  dispatch({
    type: types.GET_PRODUCT,
    product: product.data[0],
    reviews: [],
    related: [],
  });
}

const productRelated = async(id, dispatch) => {
  const related = await axiosApi.get(`v1/products/${id}/recommendation`).catch((error) => error.response);

  dispatch({
    type: types.PRODUCT_RELATED,
    related: related.data.status_code === 102 ? [] : related.data,
  });

}

export const getProduct = (productID) => (dispatch, getStore, params) => {
  dispatch({ type: types.PRODUCT_DETAIL_LOADERS });

  productDetail(productID, dispatch);
  productRelated(productID, dispatch);

  dispatch(getProductCompare(productID));
  dispatch(getReviews(productID));

  return true;
};

export const productGet = (id) => async (dispatch) => {
  if (!id) return;
  dispatch({ type: types.LOADER });
  const response = await axiosApi.get(`/v1/products/${id}`).catch((error) => error.response);
  if (response.status === 204) {
    window.location.href = '/box'
    return false;
  }
  dispatch({ type: types.PRODUCT_GET, product: response.data[0] })
}

export const productGetRelated = (id) => async (dispatch) => {
  if (!id) return;
  dispatch({ type: types.LOADER });
  const response = await axiosApi.get(`v1/products/${id}/recommendation`).catch((error) => error.response);

  dispatch({ type: types.PRODUCT_RELATED, related: response.data.status_code === 102 ? [] : response.data })

}

export const getReviews = (productID = false) => async (dispatch, getStore) => {
  productID = productID || getStore().product.product.id;
  const reviews = await axiosApi.get(`v1/products/${productID}/reviews?offset=${getStore().product.reviews.length}&limit=15`);
  return dispatch({
    type: types.PRODUCT_GET_REVIEWS,
    reviews: !!reviews.data.length ? reviews.data : [],
    disableBtnReviews: !reviews.data.length || reviews.data.length > 15,
  });
};

export const unloadProduct = () => (dispatch) => {
  dispatch({ type: 'REMOVE_LOADER' });
  dispatch({ type: types.UNLOAD });
};

export const unloadCategory = () => (dispatch) => {
  dispatch({ type: types.UNLOAD_CATEGORY });
  dispatch({ type: 'REMOVE_LOADER' });
};

export const productsError = (error = false) => (dispatch) => dispatch({ type: types.ERROR, error })

export const productAnalyticsList = (list = false) => (dispatch) => dispatch({type: types.PRODUCT_SET_ANALYTICS_LIST, list})
