import React from 'react'

class NotFound extends React.Component{
  render() {
    return (<div>
      Pagina no encontrada
    </div>)
  }
}

export default NotFound
