import React from 'react';

const InputRadio = ({ options, value, onChange, color }) => {
  const handleChange = (value) => {
    if(onChange){
      onChange(value);
    }
  }

  return (
    <>
      {options.map((option, optionIndex) => (
        <div key={optionIndex} onClick={() => handleChange(option.value)} className={`input-radio radio ${color}${value === option.value ? ' active' : ''}`}>
          <div className='icon'>
            <i></i>
          </div>
          {option.icon && <i className={`${option.icon} custom-icon`}></i>}
          <div className='label'>{option.label}</div>
        </div>
      ))}
    </>
  );
};

export default InputRadio;
