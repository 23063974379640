export const types = {
  ADD_ITEM_CART: 'ADD_CART',
  REMOVE_ITEM_CART: 'REMOVE_CART',
  UPDATE_ITEM_CART: 'UPDATE_CART',
  GET_CART: 'GET_CART',
  UNLOAD_CART: 'UNLOAD_CART',
  DELETE_ITEM_CART: 'DELETE_ITEM_CART',
  INITIAL_DATA: 'INITIAL_DATA',
  RESET_CART: 'RESET_CART',
  RELATED_PRODUCT: 'RELATED_PRODUCT',
  CART_LOADER: 'CART_LOADER',
  CART_GO_TO_CHECKOUT_ALERT: 'CART_GO_TO_CHECKOUT_ALERT',
  CART_OPEN_MINICART: 'CART_OPEN_MINICART',
  CART_WARNINGS: 'CART_WARNINGS',
  CART_ADD_RECOMMENDED: 'CART_ADD_RECOMMENDED',
  CART_LAST_ADDED: 'CART_LAST_ADDED',
  CART_HIDE_LAST: 'CART_HIDE_LAST',
  RESET: 'RESET',
  CART_ERROR_ADD_QUANTITY: 'CART_ERROR_ADD_QUANTITY',
  CART_GET_CONTAINERS: 'CART_GET_CONTAINERS',
  CART_LOADER_ESTIMATED_COST_BOX: 'CART_LOADER_ESTIMATED_COST_BOX',
  CART_GET_ESTIMATED_BOX: 'CART_GET_ESTIMATED_BOX',
  CART_UPDATE_CART_ANALYTICS: 'CART_UPDATE_CART_ANALYTICS',
};

const initialStore = {
  count: 0,
  goToCartAlert: false,
  loader: false,
  openMiniCart: false,
  orderID: false,
  products: [],
  purchase: {},
  recommendedAdded: false,
  related: [],
  total: 0,
  sub_total: 0,
  iva: 0,
  warnings: [],
  last: false,
  hide_last: true,
  errorStock: false,
  containers: false,
  containers_list: false,
  estimatedCostBox: false,
  loaderEstimatedCostBox: false,
  sendAnalytics: false,
};

const saveLocal = (data, orderID) => {
  window.localStorage.setItem('orderID', orderID);
};

const reducer = (store = initialStore, action) => {
  const body = document.querySelector('body');
  switch (action.type) {
    case types.CART_UPDATE_CART_ANALYTICS:
      return {...store, sendAnalytics: action.payload};
    case types.ADD_ITEM_CART:
      let addItem = [...store.itemsCart];
      const findItem = addItem.find((item) => item.id === action.id);
      if (findItem) return { ...store };

      addItem = [...addItem, { id: action.id, quantity: 1 }];
      saveLocal(addItem, action.orderID);
      return { ...store, itemsCart: addItem, orderID: action.orderID, loader: false };
    case types.UPDATE_ITEM_CART:
      const updateItemsCart = store.itemsCart.map((item) => {
        if (item.id === action.id) {
          item.quantity = action.quantity;
        }
        return item;
      });
      saveLocal(updateItemsCart, store.orderID);
      return { ...store, itemsCart: updateItemsCart, loader: false };
    case types.GET_CART:
      window.sessionStorage.setItem('cart', JSON.stringify(action.cart));
      saveLocal(false, action.orderID);
      return { ...store, ...action.cart, orderID: action.orderID, sendAnalytics: false, loader: false };
    case types.UNLOAD_CART:
      return { ...store, cart: false, loader: false };
    case types.RESET_CART:
    case types.RESET:
      window.localStorage.removeItem('orderID');
      window.sessionStorage.removeItem('cart');
      body.classList.remove('no-scroll');
      return { ...initialStore, containers_list: store.containers_list };
    case types.RELATED_PRODUCT:
      return { ...store, related: action.related, loader: false };
    case types.CART_GO_TO_CHECKOUT_ALERT:
      const goToCartAlert = !store.goToCartAlert;
      return { ...store, goToCartAlert, loader: false };
    case types.CART_OPEN_MINICART:
      const openMiniCart = !store.openMiniCart;
      if (openMiniCart) {
        body.classList.add('no-scroll');
      } else {
        body.classList.remove('no-scroll');
      }
      return { ...store, openMiniCart, loader: false, goToCartAlert: false };
    case types.CART_LOADER:
      return { ...store, loader: action.loader || false };
    case types.CART_WARNINGS:
      return { ...store, warnings: action.warnings, isRecreate: action.isRecreate || false };
    case types.CART_ADD_RECOMMENDED:
      return { ...store, recommendedAdded: action.product };
    case types.CART_LAST_ADDED:
      return { ...store, last: action.product, hide_last: false };
    case types.CART_HIDE_LAST:
      return {...store, hide_last: true};
    case types.CART_ERROR_ADD_QUANTITY:
      return { ...store, errorStock: action.error };
    case types.CART_GET_CONTAINERS:
      return {...store, containers_list: action.containers}
    case types.CART_LOADER_ESTIMATED_COST_BOX:
      return { ...store, loaderEstimatedCostBox: action.loader || false}
    case types.CART_GET_ESTIMATED_BOX:
      return { ...store, estimatedCostBox: action.cost, loaderEstimatedCostBox: false}
    default:
      const sessionCart = JSON.parse(window.sessionStorage.getItem('cart')) || {}
      const orderID = window.localStorage.getItem('orderID') || false;
      return { ...store, ...sessionCart, orderID };
  }
};

export default reducer;
