import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ChangeDeliveryType from '../../components/Cart/ChangeDeliveryType';
import Container from '../../components/Cart/Container';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import Loader from '../../components/Loader';
import { getNewShippingCost, purchaseResetShipping } from '../../store/actions/purchase';
import DeliveryOnOffice from './../../components/Cart/DeliveryOnOffice';
import ShippingPackagesNotFound from './../../components/Cart/ShippingPackagesNotFound';
import ShippingFlow from './../../components/Purchase/shipping/ShippingFlow';
import { checkoutAnalytics } from './../../store/actions/analytics-events';

const DeliveryMethodPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { products, containers } = useSelector((state) => state.cart);
  const { addressID } = useSelector((state) => state.purchase);

  const loaded = useRef();

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      const option = addressID ? 'Dirección guardada' : 'Nueva Dirección';
      checkoutAnalytics({ step: 2, products, containers, option });
    }
  }, [products, containers, addressID]);

  const {
    deliveryType,
    packagesRates,
    loaderRates,
    packageError,
    personalInfo: { zip },
  } = useSelector((state) => state.purchase);

  useEffect(() => {
    if (!zip) {
      history.replace('/carrito/datos-generales');
      return;
    }
    if (deliveryType === 'home') {
      dispatch(getNewShippingCost());
    }
  }, [dispatch, history, zip, deliveryType]);

  useEffect(() => {
    dispatch(purchaseResetShipping());
  }, [dispatch]);

  return (
    <ContainerBoxPage className='purchase-process shipping-page'>
      <Container modifier={`delivery-method-payment shipping-container${packageError ? ' error' : ''}`}>
        <ChangeDeliveryType />

        {deliveryType === 'office' && <DeliveryOnOffice />}

        {deliveryType === 'home' && loaderRates && <Loader spinner={{ title: 'Cargando' }} blue />}

        {deliveryType === 'home' && !loaderRates && packagesRates && <ShippingFlow servicesData={packagesRates} />}

        {deliveryType === 'home' && !loaderRates && packageError && <ShippingPackagesNotFound />}
      </Container>
    </ContainerBoxPage>
  );
};

export default DeliveryMethodPage;
