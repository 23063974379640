import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { purchaseConfirmAnalytics } from '../../../store/actions/analytics-events';

let init = false;

const CardPayment = () => {
  const history = useHistory();
  const { paymentTC, paymentPaypal, paymentLoyalty } = useSelector((state) => state.purchase);
  const { logged } = useSelector((state) => state.user);
  const { offices } = useSelector((state) => state.home);

  useEffect(() => {
    if (!paymentTC.reference && !paymentPaypal.reference && !paymentLoyalty.reference) {
      history.replace('/box');
    }

    if(paymentPaypal.reference){
      const tmp_analytics = JSON.parse(window.localStorage.getItem('tmp_purchase'));
      if(tmp_analytics && !init){
          init = true;
          purchaseConfirmAnalytics({ purchase: {...tmp_analytics, reference: paymentPaypal.reference}, offices });
      }
    }
    window.localStorage.removeItem('tmp_purchase');
    window.localStorage.removeItem('orderID');
  }, [history, paymentLoyalty, paymentPaypal, paymentTC, offices]);

  return (
    <div id='card-payment-confirmation'>
      <img className='img-box' src='/img/cart/grafico-contenedor.png' alt='confirmación de compra' />
      <h1>¡Gracias! Tu compra se realizó con éxito.</h1>
      <div className='purchase-code'>
        Este es el código de tu pedido:
        <strong>{paymentTC.reference || paymentPaypal.reference || paymentLoyalty.reference}</strong>
      </div>
      <Link className='btn btn-primary rounded-small' to='/box'>
        IR AL CATÁLOGO
      </Link>
      {logged && <Link className='btn btn-primary btn-primary-outline rounded-small' to="/perfil/mis-compras">RASTREAR MI PEDIDO</Link>}
    </div>
  );
};

export default CardPayment;
