import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { map } from '../../helpers/choreography/get-fill';
import { productAnimations } from '../../helpers/choreography/products';

const IconBox = ({ type, id, name, percent, ...rest }) => {
  if (type === 'informer') {

    const fillPercent = map(percent, 0, 100, 70, 0);

    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 103" {...rest} className={name}>
        <defs>
          <clipPath id="clip-path">
            <polygon
              id="box-fill-wall-mask"
              className="box-fill-walk-mask"
              points="106.74 90 60 102 60 26.39 106.74 26.39 106.74 90"
            />
          </clipPath>
        </defs>
        <g id="box">
          <path id="box-color" className="box-white" d="M12.56,26.42H60v75.16L17.92,91.64a7,7,0,0,1-5.36-6.77ZM61.39,25h44a1.39,1.39,0,0,1,1.39,1.39V88.12a1.38,1.38,0,0,1-1,1.34l-44,11.66a1.39,1.39,0,0,1-1.7-1,1.49,1.49,0,0,1,0-.36V26.39A1.39,1.39,0,0,1,61.39,25Z" />

          <path id="box-fill-wall" className="box-fill-wall" d="M61.39,25h44a1.39,1.39,0,0,1,1.39,1.39V88.12a1.38,1.38,0,0,1-1,1.34l-44,11.66a1.39,1.39,0,0,1-1.7-1,1.49,1.49,0,0,1,0-.36V26.39A1.39,1.39,0,0,1,61.39,25Z" />

          <AnimatePresence exitBeforeEnter>
            {
              percent && (
                <g className="box-fill-wall-color-wrap">
                  <motion.rect
                    id="box-fill-wall-color"
                    className="box-fill-wall-color"
                    x="60"
                    y="26.39"
                    width="46.74"
                    height="76.61"
                    animate={{ y: fillPercent }}
                    transition={{
                      ease: 'easeInOut',
                      delay: .2,
                      duration: 1
                    }}
                  />
                </g>
              )
            }
          </AnimatePresence>

          <path
            id="box-topside-r"
            className="box-white" d="M10.45,26.42H60L51.63,56.57,2.4,53.26Z" />
          <path
            id="box-topside"
            className="box-white" d="M110.55,26.42H61l8.38,30.15,49.23-3.31Z" />
          <path
            id="box-line"
            className="box-line" d="M116.9,52a1.26,1.26,0,0,1-1,.54h-.09l-46,2.91a1.21,1.21,0,0,1-1.09-.79L61.85,27.91h46.43a1.45,1.45,0,0,1,1.37,1l7.42,22A1.3,1.3,0,0,1,116.9,52ZM105.75,85.62A3.91,3.91,0,0,1,103,89.35L62.68,99.69a10.74,10.74,0,0,1-1.25.28v-62l4.45,17.53,0,.11a4.09,4.09,0,0,0,3.86,2.79h.09l35.89-2.27Zm-88.6,4.27A4,4,0,0,1,14.25,86V56.26l35.87,2.83h.11a4.1,4.1,0,0,0,3.86-2.8l0-.11,4.45-18V100a10,10,0,0,1-1.32-.28l-40.1-9.82Zm-13-37.34a1.26,1.26,0,0,1-1-.54,1.3,1.3,0,0,1-.17-1.16l7.42-22a1.45,1.45,0,0,1,1.37-1H58.16L51.36,55.4a1.19,1.19,0,0,1-1.08.78l-46-3.63ZM119.78,49.9,112.36,28A4.33,4.33,0,0,0,108.28,25H11.72A4.33,4.33,0,0,0,7.64,28L.22,49.9a4.25,4.25,0,0,0,.55,3.8,4.13,4.13,0,0,0,3.3,1.76l7.31.57V86a7,7,0,0,0,5,6.69l40.1,9.82A12.82,12.82,0,0,0,60,103H60a12.36,12.36,0,0,0,3.42-.5l40.34-10.35a6.81,6.81,0,0,0,4.85-6.53V55.92l7.3-.46a4.12,4.12,0,0,0,3.31-1.76A4.25,4.25,0,0,0,119.78,49.9Z" />

          <AnimatePresence exitBeforeEnter>
            {
              percent === 0 && (
                <g id="empty-sound">
                  <motion.path
                    variants={productAnimations.emptyBox}
                    transition={{
                      ease: 'easeInOut',
                      delay: .2,
                      duration: .3
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="box-empty"
                    d="M81.66,9.34h0a2,2,0,0,1,0,2.83l-8.49,8.49a2,2,0,0,1-2.83,0h0a2,2,0,0,1,0-2.83l8.49-8.49A2,2,0,0,1,81.66,9.34Z" />
                  <motion.path
                    variants={productAnimations.emptyBox}
                    transition={{
                      ease: 'easeInOut',
                      delay: 0,
                      duration: .3
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="box-empty"
                    d="M37.34,9.34h0a2,2,0,0,0,0,2.83l8.49,8.49a2,2,0,0,0,2.83,0h0a2,2,0,0,0,0-2.83L40.17,9.34A2,2,0,0,0,37.34,9.34Z" />
                  <motion.path
                    variants={productAnimations.emptyBox}
                    transition={{
                      ease: 'easeInOut',
                      delay: .4,
                      duration: .3
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="box-empty"
                    d="M60,0h0a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2V2A2,2,0,0,1,60,0Z" />
                </g>
              )
            }
          </AnimatePresence>
        </g>
      </svg>
    )
  } else if (type === 'informer-new') {
    const fillPercent = map(percent, 0, 100, 120, 0)

    return (
      <svg version="1.1" id="full-box" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 180 155.5" xmlSpace="preserve" className={name}>

        <AnimatePresence exitBeforeEnter>
          {
            percent > 39 && (
              <motion.path
                variants={productAnimations.rightProd}
                transition={{
                  ease: 'easeInOut',
                  delay: .2,
                  duration: 1
                }}
                initial="hidden"
                animate="visible"
                exit="hidden" id="product-right" className="st0" d="M31.6,2.4h46.8c0,0,9,22.4,0,52.9H31.6C31.6,55.2,22.5,25.9,31.6,2.4z" />
            )
          }
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {
            percent > 60 && (
              <motion.path
                variants={productAnimations.leftProd}
                transition={{
                  ease: 'easeInOut',
                  delay: .4,
                  duration: 1
                }}
                initial="hidden"
                animate="visible"
                exit="hidden" id="product-left" className="st0" d="M104.2,39.5h47.9c0,0-0.2-18.9-7.7-22.3h-33.9c0,0-2.7,0.1-3.3,3.2
                C106.5,23.4,102.8,35.8,104.2,39.5z"/>
            )
          }
        </AnimatePresence>
        <g id="box">
          <path id="bg" className="st1" d="M18.8,40.8H90v112.7l-63.1-14.9c-4.7-1.1-8-5.3-8-10.2V40.8H18.8z M92.1,38.6H158 c1.2,0,2.1,0.9,2.1,2.1v92.6c0,0.9-0.6,1.8-1.6,2l-65.9,17.5c-1.1,0.3-2.3-0.4-2.6-1.5c0-0.2-0.1-0.4-0.1-0.5V40.7 C90,39.6,90.9,38.6,92.1,38.6z" />
          <path id="right-wall" className="st2" d="M92.1,38.6H158c1.2,0,2.1,0.9,2.1,2.1v92.6c0,0.9-0.6,1.8-1.6,2l-65.9,17.5 c-1.1,0.3-2.3-0.4-2.6-1.5c0-0.2-0.1-0.4-0.1-0.5V40.7C90,39.6,90.9,38.6,92.1,38.6z" />
          <path id="left-wall" className="st2" d="M88,38.6H22.1c-1.2,0-2.1,0.9-2.1,2.1v92.6c0,0.9,0.6,1.8,1.6,2l65.9,17.5 c1.1,0.3,2.3-0.4,2.6-1.5c0-0.2,0.1-0.4,0.1-0.5V40.7C90.1,39.6,89.2,38.6,88,38.6L88,38.6z" />

          <g id="mask-progress">
            <g>
              <g>
                <defs>
                  <AnimatePresence exitBeforeEnter>
                    {
                      percent && (
                        <motion.path
                          initial={{ y: 110 }}
                          animate={{ y: fillPercent }}
                          transition={{
                            ease: 'easeInOut',
                            duration: 3
                          }}
                          id="SVGID_1_" d="M158,38.6H92.1c-1,0-1.8,0.7-2,1.6c-0.2-0.9-1-1.6-2-1.6l0,0h-66c-1.2,0-2.1,0.9-2.1,2.1v92.6
						c0,0.9,0.6,1.8,1.6,2l65.9,17.5c1.1,0.3,2.3-0.4,2.6-1.5v-0.1v0.1c0.3,1.1,1.4,1.8,2.6,1.5l65.9-17.5c0.9-0.2,1.6-1.1,1.6-2
						V40.7C160.1,39.6,159.2,38.6,158,38.6z"/>)
                    }</AnimatePresence>
                </defs>
                <clipPath id="SVGID_00000144336933939019840900000013732735138256708013_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>

                <path id="right-fill" className="right-fill" d="M90.1,56.9l70-14.6v94.6L90,155.5L90.1,56.9z" />
              </g>
            </g>
            <g>
              <g>
                <defs>
                  <AnimatePresence exitBeforeEnter>
                    {
                      percent && (
                        <motion.path
                          initial={{ y: 110 }}
                          animate={{ y: fillPercent }}
                          transition={{
                            ease: 'easeInOut',
                            duration: 3
                          }} id="SVGID_00000111173890634904223080000003782382162746990776_" d="M158,38.6H92.1c-1,0-1.8,0.7-2,1.6
						c-0.2-0.9-1-1.6-2-1.6l0,0h-66c-1.2,0-2.1,0.9-2.1,2.1v92.6c0,0.9,0.6,1.8,1.6,2l65.9,17.5c1.1,0.3,2.3-0.4,2.6-1.5v-0.1v0.1
						c0.3,1.1,1.4,1.8,2.6,1.5l65.9-17.5c0.9-0.2,1.6-1.1,1.6-2V40.7C160.1,39.6,159.2,38.6,158,38.6z"/>
                      )}
                  </AnimatePresence>
                </defs>
                <clipPath id="SVGID_00000001664974615977592010000009622657271323645068_">
                  <use xlinkHref="#SVGID_00000111173890634904223080000003782382162746990776_" style={{ overflow: "visible" }} />
                </clipPath>
                <path id="left-fill" className="left-fill" d=" M90.1,56.9L20,43.3v93.6l70.1,18.6V56.9z" />
              </g>
            </g>
          </g>

          <path id="box-open-sides" className="st1" d="M15.7,40.8H90L77.4,86L3.6,81L15.7,40.8z M165.8,40.8H91.5L104.1,86l73.8-5L165.8,40.8z" />
          <path id="box-shape" className="st5" d="M175.3,79.1c-0.4,0.5-0.9,0.8-1.5,0.8h-0.1l-69.1,4.4c-0.7,0-1.4-0.5-1.6-1.2L92.8,43h69.6 c0.9,0,1.7,0.6,2,1.5l11.1,32.9C175.8,78,175.7,78.6,175.3,79.1L175.3,79.1z M158.6,129.6c0,2.6-1.7,4.9-4.1,5.6L94,150.7 c-0.6,0.2-1.2,0.3-1.9,0.4V58l6.7,26.3c0,0.1,0,0.1,0,0.2c0.8,2.5,3.2,4.2,5.8,4.2h0.1l53.8-3.4L158.6,129.6L158.6,129.6z M25.7,136c-2.6-0.7-4.3-3.1-4.3-5.8V85.5l53.8,4.2c0.1,0,0.1,0,0.2,0c2.6,0,4.9-1.7,5.8-4.2c0-0.1,0-0.1,0.1-0.2l6.7-27V151 c-0.7-0.1-1.3-0.2-2-0.4C85.9,150.7,25.7,136,25.7,136z M6.2,80c-0.6,0-1.2-0.3-1.5-0.8c-0.4-0.5-0.5-1.2-0.2-1.7l11-33 c0.3-0.9,1.1-1.5,2-1.5h69.7L77,84.2c-0.3,0.7-0.9,1.1-1.6,1.2L6.4,80C6.3,80,6.3,80,6.2,80L6.2,80z M179.7,76l-11.2-32.9 c-0.9-2.7-3.4-4.4-6.1-4.4H17.6c-2.8,0-5.2,1.8-6.1,4.4L0.3,76c-0.6,1.9-0.3,4,0.8,5.7c1.1,1.6,3,2.6,5,2.6l11,0.9v45 c0,4.7,3.1,8.8,7.6,10l60.1,14.7c1.7,0.5,3.4,0.7,5.1,0.7H90l0,0l0,0c1.7,0,3.5-0.3,5.1-0.7l60.5-15.5c4.3-1.3,7.3-5.3,7.3-9.8V85 l10.9-0.7c2,0,3.8-1,5-2.6C180,80,180.3,77.9,179.7,76L179.7,76z" />
        </g>
      </svg >
    )

  } else {
    return (<svg width='1em' height='0.6403em' viewBox='0 0 114 73' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest} className={`svg-container ${name}`}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.327 0H99.082C99.815 0 100.408 0.593 100.408 1.326V59.05C100.408 59.653 100.001 60.18 99.418 60.332L57.661 71.24C56.952 71.425 56.228 71 56.043 70.292C56.014 70.183 56 70.071 56 69.957V1.326C56.001 0.593 56.594 0 57.327 0Z'
        className='box-color'
        fillOpacity='0.3'
      />
      <mask id={`svg-${id}`} mask-type='alpha' maskUnits='userSpaceOnUse' x='57' y='12' width='46' height='60'>
        <rect x='57' y='0' width='46' height='73' fill='#fff' className='svg-percent-fill' style={{ transform: `translateY(${100 - percent}%)` }} />
      </mask>
      <g mask={`url(#svg-${id})`}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.327 0H99.082C99.815 0 100.408 0.593 100.408 1.326V59.05C100.408 59.653 100.001 60.18 99.418 60.332L57.661 71.24C56.952 71.425 56.228 71 56.043 70.292C56.014 70.183 56 70.071 56 69.957V1.326C56.001 0.593 56.594 0 57.327 0Z'
          className='box-fill'
        />
      </g>
      <path fillRule='evenodd' clipRule='evenodd' d='M105.022 1.32703H57.953L65.906 29.543L112.674 26.449L105.022 1.32703Z' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.055 25.279C110.825 25.599 110.469 25.782 110.075 25.782L109.991 25.785L66.244 28.505C65.782 28.493 65.374 28.203 65.207 27.772L58.757 2.724H102.869C103.454 2.724 103.974 3.096 104.164 3.649L111.214 24.189C111.34 24.561 111.284 24.959 111.055 25.279ZM100.465 56.73C100.465 58.345 99.382 59.785 97.875 60.22L59.55 69.906C59.159 70.019 58.761 70.103 58.361 70.164V12.105L62.587 28.513C62.595 28.548 62.606 28.583 62.617 28.616C63.155 30.18 64.626 31.23 66.278 31.23C66.307 31.23 66.335 31.23 66.363 31.228L100.465 29.108V56.73ZM16.288 60.731C14.668 60.281 13.535 58.792 13.535 57.108V29.258L47.615 31.908L47.721 31.911C49.374 31.911 50.846 30.861 51.383 29.296C51.395 29.26 51.406 29.224 51.415 29.186L55.639 12.37V70.18C55.219 70.122 54.799 70.036 54.383 69.921L16.288 60.731ZM3.92402 25.782C3.53102 25.782 3.17402 25.599 2.94602 25.279C2.71702 24.959 2.65902 24.561 2.78702 24.189L9.83602 3.649C10.026 3.096 10.546 2.724 11.131 2.724H55.254L48.791 28.456C48.625 28.882 48.221 29.17 47.764 29.186L4.02902 25.786C3.99502 25.784 3.95902 25.782 3.92402 25.782ZM113.788 23.305L106.739 2.765C106.171 1.11 104.616 0 102.87 0H11.13C9.38302 0 7.82702 1.111 7.26002 2.764L0.21102 23.305C-0.19898 24.501 -0.00497973 25.832 0.73002 26.861C1.45302 27.875 2.62202 28.487 3.86502 28.506L10.812 29.046V57.108C10.812 60.013 12.765 62.581 15.606 63.368L53.701 72.558C54.767 72.853 55.863 73 56.952 73L56.998 72.999H57L57.005 72.998C58.108 72.993 59.205 72.84 60.261 72.536L98.585 62.85C101.295 62.068 103.188 59.552 103.188 56.73V28.938L110.122 28.506C111.37 28.491 112.544 27.879 113.27 26.861C114.005 25.831 114.199 24.501 113.788 23.305Z'
        className='box-color'
      />
    </svg>);
  }
};

export default IconBox;

