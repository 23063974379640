import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { purchaseResetShipping, selectOffice } from './../../store/actions/purchase';

const DeliveryOnOffice = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const offices = useSelector((state) => state.home.offices);
  const { officeID } = useSelector((state) => state.purchase);

  const handleChangeOffice = (id) => dispatch(selectOffice(id));

  const handlePaymentMethod = () => history.push('/carrito/metodo-de-pago');

  useEffect(() => {
    dispatch(purchaseResetShipping())
  }, [dispatch])

  return (
    <div id='delivery-on-office'>
      <div className='offices'>
        {offices.map((office, officeIndex) => (
          <div key={officeIndex} className={`office${officeID === office.id ? ' active' : ''}`} onClick={() => handleChangeOffice(office.id)}>
            <strong className='title'>
              <span>{office.name}</span>
              <i className='icon-ok circle'></i>
            </strong>
            {office.street} #{office.number}
            <br />
            {office.suburb}
            <br />
            {office.zip}
          </div>
        ))}
      </div>
      <button className={`btn btn-primary${!officeID ? ' disabled' : ''}`}onClick={handlePaymentMethod}>CONTINUAR</button>
    </div>
  );
};

export default DeliveryOnOffice;
