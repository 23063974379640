import React from 'react';
import { connect } from 'react-redux';
import { defaultImg, imageUrl } from '../helpers';
import { analyticsAddFavorite, analyticsSearchProductDetails } from '../store/actions/analytics-events';
import { addCart, itemCart } from '../store/actions/cart';
import { updateFavorites } from '../store/actions/favorites';
import BtnAddToCart from './BtnAddToCart';
import MoneyFormat from './MoneyFormat';
import RatingProduct from './RatingProduct';

class ProductThumb extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      itemCart: false,
    };
    this.productContainer = React.createRef(false);
  }

  updateFavorite = () => {
    const id = this.props.id || this.props.product_id;
    analyticsAddFavorite(this.props);
    this.props.updateFavorite(id);
  };

  isFavorite = () => {
    const id = this.props.id || this.props.product_id;
    return this.props.favorites.includes(id);
  };

  itemCart = () => {
    const id = this.props.id || this.props.product_id;
    return this.props.itemsCart.find((item) => item.product_id === id);
  };

  sendAnalytics = () => {
    if (this.props.analyticsFromSearch) {
      analyticsSearchProductDetails(this.props);
    }
  };

  render() {
    return (
      <article className='product-thumb animate__animated animate__fadeIn' ref={this.productContainer}>
        <span className={`favorite add ${this.isFavorite() ? 'icon-heart' : 'icon-heart-empty'}`} onClick={this.updateFavorite}></span>
        <a
          onDragStart={(event) => event.preventDefault()}
          href={`/producto/${this.props.id || this.props.product_id}/${this.props.code}`}
          className='details'
          onClick={(event) => this.sendAnalytics(event)}>
          <img src={imageUrl(this.props.media[0] ? this.props.media[0].sizes.small : false)} alt={this.props.name} onError={(event) => defaultImg(event)} />
          <h1>{this.props.name}</h1>
          <div className={this.props.showRating ? 'show-rating' : ''}>
            <span className='price'>
              <MoneyFormat money={this.props.price} />
            </span>
            {this.props.showRating && <RatingProduct rating={this.props.rating} />}
          </div>
        </a>
        <BtnAddToCart product={this.props} itemCart={this.itemCart()} productId={this.props.id || this.props.product_id} limit={this.props.quantity_available} parentElement={this.productContainer} />
      </article>
    );
  }
}

const mapStateProps = (state) => {
  return {
    favorites: state.favorites.favorites,
    itemsCart: state.cart.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFavorite: (id) => dispatch(updateFavorites(id)),
    addCart: (id) => dispatch(addCart(id)),
    itemCart: (id) => dispatch(itemCart(id)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(ProductThumb);
