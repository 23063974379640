import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from '../../helpers/validations';
import { userLogin } from '../../store/actions/user';
import FormInput from '../FormElements/FormInput';
import AppButton from '../UI/Buttons';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { loader, error } = useSelector((state) => state.user);
  const [showValue, setShowValue] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const submit = (data) => {
    dispatch(userLogin({
      email: data.email.trim(),
      password: data.password.trim(),
    }))
  };

  const enableSubmit = () => isDirty && !Object.keys(errors).length;

  return (
    <form className="login-form" onSubmit={handleSubmit(submit)}>
      <FormInput
        label='Correo electrónico*'
        placeholder='jorgeEHP@correo.com'
        name='email'
        register={register}
        options={validateEmail}
        error={errors.email}
        type='email'
        defaultValue={''}
        setValue={setValue}
        validate="email"
      />
      <FormInput
        label='Contraseña'
        placeholder='Ingresa de 8 a 30 caracteres'
        name='password'
        register={register}
        error={error}
        options={{ shouldValidate: true, required: true }}
        type='password'
        defaultValue={''}
        setValue={setValue}
        setShowValue={setShowValue}
        showValue={showValue}
        className="pass"
      />
      <AppButton type="main" txt="INICIAR SESIÓN" available={enableSubmit()} loader={loader} />
    </form>
  )
}

export default LoginForm
