import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MostWanted from '../../components/BasketBox/MostWanted';
import Carousel from '../../components/Carousel';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import Loader from '../../components/Loader';
import { getCategories, getCustomCategories, getLastVisited } from '../../store/actions/categories';
import { getHomeData, setCurrentVideo } from '../../store/actions/home';
import FeaturedProducts from './../../components/BasketBox/FeaturedProducts';
import Image from './../../components/Image';
import useIsMobile from './../../hooks/useIsMobile';

const HomePage = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [mainCategories, setMainCategories] = useState(false)
  const [featuredCategory, setFeaturedCategory] = useState(false)

  const { categories_w_products, custom_categories, categories, custom_categories_loader, last_visited, loader } = useSelector((state) => state.categories);

  const { banner, tutorials } = useSelector((state) => state.home);


  useEffect(() => {
    const filterCategories = categories_w_products.filter(item => item.code !== 'destacados');
    const findFeaturedCategory = categories_w_products.find(item => item.code === 'destacados')

    setMainCategories(filterCategories);
    setFeaturedCategory(findFeaturedCategory);

  }, [categories_w_products]);

  useEffect(() => {
    dispatch(getHomeData());
    dispatch(getCategories(true, true));
    dispatch(getCustomCategories());
    dispatch(getLastVisited());
  }, [dispatch]);

  const handleTutorial = (code) => {
    dispatch(setCurrentVideo(code))
  }

  return (
    <ContainerBoxPage className='home-box-page'>
      <div id='banner-home'>
        {banner && (
          <>
            <img
              src={banner.media.small}
              srcSet={`
          ${banner.media.small} 740w,
          ${banner.media.medium} 1360w,
          ${banner.media.large},
        `}
              alt={banner.copy}
            />
            <h1>
              <span>{banner.copy}</span>
            </h1>
          </>
        )}
        {!banner && <Loader ellipsis />}
      </div>

      <div className='categories-home-carousel'>
        <h2>Categorías</h2>
        <Carousel gap={5} hasArrows>
          {categories.map((category, categoryIndex) => (
            <Link to={`/box/categoria/${category.id}/${category.code}`} key={categoryIndex}>
              <>
                <span>
                  <Image src={category.media.small || '/img/categoria-generico.svg'} alt={category.code} />
                </span>
                {category.name}
              </>
            </Link>
          ))}
        </Carousel>
        <hr />
      </div>

      <FeaturedProducts
        subtitle={featuredCategory?.name}
        list={featuredCategory?.name}
        link={`categoria/${featuredCategory?.id}/${featuredCategory?.code || ''}`}
        products={featuredCategory?.products}
        loader={loader}
      />

      <MostWanted wanted={custom_categories[0]?.most_wanted} loader={custom_categories_loader} />

      {last_visited.length > 0 && <FeaturedProducts subtitle={last_visited[0]?.name} link={`categoria/102/${last_visited[0]?.code}`} products={last_visited[0]?.products} />}

      <div className='banners comerciante'>
        <div>
          {!isMobile && (
            <div className='banner-small'>
              <img src='/img/banner-monedero.jpg' alt='Mas x Piza Monedero' />
              <div className='detail'>
                <h2>
                  Más x Pieza
                  <br />
                  Monedero
                </h2>
                <p>Descubre nuestro programa de lealtad y obtén beneficios adicionales en tus compras.</p>
                <Link to='/tutoriales' className='btn btn-white btn-outline' onClick={() => {
                  handleTutorial(tutorials[0])
                }}>
                  Ver tutorial
                </Link>
              </div>
            </div>
          )}
          <div className='discover'>
            {!isMobile && <h2>Descubre Más x Caja</h2>}
            <div className='call-action'>
              <div className='icon-box'>
                <img src='/img/box-mas-x-caja.png' alt='box active' />
              </div>
              <div className='txt'>
                {isMobile && <h2>Descubre Más x Caja</h2>}
                <p>¡Aprovecha sus ventajas!</p>
                {!isMobile && (
                  <Link to='/tutoriales' className='btn btn-primary btn-outline' onClick={() => {
                    handleTutorial(tutorials[1])
                  }}>
                    Ver tutorial
                  </Link>
                )}
              </div>
              {isMobile && (
                <Link to='/tutoriales' className='btn btn-primary btn-outline' onClick={() => {
                  handleTutorial(tutorials[1])
                }}>
                  Ver tutorial
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <FeaturedProducts list={mainCategories[0]?.name} subtitle={mainCategories[0]?.name} products={mainCategories[0]?.products} link={`categoria/${mainCategories[0]?.id}/${mainCategories[0]?.code}`} loader={loader} />

      {isMobile && (
        <div className='banners comerciante mobile'>
          <div>
            <div className='banner-small'>
              <img src='/img/banner-monedero-mobile.jpg' alt='Mas x Piza Monedero' />
              <div className='detail'>
                <h2>Más x Pieza Monedero</h2>
                <p>Descubre nuestro programa de lealtad y obtén beneficios adicionales en tus compras.</p>
                {/* <Link to='/contacto' className='btn btn-white btn-outline'>
                  OBTENER MONEDERO
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      )}

      <FeaturedProducts subtitle={mainCategories[1]?.name} list={mainCategories[1]?.name} products={mainCategories[1]?.products} link={`categoria/${mainCategories[1]?.id}/${mainCategories[1]?.code}`} loader={loader} />

      <FeaturedProducts
        subtitle={custom_categories[0]?.best_sellers.name}
        list={custom_categories[0]?.best_sellers.name}
        products={custom_categories[0]?.best_sellers.products}
        loader={loader}
      />
    </ContainerBoxPage>
  );
};

export default React.memo(HomePage);
