import React, { useState } from 'react';

const Rating = ({max = 5, value = 0, onChange}) => {
  const [rate, setRate] = useState(false)

  const items = new Array(max).fill(0)

  const change = (item) => {
    if(!onChange) return;
    onChange(parseInt(item) + 1);
  }

  return (<div className='form-rating'>
    {Object.keys(items).map((item) => <span key={`item-${item}`} className={((value > item) || rate > item) ? 'active' : ''}
    onMouseOver={() => setRate(parseInt(item) + 1)}
    onMouseLeave={() => setRate(false)}
    onClick={() => change(item)}>
      <i className="icon-star"></i>
    </span>)}
  </div>);
};

export default Rating;
