import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import { getCardsSaved, purchaseSetMSI, purchaseSetTokenCard } from './../../store/actions/purchase';
import Image from './../Image';
import AddNewCard from './AddNewCard';
import InputCheckbox from './../FormElements/InputCheckbox';
import classNames from 'classnames';
import useTotalPurchase from './../../hooks/useTotalPurchase';

const CardList = () => {
  const dispatch = useDispatch();
  const totalPurchase = useTotalPurchase();

  const { cardsList, loaderCards, tokenCard, msi } = useSelector((state) => state.purchase);
  const { MSI } = useSelector((state) => state.home);

  const [newCard, setNewCard] = useState(false);
  const [enableMSI, setEnableMSI] = useState(false);
  const [msiMonthsEnabled, setMsiMonthsEnabled] = useState(false);

  useEffect(() => {
    const months = MSI.months.find((item) => item.min_amount <= totalPurchase.total_with_iva && (item.max_amount >= totalPurchase.total_with_iva || item.max_amount === true));
    if (months) {
      setMsiMonthsEnabled(months);
    }
  }, [MSI, totalPurchase]);

  useEffect(() => {
    dispatch(getCardsSaved());
  }, [dispatch]);

  useEffect(() => {
    if (tokenCard) return;
    const card = cardsList.find((card) => card.default === true);
    if (card && !newCard) {
      dispatch(purchaseSetTokenCard(card));
    }
  }, [dispatch, tokenCard, cardsList, newCard]);

  const setTokenCard = (card) => dispatch(purchaseSetTokenCard(card));

  const handleAddCard = (status) => {
    dispatch({ type: 'PURCHASE_CLEAR_CARD_TOKEN' });
    setNewCard(status);
  };

  const handleMSI = (value) => {
    if (msi === value) {
      return dispatch(purchaseSetMSI(false));
    }
    dispatch(purchaseSetMSI(value));
  };

  const openMsiOptions = () => {
    dispatch(purchaseSetMSI(false));
    setEnableMSI(!enableMSI);
  };

  if (loaderCards) return <Loader spinner={{ title: 'Cargando' }} blue />;

  if (!loaderCards && !cardsList.length) {
    return (
      <>
        <div className='cards-not-found-alert'>
          <i className='icon-card-alert'></i>
          <p>Aún no tienes tarjetas agregadas.</p>
          <span className='btn btn-primary rounded-small' onClick={() => handleAddCard(true)}>
            Agregar tarjeta
          </span>
        </div>
        {newCard && <AddNewCard close={() => handleAddCard(false)} />}
      </>
    );
  }

  return (
    <>
      <div className='card-list'>
        {cardsList.map((card, cardIndex) => (
          <div key={cardIndex} className={`card${tokenCard === card.id ? ' selected' : ''}`} onClick={() => setTokenCard(card)}>
            <div className='info'>
              <div className='card-brand'>
                <Image src={`/img/cards/icon-${card.brand}.png`} />
              </div>
              <div className='card-number'>
                {(card.brand === 'visa' || card.brand === 'mastercard') && <>**** **** **** </>}
                {card.brand === 'american_express' && <>**** ****** *</>}
                {card.last4}
              </div>
              <div className='icon'>
                <i className='icon-ok circle'></i>
              </div>
            </div>
            {MSI.enabled && totalPurchase.total >= MSI.min_amount && (
              <div className='msi'>
                <InputCheckbox item={{ label: 'Paga a meses sin intereses', value: 1 }} onChange={openMsiOptions} selected={enableMSI} white />
                {enableMSI && msiMonthsEnabled && (
                  <div className='msi-options'>
                    {msiMonthsEnabled?.term.map((month, monthIndex) => (
                      <div className={classNames('msi-option', { active: month === msi })} key={monthIndex} onClick={() => handleMSI(month)}>
                        {month} meses
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <span className='link add' onClick={() => handleAddCard(true)}>
        <i className='icon-plus-circle'></i> Agregar nueva
      </span>
      <Link to='/carrito/realizar-pago' className={`btn btn-primary${!tokenCard ? ' disabled' : ''}`}>
        CONTINUAR
      </Link>
      {newCard && <AddNewCard close={() => handleAddCard(false)} />}
    </>
  );
};

export default CardList;
