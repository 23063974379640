import { types } from '../reducers/box';
import { axiosApi } from '../axiosAPI';

export const boxOpen = (box = false) => (dispatch) => dispatch({ type: types.BOX_OPEN, box });

export const boxDetail = (detail = false) => (dispatch) => dispatch({ type: types.BOX_DETAIL, detail });

export const boxIncrease = (percent = 5) => (dispatch) => dispatch({ type: types.BOX_INCREASE, percent });

export const boxGetContainers = () => async (dispatch) => {
  let containers = JSON.parse(window.sessionStorage.getItem('containers'));
  if (!containers) {
    const response = await axiosApi.get('/v1/site/container');
    containers = response.data.containers;
    window.sessionStorage.setItem('containers', JSON.stringify(containers));
  }

  dispatch({ type: types.CART_GET_CONTAINERS, containers });
};
