import React from 'react';

class Input extends React.Component {
  state = {
    error: false,
    show: false,
    active: !!this.props.value,
  };

  show = () => {
    this.setState({ show: !this.state.show });
  };

  active = (event) => {
    const body = document.querySelector('body')
    body.classList.add('no-sticky');
    const { currentTarget } = event;
    const elementContainer = currentTarget.parentElement;

    elementContainer.classList.add('active');
    this.setState({ active: true });
  };

  send = (value, error) => {
    if (this.props.update) {
      this.props.update(value, error);
    }
  };

  blur = (event) => {
    const body = document.querySelector('body')
    body.classList.remove('no-sticky');
    let value = event.currentTarget.value.trim();
    const elementContainer = event.currentTarget.parentElement;
    event.currentTarget.value = value;

    if (!value.length) {
      this.setState({ active: false });
      return elementContainer.classList.remove('active');
    }

    if (this.props.type === 'phone') {
      value = value.replace(/\D/g, '');
      if (!/^[\d- ()]+$/.test(value)) {
        elementContainer.classList.add('error');
        this.send(false, true);
        return this.setState({ error: '*Teléfono invalido' });
      }
      if (value.length > 10) {
        elementContainer.classList.add('error');
        this.send(false, true);
        return this.setState({ error: this.props.max.label });
      }
      if (value.length < 10) {
        elementContainer.classList.add('error');
        this.send(false, true);
        return this.setState({ error: this.props.min.label });
      }
    }

    if (this.props.min && value.length < this.props.min.value) {
      elementContainer.classList.add('error');
      this.send(false, true);
      return this.setState({ error: this.props.min.label });
    }

    if (this.props.max && value.length > this.props.max.value) {
      elementContainer.classList.add('error');
      this.send(false, true);
      return this.setState({ error: this.props.max.label });
    }

    if (this.props.minWords) {
      const words = value.split(' ');
      if (words.length < this.props.minWords.value) {
        elementContainer.classList.add('error');
        this.send(false, true);
        return this.setState({ error: this.props.minWords.label });
      }
      words.forEach((element) => {
        if (this.props.min && element.length < this.props.min.value) {
          elementContainer.classList.add('error');
          this.send(false, true);
          return this.setState({ error: this.props.min.label });
        }

        if (this.props.max && element.length > this.props.max.value) {
          elementContainer.classList.add('error');
          this.send(false, true);
          return this.setState({ error: this.props.max.label });
        }
      });
    }

    if (this.props.pattern) {
      const validate = this.props.pattern.exp.test(value);
      if (!validate) {
        elementContainer.classList.add('error');
        this.send(false, true);
        return this.setState({ error: this.props.pattern.label });
      }
    }

    if (this.props.compare && this.props.compare.value !== value) {
      elementContainer.classList.add('error');
      this.send(false, true);
      return this.setState({ error: this.props.compare.label });
    }

    if (this.props.onChange) return this.props.onChange(value);

    this.send(value);
  };

  check = (event) => {
    if (!this.props.value) {
      this.setState({ value: event.currentTarget.value });
    }
  };

  update = (event) => {
    this.setState({ error: false });
    const { currentTarget } = event;
    let value = currentTarget.value;
    if (this.props.accept) {
      value = value.replace(this.props.accept, '');
    }
    const elementContainer = currentTarget.parentElement;
    if (this.props.max && value.length > this.props.max.value) {
      return (currentTarget.value = this.props.value || this.state.value);
    }
    elementContainer.classList.remove('error');
    this.send(value, false);
  };

  type = () => {
    if (this.state.show) return 'text';
    if (this.props.type === 'phone' || this.props.type === 'number') return 'tel';

    return this.props.type || 'text';
  };

  value = () => {
    if (!this.props.value && this.state.error) return;
    return this.props.value || '';
  };

  componentDidUpdate(prevProps, prevState) {
    const active = !!this.props.value && !this.state.active;
    if (active) {
      this.setState({ active });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value || nextState.error !== this.state.error || nextState.active !== this.state.active || nextState.show !== this.state.show;
  }

  render() {
    return (
      <div className={`input input-${this.props.type || 'text'} ${this.props.className || ''}${this.state.active ? ' active' : ''}${this.props.readonly ? ' read-only' : ''}`}>
        <input
          type={this.type()}
          onFocus={(event) => this.active(event)}
          onBlur={(event) => this.blur(event)}
          onChange={(event) => this.update(event)}
          autoComplete='off'
          minLength={this.props.min ? this.props.min.value : ''}
          maxLength={this.props.max ? this.props.max.value : ''}
          value={this.value()}
          tabIndex={this.props.tabIndex || 0}
          name={this.props.name}
          inputMode={this.props.inputMode || 'text'}
          autoCapitalize={this.props.autoCapitalize || 'on'}
        />
        <div className='label'>
          {this.props.icon && (
            <span className='icon'>
              <i className={this.props.icon}></i>
            </span>
          )}
          <span className='placeholder'>
            {this.props.placeholder}
            {this.props.suffix && <i>{this.props.suffix}</i>}
          </span>
        </div>
        {this.props.type && this.props.type === 'password' && (
          <span onClick={() => this.show()} className='show-password'>
            <i className={`icon-${this.state.show ? 'eye-on' : 'eye-off'}`}></i>
          </span>
        )}
        {this.state.error && <div className='error-label'>{this.state.error}</div>}
      </div>
    );
  }
}

export default React.memo(Input);
