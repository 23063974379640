const fileBase64 = (event, maxSize = 12) => {
  const file = event.currentTarget.files[0];

  if (!file) return;
  const fileSize = file.size / 1024 / 1024 <= maxSize;

  const fileSizeCheck = fileSize;
  if (!fileSizeCheck) {
    return { ok: false, error: 'Archivo demasiado grande' };
  }

  let type, icon;

  switch (file.type) {
    case 'text/csv':
      type = 'csv';
      icon = 'document';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      type = 'xlsx';
      icon = 'document';
      break;
    case 'application/pdf':
      type = 'pdf';
      icon = 'document';
      break;
    case 'image/png':
      type = 'png';
      icon = 'picture';
      break;
    case 'image/jpg':
    case 'image/jpeg':
      type = 'jpg';
      icon = 'picture';
      break;
    default:
      type = 'xls';
      icon = 'document';
  }
  return new Promise((resolve, reject) => {
    const loadFile = new FileReader();

    loadFile.onload = () =>
      resolve({
        ok: true,
        base64: loadFile.result.replace(/.+;base64,/, ''),
        size: fileSize,
        name: file.name.replace(/\.[a-z]{3,4}$/, ''),
        type,
        icon,
      });

    loadFile.onerror = () => reject({ ok: false, msg: 'Error al cargar archivo' });

    loadFile.readAsDataURL(file);
  });
};

export default fileBase64;
