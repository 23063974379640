import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { analyticsRemoveCart } from '../store/actions/analytics-events';
import { addCart, deleteItemCart, getCart, unloadCart } from '../store/actions/cart';
import { selectOffice } from '../store/actions/purchase';
import GoToCartAlert from './GoToCartAlert';
import BasketItems from './MiniCart/BasketItems';
import RelatedProducts from './MiniCart/RelatedProducts';
import MoneyFormat from './MoneyFormat';
import PerfectScroll from './PerfectScroll';

class MiniCart extends React.Component {
  state = {
    open: false,
    openSelectBranch: false,
    goCheckout: false,
    toDelete: false,
  };
  open = (goCart = false) => {
    const perfectScrollBasket = document.querySelector('#mini-cart .products-basket .ps');

    if (this.state.openSelectBranch) {
      perfectScrollBasket.scrollTop = 999999999;
      return this.setState({ openSelectBranch: false });
    }
    const perfectScrollRelated = document.querySelector('#mini-cart .products-related .ps');
    const perfectScrollBranch = document.querySelector('#mini-cart .select-branch .ps');
    if (perfectScrollBasket) {
      perfectScrollBasket.scrollTop = 0;
    }
    if (perfectScrollRelated) {
      perfectScrollRelated.scrollTop = 0;
    }
    if (perfectScrollBranch) {
      perfectScrollBranch.scrollTop = 0;
    }
    this.props.openMiniCart();
  };

  componentDidMount() {
    const containerMiniCart = document.getElementById('mini-cart');
    containerMiniCart.addEventListener('click', (event) => {
      const { target } = event;
      if (this.props.cart.openMiniCart && target.id === 'mini-cart') {
        this.open();
      }
    });
  }

  delete = (product) => {
    const itemToDelete = document.querySelector('.confirm-delete.active');
    if (itemToDelete) {
      itemToDelete.classList.remove('active');
    }

    analyticsRemoveCart(product, product.quantity);

    this.props.deleteItem(product.product_id);
  };

  openSelectBranch = () => {
    this.setState({ openSelectBranch: !this.state.openSelectBranch });
  };

  selectOffice = (id) => {
    this.setState({ openSelectBranch: false });
    this.props.selectOffice(id);
  };

  goCheckout = () => {
    const goCheckout = !this.state.goCheckout;
    this.setState({ goCheckout });
  };

  enabledGoCart = () => {
    return this.props.cart.products && !!this.props.cart.products.length;
  };

  openGoToCartAlert = () => {
    if (!this.enabledGoCart()) return;
    if (this.props.logged) {
      this.open();
      return this.props.history.push('/carrito');
    }
    this.props.openGoToCartAlert();
  };

  render() {
    return (
      <Fragment>
        <div className='basket-mobile'>
          <span className='icon-basket pointer' onClick={() => this.open()}>
            {this.props.cart.count > 0 && <i>{this.props.cart.count < 100 ? this.props.cart.count : '99+'}</i>}
          </span>
        </div>
        <section id='mini-cart' className={`${this.props.cart.openMiniCart ? 'active' : ''}${!this.props.cart.products.length ? ' empty' : ''}`}>
          {this.state.openSelectBranch && (
            <div className='select-branch'>
              <header>
                <span className='icon-basket'>{this.props.cart.count > 0 && <i>{this.props.cart.count}</i>}</span>
                <h1>Canasta</h1>
                <span className='icon-close' onClick={() => this.open()}></span>
              </header>
              <PerfectScroll>
                <div className='content'>
                  <div className='address-items'>
                    <p>Selecciona una tienda:</p>
                    <div className='select no-border tmp'>RECOGER EN SUCURSAL</div>
                    {this.props.offices &&
                      this.props.offices.map((office, officeIndex) => (
                        <div key={officeIndex} className='address' onClick={() => this.selectOffice(office.id)}>
                          <strong>{office.name}</strong>
                          {office.address}
                        </div>
                      ))}
                  </div>
                </div>
              </PerfectScroll>
              <div className='total'>
                <div>
                  <div>
                    Total:
                    <br />
                    <span className='total-price'>
                      <MoneyFormat money={this.props.cart.total} />
                    </span>
                  </div>
                  <div>
                    <span onClick={() => this.openGoToCartAlert()} className={`btn btn-primary${this.enabledGoCart() ? '' : ' disabled'}`}>
                      Ir al pago
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='products-basket'>
            <header>
              <span className='icon-basket'>{this.props.cart.count > 0 && <i>{this.props.cart.count < 100 ? this.props.cart.count : '99+'}</i>}</span>
              <h1>Canasta</h1>
              <span className='icon-close' onClick={() => this.open()}></span>
            </header>
            <PerfectScroll>
              <div className='content'>
                <BasketItems products={this.props.cart.products} orderID={this.props.cart.orderID} close={this.open} />
                <div className='other-options'>
                  <div>
                    También puedes:
                    <br />
                    <div>
                      <button className='btn btn-white btn-primary-outline' onClick={() => this.openSelectBranch()}>
                        Recoger en
                      </button>
                      <Link className='btn btn-white btn-primary-outline' onClick={this.open} to='/contacto'>
                        COTIZAR
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScroll>
            <div className='total'>
              <div>
                <div>
                  Total:
                  <br />
                  <span className='total-price'>
                    <MoneyFormat money={this.props.cart.total} />
                  </span>
                </div>
                <div>
                  <span className={`btn btn-primary${this.enabledGoCart() ? '' : ' disabled'}`} onClick={() => this.openGoToCartAlert()}>
                    Ir al pago
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!this.state.openSelectBranch && this.props.cart.related && this.props.cart.related.length > 0 && <RelatedProducts products={this.props.cart.related} />}
        </section>
        <GoToCartAlert />
      </Fragment>
    );
  }
}

const mapStateProps = (state) => {
  return {
    cart: state.cart,
    logged: state.user.logged,
    offices: state.home.offices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMiniCart: () => dispatch({ type: 'CART_OPEN_MINICART' }),
    getCart: (id = false) => dispatch(getCart(id)),
    unloadCart: () => dispatch(unloadCart()),
    deleteItem: (id) => dispatch(deleteItemCart(id)),
    addCart: (id) => dispatch(addCart(id, true)),
    loginForm: () => dispatch({ type: 'USER_OPEN', open: 'login' }),
    openGoToCartAlert: () => dispatch({ type: 'CART_GO_TO_CHECKOUT_ALERT' }),
    selectOffice: (id) => dispatch(selectOffice(id)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default React.memo(withRouter(createConnect(MiniCart)));
