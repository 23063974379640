export const types = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  REGISTER: 'REGISTER',
  REGISTER_CLEAR: 'REGISTER_CLEAR',
  RESET: 'RESET',
  USER_ADD_ADDRESS: 'USER_ADD_ADDRESS',
  USER_ASSOCIATE_ORDER_ID: 'USER_ASSOCIATE_ORDER_ID',
  USER_BACK: 'USER_BACK',
  USER_CLEAR_ERROR: 'USER_CLEAR_ERROR',
  USER_CLOSE_SESSION: 'USER_CLOSE_SESSION',
  USER_CONFIRM: 'USER_CONFIRM',
  USER_CONFIRM_ERROR: 'USER_CONFIRM_ERROR',
  USER_CLEAR_CONFIRM: 'USER_CLEAR_CONFIRM',
  USER_CROPPER: 'USER_CROPPER',
  USER_DATA_NETWORK: 'USER_DATA_NETWORK',
  USER_DELETE_ADDRESS: 'USER_DELETE_ADDRESS',
  USER_ERROR: 'USER_ERROR',
  USER_ERROR_ADD_ADDRESS: 'USER_ERROR_ADD_ADDRESS',
  USER_GET_ADDRESSES: 'USER_GET_ADDRESSES',
  USER_GET_LOYALTY: 'USER_GET_LOYALTY',
  USER_GET_PROFILE: 'USER_GET_PROFILE',
  USER_LAYER: 'USER_LAYER',
  USER_LOADER: 'USER_LOADER',
  USER_LOADER_ADDRESSES: 'USER_LOADER_ADDRESSES',
  USER_LOADER_DELETE: 'USER_LOADER_DELETE',
  USER_LOADER_LOYALTY: 'USER_LOADER_LOYALTY',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_DESKTOP: 'USER_LOGIN_DESKTOP',
  USER_OPEN: 'USER_OPEN',
  USER_OPEN_MDL: 'USER_OPEN',
  USER_OVERLAY: 'USER_OVERLAY',
  USER_RECOVERY: 'USER_RECOVERY',
  USER_RECOVERY_CHANGE_PASSWORD: 'USER_RECOVERY_CHANGE_PASSWORD',
  USER_RECOVERY_CHANGE_PASSWORD_FORM: 'USER_RECOVERY_CHANGE_PASSWORD_FORM',
  USER_REMOVE_ORDER_ID: 'USER_REMOVE_ORDER_ID',
  USER_SET_ADDRESS_EDIT: 'USER_SET_ADDRESS_EDIT',
  USER_SOCIAL_LINKED: 'USER_SOCIAL_LINKED',
  USER_SOCIAL_NETWORK_ERROR: 'USER_SOCIAL_NETWORK_ERROR',
  USER_STEP: 'USER_STEP',
  USER_UPDATE_ADDRESS: 'USER_UPDATE_ADDRESS',
  USER_UPDATE_PROFILE: 'USER_UPDATE_PROFILE',
  USER_UPDATE_PROFILE_INPUT: 'USER_UPDATE_PROFILE_INPUT',
  USER_REQUEST_WALLET: 'USER_REQUEST_WALLET',
  USER_REGISTER_CORRECTLY: 'USER_REGISTER_CORRECTLY',
  USER_BUYING_OFFLINE: 'USER_BUYING_OFFLINE',
  USER_REMINDER: 'USER_REMINDER',
  USER_REMINDER_OVERLAY: 'USER_REMINDER_OVERLAY'
};

const initialStore = {
  activate: false,
  address: false,
  addresses: [],
  cropper: false,
  error: false,
  error_add_address: false,
  layer: false,
  loader: false,
  loaderAddresses: true,
  loaderDelete: false,
  loaderLoyalty: false,
  logged: false,
  loyalty: false,
  open: '',
  overlay: false,
  login_desktop: false,
  overlay_position: false,
  prevOpen: [],
  profile: false,
  recovery_fail: false,
  recovery_success: false,
  recovery_token: false,
  registerResponse: false,
  step: 0,
  token: false,
  updateConfirmation: false,
  update_password_fail: false,
  update_password_success: false,
  user_data_network: {},
  socialNetworkError: false,
  walletRequest: false,
  registered: false,
  buy_offline: true,
  is_reminder: false,
  is_reminder_overlay: false
};

const reducer = (store = initialStore, action) => {
  const body = document.querySelector('body');
  const containerOptionsMenu = document.querySelector('.user-options-menu .perfect-scroll>div');
  switch (action.type) {
    case types.REGISTER:
      return { ...store, registerResponse: action.registerResponse, loader: false };
    case types.REGISTER_CLEAR:
      return { ...store, registerResponse: false, loader: false, user_data_network: {} };
    case types.USER_CONFIRM:
      return { ...store, open: 'user-confirm' };
    case types.USER_CONFIRM_ERROR:
      return { ...store, registerResponse: action.error, open: 'register' };
    case types.USER_LOGIN:
      window.localStorage.setItem('expires_in', action.token.expires_in);
      window.localStorage.setItem('logged', 1);
      return { ...store, token: action.token.access_token, refresh_token: action.token.refresh_token, profile: action.profile, loader: false, open: 'user-menu', logged: true, buy_offline: false };
    case types.USER_CLOSE_SESSION:
      window.localStorage.removeItem('expires_in');
      window.localStorage.removeItem('logged');
      body.classList.remove('no-scroll');
      if (containerOptionsMenu) {
        containerOptionsMenu.scrollTop = 0;
      }
      return { ...store, token: false, profile: false, loader: false, open: 'user-menu', logged: false, buy_offline: true };
    case types.USER_GET_PROFILE:
      if (containerOptionsMenu) {
        containerOptionsMenu.scrollTop = 0;
      }
      return { ...store, profile: action.profile };
    case types.USER_ERROR:
      return { ...store, error: action.error, loader: false };
    case types.USER_CLEAR_ERROR:
      return { ...store, error: false, recovery_fail: false, recovery_success: false, loader: false, error_add_address: false };
    case types.USER_RECOVERY:
      return { ...store, ...action.recovery };
    case types.USER_RECOVERY_CHANGE_PASSWORD_FORM:
      return { ...store, recovery_token: action.token, open: 'recovery_change_password' };
    case types.USER_RECOVERY_CHANGE_PASSWORD:
      if (action.fail) {
        return { ...store, recovery_fail: action.fail, loader: false };
      }
      return { ...store, recovery_success: action.success };

    case types.CHANGE_PASSWORD:
      if (action.fail) {
        return { ...store, update_password_fail: action.fail, loader: false };
      } else if (action.success) {
        return { ...store, update_password_success: action.success, loader: false };
      }
      return { ...store, update_password_success: false, update_password_fail: false, loader: false };
    case types.USER_OPEN:
      if (!action.open.length) {
        body.classList.remove('no-scroll');
        return { ...store, open: '', prevOpen: [], cropper: false, recovery_fail: false, recovery_success: false, loader: false };
      } else {
        body.classList.add('no-scroll');
      }
      if (containerOptionsMenu) {
        containerOptionsMenu.scrollTop = 0;
      }
      return { ...store, open: action.open, recovery_fail: false, recovery_success: false, loader: false };

    case types.USER_BACK:
      let btnGoBack;

      switch (store.open) {
        case 'register':
          btnGoBack = 'user-menu';
          break;
        case 'login':
          btnGoBack = 'user-menu';
          break;
        case 'recovery':
          btnGoBack = 'login';
          break;
        case 'userOpenForm':
          btnGoBack = 'recovery';
          break;
        case 'errorUpload':
          btnGoBack = 'register';
          break;
        case 'user-confirm':
          window.location.href = '/box';
          btnGoBack = '';
          break;
        default:
          btnGoBack = '';
          break;
      }

      if (btnGoBack === '') {
        body.classList.remove('no-scroll');
      } else {
        body.classList.add('no-scroll');
      }
      containerOptionsMenu.scrollTop = 0;
      return { ...store, open: btnGoBack, recovery_fail: false, recovery_success: false, error: false, loader: false };
    case types.USER_CROPPER:
      return { ...store, cropper: action.cropper };
    case types.USER_LOADER:
      return { ...store, loader: action.loader };
    case types.USER_GET_ADDRESSES:
      body.classList.remove('no-scroll');
      return { ...store, addresses: action.addresses, loader: false, loaderAddresses: false, loaderDelete: false };
    case types.USER_SET_ADDRESS_EDIT:
      return { ...store, address: action.address };
    case types.USER_ADD_ADDRESS:
      const addressesAdd = { ...store.addresses, ...action.address };
      return { ...store, addressesAdd, loader: false };
    case types.USER_ERROR_ADD_ADDRESS:
      return { ...store, error_add_address: action.error, loader: false };
    case types.USER_ASSOCIATE_ORDER_ID:
      return { ...store, profile: { ...store.profile, order_id: action.order_id } };
    case types.USER_UPDATE_PROFILE_INPUT:
      return { ...store, profile: { ...store.profile, [action.field]: action.data } };
    case types.USER_REMOVE_ORDER_ID:
      return { ...store, profile: { ...store.profile, order_id: false } };
    case types.RESET:
      window.localStorage.removeItem('logged');
      return { ...initialStore };
    case types.USER_LOADER_ADDRESSES:
      return { ...store, loaderAddresses: true };
    case types.USER_LOADER_DELETE:
      return { ...store, loaderDelete: action.address_id };
    case types.USER_SOCIAL_LINKED:
      return { ...store, user_social_linked: action.socialNetwork, loader: false };
    case types.USER_SOCIAL_NETWORK_ERROR:
      return { ...store, socialNetworkError: action.error || false }
    case types.USER_OVERLAY:
      return { ...store, overlay: action.payload };
    case types.USER_LOGIN_DESKTOP:
      return { ...store, login_desktop: action.payload };
    case types.USER_LAYER:
      if (action.payload.position) {
        return { ...store, layer: action.payload.layer, overlay_position: action.payload.position };
      } else {

        return { ...store, layer: action.payload.layer };
      }

    case types.USER_LOADER_LOYALTY:
      return { ...store, loaderLoyalty: action.loader || false }
    case types.USER_GET_LOYALTY:
      return { ...store, loyalty: action.loyalty, loaderLoyalty: false }

    case types.USER_STEP:
      window.localStorage.setItem('registration-steps', action.payload)
      return { ...store, step: action.payload, registerResponse: false };
    case types.USER_UPDATE_PROFILE:
      return { ...store, profile: { ...store.profile, ...action.profile, catalog: action.catalog }, updateConfirmation: action.confirmation, loader: false }
    case types.USER_CLEAR_CONFIRM:
      return { ...store, updateConfirmation: false }
    case types.USER_DATA_NETWORK:
      return { ...store, user_data_network: action.data, loader: false }
    case types.USER_REQUEST_WALLET:
      return { ...store, requestWallet: action.data, loaderLoyalty: false }
    case types.USER_REGISTER_CORRECTLY:
      return { ...store, registered: action.payload }
    case types.USER_REMINDER:
      return { ...store, is_reminder: action.payload }
    case types.USER_REMINDER_OVERLAY:
      return { ...store, is_reminder_overlay: action.payload }
    default:
      const logged = window.localStorage.getItem('logged');
      return { ...store, logged: logged === '1' };
  }
};

export default reducer;
