import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

class PerfectScroll extends React.Component {
  componentDidMount() {
    this.$el = new PerfectScrollbar(this.el, {
      suppressScrollX: true,
      wheelPropagation: this.props.propagation || false,
      scrollYMarginOffset: this.props.scrollYMarginOffset ||  30
    });
  }

  componentWillUnmount() {
    this.$el.destroy();
    this.$el = null;
  }

  componentDidUpdate() {
    this.$el.update();
  }

  render() {
    return (
      <div className={`perfect-scroll${this.props.className ? ' ' + this.props.className : ''}`} style={{height:this.props.height}}>
        <div ref={(el) => (this.el = el)}>
          <div className='content'>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default React.memo(PerfectScroll);
