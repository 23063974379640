import React from 'react';
import { connect } from 'react-redux';
import { clearPackageError } from '../../store/actions/purchase';
import AdvancedModal from '../AdvancedModal';

class PackageError extends React.Component {
  componentWillUnmount() {
    this.props.clearError();
  }
  render() {
    return (
      <AdvancedModal className='packages-error' close={this.props.clearError} scrollYMarginOffset={0}>
        <AdvancedModal.Body>
          <img src='/img/danger.png' alt='Danger' />
          {this.props.error.message ? this.props.error.message : <>La dirección seleccionada no entra en el rango de entrega de nuestros servicios.</>}
          {this.props.error.details && <>
            <ul className="detail-error">
              {this.props.error.details.map((error, indexError) => <li key={indexError}>
<strong>{error.product_name}</strong>
{error.messages.join(', ')}
              </li>)}
            </ul>
          </>}
          <div className='text-center'>
            <span className='btn btn-primary btn-x-small rounded' onClick={this.props.clearError}>
              ACEPTAR
            </span>
          </div>
        </AdvancedModal.Body>
      </AdvancedModal>
    );
  }
}

const mapStateProps = (state) => {
  return {
    error: state.purchase.packageError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch(clearPackageError()),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(PackageError);
