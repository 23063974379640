export const types = {
  PURCHASE_CHANGE_ADDRESS_ERRORS: 'PURCHASE_CHANGE_ADDRESS_ERRORS',
  PURCHASE_CHANGE_ADDRESS_FORM_LOGGED: 'PURCHASE_CHANGE_ADDRESS_FORM_LOGGED',
  PURCHASE_CHANGE_ADDRESS_GUEST: 'PURCHASE_CHANGE_ADDRESS_GUEST',
  PURCHASE_CHANGE_ADDRESS_UPDATE_FIELD_LOGGED: 'PURCHASE_CHANGE_ADDRESS_UPDATE_FIELD_LOGGED',
  PURCHASE_CHANGE_CARD_TOKEN: 'PURCHASE_CHANGE_CARD_TOKEN',
  PURCHASE_CHANGE_DELIVERY_TYPE: 'PURCHASE_CHANGE_DELIVERY_TYPE',
  PURCHASE_CHANGE_NEW_ADDRESS_LOGGED: 'PURCHASE_CHANGE_NEW_ADDRESS_LOGGED',
  PURCHASE_CHANGE_PAYMENT_METHOD_OPEN: 'PURCHASE_CHANGE_PAYMENT_METHOD_OPEN',
  PURCHASE_CLEAR_CARD_DATA: 'PURCHASE_CLEAR_CARD_DATA',
  PURCHASE_CLEAR_CARD_TOKEN: 'PURCHASE_CLEAR_CARD_TOKEN',
  PURCHASE_CLEAR_DATA: 'PURCHASE_CLEAR_DATA',
  PURCHASE_CLEAR_ERROR_PAYMENT: 'PURCHASE_CLEAR_ERROR_PAYMENT',
  PURCHASE_CLEAR_PACKAGE_ERROR: 'PURCHASE_CLEAR_PACKAGE_ERROR',
  PURCHASE_GET_CARDS_SAVED: 'PURCHASE_GET_CARDS_SAVED',
  PURCHASE_CARDS_DELETE: 'PURCHASE_GET_CARDS_DELETE',
  PURCHASE_CARDS_UPDATE: 'PURCHASE_GET_CARDS_UPDATE',
  PURCHASE_GET_LOCATIONS_BY_ZIP: 'PURCHASE_GET_LOCATIONS_BY_ZIP',
  PURCHASE_GO_BACK: 'PURCHASE_GO_BACK',
  PURCHASE_LOADER: 'PURCHASE_LOADER',
  PURCHASE_LOADER_LOCATIONS_BY_ZIP: 'PURCHASE_LOADER_LOCATIONS_BY_ZIP',
  PURCHASE_LOADER_RATES: 'PURCHASE_LOADER_RATES',
  PURCHASE_LOAD_PACKAGES: 'PURCHASE_LOAD_PACKAGES',
  PURCHASE_LOYALTY_GET: 'PURCHASE_LOYALTY_GET',
  PURCHASE_MAKE_PAYMENT_LOYALTY: 'PURCHASE_MAKE_PAYMENT_LOYALTY',
  PURCHASE_MAKE_PAYMENT_OXXO: 'PURCHASE_MAKE_PAYMENT_OXXO',
  PURCHASE_MAKE_PAYMENT_PAYPAL: 'PURCHASE_MAKE_PAYMENT_PAYPAL',
  PURCHASE_MAKE_PAYMENT_TC: 'PURCHASE_MAKE_PAYMENT_TC',
  PURCHASE_OPEN_CHANGE_ADDRESS: 'PURCHASE_OPEN_CHANGE_ADDRESS',
  PURCHASE_RESET: 'PURCHASE_RESET',
  PURCHASE_RESET_SHIPPING: 'PURCHASE_RESET_SHIPPING',
  PURCHASE_SELECT_NEW_ADDRESS_LOGGED: 'PURCHASE_SELECT_NEW_ADDRESS_LOGGED',
  PURCHASE_SELECT_OFFICE: 'PURCHASE_SELECT_OFFICE',
  PURCHASE_SELECT_SHIPPING: 'PURCHASE_SELECT_SHIPPING',
  PURCHASE_SET_ADDRESS_ID: 'PURCHASE_SET_ADDRESS_ID',
  PURCHASE_SET_CARD_DATA: 'PURCHASE_SET_CARD_DATA',
  PURCHASE_SET_CARD_TOKEN: 'PURCHASE_SET_CARD_TOKEN',
  PURCHASE_SET_CARD_TYPE: 'PURCHASE_SET_CARD_TYPE',
  PURCHASE_SET_LOYALTY_AMOUNT: 'PURCHASE_SET_LOYALTY_AMOUNT',
  PURCHASE_SET_PAYMENT_METHOD: 'PURCHASE_SET_PAYMENT_METHOD',
  PURCHASE_SET_PERSONAL_INFO: 'PURCHASE_SET_PERSONAL_INFO',
  PURCHASE_STEP: 'PURCHASE_STEP',
  PURCHASE_TOKENIZE_CARD: 'PURCHASE_TOKENIZE_CARD',
  PURCHASE_UNLOAD_PACKAGES: 'PURCHASE_UNLOAD_PACKAGES',
  PURCHASE_UPDATE_PERSONAL_INFO: 'PURCHASE_UPDATE_PERSONAL_INFO',
  PURCHASE_UPDATE_TC_DATA: 'PURCHASE_UPDATE_TC_DATA',
  PURCHASE_RESET_LOCATIONS_BY_ZIP: 'PURCHASE_RESET_LOCATIONS_BY_ZIP',
  PURCHASE_LOADER_TOKENIZE: 'PURCHASE_LOADER_TOKENIZE',
  PURCHASE_COMPLETE_ANALYTICS_DATA: 'PURCHASE_COMPLETE_ANALYTICS_DATA',
  PURCHASE_SET_MSI: 'PURCHASE_SET_MSI',
  RESET: 'RESET',
};

const initialStore = {
  addressID: false,
  step: false,
  personalInfo: {
    address: false,
    alias: false,
    city: false,
    email: false,
    exteriorNumber: false,
    interiorNumber: false,
    name: false,
    phone: false,
    references: '',
    state: false,
    suburb: false,
    zip: false,
    rfc: false,
  },
  changeAddressID: false,
  openChangeAddress: false,
  formAddNewAddressLogged: false,
  changeAddress: {
    address: false,
    alias: false,
    city: false,
    email: false,
    exteriorNumber: false,
    interiorNumber: false,
    name: false,
    phone: false,
    references: '',
    state: false,
    suburb: false,
    zip: false,
  },
  paymentMethod: '', //'tc',
  cardData: {
    cvv: false,
    month: false,
    name: false,
    number: false,
    save: false,
    type: false,
    year: false,
  },
  cardsList: [],
  tokenCard: false,
  unSaveCard: {
    brand: false,
    exp_month: false,
    exp_year: false,
    last4: false,
    name: false,
  },
  tokenizer_error: false,
  openChangePaymentMethod: false,
  tmpCardToken: false,
  paymentTC: {
    error: false,
    message: false,
    reference: false,
    detail: false,
    warning: false
  },
  msi: false,
  paymentOXXO: {
    message: '',
    status_code: '',
    warning: '',
    reference: '',
    barcode_url: '',
    expires_at: '',
    amount: '',
  },
  paymentPaypal: {
    approve_link: false,
    reference: false,
  },
  paymentLoyalty: {
    reference: false,
    message: false,
  },
  loyalty_points: 0,
  pickIn: false,
  deliveryType: 'home',
  officeID: false,
  shipping: false,
  packages: false,
  packagesRates: null,
  loyalty: false,
  packageError: false,
  loader: false,
  loaderRates: true,
  loader_city: false,
  loaderLocations: false,
  locationsByZip: false,
  loaderCards: true,
  loaderTokenize: false,
  cardUpdate: false,
  purchaseCompleteAnalytics: false
};

const reducer = (store = initialStore, action) => {
  const updateCardData = { ...store.cardData };
  switch (action.type) {
    case types.PURCHASE_MAKE_PAYMENT_TC:
      const paymentResult = {
        error: !action.reference,
        message: action.message,
        reference: action.reference,
        detail: action.detail || false,
        warning: action.warning || false,
      };

      const stepAfterPayment = !!action.reference ? 4 : 3;

      if (!!action.reference) {
        window.localStorage.removeItem('orderID');
        window.sessionStorage.removeItem('cart');
        window.scroll(0, 0);
        return { ...initialStore, paymentMethod: 'tc', paymentTC: paymentResult, step: stepAfterPayment, loader: false, purchaseCompleteAnalytics: {...store.purchaseCompleteAnalytics} };
      }
      return { ...store, paymentTC: paymentResult, loader: false };
    case types.PURCHASE_CLEAR_ERROR_PAYMENT:
      return { ...store, payment: { ...initialStore.payment }, paymentTC: {...initialStore.paymentTC} };
    case types.PURCHASE_STEP:
      window.scroll(0, 0);
      return { ...store, step: action.step, loader: false };
    case types.PURCHASE_SET_ADDRESS_ID:
      return { ...store, addressID: action.addressID, personalInfo: action.address };
    case types.PURCHASE_LOADER:
      const loader = action.loader || !store.loader;
      return { ...store, loader };
    case types.PURCHASE_LOADER_RATES:
      const loaderRates = action.loader || !store.loader;
      return { ...store, loaderRates };
    case types.PURCHASE_LOADER_CITY:
      const loader_city = action.loader || !store.loader;
      return { ...store, loader_city };
    case types.PURCHASE_SET_PAYMENT_METHOD:
      return { ...store, paymentMethod: action.method, msi: false };
    case types.PURCHASE_SET_CARD_TOKEN:
      if (store.step === 3) {
        return { ...store, tmpCardToken: action.token };
      }
      return { ...store, tokenCard: action.token };
    case types.PURCHASE_UPDATE_PERSONAL_INFO:
      const personalInfo = { ...store.personalInfo };
      personalInfo[action.field] = action.value;
      return { ...store, personalInfo };
    case types.PURCHASE_UPDATE_TC_DATA:
      if (action.field === 'number') {
        if (/^4[0-9]+/.test(action.value)) {
          updateCardData.type = 'visa';
        }

        if (/^(34|37).+/.test(action.value)) {
          updateCardData.type = 'amex';
        }

        if (/^(51|52|53|54|55|222100|271099).+/.test(action.value)) {
          updateCardData.type = 'mastercard';
        }
      }
      updateCardData[action.field] = action.value;
      return { ...store, cardData: updateCardData };
    case types.PURCHASE_SET_CARD_TYPE:
      updateCardData.type = action.cardType;
      return { ...store, cardData: updateCardData };
    case types.PURCHASE_CLEAR_CARD_DATA:
      if (store.step === 3) {
        return { ...store, cardData: { ...initialStore.cardData } };
      }
      return { ...store, tokenCard: false, cardData: { ...initialStore.cardData } };
    case types.PURCHASE_GO_BACK:
      const goBackStep = store.step - 1;
      window.scroll(0, 0);
      return { ...store, step: goBackStep };
    case types.PURCHASE_TOKENIZE_CARD:
      if (action.error) {
        return { ...store, tokenizer_error: action.error, loader: false, loaderTokenize: false };
      }
      window.scroll(0, 0);
      const unSaveCard = {
        name: store.cardData.name,
        last4: store.cardData.number.substr(-4, 4),
        brand: store.cardData.type,
        exp_month: store.cardData.month.label,
        exp_year: store.cardData.year,
      };

      return {
        ...store,
        tokenCard: action.token,
        step: 3,
        cardData: {
          name: false,
          number: false,
          month: false,
          year: false,
          cvv: false,
          type: false,
          save: false,
        },
        tokenizer_error: false,
        loaderTokenize: false,
        unSaveCard,
        openChangePaymentMethod: false,
        loader: false,
      };
    case types.PURCHASE_CHANGE_PAYMENT_METHOD_OPEN:
      return { ...store, openChangePaymentMethod: !store.openChangePaymentMethod, tmpCardToken: false, cardData: { ...initialStore.cardData } };
    case types.PURCHASE_GET_CARDS_SAVED:
      return { ...store, cardsList: action.cards, cardUpdate: false, loaderCards: false };

    case types.PURCHASE_CARDS_UPDATE:
      return { ...store, cardUpdate: action.update || false, loaderTokenize: false };

    case types.PURCHASE_CARDS_DELETE:
      return { ...store, cardsList: action.cards, loaderCards: false };

    case types.PURCHASE_OPEN_CHANGE_ADDRESS:
      const updateOpenChangeAddress = !store.openChangeAddress;
      let updateFormAddNewAddressLogged = store.formAddNewAddressLogged;
      if (!updateOpenChangeAddress) {
        updateFormAddNewAddressLogged = false;
      }

      return { ...store, openChangeAddress: updateOpenChangeAddress, formAddNewAddressLogged: updateFormAddNewAddressLogged, changeAddressID: false };
    case types.PURCHASE_CHANGE_ADDRESS_GUEST:
      let changeAddress = { ...store.changeAddress };
      let changePersonalInfo = { ...store.personalInfo };
      let openChangeAddress = true;
      let formAddNewAddressLogged = store.formAddNewAddressLogged;
      if (action.name === 'submit') {
        if (action.isLogged) {
          changePersonalInfo = { ...store.personalInfo, ...store.changeAddress };
        } else {
          const changeAddressGuest = {
            zip: store.changeAddress.zip,
            address: store.changeAddress.address,
            exteriorNumber: store.changeAddress.exteriorNumber,
            interiorNumber: store.changeAddress.interiorNumber,
            suburbs: store.changeAddress.suburbs,
            state: store.changeAddress.state,
            city: store.changeAddress.city,
            references: store.changeAddress.references,
          };
          changePersonalInfo = { ...store.personalInfo, ...changeAddressGuest };
        }
        changeAddress = { ...initialStore.changeAddress };
        openChangeAddress = false;
        formAddNewAddressLogged = false;
      } else {
        changeAddress[action.name] = action.value;
      }
      return { ...store, changeAddress, personalInfo: changePersonalInfo, openChangeAddress, formAddNewAddressLogged, shipping: false };
    case types.PURCHASE_SELECT_NEW_ADDRESS_LOGGED:
      return { ...store, changeAddressID: action.addressID };
    case types.PURCHASE_CHANGE_NEW_ADDRESS_LOGGED:
      return { ...store, personalInfo: action.personalInfo, openChangeAddress: false, changeAddressID: false, shipping: false };
    case types.PURCHASE_CHANGE_ADDRESS_FORM_LOGGED:
      return { ...store, formAddNewAddressLogged: action.open };
    case types.PURCHASE_CHANGE_CARD_TOKEN:
      if (store.tmpCardToken) {
        return { ...store, tokenCard: store.tmpCardToken, tmpCardToken: false, openChangePaymentMethod: false };
      }
      return { ...store, openChangePaymentMethod: false };

    case types.PURCHASE_LOAD_PACKAGES:
      if (action.error) {
        return { ...store, packageError: action.error, loaderRates: false };
      }
      return { ...store, loaderRates: false, packagesRates: action.packagesRates.rates };
    case types.PURCHASE_SELECT_SHIPPING:
      if (action.error) {
        return { ...store, packagesRates: null, packageError: action.error, loaderRates: false, shipping: false, pickIn: false, officeID: false };
      }
      if (!action.rate) {
        return { ...store, pickIn: true, shipping: false, deliveryType: 'home', officeID: false };
      }
      return { ...store, pickIn: false, shipping: action.rate, loaderRates: false, deliveryType: 'home', officeID: false };
    case types.PURCHASE_RESET_SHIPPING:
      return { ...store, pickIn: false, shipping: false, officeID: false, paymentMethod: '', loyalty_points: 0 };
    case types.PURCHASE_SELECT_OFFICE:
      const stepAfterOffice = action.next && store.step === 1 ? 2 : store.step;
      return { ...store, officeID: action.office, step: stepAfterOffice, packagesRates: null, shipping: false, loaderRates: false, deliveryType: 'office' };
    case types.PURCHASE_UNLOAD_PACKAGES:
      return { ...store, packagesRates: null };
    case types.PURCHASE_CLEAR_PACKAGE_ERROR:
      return { ...store, packageError: false };
    case types.PURCHASE_CHANGE_DELIVERY_TYPE:
      return { ...store, deliveryType: action.deliveryType || 'home', officeID: false };
    case types.PURCHASE_LOADER_LOCATIONS_BY_ZIP:
      return { ...store, loaderLocations: action.loader || false };
    case types.PURCHASE_GET_LOCATIONS_BY_ZIP:
      return { ...store, locationsByZip: action.locations, loaderLocations: false };
    case types.PURCHASE_RESET_LOCATIONS_BY_ZIP:
      return { ...store, locationsByZip: false, loaderLocations: false };
    case types.PURCHASE_SET_PERSONAL_INFO:
      return { ...store, personalInfo: action.data };
    case types.PURCHASE_SET_CARD_DATA:
      return { ...store, cardData: action.card || {
        cvv: false,
        month: false,
        name: false,
        number: false,
        save: false,
        type: false,
        year: false,
      }, tokenCard: action.card?.id || false, loader: false };
    case types.PURCHASE_CLEAR_CARD_TOKEN:
      return {
        ...store,
        tokenCard: false,
        tokenizer_error: false,
        cardData: {
          cvv: false,
          month: false,
          name: false,
          number: false,
          save: false,
          type: false,
          year: false,
        },
      };
    case types.PURCHASE_MAKE_PAYMENT_OXXO:
      return { ...store, paymentOXXO: action.paymentOXXO, loader: false };
    case types.PURCHASE_MAKE_PAYMENT_PAYPAL:
      return { ...store, paymentPaypal: action.paymentPaypal, paymentMethod: 'paypal', loader: false };
    case types.PURCHASE_MAKE_PAYMENT_LOYALTY:
      return { ...store, paymentLoyalty: action.paymentLoyalty, paymentMethod: 'loyalty', loader: false };
    case types.PURCHASE_LOYALTY_GET:
      return { ...store, loyalty: action.loyalty };
    case types.PURCHASE_SET_LOYALTY_AMOUNT:
      return { ...store, loyalty_points: action.amount };
    case types.PURCHASE_LOADER_TOKENIZE:
      return { ...store, loaderTokenize: action.loader || false}
    case types.PURCHASE_SET_MSI:
      return { ...store, msi: action.msi}
    case types.PURCHASE_COMPLETE_ANALYTICS_DATA:
      return {...store, purchaseCompleteAnalytics: action.data || false}
    case types.PURCHASE_CLEAR_DATA:
    case types.RESET:
    case types.PURCHASE_RESET:
      return { ...initialStore, purchaseCompleteAnalytics: {...store.purchaseCompleteAnalytics} };
    default:
      return { ...store };
  }
};

export default reducer;
