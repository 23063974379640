import { motion } from 'framer-motion';
import React from 'react';
import { categoriesAnim } from '../../helpers/choreography/category';
import CategoryHeader from '../../components/Categories/Box/CategoryHeader';
import CategoriesFlow from '../../components/Categories/Box/Categories';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';

const Categories = () => {
    return (
        <ContainerBoxPage className='category-box-page'>
            <motion.section
                variants={categoriesAnim.layout}
                transition={{ ease: 'easeInOut', delay: 0.2, duration: 0.4, }}
                initial='hidden'
                animate='visible'
                exit='hidden'
                className="categos"
            >
                <div className="panel">
                    <CategoryHeader />
                    <CategoriesFlow />
                </div>
            </motion.section>
        </ContainerBoxPage>
    )
}

export default Categories
