export const types = {
  GET_FAVORITES: 'GET_FAVORITES',
  GET_FAVORITES_LOADER: 'GET_FAVORITES_LOADER',
  UPDATE_FAVORITE: 'UPDATE_FAVORITE',
  RESET: 'RESET',
};

const initialStore = {
  favorites: [],
  products: [],
  favorites_loader: false
};

const loadLocal = () => {
  const local = window.sessionStorage.getItem('favorites') || '[]';
  return JSON.parse(local);
};
const loadLocalProducts = () => {
  const local = window.sessionStorage.getItem('favorites_products') || '[]';
  return JSON.parse(local);
};

const saveLocal = (data) => window.sessionStorage.setItem('favorites', JSON.stringify(data));
const saveLocalProducts = (data) => window.sessionStorage.setItem('favorites_products', JSON.stringify(data));

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.UPDATE_FAVORITE:
      let updateFavorites = [...store.favorites];
      const findIndexToRemove = updateFavorites.indexOf(action.id);
      if (findIndexToRemove > -1) {
        updateFavorites.splice(findIndexToRemove, 1);
      } else {
        updateFavorites = [...updateFavorites, action.id];
      }
      saveLocal(updateFavorites);
      return { ...store, favorites: updateFavorites };
    case types.RESET:
      window.sessionStorage.removeItem('favorites_products');
      return { ...initialStore }
    case types.GET_FAVORITES:
      saveLocalProducts(action.payload);
      return { ...store, products: action.payload };
    case types.GET_FAVORITES_LOADER:
      return { ...store, favorites_loader: action.payload };
    default:
      return { favorites: loadLocal(), products: loadLocalProducts(), favorites_loader: store.favorites_loader };
  }
};

export default reducer;
