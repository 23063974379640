export const productAnimations = {
    loaderAnimation: {
        visible: {
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
    },
    emptyBox: {
        hidden: {
            opacity: 0,
            y: 10
        },
        visible: {
            opacity: 0.51,
            y: 0
        }
    },
    rightProd: {
        hidden: {
            y: 40
        },
        visible: {
            y: 0
        }
    },
    leftProd: {
        hidden: {
            y: 40
        },
        visible: {
            y: 0
        }
    }
}