import React from 'react';

import CropImage from '../CropImage';
import TakePhotoCamera from '../TakePhotoCamera';
import { connect } from 'react-redux';
import ErrorUploadImage from '../UserMenu/ErrorUploadImage';

class InputImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      camera: false,
      photo: false,
      tmpPhoto: false,
      tokePhoto: false,
      selectMethod: false,
      error: false,
    };
  }

  cancel = () => this.setState({ camera: false, photo: false, tmpPhoto: false, takePhoto: false, selectMethod: false });

  openSelectMethod = () => this.setState({ selectMethod: true });

  setTakePhoto = () => this.setState({ takePhoto: true, selectMethod: false });

  getPhotoFile = (event) => {
    const file = event.currentTarget.files[0];
    if (!file) return;
    const fileSizeCheck = file.size / 1024 / 1024 <= 12;
    if (!fileSizeCheck) {
      this.setState({ error: true });
      return;
    }
    const loadFile = new FileReader();
    loadFile.readAsDataURL(file);
    loadFile.onload = () => this.setState({ tmpPhoto: loadFile.result, selectMethod: false, photo: false });
    loadFile.onerror = () => console.warn('no se puede cargar');
    this.props.cropper(true);
    document.querySelector('body').classList.add('no-close');
  };

  capture = (photo) => {
    this.setState({ tmpPhoto: photo, takePhoto: false });
  };

  savePhoto = (photo) => {
    this.setState({ photo, tmpPhoto: false });
    this.props.save(photo);
    this.props.cropper(false);
  };

  calcelCrop = () => {
    const inputFile = document.getElementById('select-photo');
    if (inputFile) {
      inputFile.value = null;
    }
    this.setState({ tmpPhoto: false, photo: false });
    this.props.cropper(false);
  };

  remove = () => {
    this.setState({ photo: false });
    this.props.save(false);
  };

  closeError = () => this.setState({ error: false });

  render() {
    return (
      <div className='input-image'>
        {((!this.state.camera && !this.state.photo && !this.state.tmpPhoto) || (this.props.isMobile && !this.state.photo)) && (
          <div>
            <label className={`upload-image only`}>
              <input id='select-photo' type='file' name='image' accept='image/png, image/jpeg' onChange={(event) => this.getPhotoFile(event)} />
              <i className="icon-camera" style={{backgroundImage: `url(${this.props.src})`, backgroundSize: 'cover'}}></i>
            </label>
            {this.state.error && <ErrorUploadImage close={() => this.closeError()} />}
          </div>
        )}

        {this.state.camera && !this.state.takePhoto && !this.state.selectMethod && !this.state.tmpPhoto && !this.state.photo && (
          <span className='upload-image icon-select-image' onClick={() => this.openSelectMethod()}>
            <i className='icon-camera'></i>
          </span>
        )}

        {this.state.camera && this.state.selectMethod && !this.state.photo && !this.state.tmpPhoto && (
          <div className='select-method'>
            <p>Selecciona un método para cargar tu imagen:</p>
            <div className='methods-image'>
              <div className='use-camera'>
                <span className='upload-image icon-select-image' onClick={() => this.setTakePhoto()}>
                  <i className='icon-camera'></i>
                </span>
                <p>Usar la cámara del dispositivo.</p>
              </div>
              <div className='use-image-device'>
                <label className={`upload-image${this.state.loader ? ' loader-img' : ''}`}>
                  <input type='file' id='select-photo' name='image' accept='image/png, image/jpeg' onChange={(event) => this.getPhotoFile(event)} value={this.state.tmpPhoto} />
                  <i className='icon-camera'></i>
                </label>
                <p>Usar una imagen del dispositivo.</p>
              </div>
            </div>
            <p className='image-features'>
              Formatos permitidos: JPG, JGPE y PNG.
              <br />
              Péso máximo: 2MB.
            </p>
          </div>
        )}

        {this.state.takePhoto && !this.state.tmpPhoto && !this.state.photo && <TakePhotoCamera capture={(photo) => this.capture(photo)} />}

        {this.state.tmpPhoto && !this.state.photo && this.props.isMobile && <div className='bg-cropper'></div>}
        {this.state.tmpPhoto && !this.state.photo && (
          <CropImage photo={this.state.tmpPhoto} save={(photo) => this.savePhoto(photo)} cancel={() => this.calcelCrop()} close={() => this.props.close()} />
        )}

        {this.state.photo && (
          <>
          <div className='avatar-final'>
            <img src={this.state.photo} alt='avatar' onClick={() => this.remove()} />
          </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    cropper: (cropper) => dispatch({ type: 'USER_CROPPER', cropper }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(InputImage);
