import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userGetAddresses, userSetAddress } from '../../store/actions/user';
import Loader from '../Loader';
import AddressCard from './AddressCard';
import { unloadAddresses } from './../../store/actions/user';

const AddressList = () => {
  const init = useRef(false);
  const dispatch = useDispatch();

  const handleOpenForm = () => {
    dispatch(userSetAddress(true));
    window.scroll(0, 0);
  };

  const { loaderAddresses, addresses, address } = useSelector((state) => state.user);

  useEffect(() => {
    if(loaderAddresses && !addresses.length && !address && !init.current){
      dispatch(userGetAddresses())
      init.current = true;
    }
  }, [address, addresses, dispatch, loaderAddresses])

  useEffect(() => {
    return () => {
      dispatch(userSetAddress(false));
      dispatch(unloadAddresses());
      init.current = false;
    }
  }, [dispatch])

  if (address) return '';

  return (
    <>
      {loaderAddresses && <Loader spinner={{ title: 'Cargando...' }} blue />}
      {!loaderAddresses && !!addresses.length && (
        <>
          <h1 className='title-section'>Mis direcciones</h1>
          <div className='addresses-list'>
            {addresses.map((address, addressIndex) => (
              <AddressCard address={address} key={addressIndex} />
            ))}
          </div>
          <div className='add-address'>
            <span className='link' onClick={() => handleOpenForm(true)}>
              <i className='icon-plus-circle'></i> Agregar dirección
            </span>
          </div>
        </>
      )}
      {!loaderAddresses && !addresses.length && (
        <div className='not-found-address'>
          <i className="icon-pin"></i>
          <strong>Aún no tienes direcciones agregadas.</strong>
          <button onClick={() => handleOpenForm(true)} className='btn btn-primary rounded-small'>AGREGAR DIRECCIÓN</button>
        </div>
      )}
    </>
  );
};

export default React.memo(AddressList);
