import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCategories } from './../../store/actions/categories';

let containerScrollY = false;
let bodyScrollPosition = false;

const CategoriesFilterMobile = () => {
  const { categories } = useSelector((state) => state.categories);

  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [init, setInit] = useState(false)

  const container = useRef(false);
  const dispatch = useDispatch();

  const { categoryID } = useParams();

  useEffect(() => {
    if (init) return;
    setInit(true);
    const inRootCategories = categories.find(category => category.id === parseInt(categoryID));

    if (inRootCategories) {
      return setMenu(categoryID);
    }

    categories.some(category => {
      const inSubCategory = category.subcategories.find(item => item.id === parseInt(categoryID))
      if (inSubCategory) {
        setMenu(inSubCategory.parent_id);
        return true;
      }
      return false;
    })
  }, [init, categories, categoryID])

  const openMenu = (event, id) => {
    if (menu === id) {
      setMenu(false);
    } else {
      const element = event.currentTarget;
      setTimeout(() => {
        element.scrollIntoView({ alignToTop: true, behavior: 'smooth' })
      }, 500)
      setMenu(id);
    }
  };

  const enableMenu = (status) => {
    const body = document.querySelector('body');
    if (open) {
      body.classList.remove('no-scroll');
      window.scroll(0, bodyScrollPosition);
    } else {
      bodyScrollPosition = window.scrollY;
      body.classList.add('no-scroll');
    }
    if (status === -1) {
      window.scroll(0, 0);
      setOpen(false);
      return
    }
    setOpen(status);
  };

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!container.current) return;

    const isFixed = () => {
      if (container.current.offsetTop > 0) {
        containerScrollY = container.current.offsetTop + 70 + 24;
      }
      if (window.scrollY >= containerScrollY) {
        container.current.classList.add('fixed');
      } else {
        container.current.classList.remove('fixed');
      }
    };

    window.addEventListener('scroll', isFixed, { passive: true });
    return () => {
      window.removeEventListener('scroll', isFixed);
    };
  }, [container]);

  return (
    <section id='categories-filter-mobile' ref={container}>
      <Link to='/categorias' className='btn btn-white'>
        Todos los productos
      </Link>
      <span className='btn btn-white btn-outline' onClick={() => enableMenu(true)}>
        Categorías <span className='icon-down-open'></span>
      </span>

      <div className={`categories-list-mobile${open ? ' enabled' : ''}`}>
        <h1>
          Categorías <span className='icon-close' onClick={() => enableMenu(false)}></span>
        </h1>
        <ul className='categories-items'>
          {categories.map((category, categoryIndex) => (
            <li key={categoryIndex} className={menu === category.id ? 'open' : ''}>
              {!!category.subcategories.length ? (
                <div onClick={(event) => openMenu(event, category.id)}>
                  <span>{category.name}</span>
                  <i className='icon-down-open'></i>
                </div>
              ) : (
                <Link to={`/categoria/${category.id}/${category.code}`} onClick={() => enableMenu(false)}>
                  {category.name}
                </Link>
              )}
              {!!category.subcategories.length && (
                <ul className='subcategories-items'>
                  <li className={parseInt(categoryID) === category.id ? 'active' : ''}>
                    <Link to={`/categoria/${category.id}/${category.code}`} onClick={() => enableMenu(-1)}>
                      Ver todos <span>({category.product_quantity})</span>
                    </Link>
                  </li>
                  {category.subcategories.map((subcategory, subcategoryIndex) => (
                    <li key={subcategoryIndex} className={parseInt(categoryID) === subcategory.id ? 'active' : ''}>
                      <Link onClick={() => enableMenu(-1)} to={`/categoria/${subcategory.id}/${subcategory.code}`}>
                        {subcategory.name} <span>({subcategory.product_quantity})</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CategoriesFilterMobile;
