import React, { Fragment } from 'react';
import PerfectScroll from './PerfectScroll';

class Modal extends React.Component {
  close = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  componentDidMount() {
    if (!window.ownModal) {
      if (this.props.close) {
        window.addEventListener('click', (event) => {
          const { target } = event;
          if (target === this.modal) {
            this.props.close();
          }
        });
      }
    }
  }

  scrollEnabled = () => this.props.scroll !== undefined ? this.props.scroll : true;

  render() {
    if (!this.props.children) return '';

    return (
      <section className={`modal${this.props.open ? ' active' : ' disable'} ${this.props.className || ''}`} ref={(el) => (this.modal = el)}>
        <div className={`modal-content${this.scrollEnabled() ? '' : ' with-padding'}`}>
          {!!this.scrollEnabled() && (
            <PerfectScroll className={`${this.props.open ? ' active' : ' disable'} update-${Math.random()}`}>
              {this.props.close && (
                <span className='btn-close' onClick={() => this.close()}>
                  <i className='icon-close'></i>
                </span>
              )}
              {this.props.children}
            </PerfectScroll>
          )}
          {!this.scrollEnabled() && (
            <Fragment>
              {this.props.close && (
                <span className='btn-close' onClick={() => this.close()}>
                  <i className='icon-close'></i>
                </span>
              )}
              {this.props.children}
            </Fragment>
          )}
        </div>
      </section>
    );
  }
}

export default React.memo(Modal);
