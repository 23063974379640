import axiosApi from '../axiosAPI';
import { types } from '../reducers/search';
import { getCategories } from './categories';

const updateFilters = (filters, filter) => {
  if (!filters) return {};
  if (!filter) return filters;
  if (filter.type === 'price') {
    const checkPrice = filters.price && (filters.price?.min === filter.price?.min && filters.price?.max === filter.price?.max);
    if (checkPrice) {
      delete filters.price;
    } else {
      filters.price = filter;
    }
  } else {
    const find = filters[filter.type] && filters[filter.type].id === filter.id;
    if (find) {
      delete filters[filter.type];
    } else {
      filters[filter.type] = filter;
    }
  }

  return filters;
};

const searchFiltersURI = (filters) => {
  const filter_names = Object.keys(filters);
  if (!filter_names.length) return '';

  let URI = '';
  Object.keys(filters).forEach((key) => {
    if (key === 'price' || key === 'prices') {
      URI += `&minPrice=${filters[key].min}&maxPrice=${filters[key].max}`;
    } else {
      URI += `&${key}=${filters[key].id}`;
    }
  });

  return URI;
};

const searchGetProducts = (offset = 0, limit = 50, keyword, filters) => {
  return axiosApi(`v1/products/?search=${keyword.trim()}${searchFiltersURI(filters)}&limit=${limit}&offset=${offset}`);
}

export const search = (keyword, page = false, filter = false) => async (dispatch, getStore) => {
  let filtersArr = false;
  const currentFilters = { ...getStore().search.filters };

  if (page === 'reload_w_filters') {
    filtersArr = { ...getStore().search.filters_active }
  }

  if (page) {
    dispatch({ type: types.SEARCH_RESET });
  }
  if (!getStore().categories.categories || !getStore().categories.categories.length) {
    await dispatch(getCategories());
  }

  dispatch({ type: types.SEARCH_LOADER, loader: true });
  const filters = updateFilters((filtersArr || getStore().search.filters_active), filter);
  const limit = page ? 50 : 15;
  const catalog_id = parseInt(getStore().categories.sector) || getStore().user.profile?.catalog?.id || 3;
  if (catalog_id) {
    filters.catalog_id = { id: catalog_id };
  }
  const results = await searchGetProducts(0, limit, keyword, filters);

  if (results.data.status_code === 100 || results.data.status_code === 3) {
    return dispatch({ type: types.SEARCH_ERROR, error: results.data, keyword, filters_active: filters, page, filters: currentFilters });
  }

  return dispatch({
    type: types.SEARCH,
    keyword: keyword,
    results: page ? [] : results.data.products,
    products: page ? results.data.products : getStore().search.products,
    filters: (page && page !== 'reload_w_filters') ? { ...results.data.filter, sorts: results.data.sort_by } : currentFilters,
    filters_active: filters,
    categories: [],
    product_quantity: results.data.product_quantity
  });
};

let inProcess = false;

export const searchGetResults = ({ keyword = false, reload = false, append = false }) => async (dispatch, getStore) => {
  if (inProcess) return;
  inProcess = true;
  setTimeout(() => {
    inProcess = false;
  }, 1000);

  if (!getStore().categories.categories || !getStore().categories.categories.length) {
    dispatch(getCategories());
  }

  dispatch({ type: types.SEARCH_LOADER, loader: true });

  const search = keyword || getStore().search.keyword;
  let offset = 0;
  let limit = 20;
  let filters = getStore().filters.active;
  if (append) {
    const { products } = getStore().search;
    offset = products.length;
    limit = 15;
  }

  const catalog_id = parseInt(getStore().categories.sector) || getStore().user.profile?.catalog?.id || 3;

  const response = await axiosApi(`v1/products/?search=${search.trim()}${searchFiltersURI(filters)}&limit=${limit}&offset=${offset}&catalog_id=${catalog_id}`);

  if (response.data.status_code === 100 || response.data.status_code === 3) {
    return dispatch({ type: types.SEARCH_ERROR, error: response.data, keyword, filters_active: filters, page: true, filters: false });
  }

  if (reload) {
    const newFilters = { ...response.data.filter, sorts: response.data.sort_by };
    delete newFilters.prices;
    dispatch({ type: 'FILTERS_SET_SEARCH', search: newFilters })
    dispatch({ type: types.SEARCH_SET_PRODUCTS, products: response.data.products, product_quantity: response.data.product_quantity, keyword: search })
  } else if (append) {
    const products = getStore().search.products;
    dispatch({ type: types.SEARCH_SET_PRODUCTS, products: [...products, ...response.data.products] })
  } else {
    dispatch({ type: types.SEARCH_SET_PRODUCTS, products: response.data.products, product_quantity: response.data.product_quantity })
  }



}

export const searchLoadMoreProducts = () => async (dispatch, getStore) => {
  const { products, keyword, filters_active: filters } = getStore().search

  dispatch({ type: types.SEARCH_LOADER, loader: true });
  const response = await searchGetProducts(products.length, 15, keyword, filters);

  if (response.data.status_code === 100) {
    return;
  }

  dispatch({ type: types.SEARCH_LOAD_PRODUCTS, data: response.data })
}

export const openSearch = (open) => (dispatch) => dispatch({ type: types.SEARCH_OPEN, open });

export const searchSetPage = () => (dispatch) => dispatch({ type: types.SEARCH_SET_PAGE });

export const setFilters = (filter, replace = false) => (dispatch, getStore) => {
  if (replace) {
    return dispatch({ type: types.SEARCH_SET_FILTERS, filters: filter });
  }
  const filters = updateFilters({ ...getStore().search.filters_active }, filter);
  return dispatch({ type: types.SEARCH_SET_FILTERS, filters });
};

export const searchReset = () => (dispatch) => {
  dispatch({ type: types.SEARCH_RESET });
};
