import React from 'react'
import classNames from 'classnames';
import useIsMobile from '../../hooks/useIsMobile';
import { AnimatePresence, motion } from 'framer-motion';
import { buttons } from '../../helpers/choreography/buttons';
import Loader from '../Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export function AppButton(props) {
    const {
        type,
        txt,
        icon,
        modifier,
        onClick,
        customClick,
        available,
        id,
        url,
        handler,
        loader,
        isDisabledButton,
        refValue,
        small,
        lato,
        main,
        outline,
        ...rest
    } = props

    const isMobile = useIsMobile();

    const handleClick = (e) => {
        if (onClick) {
            onClick(e)
        } else if(customClick) {
            customClick()
        }
    }

    const handleClickCustomEvent = () => {
        if (id) {
            customClick(id)
        } else {
            customClick()
        }
    }

    if (type === 'icon') {
        return (
            <button className={'btn btn-icon btn-icon--' + modifier} onClick={handleClickCustomEvent} disabled={isDisabledButton}>
                <span className={'icon-' + icon}></span>
            </button>
        )
    }
    if (type === 'purchase') {
        return (
            isMobile
                ?
                <button
                    className={classNames({
                        'btn': true,
                        'btn-shipping-button': true,
                        'btn-shipping-button--selected': handler
                    })}
                    onClick={handleClick}
                >
                    <motion.div
                        variants={buttons.moveImg}
                        animate={handler ? 'moved' : 'origin'}
                        transition={{
                            ease: 'easeInOut',
                            delay: .2,
                            duration: .2
                        }}
                        className={'btn-shipping-button-img btn-shipping-button--' + modifier}>
                        <img src={url} alt={modifier} />
                    </motion.div>
                    <AnimatePresence exitBeforeEnter>
                        {
                            handler && (
                                <motion.div
                                    variants={buttons.closeButton}
                                    transition={{
                                        ease: 'easeInOut',
                                        delay: .2,
                                        duration: .2
                                    }}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    className="btn-shipping-button-action icon-cancel-circle">
                                </motion.div>
                            )
                        }
                    </AnimatePresence>
                </button>
                :
                <button
                    className={classNames({
                        'btn': true,
                        'btn-shipping-button': true,
                        'btn-shipping-button--selected': handler
                    })}
                    onClick={handleClick}
                >
                    <motion.div
                        variants={buttons.moveImg}
                        animate={handler ? 'moved' : 'origin'}
                        transition={{
                            ease: 'easeInOut',
                            delay: .2,
                            duration: .2
                        }}
                        className={'btn-shipping-button-img btn-shipping-button--' + modifier}>
                        <img src={url} alt={modifier} />
                    </motion.div>
                    <motion.div
                        variants={buttons.closeButton}
                        transition={{
                            ease: 'easeInOut',
                            delay: .2,
                            duration: .2
                        }}
                        initial="hidden"
                        animate={handler ? 'visible' : 'hidden'}
                        exit="hidden"
                        className="btn-shipping-button-action icon-ok">
                    </motion.div>
                </button>
        )
    }
    if (type === 'connect') {
        return (
            <button ref={refValue} className={
                classNames({
                    'btn': true,
                    'btn--disabled': !available,
                    'btn--connect': type === 'connect',
                    'btn--connect-google': modifier === 'google'
                })
            } disabled={!available} onClick={handleClick} >
                {isMobile ?
                    txt
                    :
                    icon !== 'google' ?
                        <span className={'icon-' + icon}>
                            {txt}
                        </span>
                        :
                        <>
                            <span className="icon-square">
                                <img src='/img/ico-google-plus.svg' alt={icon} />
                            </span>
                            {txt}
                        </>
                }
            </button>
        )
    }
    else if (type === 'link'){
        return (
            <Link className={
                classNames({
                    'btn': true,
                    'btn--main': type === 'main' || main,
                    'btn--outlined': type === 'outlined' || outline,
                    'btn--main-blue': type === 'main-blue',
                    'btn--disabled': !available,
                    'btn--is-loading': loader,
                    'rounded-small': small,
                    'lato': lato,

                }, modifier)
            } disabled={!available} onClick={handleClick} {...rest}>
                {!loader && txt}
                {loader && <Loader spinner blue />}
            </Link>
        )
    }
    else {
        return (
            <button className={
                classNames({
                    'btn': true,
                    'btn--main': type === 'main',
                    'btn--outlined': type === 'outlined',
                    'btn--main-blue': type === 'main-blue',
                    'btn--disabled': !available,
                    'btn--is-loading': loader,
                    'rounded-small': small,
                    'lato': lato
                })
            } disabled={!available} onClick={handleClick}>
                {!loader && txt}
                {loader && <Loader spinner blue />}
            </button>
        )
    }
}

export default AppButton
