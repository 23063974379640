import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { contacts } from '../../helpers/choreography/contacts'
import useIsMobile from '../../hooks/useIsMobile'

const Contacts = () => {
    const isMobile = useIsMobile()
    const { phone } = useSelector((state) => state.home)
    const { mobile, office } = phone
    const officeNumber = office?.number.split(' ').join('').replace(/[^0-9]/g, '')
    const mobileNumber = mobile?.number.split(' ').join('').replace(/[^0-9]/g, '')
    const [show, setShow] = useState(false)

    const showContacts = () => {
        setShow(!show)
    }

    return (
        <aside className="contacts">
            {isMobile && (
                <div className={classNames({
                    "contacts-link": true,
                    "contacts-link--open": show
                })} onClick={showContacts}>
                    {!show && <span className="icon-contacto"></span>}
                    {show && <span className="icon-close-light"></span>}
                </div>
            )}
            {!isMobile && (
                <motion.div
                    variants={isMobile ? false : contacts.openBtn}
                    transition={{
                        ease: 'easeInOut',
                        delay: isMobile ? .2 : 0,
                        duration: .2
                    }}
                    initial="hidden"
                    animate={show ? 'visible' : 'hidden'}
                    exit="hidden"
                    className={classNames({
                        "contacts-link": true,
                        "contacts-link--open": show
                    })} onClick={showContacts}>
                    {!show && <span className="icon-contacto"></span>}
                    {show && <span className="icon-close-light"></span>}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 168">
                        <g>
                            <g>
                                <path className="cls-1" width="2em" d="M80,0C78.07,20.14,68.44,37.29,40.13,42.39,40.13,42.39,0,47,0,84s40.13,41.61,40.13,41.61C68.44,130.71,78.07,147.87,80,168Z" />
                            </g>
                        </g>
                    </svg>
                </motion.div>
            )}
            <AnimatePresence exitBeforeEnter>
                {show && (
                    <motion.div
                        variants={isMobile ? contacts.showOverlay : contacts.showList}
                        transition={{
                            ease: 'easeInOut',
                            delay: isMobile ? .2 : 0,
                            duration: .2
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="contacts-list">
                        {!isMobile && show && (
                            <motion.p
                                variants={contacts.upButton}
                                transition={{
                                    ease: 'easeInOut',
                                    delay: .4,
                                    duration: .1
                                }}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                className="mxp-text">
                                ¿Tienes alguna duda?, ¿algún pedido especial? ¡Contáctanos!
                            </motion.p>
                        )}

                        <motion.a
                            variants={contacts.upButton}
                            transition={{
                                ease: 'easeInOut',
                                delay: .2,
                                duration: .1
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            rel="noopener noreferrer"
                            href={`tel:${officeNumber}`} className="contacts-list-item contacts-list-item--phone">
                            <span className={office.icon}></span>
                            {!isMobile && (<>Llámanos</>)}
                        </motion.a>
                        <motion.a
                            variants={contacts.upButton}
                            transition={{
                                ease: 'easeInOut',
                                delay: .3,
                                duration: .1
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            href={`https://wa.me/52${mobileNumber}`}
                            className="contacts-list-item contacts-list-item--whatsapp"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/img/logo-whatsapp.svg" alt={mobile.icon} />

                            {!isMobile && (<>Whatsapp</>)}
                        </motion.a>
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter>
                {show && (
                    <motion.div
                        variants={contacts.showOverlay}
                        transition={{
                            ease: 'easeInOut',
                            delay: .2,
                            duration: .2
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={() => {
                            setShow(false)
                        }}
                        className="contacts-overlay"></motion.div>
                )}
            </AnimatePresence>
        </aside>
    )
}

export default Contacts
