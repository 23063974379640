export const types = {
  BOX_OPEN: 'BOX_OPEN',
  BOX_DETAIL: 'BOX_DETAIL',
  BOX_INCREASE: 'BOX_INCREASE',
  BOX_GET: 'BOX_GET',
};

const initialStore = {
  boxes: false,
  box: false,
  detail: false,
  percent: 70,
  confirmation: {

  }
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.BOX_OPEN:
      return { ...store, box: action.box || false };
    case types.BOX_DETAIL:
      return { ...store, detail: action.detail || false };
    case types.BOX_INCREASE:
      const changePercent = store.percent < 100 ? store.percent + action.percent : 0;
      return { ...store, percent: changePercent}
    case types.BOX_GET:
      return {...store, boxes: action.boxes || false}
    default:
      return { ...store };
  }
};

export default reducer;
