import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartGetContainers } from '../../store/actions/cart';
import Loader from '../Loader';
import SliderBoxes from './SliderBoxes';

const BoxesList = ({ close }) => {
  const dispatch = useDispatch();
  const { containers_list } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!containers_list) {
      dispatch(cartGetContainers());
    }
  }, [dispatch, containers_list]);

  const handleClose = (event) => {
    if (close && event.target.id === 'boxes-list') {
      close();
    }
  };

  let containers_list_temp = [];

  for (let i = 0; i < containers_list.length; i++) {
    const element = containers_list[i];
    if (element && element.is_principal_group === true) {
      containers_list_temp.push(element);
    }
  }

  return (
    <section id='boxes-list' onClick={handleClose} style={{opacity: 0}}>

      <div className="boxes-list-content">
        <h1>Caja</h1>
        <p>
          Cambiará para adaptarse a tu compra y fijar un precio de envío.
          ¡Aprovecha la capacidad de cada caja para ahorrar al máximo!
        </p>
        {!containers_list_temp && <Loader ellipsis />}
        {containers_list_temp && <SliderBoxes boxes={containers_list_temp} />}
      </div>
    </section>
  );
};

export default BoxesList;
