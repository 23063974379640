import React from 'react';
import classNames from 'classnames';

const InputCheckbox = ({ selected, item, items, className, onChange, register, name, white }) => {

  const send = (item) => {
    if (!onChange) return;
    const value = selected ? false : (item.value || item);
    onChange(value);
  };

  if (!item && !items) return '';
  return (
    <label className={
    classNames('input-radio', className, {
      white: white,
      active: selected,
    })
    } onClick={() => send(item)}>
      {register && <input type="checkbox" {...register(name)} checked={selected} />}
      <div className='icon'>
        <i></i>
      </div>
      <div className='label'>{item.label || item}</div>
    </label>
  );
};

export default InputCheckbox;
