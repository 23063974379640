import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { modalAnimations } from '../../helpers/choreography/modal-box';
import { useEffect } from 'react';

const body = document.querySelector('body')

function ModalBox({ children, close, ...rest }) {
    const {
        show,
        modifier,
        animType
    } = rest;

    useEffect(() => {
        const clickOutside = (event) => {
            if(close && event.target.classList.contains('mb-body')){
                close()
            }
        }
        window.addEventListener('click', clickOutside);
        if(show){
            body.classList.add('no-scroll');
        } else{
            body.classList.remove('no-scroll');
        }
        return () => {
            body.classList.remove('no-scroll');
            window.removeEventListener('click', clickOutside);
        }
    }, [show, close])

    return (
        <AnimatePresence exitBeforeEnter>
            {show && (
                <div className={`mb-wrapper ${modifier}`}>
                    <div className="mb-body">
                        <motion.div
                            variants={animType === 'enterSide' ? modalAnimations.bodyAnimSide : modalAnimations.bodyAnim}
                            transition={{
                                ease: 'easeInOut',
                                delay: .2,
                                duration: .2
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            className="mb-container">
                            {children}
                        </motion.div>
                    </div>
                    <motion.div
                        variants={animType === 'enterSide' ? modalAnimations.bgAnimationAside : modalAnimations.bgAnimation}
                        transition={{
                            ease: 'easeInOut',
                            duration: .2
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        className="mb-bg"></motion.div>
                </div>
            )}
        </AnimatePresence>
    )
}

export default ModalBox
