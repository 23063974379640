import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { headerAnimations } from '../../helpers/choreography/header';
import useIsMobile from '../../hooks/useIsMobile';
import { setSector } from '../../store/actions/categories';
import { closeSession, setUserOpen, userMenuControl } from '../../store/actions/user';
import Loader from '../Loader';
import MoneyFormat from '../MoneyFormat';
import AppButton from '../UI/Buttons';
import classNames from 'classnames';
import { resetFavorites } from '../../store/actions/favorites';
import { segmentoAnalytics } from '../../store/actions/analytics-events';

const menuLinks = [
  {
    name: 'Mi perfil',
    icon: 'icon-login',
    to: '/perfil/mi-perfil',
    private: true,
  },
  {
    name: 'Mis compras',
    icon: 'icon-box-open-1',
    to: '/perfil/mis-compras',
    private: true,
  },
  {
    name: 'Mis favoritos',
    icon: 'icon-heart-1-empty',
    to: '/perfil/mis-favoritos',
    private: true,
  },
  {
    name: 'Tutoriales',
    icon: 'icon-play',
    to: '/perfil/tutoriales',
  },
  {
    name: 'Monedero',
    icon: 'icon-card-2',
    to: '/perfil/monedero',
    code: 'monedero',
    private: true,
  },
];

// Mobile user navigation
const UserMenu = () => {
  const ref = useRef();
  const body = document.querySelector('body');
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { logged, profile, loader, layer, login_desktop } = useSelector((state) => state.user);
  const { catalogs } = useSelector((state) => state.home);
  const { sector } = useSelector((state) => state.categories);
  const {
    profile: { catalog },
  } = useSelector((state) => state.user);

  const currentSector = parseInt(sector) || catalog || 3;

  const handleSectors = (option) => {
    dispatch(setSector(option.id));
    dispatch(userMenuControl(false, false));
    segmentoAnalytics(option.code)
    body.classList.remove('no-scroll');
  };

  const handleCloseSession = () => {
    dispatch(userMenuControl(true, 'user-menu'));
    dispatch(closeSession());
    dispatch(resetFavorites())
  };

  const goLink = (event) => {
    if (!logged) {
      event.preventDefault();
      handleLogin();
      return;
    } else {
      dispatch(userMenuControl(false, false, false));
      dispatch(setUserOpen(false));
    }
  };
  const handleLogin = () => {
    dispatch(userMenuControl(true, 'user-login', 'top'));
  };

  const handleRegistrationLink = () => {
    dispatch(userMenuControl(false, false));
    body.classList.remove('no-scroll');
    setTimeout(() => {
      history.push('/box/registro');
    }, 400);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (login_desktop && ref.current && !ref.current.contains(e.target)) {
        dispatch(setUserOpen(!login_desktop));
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dispatch, login_desktop]);

  return (
    <>
      {isMobile && (
        <div className='header-overlay-content'>
          <motion.div
            variants={headerAnimations.showMenu}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
            initial='hidden'
            animate={layer ? 'visible' : 'hidden'}
            exit='hidden'
            className='user-menu-overflow'
            ref={ref}>
            <div className='user-menu user-menu-wrapper'>
              <nav className='user-header'>
                <div className='user-logo'>
                  <img src='/img/lg-mxp-color@2x.png' alt='Más x Pieza' />
                </div>
                <AppButton
                  type='icon'
                  icon='close'
                  modifier='close'
                  customClick={() => {
                    dispatch(userMenuControl(false, false));
                    body.classList.remove('no-scroll');
                  }}
                />
              </nav>
              <section className={`user-menu-activity ${loader ? 'user-menu-activity--loading' : ''} ${logged ? 'user-menu-activity--logged' : 'user-menu-activity--user'}`}>
                {loader && <Loader spinner={{ title: 'Cargando' }} blue />}
                {!loader && (
                  <>
                    {logged && (
                      <div className={`avatar${profile.avatar ? ' active' : ''}`}>
                        <figure className={`avatar-img ${!profile.avatar ? 'no-image' : ''}`}>
                          <img src={profile?.avatar || '/img/ico-avatar-generico.svg'} alt={profile?.user_name} />
                        </figure>
                        <div className='avatar-data'>
                          <div className='avatar-data-desk'>
                            <p className='mxp-txt avatar-data-hi'>¡Hola!</p>
                            <p className='mxp-txt avatar-data-name'>{profile.user_name}</p>
                          </div>
                          <p className='mxp-txt avatar-data-close' onClick={handleCloseSession}>
                            Cerrar sesión
                          </p>
                        </div>
                      </div>
                    )}
                    <ul className='user-navigation-options'>
                      {!logged && (
                        <li className='user-options-item' onClick={handleLogin}>
                          <span>
                            <i className='icon-login'></i>
                            Iniciar sesión
                          </span>
                        </li>
                      )}
                      {menuLinks.map((item, itemIndex) => {
                        if (item.private && !logged) return '';
                        return (
                          <li key={itemIndex} className={
                            classNames('user-options-item', {
                              wallet: item.code === 'monedero'
                            })
                          }>
                            <Link to={item.to}>
                              <i className={item.icon}></i>
                              <div>
                                {item.name}
                                {item.code === 'monedero' && (
                                  <span className='loyalty'>
                                    <MoneyFormat money={profile.loyalty?.points || 0} />
                                  </span>
                                )}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <ul className='user-navigation-sectors'>
                      <li className='label'>Ver productos de:</li>
                      {catalogs.map((catalog, catalogIndex) => {
                        return (
                          <li className={`user-options-item ${catalog.id === currentSector ? 'user-options-item--active' : ''}`} onClick={() => handleSectors(catalog)} key={catalogIndex}>
                            {catalog.alt_name || catalog.name}
                          </li>
                        );
                      })}
                    </ul>
                    <div className='register-option'>
                      <p className='mxp-text'>¿Aún no tienes una cuenta?</p>
                      <span onClick={handleRegistrationLink}>REGÍSTRATE AQUÍ</span>
                    </div>
                  </>
                )}
              </section>
            </div>
          </motion.div>
        </div>
      )}
      {!isMobile && (
        <motion.div
          variants={headerAnimations.showMenuDesktop}
          transition={{ ease: 'easeInOut', duration: 0.2 }}
          initial='hidden'
          animate={layer ? 'visible' : 'hidden'}
          exit='hidden'
          className='user-menu-desktop'
          ref={ref}>
          <section className={`user-menu-activity ${loader ? 'user-menu-activity--loading' : ''} ${logged ? 'user-menu-activity--logged' : 'user-menu-activity--user'}`}>
            {loader && <Loader spinner={{ title: 'Cargando' }} blue />}
            {logged && !loader && (
              <>
                <div className={`avatar${profile.avatar ? ' active' : ''}`}>
                  <div className='avatar-data'>
                    <div className='avatar-data-desk'>
                      <p className='mxp-txt avatar-data-hi'>¡Hola!</p>
                      <p className='mxp-txt avatar-data-name'>{profile.user_name}</p>
                    </div>
                    <p className='mxp-txt avatar-data-close' onClick={handleCloseSession}>
                      Cerrar sesión
                    </p>
                  </div>
                </div>
                <ul className='user-options-logged'>
                  {menuLinks.map((item, i) => {
                    if (item.name !== 'Monedero') {
                      return (
                        <li key={i} className='user-options-item'>
                          <Link onClick={(event) => goLink(event)} to={item.to}>
                            <span className={`user-options-elm ${item.icon}`}>{item.name}</span>
                          </Link>
                        </li>
                      );
                    } else {
                      return (
                        <li key={i} className='user-options-item user-options-item--money'>
                          <Link onClick={(event) => goLink(event)} to={item.to}>
                            <span className='user-options-elm'>
                              <span className={item.icon}></span>
                              <p>
                                <span>{item.name}</span>
                                <span className='user-options-elm-price'>
                                  <MoneyFormat type='mixed' money={profile.loyalty?.points || 0} />
                                </span>
                              </p>
                            </span>
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </>
            )}
          </section>
        </motion.div>
      )}
    </>
  );
};

export default UserMenu;
