import React from 'react';
import { connect } from 'react-redux';

const UserConfirm = ({openLoginForm}) => {
  return (
    <div id='user-account-confirm'>
      <div className='icon'>
        <img src='/img/icon-info.png' alt='Info' />
      </div>
      <div className='message'>
        <strong>Tu cuenta ya fue verificada.</strong>
        Ingresa el correo electrónico y contraseña que registraste para iniciar sesión.
      </div>
      <div className='login'>
        <span className='btn btn-primary rounded' onClick={openLoginForm}>INICIAR SESIÓN</span>
      </div>
    </div>
  );
};

const mapStateProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLoginForm: () => dispatch({ type: 'USER_OPEN', open: 'login' }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(UserConfirm);
