import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FeaturedProducts from '../../components/BasketBox/FeaturedProducts';
import CompareProductMobile from '../../components/Product/CompareProductMobile';
// import ProductFeatures from '../../components/Products/Features';
import { productGetRelated } from '../../store/actions/products';
import ContainerBoxPage from './../../components/containers/ContainerBoxPage';
import CompareProductDesktop from './../../components/Product/CompareProductDesktop';
import useIsMobile from './../../hooks/useIsMobile';
import { getProductCompare } from './../../store/actions/compare';

const CompareProductPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useIsMobile()

  const {related} = useSelector(state => state.product)

  useEffect(() => {
    dispatch(getProductCompare(params.id))
    dispatch(productGetRelated(params.id))
  }, [dispatch, params.id])

  return (
    <ContainerBoxPage className='compare-product-page'>
      {isMobile && <CompareProductMobile />}
      {!isMobile && <CompareProductDesktop />}
      {!!related.length && <FeaturedProducts
      title='Te recomendamos'
      products={related}
      />}
    </ContainerBoxPage>
  );
};

export default CompareProductPage;
