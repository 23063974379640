import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../../components/UI/Buttons';
import MoneyFormat from '../../components/MoneyFormat';
import { useHistory } from 'react-router';
import { cartGetEstimatedBox } from '../../store/actions/cart';
import { userIsReminder, userIsReminderOv } from '../../store/actions/user';
import Loader from '../Loader';

function ContainerCheck({ products }) {
    const body = document.querySelector('body');
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        // containers,
        iva,
        sub_total,
        total,
        estimatedCostBox,
        loaderEstimatedCostBox
    } = useSelector((state) => state.cart);
    const { logged, buy_offline } = useSelector(state => state.user)


    const total_with_shipping = estimatedCostBox && window.innerWidth > 1023 ? total + estimatedCostBox : total;

    useEffect(() => {
        if (logged) {
            dispatch(cartGetEstimatedBox());
        }
    }, [dispatch, logged, total])

    const handleGoToPayment = () => {
        if (buy_offline) {
            dispatch(userIsReminder(true))
            dispatch(userIsReminderOv(true))
            body.classList.add('no-scroll')
        } else {
            history.push('/carrito/datos-generales')
        }
    }

    const canContinue = products.length > 0;
    // const remains = containers[0]?.percentage ? containers[0].percentage : 0
    const messages = `Aprovecha el espacio total de tu caja agregando más productos por el mismo costo de envío.`


    return (
        <article className="product-check">
            {canContinue && (
                <div className="product-reminder">
                    <div className="reminder-info">
                        <span className="icon-info"></span>
                        <p className="mxp-text">{messages}</p>
                    </div>
                    <div className="reminder-bg">
                        <svg xmlns="http://www.w3.org/2000/svg">
                            <path d="M250.433 32.61c15.722 0 28.466 12.745 28.466 28.466v80.067c0 15.722-12.744 28.466-28.466 28.466H58.07c-3.341 7.402-11.671 21.311-30.334 32.704-1.017.62-2.24-.412-1.805-1.52 2.026-5.18 4.528-15.859-.045-31.306C11.374 168.182 0 155.994 0 141.143V71.076C0 55.356 12.745 42.61 28.466 42.61zm41.163 13.605 19.119 3.898a2.791 2.791 0 1 1-1.305 5.426l-19.118-3.897a2.792 2.792 0 0 1-2.097-3.2l.035-.167a2.79 2.79 0 0 1 3.365-2.06zm19.57-17.463a2.79 2.79 0 0 1-1.719 3.552l-18.19 7.058a2.789 2.789 0 0 1-3.49-1.553l-.064-.165a2.79 2.79 0 0 1 1.718-3.552l18.19-7.058a2.791 2.791 0 0 1 3.554 1.718zM296.441 8.82a2.79 2.79 0 0 1 .475 3.917L285.43 28.51a2.792 2.792 0 0 1-3.77.584l-.148-.108a2.79 2.79 0 0 1-.475-3.918l11.487-15.772a2.793 2.793 0 0 1 3.92-.476zM273.28.012a2.791 2.791 0 0 1 2.518 3.04l-1.137 19.477a2.79 2.79 0 0 1-2.87 2.531l-.169-.01a2.791 2.791 0 0 1-2.518-3.04l1.137-19.48a2.791 2.791 0 0 1 3.04-2.518z" fill="#E08E00" fillRule="evenodd" fillOpacity=".146" />
                        </svg>
                    </div>

                </div>
            )}
            <div className="product-row">
                <p className="mxp-text mxp-text-clear product-count-name">Subtotal:</p>
                <p className="mxp-text mxp-text-clear product-count-number">
                    <MoneyFormat type="mixed" money={sub_total} />
                </p>
            </div>
            <div className="product-row">
                <p className="mxp-text mxp-text-clear product-count-name">IVA:</p>
                <p className="mxp-text mxp-text-clear product-count-number">
                    <MoneyFormat type="mixed" money={iva} />
                </p>
            </div>
            <div>
                <div className="product-row shipping-price">
                    <div className="mxp-text mxp-text-clear product-count-name">Costo de envío aproximado:</div>
                    <div className="mxp-text mxp-text-clear product-count-number">
                        {loaderEstimatedCostBox && <Loader spinner={{ title: 'Calculando' }} initial blue scale="0.4" />}
                        {!loaderEstimatedCostBox && <>
                            {estimatedCostBox > 0 ?
                                <MoneyFormat type="mixed" money={estimatedCostBox} /> : <span className="mxp-text-price product-count-number-not">No disponible</span>
                            }
                        </>}
                    </div>
                </div>
                <div className="product-row product-total">
                    <p className="mxp-text mxp-text-clear product-count-name">Total:</p>
                    <p className="mxp-text mxp-text-clear product-count-number">
                        <MoneyFormat type="mixed" money={total_with_shipping} />
                    </p>
                </div>
            </div>
            {canContinue && (
                <nav className="product-nav">
                    <AppButton type="main" txt="IR AL PAGO" modifier="main" available={canContinue} onClick={handleGoToPayment} />
                </nav>
            )}
        </article>

    )
}

export default ContainerCheck
