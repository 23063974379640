import { useEffect, useState } from 'react';

const useIsMobile = (width = 1024) => {
  const [mobile, setMobile] = useState(window.innerWidth < width);

  useEffect(() => {
    const isMobile = () => {
      const checkIsMobile = window.innerWidth < width;
      if (mobile !== checkIsMobile) {
        setMobile(checkIsMobile);
      }
    };

    window.addEventListener('resize', isMobile);

    return () => {
      window.removeEventListener('resize', isMobile);
    };
  }, [mobile, width]);

  return mobile;
};

export default useIsMobile;
