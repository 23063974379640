import { getNewShippingCosts, getShippingCosts, saveShippingCost } from '../../helpers/api/shipping';
import axiosApi from '../axiosAPI';
import { types } from '../reducers/purchase';
import { historyReset } from './history-purchase';
import { userRemoveOrderID } from './user';

if (window.Conekta) {
  const apiKey = process.env.API_KEY_CONEKTA || 'key_Omka3vP7fmaB3a3goNMe76Q';
  window.Conekta.setPublicKey(apiKey);

  window.Conekta.setLanguage('es');
}

const clearPurchaseData = () => (dispatch) => {
  window.sessionStorage.removeItem('cart');
  dispatch(historyReset());
  dispatch(userRemoveOrderID());
};

export const selectOffice =
  (id, next = false) =>
    (dispatch) => {
      dispatch({ type: types.PURCHASE_SELECT_OFFICE, office: id, next });
    };

export const enableAddAddress = () => (dispatch, getStore) => {
  const data = getStore().purchase;

  return (
    !!data.personalInfo.name.length &&
    !!data.personalInfo.email.length &&
    !!data.personalInfo.phone.length &&
    !!data.personalInfo.address.length &&
    !!data.personalInfo.exteriorNumber.length &&
    !!data.personalInfo.zip.length &&
    !!data.personalInfo.suburbs.id &&
    !!data.personalInfo.state.id &&
    !!data.personalInfo.city.id &&
    !!data.personalInfo.references.length
  );
};

export const purchaseAllowQuotations = () => (dispatch, getStore) => {
  const data = getStore().purchase;
  if (data.deliveryType === 'office' && data.officeID) {
    return true;
  }

  return (
    (!!data.personalInfo?.name.length &&
      !!data.personalInfo?.email.length &&
      !!data.personalInfo?.phone.length &&
      !!data.personalInfo?.address.length &&
      !!data.personalInfo?.exteriorNumber.length &&
      !!data.personalInfo?.references.length &&
      !!data.personalInfo?.zip.length &&
      !!data.personalInfo?.suburb.id &&
      !!data.personalInfo?.state.id &&
      !!data.personalInfo?.city.id) ||
    !!data.addressID
  );
};

export const purchaseAllowPaymentMethods = () => (dispatch, getStore) => {
  const data = getStore().purchase;
  const checkAddress = dispatch(purchaseAllowQuotations());

  return checkAddress && !!data.shipping;
};

export const purchaseAllowMakePayment = () => (dispatch, getStore) => {
  return false;
};

export const enableNext = () => (dispatch, getStore) => {
  const data = getStore().purchase;
  const isLogged = getStore().user.logged;
  const deliveryType = getStore().purchase.deliveryType;
  const officeID = getStore().purchase.officeID;

  if (data.step === 1) {
    const checkOffice = deliveryType === 'home' ? true : officeID;

    return (
      ((!!data.personalInfo?.name.length &&
        !!data.personalInfo?.email.length &&
        !!data.personalInfo?.phone.length &&
        !!data.personalInfo?.address.length &&
        !!data.personalInfo?.exteriorNumber.length &&
        !!data.personalInfo?.references.length &&
        !!data.personalInfo?.zip.length &&
        !!data.personalInfo?.suburbs.id &&
        !!data.personalInfo?.state.id &&
        !!data.personalInfo?.city.id) ||
        !!data.addressID) &&
      checkOffice
    );
  }

  if (data.step === 2) {
    if (data.paymentMethod === 'paypal') return true;

    const validCVV = ((data.cardData.type === 'visa' || data.cardData.type === 'mastercard') && data.cardData.cvv.length === 3) || data.cardData.cvv.length === 4;

    return (
      (isLogged && data.tokenCard) ||
      (!!data.cardData.name && validateCard(data.cardData.number) && validateDate(data.cardData.month, data.cardData.year) && validCVV) ||
      (!isLogged && !!data.cardData.name && validateCard(data.cardData.number) && validateDate(data.cardData.month, data.cardData.year) && validCVV)
    );
  }

  if (data.step === 3) {
    if (data.paymentMethod === 'paypal') return true;

    const validCVV = ((data.cardData.type === 'visa' || data.cardData.type === 'mastercard') && data.cardData.cvv.length === 3) || data.cardData.cvv.length === 4;

    return data.tmpCardToken || (!!data.cardData.name && validateCard(data.cardData.number) && validateDate(data.cardData.month, data.cardData.year) && validCVV);
  }

  return false;
};

export const nextStep = () => (dispatch, getStore) => {
  const data = getStore().purchase;
  const isLogged = getStore().user.logged;

  if (data.step === 2 && data.paymentMethod === 'tc' && (!data.tokenCard || !isLogged)) {
    return dispatch(generateToken());
  }

  return dispatch({ type: 'PURCHASE_STEP' });
};

const validateCard = (card) => {
  if (!/^4([0-9]{12}|[0-9]{15}|[0-9]{17,18})$/.test(card) && !/^3[47][0-9]{13}$/.test(card) && !/^(((5[1-5])[0-9]{14})|((222100|271099)[0-9]{10}))$/.test(card)) {
    return false;
  }
  return true;
};

const validateDate = (month, year) => {
  if (!month || !year) return false;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (year < currentYear || (currentYear === year && month.value < currentMonth)) {
    return false;
  }
  return true;
};

export const expireDateError = () => (dispatch, getStore) => {
  const month = getStore().purchase.cardData.month;
  const year = getStore().purchase.cardData.year;

  if (!month || !year) return false;

  if (!validateDate(month, year)) {
    return '*La fecha ingresada no es válida, verifícala e intenta nuevamente.';
  }
  return false;
};

export const getCardsSaved = () => async (dispatch) => {
  const cardsData = await axiosApi.get('v1/payment/conekta/payment-methods');
  if (cardsData.data.status_code === 604 || !cardsData.ok) {
    return dispatch({ type: types.PURCHASE_GET_CARDS_SAVED, cards: [] });
  };

  dispatch({ type: types.PURCHASE_GET_CARDS_SAVED, cards: cardsData.data });
};

/**
 * Remover tarjeta dentro de la lista de tarjetas del usuario.
 * @param {payment_source} payment_source
 */
export const removeCardsSaved = (payment_source) => async (dispatch, getStore) => {
  const response = await axiosApi.delete('v1/payment/conekta/payment-methods', {
    data: {
      payment_source
    }
  });

  if (response.data.status_code === 605) {
    // not found
    // {"message":"El método de pago no ha sido encontrado.","status_code":605}
  }

  // status_code 606 = ok

  const cards = getStore().purchase.cardsList.filter((item) => item.id !== payment_source)

  dispatch({ type: types.PURCHASE_CARDS_DELETE, cards });

};

/**
 * Actualizar tarjeta dentro de la lista de tarjetas del usuario.
 * @param {información de tarjeta del usuario.} cardInfo
 * @returns información de la actualización
 */
export const updateCardsSaved = (cardInfo) => async (dispatch, getStore) => {
  dispatch({ type: types.PURCHASE_LOADER_TOKENIZE, loader: true })

  const response = await axiosApi.put('v1/payment/conekta/payment-methods', {
    payment_source: cardInfo.payment_source || cardInfo.id,
    name: cardInfo.name,
    exp_month: ("0" + cardInfo.exp_month).slice(-2),
    exp_year: cardInfo.exp_year,
    address: {
      street1: cardInfo.address.street1,
      street2: cardInfo.address.street2,
      city: cardInfo.address.city,
      state: cardInfo.address.state,
      country: 'mx',
      postal_code: cardInfo.address.postal_code,
    },
  });

  dispatch({ type: types.PURCHASE_CARDS_UPDATE, update: response.data });
};

export const generateToken =
  (card, save = false) =>
    async (dispatch, getStore) => {
      dispatch({ type: types.PURCHASE_LOADER });
      const personalInfo = getStore().purchase.personalInfo;
      const personalInfoSaved = getStore().user.addresses.find((item) => item.address_id === getStore().purchase.addressID);

      let address;

      if (personalInfo.address) {
        address = {
          street: `${personalInfo.address} #${personalInfo.exteriorNumber}${personalInfo.interiorNumber ? ' int.' + personalInfo.interiorNumber : ''}`,
          city: personalInfo.city.name,
          state: personalInfo.state.name,
          zip: personalInfo.zip,
          country: 'Mexico',
        };
      } else {
        address = {
          street: `${personalInfoSaved.address.content} #${personalInfoSaved.address.exterior_number}${personalInfoSaved.address.interior_number ? ' int.' + personalInfoSaved.address.interior_number : ''
            }`,
          city: personalInfoSaved.location.city_name,
          state: personalInfoSaved.location.state_name,
          zip: personalInfoSaved.location.zip,
          country: 'Mexico',
        };
      }

      card.address = address;
      window.Conekta.Token.create(
        { card },
        async (token) => {
          delete card.cvc;
          if (save) {
            const saveCard = await axiosApi.post('v1/payment/conekta/payment-methods', {
              token: token.id,
              phone: personalInfo.phone || personalInfoSaved.address.phone,
              name: card.name,
            });
            dispatch(getCardsSaved());
            card.id = saveCard.data.payment_source;
            return dispatch({ type: types.PURCHASE_SET_CARD_DATA, card });
          }
          card.id = token.id;
          return dispatch({ type: types.PURCHASE_SET_CARD_DATA, card });
        },
        (error) => {
          dispatch({ type: types.PURCHASE_TOKENIZE_CARD, error: error.message_to_purchaser });
        },
      );
    };

export const setAddress =
  (addressID, address = false) =>
    (dispatch, getStore) => {
      if (!address) {
        const addressData = getStore().user.addresses.find((item) => item.address_id === addressID);
        address = {
          alias: addressData.alias,
          address: addressData.address.content,
          city: {
            id: addressData.location.city_id,
            name: addressData.location.city_name,
          },
          email: false,
          exteriorNumber: addressData.address.exterior_number,
          interiorNumber: addressData.address.interior_number,
          name: addressData.address.name,
          phone: addressData.address.phone,
          references: addressData.address.references,
          state: {
            id: addressData.location.state_id,
            name: addressData.location.state_name,
          },
          suburb: addressData.location.suburb_name,
          zip: addressData.location.zip,
        };
      }

      return dispatch({ type: types.PURCHASE_SET_ADDRESS_ID, addressID, address });
    };

export const changeAddressLogged = () => (dispatch, getStore) => {
  const newAddress = getStore().user.addresses.find((item) => item.address_id === getStore().purchase.changeAddressID);

  const personalInfo = {
    alias: newAddress.alias,
    address: newAddress.address.content,
    city: {
      id: newAddress.location.city_id,
      name: newAddress.location.city_name,
    },
    email: false,
    exteriorNumber: newAddress.address.exterior_number,
    interiorNumber: newAddress.address.interior_number,
    name: newAddress.address.name,
    phone: newAddress.address.phone,
    references: newAddress.address.references,
    state: {
      id: newAddress.location.state_id,
      name: newAddress.location.state_name,
    },
    suburbs: newAddress.location.suburb_name,
    zip: newAddress.location.zip,
  };

  dispatch({ type: types.PURCHASE_CHANGE_NEW_ADDRESS_LOGGED, personalInfo });
};

export const enableChangeAddress = () => (dispatch, getStore) => {
  const isLogged = getStore().user.logged;
  const data = getStore().purchase.changeAddress;
  if (isLogged) {
    return (
      !!data.zip.length &&
      !!data.address.length &&
      !!data.exteriorNumber.length &&
      !!data.suburbs.id &&
      !!data.state.id &&
      !!data.city.id &&
      !!data.name.length &&
      !!data.email.length &&
      !!data.references.length &&
      !!data.phone.length
    );
  }
  return !!data.zip.length && !!data.address.length && !!data.references.length && !!data.exteriorNumber.length && !!data.suburbs.id && !!data.state.id && !!data.city.id;
};

const setPurchaseCompleteAnalyticsData = (cart, purchase) => {
  const {
    paymentTC,
    paymentOXXO,
    paymentPaypal,
    paymentLoyalty,
    deliveryType,
    officeID,
    shipping,
    loyalty_points,
    msi,
    paymentMethod,
  } = purchase;

  const {
    total,
    iva,
    containers,
    products,
  } = cart;

  let reference = paymentTC?.reference || paymentOXXO?.reference || paymentPaypal?.reference || paymentLoyalty?.reference || '';

  return {
    reference,
    total: (total + (shipping.total || 0) - loyalty_points),
    iva,
    containers,
    products,
    deliveryType,
    shipping,
    officeID,
    msi,
    paymentMethod,
    loyalty_points
  }

}

export const purchaseMakePaymentTC = () => async (dispatch, getStore) => {
  dispatch({ type: types.PURCHASE_LOADER });
  const { tokenCard: cardToken, loyalty_points, msi } = getStore().purchase;
  const isLogged = getStore().user.logged;
  const delivery = getStore().purchase.officeID || true;

  const endPointToken = isLogged ? 'v1/payment/conekta/payment-token' : 'v1/payment/conekta/payment-token/guest';

  if (/^tok_.+$/.test(cardToken)) {
    let paymentData = {
      order_id: getStore().cart.orderID,
      token: cardToken,
      name: getStore().purchase.cardData.name,
      phone: getStore().purchase.personalInfo.phone,
      delivery,
      loyalty_points,
      captcha: !isLogged,
    };
    if (msi) {
      paymentData.monthly_installments = msi;
    }
    if (!isLogged) {
      paymentData.email = getStore().purchase.personalInfo.email;
    }
    const paymentTok = await axiosApi.post(endPointToken, { ...paymentData });

    if (paymentTok.data.status_code === 612) {
      return dispatch({
        type: types.PURCHASE_MAKE_PAYMENT_TC,
        message: paymentTok.data.message,
        detail: paymentTok.data.details.join(),
        warning: paymentTok.data.warning,
        reference: false,
      });
    }
    if (paymentTok.data.reference) {
      const purchaseData = setPurchaseCompleteAnalyticsData({...getStore().cart}, {...getStore().purchase})
      dispatch({ type: types.PURCHASE_COMPLETE_ANALYTICS_DATA, data: { ...purchaseData, reference: paymentTok.data.reference } })
    }
    dispatch({
      type: types.PURCHASE_MAKE_PAYMENT_TC,
      message: paymentTok.data.message,
      reference: paymentTok.data.reference || false,
    });
    if (isLogged) {
      dispatch(purchaseLoyaltyGenerate());
    }
    dispatch(clearPurchaseData());
  } else {
    const paymentData = {
      order_id: getStore().cart.orderID,
      payment_source: cardToken,
      delivery,
      loyalty_points,
    };
    if (msi) {
      paymentData.monthly_installments = msi;
    }
    const paymentSrc = await axiosApi.post('v1/payment/conekta/payment-source', paymentData);

    if (paymentSrc.data.status_code === 612) {
      return dispatch({
        type: types.PURCHASE_MAKE_PAYMENT_TC,
        message: paymentSrc.data.message,
        detail: paymentSrc.data.details.join(),
        warning: paymentSrc.data.warning,
        reference: false,
      });
    }
    if (paymentSrc.data.reference) {
      const purchaseData = setPurchaseCompleteAnalyticsData({...getStore().cart}, {...getStore().purchase})
      dispatch({ type: types.PURCHASE_COMPLETE_ANALYTICS_DATA, data: { ...purchaseData, reference: paymentSrc.data.reference } })
      dispatch(purchaseLoyaltyGenerate());
    }

    dispatch({
      type: types.PURCHASE_MAKE_PAYMENT_TC,
      message: paymentSrc.data.message,
      reference: paymentSrc.data.reference || false,
    });

    dispatch(clearPurchaseData());
    // end payment
  }
};

export const clearErrorPayment = () => (dispatch) => {
  dispatch({ type: types.PURCHASE_CLEAR_ERROR_PAYMENT });
};

export const updateNewCardValue = (name, value) => (dispatch) => { };

export const getShippingCost =
  (setOrderID = false, setZip = false) =>
    async (dispatch, getStore) => {
      const orderID = setOrderID || getStore().cart.orderID;
      const zip = setZip || getStore().purchase.personalInfo.zip;

      dispatch({ type: types.PURCHASE_LOADER_RATES, loader: true });
      const packagesRates = await getShippingCosts(orderID, zip);
      if (!packagesRates.ok) {
        return dispatch({ type: types.PURCHASE_LOAD_PACKAGES, error: packagesRates.error });
      }

      return dispatch({ type: types.PURCHASE_LOAD_PACKAGES, packagesRates });
    };

export const getNewShippingCost =
  (setOrderID = false, address = false) =>
    async (dispatch, getStore) => {
      const orderID = setOrderID || getStore().cart.orderID;
      const { profile: { name, email, phone } } = setOrderID || getStore().user;
      const currentAddress = address || getStore().purchase.personalInfo;

      const destination = {
        email: currentAddress.email || email,
        "first_name": currentAddress.name || name,
        "last_name": "demo",
        "phone": currentAddress.phone || phone,
        "street": currentAddress.address,
        "number": currentAddress.exteriorNumber,
        "suburb": currentAddress.suburbs?.name || currentAddress.suburbs || currentAddress.suburb?.name || currentAddress.suburb,
        "state": currentAddress.state.name,
        "reference": currentAddress.references,
        "zip_code": currentAddress.zip,
        "city": currentAddress.city.name
      };

      dispatch({ type: types.PURCHASE_LOADER_RATES, loader: true });
      const packagesRates = await getNewShippingCosts(orderID, destination);
      if (!packagesRates.ok) {
        return dispatch({ type: types.PURCHASE_LOAD_PACKAGES, error: packagesRates.error });
      }

      return dispatch({ type: types.PURCHASE_LOAD_PACKAGES, packagesRates });
    };

export const saveShipping = (rate) => async (dispatch, getStore) => {
  if (rate === false) {
    return dispatch({ type: types.PURCHASE_SELECT_SHIPPING, rate });
  }

  const storeData = getStore();
  const order_id = storeData.cart.orderID;
  const personalInfo = storeData.purchase.personalInfo;

  const data = {
    order_id,
    carrier: rate.carrier,
    product: rate.product,
    destination_zip: personalInfo.zip
  }

  const save = await saveShippingCost(data);
  if (save.status_code !== 705) {
    return dispatch({ type: types.PURCHASE_SELECT_SHIPPING, error: save });
  }
  dispatch({ type: types.PURCHASE_SELECT_SHIPPING, rate });
};

export const clearPackageError = () => (dispatch) => dispatch({ type: types.PURCHASE_CLEAR_PACKAGE_ERROR });

export const changeDeliveryType = (deliveryType) => (dispatch) => dispatch({ type: types.PURCHASE_CHANGE_DELIVERY_TYPE, deliveryType });

export const purchaseGetLocationsByZip = (zip) => async (dispatch) => {
  dispatch({ type: types.PURCHASE_LOADER_LOCATIONS_BY_ZIP, loader: true });
  const response = await axiosApi.get(`v1/locations?zip=${zip}`);
  dispatch({ type: types.PURCHASE_GET_LOCATIONS_BY_ZIP, locations: response.data });
};

export const purchaseResetLocationsByZip = () => (dispatch) => dispatch({ type: types.PURCHASE_RESET_LOCATIONS_BY_ZIP });

export const purchaseUpdatePersonalInfoField = (field, value) => (dispatch) => dispatch({ type: types.PURCHASE_UPDATE_PERSONAL_INFO, field, value });

export const purchaseSetPersonalInfo = (data) => (dispatch) => dispatch({ type: types.PURCHASE_SET_PERSONAL_INFO, data });

export const purchaseSetTokenCard = (card) => (dispatch) => dispatch({ type: types.PURCHASE_SET_CARD_DATA, card });

export const purchaseSetPaymentMethod = (method) => (dispatch) => dispatch({ type: types.PURCHASE_SET_PAYMENT_METHOD, method });

export const purchaseReset = (data) => (dispatch) => {
  dispatch({ type: types.PURCHASE_RESET });
  dispatch(historyReset());
  dispatch(userRemoveOrderID());
};

export const purchaseMakePaymentOXXO = () => async (dispatch, getStore) => {
  dispatch({ type: types.PURCHASE_LOADER });
  const cart = getStore().cart;
  const purchase = getStore().purchase;
  const user = getStore().user;

  const delivery = purchase.deliveryType === 'home' ? true : purchase.officeID;

  const data = {
    order_id: cart.orderID,
    name: purchase.personalInfo.name,
    phone: purchase.personalInfo.phone,
    email: user.logged ? user.profile.email : purchase.personalInfo.email,
    delivery,
    captcha: true,
    loyalty_points: purchase.loyalty_points,
  };

  const response = await axiosApi.post('v1/payment/conekta/payment-oxxo', data);

  // analytics
  const purchaseData = setPurchaseCompleteAnalyticsData(getStore().cart, getStore().purchase)
  dispatch({ type: types.PURCHASE_COMPLETE_ANALYTICS_DATA, data: { ...purchaseData, reference: response.data.reference } })
  // end analytics

  dispatch({ type: types.PURCHASE_MAKE_PAYMENT_OXXO, paymentOXXO: response.data });
};

export const purchaseMakePaymentPaypal = (data) => async (dispatch, getStore) => {
  dispatch({ type: types.PURCHASE_LOADER, loader: true });

  const purchase = getStore().purchase;
  const cart = getStore().cart;
  const user = getStore().user;

  const delivery = purchase.deliveryType === 'home' ? true : purchase.officeID;

  const data = {
    order_id: cart.orderID,
    delivery,
    email: purchase.personalInfo.email || user.profile.email,
    name: purchase.personalInfo.name || user.profile.name,
    phone: purchase.personalInfo.phone || user.profile.phone,
    loyalty_points: purchase.loyalty_points,
    captcha: true,
  };

  const response = await axiosApi.post('v1/payment/paypal/orders', data);

  dispatch({ type: types.PURCHASE_MAKE_PAYMENT_PAYPAL, pymentPaypal: response.data });

  if (response.data.approve_link) {
    const purchaseData = setPurchaseCompleteAnalyticsData(getStore().cart, getStore().purchase)
    window.localStorage.setItem('tmp_purchase', JSON.stringify({...purchaseData, time: new Date().getTime()}))
    window.location.href = response.data.approve_link;
  }
};

export const purchaseLoyaltyGet = () => async (dispatch) => {
  const response = await axiosApi.get('v1/loyalty');

  const data = {
    message: response.data.message,
    last4: response.data.last4 || false,
    points: response.data.points || 0,
  };
  dispatch({ type: types.PURCHASE_LOYALTY_GET, loyalty: data });
};

export const purchaseLoyaltyGenerate = () => async (dispatch, getStore) => {
  const {
    loyalty: { last4 },
  } = getStore().purchase;
  if (last4) return;
  const { orderID: order_id } = getStore().cart;
  const {
    profile: { phone },
  } = getStore().user;
  const data = {
    order_id,
    phone,
    delivery: '',
  };
  const response = await axiosApi.post('v1/loyalty', data);
  console.warn('response.data: ', response.data);
};

export const purchaseResetShipping = () => (dispatch) => {
  dispatch(clearPackageError());
  dispatch({ type: types.PURCHASE_RESET_SHIPPING });
};

export const purchaseConfirmationPaypal = (token) => async (dispatch) => {
  const response = await axiosApi.post('/v1/payment/paypal/orders/capture', { token, captcha: true });

  // analytics
  dispatch({ type: types.PURCHASE_MAKE_PAYMENT_PAYPAL, paymentPaypal: response.data });
};

export const purchasePaymentLoyalty = (amount) => (dispatch) => {
  dispatch({ type: types.PURCHASE_SET_LOYALTY_AMOUNT, amount });
};

export const purchaseMakePaymentLoyalty = () => async (dispatch, getStore) => {
  const { orderID: order_id } = getStore().cart;
  const {
    profile: { phone },
  } = getStore().user;
  const { deliveryType, officeID } = getStore().purchase;
  const data = {
    order_id,
    phone,
    delivery: deliveryType === 'home' || officeID,
    captcha: true,
  };

  dispatch({ type: types.PURCHASE_LOADER, loader: true });
  const response = await axiosApi.post('v1/payment/loyalty', data);

  const purchaseData = setPurchaseCompleteAnalyticsData(getStore().cart, getStore().purchase)
  dispatch({ type: types.PURCHASE_COMPLETE_ANALYTICS_DATA, data: { ...purchaseData, reference: response.data.reference } })

  dispatch({ type: types.PURCHASE_MAKE_PAYMENT_LOYALTY, paymentLoyalty: response.data });
};

export const purchaseClearPaymentMethod = () => (dispatch) => {
  dispatch({ type: types.PURCHASE_CLEAR_CARD_TOKEN });
};

export const purchaseTokenizeCard = ({ card, reload = false, save = false, setCard = false, callback = false }) => async (dispatch) => {

  dispatch({ type: types.PURCHASE_LOADER_TOKENIZE, loader: true })
  let ok = false;
  const handleSuccess = async (token) => {
    delete card.cvc;
    if (save) {
      const saveCard = await axiosApi.post('v1/payment/conekta/payment-methods', {
        token: token.id,
        phone: card.phone,
        name: card.name,
      });
      if (reload) {
        dispatch(getCardsSaved());
      }
      card.id = saveCard.data.payment_source;
      if (setCard) {
        dispatch({ type: types.PURCHASE_SET_CARD_DATA, card })
      }
    }
    card.id = token.id;
    if (!save && setCard) {
      dispatch({ type: types.PURCHASE_SET_CARD_DATA, card });
    }
    if (callback) {
      callback(card)
    }
    dispatch({ type: types.PURCHASE_LOADER_TOKENIZE, loader: false })
    return { ok: true }
  }

  const handleError = (error) => {
    dispatch({ type: types.PURCHASE_TOKENIZE_CARD, error: error.message_to_purchaser });
  }

  await window.Conekta.Token.create(
    { card }, handleSuccess, handleError
  );
  return ok;
}

export const purchaseSetMSI = (msi) => (dispatch) => {
  dispatch({ type: types.PURCHASE_SET_MSI, msi })
}

export const purchaseResetAnalyticsData = () => (dispatch) => dispatch({ type: types.PURCHASE_COMPLETE_ANALYTICS_DATA})
