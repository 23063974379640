import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryProductsRecommended } from '../../store/actions/categories';
import ProductThumb from '../ProductThumb';
import $ from 'jquery';



const ProductsNotFound = ({message}) => {
  const productsRecommended = useSelector(state => state.categories.productsRecommended)
  const category = useSelector(state => state.product.category)

  const carrusel = useRef();

  const dispatch = useDispatch()

  useEffect(() => {

    const carruselElement = carrusel.current;
    if(!productsRecommended){
      dispatch(categoryProductsRecommended());
    }
    if(productsRecommended){
      $(carruselElement).slick({
        mobileFirst: true,
        variableWidth: true,
        nextArrow: false,
        prevArrow: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        responsive:[
          {
            breakpoint: 480,
            settings:{
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 720,
            settings:{
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 940,
            settings:{
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1023,
            settings:{
              slidesToShow: 2,
              slidesToScroll: 2,
              nextArrow: '<span class="icon-right-open"></span>',
              prevArrow: '<span class="icon-left-open"></span>',
              infinite: true,
              variableWidth: true,
              centerMode: false,
            }
          },
        ]
      })
    }
    return () => {
      if(carruselElement.classList.contains('slick-initialized')){
        $(carruselElement).slick('unslick');
      }
    }
  }, [dispatch, productsRecommended, carrusel])

  return (<div id='products-not-found'>
    <img src="/img/search-alert.png" alt="Not found"/>
    <h1>¡Lo sentimos!</h1>
<p>{ message || 'Por el momento, no tenemos productos disponibles.'}</p>
    <div className="recommended">
      <strong>TE RECOMENDAMOS</strong>
      <div className="item-slides" ref={carrusel}>
        {productsRecommended && productsRecommended.map((product, productIndex) => <ProductThumb key={productIndex} {...product} section="Category" showRating={true} categoryName={category?.name} />)}
      </div>
    </div>
  </div>);
};

export default ProductsNotFound;
