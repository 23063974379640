import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getHomeData } from '../../store/actions/home';
import ProductList from './ProductList';
import StepsProgress from './StepsProgress';

const Container = ({ children, modifier = '', ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderID } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!orderID) {
      history.replace('/');
    }
    dispatch(getHomeData());
  }, [dispatch, history, orderID]);

  return (
    <div id='container' {...rest}>
      <div>
        <div className='main'>
          <StepsProgress />
          <div className={`content ${modifier}`}>{children}</div>
        </div>
        <ProductList />
      </div>
    </div>
  );
};

export default React.memo(Container);
