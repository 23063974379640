import React from 'react';
import { connect } from 'react-redux';
import MoneyFormat from '../MoneyFormat';
import AdvancedModal from '../AdvancedModal';
import { saveShipping, selectOffice } from '../../store/actions/purchase';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import PackageError from './PackageError';
import Select from '../FormElements/Select';
import { isMobile } from '../../helpers/index';

class PackagesRates extends React.Component {
  state = {
    method: 'shipping',
    office: false,
  };

  resetMethod = () => {
    if (!isMobile() && this.state.method === 'office') {
      this.setState({ method: 'shipping' });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.resetMethod);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetMethod);
  }

  method = (method) => {
    this.setState({ method });
  };

  packages = () => {
    const items = Object.keys(this.props.packages).map((item) => {
      const img = item === 'REDPACK' ? '/img/package_redpack.png' : '/img/package_estafeta.png';
      return {
        title: item,
        img,
      };
    });

    return items;
  };

  close = () => {
    this.props.saveShipping(false);
    this.props.selectOffice(false);
  };

  setOffice = (id) => {
    this.props.selectOffice(id);
  };

  selectOffice = (office) => {
    this.setState({ office });
  };

  render() {
    if (this.props.error) return <PackageError />;

    if (!this.props.packages) return '';

    return (
      <>
        {this.props.loader && <Loader id='loader-packages-rates' />}
        <AdvancedModal className={`packages-rates packages-${this.packages().length}`} scrollAfterClose={this.props.scrollAfterClose} close={this.props.cancel}>
          <AdvancedModal.Body>
            <div className='tabs'>
              <span className={`link${this.state.method === 'shipping' && ' active'}`} onClick={() => this.method('shipping')}>
                PAQUETERÍA
              </span>
              <span className={`link${this.state.method === 'office' && ' active'}`} onClick={() => this.method('office')}>
                SUCURSAL
              </span>
            </div>
            {this.state.method === 'shipping' &&
              this.packages().map((item, packageIndex) => (
                <div key={packageIndex} className={`package col-1_${this.packages().length}`}>
                  <div className='img'>
                    <img src={item.img} alt={item.title} />
                  </div>
                  <ul>
                    {this.props.packages[item.title].map((cost, costIndex) => (
                      <li key={costIndex} onClick={() => this.props.saveShipping(cost)}>
                        <div className='detail'>
                          <strong>{cost.product}</strong>
                          <p>
                            Entrega aproximada en {cost.delivery_days} {cost.delivery_days > 1 ? 'días' : 'día'}
                          </p>
                          <span>IVA incluído</span>
                        </div>
                        <div className='price'>
                          <span className='btn btn-primary rounded'>
                            <MoneyFormat money={cost.total} />
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            {this.state.method === 'office' && (
              <div className='offices-list'>
                {this.props.offices.map((office, officeIndex) => (
                  <div key={officeIndex} onClick={() => this.setOffice(office.id)}>
                    <strong>{office.name}</strong>
                    {office.address}
                  </div>
                ))}
              </div>
            )}
          </AdvancedModal.Body>
          <AdvancedModal.Footer>
            <strong>También puedes recoger en tienda</strong>
            <div className='select-office-desktop'>
              <div className='change-office'>
                <Select options={this.props.offices} keyLabel='name' icon='icon-pin' placeholder='Sucursal' onChange={(office) => this.selectOffice(office)} value={this.state.office} keySubtitle="address" />
              </div>
              <div className='set-office'>
                <span onClick={() => this.setOffice(this.state.office.id)} className={`btn btn-primary rounded${!this.state.office ? ' disabled' : ''}`}>
                  ELEGIR
                </span>
              </div>
            </div>
          </AdvancedModal.Footer>
        </AdvancedModal>
      </>
    );
  }
}

const mapStateProps = (state) => {
  return {
    packages: state.purchase.packagesRates,
    loader: state.purchase.loaderRates,
    error: state.purchase.packageError,
    offices: state.home.offices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveShipping: (rate) => dispatch(saveShipping(rate)),
    cancel: () => dispatch({ type: 'PURCHASE_UNLOAD_PACKAGES' }),
    selectOffice: (id) => dispatch(selectOffice(id, true)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

PackagesRates.propTypes = {
  scrollAfterClose: PropTypes.bool.isRequired,
};

export default createConnect(PackagesRates);
