export const types = {
  LOADER: 'LOADER',
  REMOVE_LOADER: 'REMOVE_LOADER',
  ERROR_SERVER: 'ERROR_SERVER',
  BREADCRUMBS: 'BREADCRUMBS',
  GLOBAL_SET_PAGE: 'GLOBAL_SET_PAGE',
  GLOBAL_SET_ALERT: 'GLOBAL_SET_ALERT',
  USER_SHOW_COLORS: 'USER_SHOW_COLORS',
  USER_SAVE_COLORS: 'USER_SAVE_COLORS',
  USER_UPDATE_COLORS: 'USER_UPDATE_COLORS',
  USER_RESET_COLORS: 'USER_RESET_COLORS',
};

const initialStore = {
  breadcrumbs: [
    {
      title: 'Inicio',
      path: '/',
    },
  ],
  errorServer: false,
  loader: false,
  currentPage: {
    id: '',
    className: ''
  },
  alert: false,
  show_colors: false,
  colors_info: [],
  saved_colors: []
};

// const loadLocalProducts = () => {
//   const local = window.sessionStorage.getItem('user_product_colors') || '[]';
//   return JSON.parse(local);
// };
const saveLocalProducts = (data) => window.sessionStorage.setItem('user_product_colors', JSON.stringify(data));

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.LOADER:
      return { ...store, loader: true };
    case types.REMOVE_LOADER:
      return { ...store, loader: false };
    case types.ERROR_SERVER:
      return { ...store, errorServer: action.error };
    case types.BREADCRUMBS:
      const breadcrumbs = action.breadcrumbs || [...initialStore.breadcrumbs];
      return { ...store, breadcrumbs };
    case types.GLOBAL_SET_PAGE:
      return { ...store, currentPage: action.currentPage }
    case types.GLOBAL_SET_ALERT:
      return { ...store, alert: action.alert || false }
    case types.USER_SHOW_COLORS:
      return { ...store, show_colors: action.payload };
    case types.USER_RESET_COLORS:
      window.localStorage.removeItem('favorites');
      return { ...initialStore }
    case types.USER_SAVE_COLORS:
      let colors = [...store.colors_info, action.payload];
      saveLocalProducts(colors);
      return { ...store, colors_info: colors };

    case types.USER_UPDATE_COLORS:

      saveLocalProducts(action.payload);
      return { ...store, saved_colors: action.payload };
    default:
      return { ...store };


  }
};

export default reducer;
