import $ from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartAddItem } from '../../store/actions/cart';
import Breadcrumbs from '../Breadcrumbs';
import BtnAddFavorite from '../BtnAddFavorite';
import MoneyFormat from '../MoneyFormat';
import PerfectScroll from '../PerfectScroll';
import RatingProduct from '../RatingProduct';
import Image from './../Image';

const Product = ({ ...rest }) => {
  const [quantity, setQuantity] = useState(1);
  const [added, setAdded] = useState(false);
  const { logged } = useSelector((state) => state.user);

  const dispatch = useDispatch()

  const handleChange = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');
    setAdded(false);
  };

  const handlePlusMinus = (value) => {
    setQuantity(quantity + value);
    setAdded(false);
  };

  const attribute = (key) => {
    const find = rest.attributes?.find((item) => item.code === key);
    return find?.value || '';
  };

  const handleAddToCart = () => {
    const product = { ...rest, price: attribute('precio') }
    dispatch(cartAddItem(product, quantity));
    setAdded(true);
  };

  return (
    <article {...rest}>
      <div className='info'>
        <Image src={rest.media ? rest.media[0]?.sizes.small : false} />
        <RatingProduct rating={rest.rating} />
        {logged && <BtnAddFavorite id={rest.product_id || 0} is_favorite={rest.is_favorite} />}
        <h1>{rest.name}</h1>
        <strong className='price'>
          <MoneyFormat money={attribute('precio')} />
        </strong>
        <div className='compare-add-to-cart'>
          <span className={`icon-minus-circle${quantity < 2 ? ' disabled' : ''}`} onClick={() => handlePlusMinus(-1)}></span>
          <input type='text' className='quantity' name='quantity' onBlur={handleAddToCart} onChange={handleChange} value={quantity} />
          <span className={`icon-plus-circle${quantity >= 10 ? ' disabled' : ''}`} onClick={() => handlePlusMinus(1)}></span>
        </div>
        <span className={`btn btn-primary rounded-small${added ? ' disabled' : ''} center-flex`} onClick={handleAddToCart}>
          Agregar
        </span>
        <PerfectScroll className='description'>{rest.description}</PerfectScroll>
      </div>
      <div className='features'>
        <div className='brand'>{attribute('marca')}</div>
        <div className='size'>
          {attribute('largo')} cm x {attribute('ancho')} cm x {attribute('alto')} cm
        </div>
        <div className='material'>{attribute('material')}</div>
        <div className='weight'>{attribute('peso')} kg</div>
        <div className='color'>{attribute('color')}</div>
      </div>
    </article>
  );
};

const CompareProductDesktop = () => {
  const slider = useRef(false);

  const { compare, main } = useSelector((state) => state.compare);

  useEffect(() => {
    let currentSlider = false;
    if (slider.current && !!compare.length) {
      currentSlider = $(slider.current);
      currentSlider.slick({
        slidesToScroll: 2,
        slidesToShow: 2,
        variableWidth: true,
        dots: true,
        nextArrow: '<span class="icon-right-open"></span>',
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1250,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3,
            },
          },
        ],
      });
    }

    return () => {
      if (currentSlider) {
        currentSlider.slick('unslick');
      }
    };
  });

  return (
    <>
      <Breadcrumbs />
      <h1 className='compare-title'>Comparador de producto</h1>
      <div id='compare-product-desktop'>
        <div className='fields'>
          <div className='brand'>
            <i className='icon-tag'></i> Marca
          </div>
          <div className='brand'>
            <i className='icon-size'></i> Medidas
          </div>
          <div className='brand'>
            <i className='icon-material'></i> Material
          </div>
          <div className='brand'>
            <i className='icon-weight'></i> Peso
          </div>
          <div className='brand'>
            <i className='icon-color'></i> Color
          </div>
        </div>
        <div className='articles'>
          <Product className='main-article' {...main} />
          <div className='items' ref={slider}>
            {compare.map((product, productIndex) => (
              <Product key={productIndex} {...product} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompareProductDesktop;
