import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import MoneyFormat from '../../../components/MoneyFormat';
import AppButton from '../../../components/UI/Buttons';
import UserProduct from '../../../components/UI/UserProduct';
import useIsMobile from '../../../hooks/useIsMobile';
import { isRecreate, purchaseGetDetail, purchaseRecreate, setProductReview } from '../../../store/actions/history-purchase';
import ProfileContainer from '../ProfileContainer';


const MyPurchasesDescription = () => {
    const match = useRouteMatch()
    const dispatch = useDispatch()
    const params = useParams();
    const { reference, detail } = useSelector((state) => state.history)

    const isMobile = useIsMobile()
    const history = useHistory()



    const handleReview = (item) => {
        dispatch(setProductReview(item))
        history.push(`/perfil/mis-compras/${match.params.id}/${item.code}`)
    }

    const recreateOrder = (order_id) => {
        if (order_id) {
            const itemsLength = detail.products.length;
            const lastProduct = detail.products[itemsLength - 1]
            dispatch({ type: 'CART_LAST_ADDED', product: lastProduct });
            dispatch(purchaseRecreate(order_id));
            dispatch(isRecreate(true));
        }
    }

    useEffect(() => {
        if (reference || params.id) {
            dispatch(purchaseGetDetail(reference || params.id));
        } else {
            history.push(`/perfil/mis-compras`)
        }
    }, [dispatch, history, reference, params.id]);

    return (
        <ProfileContainer hideBreadcrumbs={true}>
            <section className="profile-purchases-description">
                <nav className="desc-nav">
                    {isMobile && <h2 className="mxp-subtitle">Detalle de la compra</h2>}
                    <AppButton
                        type="icon"
                        icon="cancel-circle"
                        modifier="close"
                        customClick={() => {
                            history.push('/perfil/mis-compras')
                        }}
                    />
                </nav>
                <div className="desc-info">
                    <div className="desc-general desc-product-id">
                        <div className="purchase-text">
                            <span className="mxp-text-clear">ID de compra:</span>
                            <span className="mxp-text desc-general-date">{detail.reference}</span>
                        </div>
                        <div className="purchase-text">
                            <span className="mxp-text-clear">Fecha de compra:</span>
                            <span className="mxp-text desc-general-date">{detail.date}</span>
                        </div>
                    </div>
                    <div className="desc-general desc-product-half">
                        <h3 className="desc-subtitle mxp-sub-subtitle">Datos generales</h3>
                        <div className="purchase-text">
                            <span className="mxp-text-clear">Nombre:</span>
                            <span className="mxp-text">{detail.name}</span>
                        </div>
                        <div className="purchase-text">
                            <span className="mxp-text-clear">Correo electrónico:</span>
                            <span className="mxp-text">{detail.email}</span>
                        </div>
                        <div className="purchase-text">
                            <span className="mxp-text-clear">Teléfono:</span>
                            <span className="mxp-text">{detail.phone}</span>
                        </div>

                        {
                            detail && detail.shipment && (
                                <div className="purchase-text purchase-text-address">
                                    <span className="mxp-text-clear">Dirección de envío:</span>
                                    <span className="mxp-text">
                                        {detail.shipment.full_address}<br />
                                        {detail.shipment.zip}<br />
                                        {detail.shipment.city}
                                    </span>
                                </div>
                            )
                        }
                        {
                            detail && detail.office && (
                                <div className="purchase-text purchase-text-address">
                                    <span className="mxp-text-clear">Dirección de envío:</span>
                                    <span className="mxp-text">
                                        {detail.office.address}<br />
                                        {detail.office.zip}<br />
                                        {detail.office.city}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    <div className="desc-general desc-product-half">
                        <h3 className="desc-subtitle mxp-sub-subtitle">Método de pago</h3>
                        <div className="purchase-text">
                            {detail.payment_method?.conekta && <>
                                <span className="mxp-text-clear">
                                    {detail.payment_method.conekta.type === 'oxxo' ? 'OXXO Pay' : 'Tarjeta'}
                                </span>
                                {
                                    detail.payment_method.conekta.account_type && (
                                        <span className="mxp-text">
                                            {detail.payment_method.conekta.account_type}<br />
                                            {`**** **** **** ${detail.payment_method.conekta.last4}`}
                                        </span>
                                    )}
                                {
                                    detail.payment_method.type === 'oxxo' && (
                                        <span className="mxp-text"> </span>
                                    )}
                            </>}
                        </div>
                        <div className="purchase-text">
                            {(detail.payment_type === 'loyalty' || detail.payment_method?.loyalty) && <>
                                <span className="mxp-text-clear">Monedero electrónico:</span>
                                <span className="mxp-text">
                                    {detail.loyalty_card}
                                </span>
                            </>}
                            {(detail.payment_method?.paypal || detail.payment_type === 'paypal') && <>
                                <span className="mxp-text-clear">Externo:</span>
                                <span className="mxp-text">Paypal</span>
                            </>}
                            {detail.monthly_installments && <>
                                <span className="mxp-text-clear">Pago a meses sin intereses</span>
                                <span className="mxp-text">{detail.monthly_installments} meses</span>
                            </>}
                        </div>
                    </div>
                    <div className="desc-general desc-product-products">
                        <h3 className="desc-subtitle mxp-sub-subtitle">Productos adquiridos</h3>
                        <div className="desc-products">
                            {
                                detail && detail.products && (
                                    detail.products.map((product, index) => {
                                        const cant = product.quantity > 1;
                                        const productRating = product.review.rating;
                                        return (
                                            <UserProduct product={product} key={index} cant={cant} action={(product) => handleReview(product)} ratingValue={productRating} allowRating={detail.delivery_status === 'Entregado'} />
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                    <div className={`${isMobile ? 'desc-product-check-mobile' : 'desc-product-check-desktop'}`}>

                        <div className="desc-general desc-product-check-item">
                            <div className="product-row">
                                <p className="mxp-text mxp-text-clear product-count-name">Subtotal:</p>
                                <p className="mxp-text mxp-text-clear product-count-number">
                                    <MoneyFormat type="mixed" money={detail.sub_total} />
                                </p>
                            </div>
                            <div className="product-row">
                                <p className="mxp-text mxp-text-clear product-count-name">IVA:</p>
                                <p className="mxp-text mxp-text-clear product-count-number">
                                    <MoneyFormat type="mixed" money={detail.iva} />
                                </p>
                            </div>
                            <div className="product-row">
                                <p className="mxp-text mxp-text-clear product-count-name">Costo de envío:</p>
                                <p className="mxp-text mxp-text-clear product-count-number">
                                    <MoneyFormat type="mixed" money={detail.shipment_total} />
                                </p>
                            </div>
                            <div className="product-row product-total">
                                <p className="mxp-text mxp-text-clear product-count-name">Total:</p>
                                <p className="mxp-text mxp-text-clear product-count-number">
                                    <MoneyFormat type="mixed" money={detail.total} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="desc-product-check-actions">
                        {
                            detail.payment_status === 'order.paid' && !detail.office && detail.tracker_id &&
                            <AppButton type="link" to={`/perfil/compra/${params.id}/rastrear`} txt="ESTATUS DEL PEDIDO" modifier="nowrap" available outline small />
                        }
                        {
                            detail.payment_status === 'order.paid' && detail.office && !detail.tracker_id &&
                            <AppButton type="link" to={`/perfil/compra/${params.id}/rastrear`} txt="ESTATUS DEL PEDIDO" modifier="nowrap" available outline small />
                        }
                        <AppButton type="main" txt="Volver a pedir" onClick={() => recreateOrder(detail.order_id)} available small />
                    </div>
                </div>
            </section>
        </ProfileContainer>
    )
}

export default MyPurchasesDescription
