import React from 'react';
import classNames from 'classnames';

const Loader = ({ id, className = '', active = true, ellipsis, spinner, scale = 1, ...rest }) => {
  if (ellipsis) {
    return (
      <div className='lds-ellipsis center'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
  if (spinner) {
    const {
      title
    } = spinner;
    const {initial, blue, white, darkBlue } = rest;
    const size = 80 * parseFloat(scale);
    return (
      <div className={classNames('lds-loader-spinner', {
        'initial': initial,
        'blue': blue,
        'white': white,
        'darkBlue': darkBlue,
      }, className)}>
        <div className="lds-loader-spinner-body">
          {title && <h4 className="lds-loader-spinner-title">{title}</h4>}
          <div className="lds-loader-spinner-wheel">
            <div className={classNames("lds-spinner", {

            })} style={{transform: `scale(${1 * scale})`, width: size, height: size}}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={`lds-ring${className ? ' ' + className : ''}${active ? ' active' : ''}`} id={id}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

Loader.defaultProps = {
  id: 'main-loader',
};

export default React.memo(Loader);
