import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import ProfileContainer from './../ProfileContainer';
import ModalBox from './../../../components/UI/ModalBox';
import ContainerAlert from './../../../components/Detail/ContainerAlert';
import { getCardsSaved, removeCardsSaved } from '../../../store/actions/purchase';
import { useRef } from 'react';

const body = document.querySelector('body');

const Card = ({ name, number, id = '123', type = 'visa', ...rest }) => {
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState(false);
  const [loader, setLoader] = useState(false);
  const positionY = useRef(false);

  const logos = {
    visa: 'icon-visa.png',
    mastercard: 'icon-mastercard.png',
    american_express: 'icon-american_express.png',
  };

  const handleOpenConfirmation = (isOpen) => {
    setConfirmation(isOpen);
    if (isOpen) {
      positionY.current = window.scrollY;
      body.classList.add('no-scroll');
      window.scroll(0, 0);
    } else {
      body.classList.remove('no-scroll');
      window.scroll(0, positionY.current);
    }
  };

  const handleDelete = () => {
    handleOpenConfirmation(false);
    setLoader(true);
    dispatch(removeCardsSaved(id));
    setLoader(false);
  };

  return (
    <>
      <ModalBox show={confirmation} modifier='mb-alerts-wrap'>
        <ContainerAlert cancelAction={handleOpenConfirmation} customAction={handleDelete} title='¿Estás seguro que deseas eliminar esta tarjeta? '></ContainerAlert>
      </ModalBox>
      <div className='card' {...rest}>
        {loader && <Loader spinner blue />}
        <div className='info'>
          <div className='logo'>
            <img src={`/img/cards/${logos[type]}`} alt={type} />
          </div>
          <div className='data'>
            <div className='name'>{name}</div>
            <div className='number'>{number}</div>
          </div>
        </div>
        <div className='actions'>
          <span className='trash' onClick={() => handleOpenConfirmation(true)}>
            <i className='icon-trash'></i>
          </span>
          <Link className='edit' to={`/perfil/tarjeta/${id}/editar`}>
            <i className='icon-edit'></i>
          </Link>
        </div>
      </div>
    </>
  );
};

const MyCardsPage = () => {
  const dispatch = useDispatch();
  const { cardsList, loaderCards } = useSelector((state) => state.purchase);

  useEffect(() => {
    dispatch(getCardsSaved());
  }, [dispatch]);

  if (!loaderCards && !cardsList.length) {
    return (
      <ProfileContainer className='profile-my-cards'>
        <h1 className="title-section">Mis tarjetas</h1>
        <div className='cards-not-found-alert'>
          <i className='icon-card-alert'></i>
          <p>Aún no tienes tarjetas agregadas.</p>
          <Link className='btn btn-primary rounded-small' to='/perfil/tarjeta/agregar'>
            Agregar tarjeta
          </Link>
        </div>
      </ProfileContainer>
    );
  }

  return (
    <ProfileContainer className='profile-my-cards'>
      {loaderCards && <Loader spinner={{ title: 'Cargando...' }} blue />}
      {!loaderCards && !!cardsList.length && (
        <>
          <h1 className='title-section'>Mis tarjetas</h1>
          <div className='card-list'>
            {cardsList.map((card) => (
              <Card type={card.brand} name={card.name} number={'**** **** **** ' + card.last4} key={card.id} id={card.id} />
            ))}
          </div>
          <div className='add-new-card'>
            <Link className='link' to='/perfil/tarjeta/agregar'>
              <i className='icon-plus-circle'></i> Agregar tarjeta
            </Link>
          </div>
        </>
      )}
      {!loaderCards && !cardsList.length && (
        <div className='not-found-address'>
          <i className='icon-pin'></i>
          <strong>Aún no tienes tarjetas agregadas.</strong>
          <div className='add-new-card'>
            <Link className='link' to='/perfil/tarjeta/agregar'>
              <i className='icon-plus-circle'></i> Agregar tarjeta
            </Link>
          </div>
        </div>
      )}
    </ProfileContainer>
  );
};

export default MyCardsPage;
