import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartAddItem } from '../../store/actions/cart';
import FakeSelect from '../FormElements/FakeSelect';
import Image from '../Image';
import MoneyFormat from '../MoneyFormat';
import useProductCart from './../../hooks/useProductCart';
import IconBox from './IconBox';

const AddToBox = ({ onClick }) => {
  const [percent, setPercent] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState({
    product: false,
    container: false,
  });

  const { product } = useSelector((state) => state.product);
  const { containers, last, hide_last, loader, products } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const productInCart = useProductCart(product.id || product.product_id);

  const addToCart = () => {
    if (!quantity || quantity === productInCart.quantity) {
      return;
    }
    dispatch(cartAddItem(product, quantity));
  };

  useEffect(() => {
    const lastContainer = containers ? [...containers].pop() : false;
    if (!lastContainer) return;

    setPercent(0);

    setTimeout(() => {
      setPercent(lastContainer?.percentage || 0);
    }, 1000);

    setCart({
      container: lastContainer,
    });
  }, [containers]);

  useEffect(() => {
    const find = products.find(item => item.product_id === product.id);
    if (find) {
      setQuantity(find.quantity)
    }
  }, [product.id, products])

  const quantityOptions = () => {
    const items = product.quantity_available >= 9 ? 9 : product.quantity_available;
    return new Array(items).fill(1).map((_, index) => index + 1);
  };

  const handleOnClickAction = () => {
    if (loader || !hide_last) return;
    if (onClick) return onClick();
  };


  return (
    <section id='add-to-box' className={classNames({ disable: onClick })} onClick={handleOnClickAction}>
      {!hide_last && (
        <div className={`added-confirmation ${cart.container.code}`}>
          <div className='quantity-items'>{last.quantity} producto(s) agregado(s) a tu caja</div>
          <div className='effect'>
            <Image alt='demo' src={product.media[0]?.sizes.small} />
            <IconBox name={cart.container.code} percent={percent} id='add-to-box-detail' />
            <div className='box-name'>{cart.container.name}</div>
          </div>
          <Link to='/box/detail' className='link'>
            IR AL PEDIDO
          </Link>
        </div>
      )}
      <div className='sticky'>
        <div className='details'>
          <h1>{product.name}</h1>
          <div className='price'>
            <MoneyFormat money={product.price} />
          </div>
        </div>
        <div className='actions'>
          <FakeSelect options={quantityOptions()} value={quantity || 1} onChange={setQuantity} className='quantity fake-select' up id='add-to-box-mobile-detail' customValueNumber />
          <button className={`btn btn-primary rounded${loader || !hide_last || quantity === productInCart.quantity ? ' disabled' : ''}`} onClick={addToCart}>
            {productInCart.product_id ? 'ACTUALIZAR' : 'AGREGAR'} <i className='icon-box-open-1' />
          </button>
        </div>
      </div>
    </section>
  );
};

export default AddToBox;
