import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { defaultImg } from '../helpers';
import { getReviews } from '../store/actions/products';
import RatingProduct from './RatingProduct';

const Comments = () => {
  const { reviews, product } = useSelector((state) => state.product);
  const isLoaded = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!reviews.length && !isLoaded.current) {
      isLoaded.current = true;
      dispatch(getReviews());
    }
  }, [dispatch, reviews]);

  if (!reviews.length) return '';

  return (
    <div id='comments'>
      <h1>Opiniones del producto</h1>
      <ul className='carousel'>
        {reviews.map((review, index) => {
          if(index > 2) return '';
          return (
            <li className='comment' key={index}>
              <div className='author'>
                <div className='photo'>
                  <img src={review.user_avatar} alt={review.name} onError={(event) => defaultImg(event)} />
                </div>
                <div className='name'>
                  <h1>{review.user_name}</h1>
                  <RatingProduct rating={review.rating} />
                </div>
              </div>
              <p>{review.comment}</p>
            </li>
          );
        })}
      </ul>
      <Link className='link' to={`/comentarios/${product.id}/${product.code}`}><i className="icon-plus-circle"></i> Ver más</Link>
    </div>
  );
};

export default React.memo(Comments);
