import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FakeSelect from '../FormElements/FakeSelect';
import { cartAddItem } from './../../store/actions/cart';
import classNames from 'classnames';

const AddToBox2 = ({ onClick }) => {
  const dispatch = useDispatch();

  const { product } = useSelector((state) => state.product);
  const { loader, products } = useSelector((state) => state.cart);

  const [quantity, setQuantity] = useState(1);
  const [productCart, setProductCart] = useState(false)

  const handleChange = () => {
    if (quantity) {
      dispatch(cartAddItem(product, quantity));
    }
  };

  useEffect(() => {
    const find = products.find(item => item.product_id === product.id);
    if (find) {
      setProductCart(find);
      setQuantity(productCart.quantity)
    } else {
      setProductCart(false)
    }
  }, [product.id, productCart.quantity, products])

  const quantityOptions = () => {
    const items = product.quantity_available >= 9 ? 9 : product.quantity_available;
    return new Array(items).fill(1).map((item, index) => index + 1);
  }

  const handleOnClickAction = () => {
    if (onClick) return onClick()
  }

  return (
    <div className={classNames('add-to-cart-options', { disable: onClick })} onClick={handleOnClickAction}>
      <FakeSelect className='quantity' placeholder='Cantidad' options={quantityOptions()} id='quantity-fake-select' customValueNumber={product.quantity_available > 9} onChange={setQuantity} value={quantity || 1} />
      <button className={`btn btn-primary rounded-small${loader ? ' btn-loader' : ''}${quantity === productCart.quantity ? ' disabled' : ''}`} onClick={handleChange}>
        {productCart ? 'ACTUALIZAR' : 'AGREGAR'} <i className='icon-box-open-1'></i>
      </button>
    </div>
  );
};

export default AddToBox2;
