import React from 'react';
import { connect } from 'react-redux';
import { addCart, deleteItemCart, updateItemCart, cartErrorStock } from '../store/actions/cart';
import { analyticsAddCart, analyticsRemoveCart } from '../store/actions/analytics-events';

class BtnAddToCart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      quantity: 0,
      error_stock: false,
      limit: false,
    }
    this.errorRef = React.createRef(false);
  }

  setErrorStock = () => {
    this.setState({ error_stock: true });
    setTimeout(() => this.setState({ error_stock: false }), 3000);
  };

  updateInputQuantity = ({ currentTarget }) => {
    if (this.props.parentElement?.current) {
      this.props.parentElement.current.scrollIntoView({ block: 'nearest' });
    }
    this.setState({ quantity: currentTarget.value.replace(/[^0-9]/g, '') });
  };

  updateItemCart = (add = true, input = false) => {
    if (input) {
      if (parseInt(this.state.quantity) !== this.props.itemCart.quantity) {
        if (!this.state.quantity || this.state.quantity < 1 || isNaN(this.state.quantity)) {
          this.setState({ loader: true, quantity: 0 });
          analyticsRemoveCart(this.props.product, this.props.itemCart.quantity);
          return this.props.deleteItemCart(this.props.productId);
        }

        const limit = this.state.limit || this.props.limit;

        let quantityInput = this.state.quantity;
        if (this.state.quantity > limit && this.props.itemCart.quantity === limit) {
          this.setErrorStock();
          return this.setState({ quantity: this.props.itemCart.quantity, error_stock: true });
        } else if (this.state.quantity > limit) {
          this.setErrorStock();
          quantityInput = limit;
        }

        analyticsAddCart(this.props.product, quantityInput);

        this.setState({ loader: true, quantity: quantityInput });

        return this.props.updateItemCart(this.props.productId, quantityInput);
      }
      return false;
    }
    if (this.props.parentElement?.current) {
      this.props.parentElement.current.scrollIntoView({ block: 'nearest' });
    }
    let quantity = this.props.itemCart.quantity;
    if (add && quantity < this.props.limit) {
      quantity++;
    } else if (!add) {
      quantity--;
    }
    if (quantity === 0) {
      this.setState({ loader: true });
      analyticsRemoveCart(this.props.product, this.props.itemCart.quantity);
      return this.props.deleteItemCart(this.props.productId);
    }

    if (this.props.itemCart.quantity !== quantity) {
      this.setState({ loader: true, quantity });
      analyticsAddCart(this.props.product, quantity);
      this.props.updateItemCart(this.props.productId, quantity);
    }
  };

  addCart = () => {
    if (!this.props.productId) return;
    analyticsAddCart(this.props.product, 1);

    this.setState({ loader: true, quantity: 1 });
    this.props.addCart(this.props.productId, this.props.product);
  };

  keyPress = (event) => {
    if (!/[\d]/.test(event.key) && ![8, 9, 13, 37, 39].includes(event.keyCode)) return event.preventDefault();
    if (event.keyCode === 13) {
      this.updateItemCart(false, true);
    }
  };

  componentDidMount() {
    const quantity = this.props.itemCart ? this.props.itemCart.quantity : 0;
    this.setState({ quantity });
  }

  componentDidUpdate(prevProps) {
    const prevItemCart = prevProps.itemCart || {};
    const itemCart = this.props.itemCart || {};

    if (prevItemCart.quantity !== itemCart.quantity && !this.props.errorStock) {
      const quantity = this.props.itemCart ? this.props.itemCart.quantity : 0;
      this.setState({ quantity });
    }

    if (this.props.errorStock && this.props.errorStock.id === this.props.productId && !this.errorRef.current) {
      this.errorRef.current = true;
      this.props.cartErrorStock();
      this.setState({ quantity: this.props.itemCart.quantity, error_stock: true, limit: this.props.errorStock.stock });
      setTimeout(() => {
        this.errorRef.current = false;
        this.setState({ error_stock: false });
      }, 3000);
    }
  }

  render() {
    return (
      <div className={`btn-add-to-cart${this.props.loader === this.props.productId ? ' active' : ''}${this.props.loader ? ' disabled' : ''}`}>
        {this.props.itemCart ? (
          <div className='add-cart in-cart'>
            <span className='icon-minus' onClick={() => this.updateItemCart(false)}></span>
            <input
              type='text'
              className='items-in-cart'
              value={this.state.quantity}
              min='0'
              max={this.state.limit || this.props.limit}
              onChange={(event) => this.updateInputQuantity(event)}
              onBlur={() => this.updateItemCart(false, true)}
              onKeyDown={(event) => this.keyPress(event)}
              inputMode='numeric'
            />
            <span className={`icon-plus${(this.state.limit || this.props.limit) === this.props.itemCart.quantity ? ' disabled' : ''}`} onClick={() => this.updateItemCart()}></span>
          </div>
        ) : (
          <div className='add-cart' onClick={() => this.addCart()}>
            Añadir a canasta
          </div>
        )}
        <div className='loader'></div>
        {this.state.error_stock && <div className='error-stock'>*Número máximo de piezas en stock.</div>}
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    loader: state.cart.loader,
    errorStock: state.cart.errorStock,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCart: (id, reload = false) => dispatch(addCart(id, reload)),
    updateItemCart: (id, quantity, reload = false) => dispatch(updateItemCart(id, quantity, reload)),
    deleteItemCart: (id) => dispatch(deleteItemCart(id)),
    cartErrorStock: () => dispatch(cartErrorStock(false)),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default React.memo(createConnect(BtnAddToCart));
