import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { moneyFormat } from '../../helpers/index';
import { analyticsRemoveCart } from '../../store/actions/analytics-events';
import { deleteItemCart } from '../../store/actions/cart';
import BasketItem from '../BasketItem';
import BtnAddToCart from '../BtnAddToCart';
import MoneyFormat from '../MoneyFormat';

const BasketItems = ({ products, orderID, close }) => {
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = useState(false);

  const deleteItem = (product) => {
    setToDelete(product.product_id);
    analyticsRemoveCart(product, product.quantity);
    dispatch(deleteItemCart(product.product_id));
  };

  useEffect(() => {
    setToDelete(false);
  }, [products]);

  return (
    <div className='cart-items'>
      {products &&
        products.map((product, index) => (
          <BasketItem key={index} idRightLeft={`product-delete-${product.product_id}`}>
            <article>
              <div className='image'>
                {product.media.length ? <img src={product.media[0].sizes.small} alt={product.media[0].alt} /> : <img src='/img/img-default-small.jpg' alt={product.name}></img>}
              </div>
              <div className='detail'>
                <h1>{product.name}</h1>
                <span className='trash-desktop' onClick={() => deleteItem(product)}>
                  <span className='icon-trash'></span>
                </span>
                <div className='price'>
                  <MoneyFormat money={product.price} />
                </div>
                <BtnAddToCart product={product} reload={true} productId={product.product_id} itemCart={product} limit={product.stock} />
              </div>
              <div className={`confirm-delete${toDelete === product.product_id ? ' erasing' : ''}`} id={`product-delete-${product.product_id}`}>
                <div>
                  <strong>{product.name}</strong>
                  <br />
                  <span className='price'>{moneyFormat(product.price)}</span>
                </div>
                <div className='delete' onClick={() => deleteItem(product)}>
                  <span className='icon-trash'></span>
                </div>
              </div>
            </article>
          </BasketItem>
        ))}
      {!orderID && (
        <div>
          <strong>
            No tienes productos agregados
            <br />a tu canasta.
          </strong>
          <Link onClick={() => close()} to='/categorias' className='btn btn-primary rounded got-cart'>
            IR AL CATÁLOGO
          </Link>
        </div>
      )}
    </div>
  );
};

export default React.memo(BasketItems);
