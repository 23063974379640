import Categories from '../pages/Box/Categories';
import Category from '../pages/Box/Category';
import DetailPage from '../pages/Box/DetailPage';
import HomePage from '../pages/Box/HomePage';
import SearchPage from '../pages/Box/Search';
import CategoryPage from '../pages/CategoryPage';
import Registration from '../pages/User/Registration';

const routes = [
  {
    id: 'basket-box-page',
    name: 'basket-box-page',
    title: 'Basket box',
    path: '/box',
    exact: true,
    template: 'empty',
    component: HomePage,
  },
  {
    id: 'categories-page',
    name: 'categories',
    title: 'Categorías',
    path: '/box/categorias',
    exact: true,
    component: Category,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'categories-page',
    name: 'categories',
    title: 'Categorías',
    path: '/categorias',
    exact: true,
    component: Category,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'category-page',
    name: 'category',
    title: 'Categoría',
    path: '/box/categoria/:categoryID/:slug',
    exact: true,
    component: Categories,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    breadcrumb_remove_title: true,
  },
  {
    id: 'search-page',
    name: 'search',
    title: 'Buscar',
    path: '/buscar/:search',
    exact: true,
    component: SearchPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
    breadcrumb_remove_title: true,
  },
  {
    id: 'detail-box-page',
    name: 'detail-box-page',
    title: 'detail box',
    path: '/box/detail',
    exact: false,
    template: 'empty',
    component: DetailPage,
    breadcrumbs: ['/'],
    breadcrumb_remove_title: true,
  },
  {
    id: 'registration',
    name: 'registro',
    title: 'Registro',
    path: '/box/registro',
    exact: true,
    component: Registration,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  },
  {
    id: 'testing-components',
    name: 'test',
    title: 'Test',
    path: '/box/test/:categoryID/:slug',
    exact: true,
    component: CategoryPage,
    breadcrumbs: ['/'],
    breadcrumb_mobile: ['/'],
    breadcrumb_remove_title_mobile: true,
  }
];

export default routes;
