import Cropper from 'cropperjs';
import React from 'react';

class CropImage extends React.Component {
  state = { image: false };
  componentDidMount() {
    this.$image = new Cropper(this.image, {
      aspectRatio: 1 / 1,
      viewMode: 2,
      dragMode: 'move',
      guides: false,
      movable: true,
      zoomable: false,
      scalable: true,
      minCropBoxHeight: 96,
      minCanvasWidth: 96,
    });
  }

  loadImg = async (data) => {
    let imageElement = new Image();
    imageElement.src = data;
    return (imageElement.onload = () => {
      return imageElement;
    });
  };

  save = () => {
    const imageBase64 = this.$image.getCroppedCanvas().toDataURL('image/jpeg', 1);

    var canvas = document.createElement('canvas');
    canvas.setAttribute('width', 200);
    canvas.setAttribute('height', 200);
    var ctx = canvas.getContext('2d');
    var image = new Image();
    image.src = imageBase64;
    image.onload = () => {
      ctx.drawImage(image, 0, 0, 200, 200);
      this.photo = ctx.canvas.toDataURL('image/jpeg', 1);
      this.props.save(this.photo);
    };
  };

  componentWillUnmount() {
    this.$image.destroy();
  }

  render() {
    if (!this.props.photo) return '';
    return (
      <div className='crop-image'>
        <div className="btns-cropper">
          {/* <span className='go-back' onClick={() => this.props.cancel()}>
            <i className='icon-left-open'></i> Cancelar
          </span> */}
          {/* <span className="btn-close" onClick={() => this.props.close()}><i className="icon-close"></i></span> */}
          <span className="btn-close" onClick={() => this.props.cancel()}><i className="icon-close"></i></span>
        </div>
        <div>
          <img src={this.props.photo} alt='avatar' ref={(el) => (this.image = el)} />
        </div>
        <span className='btn btn-primary' onClick={() => this.save()}>
          Guardar
        </span>
      </div>
    );
  }
}

export default CropImage;
