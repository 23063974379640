import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader';

const MostWanted = ({ wanted, loader }) => {
  return (
    <section className='most-wanted'>
      <div>
        <h1 className='title'>Búsquedas populares:</h1>
        {loader && <Loader spinner initial blue scale='0.5' />}
        {!loader && wanted && (
          <div className='searches'>
            {wanted.map((product, productIndex) => {
              const { code, name } = product;
              return (
                <Link to={`/buscar/${code.replace('-', '+')}`} key={productIndex}>
                  {name}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default MostWanted;
