import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useIsMobile from '../../hooks/useIsMobile'
import { cartAddItem } from '../../store/actions/cart'
import BtnAddFavorite from '../BtnAddFavorite'
import Image from '../Image'
import MoneyFormat from '../MoneyFormat'
import RatingProduct from '../RatingProduct'
import AppButton from '../UI/Buttons'


const FavoritesThumb = ({ product }) => {
    const { loader, products } = useSelector((state) => state.cart)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const [productLoader, setProductLoader] = useState(false)
    const [count, setCount] = useState(0)
    const [input, setInput] = useState(0)
    const isOnCard = products.filter(item => item.product_id === product.id)[0]

    const updateInput = () => {
        if (count === input) return;
        if (count !== input && input > 0) {
            dispatch(cartAddItem(product, input));
        }
    };

    const updateCart = (update) => {
        setProductLoader(true)
        const quantity = input + update;

        if (quantity < 1) {
            setInput(0);
        }
        setCount(quantity)
        setInput(quantity)
    };

    const handleChange = (event) => {
        const value = event.currentTarget.value.replace(/[^0-9]/g, '');
        const quantity = value < product.quantity_available ? value : product.quantity_available;
        setProductLoader(true)
        setCount(quantity)
        setInput(parseInt(quantity) || '');
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return updateInput();
        }
    };

    useEffect(() => {
        if (!loader) {
            setProductLoader(false)
        }
    }, [loader])


    const [cartExist, setCartExist] = useState(false)

    useEffect(() => {
        if (cartExist) return
        if (isOnCard !== undefined) {
            setCount(isOnCard.quantity)
            setInput(parseInt(isOnCard.quantity) || '');
        }
        return () => {
            setCartExist(true)
        }
    }, [cartExist, isOnCard])

    const available = count > 0

    return (
        <div className="favorites-product" key={product.id}>
            {isMobile && <div className="favorites-mobile-title">
                <h3 className="mxp-sub-subtitle">{product.name}</h3>
                <BtnAddFavorite id={product.id} />
            </div>}
            <div className="favorites-product-info">
                <figure className="favorites-product-img">
                    <Image src={product.media[0]?.sizes?.small} alt={product.name} />
                </figure>
                {isMobile && (
                    <div className="favorites-product-features">
                        <p className="favorites-product-brand">{product.brand_name}</p>
                        <RatingProduct rating={product.rating} />
                        <p className="favorites-product-price"><MoneyFormat type="mixed" money={product.price} /></p>
                    </div>)}
            </div>
            {!isMobile && (

                <div className="favorites-product-features">
                    <div className="favorites-mobile-title">
                        <h3 className="mxp-sub-subtitle">{product.name}</h3>
                        <BtnAddFavorite id={product.id} />
                    </div>
                    <div className="favorites-product-desktop">
                        <p className="favorites-product-brand">{product.brand_name}</p>
                        <RatingProduct rating={product.rating} />
                        <div className="favorites-product-features-flx">
                            <p className="favorites-product-price"><MoneyFormat type="mixed" money={product.price} /></p>
                        </div>
                    </div>
                    <div className="favorites-quantity">
                        <div className={`add-cart ${loader && productLoader ? 'loader-inline' : ''}`}>
                            <p>Cantidad:</p>
                            <div>
                                <span className={`icon-minus-circle${!count ? ' disable' : ''}`} onClick={() => updateCart(-1)}></span>
                                <input type='text' className='quantity' name='quantity' value={input} onBlur={updateInput} onChange={handleChange} onKeyDown={handleKeyDown} />
                                <span className={`icon-plus-circle${count >= product.quantity_available ? ' disable' : ''}`} onClick={() => updateCart(1)}></span>
                            </div>
                        </div>
                    </div>
                    <nav className="favorites-product-nav">
                        <AppButton
                            type="main"
                            txt={'Agregar'}
                            customClick={() => {
                                dispatch(cartAddItem(product, count));
                            }}
                            available={available}
                        />
                    </nav>
                </div>
            )
            }
            {isMobile && (
                <>
                    <div className="favorites-quantity">
                        <div className={`add-cart ${loader && productLoader ? 'loader-inline' : ''}`}>
                            <p>Cantidad:</p>
                            <div>
                                <span className={`icon-minus-circle${!count ? ' disable' : ''}`} onClick={() => updateCart(-1)}></span>
                                <input type='text' className='quantity' name='quantity' value={input} onBlur={updateInput} onChange={handleChange} onKeyDown={handleKeyDown} />
                                <span className={`icon-plus-circle${count >= product.quantity_available ? ' disable' : ''}`} onClick={() => updateCart(1)}></span>
                            </div>
                        </div>
                    </div>
                    <nav className="favorites-product-nav">
                        <AppButton
                            type="main"
                            txt={'Agregar'}
                            customClick={() => {
                                dispatch(cartAddItem(product, count));
                            }}
                            available={available}
                        />
                    </nav>
                </>
            )}
        </div >

    )
}

export default FavoritesThumb
