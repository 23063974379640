import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import AlertMessage from '../../../components/AlertMessage';
import Loader from '../../../components/Loader';
import { purchaseGetTracking } from '../../../store/actions/history-purchase';
import ProfileContainer from '../ProfileContainer';
import { purchaseGetDetail } from './../../../store/actions/history-purchase';

const MyPurchaseTracking = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { tracking, detail } = useSelector((state) => state.history);

  useEffect(() => {
    dispatch(purchaseGetTracking(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!detail) {
      dispatch(purchaseGetDetail(params.id));
    }
  }, [dispatch, params.id, detail]);

  const isCanceled = () => {
    return tracking.items?.findIndex((item) => item.status === 'Cancelado' && item.is_active);
  };

  return (
    <ProfileContainer className='my-purchase-tracking'>
      <h1 className='title-section'>Rastreo del pedido</h1>
      {!tracking.message && (
        <>
          <div className='purchase-info'>
            <div className='purchase-id'>
              <strong>ID de compra:</strong>
              <span>{params.id}</span>
            </div>
            <div className='purchase-date'>
              <strong>Fecha de compra:</strong>
              <span>{detail.date}</span>
            </div>
          </div>
          <h2>RASTREO DEL PEDIDO</h2>
          {!tracking && <Loader spinner={{ title: 'Cargando' }} blue />}
          {tracking.items?.length && (
            <ul className='status-list'>
              {tracking.items.map((item, itemIndex) => {
                if ((item.status === 'Cancelado' && !item.is_active) || (item.status !== 'Cancelado' && item.status !== 'Pendiente de recolección' && isCanceled() > 1)) return '';

                if (!detail.tracker_id && item.status === 'En tránsito') return '';

                return (
                  <li key={itemIndex} className={item.is_active ? 'active' : ''}>
                    <strong>{item.status_detail}</strong>
                    {item.date}
                    <br />
                    {item.time && (
                      <>
                        <br />
                        {item.time}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {tracking && <Link to={`/perfil/mis-compras/${params.id}`} className='btn btn-primary rounded-small'>
            REGRESAR
          </Link>}
        </>
      )}
      <AlertMessage message={tracking.message}>
        <Link to={`/perfil/mis-compras/${params.id}`} className='btn btn-primary rounded-small'>
          REGRESAR
        </Link>
      </AlertMessage>
    </ProfileContainer>
  );
};

export default MyPurchaseTracking;
