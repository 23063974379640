import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const logged = useSelector((state) => state.user.logged);
  return <Route {...rest} component={() => (logged ? <Component /> : <Redirect to='/box' />)} />;
};

export default React.memo(PrivateRoute)
