import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import { historyReset } from '../../store/actions/history-purchase';
import useIsMobile from './../../hooks/useIsMobile';
import MyProfileMenu from './Profile/MyProfileMenu';

const paths = {
  profile: ['/perfil/datos-personales', '/perfil/cambiar-contrasena', '/perfil/mis-direcciones', '/perfil/mis-tarjetas'],
  purchases: [],
  wallet: [],
};

const isActive = (location, section) => {
  return paths[section].includes(location.pathname.replace(/\/$/, ''));
};

const ContainerDesktop = ({ children }) => {
  return (
    <div className='profile-container-desktop'>
      <div className='profile-sidebar'>
        <h1>Mi cuenta</h1>
        <ul>
          <li className='root-menu'>
            <NavLink className='profile' to='/perfil/datos-personales/' isActive={(match, location) => isActive(location, 'profile')}>
              <i className='icon-login-2'></i>
              <strong>
                Mi perfil <i className='icon-right-open'></i>
              </strong>
            </NavLink>
            <MyProfileMenu single />
          </li>
          <li className='root-menu'>
            <NavLink to='/perfil/mis-compras'>
              <i className='icon-box-open-1 link-purchases-icon'></i>
              <strong>
                Mis compras
                <i className='icon-right-open'></i>
              </strong>
            </NavLink>
          </li>
          <li className='root-menu'>
            <NavLink to='/perfil/mis-favoritos'>
              <i className='icon-box-open-1 icon-heart-1-empty'></i>
              <strong>
                Mis Favoritos
                <i className='icon-right-open'></i>
              </strong>
            </NavLink>
          </li>
          <li className='root-menu'>
            <NavLink to='/perfil/monedero'>
              <i className='icon-card-2 link-wallet-icon'></i>{' '}
              <strong>
                Monedero electrónico
                <i className='icon-right-open'></i>
              </strong>
            </NavLink>
          </li>
          <li className='root-menu'>
            <NavLink to='/perfil/tutoriales'>
              <i className='icon-play-empty link-tutorial-icon'></i>{' '}
              <strong>
                Tutoriales
                <i className='icon-right-open'></i>
              </strong>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className='profile-content'>{children}</div>
    </div>
  );
};

const ProfileContainer = ({ ...rest }) => {
  const { children, className, hideBreadcrumbs } = rest
  const isMobile = useIsMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!location.pathname.includes('mis-compras') && !location.pathname.includes('rastrear')) {
      dispatch(historyReset());
    }
  }, [dispatch, location])
  return (
    <ContainerBoxPage className={`profile-page${className ? ' ' + className : ''}`}>
      <div className='profile-page-container'>
        {!hideBreadcrumbs && <Breadcrumbs />}
        {isMobile && <>{children}</>}
        {!isMobile && <ContainerDesktop>{children}</ContainerDesktop>}
      </div>
    </ContainerBoxPage>
  );
};

export default React.memo(ProfileContainer);
