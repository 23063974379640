import React from 'react';
import { Link } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import Carousel from '../Carousel';
import Loader from '../Loader';
import ProductThumb from './ProductThumb';


// cat, customCatLoader, type

const FeaturedProducts = ({ title, subtitle, products = false, link, loader, list }) => {
  const isMobile = useIsMobile();

  return (
    <section className='sector-featured'>
      <div>
        {title && <h1>{title}</h1>}
        {(subtitle || link) && (
          <strong>
            {subtitle}
            {link && !loader && products && (
              <Link to={`/box/${link}`}>
                Ver más<i className='icon-plus-circle'></i>
              </Link>
            )}
          </strong>
        )}
        {(!products || loader) && <Loader ellipsis />}
        {isMobile && products && !loader && (
          <ul>
            {products.slice(0, 6).map((product, productIndex) => (
              <li key={productIndex}>
                <ProductThumb product={product} list={list} />
              </li>
            ))}
          </ul>
        )}

        {!isMobile && products && !loader && (
          <Carousel className='carousel-featured-products' hasArrows>
            {products.slice(0, 9).map((product, productIndex) => (
              <ProductThumb key={productIndex} product={product} list={list} />
            ))}
          </Carousel>
        )}
        {link && !loader && products && (
          <Link to={`/box/${link}`} className='see-more'>
            Ver más<i className='icon-plus-circle'></i>
          </Link>
        )}
      </div>
    </section>
  );
};

export default React.memo(FeaturedProducts);
