import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ProfileContainer from '../ProfileContainer'
import classNames from 'classnames'
import FavoritesThumb from '../../../components/User/FavoritesThumb';
import useIsMobile from '../../../hooks/useIsMobile';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Loader';


const MyFavorites = () => {
    const { products, favorites_loader } = useSelector((state) => state.favorites);
    const favoritesExist = products !== undefined && products.length !== 0
    const favorites = products
    const isMobile = useIsMobile()

    const [pageNumber, setPageNumber] = useState(0)
    const favoritesPerPage = 4
    const pagesVisited = pageNumber * favoritesPerPage
    const displayFavorites = favorites.slice(pagesVisited, pagesVisited + favoritesPerPage)

    const pageCount = Math.ceil(favorites.length / favoritesPerPage)

    const changePage = ({ selected }) => {
        window.scroll(0, 0);
        setPageNumber(selected)
    }

    return (
        <ProfileContainer className='profile-favorites-wrapper'>
            <div className={classNames({
                "profile-favorites": true,
                "profile-favorites--action": !favoritesExist
            })}>
                {isMobile && <h2 className="profile-favorites-title">Mis favoritos</h2>}
                {favoritesExist && !favorites_loader && (
                    products.length > 10 && (
                        <div className="profile-favorites-paginated">
                            <div className="profile-favorites-results">
                                {displayFavorites.map((product) => {
                                    return <FavoritesThumb product={product} key={product.id} />
                                })}
                            </div>
                            <ReactPaginate
                                previousLabel="Anterior"
                                nextLabel="Siguiente"
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName="profile-favorites-pagination"
                                previousLinkClassName="profile-favorites--prev"
                                nextLinkClassName="profile-favorites--next"
                            />
                        </div>
                    )
                )}
                {favoritesExist && products.length < 10 && !favorites_loader && (
                    favorites.map((product) => {
                        return <FavoritesThumb product={product} key={product.id} />
                    })
                )}
                {!favoritesExist && !favorites_loader && (
                    <div className="profile-favorites-not">
                        <div className="icon-heart"></div>
                        <h2 className="mxp-subtitle">No cuentas con productos favoritos.</h2>
                    </div>
                )}
                {favorites_loader && <Loader spinner={{ title: 'Cargando' }} blue />}
            </div>
        </ProfileContainer>
    )
}

export default MyFavorites
