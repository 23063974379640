import React from 'react'
import ContainerCheck from './ContainerCheck'
import ContainerProductList from './ContainerProductList'

function ContainerInfo({ loader, toDelete, products, setToDelete, setShowModal, setProductTo }) {
    return (
        <div className="container-products">
            <ContainerProductList
                loader={loader}
                toDelete={toDelete}
                products={products}
                setToDelete={setToDelete}
                setShowModal={setShowModal}
                setProductTo={setProductTo}
            />
            <ContainerCheck products={products} />
        </div>
    )
}

export default ContainerInfo
