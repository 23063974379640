import React from 'react';
import PersonalInformation from './PersonalInformation';
import PaymentMethod from './PaymentMethod';
import ClosingSales from '../ClosingSales';
import ConfirmPayment from '../ConfirmPayment';

class PurchaseUserGuest extends React.Component {
  render() {
    return (
      <div id='purchase-user-guest'>
        {this.props.step === 1 && <PersonalInformation />}
        {this.props.step === 2 && <PaymentMethod />}
        {this.props.step === 3 && <ClosingSales />}
        {this.props.step === 4 && <ConfirmPayment />}
      </div>
    );
  }
}

export default PurchaseUserGuest;
