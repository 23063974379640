import React from 'react';

const BoxHeaderIcon = ({ percent, ...rest }) => {
  return (<div id='box-header-icon' {...rest}>
    <svg width="1em" height="0.9333em" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">

<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="14">
<rect width="45" height="14" fill="white"/>
</mask>

<g mask="url(#mask0)">
<path className={`fill-box step3${percent >= 66 ? ' active' : ''}`} d="M19.3962 3.80078L19.4382 3.91678L19.4752 4.02678C19.7632 4.90178 20.7212 8.2768 20.2912 13.4008H6.10919C5.60619 7.4028 7.00419 3.80078 7.00419 3.80078H19.3962ZM35.8642 8.6008C36.3722 8.6008 36.8242 8.8958 36.9842 9.3508C37.4902 10.8008 37.8432 12.2388 38.0002 13.4008H25.2002C25.3592 12.2378 25.7172 10.7978 26.2232 9.3508C26.3812 8.8968 26.8332 8.6008 27.3432 8.6008H35.8642Z" fill="#00A6FB" style={{opacity: 0}} />
<path className={`fill-box step1${percent > 0 ? ' active' : ''}`} fillRule="evenodd" clipRule="evenodd" d="M32.042 13.3996L34 0.599609H10L11.958 13.3996H32.042Z" fill="#00A6FB" fillOpacity="0.5" style={{opacity: 0}} />
<path className={`fill-box step2${percent >= 33 ? ' active' : ''}`} fillRule="evenodd" clipRule="evenodd" d="M19.9426 13.3999L20.4606 12.3689C20.8106 11.6719 20.7026 10.8189 20.1896 10.2199C19.5526 9.4789 19.3566 8.3819 19.9776 6.7699C20.9186 4.32588 23.7076 1.77088 24.8796 2.25988C26.1146 2.77588 26.3726 6.6009 25.4316 9.0459C24.8116 10.6579 23.9416 11.3109 22.9896 11.3889C22.2216 11.4529 21.5776 11.9919 21.3676 12.7469L21.1876 13.3999H19.9426Z" fill="white" style={{opacity: 0}} />
</g>


<path d="M44.323 22.3364L41.602 14.4584C41.383 13.8244 40.784 13.3984 40.109 13.3984H4.69603C4.02203 13.3984 3.42203 13.8244 3.20403 14.4584L0.482028 22.3364C0.325028 22.7934 0.397028 23.2964 0.676028 23.6914C0.955028 24.0864 1.40703 24.3244 1.89303 24.3314L4.57303 24.5384V35.3024C4.57503 36.4224 5.32703 37.4024 6.41203 37.7024L21.124 41.2284C21.533 41.3404 21.954 41.3984 22.378 41.3984H22.398C22.825 41.3984 23.248 41.3384 23.658 41.2204L38.448 37.5064C39.5 37.2014 40.224 36.2444 40.228 35.1564V24.4964L42.902 24.3324C43.388 24.3264 43.842 24.0884 44.122 23.6934C44.403 23.2984 44.476 22.7934 44.318 22.3364H44.323ZM1.95603 23.2894H1.91603C1.76603 23.2894 1.62603 23.2184 1.53803 23.0974C1.45003 22.9754 1.42703 22.8204 1.47503 22.6784L4.19703 14.7984C4.26903 14.5854 4.47003 14.4424 4.69703 14.4434H21.729L19.235 24.3134C19.17 24.4764 19.015 24.5854 18.839 24.5934L1.95603 23.2894ZM21.878 40.3184C21.714 40.2944 21.552 40.2604 21.392 40.2164L6.68003 36.6914C6.05203 36.5174 5.61703 35.9494 5.61603 35.3014V24.6304L18.791 25.6364H18.831C19.466 25.6314 20.028 25.2284 20.234 24.6304C20.239 24.6174 20.244 24.6034 20.247 24.5894L21.878 18.1394V40.3184ZM39.182 35.1564C39.181 35.7734 38.775 36.3174 38.181 36.4964L23.387 40.2114C23.237 40.2544 23.084 40.2874 22.929 40.3104V18.0424L24.571 24.3344V24.3684C24.779 24.9684 25.347 25.3714 25.985 25.3714H26.016L39.182 24.5564V35.1564ZM43.267 23.0954C43.181 23.2164 43.041 23.2884 42.892 23.2884H42.857L25.972 24.3334C25.793 24.3264 25.636 24.2164 25.57 24.0504L23.082 14.4444H40.109C40.331 14.4484 40.527 14.5904 40.598 14.7994L43.33 22.6774C43.379 22.8174 43.355 22.9744 43.267 23.0954Z" fill="#ED7202"/>

{percent > 0 && <path d="M7.82957 33.9984L14.4136 35.0824V35.0724C14.9286 35.1554 15.2936 35.6174 15.2556 36.1344C15.2166 36.6524 14.7856 37.0544 14.2636 37.0604C14.1856 37.0604 14.1066 37.0504 14.0306 37.0304L7.58256 35.5664C7.19256 35.4544 6.94756 35.0724 7.00956 34.6744C7.07256 34.2754 7.42457 33.9864 7.82957 33.9984Z" fill="#00A6FB" />}
</svg>

  </div>);
};

export default React.memo(BoxHeaderIcon);
