import React, { Fragment } from 'react';
import Modal from './Modal';
import { connect } from 'react-redux';
import Input from './Input';
import { withRouter } from 'react-router-dom';
import { validateEmail } from '../helpers';
import { userLogin } from '../store/actions/user';
import moveInput from '../helpers/input-auto-scroll-viewport';

class GoToCartAlert extends React.Component {
  state = {
    login: {
      username: false,
      password: false,
    },
  };

  login = (event) => {
    event.preventDefault();
    this.props.login({
      email: this.state.login.username,
      password: this.state.login.password,
    });
  };

  goToRegister = () => {
    this.props.close();
    this.props.closeMiniCart();
    this.props.userOpenForm('register');
  };

  goToRecovery = () => {
    this.props.close();
    this.props.closeMiniCart();
    this.props.userOpenForm('recovery');
  };

  goCart = () => {
    this.close();
    this.props.closeMiniCart();
    this.props.history.push('/carrito');
  };

  update = (name, value) => {
    const login = this.state.login;
    login[name] = value;
    this.setState({ login });
  };

  close = () => {
    this.setState({ login: { username: false, password: false } });
    this.props.close();
  };

  isLoggedRedirect = () => {
    if (this.props.open && this.props.isLogged && window.location.pathname !== '/carrito') {
      this.props.userOpenForm('');
      this.props.closeMiniCart();
      return this.props.history.push({ pathname: '/carrito' });
    }
    if (this.props.open && this.props.isLogged && window.location.pathname === '/carrito') {
      window.location.reload();
    }
  };

  componentDidMount() {
    this.isLoggedRedirect();

    const inputsModal = document.querySelectorAll('.go-to-cart-alert .ps input');
    inputsModal.forEach((input) => {
      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, '.go-to-cart-alert .ps', 0, 500);
      });
    });
  }

  componentDidUpdate() {
    this.isLoggedRedirect();

    const inputsModal = document.querySelectorAll('.go-to-cart-alert .ps input');
    inputsModal.forEach((input) => {
      input.removeEventListener('focus', (event) => {
        moveInput(event.currentTarget, '.go-to-cart-alert .ps', 0, 500);
      });

      input.addEventListener('focus', (event) => {
        moveInput(event.currentTarget, '.go-to-cart-alert .ps', 0, 500);
      });
    });
  }

  render() {
    return (
      <Modal open={this.props.open} close={() => this.close()} className={`go-to-cart-alert${this.props.fail ? ' fail' : ''}`}>
        {this.props.open && !this.props.fail && (
          <Fragment>
            <h1>¡Pst!</h1>
            <p>Para realizar tu compra de manera más fácil y en menor tiempo, inicia sesión.</p>
            <form action='#' onSubmit={(event) => this.login(event)}>
              <Input
                placeholder='Correo electrónico'
                icon='icon-mail'
                update={(value, error) => this.update('username', value, error)}
                pattern={validateEmail}
                value={this.state.login.username}
                name='email'
                inputMode='email'
              />
              <Input placeholder='Contraseña' icon='icon-key' type='password' update={(value, error) => this.update('password', value, error)} value={this.state.login.password} name='password' />
              <button className={`btn btn-primary rounded${this.props.loader ? ' btn-loader' : ''}`} disabled={!this.state.login.username || !this.state.login.password}>
                Iniciar
              </button>
              <span className='link recovery' onClick={() => this.goToRecovery()}>
                Recuperar contraseña
              </span>
              <div className='register-option'>
                ¿Aún no tienes una cuenta?
                <br />
                <span className='link btn-register' onClick={() => this.goToRegister()}>
                  REGÍSTRATE AQUÍ
                </span>
                <hr />
                <span className='link btn-continue' onClick={() => this.goCart()} to='/carrito'>
                  CONTINUAR COMO INVITADO <i className='icon-right-open'></i>
                </span>
              </div>
            </form>
          </Fragment>
        )}

        {this.props.fail && (
          <div className='msg-fail'>
            <span className='icon'>
              <i className='icon-close'></i>
            </span>
            <h3>Lo sentimos</h3>
            <p>
              {this.props.fail.message}
              <br />
              <span className='btn btn-primary rounded' onClick={() => this.props.clearError()}>
                Regresar
              </span>
            </p>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateProps = (state) => {
  return {
    open: state.cart.goToCartAlert,
    loader: state.user.loader,
    isLogged: state.user.logged,
    fail: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch({ type: 'CART_GO_TO_CHECKOUT_ALERT' }),
    closeMiniCart: () => dispatch({ type: 'CART_OPEN_MINICART' }),
    userOpenForm: (open) => dispatch({ type: 'USER_OPEN', open }),
    login: (userData) => dispatch(userLogin(userData)),
    clearError: () => dispatch({ type: 'USER_CLEAR_ERROR' }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default React.memo(withRouter(createConnect(GoToCartAlert)));
