import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from '../../components/Cart/Container';
import ContainerBoxPage from '../../components/containers/ContainerBoxPage';
import Image from '../../components/Image';
import { purchaseLoyaltyGet, purchasePaymentLoyalty, purchaseSetPaymentMethod } from '../../store/actions/purchase';
import AddFromLoyaltyCard from './../../components/Cart/AddFromLoyaltyCard';
import PaymentCard from './../../components/Cart/PaymentCard';
import PaymentLoyalty from './../../components/Cart/PaymentLoyalty';
import useIsMobile from './../../hooks/useIsMobile';
import { purchaseSetMSI, clearErrorPayment } from './../../store/actions/purchase';
import { checkoutAnalytics } from './../../store/actions/analytics-events';

const PaymentMethodPage = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const history = useHistory();

  const { logged } = useSelector((state) => state.user);
  const {
    paymentMethod: method,
    personalInfo: { zip },
    loyalty,
    loyalty_points,
    shipping
  } = useSelector((state) => state.purchase);

  const {total} = useSelector(state => state.cart)

  const { products, containers } = useSelector((state) => state.cart);
  const { officeID } = useSelector((state) => state.purchase);
  const { offices } = useSelector((state) => state.home);

  const loaded = useRef();

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;

      const option = officeID ? 'Recoger en sucursal' : 'Entrega a domicilio';

      const office = offices.find(item => item.id === officeID) || false;

      const entrega = office.name || 'nombre paqueteria';

      checkoutAnalytics({ step: 3, products, containers, option, entrega});
    }
  }, [products, containers, officeID, offices]);

  const handlePaymentMethod = useCallback((method) => dispatch(purchaseSetPaymentMethod(method)), [dispatch]);

  useEffect(() => {
    if (!isMobile && !method) {
      handlePaymentMethod('tc');
    }
  }, [handlePaymentMethod, isMobile, method]);

  useEffect(() => {
    dispatch(purchaseSetMSI(false));
    dispatch(clearErrorPayment());
  }, [dispatch])

  const setPaymentMethod = (method) => {
    handlePaymentMethod(method);
    history.push('/carrito/realizar-pago');
  };

  useEffect(() => {
    if (!zip) {
      history.replace('/carrito/datos-generales');
    }
  }, [zip, history]);

  useEffect(() => {
    if (!loyalty && logged) {
      dispatch(purchaseLoyaltyGet());
    }
    dispatch(purchasePaymentLoyalty(0));
  }, [loyalty, logged, dispatch]);

  return (
    <ContainerBoxPage className='purchase-process payment-method-page'>
      <Container>
        {logged && loyalty?.last4 && <AddFromLoyaltyCard />}
        <section className={`methods${method ? ` selected ${method}` : ''}`}>
          <h1>Selecciona tu forma de pago:</h1>
          <div className={`method method-tc${method === 'tc' ? ' active' : ' disabled'}`}>
            <div onClick={() => handlePaymentMethod('tc')}>
              <i className='icon-card-2'></i>
              Tarjeta de crédito o débito
            </div>
            {isMobile && <i className='close icon-cancel-circle' onClick={() => handlePaymentMethod(false)} />}
            {!isMobile && (
              <div className='icon'>
                <i className='icon-ok circle'></i>
              </div>
            )}
          </div>
          <div className={`method method-paypal${method === 'paypal' ? ' active' : ' disabled'}`}>
            <div onClick={() => handlePaymentMethod('paypal')}>
              <Image src={`/img/cart/icon-paypal-${method === 'paypal' ? 'enabled' : 'disabled'}.png`} alt='Pago con tarjeta' />
              PayPal
            </div>
            {isMobile && <i className='close icon-cancel-circle' onClick={() => handlePaymentMethod(false)} />}
            {!isMobile && (
              <div className='icon'>
                <i className='icon-ok circle'></i>
              </div>
            )}
          </div>
          {(total + (shipping.total || 0) - loyalty_points) < 10000 && <div className={`method method-oxxo${method === 'oxxo' ? ' active' : ' disabled'}`}>
            <div onClick={() => handlePaymentMethod('oxxo')}>
              <i className='icon-ticket'></i>
              Pago en OXXO
            </div>
            {isMobile && <i className='close icon-cancel-circle' onClick={() => handlePaymentMethod(false)} />}
            {!isMobile && (
              <div className='icon'>
                <i className='icon-ok circle'></i>
              </div>
            )}
          </div>}
          {logged && loyalty?.last4 && (
            <div className={`method method-loyalty${method === 'loyalty' ? ' active' : ' disabled'}`}>
              <div onClick={() => handlePaymentMethod('loyalty')}>
                <i className='icon-card-2'></i>
                Monedero electrónico
              </div>
              {isMobile && <i className='close icon-cancel-circle' onClick={() => handlePaymentMethod(false)} />}
              {!isMobile && (
                <div className='icon'>
                  <i className='icon-ok circle'></i>
                </div>
              )}
            </div>
          )}
        </section>
        {method && (
          <div className='method-info'>
            {method === 'tc' && <PaymentCard />}
            {method === 'paypal' && (
              <>
                <div className='method-info-content'>
                  <div className='info'>Se desplegará una ventana emergente hacia el sitio de PayPal.</div>
                  <div className='warning'>
                    <i className='icon-alert'></i>
                    <div>Si no se despliega alguna ventana emergente, revisa la configuración de tu navegador para otorgarle permisos correspondientes.</div>
                  </div>
                </div>
                <button className='btn btn-primary' onClick={() => setPaymentMethod('paypal')}>
                  CONTINUAR
                </button>
              </>
            )}
            {method === 'oxxo' && (
              <>
                <div className='method-info-content'>
                  <div className='info'>Se generará tu código de pago una vez que confirmes tu información.</div>
                </div>
                <button className='btn btn-primary' onClick={() => setPaymentMethod('oxxo')}>
                  CONTINUAR
                </button>
              </>
            )}
            {method === 'loyalty' && <PaymentLoyalty />}
          </div>
        )}
      </Container>
    </ContainerBoxPage>
  );
};

export default PaymentMethodPage;
