export const buttons = {
    closeButton: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: 500
        },
    },
    moveImg: {
        origin: {
            x: 0
        },
        moved: {
            x: -40
        },
    },
    tagSel: {
        visible: {
            x: 10,
            opacity: 0
        },
        hidden: {
            x: -6,
            opacity: 1
        }
    },
    tagMove: {
        visible: {
            x: 0
        },
        hidden: {
            x: -6
        }
    }
}