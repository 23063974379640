export const imageUrl = (image, spacer = false) => {
  if (!image) return spacer ? '/img/spacer.png' : '/img/img-default.jpg';

  return image;
};
export const defaultImg = (event) => (event.currentTarget.src = '/img/img-default.jpg');

export const isMobile = (size = 1024) => window.innerWidth < size;

export const mobileDevice = () =>
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    navigator.userAgent || navigator.vendor || window.opera,
  );

export const imageZoom = async (img, resultID = false) => {
  let result = false;

  result = resultID && document.getElementById(resultID) ? document.getElementById(resultID) : document.querySelector('.img-zoom-result');
  if (!result) {
    result = document.createElement('DIV');
    result.setAttribute('class', 'img-zoom-result');
    img.parentElement.insertBefore(result, img);
  }

  result.style.display = 'block';
  if (result.offsetHeight !== result.offsetWidth) {
    result.style.height = result.offsetWidth + 'px';
  }

  const checkLens = () => {
    if (!lens) {
      lens = document.createElement('DIV');
      lens.setAttribute('class', 'img-zoom-lens');
      lens.addEventListener('mousemove', moveLens);
      lens.addEventListener('touchmove', moveLens);
      img.parentElement.insertBefore(lens, img);
    }
  };

  let lens = document.querySelector('.img-zoom-lens');
  checkLens();

  let cx = result.offsetWidth / lens.offsetWidth;
  let cy = result.offsetHeight / lens.offsetHeight;

  result.style.backgroundImage = "url('" + img.src + "')";
  result.style.backgroundSize = img.width * cx + 'px auto';

  img.addEventListener('mousemove', moveLens);
  img.addEventListener('touchmove', moveLens);
  img.addEventListener('mouseleave', destroy);
  function moveLens(event) {
    event.preventDefault();
    const pos = getCursorPos(event);
    let x = pos.x - lens.offsetWidth / 2;
    let y = pos.y - lens.offsetHeight / 2;
    if (x > img.width - lens.offsetWidth) {
      x = img.width - lens.offsetWidth;
    }
    if (x < 0) {
      x = 0;
    }
    if (y > img.height - lens.offsetHeight) {
      y = img.height - lens.offsetHeight;
    }
    if (y < 0) {
      y = 0;
    }
    lens.style.left = x + 'px';
    lens.style.top = y + 'px';
    result.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px';
  }
  function getCursorPos(event) {
    event = event || window.event;
    const element = img.getBoundingClientRect();
    let x = event.pageX - element.left;
    let y = event.pageY - element.top;
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }
  function destroy() {
    const zoomLens = document.querySelector('.img-zoom-lens');
    if (zoomLens) {
      zoomLens.remove();
    }
    const result = document.querySelector('.img-zoom-result');
    if (result) {
      result.remove();
    }

    if (resultID) {
      const resultContainer = document.getElementById(resultID);
      resultContainer.style.display = 'none';
    }
  }
};

export const imagesZoom = (container = false) => {
  const images = document.querySelectorAll('.image-zoom');
  images.forEach((element) => {
    element.addEventListener('mouseover', (event) => {
      const { currentTarget } = event;
      imageZoom(currentTarget, container);
    });
  });
};

export const moneyFormat = (value, locale = 'en-US') => {
  if (isNaN(value) || value === 0) return '$0.00';

  return '$' + Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 2 }).format(value);
};

export const validateEmail = { exp: /^\w+([.+-]?\w+)*@[a-z0-9]+([-_]{1,2})?([a-z]*)(\.[a-z]{2,15})+$/i, label: '*Correo electrónico inválido.' };

export const validateName = {
  regexp: {
    exp: /^[a-zÀ-ÿ]+(\s?[a-zÀ-ÿ]+)*$/i,
    label: '*Nombre solo puede contener letras, acentos y espacios.',
  },
  minlength: {
    value: 3,
    label: '*El nombre y apellido deben contener al menos 3 caracteres cada uno.',
  },
  maxlength: {
    value: 100,
    label: '*El nombre debe contener máximo 100 caracteres.',
  },
  minWords: {
    value: 2,
    label: 'Por lo menos, debes agregar tu nombre y primer apellido.',
  },
};

export const validateString = (placeholder, min, max, words) => {
  const validate = {
    regexp: {
      exp: /^[\wÀ-ÿ,.\n_-\s!¡]+$/i,
      label: '*Nombre solo puede contener letras, acentos y espacios.',
    },
    minlength: {
      value: min,
      label: `*${placeholder} deben contener al menos ${min} caracteres.`,
    },
    maxlength: {
      value: max,
      label: `*${placeholder} debe contener máximo ${max} caracteres.`,
    },
  };

  if (words) {
    validate.minWords = {
      value: words,
      label: `Por lo menos, debes agregar ${words} palabras.`,
    };
  }

  return validate;
}

export const validatePassword = {
  minlength: {
    value: 8,
    label: '*La contraseña debe contener al menos 8 caracteres.',
  },
  maxlength: {
    value: 30,
    label: '*La contraseña debe contener máximo 30 caracteres.',
  },
  regexp: {
    exp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?¡"/+,.:;='^|~_()¿{}[\]\\-])[A-Za-z\d#$@!%&*?¡"/+,.:;='^|~_()¿{}[\]\\-]{8,32}$/,
    label: '*La contraseña debe contener al menos 1 letra minúscula, 1 letra mayúscula, 1 número y un carácter especial.',
  },
};

export const validatePhone = {
  minlength: {
    value: 10,
    label: '*El teléfono debe contener 10 dígitos.',
  },
  maxlength: {
    value: 10,
    label: '*El teléfono debe contener 10 dígitos.',
  },
  regexp: {
    exp: /^[\d-() ]+$/,
    label: '*El teléfono debe contener 10 dígitos.',
  },
};

export const validateNumberStreet = {
  minlength: {
    value: 1,
    label: '*Debe contener al menos 1 dígito',
  },
  maxlength: {
    value: 10,
    label: '*Máximo 10 caracteres.',
  },
  regexp: {
    exp: /^[a-zA-Z\d-]+$/,
    label: '*Solo admite números, letras y guion medio (-).',
  },
};

export const validateAddress = {
  minlength: {
    value: 4,
    label: '*La dirección debe contener al menos 4 caracteres.',
  },
};

export const validateZip = {
  minlength: 5,
  maxlength: {
    value: 5,
    label: 'Máximo 5 dígitos.',
  },
  regexp: {
    exp: /^[0-9]{5}$/,
    label: '*El Código Postal debe contener 5 dígitos.',
  },
};

export const isChildOfClass = (item, CSSClass) => {
  if (!item) return false;

  const check = item.classList.contains(CSSClass) || (item.parentElement && item.parentElement.classList.contains(CSSClass));

  if (check) return true;

  if (item.nodeName === 'BODY' || (item.parentElement && item.parentElement.nodeName === 'BODY')) return false;

  return isChildOfClass(item.parentElement, CSSClass);
};

export const isChildOfID = (item, id) => {
  if (!item) return false;

  const check = item.id === id || (item.parentElement && item.parentElement.id === id);

  if (check) return true;

  if (item.nodeName === 'BODY' || (item.parentElement && item.parentElement.nodeName === 'BODY')) return false;

  return isChildOfID(item.parentElement, id);
};

export const isChildOfElement = (item, element) => {
  if (!item || !element) return false;

  const check = item.isEqualNode(element);
  if (check) return true;

  if (item.nodeName === 'BODY' || (item.parentElement && item.parentElement.nodeName === 'BODY')) return false;

  return isChildOfElement(item.parentElement, element);
}

export const months = [
  {
    value: 1,
    label: '01',
  },
  {
    value: 2,
    label: '02',
  },
  {
    value: 3,
    label: '03',
  },
  {
    value: 4,
    label: '04',
  },
  {
    value: 5,
    label: '05',
  },
  {
    value: 6,
    label: '06',
  },
  {
    value: 7,
    label: '07',
  },
  {
    value: 8,
    label: '08',
  },
  {
    value: 9,
    label: '09',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 12,
    label: '12',
  },
];

const currentYear = new Date().getFullYear().toString();
export const years = [];

for (let index = 0; index < 15; index++) {
  years.push(Number(currentYear) + index);
}

export const onlyAcceptName = /[^a-zA-ZÀ-ÿ`´¨^ ]/g;

export const onlyAlphaNumeric = /[^a-z0-9À-ÿ()-´`~¨ ]/gi;

export const onlyAcceptEmail = /[^a-zA-Z0-9@+.-_]/g;

export const onlyAcceptNumbers = /[^0-9]/g;

export const onlyAcceptNumbersLetters = /[^a-zA-Z0-9-]/g;

export const onlyAcceptPhone = /[^0-9]/g;

export const highlightText = (string, pattern) => {
  const regExp = new RegExp('(' + pattern.split(' ').join('|') + ')', 'ig');
  return string.replace(regExp, '<span>$1</span>');
};

export const whatsappLink = (number, message = false) => {
  if (!number) return '#';

  const msg = message || 'Hola, que tal. Me interesa conocer más sobre sus productos.';
  return `https://wa.me/52${number.replace(/[^\d]+/g, '')}?text=${encodeURI(msg)}`;
};

export const getSector = () => window.localStorage.getItem('sector') || '';

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
