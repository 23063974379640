import React, { useEffect, useState } from 'react';
import Image from '../Image';
import IconBox from '../BasketBox/IconBox';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AnimationLastAdded = () => {
  const cart = useSelector(state => state.cart)
  const {product} = useSelector(state => state.product)

  const [percent, setPercent] = useState(0);
  const [container, setContainer] = useState({})
  const {hide_last, containers} = cart;

  useEffect(() => {
    if(hide_last) {
      setPercent(0);
    }else if(containers?.length) {
      const container = [...containers].pop();
      setContainer(container);
      setPercent(container.percentage);
    }
  }, [hide_last, containers])

  if(!container || hide_last) return '';

  return (<div className={`added-confirmation-desktop ${container.code}`}>
  <div className='effect'>
    <Image alt='demo' src={product.media[0]?.sizes.small} className='product-added' />
    <IconBox name={container.code} percent={percent} className="icon-box-animated" />
    <div className='box-name'>{container.name}</div>
  <Link to='/box/detail' className='link'>
    IR AL PEDIDO
  </Link>
  </div>
</div>);
};

export default AnimationLastAdded;
