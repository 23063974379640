import axiosApi from '../../store/axiosAPI';

export const splitName = (name) => {
  const items = name.split(' ');
  if (items.length === 2) {
    return {
      firstName: items[0],
      lastName: items[1],
    }
  }
  return {
    lastName: items.splice(-2, 2).join(' '),
    firstName: items.join(' ')
  }
}

export const getShippingCosts = async (orderID, zip) => {
  const response = await axiosApi.post('v1/quotations', {
    order_id: orderID,
    destination_zip: zip,
  }).catch((error) => error.response);
  if (response.data.status_code !== 702) {
    return { ok: false, error: response.data };
  };

  const rates = {};
  response.data.rates.forEach((element) => {
    if (!!rates[element.carrier]) {
      rates[element.carrier].push(element);
    } else {
      rates[element.carrier] = [element];
    }
  });
  return { ok: true, rates };
};

export const getNewShippingCosts = async (order_id, destination) => {
  const response = await axiosApi.post('v2/quotations', {
    order_id,
    destination,
  }).catch((error) => error.response);
  if (response.data.status_code !== 702) {
    return { ok: false, error: response.data };
  };

  const rates = {};
  response.data.rates.forEach((element) => {
    if (!!rates[element.carrier]) {
      rates[element.carrier].push(element);
    } else {
      rates[element.carrier] = [element];
    }
  });
  return { ok: true, rates };
};

export const saveShippingCost = async (data) => {
  const response = await axiosApi.post('v2/shipments', data).catch((error) => error.response);
  return response.data;
}
