import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { highlightText } from '../../helpers/index';
import { analyticsSearchProductDetails, searchAnalytics, segmentoAnalytics } from '../../store/actions/analytics-events';
import { openSearch, search } from '../../store/actions/search';
import useIsMobile from '../../hooks/useIsMobile';
import { analyticsSearch } from '../../store/actions/analytics-events';
import PerfectScroll from '../PerfectScroll';
import { AnimatePresence, motion } from 'framer-motion';
import { headerAnimations } from '../../helpers/choreography/header';
import IconBox from './IconBox';
import FakeSelect from '../FormElements/FakeSelect';
import { setSector } from '../../store/actions/categories';

const SearchDesktop = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isMobile = useIsMobile()
    const [show, setShow] = useState(false)
    const [category, setCategory] = useState(false)
    const [categoriesArr, setCategoriesArr] = useState([])
    const { catalogs } = useSelector(state => state.home)
    const { sector } = useSelector(state => state.categories)
    const [text, setText] = useState('')
    const timer = useRef(false)

    useEffect(() => {
        if (catalogs.length && !categoriesArr.length) {
            const items = catalogs.map(category => ({ value: category.code, id: category.id, label: category.alt_name || category.name }))
            setCategoriesArr(items);
            if(!category){
                const findHome = sector ? items.find(item => item.id === parseInt(sector)) : items.find(item => item.value === 'hogar');
                setCategory(findHome || false)
            }
        }
    }, [dispatch, catalogs, category, sector, categoriesArr])

    const { results, error, loader, keyword } = useSelector((state) => state.search);

    const clearTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = false;
        }
    };

    const currentSector = catalogs.find(item => item.id === sector) || {code: 'hogar'}

    useEffect(() => {
        if (!text) return;
        clearTimer();

        timer.current = setTimeout(() => {
            const searchText = text.trim()
            if(searchText === keyword) return;
            searchAnalytics(text.trim(), currentSector.code);
            dispatch(search(searchText, false));
            setShow(true)
        }, 1000);

        return () => {
            clearTimer();
        };
    }, [dispatch, text, keyword, currentSector]);

    const updateText = ({ currentTarget: { value } }) => {
        setText(value.replace(/[^a-z0-9ñáéíóúü\s]/gi, ''));
    };

    const sendForm = (event) => {
        event.preventDefault();
        clearTimer();
        event.currentTarget.blur();
        const searchText = text.trim();
        if (!searchText) return;
        analyticsSearch(searchText);
        dispatch(search(searchText, true));
        history.push(`/buscar/${encodeURI(text.trim())}`);
        setText('');
        setShow(false)
    };

    const closeSearch = (product) => {
        dispatch(openSearch(false));
        setShow(false)
        analyticsSearchProductDetails(product);
    };

    const showResults = () => {
        return text && text.trim() === keyword && (results.length || error) && show;
    };

    const handleSectors = (option) => {
        if(parseInt(sector) === option.id) return;
        setCategory(option)
        dispatch(setSector(option.id));
        segmentoAnalytics(option.label);
    }

    return (
        <form
            className={`
        search
        search-${isMobile ? 'mobile' : 'desktop'}
        ${text ? ' active' : ''}
        ${loader ? ' search-loader' : ''}
        ${!!keyword.length && !results.length ? ' not-results' : ''}
      `
            }
            onSubmit={sendForm}
        >
            <div className="container-input">
                {!isMobile && (<div className='icon-search-light'></div>)}
                <input
                    type='text'
                    placeholder='Buscar productos, marcas y más…'
                    onChange={(event) => updateText(event)}
                    value={text}
                />
            </div>

            <div className="container-category">
                <span>Ver productos de:</span>
                <FakeSelect
                    className='category'
                    placeholder={category.label || 'Todos los perfiles'}
                    options={categoriesArr}
                    value={category}
                    onChange={(option) => {
                        handleSectors(option)
                    }}
                />
            </div>
            <AnimatePresence>
                {showResults() && (
                    <motion.div
                        variants={headerAnimations.showCatsList}
                        transition={{ ease: 'easeInOut', duration: .2 }}
                        initial="hidden"
                        animate={"visible"}
                        exit="hidden"
                        className="search-results">
                        <div className="search-results-list">
                            {!error && (
                                <PerfectScroll height={results.length * 23} className={`search-result-scroll`}>
                                    <ul className='results-list'>
                                        {results.map((result, index) => (
                                            <li key={index} className="results-item">
                                                <Link onClick={() => closeSearch(result)} to={`/producto/${result.id}/${result.code}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: highlightText(result.name, keyword) }}></div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </PerfectScroll>
                            )}

                            {error && (
                                <motion.div
                                    variants={headerAnimations.showCatsList}
                                    transition={{
                                        ease: 'easeInOut',
                                        duration: .2
                                    }}
                                    initial="hidden"
                                    animate={"visible"}
                                    exit="hidden"
                                >
                                    <div className="search-results-error">
                                        <h3 className="mxp-title">{error.message || error}</h3>
                                        <IconBox type='informer' id='container-informer-box' name='empty' percent={0} />
                                    </div>
                                </motion.div>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </form>
    );
};

export default React.memo(SearchDesktop);
