import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { imageUrl, isMobile } from '../../helpers/index';
import { changeAddressLogged, clearErrorPayment, enableChangeAddress, getShippingCost, purchaseMakePaymentTC } from '../../store/actions/purchase';
import { addNewAddress } from '../../store/actions/user';
import AdvancedModal from '../AdvancedModal';
import ErrorModal from '../ErrorModal';
import Loader from '../Loader';
import MoneyFormat from '../MoneyFormat';
import PerfectScroll from '../PerfectScroll';
import FormChangePaymentMethod from './FormChangePaymentMethod';
import FormChangeAddressGuest from './Guest/FormChangeAddress';
import FormChangeAddressLogged from './Logged/FormChangeAddressLogged';
import PackagesRates from './PackagesRates';

class ClosingSales extends React.Component {
  state = {
    changeAddressForm: false,
    isMobile: false,
  };

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
  }

  openAddressForm = () => this.setState({ changeAddressForm: !this.state.changeAddressForm });

  enableAddNewAddress = () => {
    return (
      !!this.props.purchase.changeAddress.alias &&
      !!this.props.purchase.changeAddress.name &&
      !!this.props.purchase.changeAddress.phone &&
      !!this.props.purchase.changeAddress.address &&
      !!this.props.purchase.changeAddress.exteriorNumber &&
      !!this.props.purchase.changeAddress.zip &&
      !!this.props.purchase.changeAddress.suburbs &&
      !!this.props.purchase.changeAddress.state &&
      !!this.props.purchase.changeAddress.city
    );
  };

  addNewAddress = () => {
    this.props.addNewAddress({
      alias: this.props.purchase.changeAddress.alias,
      name: this.props.purchase.changeAddress.name,
      phone: this.props.purchase.changeAddress.phone,
      state_id: this.props.purchase.changeAddress.state.id,
      state_name: this.props.purchase.changeAddress.state.name,
      city_id: this.props.purchase.changeAddress.city.id,
      city_name: this.props.purchase.changeAddress.city.name,
      suburb_id: this.props.purchase.changeAddress.suburbs.id,
      suburb_name: this.props.purchase.changeAddress.suburbs.name,
      zip: this.props.purchase.changeAddress.zip,
      interior_number: this.props.purchase.changeAddress.interiorNumber || '',
      exterior_number: this.props.purchase.changeAddress.exteriorNumber,
      address: this.props.purchase.changeAddress.address,
      is_default: this.props.purchase.changeAddress.principal,
      references: this.props.purchase.changeAddress.references || '',
    });
    this.props.setNewAddress();
  };

  cardSelected = () => {
    return this.props.purchase.cardsSaved.find((item) => item.id === this.props.purchase.tokenCard) || this.props.purchase.unSaveCard;
  };

  maxSizeFormChangeAddressLogged = () => {
    if (this.props.isLogged && !this.state.isMobile) {
      return { width: 740, height: 640 };
    }
    if (this.state.isMobile) {
      return { width: 370, height: 460 };
    }
    return { width: 520, height: 490 };
  };

  cardBrand = () => {
    switch (this.cardSelected().brand) {
      case 'amex':
      case 'american_express':
        return 'AMEX';
      case 'mastercard':
        return 'Master Card';
      default:
        return 'Visa';
    }
  };

  total = () => {
    const shippingRate = this.props.purchase.shipping.total || 0;
    return this.props.cart.total + shippingRate;
  };

  paymentEnabled = () => {
    return !!this.props.purchase.shipping || this.props.purchase.officeID;
  };

  componentDidUpdate(prevProps) {
    if (this.props.purchase.personalInfo.address !== prevProps.purchase.personalInfo.address) {
      this.props.changeRate();
    }
  }

  office = () => {
    return this.props.offices.find((office) => office.id === this.props.purchase.officeID);
  };

  render() {
    return (
      <div id='closing-sales'>
        <section className='purchase-detail'>
          <h1>DETALLE DE COMPRA</h1>
          <table className='total-detail desktop'>
            <tbody>
              <tr>
                <th>SUBTOTAL:</th>
                <td>
                  <MoneyFormat money={this.props.cart.sub_total} />
                </td>
              </tr>
              <tr>
                <th>IVA:</th>
                <td>
                  <MoneyFormat money={this.props.cart.iva} />
                </td>
              </tr>
              <tr>
                <th>ENVÍO:</th>
                <td>
                  <MoneyFormat money={this.props.purchase.shipping.total || 0} />
                </td>
              </tr>
              <tr className='total'>
                <th>TOTAL:</th>
                <td>
                  <MoneyFormat money={this.total()} />
                </td>
              </tr>
            </tbody>
          </table>
          <PerfectScroll className='closing-sales-products'>
            {this.props.cart.products.map((product, index) => (
              <article key={index}>
                <div className='image'>
                  <img src={imageUrl(product.media[0] ? product.media[0].sizes.small : false)} alt='' />
                </div>
                <div className='detail'>
                  <h1>{product.name}</h1>
                  <div className='price'>
                    <strong>Precio:</strong> <MoneyFormat money={product.price} />
                  </div>
                  <div className='quantity'>
                    <strong>Cantidad:</strong> {product.quantity}
                  </div>
                </div>
              </article>
            ))}
          </PerfectScroll>
          <table className='total-detail mobile'>
            <tbody>
              <tr>
                <th>SUBTOTAL:</th>
                <td>
                  <MoneyFormat money={this.props.cart.sub_total} />
                </td>
              </tr>
              <tr>
                <th>IVA:</th>
                <td>
                  <MoneyFormat money={this.props.cart.iva} />
                </td>
              </tr>
              <tr>
                <th>ENVÍO:</th>
                <td>
                  <MoneyFormat money={this.props.purchase.shipping.total || 0} />
                </td>
              </tr>
              <tr className='total'>
                <th>TOTAL:</th>
                <td>
                  <MoneyFormat money={this.total()} />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <div className='content'>
          <section className='user-data'>
            <h1>DATOS DEL USUARIO</h1>
            <div>
              <strong>Nombre:</strong>
              {this.props.purchase.personalInfo.name}
            </div>
            <div>
              <strong>Correo electrónico:</strong>
              {this.props.purchase.personalInfo.email || this.props.user.profile.email}
            </div>
            <div>
              <strong>Teléfono:</strong>
              {this.props.purchase.personalInfo.phone}
            </div>
          </section>
          <section className='payment-method'>
            <h1>MÉTODO DE PAGO</h1>
            <div>
              <strong>Nombre del tarjetahabiente:</strong>
              {this.cardSelected().name}
            </div>
            <div className='card-data'>
              <strong>Número de la tarjeta:</strong>
              <div className='card-number'>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                {this.cardSelected().last4}
              </div>
              <div className='card-type'>
                <span>/</span>
                {this.cardBrand()}
              </div>
            </div>
            <div className='expire'>
              <strong>Vencimiento:</strong>
              {this.cardSelected().exp_month} / {this.cardSelected().exp_year}
            </div>
            <div className='change'>
              <span className='link' onClick={this.props.openChangePaymentMethod}>
                Cambiar <i className='icon-right-open'></i>
              </span>
            </div>
          </section>
          <section className='shipping-information'>
            <h1>{this.props.purchase.deliveryType === 'home' ? 'DATOS DE ENVÍO' : 'RECOGER EN SUCURSAL'}</h1>
            <div className='address'>
              <strong>Dirección:</strong>
              <p>
                {!!this.props.purchase.personalInfo.alias && (
                  <Fragment>
                    {this.props.purchase.personalInfo.alias}
                    <br />
                  </Fragment>
                )}
                {this.props.isLogged
                  ? this.props.purchase.personalInfo.address
                  : `${this.props.purchase.personalInfo.address} #${this.props.purchase.personalInfo.exteriorNumber}${
                      !!this.props.purchase.personalInfo.interiorNumber ? ' int. ' + this.props.purchase.personalInfo.interiorNumber : ''
                    }`}
                <br />
                {this.props.purchase.personalInfo.suburbs.name || this.props.purchase.personalInfo.suburbs}
                <br />
                CP {this.props.purchase.personalInfo.zip}
              </p>
            </div>
            <div className='change'>
              <span className='link' onClick={this.props.openChangeAddress}>
                Cambiar <i className='icon-right-open'></i>
              </span>
            </div>

            <div className='address'>
              <strong>{this.props.purchase.deliveryType === 'home' ? 'Paquetería' : 'Sucursal'}:</strong>
              {this.props.purchase.shipping && (
                <p>
                  {this.props.purchase.shipping.carrier}
                  <span>
                    {this.props.purchase.shipping.product} / Entrega aproximada en {this.props.purchase.shipping.delivery_days} {this.props.purchase.shipping.delivery_days > 1 ? 'días' : 'día'}
                  </span>
                </p>
              )}

              {this.props.purchase.deliveryType === 'office' && (
                <p>
                  {this.office().name}
                  <span>
                    {this.office().street} #{this.office().number}
                    <br />
                    {this.office().suburb}
                    <br />
                    CP {this.office().zip}
                  </span>
                </p>
              )}
            </div>
            <div className='change'>
              <span className='link' onClick={this.props.changeRate}>
                Cambiar <i className='icon-right-open'></i>
              </span>
            </div>
          </section>
          <div className='btn-payment-desktop'>
            <span className={`btn btn-primary rounded${this.props.purchase.loader ? ' btn-loader' : ''}${this.paymentEnabled() ? '' : ' disabled'}`} onClick={this.props.makePayment}>
              Pagar <MoneyFormat money={this.total()} />
            </span>
          </div>
        </div>

        {this.props.purchase.openChangePaymentMethod && <FormChangePaymentMethod />}

        {this.props.purchase.openChangeAddress && !this.props.isLogged && (
          <AdvancedModal className='change-address' close={this.props.openChangeAddress} scrollYMarginOffset={1}>
            <AdvancedModal.Header>
              <h1>Nueva dirección</h1>
            </AdvancedModal.Header>
            <AdvancedModal.Body>
              <FormChangeAddressGuest />
              <div className='btn-save'>
                <span className={`btn btn-primary rounded${this.props.enableChangeAddress() ? '' : ' disabled'}`} onClick={() => this.props.changeAddress(this.props.isLogged)}>
                  CAMBIAR
                </span>
              </div>
            </AdvancedModal.Body>
            {this.state.isMobile && (
              <AdvancedModal.Footer>
                <span className={`btn btn-primary rounded${this.props.enableChangeAddress() ? '' : ' disabled'}`} onClick={() => this.props.changeAddress(this.props.isLogged)}>
                  CAMBIAR
                </span>
              </AdvancedModal.Footer>
            )}
          </AdvancedModal>
        )}

        {this.props.purchase.openChangeAddress && this.props.isLogged && (
          <AdvancedModal
            className='change-address logged'
            close={this.props.openChangeAddress}
            maxWidth={this.maxSizeFormChangeAddressLogged().width}
            maxHeight={this.maxSizeFormChangeAddressLogged().height}
            scrollYMarginOffset={1}>
            <AdvancedModal.Header>
              <h1>Cambiar dirección</h1>
            </AdvancedModal.Header>
            <AdvancedModal.Body>
              <FormChangeAddressLogged />
              {!this.props.purchase.formAddNewAddressLogged && (
                <div className='btn-save' onClick={this.props.changeAddressLogged}>
                  <span className='btn btn-primary rounded'>CAMBIAR</span>
                </div>
              )}
            </AdvancedModal.Body>
            {this.state.isMobile && (
              <AdvancedModal.Footer>
                {!this.props.purchase.formAddNewAddressLogged && (
                  <span className={`btn btn-primary rounded${this.props.purchase.changeAddressID ? '' : ' disabled'}`} onClick={this.props.changeAddressLogged}>
                    CAMBIAR
                  </span>
                )}
                {this.props.purchase.formAddNewAddressLogged && (
                  <span className={`btn btn-primary rounded${this.enableAddNewAddress() ? '' : ' disabled'}`} onClick={this.addNewAddress}>
                    CAMBIAR
                  </span>
                )}
              </AdvancedModal.Footer>
            )}
          </AdvancedModal>
        )}

        <PackagesRates close={() => {}} scrollAfterClose={true} />
        {this.props.purchase.payment.error && <ErrorModal msg={this.props.purchase.payment.message} close={this.props.clearErrorPayment} />}
        {this.props.purchase.loaderRates && <Loader />}
        <div className='btn-payment mobile'>
          <span className={`btn btn-white btn-small rounded${this.props.purchase.loader ? ' btn-loader' : ''}${this.paymentEnabled() ? '' : ' disabled'}`} onClick={this.props.makePayment}>
            Pagar <MoneyFormat money={this.total()} />
          </span>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    isLogged: state.user.logged,
    cart: state.cart,
    purchase: state.purchase,
    user: state.user,
    offices: state.home.offices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAddress: (isLogged) => dispatch({ type: 'PURCHASE_CHANGE_ADDRESS_GUEST', name: 'submit', value: false, isLogged }),
    openChangeAddress: () => dispatch({ type: 'PURCHASE_OPEN_CHANGE_ADDRESS' }),
    makePayment: () => dispatch(purchaseMakePaymentTC()),
    changeAddressLogged: () => dispatch(changeAddressLogged()),
    enableChangeAddress: () => dispatch(enableChangeAddress()),
    openChangePaymentMethod: () => dispatch({ type: 'PURCHASE_CHANGE_PAYMENT_METHOD_OPEN' }),
    addNewAddress: (address) => dispatch(addNewAddress(address)),
    setNewAddress: () => dispatch({ type: 'PURCHASE_CHANGE_ADDRESS_GUEST', name: 'submit', value: false }),
    changeRate: () => dispatch(getShippingCost()),
    clearErrorPayment: () => dispatch(clearErrorPayment()),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(ClosingSales);
