import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { headerAnimations } from '../../helpers/choreography/header'
import { validateEmail } from '../../helpers/validations'
import useIsMobile from '../../hooks/useIsMobile'
import { clearError, recovery_password, setUserOpen, userMenuControl } from '../../store/actions/user'
import FormInput from '../FormElements/FormInput'
import AppButton from '../UI/Buttons'

const UserRecovery = () => {
    const ref = useRef()
    const dispatch = useDispatch();
    const isMobile = useIsMobile()
    const { layer, loader, recovery_fail, recovery_success, login_desktop } = useSelector((state) => state.user)

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
    } = useForm({
        mode: 'onBlur',
    });

    const returnToLogin = () => {
        dispatch(userMenuControl(true, 'user-login', 'top'))
        dispatch(clearError()) // Clear errors or logs 
    }

    const submitRecovery = (data) => {
        dispatch(recovery_password(data.email))
    }

    const enableSubmit = () => isDirty && !Object.keys(errors).length;

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (login_desktop && ref.current && !ref.current.contains(e.target)) {
                dispatch(setUserOpen(!login_desktop))
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [dispatch, login_desktop])

    return (
        <motion.div
            variants={isMobile ? headerAnimations.showMenu : headerAnimations.showMenuDesktop}
            transition={isMobile ? { ease: 'easeInOut', duration: 0.4 } : { ease: 'easeInOut', duration: 0.2 }}
            initial='hidden'
            animate={layer === 'user-recovery' ? 'visible' : 'hidden'}
            exit='hidden'
            className={`recovery ${layer === 'user-recovery' && isMobile ? 'recovery-overlay' : 'recovery-header'}`}
            ref={ref}
        >
            <div className="recovery-flx">
                <div className="recovery-box">
                    <p className="recovery-link" onClick={returnToLogin}>
                        <i className="icon-left-open-light"></i>
                        Volver
                    </p>

                    <div className={classNames({
                        'recovery-flow': true,
                        'recovery-flow--loading': loader,
                        'recovery-flow--action': recovery_fail || recovery_success
                    })}>
                        {!recovery_fail && !recovery_success &&
                            <div className="recovery-facade">
                                <p className="mxp-text">Ingresa el correo electrónico con el que estás registrado.</p>
                                <form className="recovery-form" onSubmit={handleSubmit(submitRecovery)}>
                                    <FormInput
                                        label='Correo electrónico*'
                                        placeholder='jorgeEHP@correo.com'
                                        name='email'
                                        register={register}
                                        options={validateEmail}
                                        error={errors.email}
                                        type='email'
                                        defaultValue={''}
                                        setValue={setValue}
                                        validate="email"
                                    />
                                    <AppButton type="main" txt="Enviar" available={enableSubmit()} loader={loader} />
                                </form>
                            </div>
                        }
                        {recovery_success && (
                            <div className="purchases-product-response purchases-product-response--ok">
                                <figure className="purchases-product-icon">
                                    <span className="icon-ok-2"></span>
                                </figure>
                                <h3 className="mxp-sub-subtitle">¡Listo!</h3>
                                <p className="mxp-text">Revisa tu correo electrónico y sigue las indicaciones.</p>
                            </div>
                        )}
                        {recovery_fail && (
                            <div className="purchases-product-response purchases-product-response--error">
                                <figure className="purchases-product-icon">
                                    <span className="icon-alert"></span>
                                </figure>

                                <h3 className="mxp-sub-subtitle">¡Lo sentimos!</h3>
                                <p className="mxp-text"> Ocurrió un error inesperado. Por favor, intenta nuevamente.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default UserRecovery
