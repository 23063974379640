import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import SearchMobile from './SearchMobile'
import SearchDesktop from './SearchDesktop';

const SearchForm = ({ showMobile, setShowMobile }) => {
  const isMobile = useIsMobile()

  const showMobileBar = () => {
    setShowMobile(!showMobile)
  }

  return (
    isMobile ?
      <>
        <i className='icon-search-light' onClick={showMobileBar} />
        <SearchMobile show={showMobile} setShow={setShowMobile} />
      </>
      :
      <SearchDesktop />
  )
}

export default SearchForm;
