import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateCard, validateName } from '../../helpers/validations';
import useCard from '../../hooks/useCard';
import { generateToken, purchaseClearPaymentMethod } from '../../store/actions/purchase';
import FormInput from '../FormElements/FormInput';
import useTotalPurchase from './../../hooks/useTotalPurchase';
import { purchaseSetMSI } from './../../store/actions/purchase';
import FakeSelect from './../FormElements/FakeSelect';
import InputCheckbox from './../FormElements/InputCheckbox';

const CardForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const totalPurchase = useTotalPurchase();

  const { tokenCard, tokenizer_error, msi } = useSelector((state) => state.purchase);
  const { MSI } = useSelector((state) => state.home);

  const [enableMSI, setEnableMSI] = useState(false);
  const [msiMonthsEnabled, setMsiMonthsEnabled] = useState(false);

  useEffect(() => {
    const months = MSI.months.find((item) => item.min_amount <= totalPurchase.total_with_iva && (item.max_amount >= totalPurchase.total_with_iva || item.max_amount === true));
    if (months) {
      setMsiMonthsEnabled(months);
    }
  }, [MSI, totalPurchase]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const { name, number, cvc, exp_month, exp_year } = watch();

  const cardType = useCard(number);

  const submitForm = (data) => {
    dispatch(generateToken(data));
  };

  useEffect(() => {
    dispatch(purchaseClearPaymentMethod());
  }, [dispatch]);

  useEffect(() => {
    if (isDirty && tokenCard) {
      history.push('/carrito/realizar-pago');
    }
  }, [history, isDirty, tokenCard]);

  const enableSubmit = () => !Object.keys(errors).length && name && number && cvc && exp_month && exp_year;

  const handleMSI = (value) => {
    if (msi === value) {
      return dispatch(purchaseSetMSI(false));
    }
    dispatch(purchaseSetMSI(value));
  };

  const openMsiOptions = () => {
    dispatch(purchaseSetMSI(false));
    setEnableMSI(!enableMSI);
  };

  return (
    <form className='form-payment-card' onSubmit={handleSubmit(submitForm)}>
      <FormInput
        name='name'
        label='Nombre del tarjetahabiente*'
        placeholder='Jorge Eduardo Hernández Pérez'
        register={register}
        options={validateName}
        error={errors.name}
        setValue={setValue}
        validate='name'
      />

      <FormInput
        name='number'
        label='Número de la tarjeta*'
        placeholder='16 dígitos'
        register={register}
        options={validateCard}
        error={errors.number}
        type='numeric'
        setValue={setValue}
        validate='number'
      />

      <FakeSelect name='exp_month' options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} label='Mes*' placeholder='Selecciona' value={exp_month} onChange={setValue} />

      <FakeSelect
        name='exp_year'
        options={[2021, 2022, 2023, 2024, 2025, 2026, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035]}
        label='Año*'
        placeholder='Selecciona'
        value={exp_year}
        onChange={setValue}
      />

      <FormInput
        name='cvc'
        label='CVV*'
        placeholder={cardType.cvv.placeholder}
        register={register}
        options={cardType.cvv.options}
        minLength={3}
        maxLength={4}
        error={errors.cvc}
        type='numeric'
        setValue={setValue}
        validate='number'
      />

      {MSI.enabled && totalPurchase.total >= MSI.min_amount && (
        <div className='msi'>
          <InputCheckbox item={{ label: 'Paga a meses sin intereses', value: 1 }} onChange={openMsiOptions} selected={enableMSI} />
          {enableMSI && (
            <div className='msi-options'>
              {msiMonthsEnabled?.term.map((month, monthIndex) => (
                <div className={classNames('msi-option', { active: month === msi })} key={monthIndex} onClick={() => handleMSI(month)}>
                  {month} meses
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {tokenizer_error && <div className='error-card'>{tokenizer_error}</div>}
      <button className={`btn btn-primary${enableSubmit() ? '' : ' disabled'}`}>CONTINUAR</button>
    </form>
  );
};

export default CardForm;
