import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppButton from '../../components/UI/Buttons';
import Color from '../../components/UI/Color';
import { showColors } from '../../store/actions/global';
import useProductCart from './../../hooks/useProductCart';
import { updateItemCart, deleteItemCart } from './../../store/actions/cart';

function ColorsAlert({ productID, product, close }) {
  const dispatch = useDispatch();
  let productCart = useProductCart(productID);

  const [currentProduct, setCurrentProduct] = useState(false);

  useEffect(() => {
    if (productCart.product_id) {
      setCurrentProduct(productCart);
    } else if (product?.id || product?.product_id) {
      setCurrentProduct(product);
    }
  }, [productCart, product]);

  const [selected, setSelected] = useState(false);

  const totalSelected = Object.values(selected).reduce((acc, item) => acc + item, 0);

  const colors = currentProduct
    ? currentProduct.colors.map((item) => {
        return { ...item, name: item.name.trim() };
      })
    : [];

  useEffect(() => {
    if (!currentProduct?.comments?.length || selected) return;
    const itemsSelected = {};
    const colorsArr = currentProduct.comments.split(', ');
    colorsArr.forEach((item) => {
      if (!!item.length) {
        const itemSplit = item.split(': ');
        itemsSelected[itemSplit[0]] = parseInt(itemSplit[1]);
      }
    });
    setSelected(itemsSelected);
  }, [currentProduct, selected]);

  const closeColors = () => {
    dispatch(showColors(false));
    if (close) {
      close();
    }
  };

  const continueShopping = () => {
    let quantity = 0;
    const comments = Object.entries(selected)
      .map((item) => {
        if (item[1] < 1) return '';
        quantity += item[1];
        return item.join(': ');
      })
      .filter((item) => !!item.length)
      .join(', ');
    if(!quantity) {
      dispatch(deleteItemCart(productID || currentProduct.id || currentProduct.product_id))
    }else{
      dispatch(updateItemCart(productID || currentProduct.id || currentProduct.product_id, totalSelected, true, comments, currentProduct));
    }
    dispatch(showColors(false));
    if (close) {
      close();
    }
  };

  const handleUpdateColor = (color) => {
    const updateColors = { ...selected };
    updateColors[color.name] = color.quantity;
    setSelected(updateColors);
  };

  const checkStock = () => {
    return currentProduct.stock - Object.values(selected).reduce((acc, item) => acc + item, 0);
  };

  if (!currentProduct) return '';

  return (
    <div className='mb-alert mb-colors'>
      <div className='mb-colors-header'>
        <h3 className='mxp-sub-subtitle'>Cantidad de artículos</h3>
        <AppButton type='icon' icon='cancel-circle' modifier='close' customClick={closeColors} />
      </div>
      <div className='mb-colors-list'>
        {colors.map((color, i) => {
          return <Color color={color} quantity={selected[color.name] || 0} key={i} onChange={handleUpdateColor} stock={checkStock()} />;
        })}
      </div>
      <nav className='mb-nav'>
        <AppButton type='main' txt='Continuar' onClick={continueShopping} available={true} />
      </nav>
    </div>
  );
}

export default ColorsAlert;
