export const getShippingLogo = (carrier) => {
    let brand;
    switch (carrier.toLowerCase()) {
        default:
            brand = '/img/ico-mas-por-pieza.svg'
            break;

        case 'estafeta':
            brand = '/img/shipping/lg-estafeta.svg'
            break;

        case 'fedex':
            brand = '/img/shipping/lg-fedex.svg'
            break;

        case 'tresguerras':
            brand = '/img/shipping/lg-tresguerras.svg'
            break;

        case 'paquetexpress':
            brand = '/img/shipping/lg-paqueteexpress.svg'
            break;

        case '99minutos':
        case 'noventa9minutos':
            brand = '/img/shipping/lg-99minutos.svg'
            break;

        case 'redpack':
        case 'redpackws':
            brand = '/img/shipping/lg-redpack.svg'
            break;

        case 'flecha_amarilla':
            brand = '/img/shipping/lg-flecha.svg'
            break;

        case 'pitic':
            brand = '/img/shipping/lg-pitic.svg'
            break;

        case 'envia': // Envia.com
            brand = '/img/shipping/lg-envia.svg'
            break;

        case 'pilot':
            brand = '/img/shipping/lg-pilot.svg'
            break;

        case 'scmws':
        case 'carssa':
            brand = '/img/shipping/lg-carsa.svg'
            break;

        case 'aeroflash':
            brand = '/img/shipping/lg-aeroflash.svg'
            break;

        case 'sendex':
        case 'sendexws':
            brand = '/img/shipping/lg-sendex.svg'
            break;

        case 'ups':
            brand = '/img/shipping/lg-ups.svg'
            break;

        case 'secor':
            brand = '/img/shipping/lg-secor.svg'
            break;

        case 'quiken':
            brand = '/img/shipping/lg-quiken.svg'
            break;

        case 'scm':
            brand = '/img/shipping/lg-scm.svg'
            break;

        case 'mensajerosurbanos':
            brand = '/img/shipping/lg-mensajeros-urbanos.svg'
            break;

        case 'fletesMexico':
            brand = '/img/shipping/lg-aeroflash.svg'
            break;

        case 'exxprezo':
            brand = '/img/shipping/lg-exxprezo.svg'
            break;

        case 'entrega':
            brand = '/img/shipping/lg-entrega.svg'
            break;

        case 'ampm':
            brand = '/img/shipping/lg-ampm.svg'
            break;

        case 'dostavista':
            brand = '/img/shipping/lg-dostavista.svg'
            break;

        case 'almex':
            brand = '/img/shipping/lg-almex.svg'
            break;

        case 'dhl':
        case 'dhlWs':
            brand = '/img/shipping/lg-dhl.svg'
            break;

        case 'enviaestrellablanca':
            brand = '/img/shipping/lg-envia-estrellablanca.svg'
            break;

        case 'ivoy':
            brand = '/img/shipping/lg-ivoy.svg'
            break;

        case 'uber':
            brand = '/img/shipping/lg-uber.svg'
            break;

        case 'vencedor':
            brand = '/img/shipping/lg-vencedor.svg'
            break;
    }
    return brand
}
