import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isChildOfClass } from '../../helpers';
import { cartAddItem, deleteItemCart } from '../../store/actions/cart';

import useBrand from '../../hooks/useBrand';
import useProductCart from '../../hooks/useProductCart';
import BtnAddFavorite from '../BtnAddFavorite';
import Image from '../Image';
import MoneyFormat from '../MoneyFormat';
import RatingProduct from '../RatingProduct';
import classNames from "classnames";
import { isRecreate } from '../../store/actions/history-purchase';
import { productAnalyticsList } from '../../store/actions/products';

const ProductThumb = ({ product, type, action, list }) => {
  const [input, setInput] = useState(0)
  const init = useRef(false)
  const dispatch = useDispatch()
  const brand = useBrand(product.brand_id)
  const productCart = useProductCart(product.id)
  const [productLoader, setProductLoader] = useState(false)

  const { loader } = useSelector((state) => state.cart)

  const handleClick = (event) => {
    dispatch(productAnalyticsList(list || 'NA'));
    const isFavorite = event.target.classList.contains('favorite')
    const isChild = isChildOfClass(event.target, 'add-cart')
    if (isChild || isFavorite) {
      event.preventDefault();
      return;
    }
    if (action) {
      action(product);
      event.preventDefault();
      return;
    }
  };

  useEffect(() => {
    if (productCart.quantity && !init.current) {
      init.current = true;
      setInput(productCart.quantity);
    }
  }, [productCart]);

  const updateCart = (update) => {
    setProductLoader(true)
    const quantity = productCart.quantity + update;

    if (quantity < 1) {
      setInput(0);
      return dispatch(deleteItemCart(product.id || product.product_id));
    }
    setInput(quantity);
    dispatch(cartAddItem(product, quantity));
    dispatch(isRecreate(false));
  };

  const updateInput = () => {
    dispatch(isRecreate(false));
    if (productCart.quantity === input) return;
    if (productCart.quantity !== input && input > 0) {
      dispatch(cartAddItem(product, input));
    }
    if (!input && productCart.quantity > 0) {
      return dispatch(deleteItemCart(product.id || product.product_id));
    }
  };

  const handleChange = (event) => {
    const value = event.currentTarget.value.replace(/[^0-9]/g, '');
    const quantity = value < product.quantity_available ? value : product.quantity_available;
    setProductLoader(true)
    setInput(parseInt(quantity) || '');
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return updateInput();
    }
  };

  useEffect(() => {
    if (!loader) {
      setProductLoader(false)
    }
  }, [loader])

  const { logged } = useSelector((state) => state.user);

  if (!product) return '';

  return (
    <article className={classNames({
      'product-thumbnail': true,
      'product-thumbnail-list product-thumbnail-list--listed': type === 'list',
      'product-thumbnail-list': type === 'prod'
    })}>
      <Link to={`/producto/${product.id || product.product_id}/${product.code}`} onClick={handleClick}>
        <div className='image'>
          <Image src={product.media[0]?.sizes.small} alt={product.name} />
          {
            type !== 'list' && (
              <>
                <RatingProduct rating={product.rating} />
                {logged && <BtnAddFavorite id={product.id || product.product_id} is_favorite={product.is_favorite} />}
              </>
            )
          }
        </div>
        <div className='detail'>
          {
            type === 'list' ?
              <>
                <h1>{product.name}</h1>
                <span className='brand'>{brand.name}</span>

                <div className="price-flx">
                  <div className='price'>
                    <MoneyFormat money={product.price} />
                  </div>
                  <RatingProduct rating={product.rating} />
                </div>

                <div className="detail-info">
                  <div className={`add-cart${loader && productLoader ? ' loader-inline' : ''}`}>
                    <p>Agregar a la caja</p>
                    <div>
                      <span className={`icon-minus-circle${!productCart.quantity ? ' disable' : ''}`} onClick={() => updateCart(-1)}></span>
                      <input type='text' className='quantity' name='quantity' value={input} onBlur={updateInput} onChange={handleChange} onKeyDown={handleKeyDown} />
                      <span className={`icon-plus-circle${productCart.quantity >= product.quantity_available ? ' disable' : ''}`} onClick={() => updateCart(1)}></span>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className='price'>
                  <MoneyFormat money={product.price} />
                </div>
                <h1>{product.name}</h1>
                <span className='brand'>{brand.name}</span>
              </>
          }
        </div>

        {
          type !== 'list' ?
            <div className={`add-cart ${loader && productLoader ? 'loader-inline' : ''}`}>
              <p>Agregar a la caja</p>
              <div>
                <span className={`icon-minus-circle${!productCart.quantity ? ' disable' : ''}`} onClick={() => updateCart(-1)}></span>
                <input type='text' className='quantity' name='quantity' value={input} onBlur={updateInput} onChange={handleChange} onKeyDown={handleKeyDown} />
                <span className={`icon-plus-circle${productCart.quantity >= product.quantity_available ? ' disable' : ''}`} onClick={() => updateCart(1)}></span>
              </div>
            </div>
            :
            logged && <BtnAddFavorite id={product.id} is_favorite={product.is_favorite} />
        }
      </Link>
    </article>
  );
};

export default React.memo(ProductThumb);
