class dragSlider {
  isTouch = false;
  isVertical = false;
  isScrolled = false;
  startX;
  startY;
  scrollLeft;
  element;
  speed;
  spaceItem;
  onDragStart;
  onDragStartFired = false;

  constructor(element, speed = 1.5, space, onDragStart = false) {
    this.element = element;
    this.speed = speed;
    this.spaceItem = space || 0;
    this.element.classList.add('drag-slider');
    this.onDragStart = onDragStart;
    this.activeItems();
    this.enableArrows();

    this.element.addEventListener('scroll', () => {
      this.enableArrows();
      this.activeItems();
    });

    this.element.addEventListener('mousedown', this.down);
    this.element.addEventListener('mouseleave', this.leave);
    this.element.addEventListener('mouseup', this.leave);
    this.element.addEventListener('mousemove', this.move);

    this.element.addEventListener('touchstart', (event) => this.down(event, true), {passive: true});
    this.element.addEventListener('touchleave', this.leave);
    this.element.addEventListener('touchend', this.leave);
    this.element.addEventListener('touchmove', (event) => this.move(event, true), {passive: true});
  }

  _currentItemsSize = () => {
    return [...this.element.childNodes]
      .filter((item) => item.classList.contains('active'))
      .reduce((size, item) => {
        return size + item.clientWidth + this.spaceItem;
      }, 0);
  };

  activeItems = () => {
    const items = this.element.childNodes;
    items.forEach((children) => {
      if (children.offsetLeft >= this.element.scrollLeft && children.offsetLeft + children.clientWidth <= this.element.scrollLeft + this.element.clientWidth) {
        children.classList.add('active');
      } else {
        children.classList.remove('active');
      }
    });
  };

  maxScroll = () => {
    return [...this.element.childNodes].reduce((size, item) => {
      return size + item.clientWidth + this.spaceItem;
    }, 0) - this.element.clientWidth - this.spaceItem
  }

  enableArrows = () => {
    if (this.element.previousSibling) {
      if (this.element.scrollLeft > 0) {
        this.element.previousSibling.classList.remove('disabled');
      } else {
        this.element.previousSibling.classList.add('disabled');
      }
    }

    if (this.element.nextSibling) {
      if (this.element.scrollLeft < this.maxScroll()) {
        this.element.nextSibling.classList.remove('disabled');
      } else {
        this.element.nextSibling.classList.add('disabled');
      }
    }
  };

  next = () => {
    if (this.maxScroll() === this.element.scrollLeft) {
      return;
    }
    if((this._currentItemsSize() + this.element.scrollLeft) === 0){
      this.activeItems();
    }
    this.element.scroll({
      left: this._currentItemsSize() + this.element.scrollLeft,
      behavior: 'smooth',
    });
  };

  prev = () => {
    if (this.element.scrollLeft === 0) {
      return;
    }
    const nextPosition = this.element.scrollLeft - this._currentItemsSize();

    this.element.scroll({
      left: nextPosition,
      behavior: 'smooth',
    });
  };

  // events
  down = (event, touch = false) => {
    this.isTouch = true;
    let pageX = touch ? event.changedTouches[0].pageX : event.pageX;
    let pageY = touch ? event.changedTouches[0].pageY : event.pageY;
    this.startX = pageX - this.element.offsetLeft;
    this.startY = pageY - this.element.offsetTop;

    this.scrollLeft = this.element.scrollLeft;
  };

  leave = () => {
    this.isTouch = false;
    this.isVertical = false;
    this.isScrolled = false;
    this.element.classList.remove('active');
    this.onDragStartFired = false;
  };

  move = (event, touch = false) => {
    if (!this.isTouch || this.isVertical) return;

    if(this.onDragStart && !this.onDragStartFired){
      this.onDragStart();
      this.onDragStartFired = true;
    }
    let pageX = touch ? event.changedTouches[0].pageX : event.pageX;
    const x = pageX - this.element.offsetLeft;
    const walkX = (x - this.startX) * this.speed;

    let pageY = touch ? event.changedTouches[0].pageY : event.pageY;
    const Y = pageY - this.element.offsetTop;
    const walkY = (Y - this.startY) * this.speed;

    if (Math.abs(walkX) > Math.abs(walkY) || this.isScrolled) {
      this.isVertical = false;
      this.isScrolled = true;
      this.element.classList.add('active');
      this.element.scrollLeft = this.scrollLeft - walkX;
      event.stopPropagation();
      event.preventDefault();
    } else if (Math.abs(walkX) > 20) {
      this.isVertical = true;
    }
  };
}

export default dragSlider;
