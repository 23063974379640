import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MoneyFormat from '../MoneyFormat';
import Image from './../Image';
import useIsMobile from './../../hooks/useIsMobile';
import PerfectScrollbar from 'perfect-scrollbar';
import classNames from 'classnames';

const body = document.querySelector('body');

const ProductList = () => {
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);
  const [container, setContainer] = useState({})

  const { products, sub_total, total, iva, containers } = useSelector((state) => state.cart);
  const { shipping, loyalty_points, paymentMethod } = useSelector(state => state.purchase)
  const { overlay } = useSelector((state) => state.user);

  const containerProducts = useRef(false);

  const handleOpen = () => {
    if (open) {
      body.classList.remove('no-scroll');
    } else {
      body.classList.add('no-scroll');
    }
    setOpen(!open);
  };

  useEffect(() => {
    const resize = () => {
      setOpen(false);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (containers.length) {
      setContainer([...containers].pop());
    }
  }, [containers])

  useEffect(() => {
    let perfectScrollbar = null;
    if (!isMobile && containerProducts.current) {
      perfectScrollbar = new PerfectScrollbar(containerProducts.current);
    } else if (isMobile && perfectScrollbar) {
      perfectScrollbar.destroy();
      perfectScrollbar = null;
    }

    return () => {
      if (perfectScrollbar) {
        perfectScrollbar.destroy();
        perfectScrollbar = null;
      }
    };
  });

  const totalPayment = () => {
    const sum = total + (shipping.total || 0);
    return paymentMethod !== 'loyalty' ? sum - loyalty_points : sum;
  }

  return (
    <section id='product-list' className={classNames({
      'open': open,
      'open--overlay': overlay
    })}>
      <span className='link' onClick={handleOpen}>
        <i className={`icon-${open ? 'minus' : 'plus'}-circle`}></i> Detalle de la compra
      </span>
      <div className='content'>
        <h1>Detalle</h1>
        <div className='box-detail'>
          {containers.length} x
          <i className='icon-box-open-1'></i>
          {container.name}
        </div>
        <div className='articles' ref={containerProducts}>
          {products.map((product, productIndex) => (
            <article key={productIndex}>
              <Link target='_blank' to={`/producto/${product.product_id}/${product.code || 'detalle'}`}>
                <h1>{product.name}</h1>
                <div>
                  <div className='img'>
                    <Image src={product.media[0]?.sizes.small} alt={product.name} />
                  </div>
                  <div className='detail'>
                    <div className='price'>
                      Precio:
                      <span>
                        <MoneyFormat money={product.price} />
                      </span>
                    </div>
                    <div className='quantity'>
                      Cantidad:
                      <span>{product.quantity}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </article>
          ))}
        </div>
        <table className='total'>
          <tbody>
            <tr>
              <th>Subtotal:</th>
              <td>
                <MoneyFormat money={sub_total} />
              </td>
            </tr>
            <tr>
              <th>IVA:</th>
              <td>
                <MoneyFormat money={iva} />
              </td>
            </tr>
            {shipping && <tr className='cost-shipping'>
              <th>Costo de envío:</th>
              <td>
                <MoneyFormat money={shipping.total} />
              </td>
            </tr>}
            {loyalty_points > 0 && loyalty_points < (total + (shipping.total || 0)) && <tr className='cost-shipping'>
              <th>Monedero electrónico:</th>
              <td>
                -<MoneyFormat money={loyalty_points} />
              </td>
            </tr>}
            <tr>
              <th>Total:</th>
              <td>
                <MoneyFormat money={totalPayment()} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ProductList;
