import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { highlightText } from '../helpers/index';
import { analyticsSearchProductDetails } from '../store/actions/analytics-events';
import { openSearch, search } from '../store/actions/search';
import useIsMobile from './../hooks/useIsMobile';
import { analyticsSearch } from './../store/actions/analytics-events';
import PerfectScroll from './PerfectScroll';

const SearchText = () => {
  const { results, error, loader, keyword } = useSelector((state) => state.search);

  const [text, setText] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile()

  const timer = useRef(false);

  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = false;
    }
  };

  useEffect(() => {
    if (!text) return;
    clearTimer();

    timer.current = setTimeout(() => {
      analyticsSearch(text);
      dispatch(search(text.trim(), false));
    }, 1000);

    return () => {
      clearTimer();
    };
  }, [dispatch, text]);

  const updateText = ({ currentTarget: { value } }) => {
    setText(value.replace(/[^a-z0-9ñáéíóúü\s]/gi, ''));
  };

  const sendForm = (event) => {
    event.preventDefault();
    clearTimer();
    event.currentTarget.blur();
    const searchText = text.trim();
    if(!searchText) return;
    analyticsSearch(searchText);
    dispatch(search(searchText, true));
    history.push(`/buscar/${encodeURI(text.trim())}`);
    window.scroll(0, 400)
    setText('');
  };

  const closeSearch = (product) => {
    dispatch(openSearch(false));
    analyticsSearchProductDetails(product);
    setText('')
  };

  const showResults = () => {
    return text && text.trim() === keyword && (results.length || error);
  };

  const _openSearch = () => dispatch(openSearch(true))

  return (
    <form className={`search-form-${isMobile ? 'mobile' : 'desktop'}${text ? ' active' : ''}${loader ? ' search-loader' : ''}${!!keyword.length && !results.length ? ' not-results' : ''}`} onSubmit={sendForm}>
      <input type='text' placeholder='Busca un producto en particular' onChange={(event) => updateText(event)} value={text} />
      {isMobile ? <span className='btn-search-text' onClick={_openSearch}>
        <i className='icon-search-1'></i>
      </span> : <button className='btn-search-text'>
        <i className='icon-search-1'></i>
      </button>}
      {showResults() && (
        <PerfectScroll height={results.length * 23} className={`search-result-scroll${error ? ' error' : ''}`}>
          <ul className='search-results'>
            {error && (
              <li className='not-found'>
                <span>
                  <img src='/img/search-alert.png' alt='Search alert' />
                </span>
                <div className='msg'>
                  <strong>¡Lo sentimos!</strong>
                  No hay resultados para tu búsqueda.
                </div>
              </li>
            )}
            {results.map((result, index) => (
              <li key={index}>
                <Link onClick={() => closeSearch(result)} to={`/producto/${result.id}/${result.code}`}>
                  <div dangerouslySetInnerHTML={{ __html: highlightText(result.name, keyword) }}></div>
                </Link>
              </li>
            ))}
          </ul>
        </PerfectScroll>
      )}
    </form>
  );
};

export default SearchText;
