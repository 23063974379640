import React, { useState } from 'react';
import fileBase64 from '../../helpers/file-base64';
import Modal from '../Modal';
import ResizeImage from '../../helpers/resizeImage';
import classNames from 'classnames';

let body;
if (typeof window !== 'undefined') {
  body = document.querySelector('body');
}

const UploadFile = ({ onChange, file, maxSize = 5, disabled }) => {
  const [openUpload, setOpenUpload] = useState(false);
  const [isImage, setIsImage] = useState(false)

  const uploadFile = async (event) => {
    let uploadedFile;
    if(isImage){
      const getImageData = await new ResizeImage(event.currentTarget).getResize(1000);
      uploadedFile = getImageData.onlyCode();
    }
    else{
      uploadedFile = await fileBase64(event, maxSize);
    }

    onChange(uploadedFile);
    if(uploadedFile.ok){
      handleOpen(false);
    }
  };

  const closeError = () => {
    onChange(null);
    handleOpen(true);
  };

  const handleOpen = (status) => {
    if(disabled) return;
    setOpenUpload(status)
    if(status){
      body.classList.add('no-scroll')
    }else{
      body.classList.remove('no-scroll')
    }
  }

  return (
    <div className={classNames('upload-file', {
      'has-file': file,
      'disabled': disabled
    })}>
      {!file && (
        <span className='btn btn-primary-outline rounded-small' onClick={() => handleOpen(true)}>
          ADJUNTAR ARCHIVO
        </span>
      )}
      {file && (
        <div className='file-uploaded'>
          <div className='icon'>
            <i className={`icon-${file.icon}`}></i>
          </div>
          <div className='name'>
            <span className='file-name'>
              {file.name} <i>{file.type}</i>
            </span>
          </div>
          <div className='trash'>
            <i className='icon-trash' onClick={() => onChange(null)}></i>
          </div>
        </div>
      )}
      {file?.error && (
        <Modal className='error-upload-file' open={file?.error} close={closeError}>
          <img loading='eager' src='/img/alerta_negativa.png' alt='Error' />
          <h1>Lo sentimos</h1>
          <p>
            Ha ocurrido un error al cargar tu archivo.
            <br />
            Por favor, intenta nuevamente.
          </p>
          <span className='btn btn-primary rounded-small' onClick={closeError}>
            ADJUNTAR ARCHIVO
          </span>
        </Modal>
      )}
      {!file && (
        <Modal
          open={openUpload}
          close={() => {
            setOpenUpload(false);
          }}>
          <span className='title'>Selecciona el tipo de archivo:</span>
          <label className='file-pdf-xls' onClick={() => setIsImage(false)}>
            <input type='file' accept='application/pdf' onChange={uploadFile} />
            <span className='icon-document'></span>
            <strong>DOCUMENTO:<br />PDF</strong>
          </label>
          <label className='file-picture' onClick={() => setIsImage(true)}>
            <input type='file' accept='image/png, image/jpeg' onChange={uploadFile} />
            <span className='icon-picture'></span>
            <strong>IMAGEN:<br />JPG, PNG</strong>
          </label>
          <p className='max-size'>Peso máximo: {maxSize} MB.</p>
        </Modal>
      )}
    </div>
  );
};

export default UploadFile;
