import React from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import ShippingDesktop from './ShippingDesktop';
import ShippingMobile from './ShippingMobile';


function ShippingFlow({ servicesData }) {
    const isMobile = useIsMobile();
    const names = Object.keys(servicesData)

    return (
        isMobile
            ?
            // mobile flow
            <ShippingMobile
                names={names}
                info={servicesData}
            />
            :
            // Desktop Flow
            < ShippingDesktop
                names={names}
                info={servicesData}
            />
    )
}

export default ShippingFlow
