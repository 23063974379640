import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { validateEmail, validateName, validatePhone, validateRFC } from '../../helpers/validations';
import { registerUser, userChangeStep, userMenuControl, userRegistered } from '../../store/actions/user';
import ContainerAlert from '../Detail/ContainerAlert';
import FakeSelect from '../FormElements/FakeSelect';
import FormInput from '../FormElements/FormInput';
import Loader from '../Loader';
import AppButton from '../UI/Buttons';
import ModalBox from '../UI/ModalBox';
import { validatePassword } from './../../helpers/validations';
import { getUserData, registerUserNetwork } from './../../store/actions/user';

const RegistrationMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showValue, setShowValue] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const { catalogs } = useSelector((state) => state.home);
  const { loader, layer, registerResponse, step, user_data_network } = useSelector((state) => state.user);

  const btnGoogle = useRef(false);

  const goToCreateAccount = useCallback(() => {
    dispatch(userChangeStep(1));
    dispatch(userMenuControl(false, 'user-form'));
  }, [dispatch]);

  useEffect(() => {
    const attachSigning = (element) => {
      window.auth2.attachClickHandler(
        element,
        {},
        function (googleUser) {
          dispatch(getUserData('google', googleUser.getAuthResponse().id_token));
          goToCreateAccount();
        },
        function (error) { },
      );
    };
    if (window.gapi && btnGoogle.current) {
      window.gapi.load('auth2', function () {
        window.auth2 = window.gapi.auth2.init({
          client_id: process.env.GOOGLE_SIGNING_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
        });
        attachSigning(btnGoogle.current);
      });
    }
  }, [dispatch, goToCreateAccount]);

  useEffect(() => {
    if (catalogs.length) {
      const items = catalogs.map((category) => ({ value: category.code, id: category.id, label: category.alt_name || category.name }));
      setCategoriesArr(items);
    }
  }, [dispatch, catalogs]);

  useEffect(() => {
    if (step === 0) dispatch(userMenuControl(false, 'user-registration'));
  }, [dispatch, step]);

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      dispatch(getUserData('facebook', response.authResponse.accessToken));
      goToCreateAccount();
    } else {
      facebookLogin();
    }
  };

  const facebookLogin = () => {
    window.FB.login(function (response) {
      statusChangeCallback(response);
    });
  };

  const handleFacebookConnect = () => {
    window.FB.getLoginStatus((response) => {
      statusChangeCallback(response);
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
  });

  const submit = (data) => {
    if (user_data_network && user_data_network.name) {
      data.social_network = {
        social_type: user_data_network.social_type,
        access_token: user_data_network.access_token,
      };
      dispatch(registerUserNetwork(data));
    } else {
      dispatch(registerUser(data));
    }
  };

  const cancelSubmit = () => {
    dispatch(userChangeStep(0));
  };

  const handleContinue = () => {
    if (step !== 3) {
      dispatch(userChangeStep(step + 1));
    } else {
      submit();
    }
  };

  const { name, email, phone, password, password_confirm, sector } = watch();

  const enableSubmitSteps = [name && email && phone && sector, password && password_confirm && password === password_confirm];

  const enableSubmit = () => {
    if (step === 1) {
      return enableSubmitSteps[0] && !Object.keys(errors).length;
    }
    return enableSubmitSteps[0] && enableSubmitSteps[1] && !Object.keys(errors).length;
  };

  const [show_alert_modal, setShowModal] = useState(false);

  useEffect(() => {
    if (registerResponse.status_code === 200) {
      setShowModal(true)
      dispatch(userRegistered(true))
    }
  }, [dispatch, registerResponse])

  return (
    <section className={`register ${[3, 203].includes(registerResponse.status_code) ? 'register--action' : ''} ${registerResponse.status_code === 202 ? 'register-events--action' : ''}`}>
      {loader && !registerResponse && <Loader spinner={{ title: 'Cargando' }} blue />}
      {step === 0 && !loader && (
        <div className='register-menu'>
          <h2 className='mxp-subtitle'>Registro</h2>
          <p className='mxp-text'>Obtén beneficios adicionales y una mejor experiencia dentro de nuestro sitio.</p>
          <nav className='register-mobile-nav'>
            <AppButton type='main' txt='Crear una cuenta' icon='facebook' available={true} customClick={goToCreateAccount} />

            <AppButton type='connect' txt='Registrarme con Facebook' icon='facebook' modifier='login-facebok' available={true} customClick={handleFacebookConnect} />
            <AppButton type='connect' txt='Registrarme con Google+' icon='google' modifier='google' available={true} refValue={btnGoogle} />

            <AppButton type='outlined' txt='Regresar' available={true} customClick={() => history.push('/box')} />
          </nav>
        </div>
      )}
      {layer === 'user-form' && !loader && step !== 0 && ![3, 203].includes(!registerResponse.status_code) && (
        <>
          <h2 className='mxp-subtitle'>Crear una cuenta</h2>
          <div className='register-user-tabs'>
            <nav className='user-tab-head'>
              <div className={`user-tab-link ${step === 1 ? 'user-tab-link--active' : ''}`}>
                {step !== 1 && <span className='user-tab-link-number'>1</span>}
                {step === 1 && <span className='user-tab-link-fullName'>1.- Datos personales</span>}
              </div>
              <div className={`user-tab-link ${step === 2 ? 'user-tab-link--active' : ''}`}>
                {step !== 2 && <span className='user-tab-link-number'>2</span>}
                {step === 2 && <span className='user-tab-link-fullName'>2.- Contraseña</span>}
              </div>
              <div className={`user-tab-link ${step === 3 ? 'user-tab-link--active' : ''}`}>
                {step !== 3 && <span className='user-tab-link-number'>3</span>}
                {step === 3 && <span className='user-tab-link-fullName'>3.- Adicionales</span>}
              </div>
            </nav>
            <article className='user-tab-tabs'>
              <form className='login-form' onSubmit={handleSubmit(submit)}>
                <div className={`user-tab-page ${step === 1 ? 'user-tab-page--active' : ''}`}>
                  <div className='register-inputs'>
                    <FormInput
                      label='Nombre completo*'
                      placeholder='Jorge Eduardo Hernández Pérez'
                      name='name'
                      register={register}
                      options={validateName}
                      error={errors.name}
                      type='text'
                      defaultValue={user_data_network.name || ''}
                      setValue={setValue}
                      validate='name'
                    />
                    <FormInput
                      label='Correo electrónico*'
                      placeholder='jorgeEHP@correo.com'
                      name='email'
                      register={register}
                      options={validateEmail}
                      error={errors.email}
                      type='email'
                      defaultValue={user_data_network.email || ''}
                      setValue={setValue}
                      validate='email'
                    />
                    <FormInput
                      label='Teléfono*'
                      placeholder='10 dígitos'
                      name='phone'
                      register={register}
                      options={validatePhone}
                      error={errors.phone}
                      type='text'
                      defaultValue={''}
                      setValue={setValue}
                      validate='number'
                      maxLength='10'
                    />
                    <div className='register-user-type'>
                      <label className='register-user-type-label'>Tipo de perfil*</label>
                      <FakeSelect
                        className='register-user-type-select'
                        id='register-user-type-select'
                        placeholder='Selecciona un perfil'
                        options={categoriesArr}
                        name='sector'
                        onChange={setValue}
                        value={sector}
                        registerOptions={{ shouldValidate: true, required: true }}
                      />
                    </div>
                  </div>
                </div>
                <div className={`user-tab-page ${step === 2 ? 'user-tab-page--active' : ''}`}>
                  <div className='register-field register-field--passwords'>
                    <div className='register-inputs'>
                      <FormInput
                        label='Contraseña*'
                        placeholder='Ingresa de 8 a 30 caracteres'
                        name='password'
                        register={register}
                        error={errors.password}
                        options={validatePassword}
                        type='password'
                        defaultValue={''}
                        setValue={setValue}
                        setShowValue={setShowValue}
                        showValue={showValue}
                        className='pass'
                      />
                      <FormInput
                        label='Confirmar contraseña*'
                        placeholder='Ingresa de 8 a 30 caracteres'
                        name='password_confirm'
                        register={register}
                        error={errors.password_confirm}
                        options={validatePassword}
                        type='password'
                        defaultValue={''}
                        setValue={setValue}
                        setShowValue={setConfirmValue}
                        showValue={confirmValue}
                        className='pass'
                      />
                      <p className='mxp-text'> Recuerda que la contraseña debe contener mínimo 8 caracteres, incluir mayúsculas, minúsculas, números y caracteres especiales ($#&,.-). </p>
                    </div>
                  </div>
                </div>
                <div className={`user-tab-page ${step === 3 && !registerResponse ? 'user-tab-page--active' : ''}`}>
                  <div className='register-field register-field--extras'>
                    <div className='register-inputs'>
                      <FormInput
                        label='RFC (opcional)'
                        placeholder='13 caracteres'
                        name='rfc'
                        register={register}
                        error={errors.rfc}
                        type='text'
                        defaultValue={''}
                        setValue={setValue}
                        options={validateRFC}
                      />
                    </div>
                    <nav className='user-tab-nav'>
                      <AppButton type='main' txt='Registrarme' available={enableSubmit()} loader={loader} />
                    </nav>
                  </div>
                </div>
              </form>
              {!registerResponse && (
                <nav className={`user-tab-nav--not-submitable ${step === 3 ? 'user-tab-nav--not-submitable-last' : ''}`}>
                  {step !== 3 && <AppButton type='main' txt='Continuar' available={enableSubmit()} loader={loader} customClick={handleContinue} />}

                  <AppButton type='outlined' txt='Cancelar' available={true} customClick={cancelSubmit} />
                </nav>
              )}
            </article>
          </div>
        </>
      )}
      {!loader && [3, 203].includes(registerResponse.status_code) && (
        <>
          <h2 className='mxp-subtitle'>Registro</h2>
          <div className='purchases-product-response purchases-product-response--error'>
            <figure className='purchases-product-icon'>
              <span className='icon-alert'></span>
            </figure>

            <h3 className='mxp-sub-subtitle'>Lo sentimos ha ocurrido un error inesperado.</h3>
            <p className='mxp-text'>Por favor, intenta nuevamente</p>
            <AppButton type='main' txt='Volver a intentar' onClick={cancelSubmit} available={true} />
            <AppButton type='outlined' txt='Cancelar' available={true} customClick={() => history.push('/')} />
          </div>
        </>
      )}
      {registerResponse.status_code === 202 && (
        <>
          <h2 className='mxp-subtitle'>Registro</h2>
          <div className='purchases-product-response purchases-product-response--ok'>
            <figure className='purchases-product-icon'>
              <span className='icon-ok-2'></span>
            </figure>
            <h3 className='mxp-sub-subtitle'>{registerResponse.short_message}</h3>
            <p className='mxp-text'>Para terminar tu registro debes activar tu cuenta, revisa tu correo electrónico.</p>
            <AppButton
              type='main'
              txt='Ir al catálogo'
              onClick={() => {
                history.push('/');
              }}
              available={true}
            />
          </div>
        </>
      )}
      {registerResponse.status_code === 200 && (
        <ModalBox
          show={show_alert_modal}
          modifier='mb-alerts-wrap'
        >
          <ContainerAlert
            type="global"
            customAction={() => {
              setShowModal(true)
            }}
            cancelAction={() => {
              setShowModal(false)
              history.push('/')
            }}
            setShowModal={setShowModal}
            title="¡Lo sentimos!"
            copy={registerResponse.message}
            label="Reintentar"
          />
        </ModalBox>
      )}
    </section>
  );
};

export default RegistrationMobile;
