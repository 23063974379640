import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewAddress from '../../../components/NewAddress';
import AddressList from '../../../components/User/AddressList';
import { userCreateAddress, userSetAddress, userUpdateAddress } from '../../../store/actions/user';
import ProfileContainer from './../ProfileContainer';

const MyAddressesPage = () => {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.user);

  const handleSaveNewAddress = (data) => {
    window.scroll(0,0)
    if (data.address_id) {
      dispatch(userUpdateAddress(data));
    } else {
      dispatch(userCreateAddress(data));
    }
  };

  const handleCloseForm = () => {
    dispatch(userSetAddress(false));
  };

  return (
    <ProfileContainer className='address-container'>
      <AddressList />
      {address && (
        <NewAddress
          title={typeof formAddress === 'object' ? 'Actualizar dirección' : 'Nueva dirección'}
          labelSubmit={typeof formAddress === 'object' ? 'ACTUALIZAR DIRECCIÓN' : 'GUARDAR DIRECCIÓN'}
          onSubmitForm={handleSaveNewAddress}
          initialData={address}
          logged
          close={handleCloseForm}
        />
      )}
    </ProfileContainer>
  );
};

export default React.memo(MyAddressesPage);
