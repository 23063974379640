import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDeliveryType } from '../../store/actions/purchase';

const ChangeDeliveryType = () => {
  const dispatch = useDispatch();
  const {deliveryType} = useSelector(state => state.purchase)

  const handleDeliveryType = (type) => dispatch(changeDeliveryType(type))

  return (<div className='delivery-type'>
  <strong>Selecciona tu forma de entrega:</strong>
  <label className='form-radio'>
    <input type='radio' name='delivery-type' value='home' onChange={() => handleDeliveryType('home')} checked={deliveryType === 'home'} />
    <i></i>
    <span>Envío a domicilio</span>
  </label>
  <label className='form-radio'>
    <input type='radio' name='delivery-type' value='office' onChange={() => handleDeliveryType('office')} checked={deliveryType === 'office'} />
    <i></i>
    <span>Recoger en sucursal</span>
  </label>
</div>);
};

export default ChangeDeliveryType;
