import React from 'react';

const SlideToggle = ({label, enabled, onChange, refValue}) => {
  const handleChange = () => {
    if(onChange){
      onChange(!enabled)
    }
  }
  return (<div className={`slide-toggle${enabled? ' active': ''}`}>
    {label && <label>{label}</label> }
    <span ref={refValue} onClick={handleChange}><i></i></span>
  </div>);
};

export default SlideToggle;
