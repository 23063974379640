import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { clearText, onlyAcceptEmail, onlyAcceptName } from '../../helpers/validations';
import { onlyAlphaNumeric, onlyAcceptNumbers, clearAddress } from './../../helpers/validations';

const validations = {
  name: (value) => {
    return value.replace(onlyAcceptName, '')
  },
  alpha: (value) => {
    return value.replace(onlyAlphaNumeric, '')
  },
  text: (value) => {
    return value.replace(clearText, '')
  },
  number: (value) => {
    return value.replace(onlyAcceptNumbers, '')
  },
  email: (value) => {
    return value.replace(onlyAcceptEmail, '')
  },
  address: (value) => {
    return value.replace(clearAddress, '')
  },
}

const FormInput = ({ showValue, setShowValue, label, value, disabled, loader, register, options, error, name, type = 'text', className, setValue, validate, pattern, ...rest }) => {

  const [forceText, setForceText] = useState(false)
  const init = useRef(false);

  const handleShowValue = () => {
    if(setShowValue){
      return setShowValue(!showValue)
    }
    setForceText(!forceText)
  }

  const handleChange = (event) => {
    let value = event.currentTarget.value;
    if (validate) {
      value = validations[validate](value);
    }
    if (pattern) {
      value = value.replace(pattern, '');
    }
    return setValue(name, value, { shouldValidate: true, shouldDirty: true})
  }

  useEffect(() => {
    if(!init.current && rest.defaultValue && setValue){
      setValue(name, rest.defaultValue)
      init.current = true;
    }
  }, [name, rest, setValue])

  return (
    <div className={`form-input${className ? ' ' + className : ''}${value ? ' active' : ''}${error ? ' error' : ''}${disabled ? ' disabled' : ''}${loader ? ' loading' : ''}`}>
      <label>
        <span>{label}</span>
        <div>
          <input type={(type === 'email' || type !== 'password' || (showValue || forceText)) ? 'text' : 'password' || type === 'phone' ? type : 'text'}  {...register(name, options)} inputMode={type} disabled={disabled} onChange={handleChange} autoComplete="off" {...rest} />
        {type === 'password' && <i className={`pass-icon icon-eye-${(showValue || forceText) ? 'on' : 'off'}`} onClick={handleShowValue}></i>}
        </div>
      </label>
      {error && <span className='error'>{error.message}</span>}
    </div>
  );
};

export default React.memo(FormInput);
