import React from 'react';
import { connect } from 'react-redux';

class Card extends React.Component {
  state = {
    value: '',
  };

  isActive = (id) => {
    if(this.props.step === 3 && this.props.tmpToken){
      return id === this.props.tmpToken;
    }
    return this.props.token === id;
  }

  setToken = (event, id) => {
    if(!event.currentTarget.classList?.contains('selected')){
      event.currentTarget.scrollIntoView({alignToTop: false, inline: 'center', block: 'nearest'})
      this.props.setToken(id)
    }
  }

  render() {
    return (
      <div className={`item-card${this.isActive(this.props.id) ? ' selected' : ''}`} onClick={(event) => this.setToken(event, this.props.id)}>
        <div className='logo'>
          <img src='/img/ico-mastercard.svg' alt='TC Mastercard' />
        </div>
        <div className='tc-number'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {this.props.last4}
        </div>
        <div className='expire'>
          <div className='date'>
            {this.props.exp_month}/{this.props.exp_year}
          </div>
          <div className='cvv'>
            CVV
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    token: state.purchase.tokenCard,
    tmpToken: state.purchase.tmpCardToken,
    step: state.purchase.step,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch({ type: 'PURCHASE_SET_CARD_TOKEN', token }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(Card);
