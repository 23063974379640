import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getLinkedSocialProfile, patchLinkedSocialProfile, postLinkedSocialProfile, userSocialNetworkErrorClear } from '../../../store/actions/user';
import ContainerBoxPage from './../../../components/containers/ContainerBoxPage';
import SlideToggle from './../../../components/FormElements/SlideToggle';
import { AppButton } from './../../../components/UI/Buttons';
import ModalBox from './../../../components/UI/ModalBox';
import useIsMobile from './../../../hooks/useIsMobile';

const Menu = () => {
  const [facebook, setFacebook] = useState(false);
  const [google, setGoogle] = useState(false);

  const dispatch = useDispatch();
  const btnGoogle = useRef(false);

  const { user_social_linked, socialNetworkError } = useSelector((state) => state.user);

  const updateLinkedSocial = (type, value) => {
    if (value) {
      dispatch(patchLinkedSocialProfile(type));
      if (type === 'facebook') {
        setFacebook(false);
      } else {
        setGoogle(false);
      }
    } else {
      if (type === 'google') {
        window.auth2.signIn().then(function (response) {
          if (response) {
            dispatch(postLinkedSocialProfile('google', response.getAuthResponse().id_token));
          }
        });
      } else {
        checkLoginState();
      }
    }
  };

  useEffect(() => {
    if (user_social_linked) {
      if (user_social_linked.data && user_social_linked.data.length === 0) {
        setFacebook(false);
        setGoogle(false);
      } else {
        if (user_social_linked.data && user_social_linked.data.length > 0) {
          // TODO: Validate assign social network
          user_social_linked.data.forEach((item) => {
            const value = item.status === 1 || item.status ? true : false;
            if (item.social_type === 'facebook') {
              setFacebook(value);
            } else {
              setGoogle(value);
            }
          });
        }
      }
    } else {
      if (user_social_linked === undefined) {
        dispatch(getLinkedSocialProfile());
      }
    }
  }, [dispatch, user_social_linked]);

  // Integracion de redes sociales
  // Google
  useEffect(() => {
    if (window.gapi && btnGoogle.current) {
      window.gapi.load('auth2', function () {
        window.auth2 = window.gapi.auth2.init({
          client_id: process.env.GOOGLE_SIGNING_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
        });
      });
    }
  }, []);
  // Google

  // Facebook
  const FB = window.FB;

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v2.7',
      });
    };
  }, [FB]);

  const checkLoginState = () => {
    FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      dispatch(postLinkedSocialProfile('facebook', response.authResponse.accessToken));
    } else {
      facebookLogin();
    }
  };

  const facebookLogin = () => {
    FB.login(function (response) {
      if (response.authResponse) {
        dispatch(postLinkedSocialProfile('facebook', response.authResponse.accessToken));
      } else {
        // not auth / cancelled the login!
      }
    });
  };
  // Facebook
  // FINAL Integracion de redes sociales

  const clearSocialNetworkError = () => {
    dispatch(userSocialNetworkErrorClear());
  };

  return (
    <>
      <div className='menu-profile-mobile'>
        <h1 className='title'>
          <i className='icon-left-open'></i>
          Mi perfil
        </h1>
        <ul>
          <li>
            <NavLink to='/perfil/datos-personales' className='link-info'>
              <i className='icon-section icon-login'></i>
              <span>Datos personales</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/perfil/cambiar-contrasena' className='link-password'>
              <i className='icon-section icon-key'></i>
              <span>Cambiar contraseña</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/perfil/mis-direcciones' className='link-address'>
              <i className='icon-section icon-pin'></i>
              <span>Mis direcciones</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/perfil/mis-tarjetas' className='link-cards'>
              <i className='icon-section icon-card-2'></i>
              <span>Mis tarjetas</span>
            </NavLink>
          </li>
        </ul>

        <div className='user-social-networks'>
          <strong>Redes sociales vinculadas</strong>
          <div className='user-facebook'>
            <div>
              <img src='/img/ico-facebook.png' alt='facebook' /> Facebook
            </div>
            <SlideToggle
              enabled={facebook}
              onChange={() => {
                updateLinkedSocial('facebook', facebook);
              }}
            />
          </div>
          <div className='user-google'>
            <div>
              <img src='/img/ico-google-plus.png' alt='google' /> Google
            </div>{' '}
            <SlideToggle
              refValue={btnGoogle}
              enabled={google}
              onChange={() => {
                updateLinkedSocial('google', google);
              }}
            />
          </div>
        </div>
      </div>
      <ModalBox show={socialNetworkError} modifier='modal-alert' close={clearSocialNetworkError}>
        {socialNetworkError.status_code !== 220 && <i className='icon-alert'></i>}
        {socialNetworkError.status_code === 220 && <i className='icon-ok-2'></i>}
        <h1>{socialNetworkError.title || '¡Lo sentimos!'}</h1>
        {socialNetworkError.short_message && <strong>{socialNetworkError.short_message}</strong>}
        {socialNetworkError.message && <p>{socialNetworkError.message}</p>}
        <AppButton txt='Cerrar' type='main' onClick={clearSocialNetworkError} available />
      </ModalBox>
    </>
  );
};

const MyProfileMenu = ({ single }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const history = useHistory();

  if (location.pathname.includes('/perfil/mi-perfil') && !isMobile) {
    history.push('/perfil/datos-personales');
    return '';
  }

  if (single) {
    return <Menu />;
  }

  return (
    <ContainerBoxPage className='profile-page'>
      <div className='profile-page-container'>
        <Breadcrumbs />
        <Menu />
      </div>
    </ContainerBoxPage>
  );
};

export default React.memo(MyProfileMenu);
