import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class ConfirmPayment extends React.Component {
  goCart = () => {
    this.props.clearOrderID();
    this.props.clearPurchase();
  };

  render() {
    return (
      <div id='confirm-payment-logged'>
        <i className='icon-ok'></i>
        <h1>¡Gracias!</h1>
        <p>Tu compra se realizó con éxito.</p>
        <div className='code'>
          Código de tu pedido:
          <strong>{this.props.reference}</strong>
        </div>
        <div className='actions'>
          <Link onClick={() => this.goCart()} to='/categorias' className='btn btn-primary rounded go-cart'>
            IR A CATÁLOGO
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state) => {
  return {
    reference: state.purchase.payment.reference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearPurchase: () => dispatch({ type: 'PURCHASE_CLEAR_DATA' }),
    clearOrderID: () => dispatch({ type: 'RESET_CART' }),
  };
};

const createConnect = connect(mapStateProps, mapDispatchToProps);

export default createConnect(ConfirmPayment);
