import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { purchaseGetLocationsByZip, purchaseSetPersonalInfo } from '../../store/actions/purchase';
import FakeSelect from '../FormElements/FakeSelect';
import FormInput from '../FormElements/FormInput';
import { validateAddress, validateEmail, validateName, validateNumberStreet, validatePhone, validateRFC, validateZip, validateText } from './../../helpers/validations';

const GeneralDataGuest = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { locationsByZip, loaderLocations, personalInfo } = useSelector((state) => state.purchase);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
  } = useForm({
    mode: 'onBlur',
  });

  const { name, email, phone, exteriorNumber, zip, suburb, state, city, references, address } = watch();

  const enableSubmit = () => {
    const errorsArr = { ...errors };
    delete errorsArr.rfc;
    return !Object.keys(errorsArr).length && name && email && phone && address && exteriorNumber && zip && state && city && suburb && references;
  };

  useEffect(() => {
    if(personalInfo.suburb){
      setValue('suburb', personalInfo.suburb)
    }
  }, [setValue, personalInfo.suburb])

  useEffect(() => {
    if(isDirty && zip){
      dispatch(
        purchaseSetPersonalInfo({
          address: false,
          alias: false,
          city: false,
          email: false,
          exteriorNumber: false,
          interiorNumber: false,
          name: false,
          phone: false,
          references: '',
          state: false,
          suburb: false,
          zip: false,
          rfc: false,
        }),
      );
      setValue('suburb', false);
    }
  }, [dispatch, setValue, isDirty, zip]);

  const submit = (data) => {
    dispatch(
      purchaseSetPersonalInfo({
        address: data.address.trim(),
        alias: data.alias ? data.alias.trim() : '',
        city: data.city,
        email: data.email.trim(),
        exteriorNumber: data.exteriorNumber.trim(),
        interiorNumber: data.interiorNumber.trim(),
        name: data.name.trim(),
        phone: data.phone.trim(),
        references: data.references.trim(),
        state: data.state,
        suburb: data.suburb,
        zip: data.zip.trim(),
        rfc: data.rfc.trim(),
      }),
    );
    history.push('/carrito/metodo-de-entrega');
  };

  useEffect(() => {
    if (zip && /^[0-9]{5}$/.test(zip)) {
      dispatch(purchaseGetLocationsByZip(zip));
    }
  }, [dispatch, zip]);

  useEffect(() => {
    if (locationsByZip.message) {
      setError('zip', { type: 'zip', message: locationsByZip.message }, true);
    } else if (locationsByZip.city) {
      setValue('city', locationsByZip.city);
      setValue('city_label', locationsByZip.city.name);
      setValue('state', locationsByZip.state);
      setValue('state_label', locationsByZip.state.name);
    }
  }, [setValue, setError, locationsByZip]);

  return (
    <form id='general-data-guest' onSubmit={handleSubmit(submit)} className='form-purchase-general-data'>
      <FormInput
        label='Nombre completo*'
        placeholder='Jorge Eduardo Hernández Pérez'
        name='name'
        register={register}
        options={validateName}
        error={errors.name}
        defaultValue={personalInfo.name || ''}
        setValue={setValue}
        validate='name'
      />

      <FormInput
        label='Correo electrónico*'
        placeholder='jorgeEHP@correo.com'
        name='email'
        register={register}
        options={validateEmail}
        error={errors.email}
        type='email'
        defaultValue={personalInfo.email || ''}
        setValue={setValue}
        validate='email'
      />

      <FormInput
        label='Teléfono*'
        placeholder='10 dígitos'
        name='phone'
        register={register}
        options={validatePhone}
        error={errors.phone}
        type='tel'
        maxLength='10'
        defaultValue={personalInfo.phone || ''}
        setValue={setValue}
        validate='number'
      />

      <FormInput
        label='Código Postal*'
        placeholder='00000'
        name='zip'
        type='numeric'
        maxLength='5'
        register={register}
        options={validateZip}
        error={errors.zip}
        loader={loaderLocations}
        readOnly={loaderLocations}
        defaultValue={personalInfo.zip || ''}
        setValue={setValue}
        validate='number'
      />

      <FormInput
        label='Dirección*'
        placeholder='Nombre de la calle'
        name='address'
        register={register}
        options={validateAddress}
        error={errors.address}
        validate='address'
        defaultValue={personalInfo.address || ''}
        setValue={setValue}
      />

      <div className='address-number'>
        <FormInput
          label='Número exterior*'
          placeholder='000'
          name='exteriorNumber'
          register={register}
          options={validateNumberStreet}
          error={errors.exteriorNumber}
          defaultValue={personalInfo.exteriorNumber || ''}
          setValue={setValue}
          pattern={/[^a-zA-Z0-9]/g}
        />

        <FormInput label='Número interior' placeholder='000' name='interiorNumber' register={register} defaultValue={personalInfo.interiorNumber || ''} setValue={setValue} pattern={/[^a-zA-Z0-9]/g} />
      </div>

      <FakeSelect
        options={locationsByZip?.suburbs}
        label='Colonia*'
        placeholder='Selecciona una colonia'
        value={suburb}
        name='suburb'
        id='form-select-suburb-general-data'
        registerOptions={{ shouldValidate: true, required: true }}
        onChange={setValue}
      />

      <FormInput label='Estado*' name='state_label' register={register} readOnly />

      <FormInput label='Municipio*' name='city_label' register={register} error={errors.city} readOnly />

      <FormInput
        label='Entre las calles*'
        name='references'
        register={register}
        options={validateText}
        error={errors.references}
        defaultValue={personalInfo.references || ''}
        setValue={setValue}
        validate='text'
      />

      <FormInput
        label='RFC'
        name='rfc'
        register={register}
        defaultValue={personalInfo.rfc || ''}
        placeholder='13 caracteres'
        options={validateRFC}
        error={errors.rfc}
        maxLength='13'
        className='input-uppercase'
        setValue={setValue}
        pattern={/[^a-zA-Z0-9]/g}
      />
      <hr />
      <button className={`btn btn-primary${enableSubmit() ? '' : ' disabled'}`}>CONTINUAR</button>
    </form>
  );
};

export default GeneralDataGuest;
