import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { isMobile } from '../helpers';
import { applyFilters, resetFilters } from '../store/actions/filters';
import { analyticsFilter } from '../store/actions/analytics-events';

class ProductFilters extends React.Component {
  state = {
    old: false,
    isMobile: false,
  };

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
    window.addEventListener('resize', () => {
      if (this.state.isMobile !== isMobile()) {
        this.setState({ isMobile: isMobile() });
      }
    });
  }

  addFilter = (name, value) => {
    let itemsOld = false;
    if (this.state.isMobile) {
      itemsOld = !this.state.old ? { ...this.props.items.active } : { ...this.state.old };
    }
    this.setState({ old: itemsOld });
    this.props.add(name, value);
    analyticsFilter(name, value);
    if (window.innerWidth >= 1024) {
      this.props.apply();
    }
  };

  isActive = (name, filter) => {
    if (name === 'price') {
      return this.props.items.active.prices && this.props.items.active.prices.display === filter.display;
    }

    return this.props.items.active[name] && this.props.items.active[name] === filter.id;
  };

  close = (cancel = false) => {
    this.setState({ old: false });
    const productFilters = document.getElementById('product-filters');
    productFilters.classList.remove('active');
    const body = document.querySelector('body');
    body.classList.remove('no-scroll');
    if (this.enableBtn()) {
      this.props.apply();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.items !== this.props.items || nextState.isMobile !== this.state.isMobile || nextState.old !== this.state.old;
  }

  filterActive = (key) => {
    const filtersActive = { ...this.props.items.active };
    let filter;
    if (key === 'prices') {
      filter = filtersActive[key];
      return filter ? { name: filter.display, value: filter } : false;
    }

    const value = filtersActive[key];
    if (!value) return false;

    if (key === 'brand') {
      key = 'brands';
    }
    if (key === 'material') {
      key = 'materials';
    }

    filter = this.props.items.filters[key].find((item) => item.id === value);
    return filter ? { name: filter.name, value: filter.id } : false;
  };

  enableBtn = () => {
    if (typeof this.state.old !== 'object') return false;
    const old = { ...this.state.old };
    const active = { ...this.props.items.active };
    const oldPrice = old.prices ? old.prices.display : false;
    const activePrice = active.prices ? active.prices.display : false;
    return old.sort !== active.sort || old.brand !== active.brand || old.material !== active.material || oldPrice !== activePrice;
  };

  render() {
    if (!this.props.items || typeof this.props.items.filters !== 'object' || !Object.values(this.props.items.filters).length) return '';
    return (
      <section className='product-filters' id='product-filters'>
        <h1>
          <span>Ordenar y filtrar</span>
          <span className='close' onClick={() => this.close(true)}>
            <i className='icon-close'></i>
          </span>
        </h1>
        <div className='options'>
          {this.props.items.filters.sorts && (
            <Fragment>
              <strong className='title'>Ordenar</strong>
              <div className='filters'>
                <ul className='sorts'>
                  {this.props.items.filters.sorts.map((sort, index) => (
                    <li
                      key={index}
                      onClick={() => this.addFilter('sort', sort.id)}
                      className={this.isActive('sort', sort) ? 'enable' : 'disable'}
                    >
                      <i className={`check-rounded ${this.props.isMobile ? '' : ' orange'}`}></i>
                      {sort.display}
                    </li>
                  ))}
                </ul>
              </div>
            </Fragment>
          )}

          <strong className='title'>{this.state.isMobile ? 'Filtrar' : 'Filtros'}</strong>
          <div className='filters inline-close'>
            {this.props.items.filters.prices && (
              <Fragment>
                <span className='type-filter'>Por precio</span>
                <ul>
                  {this.props.items.filters.prices.map((price, index) => (
                    <li onClick={() => this.addFilter('price', price)} className={this.isActive('price', price) ? 'enable' : 'disable'} key={index}>
                      <div>{price.display} <span>({price.product_quantity})</span></div>
                      <span>
                        <i className={`check-rounded ${this.props.isMobile ? '' : ' orange'}`}></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}

            {this.props.items.filters.brands && (
              <Fragment>
                <span className='type-filter'>Por marca</span>
                <ul>
                  {this.props.items.filters.brands.map((brand, index) => (
                    <li onClick={() => this.addFilter('brand', brand)} className={this.isActive('brand', brand) ? 'enable' : 'disable'} key={index}>
                      <div>{brand.name} <span>({brand.product_quantity})</span></div>
                      <span>
                        <i className={`check-rounded ${this.props.isMobile ? '' : ' orange'}`}></i>
                      </span>{' '}
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}
            <span className='type-filter'>Por material</span>
            <ul>
              {this.props.items.filters.materials.map((material, index) => (
                <li onClick={() => this.addFilter('material', material)} className={this.isActive('material', material) ? 'enable' : 'disable'} key={index}>
                  <div>{material.name} <span>({material.product_quantity})</span></div>
                  <span>
                    <i className={`check-rounded ${this.props.isMobile ? '' : ' orange'}`}></i>
                  </span>{' '}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add: (name, value) => dispatch(applyFilters(name, value)),
    resetFilters: (filters) => dispatch(resetFilters(filters)),
  };
};

const createConnect = connect(null, mapDispatchToProps);

export default createConnect(ProductFilters);
