import React from 'react';
import useIsMobile from './../../hooks/useIsMobile';
import CategoriesFilterMobile from './CategoriesFilterMobile';
import CategoriesFiltersCarousel from './CategoriesFiltersCarousel';

const CategoriesFilter = () => {
  const isMobile = useIsMobile();

  return <div id='categories-filter'>{isMobile ? <CategoriesFilterMobile /> : <CategoriesFiltersCarousel />}</div>;
};

export default CategoriesFilter;
