import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useLastProduct = () => {
  const [lastProduct, setLastProduct] = useState(false);
  const { products, last } = useSelector((state) => state.cart);

  useEffect(() => {
    if (last) return setLastProduct(last);

    const product = products ? [...products].pop() : false;
    setLastProduct(product);
  }, [products, last]);

  return lastProduct;
};

export default useLastProduct;
