import axiosApi from '../axiosAPI';
import { types } from '../reducers/cart';
import { userAssociateOrderID } from './user';
import { addToCartProductAnalytics, removeFromCartAnalytics } from './analytics-events';

const getOldProduct = (id, products) => {
  const findProduct = products.find((product) => product.product_id === id);
  if(!findProduct) return { quantity: 0 };

  const variants = {}
  if(!!findProduct.comments.length){
    const itemsVariant = findProduct.comments.split(', ');
    itemsVariant.forEach((variant) => {
      const items = variant.split(': ');
      variants[items[0]] = items[1];
    })
  }

  return {...findProduct, variants};
};

export const itemCart = (id) => (dispatch, getStore) => {
  const item = getStore().cart.products.find((item) => item.product_id === id);
  return item || false;
};

export const addCart = (id, product = false, comment = '') => async (dispatch, getStore) => {
  if (!id) return;

  const {
    profile: { order_id: associateOrderId },
  } = getStore().user;

  dispatch({ type: types.CART_LOADER, loader: id });

  const order_id = getStore().cart.orderID || '';
  await axiosApi.post('v2/cart', { order_id, item: { product_id: id, quantity: 1, comment }, captcha: true }).then((response) => {
    window.localStorage.setItem('orderID', response.data.order_id);
    if (window.innerWidth < 1024) {
      dispatch({ type: types.CART_LAST_ADDED, product });
    }
    dispatch(getCart(response.data.order_id));
    if (!associateOrderId) {
      dispatch(userAssociateOrderID(response.data.order_id));
    }
  });
};

export const updateItemCart = (id, quantity, reloadCart = false, comments = '', product = false) => async (dispatch, getStore, params) => {
  if (!id) return;
  dispatch({ type: types.CART_LOADER, loader: id });

  const { products } = getStore().cart;

  const oldProduct = getOldProduct(id, products);

  dispatch({
    type: types.CART_UPDATE_CART_ANALYTICS, payload: {
      product: {...oldProduct, newVariants: comments},
      quantity: Math.abs(oldProduct.quantity - quantity),
      type: quantity > oldProduct.quantity ? 'add' : 'remove'
    }
  });

  const order_id = getStore().cart.orderID;
  await axiosApi.post('v2/cart', { order_id: order_id || '', item: { product_id: parseInt(id), quantity, comments }, captcha: true }).then((response) => {
    if (product) {
      dispatch({ type: types.CART_LAST_ADDED, product: { ...product, quantity } });
    }
    if (response.data.status_code === 2) {
      dispatch(cartErrorStock({ stock: response.data.stock, id }));
    }
    dispatch(getCart(response.data.order_id));
  });
};

export const deleteItemCart = (id) => async (dispatch, getStore, params) => {
  if (!id) return;

  const { products } = getStore().cart;

  const oldProduct = getOldProduct(id, products);

  dispatch({ type: types.CART_LOADER, loader: id });

  dispatch({
    type: types.CART_UPDATE_CART_ANALYTICS, payload: {
      product: oldProduct,
      quantity: oldProduct.quantity,
      type: 'remove'
    }
  });

  const orderID = getStore().cart.orderID;
  await axiosApi.delete(
    'v1/cart',
    { data: { order_id: orderID, product_id: id }, captcha: true },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  dispatch(getCart())
};

export const getCart = (orderID = false, isRecreate = false) => async (dispatch, getStore) => {
  const order_id = orderID || getStore().cart.orderID || window.localStorage.getItem('orderID');
  if (!order_id) return dispatch(unloadCart());

  const { sendAnalytics } = getStore().cart;

  await axiosApi
    .get(`v2/cart/${order_id}`)
    .then((cart) => {
      if (cart.data.message) {
        if (sendAnalytics) {
          removeFromCartAnalytics(sendAnalytics.product, sendAnalytics.quantity, [])
        }
        dispatch(unloadCart());
      } else {
        if (sendAnalytics) {
          if (sendAnalytics.type === 'add') {
            addToCartProductAnalytics(sendAnalytics.product, sendAnalytics.quantity, cart.data.containers)
          } else {
            removeFromCartAnalytics(sendAnalytics.product, sendAnalytics.quantity, cart.data.containers)
          }
        }
        dispatch({
          type: types.GET_CART,
          cart: { ...cart.data, isRecreate },
          orderID: order_id,
        });
      }
    })
    .catch(() => dispatch(unloadCart()));
};

export const getRelatedProducts = (products) => async (dispatch) => {
  const response = await axiosApi.get(`v1/products/${products.join()}/recommendation?offset=0&limit=10`);

  if (response.data.status_code) return;

  dispatch({ type: types.RELATED_PRODUCT, related: response.data });
};

export const getRelated = (id) => async (dispatch) => {
  if (!id) return;
  const response = await axiosApi.get(`v2/products/recommendation/${id}?limit=50`);
  dispatch({ type: types.RELATED_PRODUCT, related: response.data.items });
};

export const unloadCart = () => (dispatch) => {
  dispatch({ type: 'REMOVE_LOADER' });
  dispatch({ type: types.RESET_CART });
};

export const getLocationByZip = (zip) => async (dispatch, getStore, params) => {
  const location = await axiosApi.get(`v1/locations?zip=${zip}`);
  return location.data;
};

export const cartSetWarnings = (warnings, isRecreate = false) => (dispatch) => {
  dispatch({ type: types.CART_WARNINGS, warnings, isRecreate });
};

let recommendedToCart = false;

export const cartAddRecommendedToCart = (product) => (dispatch) => {
  dispatch({ type: types.CART_ADD_RECOMMENDED, product });
  if (recommendedToCart) {
    clearTimeout(recommendedToCart);
    recommendedToCart = false;
  }
  recommendedToCart = setTimeout(() => {
    dispatch({ type: types.CART_ADD_RECOMMENDED, product: false });
  }, 3000);
};

export const cartSetLastAdded = (product = false) => (dispatch) => {
  dispatch({ type: types.CART_LAST_ADDED, product });
};

export const cartErrorStock = (error) => (dispatch) => {
  dispatch({ type: types.CART_ERROR_ADD_QUANTITY, error });
};

export const cartGetContainers = () => async (dispatch) => {
  let containers = JSON.parse(window.sessionStorage.getItem('containers'));
  if (!containers) {
    const response = await axiosApi.get('/v1/site/container');
    containers = response.data.containers.filter((item) => item.is_principal_group === true);

    window.sessionStorage.setItem('containers', JSON.stringify(containers));
  }

  dispatch({ type: types.CART_GET_CONTAINERS, containers });
};

// new services

export const cartAddItem = (product, quantity) => async (dispatch, getStore) => {
  const {
    logged,
    profile: { order_id: associateOrderId },
  } = getStore().user;

  const { products } = getStore().cart;

  const oldProduct = getOldProduct(product.id || product.product_id, products);

  const diffQuantity = Math.abs(quantity - oldProduct.quantity)

  const order_id = getStore().cart.orderID || '';
  dispatch({ type: types.CART_LOADER, loader: product });
  dispatch(cartSetLastAdded({ ...product, quantity }));
  const response = await axiosApi.post(`v2/cart`, { order_id, item: { product_id: product.id || product.product_id, quantity }, captcha: true });

  if (!order_id && response.data.order_id) {
    window.localStorage.setItem('orderID', response.data.order_id);
  }
  if (logged && !associateOrderId) {
    dispatch(userAssociateOrderID(response.data.order_id));
  }

  dispatch({
    type: types.CART_UPDATE_CART_ANALYTICS, payload: {
      product,
      quantity: diffQuantity,
      type: quantity > oldProduct.quantity ? 'add' : 'remove'
    }
  });

  dispatch(getCart(response.data.order_id));
};

export const cartHideLast = () => (dispatch) => dispatch({ type: types.CART_HIDE_LAST })

export const cartGetEstimatedBox = () => async (dispatch, getStore) => {
  const { logged } = getStore().user;
  const { containers } = getStore().cart;
  if (!logged || !containers.length) return;

  dispatch({ type: types.CART_LOADER_ESTIMATED_COST_BOX, loader: true })

  const response = await axiosApi.get('v1/quotations/containers?current_code=' + containers[0].code + '&' + containers[0].total_weight);

  const total = (response.data.total * containers.length);

  dispatch({ type: types.CART_GET_ESTIMATED_BOX, cost: total })
}
