import React, { useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import Image from '../Image'
import MoneyFormat from '../MoneyFormat'
import RatingProduct from '../RatingProduct'
import AppButton from './Buttons'

const UserProduct = ({ product, cant, action, type, setRating, ratingValue, allowRating }) => {
    const isMobile = useIsMobile()
    const [rating, setRatingComponent] = useState(ratingValue);
    const ratingsAllowed = [1, 2, 3, 4, 5];

    return (
        <div className="desc-product">
            {isMobile && <p className="desc-product-name">{product.name}</p>}
            <div className="desc-product-info">
                <figure className="desc-product-img">
                    <Image src={product.media[0]?.sizes?.small} alt={product.name} />
                </figure>
                <div className="desc-product-features">
                    {!isMobile && <p className="desc-product-name">{product.name}</p>}
                    {isMobile && (
                        <>
                            <p className="desc-product-price"><MoneyFormat type="mixed" money={product.price} /></p>
                            <p className="desc-product-stock">Cant. <span className="desc-product-stock--color">
                                {product.quantity}
                                {cant ? ' Piezas' : ' pieza'}
                            </span></p>
                            <p className="desc-product-brand">{product.brand}</p>
                        </>
                    )}
                    {!isMobile && (
                        <>
                            <p className="desc-product-brand">{product.brand}</p>
                            <div className="desc-product-features-flx">
                                <p className="desc-product-stock">Cant. <span className="desc-product-stock--color">
                                    {product.quantity}
                                    {cant ? ' Piezas' : ' pieza'}
                                </span></p>
                                <p className="desc-product-price"><MoneyFormat type="mixed" money={product.price} /></p>

                            </div>
                        </>
                    )}
                </div>
            </div>
            <nav className="desc-product-nav">
                <div className={`desc-product-ratings ${type === 'review' ? 'desc-product-ratings--review' : 'desc-product-ratings--detail'}`}>
                    <p className="mxp-text">{type === 'review' ? 'Califica el producto' : 'Calificación de producto'}</p>
                    {type !== 'review' && <RatingProduct rating={ratingValue} />}
                    {type === 'review' && <div className="stars">
                        {ratingsAllowed.map((ratingStar, ratingIndex) => (
                            <span key={ratingIndex} className={rating >= ratingStar ? 'active' : ''} onClick={() => {
                                setRatingComponent(ratingStar)
                                setRating(ratingStar)
                            }}>
                                <i className='icon-star'></i>
                            </span>
                        ))}
                    </div>}
                </div>
                {allowRating && type !== 'review' && <AppButton type="main-blue" txt="Escribe una reseña" onClick={() => {
                    action(product)
                }} available={true} />}
            </nav>
        </div>
    )
}

export default UserProduct
