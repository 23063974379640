export const types = {
  COMPARE_ADD_DATA: 'COMPARE_ADD_DATA',
  COMPARE_SELECT_ITEM: 'COMPARE_SELECT_ITEM',
  COMPARE_REMOVE_ITEM: 'COMPARE_REMOVE_ITEM',
  COMPARE_CHANGE: 'COMPARE_CHANGE',
  COMPARE_LOADER: 'COMPARE_LOADER',
  COMPARE_RESET: 'COMPARE_RESET',
  RESET: 'RESET',
};

const initialStore = {
  change: false,
  compare: [],
  edit: false,
  loaded: false,
  loader: false,
  main: {},
  toCompare: [],
};

const reducer = (store = initialStore, action) => {
  switch (action.type) {
    case types.COMPARE_ADD_DATA:
      return { ...store, ...action.data, loaded: true, loader: false };
    case types.COMPARE_LOADER:
      return { ...store, loader: action.loader };
    case types.COMPARE_SELECT_ITEM:
      const check = store.toCompare.findIndex((item) => item.product_id === action.product_id);
      if (check > -1) {
        return { ...store };
      }
      const product = store.compare.find((item) => item.product_id === action.product_id);
      const addToCompare = [...store.toCompare];
      addToCompare.push(product);
      if (store.edit) {
        const index = addToCompare.findIndex((item) => item.product_id === store.edit);
        addToCompare.splice(index, 1);
      }
      return { ...store, toCompare: addToCompare, edit: false };
    case types.COMPARE_CHANGE:
      return { ...store, edit: action.product_id };
    case types.COMPARE_REMOVE_ITEM:
      const removeItemCompare = [...store.toCompare];
      const findIndex = removeItemCompare.findIndex((item) => item.product_id === action.product_id);

      if (findIndex > -1) {
        removeItemCompare.splice(findIndex, 1);
      }

      return { ...store, toCompare: removeItemCompare };
    case types.RESET:
    case types.COMPARE_RESET:
      return {...initialStore}
    default:
      return { ...store };
  }
};

export default reducer;
