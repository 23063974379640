import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { validateEmail, validateName, validatePhone, validateRFC } from '../../helpers/validations';
import FakeSelect from '../FormElements/FakeSelect';
import FormInput from '../FormElements/FormInput'
import AppButton from '../UI/Buttons'
import { registerUser, getUserData, registerUserNetwork, userMenuControl, userRegistered } from '../../store/actions/user';
import { validatePassword } from './../../helpers/validations';
import ModalBox from '../UI/ModalBox';
import ContainerAlert from '../Detail/ContainerAlert';

const RegistrationDesktop = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { loader, error, registerResponse, user_data_network } = useSelector((state) => state.user)
    const btnGoogle = useRef(false)
    const [categoriesArr, setCategoriesArr] = useState([])
    const { catalogs } = useSelector(state => state.home)

    useEffect(() => {
        if (catalogs.length) {
            const items = catalogs.map(category => ({ value: category.code, id: category.id, label: category.alt_name || category.name }))
            setCategoriesArr(items);
        }
    }, [dispatch, catalogs])

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        watch,
    } = useForm({
        mode: 'onBlur',
    });

    const { name, email, phone, sector, password, password_confirm } = watch();

    const submit = (data) => {
        if (user_data_network && user_data_network.name) {
            data.social_network = {
                social_type: user_data_network.social_type,
                access_token: user_data_network.access_token
            };
            dispatch(registerUserNetwork(data));
        } else {
            dispatch(registerUser(data));
        }
        setInitShow(false)
    };

    const handleFacebookConnect = () => {
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    }

    // Facebook
    const FB = window.FB;

    useEffect(() => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: process.env.FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v2.7'
            });
        };
    }, [FB])

    const statusChangeCallback = (response) => {
        if (response.status === 'connected') {
            dispatch(getUserData('facebook', response.authResponse.accessToken))
            clearErrors(['name', 'email'])
        } else {
            facebookLogin();
        }
    }

    const facebookLogin = () => {
        FB.login(function (response) {
            statusChangeCallback(response)
        });
    }
    // Facebook

    const handleGoogleConnect = () => {
    }

    useEffect(() => {
        const attachSigning = (element) => {
            window.auth2.attachClickHandler(element, {},
                function (googleUser) {
                    dispatch(getUserData('google', googleUser.getAuthResponse().id_token));
                    clearErrors(['name', 'email'])
                }, function (error) {

                });
        }

        if (window.gapi && btnGoogle.current) {
            window.gapi.load('auth2', function () {
                window.auth2 = window.gapi.auth2.init({
                    client_id: process.env.GOOGLE_SIGNING_CLIENT_ID,
                    cookiepolicy: 'single_host_origin',
                });
                attachSigning(btnGoogle.current);
            });
        }
    }, [clearErrors, dispatch])

    useEffect(() => {
        if (user_data_network && user_data_network.name) {
            setValue('name', user_data_network.name, { shouldValidate: false })
            setValue('email', user_data_network.email, { shouldValidate: false })
        }
    }, [setValue, user_data_network])

    const enableSubmit = () => {
        return !Object.keys(errors).length && name && email && phone && password && password_confirm && sector;
    }

    const [show_alert_modal, setShowModal] = useState(false);
    const [initShow, setInitShow] = useState(false)
    useEffect(() => {
        if (initShow) return
        if (registerResponse.status_code === 200) {
            window.scroll(0, 0)
            setInitShow(true)
            setShowModal(true)
            dispatch(userRegistered(true))
        }
        if ([3, 203].includes(registerResponse.status_code)) {
            window.scroll(0, 0)
        }
        if (registerResponse.status_code === 202) {
            window.scroll(0, 0)
        }
    }, [dispatch, initShow, registerResponse, show_alert_modal])

    return (
        <section className="register">
            <div className="register-header">
                <h2 className="mxp-subtitle">Registro</h2>
                <AppButton
                    type="icon"
                    icon="cancel-circle"
                    modifier="close"
                    customClick={() => {
                        history.push('/box')
                        dispatch({ type: 'REGISTER_CLEAR' })
                    }}
                />
            </div>
            {errors.password_confirm?.message}
            <article className={`register-events ${loader ? 'register-events--loading' : ''} ${[3, 203].includes(registerResponse.status_code) ? 'register-events--action' : ''} ${registerResponse.status_code === 202 ? 'register-events--action' : ''}`}>
                {!error && !([3, 203, 201].includes(registerResponse.status_code)) && registerResponse.status_code !== 202 && <div className="register-area">
                    <div className="register-regular">
                        <form className="login-form" onSubmit={handleSubmit(submit)}>
                            <div className="register-field">
                                <h3 className="mxp-sub-subtitle">Datos pesonales</h3>

                                <div className="register-inputs">
                                    <FormInput
                                        label='Nombre completo*'
                                        placeholder='Jorge Eduardo Hernández Pérez'
                                        name='name'
                                        register={register}
                                        options={validateName}
                                        error={errors.name}
                                        type='text'
                                        defaultValue={''}
                                        setValue={setValue}
                                        validate="name"
                                    />
                                    <FormInput
                                        label='Correo electrónico*'
                                        placeholder='jorgeEHP@correo.com'
                                        name='email'
                                        register={register}
                                        options={validateEmail}
                                        error={errors.email}
                                        type='email'
                                        defaultValue={''}
                                        setValue={setValue}
                                        validate="email"
                                    />
                                    <FormInput
                                        label='Teléfono*'
                                        placeholder='10 dígitos'
                                        name='phone'
                                        register={register}
                                        options={validatePhone}
                                        error={errors.phone}
                                        type='text'
                                        defaultValue={''}
                                        setValue={setValue}
                                        validate="number"
                                        maxLength="10"
                                    />
                                    <div className="register-user-type">
                                        <label className="register-user-type-label">Tipo de perfil*</label>
                                        <FakeSelect className='register-user-type-select'
                                            id='register-user-type-select'
                                            placeholder={'Selecciona un perfil'}
                                            options={categoriesArr}
                                            onChange={setValue}
                                            name="sector"
                                            value={sector}
                                            registerOptions={{ shouldValidate: true, required: true }}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="register-field register-field--passwords">
                                <h3 className="mxp-sub-subtitle">Contraseña</h3>
                                <div className="register-inputs">
                                    <FormInput
                                        label='Contraseña*'
                                        placeholder='Ingresa de 8 a 30 caracteres'
                                        name='password'
                                        register={register}
                                        error={errors.password}
                                        options={validatePassword}
                                        type='password'
                                        setValue={setValue}
                                        className="pass"
                                    />
                                    <FormInput
                                        label='Confirmar contraseña*'
                                        placeholder='Ingresa de 8 a 30 caracteres'
                                        name='password_confirm'
                                        register={register}
                                        error={errors.password_confirm}
                                        options={validatePassword}
                                        type='password'
                                        setValue={setValue}
                                        className="pass"
                                    />
                                    <p className="mxp-text"> Recuerda que la contraseña debe contener mínimo 8 caracteres, incluir mayúsculas, minúsculas, números y caracteres especiales ($#&,.-). </p>
                                </div>
                            </div>
                            <div className="register-field register-field--extras">
                                <h3 className="mxp-sub-subtitle">Adicionales</h3>
                                <div className="register-inputs">
                                    <FormInput
                                        label='RFC (opcional)'
                                        placeholder='13 caracteres'
                                        name='rfc'
                                        register={register}
                                        options={validateRFC}
                                        error={errors.rfc}
                                        setValue={setValue}
                                    />
                                </div>
                            </div>
                            <div className="register-nav">
                                <AppButton type="main" txt="REGISTRARME" available={enableSubmit()} loader={loader} />
                            </div>
                        </form>
                    </div>
                    <div className="register-connect">
                        <h3 className="mxp-sub-subtitle">Registrarme con redes sociales</h3>

                        <nav className="register-connect-nav">
                            <AppButton
                                type="connect"
                                txt="Registrarme con Facebook"
                                icon="facebook"
                                modifier="login-facebok"
                                available={true}
                                customClick={handleFacebookConnect} />
                            <AppButton
                                type="connect"
                                txt="Registrarme con Google+"
                                icon="google"
                                modifier="google"
                                available={true}
                                customClick={handleGoogleConnect}
                                refValue={btnGoogle} />
                        </nav>
                    </div>
                </div>}
                {[3, 203, 201].includes(registerResponse.status_code) && (
                    <div className="purchases-product-response purchases-product-response--error">
                        <figure className="purchases-product-icon">
                            <span className="icon-alert"></span>
                        </figure>

                        <h3 className="mxp-sub-subtitle">Lo sentimos ha ocurrido un error inesperado.</h3>
                        <p className="mxp-text">Por favor, intenta nuevamente</p>
                        <AppButton type="main" txt="Reintentar" onClick={() => { dispatch({ type: 'REGISTER_CLEAR' }) }} available={true} />
                        <AppButton type="outlined" txt="Cancelar" onClick={() => { history.push('/') }} available={true} />
                    </div>
                )}
                {registerResponse.status_code === 202 && (
                    <div className="purchases-product-response purchases-product-response--ok">
                        <figure className="purchases-product-icon">
                            <span className="icon-ok-2"></span>
                        </figure>
                        <h3 className="mxp-sub-subtitle">{registerResponse.short_message}</h3>
                        <p className="mxp-text">Para terminar tu registro debes activar tu cuenta, revisa tu correo electrónico.</p>
                        <AppButton type="main" txt="Ir al catálogo" onClick={() => {
                            history.push('/')
                            dispatch({ type: 'REGISTER_CLEAR' })
                        }} available={true} />
                        <AppButton type="outlined" txt="Personaliza mi perfil" onClick={() => {
                            dispatch(userMenuControl(false, 'user-login', 'top'))
                        }} available={true} />
                    </div>
                )}
                {registerResponse.status_code === 200 && (
                    <ModalBox
                        show={show_alert_modal}
                        modifier='mb-alerts-wrap'
                    >
                        <ContainerAlert
                            type="global"
                            customAction={() => {
                                setShowModal(false)
                            }}
                            cancelAction={() => {
                                setShowModal(false)
                                history.push('/')
                            }}
                            setShowModal={setShowModal}
                            title="¡Lo sentimos!"
                            copy={registerResponse.message}
                            label="Reintentar"
                        />
                    </ModalBox>
                )}
            </article>
        </section>
    )
}

export default RegistrationDesktop
