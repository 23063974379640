import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorModal from '../components/ErrorModal';
import FormInput from '../components/FormElements/FormInput';
import Textarea from '../components/FormElements/Textarea';
import UploadFile from '../components/FormElements/UploadFile';
import ContainerBoxPage from './../components/containers/ContainerBoxPage';
import FakeSelect from './../components/FormElements/FakeSelect';
import { validateEmail, validateName, validatePhone } from './../helpers/validations';
import useIsMobile from './../hooks/useIsMobile';
import { sendContactForm } from './../store/actions/contact';
import { contactoAnalytics } from './../store/actions/analytics-events';

const subjects = ['Negocio nuevo', 'Reposición inteligente', 'Presupuestar', 'Compra por mayoreo'];

const ContactPage = () => {
  const isMobile = useIsMobile();
  const logged = useSelector((state) => state.user.logged);
  const { phone: phoneOffice } = useSelector((state) => state.home);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      type: 'quotation',
      subject: '',
      name: '',
      email: '',
      phone: '',
      comments: '',
    },
  });

  const { subject, comments, name, phone, email } = watch();

  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const submitForm = async (data) => {
    setLoader(true);
    const send = await dispatch(sendContactForm({ ...data, file: file?.base64 }));
    setLoader(false);
    if (send.status_code === 800) {
      window.scroll(0, 0);
    }
    setSubmit(send);
    contactoAnalytics(subject)
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [isMobile]);

  const clearSubmit = () => {
    setSubmit(false);
  };

  const enableSubmit = () => isValid && subject.length && name.length && phone.length && (email.length || logged);

  return (
    <ContainerBoxPage className='contact-page'>
      <main>
        <Breadcrumbs basic onlyGoBack={true} />
        <h1>Contacto</h1>
        {!submit && (
          <form action='/' onSubmit={handleSubmit(submitForm)} autoComplete='off' className={logged ? 'logged-user' : ''}>
            <FakeSelect name='subject' options={subjects} label='Asunto*' value={subject} onChange={setValue} placeholder='Selecciona un motivo' completed={subject.length} />
            <FormInput
              name='name'
              label='Nombre completo*'
              placeholder='Jorge Eduardo Hernández Pérez'
              options={validateName}
              validate='name'
              setValue={setValue}
              error={errors.name}
              register={register}
            />
            {!logged && (
              <FormInput
                name='email'
                label='Correo electrónico*'
                placeholder='jorgeEHP@correo.com'
                validate='email'
                inputMode='email'
                register={register}
                setValue={setValue}
                error={errors.email}
                options={validateEmail}
              />
            )}
            <div className='input-logged-upload'>
              <FormInput
                name='phone'
                label='Teléfono*'
                placeholder='10 dígitos'
                inputMode='numeric'
                validate='number'
                options={{ ...validatePhone }}
                error={errors.phone}
                setValue={setValue}
                register={register}
                maxLength='10'
              />
              {logged && !isMobile && <UploadFile onChange={(uploadedFile) => setFile(uploadedFile)} file={file} maxSize={7} disabled={!enableSubmit()} />}
            </div>
            <Textarea placeholder='Comentario' value={comments || ''} maxLength={450} register={register} name='comments' onChange={setValue} />
            <div className='btns-group'>
              {(!logged || isMobile) && <UploadFile onChange={(uploadedFile) => setFile(uploadedFile)} file={file} maxSize={7} disabled={!enableSubmit()} />}
              <button className={`btn btn-primary rounded-small${!enableSubmit() ? ' disabled' : ''}${loader ? ' btn-loader' : ''}`}>ENVIAR</button>
            </div>
          </form>
        )}
        {submit && submit.status_code !== 800 && <ErrorModal msg={submit.message} close={clearSubmit} />}
        {submit && submit.status_code === 800 && (
          <div className='thanks'>
            <div>
              <img src='/img/alert-positive.png' alt='thanks' />
              <h2>¡Gracias!</h2>
              <p>Hemos recibido tus datos, en breve nos pondremos en contacto para dar seguimiento a tu solicitud.</p>
              <Link className='btn btn-primary rounded' to='/'>
                IR AL HOME
              </Link>
            </div>
          </div>
        )}
        <div className='office-hours'>
          <div className='phone'>
            <a className='btn btn-primary rounded-small disabled' href={`tel:${phoneOffice?.office?.number}`}>
              <i className='icon-phone'></i>
              {phoneOffice?.office?.number}
            </a>
          </div>
          <div className='hours'>
            <strong>Horario de atención:</strong>
            Lunes a sábado de 09:00 a 18:00 hrs.
          </div>
        </div>
      </main>
    </ContainerBoxPage>
  );
};

export default ContactPage;
