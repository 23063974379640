import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FakeSelect from '../../../components/FormElements/FakeSelect';
import InputImage from '../../../components/FormElements/InputImage';
import InputEditProfile from '../../../components/User/InputEditProfile';
import { validateEmail, validateName, validatePhone, validateRFC } from '../../../helpers/validations';
import { userClearConfirmUpdateProfile, userUpdateProfileAll } from '../../../store/actions/user';
import { AppButton } from './../../../components/UI/Buttons';
import ProfileContainer from './../ProfileContainer';

const PersonalInformationPage = () => {
  const dispatch = useDispatch();

  const { profile, loader, updateConfirmation } = useSelector((state) => state.user);
  const { catalogs: sectors } = useSelector((state) => state.home);

  const [newSector, setNewSector] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: { ...profile },
  });

  const handleSector = (option) => {
    setNewSector(option);
    setValue('catalog_id', option.id, { shouldDirty: true });
  };

  const handleSubmitForm = (data) => {
    dispatch(userUpdateProfileAll(data, newSector || profile.catalog, data.avatar || profile.avatar));
  };

  const enableSubmit = () => {
    return isDirty && !Object.keys(errors).length;
  };

  useEffect(() => {
    return () => {
      dispatch(userClearConfirmUpdateProfile())
    }
  }, [dispatch])

  return (
    <ProfileContainer>
      <form className='form-personal-info' onSubmit={handleSubmit(handleSubmitForm)}>
        <h1 className="title-section">Datos personales</h1>
        <div className='container-avatar'>
          <InputImage src={profile.avatar} save={(value) => setValue('image', value, {shouldDirty: true})} />
        </div>
        <div className='container-inputs'>
          <InputEditProfile
            register={register}
            name='name'
            error={errors.name}
            label='Nombre completo*'
            defaultValue={profile.user_name}
            onChange={setValue}
            options={validateName}
            placeholder={profile.user_name}
          />
          <InputEditProfile
            register={register}
            name='email'
            error={errors.email}
            label='Correo electrónico*'
            defaultValue={profile.email}
            onChange={setValue}
            options={validateEmail}
            placeholder={profile.email}
            readOnly
          />
          <InputEditProfile
            register={register}
            name='phone'
            error={errors.phone}
            label='Teléfono*'
            placeholder={profile.phone}
            defaultValue={profile.phone}
            onChange={setValue}
            options={validatePhone}
            maxLength='10'
            numeric
          />
          <FakeSelect
            options={sectors}
            label='Tipo de perfil*'
            placeholder='Seleccionar perfil'
            value={newSector || profile.catalog}
            defaultValue={profile?.catalog?.id}
            id='form-select-sector-profile'
            handle={handleSector}
            onChange={setValue}
            register={register}
            name='catalog_id'
          />
          <InputEditProfile register={register} name='rfc' error={errors.rfc} label='RFC' defaultValue={profile.rfc} onChange={setValue} options={validateRFC} placeholder={profile.rfc} icon={false} />

          {updateConfirmation?.status_code === 402 && (
              <div className='confirmation'>
                <i className='icon-ok-2'></i>
                <span>{updateConfirmation.message}</span>
              </div>
            )}
            {updateConfirmation && updateConfirmation?.status_code !== 402 && (
              <div className='confirmation-fail'>
                <i className='icon-close'></i>
                {updateConfirmation.message}
              </div>
            )}

          <AppButton txt='GUARDAR' type='main' available={enableSubmit()} loader={loader} small />
        </div>
      </form>
    </ProfileContainer>
  );
};

export default PersonalInformationPage;
