import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import IconBox from './IconBox';
import MoneyFormat from '../MoneyFormat';

let sliderLargeBoxes;

const SliderLargeBoxes = () => {
  const sliderContainer = useRef(false);

  const { containers_list } = useSelector((state) => state.cart);

  useEffect(() => {
    if (sliderContainer.current && !sliderLargeBoxes) {
      sliderLargeBoxes = $(sliderContainer.current);
    }

    if(sliderLargeBoxes){
      sliderLargeBoxes.slick({
        prevArrow: '<i class="icon-left-open-light" />',
        nextArrow: '<i class="icon-right-open-light" />',
        infinite: false
      })
    }

    return () => {
      if(sliderLargeBoxes){
        sliderLargeBoxes.slick('unslick');
      }
    }
  });

  if (!containers_list) return <Loader ellipsis />;

  return (
    <div id='box-slider' ref={sliderContainer}>
      {containers_list.map((container, containerIndex) => (
        <div key={containerIndex} className={`container-item ${container.code}`}>
          <div className='container-icon'>
            <strong>{container.name}</strong>
            <IconBox id={container.code} name={container.code} percent={50} />
          </div>
          <div className='price'>
            <i className='icon-size'></i>
            <span><MoneyFormat money={container.total} /></span>
          </div>
          <div className='size'>
            <i className='icon-size'></i>
            {container.width} cm x {container.height} cm x {container.length} cm
          </div>
          <div className='weight'>
            <i className='icon-weight'></i>
            {container.weight} kg
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderLargeBoxes;
