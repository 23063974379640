
export const purchase = {
    pageMovement: {
        visible: {
            opacity: 1,
            x: 0
        },
        hidden: {
            opacity: 0,
            x: -50
        },
    },
    downMovement: {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 0,
            y: -50
        },
    },
    buttonsParent: {
        open: {
            transition: {
                staggerChildren: 0.09,
                delayChildren: 0.2
            }
        },
        closed: {
            transition: {
                staggerChildren: 0.08,
                staggerDirection: -1
            }
        }
    },
    buttonsItem: {
        open: {
            x: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -250 }
            }
        },
        closed: {
            x: -80,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    }
}